import { Component, ElementRef } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { DeviceDetectorService } from 'ngx-device-detector';

import { JsonDataService } from './services/json-data.service';
import { ParameterService } from './services/parameter.service';
import { APICallService } from './services/api-call.service';
import { UserDetailsService } from './services/user-details.service';
import { SecurityService } from './services/security.service';
import { Observable, isObservable } from 'rxjs';

declare var addGaTag: any;
declare let gtag: Function;

@Component({
    selector: 'app-root',
    template: `
        <ng-container *ngIf="!withExpiredReportLink && jsonDataService.doneAuthorizing && !jsonDataService.onMaintenance">
            <app-message></app-message>
            <loading></loading>
            <message-box></message-box>
            <popup></popup>
            <use-of-cookies *ngIf="!userAccept"></use-of-cookies>
            <header></header>
            <div id="main-content">
                <router-outlet></router-outlet>
            </div>
            <footer></footer>
        </ng-container>
        <maintenance *ngIf="jsonDataService.onMaintenance"></maintenance>
        <expired-report-link *ngIf="withExpiredReportLink && !jsonDataService.onMaintenance"></expired-report-link>
    `
})
export class AppComponent {

    withExpiredReportLink: boolean = false;
    userAccept: string = "";

    // <agm-map [latitude]="latitude" [longitude]="longitude" (mapClick)="onChoseLocation($event)">
    //     <agm-marker *ngIf="locationChosen" [latitude]="latitude" [longitude]="longitude"></agm-marker>
    // </agm-map>

    public latitude: number = 16;
    public longitude: number = 121;
    public locationChosen: boolean = false;

    public onChoseLocation(event: any): void {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.locationChosen = true;
    }

    constructor (
        // PUBLIC
        public jsonDataService: JsonDataService,

        // PRIVATE
        private parameterService: ParameterService,
        private meta: Meta,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private titlecasePipe: TitleCasePipe,
        private elementRef: ElementRef,
        private deviceDetectorService: DeviceDetectorService,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private securityService: SecurityService
    ) {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params?.report) {
                this.withExpiredReportLink = true;
            }
        });

        this.userAccept = sessionStorage.getItem("u&jyUbb$dpa_t0(dQoc02mm0S*n=") || "";
        this.clearRecentVersionLocalAndSessionStorage();
        this.setLastActionHostListener();
        this.gtagKeyGetter();
        this.checkAuthorization();

        // This is to prevent non-prod OSP domains to become searchable on search engines.
        if (window.location.hostname !== "eprotect.bpims.com") {
            this.meta.addTag({ name: "robots", content: "noindex, nofollow" });
        }

        // Add Facebook Pixel
        if (!jsonDataService.onMaintenance) {
            this.initFacebookPixel();
        }
    }

    private clearRecentVersionLocalAndSessionStorage(): void {
        if (localStorage.getItem("ReleaseV") !== "R2") {
            sessionStorage.clear();
            localStorage.clear();
            localStorage.setItem("ReleaseV", "R2");
        }
    }

    private setLastActionHostListener(): void {
        this.elementRef.nativeElement.addEventListener("keypress", () => {
            this.userDetailsService.lastActionTime = new Date();
        });
        this.elementRef.nativeElement.addEventListener("click", () => {
            this.userDetailsService.lastActionTime = new Date();
        });

        if (this.deviceDetectorService.isDesktop()) {
            this.elementRef.nativeElement.addEventListener("mousemove", () => {
                this.userDetailsService.lastActionTime = new Date();
            });
        } else {
            this.elementRef.nativeElement.addEventListener("touchmove", () => {
                this.userDetailsService.lastActionTime = new Date();
            });
        }
    }

    private gtagKeyGetter():void {
        const gtagKey = this.jsonDataService.gtagEncryptKey;
        if (gtagKey) {
            const gTagManagerScript = document.createElement("script");
            gTagManagerScript.async = true;
            gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagKey}`;
            document.head.appendChild(gTagManagerScript);

            addGaTag();

            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    const path = event.urlAfterRedirects === "/" ? "/home" : (event.urlAfterRedirects.includes("?utm_source") ? "/home" : event.urlAfterRedirects);
                    const splitter = path.split("/");
                    const title = splitter[splitter.length - 1].includes("-") ? splitter[splitter.length-1].replace("-", " ") : splitter[splitter.length - 1];

                    gtag("config", gtagKey, {
                        "page_title": this.titlecasePipe.transform(title.split("?")[0] === "" ? "Home" : title.split("?")[0]),
                        "page_path": path,
                    });
                }
            });
        }
    }

    private async checkAuthorization(): Promise<void> {
        await this.apiCallService.checkAuthorization().pipe(
            map((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status !== "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const content = JSON.parse(this.jsonDataService.decrypt(response.response));

                            if (response.status === "AUTHORIZED") {
                                if (content.activeTag === "L") {
                                    // Do nothing for now.
                                } else {
                                    this.userDetailsService.setUserDetails(content);
                                }
                            } else if (response.status === "LIMIT_REACHED") {
                                this.userDetailsService.setUserDetails(content);
                            }
                        }, false);
                    } else {
                        sessionStorage.removeItem("bssid");
                        sessionStorage.removeItem("rkc");
                        this.userDetailsService.resetUserDetails();
                    }
                });
                this.jsonDataService.loadChildParams(location.pathname.substring(1) || '');
                let loading:Observable<boolean> | boolean = this.jsonDataService.checkLoadingParams(location.pathname);
                if(isObservable(loading)){
                    loading.subscribe(()=>{
                        this.jsonDataService.doneAuthorizing = true;
                    })
                }else{
                    this.jsonDataService.doneAuthorizing = true;
                }
                
            })
        ).toPromise();
    }

    private initFacebookPixel() {
        const pixelId = this.parameterService.paramV("FACEBOOK_PIXEL_ID");
        const metaContent = this.parameterService.paramV("FACEBOOK_META_CONTENT") || "i5m5rvc3czetdcf3mqab3mhcleys08";

        var fbMeta = document.createElement("meta");
        fbMeta.setAttribute("name", "facebook-domain-verification");
        fbMeta.setAttribute("content", metaContent);
        document.head.appendChild(fbMeta);

        var fbPixelScript = document.createElement("script");
        var fbPixelScriptContents = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelId}');
            fbq.disablePushState = true;
        `
        fbPixelScript.text = fbPixelScriptContents
        document.head.appendChild(fbPixelScript);
                    
        var fbPixelNoScript = document.createElement('noscript');
        var fbPixelNoScriptContent = document.createElement('img');
        fbPixelNoScriptContent.setAttribute('height', '1');
        fbPixelNoScriptContent.setAttribute('width', '1');
        fbPixelNoScriptContent.setAttribute('style', 'display:none');
        // fbPixelNoScriptContent.setAttribute('src', `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`);
        // var fbPixelNoScriptContents = `
        //     <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
        // `            
        // fbPixelNoScript.innerHTML = fbPixelNoScriptContents
        fbPixelNoScript.appendChild(fbPixelNoScriptContent)
        document.head.appendChild(fbPixelNoScript);
    }
}
