<section id="sub-forgot" [class.account]="!(router.url === '/login' || router.url.includes('login'))">
    <form [formGroup]="form" ng-submit="forgotPassword()">
        <div id="sub-forgot-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <h2>Forgot Password</h2>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Account Type</label>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="accountType" (selectionChange)="checkAccountType()">
                                    <mat-option *ngFor="let accountType of varAccountTypes" [value]="accountType.value">{{accountType.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-case" *ngIf="form.get('accountType')?.value == 'P'">
                        <div class="field required">
                            <label>Email Address</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="email" emailAdd>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'email')">
                                {{formService.getFieldErrorMsg(form, "email")}}
                            </p>
                        </div>
                    </div>
                    <div class="col-case" *ngIf="form.get('accountType')?.value != 'P'">
                        <div class="field required">
                            <label>User ID</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="userId" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'userId')">
                                {{formService.getFieldErrorMsg(form, "userId")}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="forgotFormMsg bottom"></div>
        </div>
        <div class="form-btns">
            <button class="btn btn2" (click)="goToForm('login')">Back</button>
            <button class="btn btn2" (click)="forgotPassword()">Submit</button>
        </div>
    </form>
    <div class="registration-btn hwl" *ngIf="router.url === '/login' || router.url.includes('login')">
        <p class="note">Don't have an account yet? Create your account here.</p>
        <a routerLink="/register" class="btn">Register</a>
    </div>
</section>