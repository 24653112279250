<section id="choose-plan">
    <div class="container">
        <ng-container *ngIf="!showBenefits && !isPlanOnlyFL">
            <div class="plan-container">
                <div class="plans">
                    <div class="plan-box montserrat top-border" *ngFor="let plan of plans; let first = first; let last = last" [class.border4]="productInfo.planInfo.planCd === plan.planCd" [class.selected]="productInfo.planInfo.planCd === plan.planCd" [class]="plan.lineCd + '-' + plan.sublineCd">
                        <div class="rec-slot" *ngIf="productInfo.lineCd === 'HA'">
                            <div class="rec-note montserrat" *ngIf="first && !last">
                                <p>Recommended</p>
                            </div>
                        </div>
                        <h1>{{plan.planName}}</h1>
                        <h2>{{plan.subTitle}}</h2>
                        <pre class="plan-short-desc">{{plan.shortDesc}}</pre>
                        <ng-container *ngIf="plan.coverageTag === 'Y'">
                            <ng-container *ngFor="let planPeril of planPerils">
                                <ng-container *ngIf="plan.planCd === planPeril.planCd && plan.sublineCd === planPeril.sublineCd">
                                    <ng-container *ngFor="let peril of perils">
                                        <p class="{{planPeril.hlTag}}" *ngIf="planPeril.perilCd === peril.perilCd">
                                            {{peril.perilLname}}
                                        </p>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="plan.coverageTag === 'N'">
                            <ng-container *ngIf="plan.shortDesc2 !== ''">
                                <div class="perils" [innerHTML]="plan.shortDesc2"></div>
                            </ng-container>
                        </ng-container>
                        <div class="plan-btns">
                            <button class="select-btn btn btn3" (click)="onSelectPlanCd(plan, '')" [disabled]='selDisabled'>{{productInfo.planInfo.planCd === plan.planCd ? "Selected" : "Select this plan"}}</button>
                            <button class="learn-btn btn btn6" (click)="getPdf(plan.lineCd, plan.sublineCd, plan.planCd)">Learn more</button>
                        </div>
                    </div>
                </div>
            </div>
            <form [formGroup]="prodVouchForm" *ngIf="showField">  
                <div class="form code">
                    <div class="col-case col-3">
                        <div class="field prodLabel">
                            <label>Enter Referral or Product Code</label>
                        </div>
                        <div class="field prodInput" [ngClass]="errorMessage === '' ? 'prodVouch' : 'error'">
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="prodVouchCode" promoCode [placeholder]="prodPlaceHolder" (input)="onKeyPress()" (blur)="validateCd()">
                                <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" [matTooltip]="prodVouchToolTip" matTooltipPosition="right" class="tooltip" *ngIf="prodVouchToolTip != ''">help_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form code2" *ngIf="errorMessage !== ''">
                    <div class="col-case col-3">
                        <div class="field prodLabel">
                        </div>
                        <div class="field prodInput">
                            <p class="field-error " >
                                {{this.errorMessage}}
                            </p>
                        </div>
                    </div>
                </div>
            </form>
            <div class="form-note montserrat">
                <div *ngIf="this.productInfo.lineCd === 'HA' && showCaveat" class="mort" [innerHTML]="mortCaveat"></div>
                <span *ngIf="this.productInfo.lineCd === 'HA' && showCaveat"><a class="link" (click)="showMortLink()">For mortgaged properties, click/tap here for further reminders.</a></span>
                <!-- <p *ngIf="this.productInfo.lineCd === 'HA' && showCaveat" class="mort">{{mortCaveat}}</p> -->
                <p *ngIf="this.productInfo.lineCd === 'HA' && showCaveat">&nbsp;</p>
                <p>{{conditionText}}</p>
            </div>
        </ng-container>
        <ng-container *ngIf="showBenefits">
            <div class="benefits-coverage-container">
                <div class="col title montserrat">BENEFITS AND COVERAGES</div>
                <div class="blue montserrat">{{productInfo.planInfo.premiumText}}</div>
                <div class="plans">
                    <div class="plan-box montserrat top-border" *ngFor="let benefit of benefits">
                        <h1 class="benefits-title">{{benefit.planSubgrpTitle}}</h1>
                        <!--<div class="perils" [innerHTML]="benefit.planSubgrpText"></div> -->
                        <pre class="coverage montserrat" [innerHTML]="benefit.planSubgrpText"></pre>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="productInfo.lineCd === 'HA' && isPlanOnlyFL">
            <div class="plan-container">
                <div class="plans">
                    <div class="plan-box montserrat top-border border4 selected" *ngFor="let plan of plans" [class]="plan.lineCd + '-' + plan.sublineCd">
                        <h1>{{plan.planName}}</h1>
                        <h2>{{plan.subTitle}}</h2>
                        <pre class="plan-short-desc">{{plan.shortDesc}}</pre>
                        <ng-container *ngIf="plan.coverageTag === 'Y'">
                            <ng-container *ngFor="let planPeril of planPerils">
                                <ng-container *ngIf="plan.planCd === planPeril.planCd && plan.sublineCd === planPeril.sublineCd">
                                    <ng-container *ngFor="let peril of perils">
                                        <p class="{{planPeril.hlTag}}" *ngIf="planPeril.perilCd === peril.perilCd">
                                            {{peril.perilLname}}
                                        </p>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="plan.coverageTag === 'N'">
                            <ng-container *ngIf="plan.shortDesc2 !== ''">
                                <div class="perils" [innerHTML]="plan.shortDesc2"></div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="plan-box montserrat top-border form-offer" *ngFor="let plan of plans">
                        <h1>{{wordingsTitle}}</h1>
                        <h2>{{wordingsOffer}}</h2>
                        <form [formGroup]="planOfferForm">
                            <div class="form no-line">
                                <div class="col-case">
                                    <mat-radio-group formControlName="isMoreCoverage" class="radio-group">
                                        <div class="radio-btn-slot">
                                            <mat-radio-button class="radio-button" value="Y">
                                                <p class="radio-lbl">{{ answerYes }}</p>
                                            </mat-radio-button>
                                        </div>
                                        <div class="radio-btn-slot">
                                            <mat-radio-button class="radio-button" value="N">
                                                <p class="radio-lbl">{{ answerNo }}</p>
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                                <!-- <div class="col-case" *ngIf="condition1" >
                                    <div class="checkbox">
                                        <mat-checkbox [checked]="isCB1Check" (change)="chkBox($event, 'CB1')" formControlName="isCB1">{{condition1}}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-case" *ngIf="condition2" >
                                    <div class="checkbox">
                                        <mat-checkbox [checked]="isCB2Check" (change)="chkBox($event, 'CB2')" formControlName="isCB2">{{condition2}}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-case" *ngIf="condition3" >
                                    <div class="checkbox">
                                        <mat-checkbox [checked]="isCB3Check" (change)="chkBox($event, 'CB3')" formControlName="isCB3">{{condition3}}</mat-checkbox>
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="form-note montserrat">
                <div *ngIf="this.productInfo.lineCd === 'HA' && showCaveat" class="mort" [innerHTML]="mortCaveat"></div>
                <span *ngIf="this.productInfo.lineCd === 'HA' && showCaveat"><a class="link" (click)="showMortLink()">For mortgaged properties, click/tap here for further reminders.</a></span>
                <!-- <p *ngIf="this.productInfo.lineCd === 'HA' && showCaveat" class="mort">{{mortCaveat}}</p> -->
                <p *ngIf="this.productInfo.lineCd === 'HA' && showCaveat">&nbsp;</p>
                <p>{{conditionText}}</p>
            </div>
        </ng-container>
        <div class="form-btns">
            <button class="btn btn4" (click)="move('back')">Cancel</button>
            <!--<button class="btn btn2" (click)="move('next')" *ngIf="!showBenefits">Proceed</button> -->
            <button class="btn btn2" (click)="move('next')" [disabled]='nextDisabled'>{{this.showBenefits ? "Okay. Proceed.": "Proceed"}}</button>
        </div>
    </div>
</section>

<overlay id="referralCode" #referralCode class="overlay-referral">
    <div content>
        <p class="ref-title montserrat">DO YOU HAVE A REFERRAL CODE?</p>
        <!--Thank you for reviewing your quotation. Do you want to generate your E-Policy?-->
        <p class="referral-text montserrat">
            If you are a BPI client, kindly contact your Branch Manager to get your Referral Code.
            <br><br>
            If you do not have a Referral Code, you may still click Continue to proceed with your application.
        </p>
        <label class="referral-label montserrat">Enter your Referral Code here</label>
        <mat-form-field appearance="fill">
            <div [formGroup]="profileForm">
                <input matInput formControlName="refCode" promoCode>
            </div>
        </mat-form-field>
    </div>
    <!--<p class="field-error">{{promoError}}</p> -->
    <button mat-button buttons class="btn btn2 referral-next" (click)="saveReferralCd()">Continue</button>
</overlay>
