import { Component } from '@angular/core';

import { MessageBoxService } from 'src/app/services/message-box.service';

@Component({
    selector: 'message-box',
    templateUrl: './message-box.html'
})
export class MessageBoxComponent {

    constructor (
        public messageBoxService: MessageBoxService
    ) { }

}
