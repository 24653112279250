import { Pipe, PipeTransform, Injectable, Injector } from '@angular/core';
import { ParameterService } from '../services/parameter.service';

@Pipe({
  name: 'policyDetailsErrMsg'
})
export class PolicyDetailsErrMsgPipe implements PipeTransform {

  private parameterService!: ParameterService;

  constructor(private injector: Injector) {
    this.parameterService = this.injector.get(ParameterService);
  }

  transform(value: string): string {
    const query = this.parameterService.paramV("POL_VALIDATIONS_DTLS_ERR_MSG_LINKED_WORD");

    if(!query || !value) {
      return value;
    }

    const index = value.toLowerCase().indexOf(query.toLowerCase());

    if(index === -1) {
      return value;
    }

    const contactUsLink = this.parameterService.paramV("POL_VALIDATION_DTLS_ERR_MSG_LINK");
    const highlightedValue = value.substr(index, query.length);
    const newValue = value.replace(highlightedValue, `<a href=${contactUsLink}>${highlightedValue}</a>`);

    return newValue;
  }

}
