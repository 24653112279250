<section id="faq">
  <div class="container"> 
    <div class="faq-top">
      <div class="faq-big-title">
        <div *ngIf="faqTitle">{{faqTitle}}</div>
        <div>{{faqTitle2}}</div>
      </div>
      <div class="faq-top-button">
        <button class="btn btn3" (click)="gotoPlan()" *ngIf="faqTitle && lineCd !== 'paytRenewal'">Avail Now</button>
        <button class="btn btn3" (click)="gotoPay()" *ngIf="faqTitle && lineCd == 'paytRenewal'">Pay Now</button>
        <button class="btn btn4" (click)="goBack()">Back</button>
      </div>
    </div>   
      <mat-accordion class="faq-box" multi>
      <ng-container  *ngIf="!faqTitle">
        <div class="intro-text">
            <pre class="justify" [innerHTML]="introText"></pre>
        </div>
        <mat-divider class="divider"></mat-divider>
      </ng-container>
      <ng-container *ngFor="let data of faqContent; index as i;">
        <mat-expansion-panel class="mat-elevation-z3">
          <mat-expansion-panel-header class="margin question">
            <mat-panel-title class="bold">
              {{faqContent[i]!.question}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <pre class="answer justify" [innerHTML]="faqContent[i]!.answer"></pre>
        </mat-expansion-panel>
      </ng-container>
      <mat-divider class="divider"></mat-divider>
      <div class="disclaimer">
        {{'Disclaimer'}}
      </div>
      <div class="disclaimer-content">
        <pre class="justify" [innerHTML]="disclaimer"></pre>
      </div>
    </mat-accordion>
  </div>
</section>