import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';

@Component({
    selector: 'stepper-dtl',
    templateUrl: './stepper-dtl.html'
})
export class StepperDetailComponent implements OnInit {

    public btnCount: number = 0;

    constructor (
      	public stepperDtlService: StepperDtlService,
      	private router: Router
    ) {
        stepperDtlService.showStepNo = true;
    }

    public ngOnInit(): void {
        this.btnCount = this.stepperDtlService.stepperDtlBtns.filter((btn: any) =>
            [undefined, "true"].indexOf(btn.isShow) >= 0
        ).length;
    }

    public getCustomClass(): string {
        let customClass = "";
        if (this.router.url === "/quotation/choose-plan") {
            customClass = "plan-form";
        } else if (this.router.url === "/policy/summary") {
            customClass = "summary";
        }

        return customClass;
    }

}