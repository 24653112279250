<section id="introduction">
    <div class="container proxima">
        <div class="intro-big-title">
            {{ introBigTitle }}
        </div>
        <div class="intro-box">
            <div class="intro-pic">
                <img [src]=" './assets/images/quotation/introduction/' + imagePath">
            </div>
            <div class="intro-content">
                <div class="intro-title top-border border4" [innerHTML]="introTitle"></div>
                <div class="intro-text" [innerHTML]="introText"></div>
                <form [formGroup]="form" *ngIf="productInfo.lineCd === 'HA' && showLangSelection === 'Y'">
                    <div class="form bottom-border">
                        <div class="field required">
                            <label class="rdo-label">Choose preferred language</label>
                            <mat-radio-group formControlName="language" class="radio-group">
                                <mat-radio-button class="radio-button" *ngFor="let lang of langSelections" [value]="lang.value">
                                    <p class="radio-lbl">{{ lang.text }}</p>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="form-btns style-1">
            <mat-checkbox *ngIf="(userDetailsService.accountType && userDetailsService.accountType !== 'P') && productInfo.lineCd !== 'HA'" (change)="doNotShowAgain = $event.checked">
                Do not show again.
            </mat-checkbox>
            <button class="btn btn2" (click)="move()">All good. Let's proceed.</button>
        </div>
    </div>
</section>