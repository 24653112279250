<section id="dashboard">
    <div class="container montserrat">
            <div class="section-title">
                <h2>Dashboard</h2>
            </div>            
            <mat-tab-group animationDuration="500ms" mat-align-tabs="start" (selectedIndexChange)="selectedTab($event)" [(selectedIndex)]="selectedIndex">
                <mat-tab label="APPLICATIONS" [disabled]="isLoading">
                    <form [formGroup]="dashboardForm">
                    <div class="card-options">
                        <div class="non-sp">
                            <div class="card top-border border2" (click)="setStatus('allRecords')" [class.selected]="dashboardForm.controls['status'].value === 'allRecords'">
                                <h3>All Records</h3>
                                <p>{{allCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setStatus('pendingQuotations')" [class.selected]="dashboardForm.controls['status'].value === 'pendingQuotations'">
                                <h3>Pending Quotations</h3>
                                <p>{{pendingQuotationCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setStatus('pendingPolicies')" [class.selected]="dashboardForm.controls['status'].value === 'pendingPolicies'">
                                <h3>Pending Policies</h3>
                                <p>{{pendingPolicyCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setStatus('cancelled')" [class.selected]="dashboardForm.controls['status'].value === 'cancelled'">
                                <h3>Cancelled</h3>
                                <p>{{cancelledCount | number}}</p>
                            </div>
                            <div class="card top-border border3" (click)="setStatus('completed')" [class.selected]="dashboardForm.controls['status'].value === 'completed'">
                                <h3>Completed</h3>
                                <p>{{completeCount | number}}</p>
                            </div>
                        </div>
                        <div class="sp">
                            <ul (click)="showOptions = !showOptions">
                                <li *ngIf="dashboardForm.controls['status'].value === 'allRecords'">
                                    <p>{{allCount | number}}</p>
                                    All Records
                                </li>
                                <li *ngIf="dashboardForm.controls['status'].value === 'pendingQuotations'">
                                    <p>{{pendingQuotationCount | number}}</p>
                                    Pending Quotations
                                </li>
                                <li *ngIf="dashboardForm.controls['status'].value === 'pendingPolicies'">
                                    <p>{{pendingPolicyCount | number}}</p>
                                    Pending Policies
                                </li>
                                <li *ngIf="dashboardForm.controls['status'].value === 'cancelled'">
                                    <p>{{cancelledCount | number}}</p>
                                    Cancelled
                                </li>
                                <li *ngIf="dashboardForm.controls['status'].value === 'completed'">
                                    <p>{{completeCount | number}}</p>
                                    Completed
                                </li>
                                <ng-container *ngIf="showOptions">
                                    <li class="options">
                                        <ul>
                                            <li (click)="setStatus('allRecords')">
                                                <p>{{allCount | number}}</p>
                                                All Records
                                            </li>
                                            <li (click)="setStatus('pendingQuotations')">
                                                <p>{{pendingQuotationCount | number}}</p>
                                                Pending Quotations
                                            </li>
                                            <li (click)="setStatus('pendingPolicies')">
                                                <p>{{pendingPolicyCount | number}}</p>
                                                Pending Policies
                                            </li>
                                            <li (click)="setStatus('cancelled')">
                                                <p>{{cancelledCount | number}}</p>
                                                Cancelled
                                            </li>
                                            <li (click)="setStatus('completed')">
                                                <p>{{completeCount | number}}</p>
                                                Completed
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="applications">
                        <div class="search-bar" [class.no-sort]="userDetailsService.accountType !== 'P'">
                            <div class="search-menu">
                                <div class="search">
                                    <div class="icon"></div>
                                    <mat-form-field appearance="fill">
                                        <input matInput [placeholder]="getSearchPlaceholder()" (keydown.enter)="updateProductList('search')" formControlName="search">
                                    </mat-form-field>
                                </div>
                                <div class="table-menu-icon" (click)="showTableMenu = !showTableMenu"></div>
                            </div>
                            <div class="table-menu" [class.show]="showTableMenu">
                                <div class="filter">
                                    <label>Filter By</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select formControlName="filterBy" (selectionChange)="updateProductList('filter')">
                                            <mat-option value="all">All</mat-option>
                                            <mat-option value="name">Name</mat-option>
                                            <mat-option value="product">Product</mat-option>
                                            <mat-option value="quotationNo">Quotation No.</mat-option>
                                            <mat-option value="policyNo">Policy No.</mat-option>
                                            <ng-container *ngIf="userDetailsService.accountType === 'P'">
                                                <mat-option value="lastModified">Last Modified</mat-option>
                                            </ng-container>
                                            <mat-option value="dateCreated">Date Created</mat-option>
                                            <ng-container *ngIf="userDetailsService.accountType !== 'P'">
                                                <mat-option value="dateCompleted">Date Completed</mat-option>
                                            </ng-container>
                                            <mat-option value="status">Status</mat-option>
                                            <ng-container *ngIf="userDetailsService.accountType !== 'P'">
                                                <mat-option value="agentCd">Agent</mat-option>
                                                <mat-option value="bmaCd">BMA</mat-option>
                                                <ng-container *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0 ">
                                                    <mat-option value="referror">Referror</mat-option>
                                                </ng-container>
                                            </ng-container>

                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="sort" *ngIf="userDetailsService.accountType === 'P'">
                                    <label>Sort By</label>
                                    <div class="fields">
                                        <mat-form-field appearance="fill">
                                            <mat-select formControlName="sortBy" (selectionChange)="updateProductList('sortBy')">
                                                <mat-option value="name">Name</mat-option>
                                                <mat-option value="product">Product</mat-option>
                                                <mat-option value="quotationNo">Quotation No.</mat-option>
                                                <mat-option value="policyNo">Policy No.</mat-option>
                                                <mat-option value="lastModified">Last Modified</mat-option>
                                                <mat-option value="dateCreated">Date Created</mat-option>
                                                <mat-option  value="status">Status</mat-option>
                                                <mat-option *ngIf="userDetailsService.accountType !== 'P'" value="agentCd">Agent</mat-option>
                                                <mat-option *ngIf="userDetailsService.accountType !== 'P'" value="bmaCd">BMA</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-select formControlName="sortDir" (selectionChange)="updateProductList('sortDir')">
                                                <mat-option value="asc">Ascending</mat-option>
                                                <mat-option value="desc">Descending</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="records">
                            <!-- NON-PUBLIC VIEW -->
                            <div class="table" *ngIf="userDetailsService.accountType !== 'P'">
                                <div class="custom-table-2">
                                    <ul [class.loading]="this.isLoading">
                                        <li class="header">
                                            <div class="non-sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                <p (click)="sort('name')" [class.sort]="dashboardForm.controls['sortBy'].value === 'name'" [class]="dashboardForm.controls['sortDir'].value" sticky>Name</p>
                                                <p (click)="sort('product')" [class.sort]="dashboardForm.controls['sortBy'].value === 'product'" [class]="dashboardForm.controls['sortDir'].value">Products</p>
                                                <p (click)="sort('quotationNo')" [class.sort]="dashboardForm.controls['sortBy'].value === 'quotationNo'" [class]="dashboardForm.controls['sortDir'].value">Quotation No.</p>
                                                <p (click)="sort('policyNo')" [class.sort]="dashboardForm.controls['sortBy'].value === 'policyNo'" [class]="dashboardForm.controls['sortDir'].value">Policy No.</p>
                                                <p (click)="sort('dateCreated')" [class.sort]="dashboardForm.controls['sortBy'].value === 'dateCreated'" [class]="dashboardForm.controls['sortDir'].value">Date Created</p>
                                                <p (click)="sort('dateCompleted')" [class.sort]="dashboardForm.controls['sortBy'].value === 'dateCompleted'" [class]="dashboardForm.controls['sortDir'].value">Date Completed</p>
                                                <p (click)="sort('status')" [class.sort]="dashboardForm.controls['sortBy'].value === 'status'" [class]="dashboardForm.controls['sortDir'].value" class="bold status">Status</p>
                                                <p *ngIf="userDetailsService.accountType !== 'P'" (click)="sort('agentCd')" [class.sort]="dashboardForm.controls['sortBy'].value === 'agentCd'" [class]="dashboardForm.controls['sortDir'].value">Agent</p>
                                                <p *ngIf="userDetailsService.accountType !== 'P'" (click)="sort('bmaCd')" [class.sort]="dashboardForm.controls['sortBy'].value === 'bmaCd'" [class]="dashboardForm.controls['sortDir'].value">BMA</p>
                                                <p (click)="sort('referror')" [class.sort]="dashboardForm.controls['sortBy'].value === 'referror'" [class]="dashboardForm.controls['sortDir'].value" class="referror" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">Referror</p>
                                                <p class="button" sticky></p>
                                            </div>
                                        </li>
                                        <ng-container *ngIf="list.length === 0">
                                            <div class="card no-record">
                                                <p *ngIf="!this.isLoading">No Record Found</p>
                                                <div class="loader" *ngIf="this.isLoading">
                                                    <!-- <div class="spinner">
                                                        <div></div>
                                                        <div></div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="list.length !== 0">
                                            <li class="body" *ngFor="let l of list">
                                                <div class="non-sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                    <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0" sticky>
                                                        {{(l.name || '-') | slice:0:28}}<ng-container *ngIf="l.name?.length > 28">...</ng-container>
                                                    </p>
                                                    <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0" sticky>
                                                        {{(l.name || '-') | slice:0:24}}<ng-container *ngIf="l.name?.length > 24">...</ng-container>
                                                    </p>
                                                    <p>{{l.product}}</p>
                                                    <p>{{l.quotationNo}}</p>
                                                    <p>{{l.policyNo}}</p>
                                                    <p>{{l.dateCreated}}</p>
                                                    <p>{{l.dateCompleted}}</p>
                                                    <p class="status" [class.pending]="[4, 9].indexOf(l.quoteStatCd) < 0">{{l.status}}</p>
                                                    <p>{{l.agentCd}}</p>
                                                    <p>{{l.bmaCd}}</p>
                                                    <p class="referror" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                        {{l.referror | slice:0:35}}<ng-container *ngIf="l.referror?.length > 35">...</ng-container>
                                                    </p>
                                                    <p><button class="btn pad-cancel" [class.btn4]="[1, 3].indexOf(l.quoteStatCd) == -1" [class.btn6]="[1, 3].indexOf(l.quoteStatCd) > -1" (click)="setCancelVar(l)">Cancel Quote</button></p>
                                                    <p></p>
                                                    <p class="referror" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0"></p>
                                                    <p class="button" sticky><button class="btn btn6" (click)="editViewProduct(l.quoteId)">View</button></p>
                                                </div>
                                                <div class="sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                    <div>
                                                        <p sticky>Name</p>
                                                        <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                            {{(l.name || '-') | slice:0:28}}<ng-container *ngIf="l.name?.length > 28">...</ng-container>
                                                        </p>
                                                        <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0" sticky>
                                                            {{(l.name || '-') | slice:0:24}}<ng-container *ngIf="l.name?.length > 24">...</ng-container>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>Product</p>
                                                        <p>{{l.product}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Quotation No.</p>
                                                        <p>{{l.quotationNo || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Policy No.</p>
                                                        <p>{{l.policyNo || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Date Created</p>
                                                        <p>{{l.dateCreated}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Date Completed</p>
                                                        <p>{{l.dateCompleted || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Status</p>
                                                        <p class="status" [class.pending]="[4, 9].indexOf(l.quoteStatCd) < 0">{{l.status}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Agent</p>
                                                        <p>{{l.agentCd || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>BMA</p>
                                                        <p>{{l.bmaCd || '-'}}</p>
                                                    </div>
                                                    <div *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                        <p>Referror</p>
                                                        <p>
                                                            {{(l.referror || '-') | slice:0:20}}<ng-container *ngIf="l.referror?.length > 20">...</ng-container>
                                                        </p>
                                                    </div>
                                                    <div *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                        <p></p>
                                                        <p></p>
                                                    </div>
                                                    <div class="button no-left" [class.agent-mobile-div]="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                        <button class="btn" [class.btn4]="[1, 3].indexOf(l.quoteStatCd) == -1" [class.btn6]="[1, 3].indexOf(l.quoteStatCd) > -1" (click)="setCancelVar(l)" [class.agent-mobile-view]="['B', 'E'].indexOf(userDetailsService.accountType) < 0">Cancel Quote</button>
                                                    </div>
                                                    <div class="button" [class.agent-mobile-div]="['B', 'E'].indexOf(userDetailsService.accountType) < 0" sticky>
                                                        <button class="btn btn6" [class.agent-mobile-view]="['B', 'E'].indexOf(userDetailsService.accountType) < 0" (click)="editViewProduct(l.quoteId)">View</button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>

                            <!-- PUBLIC VIEW -->
                            <div class="cards" *ngIf="userDetailsService.accountType === 'P'">
                                <ng-container *ngIf="list.length === 0">
                                    <div class="card no-record">
                                        <p *ngIf="!this.isLoading">No Record Found</p>
                                        <div class="loader" *ngIf="this.isLoading">
                                            <!-- <div class="spinner">
                                                <div></div>
                                                <div></div>
                                            </div> -->
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="list.length !== 0">
                                    <div class="card" *ngFor="let l of list">
                                        <p>Quotation: <span>{{l.quotationNo || '-'}}</span></p>
                                        <p>Policy: <span>{{l.policyNo || '-'}}</span></p>
                                        <p>{{l.name || '-'}}</p>
                                        <p>{{l.product}}</p>
                                        <div class="date">
                                            <p>Last Modified</p>
                                            <p>{{l.lastModified}}</p>
                                        </div>
                                        <div class="status" [class.pending]="[4, 9].indexOf(l.quoteStatCd) < 0">
                                            <p>Status</p>
                                            <p>{{l.status}}</p>
                                        </div>
                                        <button class="btn" (click)="editViewProduct(l.quoteId)" [class.btn2]="[4, 9, 7].indexOf(l.quoteStatCd) < 0" [class.btn3]="[4, 9, 7].indexOf(l.quoteStatCd) >= 0">
                                            {{[4, 9, 7].indexOf(l.quoteStatCd) < 0 ? 'Continue' : 'View Details'}}
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="paginator">
                            <div>
                                <p>Showing {{totalRecords > 0 ? ((dashboardForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10)) - (userDetailsService.accountType === 'P' ? 3 : 10)) + 1 : 0}} - {{totalRecords > 0 ? (dashboardForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10) > totalRecords ? totalRecords : (dashboardForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10))) : 0}} of {{totalRecords}}</p>
                            </div>
                            <div>
                                <div class="left" (click)="goToPage('back')"></div>
                                <label>Page</label>
                                <mat-form-field appearance="fill">
                                    <mat-select formControlName="pageNo" (selectionChange)="goToPage()">
                                        <mat-option *ngFor="let in of counter(totalPages); let i = index" [value]="i+1">
                                            {{i+1}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <label>of {{totalPages}}</label>
                                <div class="right" (click)="goToPage('next')"></div>
                            </div>
                        </div>
                    </div>
                    
                </form>
                </mat-tab>

                <!-- Renewal Dashboard -->
                <mat-tab  label="RENEWAL" *ngIf="userDetailsService.accountType !== 'P'"  [disabled]="isLoading">
                    <form [formGroup]="renewalForm">
                    <div class="card-options" id="renewal-cards">
                        <div class="non-sp pad-bot">
                            <div class="card top-border border2" (click)="setRenewalStatus('allRenewal')" [class.selected]="renewalForm.controls['status'].value === 'allRenewal'">
                                <h3>All Records</h3>
                                <p>{{allRenewalCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setRenewalStatus('renewal')" [class.selected]="renewalForm.controls['status'].value === 'renewal'">
                                <h3>For Renewal</h3>
                                <p>{{renewalCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setRenewalStatus('sentNotice')" [class.selected]="renewalForm.controls['status'].value === 'sentNotice'">
                                <h3>Sent Renewal Notice</h3>
                                <p>{{sentNoticeCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setRenewalStatus('expiredRenewal')" [class.selected]="renewalForm.controls['status'].value === 'expiredRenewal'">
                                <h3>Expired</h3>
                                <p>{{expiredRenewalCount | number}}</p>
                            </div>
                            <div class="card top-border border1" (click)="setRenewalStatus('cancelledRenewal')" [class.selected]="renewalForm.controls['status'].value === 'cancelledRenewal'">
                                <h3>Cancelled</h3>
                                <p class="red">{{cancelledRenewalCount | number}}</p>
                            </div>
                        </div>
                        <div class="non-sp pad-top">
                            <div class="card top-border border1 fixed-width" (click)="setRenewalStatus('renewedWithoutPay')" [class.selected]="renewalForm.controls['status'].value === 'renewedWithoutPay'">
                                <h3>Renewed(Without Payment)</h3>
                                <p class="red">{{renewedWithoutPayCount | number}}</p>
                            </div>
                            <div class="card top-border border3" (click)="setRenewalStatus('renewed')" [class.selected]="renewalForm.controls['status'].value === 'renewed'">
                                <h3>Renewed</h3>
                                <p>{{renewedCount | number}}</p>
                            </div>
                        </div>
                        <div class="sp">
                            <ul (click)="showOptions = !showOptions">
                                <li *ngIf="renewalForm.controls['status'].value === 'allRenewal'">
                                    <p>{{allRenewalCount | number}}</p>
                                    All Records
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'renewal'">
                                    <p>{{renewalCount | number}}</p>
                                    For Renewal
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'sentNotice'">
                                    <p>{{sentNoticeCount | number}}</p>
                                    Sent Renewal Notice
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'expiredRenewal'">
                                    <p>{{expiredRenewalCount | number}}</p>
                                    Expired
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'cancelledRenewal'">
                                    <p>{{cancelledRenewalCount | number}}</p>
                                    Cancelled
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'renewedWithoutPay'">
                                    <p>{{renewedWithoutPayCount | number}}</p>
                                    Renewed(Without Payment)
                                </li>
                                <li *ngIf="renewalForm.controls['status'].value === 'renewed'">
                                    <p>{{renewedCount | number}}</p>
                                    Renewed
                                </li>
                                <ng-container *ngIf="showOptions">
                                    <li class="options">
                                        <ul>
                                            <li (click)="setRenewalStatus('allRenewal')">
                                                <p>{{allRenewalCount | number}}</p>
                                                All Records
                                            </li>
                                            <li (click)="setRenewalStatus('renewal')">
                                                <p>{{renewalCount | number}}</p>
                                                For Renewal
                                            </li>
                                            <li (click)="setRenewalStatus('sentNotice')">
                                                <p>{{sentNoticeCount | number}}</p>
                                                Sent Renewal Notice
                                            </li>
                                            <li (click)="setRenewalStatus('expiredRenewal')">
                                                <p>{{expiredRenewalCount | number}}</p>
                                                Expired
                                            </li>
                                            <li (click)="setRenewalStatus('cancelledRenewal')">
                                                <p>{{cancelledRenewalCount | number}}</p>
                                                Cancelled
                                            </li>
                                            <li (click)="setRenewalStatus('renewedWithoutPay')">
                                                <p>{{renewedWithoutPayCount | number}}</p>
                                                Renewed(Without Pay)
                                            </li>
                                            <li (click)="setRenewalStatus('renewed')">
                                                <p>{{renewedCount | number}}</p>
                                                Renewed
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    
                    <div class="applications">
                        <div class="search-bar" [class.no-sort]="userDetailsService.accountType !== 'P'">
                            <div class="search-menu">
                                <div class="search">
                                    <div class="icon"></div>
                                    <mat-form-field appearance="fill">
                                        <input matInput [placeholder]="getSearchRenewalPlaceholder()" (keydown.enter)="updateRenewalList('search')" formControlName="search">
                                    </mat-form-field>
                                </div>
                                <div class="table-menu-icon" (click)="showTableMenu = !showTableMenu"></div>
                            </div>
                            <div class="table-menu" [class.show]="showTableMenu">
                                <div class="filter">
                                    <label>Filter By</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select formControlName="filterBy" (selectionChange)="updateRenewalList('filter')">
                                            <mat-option value="all">All</mat-option>
                                            <mat-option value="name">Name</mat-option>
                                            <mat-option value="product">Product</mat-option>
                                            <mat-option value="policyNo">Policy No.</mat-option>
                                            <mat-option value="oldExpiryDate">Expiry Date</mat-option>
                                            <mat-option value="dateCreated">Date Created</mat-option>
                                            <mat-option value="dateCompleted">Date Completed</mat-option>
                                            <mat-option value="status">Status</mat-option>
                                            <ng-container *ngIf="userDetailsService.accountType !== 'P'">
                                                <mat-option value="agentCd">Agent</mat-option>
                                                <mat-option value="bma">BMA</mat-option>
                                                <ng-container *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0 ">
                                                    <mat-option value="referror">Referror</mat-option>
                                                </ng-container>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="sort" *ngIf="userDetailsService.accountType === 'P'">
                                    <label>Sort By</label>
                                    <div class="fields">
                                        <mat-form-field appearance="fill">
                                            <mat-select formControlName="sortBy" (selectionChange)="updateRenewalList('sortBy')">
                                                <mat-option value="name">Name</mat-option>
                                                <mat-option value="product">Product</mat-option>
                                                <mat-option value="policyNo">Policy No.</mat-option>
                                                <mat-option value="oldExpiryDate">Expiry Date</mat-option>
                                                <mat-option value="lastModified">Last Modified</mat-option>
                                                <mat-option value="dateCreated">Date Created</mat-option>
                                                <mat-option value="status">Status</mat-option>
                                                <ng-container *ngIf="userDetailsService.accountType !== 'P'">
                                                    <mat-option value="agentCd">Agent</mat-option>
                                                    <mat-option value="bma">BMA</mat-option>
                                                    <ng-container *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0 ">
                                                        <mat-option value="referror">Referror</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-select formControlName="sortDir" (selectionChange)="updateRenewalList('sortDir')">
                                                <mat-option value="asc">Ascending</mat-option>
                                                <mat-option value="desc">Descending</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="records">
                            <!-- NON-PUBLIC VIEW -->
                            <div class="table" *ngIf="userDetailsService.accountType !== 'P'">
                                <div class="custom-table-2">
                                    <ul [class.loading]="this.isLoading">
                                        <li class="header">
                                            <div class="non-sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                <p class="button" *ngIf="this.renewalForm.controls['status'].value!='allRenewal'"> P/S <mat-checkbox [disabled]="this.isLoading||renewalList.length==0" (click)="listAllReport()" id="allCheck"></mat-checkbox></p>
                                                <p (click)="renewalSort('name')" [class.sort]="renewalForm.controls['sortBy'].value === 'name'" [class]="renewalForm.controls['sortDir'].value"  class="nameBorder">Name</p>
                                                <p (click)="renewalSort('product')" [class.sort]="renewalForm.controls['sortBy'].value === 'product'" [class]="renewalForm.controls['sortDir'].value">Products</p>
                                                <p (click)="renewalSort('policyNo')" [class.sort]="renewalForm.controls['sortBy'].value === 'policyNo'" [class]="renewalForm.controls['sortDir'].value">Policy No.</p>
                                                <p (click)="renewalSort('oldExpiryDate')" [class.sort]="renewalForm.controls['sortBy'].value === 'oldExpiryDate'" [class]="renewalForm.controls['sortDir'].value">Expiry Date</p>
                                                <p (click)="renewalSort('dateCreated')" [class.sort]="renewalForm.controls['sortBy'].value === 'dateCreated'" [class]="renewalForm.controls['sortDir'].value">Date Created</p>
                                                <p (click)="renewalSort('dateCompleted')" [class.sort]="renewalForm.controls['sortBy'].value === 'dateCompleted'" [class]="renewalForm.controls['sortDir'].value">Date Completed</p>
                                                <p (click)="renewalSort('status')" [class.sort]="renewalForm.controls['sortBy'].value === 'status'" [class]="renewalForm.controls['sortDir'].value" class="bold status">Status</p>
                                                <p (click)="renewalSort('agentCd')" [class.sort]="renewalForm.controls['sortBy'].value === 'agentCd'" [class]="renewalForm.controls['sortDir'].value">Agent</p>
                                                <p (click)="renewalSort('bma')" [class.sort]="renewalForm.controls['sortBy'].value === 'bma'" [class]="renewalForm.controls['sortDir'].value">BMA</p>
                                                <p (click)="renewalSort('referror')" [class.sort]="renewalForm.controls['sortBy'].value === 'referror'" [class]="renewalForm.controls['sortDir'].value" class="referror" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">Referror</p>
                                                <p class="button"></p>
                                            </div>
                                        </li>
                                        <ng-container *ngIf="renewalList.length === 0">
                                            <div class="card no-record">
                                                <p *ngIf="!this.isLoading">No Record Found</p>
                                                <div class="loader" *ngIf="this.isLoading">
                                                    <!-- <div class="spinner">
                                                        <div></div>
                                                        <div></div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="renewalList.length !== 0">
                                            <li class="body" *ngFor="let l of renewalList">
                                                <div class="non-sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                    <p class="psCheckbox" *ngIf="this.renewalForm.controls['status'].value!='allRenewal'"><mat-checkbox [disabled]="isSendToAll" [checked]="isSendToAll" (click)="listReport(l)"></mat-checkbox></p>
                                                    <p class="name nameBorder" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                        {{(l.name || '-') | slice:0:28}}<ng-container *ngIf="l.name?.length > 28">...</ng-container>
                                                    </p>
                                                    <p class="name nameBorder" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                        {{(l.name || '-') | slice:0:24}}<ng-container *ngIf="l.name?.length > 24">...</ng-container>
                                                    </p>
                                                    <p class="centered" [class.smaller-text]="l.product?.length>22">{{l.product}}</p>
                                                    <p>{{l.policyNo}}</p>
                                                    <p>{{l.oldExpiryDate2}}</p>
                                                    <p>{{l.dateCreated}}</p>
                                                    <p>{{l.dateCompleted}}</p>
                                                    <p class="status" [class.pending]="[5].indexOf(l.polStatCd) < 0">{{l.status}}</p>
                                                    <p>{{l.agentCd}}</p>
                                                    <p>{{l.bma}}</p>
                                                    <p class="referror" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                        {{l.referror | slice:0:35}}<ng-container *ngIf="l.referror?.length > 35">...</ng-container>
                                                    </p>
                                                    <p></p>
                                                    <p class="button"><button class="btn btn6" (click)="editViewRenewal(l.policyId)">View</button></p>
                                                </div>
                                                
                                                <div class="sp" [class.w-referror]="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                    <div *ngIf="this.renewalForm.controls['status'].value!='allRenewal'">
                                                        <p class="psCheckbox" (click)="listAllReport()"><mat-checkbox  [disabled]="allRenewalList.length==0"></mat-checkbox> Print/Send</p>
                                                    </div>
                                                    <div>
                                                        <p>Name</p>
                                                        <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                            {{(l.name || '-') | slice:0:28}}<ng-container *ngIf="l.name?.length > 28">...</ng-container>
                                                        </p>
                                                        <p class="name" *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                            {{(l.name || '-') | slice:0:24}}<ng-container *ngIf="l.name?.length > 24">...</ng-container>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>Product</p>
                                                        <p>{{l.product}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Policy No.</p>
                                                        <p>{{l.policyNo || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Expiry Date</p>
                                                        <p>{{l.oldExpiryDate2}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Date Created</p>
                                                        <p>{{l.dateCreated}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Date Completed</p>
                                                        <p>{{l.dateCompleted || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Status</p>
                                                        <p class="status" [class.pending]="[5].indexOf(l.polStatCd) < 0">{{l.status}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Agent</p>
                                                        <p>{{l.agentCd || '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>BMA</p>
                                                        <p>{{l.bma || '-'}}</p>
                                                    </div>
                                                    <div *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) >= 0">
                                                        <p>Referror</p>
                                                        <p>
                                                            {{(l.referror || '-') | slice:0:20}}<ng-container *ngIf="l.referror?.length > 20">...</ng-container>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p></p>
                                                        <p></p>
                                                    </div>
                                                    <div *ngIf="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                        <p></p>
                                                        <p></p>
                                                    </div>
                                                    <div class="button" [class.agent-mobile-div]="['B', 'E'].indexOf(userDetailsService.accountType) < 0">
                                                        <button class="btn btn6" [class.agent-mobile-view]="['B', 'E'].indexOf(userDetailsService.accountType) < 0" (click)="editViewRenewal(l.policyId)">View</button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="paginator">
                            <div>
                                <p>Showing {{totalRenewalRecords > 0 ? ((renewalForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10)) - (userDetailsService.accountType === 'P' ? 3 : 10)) + 1 : 0}} - {{totalRenewalRecords > 0 ? (renewalForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10) > totalRenewalRecords ? totalRenewalRecords : (renewalForm.controls["pageNo"].value * (userDetailsService.accountType === 'P' ? 3 : 10))) : 0}} of {{totalRenewalRecords}}</p>
                            </div>
                            <div>
                                <div class="left" (click)="goToRenewalPage('back')"></div>
                                <label>Page</label>
                                <mat-form-field appearance="fill">
                                    <mat-select formControlName="pageNo" (selectionChange)="goToRenewalPage()">
                                        <mat-option *ngFor="let in of counter(totalRenewalPages); let i = index" [value]="i+1">
                                            {{i+1}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <label>of {{totalRenewalPages}}</label>
                                <div class="right" (click)="goToRenewalPage('next')"></div>
                            </div>
                        </div>
                    </div>
        
                    <div class="form-btns" *ngIf="this.renewalForm.controls['status'].value!='allRenewal'">
                        <button class="btn btn2" [disabled]="bancaList.length==0&&agencyList.length==0&&manpowerList.length==0&&renewedList.length==0" (click)="generateReport(0)"><mat-icon id="button-icon">print</mat-icon>Print</button>
                        <button class="btn btn2" [disabled]="bancaList.length==0&&agencyList.length==0&&manpowerList.length==0&&renewedList.length==0" (click)="sendMail(0)"><mat-icon id="button-icon">mail</mat-icon>Send</button>
                    </div>
                    </form>
                </mat-tab>
            </mat-tab-group>
    </div>
</section>

<overlay #cancelPol id="cancel-title" [title]="'Confirmation'">
    <div content id="cancel-overlay">
        <div id="cancel-text">Do you really want to cancel your policy?</div>
        <form [formGroup]="cancellationForm">
            <div class="form">
                <div class="col-case">
                    <div class="field">
                        <label id="cancel-reason-text">Reason for Cancellation</label><label id="cancel-red-mark">*</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput placeholder="Select Reason for cancellation" formControlName="reason" [matAutocomplete]="autoReason" (input)="setReasonViaInput($event)" autocomplete>
                            <mat-autocomplete #autoReason="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                <mat-option *ngFor="let option of filteredReasonOptions | async" [value]="option">
                                    {{option.text}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary" (click)="cancelQuotation(cancellationForm.getRawValue());cancelPol.close()">Proceed</button>
</overlay>