import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { FormService } from 'src/app/services/form.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { APICallService } from 'src/app/services/api-call.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DatePipe, formatDate } from '@angular/common';
import { RenewalService } from 'src/app/services/renewal.service';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { OverlayComponent } from '../common/overlay/overlay';
import { Observable } from 'rxjs';
import { AutocompleteService } from 'src/app/services/autocomplete.service';

@Component({
    templateUrl: './dashboard.html',
})
export class DashboardComponent {

    // Cancelation Overlay
    @ViewChild("cancelPol") cancelPol!: OverlayComponent;
    
    dashboardForm:FormGroup = this.formBuilder.group({
        status: ["allRecords"],
        search: [""],
        filterBy: ["all"],
        sortBy: ["dateCreated"],
        sortDir: ["desc"],
        pageNo: [1]
    });

    renewalForm:FormGroup = this.formBuilder.group({
        status: ["allRenewal"],
        search: [""],
        filterBy: ["all"],
        sortBy: ["dateCreated"],
        sortDir: ["desc"],
        pageNo: [1]
    });

    //para sa cancellation ng policy
    cancellationForm: FormGroup = this.formBuilder.group({
        reason: [""],
        quoteId: [""],
        rid: [""]
    });
    
    filteredReasonOptions:Observable<any>; // para sa cancellation ng policy
    validCancellationText:boolean = false; // para sa policy cancelation

    showOptions:boolean = false;
    showTableMenu:boolean = false;
    isLoading:boolean = false;

    allCount:number = 0;
    pendingQuotationCount:number = 0;
    pendingPolicyCount:number = 0;
    cancelledCount:number = 0;
    completeCount:number = 0;

    allRenewalCount:number = 0;
    renewalCount:number = 0;
    renewedWithoutPayCount:number = 0;
    sentNoticeCount:number = 0;
    expiredRenewalCount:number = 0;
    cancelledRenewalCount:number = 0;
    renewedCount:number = 0;

    list:any[] = [];
    totalRecords:number = 0;
    totalPages:number = 0;

    renewalList:any[] = [];
    allRenewalList:any[] = [];
    totalRenewalRecords:number = 0;
    totalRenewalPages:number = 0;
    ospLink:string = '';
    selectedIndex:number = 0;

    renewalAgentCd:string = '';
    smsCountryCd: any[] = [
        {
          countryCd: 23,
          BM_CODE: 'PHP',
          smsCountryCd: '63',
          prefix: '0'
        }
      ]

    constructor (
        // PUBLIC
		public formService: FormService,
        public userDetailsService: UserDetailsService,

		// PRIVATE
		private router: Router,
        private formBuilder: FormBuilder,
        private appMessageService: AppMessageService,
		private productService: ProductService,
		private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
		private pageValidationService: PageValidationService,
        private securityService: SecurityService,
        private renewalService: RenewalService,
        private messageBoxService: MessageBoxService,
        private parameterService: ParameterService,
        public autocompleteService: AutocompleteService
  	) {
        if(userDetailsService.unchangedPw == 'Y'){
            router.navigate(['/change-password'],{skipLocationChange:true});
        }
        this.pageValidationService.validatePageAccessibility();
        if(this.productService.productInfo&&this.productService.productInfo.creationProcess==='renewal'){
            this.renewalForm.controls["status"].setValue(renewalService.renewalForm.status);
            this.renewalForm.controls["search"].setValue(renewalService.renewalForm.search);
            this.renewalForm.controls["filterBy"].setValue(renewalService.renewalForm.filterBy);
            this.renewalForm.controls["sortBy"].setValue(renewalService.renewalForm.sortBy);
            this.renewalForm.controls["sortDir"].setValue(renewalService.renewalForm.sortDir);
            this.renewalForm.controls["pageNo"].setValue(renewalService.renewalForm.pageNo);
            this.selectedTab(1);
            this.selectedIndex = 1;
        }
        else{
            this.productService.setProductInfo2("");
            this.getProductList();
        }
        this.renewalAgentCd = this.parameterService.paramV("REN_AGENT_CD");
        this.filteredReasonOptions = this.autocompleteService.set(this.cancellationForm, "reason", this.getReasons()); // para sa cancellation ng policy
    }

    counter(i:number):any {
        return new Array(i);
    }

    sort(sortBy:string):void {
        if (!this.isLoading) {
            this.dashboardForm.controls["pageNo"].setValue(1);
            if (this.dashboardForm.controls["sortBy"].value === sortBy) {
                const sortDir = this.dashboardForm.controls["sortDir"].value;
                this.dashboardForm.controls["sortDir"].setValue(sortDir === "asc" ? "desc" : "asc");
            } else {
                this.dashboardForm.controls["sortBy"].setValue(sortBy);
                this.dashboardForm.controls["sortDir"].setValue("desc");
            }
            this.getProductList();
        }
    }

    setStatus(status:string):void {
        if (!this.isLoading && this.dashboardForm.controls["status"].value !== status) {
            this.dashboardForm.controls["status"].setValue(status);
            this.dashboardForm.controls["search"].setValue("");
            this.dashboardForm.controls["filterBy"].setValue("all");
            this.dashboardForm.controls["sortBy"].setValue("dateCreated");
            this.dashboardForm.controls["sortDir"].setValue("desc");
            this.dashboardForm.controls["pageNo"].setValue(1);

            this.list = [];
            this.totalRecords = 0;
            this.totalPages = 0;
            if (this.checkIfWithRecord(status)) {
                this.getProductList();
            }
        }
    }

    checkIfWithRecord(status:string):boolean {
        if (status === "allRecords" && this.allCount === 0) {
            return false;
        } else if (status === "pendingQuotations" && this.pendingQuotationCount === 0) {
            return false;
        } else if (status === "pendingPolicies" && this.pendingPolicyCount === 0) {
            return false;
        } else if (status === "cancelled" && this.cancelledCount === 0) {
            return false;
        } else if (status === "completed" && this.completeCount === 0) {
            return false;
        } else if (status === "allRenewal" && this.allRenewalCount === 0) {
            return false;
        } else if (status === "renewal" && this.renewalCount === 0) {
            return false;
        } else if (status === "renewedWithoutPay" && this.renewedWithoutPayCount === 0) {
            return false;
        } else if (status === "renewed" && this.renewedCount === 0) {
            return false;
        } else if (status === "sentNotice" && this.sentNoticeCount === 0) {
            return false;
        } else if (status === "expiredRenewal" && this.expiredRenewalCount === 0) {
            return false;
        } else if (status === "cancelledRenewal" && this.cancelledRenewalCount === 0) {
            return false;
        }

        return true;
    }

    goToPage(action:string = ""):void {
        let pageNo = this.dashboardForm.controls["pageNo"].value;
        if (!this.isLoading) {
            if (action === "back" && (pageNo - 1) >= 1) {
                this.dashboardForm.controls["pageNo"].setValue(pageNo - 1);
                this.getProductList();
            } else if (action === "next" && (pageNo + 1) <= this.totalPages) {
                this.dashboardForm.controls["pageNo"].setValue(pageNo + 1);
                this.getProductList();
            } else if (!action) {
                this.getProductList();
            }
        }
    }

    getSearchPlaceholder():string {
        const filterBy = this.dashboardForm.controls["filterBy"].value;

        let searchPlaceholder = "Search";
        if (filterBy === "name") {
            searchPlaceholder += " by name";
        } else if (filterBy === "product") {
            searchPlaceholder += " by product";
        } else if (filterBy === "quotationNo") {
            searchPlaceholder += " by quotation no.";
        } else if (filterBy === "policyNo") {
            searchPlaceholder += " by policy no.";
        } else if (filterBy === "lastModified") {
            searchPlaceholder += " by last modified";
        } else if (filterBy === "dateCreated") {
            searchPlaceholder += " by date created";
        } else if (filterBy === "dateCompleted") {
            searchPlaceholder += " by date completed";
        } else if (filterBy === "agentCd") {
            searchPlaceholder += " by agent";
        } else if (filterBy === "bmaCd") {
            searchPlaceholder += " by bma";
        } else if (filterBy === "referror") {
            searchPlaceholder += " by referror";
        } else if (filterBy === "status") {
            searchPlaceholder += " by status";
        }
        return searchPlaceholder;
    }

    updateProductList(via:string):void {
        this.dashboardForm.controls["pageNo"].setValue(1);
        if (["search", "sortBy", "sortDir"].indexOf(via) >= 0) {
            this.getProductList(true);
        } else if (via === "filter") {
            if (this.dashboardForm.controls["search"].value) {
                this.getProductList(true);
            }
        }
    }

    getProductList(enableForm:boolean = false):void {
        this.list = [];
        this.dashboardForm.disable();
        this.isLoading = true;
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getProductList(
            this.dashboardForm.getRawValue()
        ).subscribe((response:any) => {
            this.isLoading = false;
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        this.jsonDataService.contorlLoading(false);
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        this.allCount = res.dashboard.pendingQuotationCount + res.dashboard.pendingPolicyCount + res.dashboard.cancelledCount + res.dashboard.completeCount;
                        this.pendingQuotationCount = res.dashboard.pendingQuotationCount;
                        this.pendingPolicyCount = res.dashboard.pendingPolicyCount;
                        this.cancelledCount = res.dashboard.cancelledCount;
                        this.completeCount = res.dashboard.completeCount;

                        this.list = res.application.list;
                        this.totalRecords = res.application.totalRecords;
                        this.totalPages = res.application.totalPages;

                        if (enableForm || this.list.length !== 0) {
                            this.dashboardForm.enable();
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    editViewProduct(quoteId:number):void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getProductInfo({
            quoteId: quoteId
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        sessionStorage.setItem("rid", res.recordId);
                        this.productService.setProductInfo2(res);

                        // ? This sets fromLink property to null because its from dashboard
                        this.productService.setProductInfo("fromLink", null);

                        if (res.quoteStatCd === 1 || res.quoteStatCd === 2) {
                            this.productService.setProductInfo("creationProcess", "quotation");
                            this.router.navigate(["/quotation/choose-plan"]);
                        } else if (res.quoteStatCd === 3) {
                            this.productService.setProductInfo("creationProcess", "policy");

                            if (res.lineCd === "PA" || res.lineCd === "CI") {
                                this.router.navigate(["/policy/personal-details"]);
                            } else if (res.lineCd === "MC") {
                                this.router.navigate(["/policy/vehicle-details"]);
                            } else if (res.lineCd === "HA"){
                                this.router.navigate(["/policy/property-details"]);
                            }
                        } else if ([4, 8, 9].indexOf(res.quoteStatCd) >= 0) {
                            this.productService.setProductInfo("creationProcess", "policy");
                            this.router.navigate(["/policy/summary"]);
                        } else if (res.quoteStatCd === 7) {
                            this.productService.setProductInfo("creationProcess", "quotation");
                            this.router.navigate(["/quotation/review-premium-charges"]);
                        } else if (res.quoteStatCd === 5 && this.productService.productInfo?.policyId) {
                            this.productService.setProductInfo("creationProcess", "expired");
                            this.router.navigate(["/policy/summary"]);
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
      	});
    }


    selectedTab(tab:number){
        if(tab===0){
            this.getProductList();
        }
        else if(tab===1){
            this.getRenewalList();
        }
    }

    //Renewal Module    
    
    getSearchRenewalPlaceholder():string {
        const filterBy = this.renewalForm.controls["filterBy"].value;

        let searchRenewalPlaceholder = "Search";
        if (filterBy === "name") {
            searchRenewalPlaceholder += " by name";
        } else if (filterBy === "product") {
            searchRenewalPlaceholder += " by product";
        } else if (filterBy === "quotationNo") {
            searchRenewalPlaceholder += " by quotation no.";
        } else if (filterBy === "policyNo") {
            searchRenewalPlaceholder += " by policy no.";
        } else if (filterBy === "lastModified") {
            searchRenewalPlaceholder += " by last modified";
        } else if (filterBy === "oldExpiryDate") {
            searchRenewalPlaceholder += " by expiry date";
        } else if (filterBy === "dateCreated") {
            searchRenewalPlaceholder += " by date created";
        } else if (filterBy === "dateCompleted") {
            searchRenewalPlaceholder += " by date completed";
        } else if (filterBy === "agentCd") {
            searchRenewalPlaceholder += " by agent";
        } else if (filterBy === "bma") {
            searchRenewalPlaceholder += " by bma";
        } else if (filterBy === "referror") {
            searchRenewalPlaceholder += " by referror";
        } else if (filterBy === "status") {
            searchRenewalPlaceholder += " by status";
        }
        return searchRenewalPlaceholder;
    }

    updateRenewalList(via:string):void {
        this.renewalForm.controls["pageNo"].setValue(1);
        if (["search", "sortBy", "sortDir"].indexOf(via) >= 0) {
            this.getRenewalList(true);
        } else if (via === "filter") {
            if (this.renewalForm.controls["search"].value) {
                this.getRenewalList(true);
            }
        }
    }

    renewalSort(sortBy:string):void {
        if (!this.isLoading) {
            this.renewalForm.controls["pageNo"].setValue(1);
            if (this.renewalForm.controls["sortBy"].value === sortBy) {
                const sortDir = this.renewalForm.controls["sortDir"].value;
                this.renewalForm.controls["sortDir"].setValue(sortDir === "asc" ? "desc" : "asc");
            } else {
                this.renewalForm.controls["sortBy"].setValue(sortBy);
                this.renewalForm.controls["sortDir"].setValue("desc");
            }
            this.getRenewalList();
        }
    }
    
    goToRenewalPage(action:string = ""):void {
        let pageNo = this.renewalForm.controls["pageNo"].value;
        if (!this.isLoading) {
            if (action === "back" && (pageNo - 1) >= 1) {
                this.renewalForm.controls["pageNo"].setValue(pageNo - 1);
                this.getRenewalList();
            } else if (action === "next" && (pageNo + 1) <= this.totalRenewalPages) {
                this.renewalForm.controls["pageNo"].setValue(pageNo + 1);
                this.getRenewalList();
            } else if (!action) {
                this.getRenewalList();
            }
        }
    }

    setRenewalStatus(status:string):void {
        if (!this.isLoading && this.renewalForm.controls["status"].value !== status) {
            this.renewalForm.controls["status"].setValue(status);
            this.renewalForm.controls["search"].setValue("");
            this.renewalForm.controls["filterBy"].setValue("all");
            this.renewalForm.controls["sortBy"].setValue("dateCreated");
            this.renewalForm.controls["sortDir"].setValue("desc");
            this.renewalForm.controls["pageNo"].setValue(1);

            this.renewalService.renewalForm.status = this.renewalForm.controls["status"].value;
            this.renewalService.renewalForm.search = this.renewalForm.controls["search"].value;
            this.renewalService.renewalForm.filterBy = this.renewalForm.controls["filterBy"].value;
            this.renewalService.renewalForm.sortBy = this.renewalForm.controls["sortBy"].value;
            this.renewalService.renewalForm.sortDir = this.renewalForm.controls["sortDir"].value;
            this.renewalService.renewalForm.pageNo = this.renewalForm.controls["pageNo"].value;

            this.renewalList = [];
            this.bancaList = [];
            this.agencyList = [];
            this.manpowerList = [];
            this.renewedList = [];
            this.totalRenewalRecords = 0;
            this.totalRenewalPages = 0;
            if (this.checkIfWithRecord(status)) {
                this.getRenewalList();
            }
            
        }
    }

    getRenewalList(enableForm:boolean = false):void {
        this.renewalList = [];
        this.renewalForm.disable();
        this.isLoading = true;
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getRenewalList(
            this.renewalForm.getRawValue()
        ).subscribe((response:any) => {
            this.isLoading = false;
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        this.allRenewalCount = res.renewals.renewalCount + res.renewals.renewedWithoutPayCount + res.renewals.renewedCount + res.renewals.sentNoticeCount + res.renewals.expiredRenewalCount;
                        this.renewalCount = res.renewals.renewalCount;
                        this.renewedWithoutPayCount = res.renewals.renewedWithoutPayCount;
                        this.renewedCount = res.renewals.renewedCount;
                        this.sentNoticeCount = res.renewals.sentNoticeCount;
                        this.expiredRenewalCount = res.renewals.expiredRenewalCount;
                        this.cancelledRenewalCount = res.renewals.cancelledRenewalCount;
                        this.renewalList = res.renewalApplication.renewalList;
                        this.allRenewalList = res.renewalApplication.allRenewalList;
                        this.totalRenewalRecords = res.renewalApplication.totalRenewalRecords;
                        this.totalRenewalPages = res.renewalApplication.totalRenewalPages;
                        this.ospLink = res.renewalApplication.ospLink;

                        if (enableForm || this.renewalList.length !== 0) {
                            this.renewalForm.enable();
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    editViewRenewal(policyId:number):void {
        const productInfo = this.renewalList.filter((a: any) => a.policyId == policyId)[0];
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getRenewalInfo({
            policyId: policyId
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        sessionStorage.setItem("rid", res.recordId);
                        this.renewalService.renewalForm.status = this.renewalForm.controls["status"].value;
                        this.renewalService.renewalForm.search = this.renewalForm.controls["search"].value;
                        this.renewalService.renewalForm.filterBy = this.renewalForm.controls["filterBy"].value;
                        this.renewalService.renewalForm.sortBy = this.renewalForm.controls["sortBy"].value;
                        this.renewalService.renewalForm.sortDir = this.renewalForm.controls["sortDir"].value;
                        this.renewalService.renewalForm.pageNo = this.renewalForm.controls["pageNo"].value;
                        
                        //Check Report Type
                        if (productInfo.polStatCd === 5) {
                            res.reportName = "BMR002";
                        } else {
                            if(productInfo.clientCd=='BPIMS'){
                                res.reportName = "BMR011";
                            }
                            else {
                                if(productInfo.agentCd==this.renewalAgentCd){
                                    res.reportName = 'BMR008';
                                }
                                else{
                                    res.reportName = 'BMR010';
                                }
                            }
                        }

                        if(res.paymentInfo.tranNo!=''&&res.paymentInfo.paymentMode!=''&&res.paymentInfo.paytDate!=''&&res.polStatCd!=5){
                            res.polStatCd = 5;
                            res.polStatDesc = this.jsonDataService.data.refCds.filter((ref: any) => ref.rvLowValue == 5 && ref.rvDomain == 'BMP011.POL_STAT_CD')[0]?.rvMeaning;
                            this.apiCallService.updatePolicyStatus({
                                policyId: policyId,
                                newStatus: 5
                            }).subscribe((response:any) => {
                                this.securityService.checkRequestKeyResponse(response, () => {
                                if (response.status === "SUCCESS") {
                                    this.jsonDataService.contorlLoading(false);
                                    this.productService.setProductInfo2(res);
                                    this.productService.setProductInfo("creationProcess", "renewal");
                                    this.router.navigate(["/policy/summary"]);
                                }
                                else{
                                    this.jsonDataService.contorlLoading(false);
                                    this.productService.setProductInfo2(res);
                                    this.productService.setProductInfo("creationProcess", "renewal");
                                    this.router.navigate(["/policy/summary"]);
                                }
                                });
                            });
                        }
                        else{
                        this.jsonDataService.contorlLoading(false);
                        this.productService.setProductInfo2(res);
                        this.productService.setProductInfo("creationProcess", "renewal");
                        this.router.navigate(["/policy/summary"]);
                        }
                           
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
      	});
    }

    encryptedFolderName:string = '';
    pdfUrl: any = '';
    numReloader: number = 0;
    reportList:any[] = ["BMR008","BMR010","BMR011","BMR002"];
    bancaList:any[] = [];
    agencyList:any[] = [];
    manpowerList:any[] = [];
    renewedList:any[] = [];
    isSendToAll:any = false;
    isListing:boolean = false;
    
    randomizer(): number {
        let num: number = Math.floor((Math.random() * 100) + 1);
        while (true) {
            if (num == this.numReloader) {
                num = Math.floor((Math.random() * 100) + 1);
            } else {
                this.numReloader = num;
                break;
            }
        }
        return num;
    }
    
    listAllReport():void{
        this.renewedList = [];
        this.bancaList = [];
        this.manpowerList = [];
        this.agencyList = [];
        if(this.isSendToAll==false&&this.isListing==false&&this.isLoading==false){
            this.isSendToAll = true;
            this.isListing  =true;
            this.allRenewalList.forEach((e:any)=>{
                if(e.polStatCd==5){
                    this.renewedList.push(e.policyId);
                }
                else{
                    if(e.clientCd=='BPIMS'){
                        this.manpowerList.push(e.policyId);
                    }
                    else {
                        if(e.agentCd==this.renewalAgentCd){
                            this.bancaList.push(e.policyId);
                        }
                        else{
                            this.agencyList.push(e.policyId);
                        }
                    }
                }
            });
            this.isListing = false;
        }
        else if(this.isSendToAll==true){
            this.isSendToAll = false;
        }
    }

    listReport(item:any){
        if(this.isSendToAll==false&&this.isListing==false){
            if(item.polStatCd==5){
                const index = this.renewedList.indexOf(item.policyId);
                if (index > -1) {
                    this.renewedList.splice(index, 1);
                }
                else{
                    this.renewedList.push(item.policyId);
                }
            }
            else{
                if(item.clientCd=='BPIMS'){
                    const index = this.manpowerList.indexOf(item.policyId);
                    if (index > -1) {
                        this.manpowerList.splice(index, 1);
                    }
                    else{
                        this.manpowerList.push(item.policyId);
                    }
                }
                else {
                    if(item.agentCd==this.renewalAgentCd){
                        const index = this.bancaList.indexOf(item.policyId);
                        if (index > -1) {
                            this.bancaList.splice(index, 1);
                        }
                        else{
                            this.bancaList.push(item.policyId);
                        }
                    }
                    else{
                        const index = this.agencyList.indexOf(item.policyId);
                        if (index > -1) {
                            this.agencyList.splice(index, 1);
                        }
                        else{
                            this.agencyList.push(item.policyId);
                        }
                    }
                }
            }
        }
    }

    generateReport(index:number){
        if(index<this.reportList.length){
            const element = this.reportList[index];

            let list = [];
            if(this.renewedList.length!=0&&element=="BMR002"){
                list = this.renewedList;
            }
            else if(this.bancaList.length!=0&&element=="BMR008"){
                list = this.bancaList;
            }
            else if(this.agencyList.length!=0&&element=="BMR010"){
                list = this.agencyList;
            }
            else if(this.manpowerList.length!=0&&element=="BMR011"){
                list = this.manpowerList;
            }
            else {
                this.generateReport(index+1);
            }
            if(list.length>0){
                this.jsonDataService.contorlLoading(true);
                this.proceedGenerateReport(list, index, 0, element);
            }
        }
    }
    proceedGenerateReport(list:any, reportIndex:number, index:number, element:any) {
        const id = list[index];
        this.apiCallService.printPDF({
            reportType: element,
            policyId: id
        }).subscribe((res:any) => {
            if (res.status === 'SUCCESS') {
                let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                let link = response.link.split("/");
                this.encryptedFolderName = response.link.split("/")[3];
                link[3] = encodeURIComponent(link[3]);
                link = link.join("/");

                const bssid = sessionStorage.getItem("bssid") || "1";
                const encryptedFolderName = link.split("/")[3];
                const token = encryptedFolderName.substr(30, 1) + bssid.substr(30, 1) +
                            encryptedFolderName.substr(10, 1) + bssid.substr(10, 1) +
                            encryptedFolderName.substr(40, 1) + bssid.substr(40, 1) +
                            encryptedFolderName.substr(15, 1) + bssid.substr(15, 1) +
                            encryptedFolderName.substr(35, 1) + bssid.substr(35, 1) +
                            encryptedFolderName.substr( 5, 1) + bssid.substr( 5, 1) +
                            encryptedFolderName.substr(25, 1) + bssid.substr(25, 1) +
                            encryptedFolderName.substr(20, 1) + bssid.substr(20, 1);
                            
                const param = this.jsonDataService.encrypt({
                    uid: sessionStorage.getItem("bssid") || "",
                    token: token,
                    dateCreated: new Date()
                });
            
                link = link.split("/");
                link[4] = link[4].split(".")[0] + " - " + id + ".pdf";
                link = link.join("/");

                this.pdfUrl = `https://${link}?v=${this.randomizer()}&report=${param}`;
                window.open(this.pdfUrl);
                
                this.jsonDataService.contorlLoading(false);
                if(index < list.length-1){
                    this.proceedGenerateReport(list,reportIndex, index+1, element);
                }
                else{
                    if(reportIndex < this.reportList.length){
                        this.generateReport(reportIndex+1);
                    }
                }
            } else {
                this.appMessageService.showAppMessage("There's something wrong getting the report. Please contact BPI MS for more information.", "error");
            }
        });
    }
        
    commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = control.value?.split(',').map((e: any) => e.trim());
        const forbidden = emails?.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { 'email': { value: control.value } } : null;
    };
    
    emailForm = this.formBuilder.group({
        recipient: ['', [Validators.required, this.commaSepEmail]],
        cc: ['', this.commaSepEmail],
        subject: ['', [Validators.required]],
        emailContent: ['', [Validators.required]],
    });

    emailSent: boolean = false;
    datePipe = new DatePipe('en-us');
    responseMessage:string = '';

    proceedSentMail(list:any, reportIndex:number, elementIndex:number, element:string, emailType:number){
        if(reportIndex<this.reportList.length){
            const id = list[elementIndex];
            this.apiCallService.printPDF({
                reportType: element,
                policyId: id
            }).subscribe((res:any) => {
                if (res.status === 'SUCCESS') {
                    let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                    const folderName = response.link.split("/")[3];
                    const productInfo = this.allRenewalList.filter((a: any) => a.policyId == id)[0];
                    
                    const policyNumber = productInfo.policyNo;
                    const oldExpiryDate = this.datePipe.transform(new Date(productInfo.oldExpiryDate), 'MMMM d, YYYY');
                    const polNo = 'XXXX' + policyNumber.substring(policyNumber.length-4, policyNumber.length);
                    const assdFirstName = productInfo.assdFirstName;
                    const emailAddress = productInfo.assdEmailAddress;
                    const paytRefNo = productInfo.paytRefNo;
                    // var startLink = '<div style="display:flex"><div style="user-select:none; display:inline-flex; background:#B11116; padding:10px; border-radius:10px; font-weight:bold"><a style="text-decoration:none"><span style="color:#FFF">';
                    // var endLink = '</span></a></div></div>';
                    var pLink = '<div style="display:flex; padding-left:20%; padding-right:20%">P_REN_LINK</div>';
                    
                    // LINK
                    const encryptedParams = this.jsonDataService.encrypt({
                        policyId: productInfo.policyId,
                        requestId: productInfo.requestId
                    });
                    // const link = this.ospLink + '/renewal?params=' + `${btoa(encryptedParams)}`;
                    const link = "https://" + this.ospLink + '/renewal-and-payments';
        
                    let email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                    policyEmail.lineCd == productInfo.lineCd &&
                    policyEmail.sublineCd == productInfo.sublineCd &&
                    policyEmail.emailType == 5)[0];
                    let header = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                    policyEmail.lineCd == productInfo.lineCd &&
                    policyEmail.sublineCd == productInfo.sublineCd &&
                    policyEmail.emailType == 5)[0]?.headerImgPath;
                    
                    if(emailType==3){
                        email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                        policyEmail.lineCd == productInfo.lineCd &&
                        policyEmail.sublineCd == productInfo.sublineCd &&
                        policyEmail.emailType == 3)[0];
                        header = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                        policyEmail.lineCd == productInfo.lineCd &&
                        policyEmail.sublineCd == productInfo.sublineCd &&
                        policyEmail.emailType == 3)[0]?.headerImgPath;
                    }
                    header = "https://"+ this.ospLink + header;
                    header = header.replace(/\\/g,"/");
                    let emailSubject = email.emailSubject.split('P_ASSURED_NAME').join(assdFirstName);
                    emailSubject = emailSubject.split('P_POLICY_NUMBER').join(productInfo.policyNo);
                    emailSubject = emailSubject.split('P_FIRST_NAME').join(assdFirstName);
                    emailSubject = emailSubject.split('P_DISPLAY_NAME').join(productInfo.name);
            
                    let emailBody = email.emailBody.split('P_POL_NO').join(polNo)
                    emailBody = emailBody.split('P_OLD_EXP_DATE').join(oldExpiryDate);
                    emailBody = emailBody.split('P_REN_LINK').join(pLink);
                    emailBody = emailBody.split('P_ASSURED_NAME').join(assdFirstName);
                    emailBody = emailBody.split('P_PLAN_NAME').join(productInfo.planName);
                    emailBody = emailBody.split('P_DOC_NAME').join(productInfo.docName);
                    emailBody = emailBody.split('P_RISK_NAME').join(productInfo.insuredRisk);
                    emailBody = emailBody.split('P_PAYT_REF_NO').join(paytRefNo);
                    
                    productInfo.effDate = formatDate(productInfo.effDate, "MM/dd/YYYY h:mm a","en-us").replace('12:00 PM', '12:00 NOON');
                    productInfo.expDate = formatDate(productInfo.expDate, "MM/dd/YYYY h:mm a","en-us").replace('12:00 PM', '12:00 NOON');
                    emailBody = emailBody.split('P_VEHICLE_INFORMATION').join(productInfo.insuredRisk);
                    emailBody = emailBody.split('P_POLICY_NUMBER').join(productInfo.policyNo);
                    emailBody = emailBody.split('P_VALIDITY_DATE').join(productInfo.effDate+' - '+productInfo.expDate);
                    emailBody = emailBody.split('P_FIRST_NAME').join(assdFirstName);
                    emailBody = emailBody.split('P_PLAN_DESC').join(productInfo.planName);
                    
                    const cc = '';
                    this.emailForm.patchValue({ 
                        subject: emailSubject, 
                        emailContent: emailBody,
                        recipient: emailAddress,
                        cc: cc
                    });

                    if(element=="BMR008"){
                        emailType = 4;
                    }
                    else if(element=="BMR010"){
                        emailType = 5;
                    }
                    else if(element=="BMR011"){
                        emailType = 6;
                    }
                    else if(element=="BMR002"){
                        emailType = 3;
                    }

                    this.apiCallService.sendMail({
                        ...this.emailForm.getRawValue(), 
                        policyId: productInfo.policyId,
                        emailType: emailType,
                        userId: this.userDetailsService.userId,
                        lineCd: productInfo.lineCd,
                        sublineCd: productInfo.sublineCd,
                        folderName: folderName,
                        header: header
                    }).subscribe((response:any) => {
                        this.securityService.checkRequestKeyResponse(response, () => {
                            this.securityService.hasValidCsrfToken(response, () => {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                if(productInfo.polStatCd==3){
                                    this.apiCallService.updatePolicyStatus({
                                        policyId: productInfo.policyId,
                                        newStatus: 6,
                                        renStatus: 4
                                    }).subscribe((response:any) => {
                                        this.securityService.checkRequestKeyResponse(response, () => {
                                        if (response.status === "SUCCESS") {
                                        let smsContent = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                                        policyEmail.lineCd == productInfo.lineCd &&
                                        policyEmail.sublineCd == productInfo.sublineCd &&
                                        policyEmail.emailType == 9)[0];
                                        
                                        smsContent = smsContent.emailBody.split('P_ASSURED_NAME').join(productInfo.assdFirstName);
                                        smsContent = smsContent.split('P_OLD_EXP_DATE').join(oldExpiryDate);
                                        smsContent = smsContent.split('P_LINK').join(link);
                                        smsContent = smsContent.split('P_POL_NO').join(polNo);
                                        smsContent = smsContent.split('P_RISK_NAME').join(productInfo.insuredRisk);
                                        smsContent = smsContent.split('P_PAYT_REF_NO').join(paytRefNo);
                                        smsContent = smsContent.split('P_PLAN_NAME').join(productInfo.planName);

                                        let smsParamsArr:any = [
                                            {
                                            mobileNo: productInfo.mobileNo,
                                            message: smsContent,
                                            countryCd: this.smsCountryCd[0].countryCd
                                            }
                                        ];

                                        for (var i = 0; i < smsParamsArr.length; i++) {
                                            const el = smsParamsArr[i];
                                            var smsCode = this.smsCountryCd[0]; 

                                            el.mobileNo = el.mobileNo.trim();

                                            if(el.mobileNo.charAt(0) == '0') {
                                            el.mobileNo = smsCode.smsCountryCd + (el.mobileNo.substr(el.mobileNo.indexOf(smsCode.prefix) + smsCode.prefix.length, el.mobileNo.length - smsCode.prefix.length));
                                            } else if(el.mobileNo.charAt(0) == '+') {
                                            el.mobileNo = el.mobileNo.substr(1, el.mobileNo.length);
                                            }
                                        }
            
                                            this.apiCallService.sendSms(smsParamsArr).subscribe((data: any) => {
                                                this.securityService.checkRequestKeyResponse(data, () => {
                                                        if (data.status === "SUCCESS") {
                                                            if(elementIndex < list.length-1){
                                                                this.proceedSentMail(list,reportIndex, elementIndex+1, element, emailType);
                                                            }
                                                            else{
                                                                if(reportIndex < this.reportList.length){
                                                                    this.sendMail(reportIndex+1);
                                                                }
                                                                this.jsonDataService.contorlLoading(false);
                                                                this.messageBoxService.show1("Success", "Your email and sms was sent successfully", "Ok", "");
                                                                this.bancaList = [];
                                                                this.agencyList = [];
                                                                this.manpowerList = [];
                                                                this.renewedList = [];
                                                                this.setRenewalStatus('allRenewal');
                                                            }
                                                        }
                                                        else {
                                                            if(elementIndex < list.length-1){
                                                                this.proceedSentMail(list,reportIndex, elementIndex+1, element, emailType);
                                                            }
                                                            else{
                                                                if(reportIndex < this.reportList.length){
                                                                    this.sendMail(reportIndex+1);
                                                                }
                                                                this.jsonDataService.contorlLoading(false);
                                                                this.messageBoxService.show1("Success", "Your email was sent successfully", "Ok", "");
                                                                this.bancaList = [];
                                                                this.agencyList = [];
                                                                this.manpowerList = [];
                                                                this.renewedList = [];
                                                                this.setRenewalStatus('allRenewal');
                                                            }
                                                        }
                                                    });
                                                });
                                        } else {
                                            this.jsonDataService.contorlLoading(false);
                                            this.messageBoxService.show1("Error", "Your email was not sent successfully", "Ok", "");
                                        }
                                        });
                                    });
                                }
                                else {
                                    if (response.status === "SUCCESS") {
                                        let smsContent = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
                                        policyEmail.lineCd == productInfo.lineCd &&
                                        policyEmail.sublineCd == productInfo.sublineCd &&
                                        policyEmail.emailType == 9)[0];
                                        
                                        smsContent = smsContent.emailBody.split('P_ASSURED_NAME').join(productInfo.assdFirstName);
                                        smsContent = smsContent.split('P_OLD_EXP_DATE').join(oldExpiryDate);
                                        smsContent = smsContent.split('P_LINK').join(link);
                                        smsContent = smsContent.split('P_POL_NO').join(polNo);
                                        smsContent = smsContent.split('P_RISK_NAME').join(productInfo.insuredRisk);
                                        smsContent = smsContent.split('P_PAYT_REF_NO').join(paytRefNo);
                                        smsContent = smsContent.split('P_PLAN_NAME').join(productInfo.planName);

                                        let smsParamsArr:any = [
                                            {
                                            mobileNo: productInfo.mobileNo,
                                            message: smsContent,
                                            countryCd: this.smsCountryCd[0].countryCd
                                            }
                                        ];

                                        for (var i = 0; i < smsParamsArr.length; i++) {
                                            const el = smsParamsArr[i];
                                            var smsCode = this.smsCountryCd[0]; 

                                            el.mobileNo = el.mobileNo.trim();

                                            if(el.mobileNo.charAt(0) == '0') {
                                            el.mobileNo = smsCode.smsCountryCd + (el.mobileNo.substr(el.mobileNo.indexOf(smsCode.prefix) + smsCode.prefix.length, el.mobileNo.length - smsCode.prefix.length));
                                            } else if(el.mobileNo.charAt(0) == '+') {
                                            el.mobileNo = el.mobileNo.substr(1, el.mobileNo.length);
                                            }
                                        }
            
                                            
                                        this.apiCallService.sendSms(smsParamsArr).subscribe((data: any) => {
                                            this.securityService.checkRequestKeyResponse(data, () => {
                                                this.securityService.hasValidCsrfToken(response, () => {
                                                    if (response.status === "SUCCESS") {
                                                        if(elementIndex < list.length-1){
                                                            this.proceedSentMail(list,reportIndex, elementIndex+1, element, emailType);
                                                        }
                                                        else{
                                                            if(reportIndex < this.reportList.length){
                                                                this.sendMail(reportIndex+1);
                                                            }
                                                            this.jsonDataService.contorlLoading(false);
                                                            this.messageBoxService.show1("Success", "Your email and sms was sent successfully", "Ok", "");
                                                            this.bancaList = [];
                                                            this.agencyList = [];
                                                            this.manpowerList = [];
                                                            this.renewedList = [];
                                                            this.setRenewalStatus('allRenewal');
                                                        }
                                                    }
                                                    else {
                                                        if(elementIndex < list.length-1){
                                                            this.proceedSentMail(list,reportIndex, elementIndex+1, element, emailType);
                                                        }
                                                        else{
                                                            if(reportIndex < this.reportList.length){
                                                                this.sendMail(reportIndex+1);
                                                            }
                                                            this.jsonDataService.contorlLoading(false);
                                                            this.messageBoxService.show1("Success", "Your email was sent successfully", "Ok", "");
                                                            this.bancaList = [];
                                                            this.agencyList = [];
                                                            this.manpowerList = [];
                                                            this.renewedList = [];
                                                            this.setRenewalStatus('allRenewal');
                                                        }
                                                    }
                                                });
                                            });
                                        });
                                    } else {
                                            this.jsonDataService.contorlLoading(false);
                                            this.messageBoxService.show1("Error", "Your email was not sent successfully", "Ok", "");
                                    }
                                }
                            });
                        });
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
        } else {
            this.jsonDataService.contorlLoading(false);
        }
    }
    sendMail(index:number){
            if(index<this.reportList.length){
                const element = this.reportList[index];
                
                let list = [];
                let emailType = 0;
                if(this.bancaList.length!=0&&element=="BMR008"){
                    list = this.bancaList;
                    emailType = 4;
                }
                else if(this.agencyList.length!=0&&element=="BMR010"){
                    list = this.agencyList;
                    emailType = 5;
                }
                else if(this.manpowerList.length!=0&&element=="BMR011"){
                    list = this.manpowerList;
                    emailType = 6;
                }
                else if(this.renewedList.length!=0&&element=="BMR002"){
                    list = this.renewedList;
                    emailType = 3;
                }
                else {
                    this.sendMail(index+1);
                }
                if(list.length>0){
                    this.jsonDataService.contorlLoading(true);
                    this.proceedSentMail(list, index, 0, element, emailType);
                }
            }
        }

        setCancelVar(data:any){
            if(Number(data.quoteStatCd)==1||Number(data.quoteStatCd)==3){
                this.cancellationForm.controls["quoteId"].setValue(data.quoteId);
                this.cancellationForm.controls["rid"].setValue(data.rid);
                this.cancelPol.open('Return');
            }
        }

        //para sa policy cancellation
        validateCancellationText(): void {
            const userType = this.userDetailsService?.accountType;
            const userId = this.userDetailsService?.userId;
            this.jsonDataService.data.BMM162.map((a: any) => {
                if(a?.userType === userType) {
                    this.validCancellationText = true;
                }
            });
            this.jsonDataService.data.BMM163.filter((a: any) => a.tranCd === "UPC").map((b: any) => {
                if(b?.userId === userId) {
                    this.validCancellationText = true;
                }
            });
        }
    
        //Para sa Cancellation ng Policy
        getReasons():any[] {
            let reasonOptions = [];
            for (let reason of this.jsonDataService.data.cancelReason) {
                reasonOptions.push({
                    value: reason.reasonCd,
                    text: reason.reasonDesc
                });
            }
            reasonOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
            return reasonOptions;
        }
    
        //Para sa Cancellation ng Policy
        setReasonViaInput(event:any):void {
            this.resetReason();
            for (const reason of this.getReasons()) {
                if (reason.text === this.cancellationForm.controls["reason"].value) {
                    this.cancellationForm.controls["reason"].setValue(reason);
                }
            }
        }
        
        //Para sa Cancellation ng Policy
        resetReason():void {
            this.cancellationForm.controls["reason"].setValue("");
            this.cancellationForm.controls["quoteId"].setValue("");
            this.cancellationForm.controls["rid"].setValue("");
        }
    
        cancelQuotation(form:any) {
            if (!form.reason.value) {
                this.appMessageService.showAppMessage("Please pick a valid reason for your cancellation", "error");
            } else if(!form.quoteId||!form.rid){
                this.appMessageService.showAppMessage("Quote cancellation cannot proceed", "error");
            }
            else{
                this.proceedCancelQuotation(form);
            }
        }
    
        proceedCancelQuotation(form:any) {
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.cancelQuotation({
                quoteId: parseInt(form.quoteId),
                reasonCd: parseInt(form.reason.value),
                rid: form.rid
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.cancellationForm.markAsPristine();
                        this.getProductList();
                        this.appMessageService.showAppMessage("Your quotation is now cancelled", "success");
                    } else {
                        this.resetReason();
                        this.appMessageService.showAppMessage("There is an error in cancelling your quotation", "error");
                    }
                });
            });
        }
}
