import { Component } from '@angular/core';

import { ParameterService } from 'src/app/services/parameter.service';

@Component({
    selector: 'use-of-cookies',
    templateUrl: './use-of-cookies.html'
})
export class UseOfCookiesComponent {

    message:string = "";
    privacyLink:string = "";
    accept:boolean = false;

    constructor (
        private parameterService: ParameterService
    ) {
        this.message = this.parameterService.paramV("LANDING_PAGE_PROMPT");
        this.privacyLink = this.parameterService.paramV("PRIVACY_LINK");
    }

    userAccept():void {
        this.accept = true;
        sessionStorage.setItem("u&jyUbb$dpa_t0(dQoc02mm0S*n=", "lg3dcG*%f1pBqp0$2@zeHda46#d)w9njJtvuwbOAwp0=");
    }
}