<section id="sub-register">
    <form [formGroup]="form">
        <div id="registration-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <h2>Create an account</h2>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Client Type</label>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="clientType" (selectionChange)="checkClientType()" tabindex="1">
                                    <mat-option *ngFor="let clientType of varClientTypes" [value]="clientType.value">{{clientType.text}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="showPersonalFields">
                        <div class="col-case">
                            <div class="field required">
                                <label>Last Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="lastName" tabindex="2"  maxlength="30" uppercase trim>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'lastName')">
                                    {{formService.getFieldErrorMsg(form, "lastName")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field required">
                                <label>First Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="firstName" tabindex="3"  maxlength="20" uppercase trim>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'firstName')">
                                    {{formService.getFieldErrorMsg(form, "firstName")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field middle">
                                <label>Middle Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="middleName" tabindex="4"  maxlength="30" uppercase trim>
                                </mat-form-field>
                            </div>
                            <div class="field suffix">
                                <label>Suffix</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="suffix" tabindex="5"  maxlength="5" uppercase trim>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-case" *ngIf="showBusinessFields">
                        <div class="field required">
                            <label>Company Name</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="companyName" tabindex="6" uppercase trim>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'companyName')">
                                {{formService.getFieldErrorMsg(form, "companyName")}}
                            </p>
                        </div>
                    </div>
                    <div class="col-case">
                        <div class="field required">
                            <label>{{labelDate}}</label>
                            <mat-form-field appearance="fill" *ngIf="showPersonalFields">
                                <input matInput formControlName="date" [max]="maxDate" [min]="minDate" [matDatepicker]="picker" tabindex="7" birthDate [attr.maxlength]="this.birthMax" (input)="setBirthMax()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="showBusinessFields">
                                <input matInput formControlName="date" [matDatepicker]="picker" tabindex="7" [attr.maxlength]="this.birthMax" (input)="setBirthMax()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <p class="field-error" *ngIf="formService.isInvalid(form, 'date')">
                                <span *ngIf="form.get('date')?.errors?.birthDate">
                                    Age must be 18 to 100 years old.
                                </span>
                                <span *ngIf="!form.get('date')?.errors?.birthDate">
                                    {{formService.getFieldErrorMsg(form, "date")}}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-case">
                        <div class="field required">
                            <label>Mobile Number</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="mobile" tabindex="8" mobileNo>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'mobile')">
                                <span *ngIf="form.get('mobile')?.errors?.mobileNo">
                                    Invalid mobile number.
                                </span>
                                <span *ngIf="!form.get('mobile')?.errors?.mobileNo">
                                    {{formService.getFieldErrorMsg(form, "mobile")}}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-case">
                        <div class="field required">
                            <label>Email Address</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="email" tabindex="9" emailAdd>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'email')">
                                {{formService.getFieldErrorMsg(form, "email")}}
                            </p>
                        </div>
                    </div>
                    <div class="col-case">
                        <div class="checkbox">
                            <mat-checkbox (change)="chkAcceptReg($event)" [checked]="accept">{{acceptReg}}<a class="link" (click)="getPdf()">{{acceptRegLine}}</a></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="registration-form-msg bottom"></div>
        </div>
        <div class="form-btns">
            <button class="btn btn3" [disabled]="!accept" (click)="processRegister()" tabindex="10">Register</button>
        </div>
    </form>
</section>