import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'sub-verify',
    templateUrl: './sub-verify.html'
})
export class SubVerifyComponent {

    constructor (
        public formService: FormService,
        private jsonDataService: JsonDataService,
        private userDetails: UserDetailsService
    ) {}

    @Input() email:any = this.userDetails.email;
    @Output() verifyEmail:EventEmitter<any> = new EventEmitter<string>();

    resendEmail() {
        this.verify();
        // this.formService.showFormLoader(this, "registration-form", "Sending Email.<br>Please wait ...", "verify", this.parentChildCommon.email);
    }

    verify() {
        this.jsonDataService.contorlLoading(true);
        this.verifyEmail.emit({
            email:this.userDetails.email,
            accountType: this.userDetails.accountType,
            userId:this.userDetails.userId
        });
        // this.parentChildCommon.verifyFunction(email);
    }

}