import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';

@Component({
    selector: 'stepper',
    templateUrl: './stepper.html'
})
export class StepperComponent implements OnDestroy {

    titleStepper:string = "";
    form:any = [];
    steps:any = {
        MC: {
            quotation: [
                { stepNo: 0, link: "" },
                { stepNo: 1, link: "choose-plan" },
                { stepNo: 2, link: "vehicle-details" },
                { stepNo: 3, link: "coverage-perils" },
                { stepNo: 4, link: "review-premium-charges" },
                { stepNo: 4, link: "account", showToStepper: false },
                { stepNo: 5, link: "email-print" }
            ],
            policy: [
                { stepNo: 1, link: "vehicle-details" },
                { stepNo: 2, link: "customer-information" },
                { stepNo: 3, link: "acceptance" },
                { stepNo: 4, link: "payment" },
                { stepNo: 5, link: "email-print" }
            ]
        },
        PA: {
            quotation: [
                { stepNo: 0, link: "" },
                { stepNo: 1, link: "choose-plan" },
                { stepNo: 2, link: "coverage-perils" },
                { stepNo: 2, link: "account", showToStepper: false },
                { stepNo: 3, link: "personal-details" },
                { stepNo: 4, link: "review-premium-charges" },
                { stepNo: 5, link: "email-print" }
            ],
            policy: [
                { stepNo: 1, link: "personal-details" },
                { stepNo: 2, link: "customer-information" },
                { stepNo: 3, link: "acceptance" },
                { stepNo: 4, link: "payment" },
                { stepNo: 5, link: "email-print" }
            ] 
        },
        HA: {
            quotation: [
                { stepNo: 0, link: "" },
                { stepNo: 1, link: "choose-plan" },
                { stepNo: 2, link: "property-details" },
                { stepNo: 3, link: "coverage-perils" },
                { stepNo: 4, link: "review-premium-charges" },
                { stepNo: 4, link: "account", showToStepper: false },
                { stepNo: 5, link: "email-print" }
            ],
            policy: [
                { stepNo: 1, link: "property-details" },
                { stepNo: 2, link: "customer-information" },
                { stepNo: 3, link: "acceptance" },
                { stepNo: 4, link: "payment" },
                { stepNo: 5, link: "email-print" }
            ]
        },
        CI: {
            quotation: [
                { stepNo: 0, link: "" },
                { stepNo: 1, link: "choose-plan" },
                { stepNo: 2, link: "coverage-perils" },
                { stepNo: 3, link: "personal-details" },
                { stepNo: 4, link: "review-premium-charges" },
                { stepNo: 4, link: "account", showToStepper: false },
                { stepNo: 5, link: "email-print" }
            ],
            policy: [
                { stepNo: 1, link: "personal-details" },
                { stepNo: 2, link: "customer-information" },
                { stepNo: 3, link: "acceptance" },
                { stepNo: 4, link: "payment" },
                { stepNo: 5, link: "email-print" }
            ] 
        },
        //temp muna tong FI d ko pa sure yungs steps para lang wala error sa uat testing -kurt
        FI: {
            quotation: [
                { stepNo: 0, link: "" },
                { stepNo: 1, link: "choose-plan" },
                { stepNo: 2, link: "property-details" },
                { stepNo: 3, link: "coverage-perils" },
                { stepNo: 4, link: "review-premium-charges" },
                { stepNo: 4, link: "account", showToStepper: false },
                { stepNo: 5, link: "email-print" }
            ],
            policy: [
                { stepNo: 1, link: "property-details" },
                { stepNo: 2, link: "customer-information" },
                { stepNo: 3, link: "acceptance" },
                { stepNo: 4, link: "payment" },
                { stepNo: 5, link: "email-print" }
            ]
        },
    };

    formStatus:number = 0;
    currentUrl:any = [];

    lineCd = this.productService.productInfo.lineCd;

    routerSub:Subscription;

    constructor (
        private router: Router,
        public stepperDtlService: StepperDtlService,
        public productService: ProductService
    ) {
        this.currentUrl = this.router.url.split("/");
        if (this.currentUrl[1] === "quotation" && this.lineCd) {
            this.form = this.lineCd === 'paytRenewal' ? [] : this.steps[this.lineCd].quotation;
            this.titleStepper = "Quotation";
        } else if (this.currentUrl[1] === "policy" && this.lineCd){
            this.form = this.lineCd === 'paytRenewal' ? [] : this.steps[this.lineCd].policy;
            this.titleStepper = "E-Policy";
        }

        this.routerSub = this.router.events.pipe(
            filter(
                (event: any) => {
                    return (event instanceof NavigationEnd);
                }
            )
        ).subscribe(e => {
            let url = e.url.substring(e.url.lastIndexOf('/') + 1).split('?')[0];
            for (let step of this.form) {
                if (step.link === url) {
                    this.formStatus = parseInt(step.stepNo);
                }
            }
        })
    }

    ngOnDestroy():void {
        this.routerSub.unsubscribe();
    }

    getSteps():any {
        let data = [];
        for (let step of this.form.filter((a: any) => a.stepNo !== 0 && a.showToStepper !== false)) {
            data.push(step);
        }
        return data;
    }
}