<section id="sub-reset">
    <form>
        <div id="sub-reset-form">
            <div class="form-fields">
                <h2>EMAIL VERIFIED</h2>
                <br><br>
                <br><br>
                <p>Your email address was successfully verified.</p>
                <br><br><br><br><br><br>
            </div>
        </div>
        <div class="form-btns">
            <button class="btn btn2" (click)="goHome()">Ok</button>
        </div>
    </form>
</section>