import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs/internal/Observable';

import { OverlayComponent } from 'src/app/components/common/overlay/overlay';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';
import { APICallService } from 'src/app/services/api-call.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UtilityService } from 'src/app/services/utility.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FormService } from 'src/app/services/form.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { SecurityService } from 'src/app/services/security.service';
import { map } from 'rxjs/operators';
import { getCurrencySymbol } from '@angular/common';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
    templateUrl: './rev-prem-charges.html'
})
export class RevPremChargesComponent {

    form:FormGroup = this.formBuilder.group ({
        reason: [""],
    });

    @ViewChild("cancelQuo") cancelQuo!: OverlayComponent;
    @ViewChild('verifyEmail')verifyEmail!: OverlayComponent;

    // FOR CANCEL QUOTATION
    isUserAllowedToCancel:boolean = false;
    reason:string = "";
    cocErrorMsg:string = "";

    // AUTO-COMPLETE OPTIONS
    filteredReasonOptions:Observable<any>;

    productInfo:any = {};
    premiumCharges:any = {};
    benefitsCoveragesCI:any = {};
    benefitsCoveragesFPA: any[] = [];
    benefitsCoveragesIND: any[] = [];
    showBenCovDetails:boolean = false;
    showDepDetails:boolean = false;
    dependents: any;
    deductibles:any[]= this.productService.productInfo.deductibles || [];


    seeMorePolicyHolder: boolean = false;

    lastSortedColumn: string = "";
    sorting: string = "ASC";

    displayedColumns: string[] = ["name", "relationship", "occupation", "maritalStatus", "birthDate", "age"];
    helpParams:any = {
        tableName: "",
        dedAmt: "",
        towingAmt: "",
        repairAmt: ""
    }
    dedAmtComment:String = "";
    towingAmtComment:String = "";
    repairAmtComment:String = "";
    summarizedPremTag: String = "";
    showComm: boolean = false;
    total: number = 0;

    rows: FormArray = this.formBuilder.array([]);
    tableForm = this.formBuilder.group({ 'dependents': this.rows });

    displayedCurrency: string = "";

    @ViewChild(MatSort)
    sort!: MatSort;

    constructor (
        // PUBLIC
        public formService: FormService,
        public userDetails: UserDetailsService,
        public productService: ProductService,
        public parameterService: ParameterService,
        public autocompleteService: AutocompleteService,
        public jsonDataService: JsonDataService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private pageValidationService: PageValidationService,
        private utilService: UtilityService,
        private stepperDtlService: StepperDtlService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.productInfo = this.productService.productInfo;
        this.commissionDisp();
        this.setPremiumCharges();

        if (this.productInfo.lineCd === 'HA') {
            this.buildAmountInsuredHA();
        }
        
        //* R2
        this.summarizedPremTag = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == productService.productInfo.lineCd && a.sublineCd == productService.productInfo.sublineCd && a.planCd == productService.productInfo.planInfo.planCd)[0]?.summarizedPremTag;
        this.productInfo.menuLineCd = this.jsonDataService.data.lines.filter((a: any) => a.lineCd == productService.productInfo.lineCd)[0]?.menuLineCd;

        // SET AUTO-COMPLETE INITIAL VALUES
        this.filteredReasonOptions = this.autocompleteService.set(this.form, "reason", this.getReasons());

        const allowedUsers = this.parameterService.paramV("ALLOW_CANCELLATION").split(",");
        for (let index = 0; index < allowedUsers.length; index++) {
            if (allowedUsers[index] === this.userDetails.accountType) {
                this.isUserAllowedToCancel = true;
                break;
            }
        }

        this.displayedCurrency = getCurrencySymbol(this.productInfo.propertyInfo?.currency, 'narrow');

        const reasonCd = this.productService.productInfo.reasonCd;
        this.cocErrorMsg = this.productService.productInfo.vehicleInfo?.cocErrorMsg || "";
        this.reason = (reasonCd ? this.jsonDataService.data.cancelReason.filter((a:any) => a.reasonCd === reasonCd)[0]?.reasonDesc : "") || "";

        this.initializeStepperDetail();
        this.productService.setProductInfo2(this.productInfo);
        this.setHelpDescription();
        this.utilService.setFormGroup(this.tableForm);
        this.assignDependentsAndBenefitsCoverages();
    }

    initializeStepperDetail():void {
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = 4;
        this.stepperDtlService.titleStepperDtl = "Review Premium & Charges";

        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: "move", actionParam: this.productInfo.quoteStatCd === 7 ? "dashboard" : "back" },
            { class: "btn2", label: "Next: Quotation", action: "move", actionParam: "next", isShow: this.productInfo.quoteStatCd === 7 ? "false" : "true" }
        ];

        if (this.productInfo.sublineCd === 'COM' || this.productInfo.sublineCd === 'PCP') {
            this.fbAnalyticsService.trackEvent('Motor_Quotation_ Charges')
        } else if (this.productInfo.sublineCd === 'CTPL') {
            this.fbAnalyticsService.trackEvent('Liability_ Quotation_Charges')
        } else if (this.productInfo.sublineCd === 'FPA') {
            this.fbAnalyticsService.trackEvent('Family_Quotation_ Personal_Details')
        } else if (this.productInfo.sublineCd === 'IND') {
            this.fbAnalyticsService.trackEvent('Personal_Quotation_ Charges')
        } else if (this.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Quotation_ Charges')
        }
    }

    assignDependentsAndBenefitsCoverages():void {
        if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'FPA' && this.productInfo.dependents && this.productInfo.dependents.length) {
            this.dependents = this.productInfo.dependents;
            this.showDepDetails = true;
        }
        if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'FPA' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            for (let benCovFPA of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesFPA.push({
                    perilCd: benCovFPA.perilCd,
                    perilName: benCovFPA.perilLname,
                    tsiPrincipal: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'PR' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiSpouse: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'SP' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiChildren: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'CH' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'IND' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            for (let benCovIND of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesIND.push({
                    perilCd: benCovIND.perilCd,
                    perilName: benCovIND.perilLname,
                    tsiAmt: this.productInfo.benefitsCoverages.filter((a:any) => a.perilCd === benCovIND.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'CI' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            this.benefitsCoveragesCI.noOfDays = this.productInfo.benefitsCoverages[0].noOfDays;
            this.benefitsCoveragesCI.tsiAmt = this.productInfo.benefitsCoverages[0].tsiAmt;
            this.benefitsCoveragesCI.maxProfit = this.benefitsCoveragesCI.noOfDays*this.benefitsCoveragesCI.tsiAmt;
            this.showBenCovDetails = true;
        }
    }

    sortDependents(sortColumn: string, key: string): void {
        if (sortColumn) {
            if (this.lastSortedColumn === sortColumn) {
                this.sorting = this.sorting === "ASC" ? "DESC" : "ASC";
            } else {
                this.lastSortedColumn = sortColumn;
                this.sorting = "ASC";
            }

            this.dependents.sort((a: any, b: any) => {
                if (this.sorting === "ASC") {
                    return this.compareStrings(a[key], b[key]);
                } else {
                    return this.compareStrings(b[key], a[key]);
                }
            });
        }
    }

    compareStrings(a: any, b: any): number {
        if (typeof a === "string") {
            a = a.toLowerCase();
            b = b.toLowerCase();
        }

        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    private commissionDisp(): void {
        let m072Dtls = this.jsonDataService.data.agentGrps.find((a: any) => a.agentGrp == this.userDetails.agentGrp);
        let m093Dtls = this.jsonDataService.data.userType.filter((a: any) => a.userType == (this.userDetails.accountType || 'P'))[0];
        if (this.userDetails.accountType === 'A') {
            if ((m072Dtls && m072Dtls?.hideCommTag == "N") ||
                (!m072Dtls?.hideCommTag && m093Dtls?.hideCommTag == 'N')) {
                this.showComm = true;
            }
        }
        else {
            this.showComm = true;
        }
    }

    setPremiumCharges():void {
        if (this.productService.productInfo.lineCd === 'MC') {
            const yearArr = this.productInfo.ara.map((b:any) => b.year);

            this.premiumCharges.premiumAmt = this.productInfo.ara.map((b:any) => b.premAmt).reduce((b:any, c:any) => b + c);
            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt;

            // NET PREMIUMS - [START]
            this.premiumCharges.totalPeril = 0;
            this.premiumCharges.perils = [];
            for (let peril of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.peril.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                let total = 0;
                for (let key of yearArr) {
                    total += this.productInfo.peril.filter((a:any) => a.year === key && a.perilCd === peril.perilCd)[0].premAmt;
                }

                this.premiumCharges.perils.push({
                    name: peril.perilLname,
                    value: total
                });

                this.premiumCharges.totalPeril += total;
            }
            // NET PREMIUMS - [END]

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a:any) =>
                this.productInfo.taxes.map((b:any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let total = 0;
                for (let key of yearArr) {
                    total += this.productInfo.taxes.filter((a:any) => a.year === key && a.taxCd === tax.taxCd)[0].taxAmt;
                }

                this.premiumCharges.amountDue += total;
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: total
                    });
                } else {
                    otherCharges += total;
                }

                this.premiumCharges.totalTax += total;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            // TAXES - [END]

            this.premiumCharges.deductibleAmt = this.productInfo.ara[0]?.dedAmt;
            this.premiumCharges.towingAmt = this.productInfo.ara[0]?.towingAmt;
            this.premiumCharges.repairLimitAmt = this.productInfo.ara[0]?.repairLimitAmt;
            this.premiumCharges.commissionAmt = this.productInfo.ara.map((b:any) => b.commAmt).reduce((b:any, c:any) => b + c);;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        }
        else if (this.productService.productInfo.lineCd === 'PA') {
            this.premiumCharges.premiumAmt = this.productInfo.premiumCharges.premiumAmt;
            this.premiumCharges.amountDue = Math.round(this.productInfo.premiumCharges.amountDue * 100) / 100;

            // NET PREMIUMS - [START]
            this.premiumCharges.totalPeril = this.premiumCharges.premiumAmt;
            this.premiumCharges.perils = [];
            let taxTotal = 0;
            for (let peril of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                let perilPrem = 0;
                perilPrem = this.productInfo.coverages.filter((a:any) => a.perilCd === peril.perilCd)[0].premAmt;
                this.premiumCharges.perils.push({
                    name: peril.perilLname,
                    value: perilPrem
                });
            }
            // NET PREMIUMS - [END]

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a:any) =>
                this.productInfo.taxes.map((b:any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let taxAmount = 0;
                taxAmount = this.productInfo.taxes.filter((a:any) => a.taxCd === tax.taxCd)[0].taxAmt;
                taxTotal += taxAmount;
                //this.premiumCharges.amountDue += total;
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmount
                    });
                } else {
                    otherCharges += taxAmount;
                }
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            this.premiumCharges.totalTax = taxTotal;
            // TAXES - [END]

            this.premiumCharges.commissionAmt = this.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = Math.round((this.premiumCharges.amountDue-this.premiumCharges.commissionAmt)*100)/100;
        }
        else if (this.productService.productInfo.lineCd === 'CI') {
            this.premiumCharges.premiumAmt = this.productInfo.premiumCharges.premiumAmt;
            this.premiumCharges.amountDue = Math.round(this.productInfo.premiumCharges.amountDue * 100) / 100;

            // NET PREMIUMS - [START]
            this.premiumCharges.totalPeril = this.premiumCharges.premiumAmt;
            this.premiumCharges.perils = [];
            let taxTotal = 0;
            for (let peril of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                let perilPrem = 0;
                perilPrem = this.productInfo.coverages.filter((a:any) => a.perilCd === peril.perilCd)[0].premAmt;
                this.premiumCharges.perils.push({
                    name: peril.perilLname,
                    value: perilPrem
                });
            }
            // NET PREMIUMS - [END]

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a:any) =>
                this.productInfo.taxes.map((b:any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let taxAmount = 0;
                taxAmount = this.productInfo.taxes.filter((a:any) => a.taxCd === tax.taxCd)[0].taxAmt;
                taxTotal += taxAmount;
                //this.premiumCharges.amountDue += total;
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmount
                    });
                } else {
                    otherCharges += taxAmount;
                }
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            this.premiumCharges.totalTax = taxTotal;
            // TAXES - [END]

            this.premiumCharges.commissionAmt = this.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = Math.round((this.premiumCharges.amountDue-this.premiumCharges.commissionAmt)*100)/100;
        }
        else if (this.productService.productInfo.lineCd === 'HA') {
            // this.premiumCharges.premiumAmt = this.productInfo.premiumCharges.premiumAmt;
            // this.premiumCharges.amountDue = Math.round(this.productInfo.premiumCharges.amountDue * 100) / 100;
            this.premiumCharges.premiumAmt = 0; // Temp
            this.premiumCharges.amountDue = 0; // Temp

            // NET PREMIUMS - [START]
            // this.premiumCharges.totalPeril = this.premiumCharges.premiumAmt;
            this.premiumCharges.totalPeril = 0; // Temp
            this.premiumCharges.perils = [];
            let taxTotal = 0;
            // for (let peril of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
            //     this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            // )) {
            //     const groupName = this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM023.GROUP_CD" && a.rvLowValue == peril.groupCd.toString())[0]?.rvMeaning;
            //     let perilPrem = 0;
            //     perilPrem = this.productInfo.coverages.filter((a:any) => a.perilCd === peril.perilCd)[0]?.premAmt;
            //     this.premiumCharges.premiumAmt += perilPrem;
            //     this.premiumCharges.totalPeril += perilPrem;
            //     this.premiumCharges.amountDue += perilPrem;
            //     this.premiumCharges.perils.push({
            //         name: groupName + " - " + peril.perilLname,
            //         value: perilPrem
            //     });
            // }
            for (let peril of this.productInfo.coverages.filter((a:any)=>a.perilCd)) {
                const groupName = this.jsonDataService.data.fireItemCd.find((a:any)=>a.itemCd == peril.itemCd)?.itemLabel;
                const perilLname = peril.perilName || this.jsonDataService.data.peril.find((a:any)=>a.perilCd == peril.perilCd && a.lineCd == this.productInfo.lineCd)?.perilLname
                let perilPrem = peril.premAmt;
                this.premiumCharges.premiumAmt += perilPrem;
                this.premiumCharges.totalPeril += perilPrem;
                this.premiumCharges.amountDue += perilPrem;
                this.premiumCharges.perils.push({
                    name: groupName + " - " + perilLname,
                    value: perilPrem
                });
            }
            // NET PREMIUMS - [END]

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a:any) =>
                this.productInfo.taxes.map((b:any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let taxAmount = 0;
                // taxAmount = this.productInfo.taxes.filter((a:any) => a.taxCd === tax.taxCd)[0].taxAmt;
                this.productInfo.taxes.filter((a:any) => a.taxCd === tax.taxCd).map((b:any) => {
                    taxAmount += b.taxAmt
                });
                taxTotal += taxAmount;
                this.premiumCharges.amountDue += taxAmount
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmount
                    });
                } else {
                    otherCharges += taxAmount;
                }
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            this.premiumCharges.totalTax = taxTotal;
            // TAXES - [END]

            // COMMISSION - [START]
            this.premiumCharges.commissionAmt = 0;
            this.productInfo.coverages.map((a:any) => {
                if(a?.perilCd) {
                    this.premiumCharges.commissionAmt += a.commAmt;
                }
            });
            // COMMISSION - [END]

            // this.premiumCharges.commissionAmt = this.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = Math.round((this.premiumCharges.amountDue-this.premiumCharges.commissionAmt)*100)/100;
        }
    }

    move(action:string):void {
        if (action === "back") {
            if (this.productService.productInfo.lineCd === "MC" || this.productService.productInfo.lineCd === 'HA') {
                this.router.navigate(["/quotation/coverage-perils"]);
            } else if (["PA", "CI"].indexOf(this.productService.productInfo.lineCd) >= 0) {
                this.router.navigate(["/quotation/personal-details"]);
            }
        } else if (action === "dashboard") {
            this.router.navigate(["/dashboard"]);
        } else {
            if (this.userDetails.userId && this.userDetails.verificationTag === "N") {
                this.checkAuthorization().subscribe(() => {
                    this.verifyEmail.open("Return")
                });
            } else if (this.userDetails.userId) {
                this.router.navigate(["/quotation/email-print"]);
            } else {
                this.router.navigate(["/quotation/account"]);
            }
        }
    }

    resetReason():void {
        this.form.controls["reason"].setValue("");
    }

    cancelQuotation(reasonCd:string) {
        if (!reasonCd) {
            this.appMessageService.showAppMessage("Please pick a valid reason for your cancellation", "error");
        } else {
            this.proceedCancelQuotation(reasonCd);
        }
    }

    proceedCancelQuotation(reasonCd:string) {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.cancelQuotation({
            quoteId: this.productInfo.quoteId,
            reasonCd: parseInt(reasonCd)
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.form.markAsPristine();
                    this.router.navigate(["/dashboard"]);
                    this.appMessageService.showAppMessage("Your quotation is now cancelled", "success");
                } else {
                    this.resetReason();
                    this.appMessageService.showAppMessage("There is an error in cancelling your quotation", "error");
                }
            });
        });
    }

    setHelpDescription() {
        this.dedAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'DED_AMT')[0]?.comment;
        this.towingAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'TOWING_AMT')[0]?.comment;
        this.repairAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'REPAIR_LIMIT_AMT')[0]?.comment;
    }

    /*******************************************************************************/
    /*                                                                             */
    /*                           AUTO-COMPLETE FUNCTIONS                           */
    /*                                                                             */
    /*******************************************************************************/

    /* REASON AUTO-COMPLETE FUNCTIONS */

    getReasons():any[] {
        let reasonOptions = [];
        for (let reason of this.jsonDataService.data.cancelReason) {
            reasonOptions.push({
                value: reason.reasonCd,
                text: reason.reasonDesc
            });
        }
        reasonOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return reasonOptions;
    }
    setReasonViaInput(event:any):void {
        this.resetReason();
        for (const reason of this.getReasons()) {
            if (reason.text === this.form.controls["reason"].value) {
                this.form.controls["reason"].setValue(reason);
            }
        }
    }

    resendEmail():void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendRecoveryEmail("verify", {
            email:this.userDetails.email,
            accountType: this.userDetails.accountType,
            userId:this.userDetails.userId
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.verifyEmail.close();
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.appMessageService.showAppMessage("Email has been sent for verification.", "success");
                } else {
                    this.formService.showFormMsg("registration-form-msg", response.message, "E");
                }
            });
        });
    }

    contactBpiMs():void {
        const url = this.router.serializeUrl(this.router.createUrlTree(["/contact-us"]));
        this.router.navigateByUrl(url);
    }

    checkAuthorization() {
        return this.apiCallService.checkAuthorization().pipe(
            map((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const content = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if (response.status === "AUTHORIZED") {
                            if (content.activeTag === "L") {
                                // Do nothing for now.
                            } else {
                                this.userDetails.setUserDetails(content);
                            }
                        } else if (response.status === "LIMIT_REACHED") {
                            this.userDetails.setUserDetails(content);
                        }
                    }, false);
                });
            })
        );
    }

    insuredCol1:any[]= [];
    insuredCol2:any[]= [];
    longerRow:any[] = [];

    buildAmountInsuredHA():void{
        let bldgItems = this.productInfo.bldgForm?.bldgItms?.filter((i:any)=> i.bldgAmt);
        let contItems = this.productInfo.contForm?.contItms?.filter((i:any)=> i.contAmt);

        let contents:any[] = [];

        if(bldgItems || contItems){
            let itemNo = 0;
            contents = bldgItems.concat(contItems).sort((a:any,b:any)=>a.seqNo-b.seqNo).map((i:any)=>{
                itemNo += 1;
                return {
                    amt : i.bldgAmt || i.contAmt,
                    itmCd : i.bldgItmCd || i.contItmCd,
                    itemNo: itemNo,
                    curr: getCurrencySymbol(i.contCurrency, 'narrow') || getCurrencySymbol(i.bldgCurrency, 'narrow')
                };
            });
        }else{
            contents = this.productInfo.bldgCont
                .sort((a:any,b:any)=>a.itemNo - b.itemNo)
                .map((i:any)=>{
                    return {amt : i.bldgAmt,
                            itmCd : i.bldgItmCd,
                            itemNo: i.itemNo,
                            curr: getCurrencySymbol(i.bldgCurrency, 'narrow')}
                });
        }

        let mtnContents:any[] = this.jsonDataService.data.firePlanItemCd.filter((b:any)=>b.lineCd == this.productInfo.lineCd && b.sublineCd == this.productInfo.sublineCd && b.planCd == this.productInfo.planInfo.planCd)

        mtnContents.forEach(a=>{
            let content = contents.find(b=>a.itemCd == b.itmCd) || { curr: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow') };
            content.label = this.jsonDataService.data.fireItemCd.find((b:any)=>b.itemCd == a.itemCd).itemLabel;
            
            a.colGrp == 1 ? this.insuredCol1.push(content) : this.insuredCol2.push(content);
            this.total += parseFloat(content.amt || 0)
        });
        this.longerRow = this.insuredCol1.length > this.insuredCol2.length ? this.insuredCol1 : this.insuredCol2;
    }
}