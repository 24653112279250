import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PageValidationService } from 'src/app/services/page-validation.service';
import { ProductService } from 'src/app/services/product.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';

@Component({
  templateUrl: './login-register.html'
})
export class LoginRegisterComponent {

    form:string = "";

    constructor (
        private router: Router,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private productService: ProductService
    ) {
        this.pageValidationService.validatePageAccessibility();

        if (this.router.url === "/register") {
            this.form = "register";
            this.productService.setProductInfo2("");
        } else if (this.router.url === "/login" || this.router.url.includes("login")) {
            this.form = "login";
            this.productService.setProductInfo2("");
        }  else {
            this.form = "both";

            // STEPPER DETAIL
            this.stepperDtlService.showStepperDtl = true;
            this.stepperDtlService.stepperDtlStepNo = 0;
            this.stepperDtlService.titleStepperDtl = "To proceed, you need to login or register";
            this.stepperDtlService.stepperDtlBtns = [];
        }
    }

    showForm(form:string):boolean {
        return this.form === "both" || this.form === form;
    }

    showCase(form:string):void {
        const registerCase = document.querySelector("#login-register > .container:not(.solo) > .reg-case");
        const loginCase = document.querySelector("#login-register > .container:not(.solo) > .login-case");
        if (registerCase !== null && loginCase !== null) {
            if (form === "register") {
                registerCase.classList.add("show");
                loginCase.classList.remove("show");
            } else {
                registerCase.classList.remove("show");
                loginCase.classList.add("show");
            }
        }
    }
}