<section id="policy-details">
    <div class="container cust">
        <div class="title-cont">
            <h2 *ngIf="!showOtpForm" class="proxima">
                <span class="big-title">POLICY DETAILS</span>
            </h2>
        </div>
        <div class="form-cont">
            <form *ngIf="!showOtpForm" [formGroup]="form" (ngSubmit)="onClickProceed()">
                <div id="policy-details-form">
                    <div class="form-loader"></div>
                    <div class="form-fields">
                        <div class="form">
                            <div class="col-case">
                                <div class="field">
                                    <label>Last 4 digits of Policy #</label>
                                    <div class="help-icon">
                                        <mat-icon matTooltip="{{polNoTooltip}}" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon>
                                    </div>
                                    <mat-form-field appearance="fill">
                                        <input matInput [placeholder]="'0000'" formControlName="polNo" maxlength="4" numbersOnly autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="field">
                                    <label>Payment Reference #</label>
                                    <div class="help-icon">
                                        <mat-icon matTooltip="{{paytRefNoTooltip}}" #tooltip2="matTooltip" (click)="tooltip2.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon>
                                    </div>
                                    <mat-form-field appearance="fill">
                                        <input matInput [placeholder]="'0000-0000-0000-0000'" formControlName="paytRefNo" [attr.maxlength]="paytRefNoMax" (input)="setPaytRefNoMax()" autocomplete paytRefNo>
                                    </mat-form-field>
                                </div>
                                <div class="field">
                                    <label>Last Name</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="lastName" uppercase autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="field">
                                    <label>Birthdate</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="birthdate" birthDate autocomplete>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="pd-form-msg" [innerHTML]="policyDetailsErrorMsg | policyDetailsErrMsg"></div>
                        </div>
                    </div>
                </div>
                <div class="form-btns">
                    <button class="btn btn4" type="button" (click)="onClickBack()">Cancel</button>
                    <button class="btn btn2" type="submit">Proceed</button>
                </div>
            </form>
        
            <form *ngIf="showOtpForm" [formGroup]="otpForm" (ngSubmit)="verifyRenewOtp()" class="otp-frm">
                <div id="otp-form">
                    <div class="form-fields">
                        <ng-container *ngIf="!withOtpMethod">
                            <h2 class="otp-title">Verify your identity</h2>
                            <p>Please choose where you want to get your One-Time Pin (OTP).</p>
                            <div class="otp-options">
                                <div class="option" *ngIf="otpDetails.mobileNo" (click)="sendRenewOtp('mobile', 'N')">
                                    <p>Text</p><span>{{otpDetails.mobileNo}}</span>
                                </div>
                                <div class="option" *ngIf="otpDetails.email" (click)="sendRenewOtp('email', 'N')">
                                    <p>Email</p><span>{{otpDetails.email}}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="withOtpMethod">
                            <h2 class="otp-title">Enter OTP</h2>
                            <p>Your One-Time PIN has been sent to {{otpReceiver}}. Please enter the PIN to sign in.</p>
                            <div class="form">
                                <div class="col-case">
                                    <div class="field">
                                        <mat-form-field appearance="fill" class="cust-bg">
                                            <input matInput formControlName="otp" type="tel" maxlength="6" autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <p class="trouble" *ngIf="otpDetails.mobileNo && otpDetails.email">
                                Having trouble? <a (click)="signInAnotherWay()">Send OTP in another way.</a>
                            </p>
                        </ng-container>
                    </div>
                    <div class="pd-form-msg">{{otpErrorMsg}}</div>
                </div>
                <div class="form-btns" *ngIf="!withOtpMethod">
                    <button class="btn btn2" (click)="cancelOtp()">Cancel</button>
                </div>
                <div class="form-btns verify-btns" *ngIf="withOtpMethod">
                    <button type="button" class="btn btn2" (click)="sendRenewOtp(otpMethod, 'Y')" [disabled]="sendOtpInterval > 0">{{sendOtpInterval > 0 ? "(" + sendOtpInterval + ")" : ""}}<br *ngIf="sendOtpInterval > 0">Resend OTP</button>
                    <button type="button" class="btn btn2" (click)="cancelOtp()">Cancel</button>
                    <button type="submit" class="btn btn2">Verify</button>
                </div>
            </form>
        </div>
    </div>
</section>
