import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { first, map, tap } from 'rxjs/operators';

import { OverlayComponent } from 'src/app/components/common/overlay/overlay';

import { ProductService } from 'src/app/services/product.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { APICallService } from 'src/app/services/api-call.service';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { FormService } from 'src/app/services/form.service';
import { SecurityService } from 'src/app/services/security.service';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { PopupService } from 'src/app/services/popup.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

export interface CoverageAmount {
    coverage: string;
    coveragePerYear: string;
    total: string;
};

@Component({
    templateUrl: './email-print.html'
})
export class EmailPrintComponent implements OnInit {
    @ViewChild('confirmPrintInfo') confirmPrint!: OverlayComponent;
    @ViewChild('emailOverlay') emailOverlay!: OverlayComponent;
    @ViewChild('confirmReEmail') confirmReEmail!: OverlayComponent;
    //@ViewChild('generateQuoteMessage') quoteMessage!: OverlayComponent; Replacement if message box did not work

    commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = control.value?.split(',').map((e: any) => e.trim());
        const forbidden = emails?.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { 'email': { value: control.value } } : null;
    };

    data: any = {
        quoteNo: '12312',
        polNo: '231231',
        status: 'To Be Send',
        category: 'Insurance',
        displayName: '',
        address: '',
        assdNo: ''
    };

    sameAcctTag: any = true;
    quoteId: String = this.productService.productInfo.quoteId;
    planName = this.productService.productInfo.planInfo.planName;
    salutationName: string = '';
    pdfUrl: any = '';
    showLov: boolean = true;
    disableEmail: boolean = false;
    numReloader: number = 0;
    emailSent: boolean = false;
    showSameAcctTag: boolean = false;

    holderForm = this.fb.group({
        sameAcctTag: [false],
        displayName: ['', [Validators.required]],
        address: ['', [Validators.required]],
        assdNo: []
    })

    emailForm = this.fb.group({
        recipient: ['', [Validators.required, this.commaSepEmail]],
        cc: ['', this.commaSepEmail],
        subject: ['', [Validators.required]],
        emailContent: ['', [Validators.required]],
    });

    lovParams: any = {
        userId: this.userService.userId
    }

    accountHolder: any = {};

    forPol: boolean = false;
    noHolder: boolean = true;
    emailNote: string = '';
    defaultEmail: string = '';
    instructions: string = '';

    encryptedFolderName: string = '';
    //quoteNo: string = ''; for overlay

    constructor(
        // PUBLIC
        public formService: FormService,
        public productService: ProductService,
        public userService: UserDetailsService,

        // PRIVATE
        private fb: FormBuilder,
        private router: Router,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private jsonDataService: JsonDataService,
        private messageBoxService: MessageBoxService,
        private pageValidationService: PageValidationService,
        private paramService: ParameterService,
        private securityService: SecurityService,
        private stepperDtlService: StepperDtlService,
        private dialogBoxService: DialogBoxService,
        private popupService: PopupService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.initializeStepperDetail();

        if (userService.accountType !== "P") {
            this.holderForm.get("sameAcctTag")?.disable({ onlySelf: true, emitEvent: false });
        }

        this.showSameAcctTag = paramService.paramV('ALLOW_DIFF_ASSD')?.split(',').indexOf(this.productService.productInfo.lineCd) != -1;
        this.emailNote = paramService.paramV('INSTRUCTIONS_EMAIL');
        this.instructions = this.paramService.paramV("INSTRUCTIONS_PRINT_SCRN_QU");

        setTimeout((e: any) =>
            popupService.popup.open('PR'), 0);
    }

    ngOnInit(): void {
        this.holderForm.get("sameAcctTag")?.valueChanges.subscribe(e => {
            if (this.holderForm.enabled) {
                if (e) {
                    setTimeout((e: any) => {
                        this.holderForm.get("displayName")?.disable({ onlySelf: true, emitEvent: false });
                        this.holderForm.updateValueAndValidity();
                        if (this.holderForm.dirty) {
                            this.holderForm.patchValue(this.accountHolder);
                        }
                    }, 0);
                } else {
                    setTimeout((e: any) => {
                        if (this.productService.productInfo.lineCd === "PA" || this.productService.productInfo.lineCd === "CI") {
                            this.holderForm.get("displayName")?.disable({ onlySelf: true, emitEvent: false });
                            this.showLov = false;
                        } else {
                            this.holderForm.get("displayName")?.enable();
                            this.showLov = true;
                        }
                        this.holderForm.get("address")?.enable();
                        this.holderForm.updateValueAndValidity();
                    }, 0);
                }
                if (this.holderForm.dirty) {
                    this.holderForm.patchValue({
                        displayName: "",
                        address: "",
                        assdNo: "",
                    });
                }
            }

            this.showLov = !e;
        });

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getPolicyHolder({
            quoteId: this.quoteId.toString()
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        res.sameAcctTag = res.sameAcctTag === "Y";

                        this.productService.productInfo.quoteNo = res.quoteNo;
                        this.productService.setProductInfo2(this.productService.productInfo);
                        this.emailSent = res.emailSent === "Y";
                        this.holderForm.patchValue({
                            sameAcctTag: res.sameAcctTag,
                            assdNo: res.assdNo,
                            displayName: res.displayName || "",
                            address: (res.addressPt1 || "") + (res.addressPt2 || "") + (res.addressPt3 || "") + (res.addressPt4 || "")
                        }, { emitEvent: false });
                        this.salutationName = res.firstName || res.displayName;
                        this.accountHolder = res.accountHolder ? { ...res.accountHolder, address: (res.accountHolder.addressPt1 || "") + (res.accountHolder.addressPt2 || "") + (res.accountHolder.addressPt3 || "") + (res.accountHolder.addressPt4 || "") } : {};
                        this.sameAcctTag = res.sameAcctTag;
                        this.defaultEmail = res.emailAddress;

                        setTimeout(() => {
                            this.disableFieldsonStat(res.quoteStatCd);
                        });

                        res.assdNo ? this.generateReport() : this.initialLoad = false;
                    });
                } else {
                    this.appMessageService.showAppMessage("Unable to save the policy holder. Please try again later", "error");
                }
            });
        });

        let emailEditAccts: any[] = this.paramService.paramV('QU_EMAIL_EDIT').split(',');
        if (emailEditAccts.indexOf(this.userService.accountType) == -1) {
            this.emailForm.get('subject')?.disable();
            this.emailForm.get('emailContent')?.disable();
        }
    }

    initializeStepperDetail(): void {
        if (!this.productService.productInfo.policyId
            && !(this.productService.productInfo.lineCd === "PA" && this.productService.productInfo.quoteStatCd === 3 && this.productService.productInfo.previousProcess === "policy")
            && !(this.productService.productInfo.lineCd === "MC" && this.productService.productInfo.quoteStatCd === 3 && this.productService.productInfo.previousProcess === "policy")
        ) {
            this.stepperDtlService.childInstance = this;
            this.stepperDtlService.showStepperDtl = true;
            this.stepperDtlService.stepperDtlStepNo = 5;
            this.stepperDtlService.titleStepperDtl = "Email / Print";
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move" },
                { class: "btn3", label: "Go to Dashboard", action: "toDashboardd" },
                { class: "btn3", label: "Continue to E-Policy", action: "toPolcy" }
            ];
        } else {
            this.stepperDtlService.childInstance = this;
            this.stepperDtlService.showStepperDtl = true;
            this.stepperDtlService.showStepNo = false;
            this.stepperDtlService.titleStepperDtl = "Email / Print";
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move" },
                { class: "btn3", label: "Go to Dashboard", action: "toDashboardd" }
            ];
            setTimeout(() => {
                this.stepperDtlService.showStepNo = false;
            }, 0)
        }

        if (this.productService.productInfo.previousProcess !== 'policy') {
            if (this.productService.productInfo.sublineCd === 'COM' || this.productService.productInfo.sublineCd === 'PCP') {
                this.fbAnalyticsService.trackEvent('Motor_Quotation_ Email')
            } else if (this.productService.productInfo.sublineCd === 'CTPL') {
                this.fbAnalyticsService.trackEvent('Liability_ Quotation_Email')
            } else if (this.productService.productInfo.sublineCd === 'FPA') {
                this.fbAnalyticsService.trackEvent('Family_Quotation_ Email')
            } else if (this.productService.productInfo.sublineCd === 'IND') {
                this.fbAnalyticsService.trackEvent('Personal_Quotation_ Email')
            } else if (this.productService.productInfo.sublineCd === 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_Quotation_ Email')
            }
        }
    }

    move(): void {
        if (!this.productService.productInfo.policyId
            && !(this.productService.productInfo.lineCd === "PA" && this.productService.productInfo.quoteStatCd === 3 && this.productService.productInfo.previousProcess === "policy")
            && !(this.productService.productInfo.lineCd === "MC" && this.productService.productInfo.quoteStatCd === 3 && this.productService.productInfo.previousProcess === "policy")
        ) {
            this.router.navigate(["/quotation/review-premium-charges"]);
        } else if (this.productService.productInfo.lineCd === "MC" && this.productService.productInfo.quoteStatCd === 3) {
            this.productService.setProductInfo("creationProcess", "policy");
            this.router.navigate(["/policy/vehicle-details"]);
        } else if (this.productService.productInfo.lineCd === "PA" && this.productService.productInfo.quoteStatCd === 3 && this.productService.productInfo.previousProcess === "policy") {
            this.productService.setProductInfo("creationProcess", "policy");
            this.router.navigate(["/policy/personal-details"]);
        } else {
            if(this.productService.productInfo?.quoteStatCd === 5 && this.productService.productInfo?.polStatCd === 7) { 
                this.productService.setProductInfo("creationProcess", "expired");
            } else {
                this.productService.setProductInfo("creationProcess", "policy");
            }
            this.router.navigate(["/policy/summary"]);
        }
    }

    disableFieldsonStat(status: number): void {
        if (status >= 3) {
            this.holderForm.disable();
            this.holderForm.controls['displayName'].disable();
            this.holderForm.controls['address'].disable();
            this.showLov = false;
            this.stepperDtlService.stepperDtlBtns[2] && (this.stepperDtlService.stepperDtlBtns[2].disabled = false);
            this.disableEmail = false;
        } else {
            this.holderForm.enable();
            if (this.userService.accountType !== "P") {
                this.holderForm.get("sameAcctTag")?.disable({ onlySelf: true, emitEvent: false });
            }
            this.showLov = !this.holderForm.get("sameAcctTag")?.value
            this.stepperDtlService.stepperDtlBtns[2].disabled = true;
            this.disableEmail = true;
        }
        this.holderForm.updateValueAndValidity();
    }

    updateDefaultEmail(): void {
        let email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
            policyEmail.lineCd == this.productService.productInfo.lineCd &&
            policyEmail.sublineCd == this.productService.productInfo.sublineCd &&
            policyEmail.emailType == 2)[0];
        let emailSubject = email.emailSubject.split('P_DISPLAY_NAME').join(this.holderForm.get("displayName")?.value);
        emailSubject = emailSubject.split('P_QUOTE_NO').join(this.productService.productInfo.quoteNo);
        emailSubject = emailSubject.split('P_FIRST_NAME').join(this.productService.productInfo?.customerInfo?.firstName || this.getFirstName());
        let emailBody = email.emailBody.split('P_FIRST_NAME').join(this.productService.productInfo?.customerInfo?.firstName || this.getFirstName() || this.salutationName);
        emailBody = emailBody.split('P_PLAN_DESC').join(this.planName);

        let recipient = '';
        let cc = '';
        if (this.userService.accountType == 'P') {
            recipient = this.userService.email;
        } else {
            recipient = this.defaultEmail || this.productService.productInfo?.customerInfo?.emailAddress;
            cc = this.userService.email;
        }

        this.emailForm.patchValue({
            subject: emailSubject,
            emailContent: emailBody,
            recipient: recipient,
            cc: cc
        });
    }

    openEmail(): void {
        if (!this.disableEmail) {
            this.updateDefaultEmail();
            this.emailOverlay.open();
        }
    }

    getFirstName() {
        if (this.holderForm.get("displayName")?.value != "") {
            let word = this.holderForm.get("displayName")?.value;
            return word.split(" ")[0]
        }
        else {
            return '';
        }
    }

    onLovOk(data: any): void {
        this.holderForm.patchValue({
            displayName: data["displayName"],
            address: data["addressPt1"],
            assdNo: data["assdNo"],
        });
        this.holderForm.markAsDirty();
    }

    savePolicyHolder(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.validateCoverage({
            quoteId: this.quoteId
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if (res.rec === 'INVALID') {
                            this.dialogBoxService.open({
                                messageType: "E",
                                content: "There are changes in Coverage Screen that requires saving. Please click OK to return.",
                                buttons: [{
                                    text: "OK", action: () => {
                                        this.router.navigate(["/quotation/coverage-perils"]);
                                    }
                                }]
                            });
                        } else {
                            if (this.holderForm.status === "VALID" || this.holderForm.status === "DISABLED") {
                                if (this.holderForm.dirty) {
                                    const form = this.holderForm.getRawValue();

                                    this.jsonDataService.contorlLoading(true);
                                    this.apiCallService.savePolicyHolder({
                                        quoteId: this.quoteId,
                                        sameAcctTag: form.sameAcctTag,
                                        displayName: form.displayName,
                                        address: form.address,
                                        assdNo: form.assdNo
                                    }).subscribe((response: any) => {
                                        this.jsonDataService.contorlLoading(false);
                                        this.securityService.checkRequestKeyResponse(response, () => {
                                            if (response.status === "SUCCESS") {
                                                this.securityService.hasValidCsrfToken(response, () => {
                                                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                                                    this.generateReport(true);
                                                    this.holderForm.markAsPristine();
                                                    this.holderForm.patchValue({
                                                        displayName: res.displayName,
                                                        address: (res.addressPt1 || "") + (res.addressPt2 || "") + (res.addressPt3 || "") + (res.addressPt4 || "")
                                                    });
                                                    this.defaultEmail = res.emailAddress;

                                                    let policyHolderDetails = this.productService.productInfo.policyHolderDetails || {};
                                                    policyHolderDetails.quote = policyHolderDetails.quote || {};
                                                    policyHolderDetails.quote.displayName = this.holderForm.get("displayName")?.value;
                                                    policyHolderDetails.quote.address = this.holderForm.get("address")?.value;
                                                    policyHolderDetails.policy = policyHolderDetails.policy || {};
                                                    policyHolderDetails.policy.displayName = this.holderForm.get("displayName")?.value;
                                                    policyHolderDetails.policy.address = this.holderForm.get("address")?.value;
                                                    this.productService.setProductInfo("policyHolderDetails", policyHolderDetails);

                                                    this.appMessageService.showAppMessage("Successfully saved.", "success");
                                                });
                                            } else {
                                                this.appMessageService.showAppMessage("Error saving your policy holder.", "info");
                                            }
                                        });
                                    });
                                } else {
                                    this.onClickPrint();
                                }
                            } else {
                                this.appMessageService.showAppMessage("There are missing information. Please provide necessary information needed.", "error");
                            }
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("Error in validating your information..", "error");
                }
            });
        });
    }

    initialLoad: boolean = true;
    onClickPrint(): void {
        if (this.initialLoad) {
            this.initialLoad = false;
        } //else {   remove another succeeding IF kapag error occured, reinstate ELSE
        if (!this.initialLoad) {
            if (this.holderForm.pristine && !this.holderForm.get('assdNo')?.value && !this.holderForm.get('sameAcctTag')?.value && !this.holderForm.get('displayName')?.value) {
                this.jsonDataService.contorlMsgBox('info', 'Please save your quote before printing.', 'Notice');
            } else if (this.holderForm.dirty) {
                this.jsonDataService.contorlMsgBox('info', 'Please save your quote before printing.', 'Notice');
            } else if (this.productService.productInfo.quoteStatCd == 1) {
                this.confirmPrint.open("Edit Draft Quotation");
                window.open(this.pdfUrl);
            } else {
                window.open(this.pdfUrl);
            }
        }
    }

    generateReport(toClickPrint?: boolean): void {
        this.noHolder = false;
        let lineCode: string = "";
        let reportId: string = "";
        if (this.productService.productInfo.lineCd === "MC") {
            lineCode = "Motor";
            reportId = "BMR001";
        }
        else if (this.productService.productInfo.lineCd === "PA") {
            lineCode = "Accident";
            reportId = "BMR001B";
        }
        else if (this.productService.productInfo.lineCd === "CI") {
            lineCode = "HCIP";
            reportId = "BMR001C";
        }
        else if (this.productService.productInfo.lineCd === "HA") {
            lineCode = "HomeCare";
            reportId = "BMR001A";
        }

        this.apiCallService.printPDF({
            reportType: reportId,
            quoteId: this.quoteId,
            lineCd: lineCode,
            userId: this.userService.userId
        }).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                let link = response.link.split("/");
                this.encryptedFolderName = response.link.split("/")[3];
                link[3] = encodeURIComponent(link[3]);
                link = link.join("/");

                const bssid = sessionStorage.getItem("bssid") || "1";
                const encryptedFolderName = link.split("/")[3];
                const token = encryptedFolderName.substr(30, 1) + bssid.substr(30, 1) +
                    encryptedFolderName.substr(10, 1) + bssid.substr(10, 1) +
                    encryptedFolderName.substr(40, 1) + bssid.substr(40, 1) +
                    encryptedFolderName.substr(15, 1) + bssid.substr(15, 1) +
                    encryptedFolderName.substr(35, 1) + bssid.substr(35, 1) +
                    encryptedFolderName.substr(5, 1) + bssid.substr(5, 1) +
                    encryptedFolderName.substr(25, 1) + bssid.substr(25, 1) +
                    encryptedFolderName.substr(20, 1) + bssid.substr(20, 1);

                const param = this.jsonDataService.encrypt({
                    uid: sessionStorage.getItem("bssid") || "",
                    token: token,
                    dateCreated: new Date()
                });

                this.pdfUrl = `https://${link}?v=${this.randomizer()}&report=${param}`;
                if (toClickPrint) {
                    this.onClickPrint();
                }
            } else {
                this.appMessageService.showAppMessage("There's something wrong generating the report. Please contact BPI MS for more information.", "error");
            }
        });
    }

    generateQuoteNo(): void {
        // this.fbAnalyticsService.trackEvent('SubmitApplication');
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.generateQuoteNo({
            quoteId: this.quoteId
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const quoteNo = this.jsonDataService.decrypt(response.response);
                        this.productService.setQuoteStat(3);
                        this.productService.setProductInfo("quoteNo", quoteNo);
                        //this.quoteNo = quoteNo; for overlay

                        this.generateReport();
                        //this.quoteMessage.open("");
                        this.disableFieldsonStat(3);
                        this.messageBoxService.show1(
                            "Quotation Successfully Generated",
                            'Your Quotation No. <b>' + quoteNo + '</b> was successfully generated. You may opt to send a copy of the quotation to your Email address or proceed to E-policy issuance and premium payment.',
                            "Close",
                            ""
                        );
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in generating your quotation number. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    randomizer(): number {
        let num: number = Math.floor((Math.random() * 100) + 1);
        while (true) {
            if (num == this.numReloader) {
                num = Math.floor((Math.random() * 100) + 1);
            } else {
                this.numReloader = num;
                break;
            }
        }
        return num;
    }

    sendMail(): void {
        const productInfo = this.productService.productInfo;
        let header = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
            policyEmail.lineCd == productInfo.lineCd &&
            policyEmail.sublineCd == productInfo.sublineCd &&
            policyEmail.emailType == 2)[0]?.headerImgPath;
        header = "https://" + window.location.hostname + header;
        header = header.replace(/\\/g, "/");

        this.emailForm.get('recipient')?.setValue(this.emailForm.get('recipient')?.value.trim());
        this.emailForm.get('cc')?.setValue(this.emailForm.get('cc')?.value.trim());

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendMail({
            ...this.emailForm.getRawValue(),
            quoteId: this.quoteId,
            emailType: 2,
            userId: this.userService.userId,
            folderName: this.encryptedFolderName,
            header: header,
            formName: 'email-print', //? indicator
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                this.securityService.hasValidCsrfToken(response, () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (response.status === "SUCCESS") {
                        this.emailSent = true;
                        this.messageBoxService.show1("S", res.message, "Close", "");
                    } else {
                        this.messageBoxService.show1("E", res.message, "Close", "");
                    }
                });
            });
        });
        this.emailOverlay.close();
    }

    validateReEmail(): void {
        if (this.emailSent) {
            this.confirmReEmail.open();
        } else {
            this.sendMail();
        }
    }

    toDashboardd() {
        this.router.navigate(["/dashboard"]);
    }

    toPolcy() {
        this.productService.setProductInfo("creationProcess", "policy");

        if (this.productService.productInfo.lineCd === "PA" || this.productService.productInfo.lineCd === "CI") {
            this.router.navigate(["/policy/customer-information"]);
        } else if (this.productService.productInfo.lineCd === "MC") {
            this.router.navigate(["/policy/vehicle-details"]);
        } else if (this.productService.productInfo.lineCd === "HA") {
            this.router.navigate(["/policy/property-details"]);
        }
    }
}
