import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StepperDtlService {

    showStepperDtl: boolean = true;
    stepperDtlStepNo: number = 1;
    titleStepperDtl: string = "";
    stepperDtlBtns: any = [];
    childInstance: any; 
    showStepNo: boolean = true;

}