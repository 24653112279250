import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

import { ParameterService } from 'src/app/services/parameter.service';

@Directive({
    selector: '[rmNo]',
})
export class RmNoDirective implements AfterViewInit {
    formControl?: AbstractControl;
    rmLength: number = 0;
    constructor (
        private el: ElementRef,
        private control: NgControl,
        private parameterService: ParameterService
    ) { 
        this.rmLength = this.parameterService.paramN('RM_NO_LENGTH');
    }

    ngAfterViewInit():void {
        this.el.nativeElement.placeholder = 'RM No.';
        setTimeout(() => {
            this.control.control?.setValidators(this.control && this.control?.control?.validator ? [this.control.control?.validator, rmNoValidator()] : [rmNoValidator()]);
            this.control.control?.updateValueAndValidity();
        }, 0);
    }

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener("keydown", ["$event"])
    onKeyDown(e:KeyboardEvent) {
        if (
               this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
            || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
            || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
            || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
            || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
            || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
            || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
            || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
            || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        if (e.shiftKey || (!parseInt(e.key) && parseInt(e.key) !== 0)) {
            e.preventDefault();
        }
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput = event.clipboardData ? this.formatRmNo(event.clipboardData.getData("text/plain")) : '';
        pastedInput && document.execCommand("insertText", false, pastedInput);
    }

    @HostListener("blur", ["$event"])
    onBlur() {
        if (this.control.value) {
            this.control.control?.setValue((this.formatRmNo(this.control.value) + ""));
        }
    }

    private formatRmNo(str: string): string {
        return str.padStart(this.rmLength, "0");
    }
}

export function rmNoValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const allowed = /[0-9]$/g.test(control.value) || control.value == '';
        return !allowed ? { 'rmNo': { value: true } } : null;
    };
}