<section id="email-print" class="proxima">
    <div class="container">
        <div class="form-wrapper">
            <iframe [src]="pdfUrl + '#toolbar=0' | safe:'resourceUrl'" width="100%" height="500px" *ngIf="pdfUrl!=''">
            </iframe>
            <div class="spinner-container" *ngIf="pdfUrl==''">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <div class="instructions-bottom">
            {{instructions}}
        </div>
    </div>
    <div class="container">
        <form>
            <div class="form-btns">
                <button class="btn btn4" (click)="toPolicy()">Back</button>
                <button class="btn btn2" (click)="onClickPrint()">
                    View PDF
                </button>
                <button class="btn btn2" (click)="openEmail()" [disabled]="this.disableEmail">Send Email</button>
            </div>
        </form>
    </div>
</section>

<overlay #emailOverlay [title]="'Email Policy'">
    <div content id="email-overlay">
        <form form [formGroup]="emailForm">
            <div class="form-note">
                <p>{{emailNote}}</p>
            </div>
            <div class="form">
                <div class="col-1">
                    <div class="field required">
                        <p class="field-desc">To</p>
                        <email-list formControlName="recipient" class="mat-form-field-appearance-fill required"></email-list>
                        <p class="field-error" *ngIf="formService.isInvalid(emailForm, 'recipient')">
                            {{formService.getFieldErrorMsg(emailForm, "recipient")}}
                        </p>
                    </div>
                    <div class="field">
                        <p class="field-desc">Cc</p>
                        <email-list formControlName="cc"></email-list>
                        <p class="field-error" *ngIf="formService.isInvalid(emailForm, 'cc')">
                            {{formService.getFieldErrorMsg(emailForm, "cc")}}
                        </p>
                    </div>
                    <div class="field required">
                        <p class="field-desc">Subject</p>
                        <mat-form-field appearance="fill" class="required">
                            <input matInput class='weighted' formControlName="subject">
                        </mat-form-field>
                    </div>
                    <div class="field required">
                        <p class="field-desc">Message</p>
                        <mat-form-field appearance="fill" class="required">
                            <textarea matInput rows="5" formControlName="emailContent"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary btn" (click)="sendMail()" [disabled]="!emailForm.valid">Send Email</button>
</overlay>

<overlay #confirmPrintInfo [title]="'Confirmation'">
    <div content>
        Was the policy printed correctly? If “Yes”, kindly reprint the Policy Report to generate file with Policy Number.
    </div>
    <button mat-button buttons class="btn-primary" (click)="confirmPrintInfo.close()">Yes</button>
</overlay>

<lov #lov (selected)="onLovOk($event)" [type]="'clients'" [params]="lovParams"></lov>