import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
	selector: 'sub-reset',
  	templateUrl: './sub-reset.html'
})
export class SubResetComponent {

    @Input() parentChildCommon:any;

    showNewPasswordValue:boolean = false;
    showRepeatPasswordValue:boolean = false;
    isRequestValid:boolean = true;
    isPasswordValid:boolean = false;

    //GET THIS FROM JSON DATA SERVICE LATER
    passwordReq: any = {
        minLength: 8,
        maxLength: 10,
        minLetter: 1,
        minDigit: 1,
        minSpecial: 1,
        minUppercase: 1,
        minLowercase: 1,
    };

    passwordReqState: any = {
        isMinLength: true,
        isMaxLength: true,
        hasMinLetter: true,
        hasMinDigit: true,
        hasMinSpecial: true,
        hasMinUppercase: true,
        hasMinLowercase: true
    }

    form:FormGroup = this.formBuilder.group ({
        newPassword: ["", [Validators.required]],
        repeatPassword: ["", [Validators.required]]
    });

    varRegLogButtons = document.querySelector("#login-register .container:not(.solo) > .buttons");

  	constructor (
    	private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private jsonDataService: JsonDataService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        public formService: FormService,
        private fbAnalyticsService: FacebookEventAnalyticsService
  	) { }

    // MOVE THIS FUNCTION TO login.ts
    processPassword():void {
        this.formService.hideFormMsg("resetFormMsg");
        if (this.form.valid) {
            let npw = this.form.value.newPassword;
            let rpw = this.form.value.repeatPassword;

            if (npw === rpw) {
                if (this.varRegLogButtons !== null) {
                    this.varRegLogButtons.classList.add("hide");
                }

                this.resetPassword({ npw: npw, rpw: rpw });
                // this.formService.showFormLoader(this, "sub-reset-form", "Saving your new password.<br>Please wait ...", "resetPassword", {
                //     npw: npw,
                //     rpw: rpw
                // });
            } else {
                this.formService.showFormMsg("resetFormMsg", "Passwords don't match.", "E");
            }
        } else {
            this.formService.showFormMsg("resetFormMsg", "Please fill all the required fields.", "E");
        }
    }

    // MOVE THIS FUNCTION TO login.ts
    resetPassword(params:any):void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.resetPassword({
            un: this.userDetailsService.userId,
            rqId: this.parentChildCommon.requestId,
            npw: params.npw,
            rpw: params.rpw
        }).subscribe((response:any) => {
            if (response.status == "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (res.status == "SUCCESS") {
                        // this.fbAnalyticsService.trackEvent('CompleteRegistration');
                        this.appMessageService.showAppMessage("Password saved successfully.", "success");

                        if (res.accountType == "P") {
                            this.parentChildCommon.userName = res.email;
                        } else {
                            this.parentChildCommon.userName = res.userId;
                        }

                        this.parentChildCommon.accountType = res.accountType;
                        this.login(params.npw);
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.formService.showFormMsg("resetFormMsg", res.message, "E");
                    }
                });
            } else {
                this.jsonDataService.contorlLoading(false);
                this.formService.showFormMsg("resetFormMsg", response.message, "E");
            }
        });
    }

    login(npw:string):void {
        this.parentChildCommon.loginFunction(npw).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            // this.hideLoaderShowForm();
            this.formService.showFormMsg("resetFormMsg", response, "E");
        });
    }

    hideLoaderShowForm():void {
        this.formService.hideFormLoader("sub-reset-form");
        if (this.varRegLogButtons !== null) {
            this.varRegLogButtons.classList.remove("hide");
        }
    }

    //CHANGE THIS TO DIRECTIVES LATER
    checkPassword() {
        if(this.form.value.newPassword) {
            let npw = this.form.value.newPassword;

            let pwdReq = this.passwordReq;
            let pwsReqState = this.passwordReqState;

            let letterCount = 0;
            let numberCount = 0;
            let specialCharCount = 0;
            let lowerCharCount = 0;
            let upperCharCount = 0;

            npw.split("").forEach((value: any) => {
                if (value.search(/[a-z]/i) >= 0) { letterCount++; }
                if (value.search(/[0-9]/) >= 0) { numberCount++; }
                if (value.search(/[!@#$%^&*]/) >= 0) { specialCharCount++; }
                if (value.search(/[a-z]/) >= 0) { lowerCharCount++; }
                if (value.search(/[A-Z]/) >= 0) { upperCharCount++; }
            });

            npw.length < pwdReq.minLength && pwdReq.minLength !== 0 ? pwsReqState.isMinLength = false : pwsReqState.isMinLength = true;
            npw.length > pwdReq.maxLength && pwdReq.maxLength !== 0 ? pwsReqState.isMaxLength = false : pwsReqState.isMaxLength = true;
            letterCount < pwdReq.minLetter ? pwsReqState.hasMinLetter = false : pwsReqState.hasMinLetter = true;
            numberCount < pwdReq.minDigit ? pwsReqState.hasMinDigit = false : pwsReqState.hasMinDigit = true;
            specialCharCount < pwdReq.minSpecial ? pwsReqState.hasMinSpecial = false : pwsReqState.hasMinSpecial = true;
            lowerCharCount < pwdReq.minLowercase ? pwsReqState.hasMinLowercase = false : pwsReqState.hasMinLowercase = true;
            upperCharCount < pwdReq.minUppercase ? pwsReqState.hasMinUppercase = false : pwsReqState.hasMinUppercase = true;

            this.passwordReqState = pwsReqState;

            this.validatePassword();
        }
    }

    validatePassword(): void {
        for (var reqs in this.passwordReqState) {
            if(!this.passwordReqState[reqs]) {
                this.isPasswordValid = false;
                break;
            };
            this.isPasswordValid = true;
        }
    }

    resendExpired():void{
        this.parentChildCommon.sendRecoveryEmail({requestId:this.parentChildCommon.requestId});
    }
}