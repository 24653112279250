import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AutocompleteService {

    set(form:FormGroup, field:string, options:any):Observable<any> {
        return form.valueChanges.pipe(
            startWith(""),
            map(form => this._filter(form[field], options))
        );
    }

    setArray(form:FormGroup, field: string, options:any, formField: string, i:number):Observable<any> {
        var arrayControl = form.get(formField) as FormArray;
        return arrayControl.at(i).valueChanges.pipe(
            startWith(""),
            map(form => this._filter(form[field], options))
        );
    }

    setFieldText(option:any):string {
        return option.text;
    }

    setFieldValue(option:any):string {
        return option.value;
    }
    private _filter(field:any, options:any):any[] {
        let fieldValue:any = "";
        if (typeof field === "string") {
            fieldValue = field.toLowerCase();
        } else if (typeof field === "object") {
            fieldValue = field.text.toLowerCase();
        }

        return options.filter((option:any) => option.text.toLowerCase().includes(fieldValue));
    }
}