import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { mimeTypes } from 'mime-wrapper';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormService } from 'src/app/services/form.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { SecurityService } from 'src/app/services/security.service';
import { DownloadService } from 'src/app/services/download.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
    templateUrl: './property-assessment.html'
})
export class PropertyAssessmentComponent {

    form: FormGroup = this.formBuilder.group({
        isMORT: [""],
        bankDetail: [""],
        loanAccountNo: [""],
        province: [""],
        city: [""],
        subdivision: [""],
        isASUBD: [""],
        isNSUBD: [""],
        subdivName: [""],
        subdivAddress: [""],
        houseNo: [""],
        street: [""],
        brgy: [""],
        roof: [""],
        wall: [""],
        numFLR: [""],
        numROOF: [""],
        numWALL: [""],
        isCB1: [""],
        isCB2: [""],
        isCB3: [""],
        isHIST: [""],
        numHIST: [""],
        numYear: [""],
        isHH1: [""],
        isHH4: [""],
        supportingDocs: [[]],
        emailAttachment: [[]],
        suppDocs: [[]],
    });

    productInfo: any = {};
    wordings: any = [];
    roofOptions: any = [];
    wallOptions: any = [];
    wallPropertyDtl: any = [];
    allowedFileExt: any[] = [];
    supportingDocsFiles: any[] = [];
    emailAttachment: any[] = [];
    allowedFileSize: number = 0;
    imagePath: string = "";
    answerYes: string = "";
    answerNo: string = "";
    nextBtnLabel: string = "";
    title: string = "";
    question1: string = "";
    question2: string = "";
    question3: string = "";
    sentence1: string = "";
    sentence2: string = "";
    sentence3: string = "";
    supportDocErrorMsg: string = "";
    mortCaveat: string = "";
    mortLink: string = "";
    mortLinkText: string = "";
    reqForQuMsg: string = "";
    reqForQuBank: any = this.parameterService.paramV('REQ_FOR_QU_BANK')?.split(',');
    reqForQuBankSw: any[] = this.parameterService.paramV('REQ_FOR_QU_BANK_SW')?.split(',') ?? [];

    doNotShowAgain: boolean = false;
    showQuestion1: boolean = true;
    isQuestion1FieldsRequired: boolean = true;
    showQuestion2: boolean = false;
    isSubdivOthers: boolean = false;
    isSubdivSecured: boolean = true;
    isHazardHunter: boolean = false;
    showQuestion2subdiv: boolean = false;
    showQuestion3: boolean = false;
    showQuestion4: boolean = false;
    isQuestion4FieldsRequired: boolean = true;
    showQuestion5: boolean = false;
    showQuestion6: boolean = false;
    questionNo: number = 0;
    showNotInsurable: boolean = false;
    showRequestQuPrompt: boolean = false;
    withSupportingDocChange: boolean = false;
    nextDisabled: boolean = true;
    showCaveat: boolean = false;
    isAllowedNonSubd: boolean = this.parameterService.paramV('ALLOWED_NON_SUBD')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P') != -1;

    cbCondition1: string = "";
    cb1AnsYes: string = "";
    cb1AnsNo: string = "";
    cbCondition2: string = "";
    cb2AnsYes: string = "";
    cb2AnsNo: string = "";
    cbCondition3: string = "";
    cb3AnsYes: string = "";
    cb3AnsNo: string = "";
    isCB1Check: boolean = false;
    isCB2Check: boolean = false;
    isCB3Check: boolean = false;

    // AUTO-COMPLETE OPTIONS
    filteredBankDetailOptions: Observable<any> = new Observable;
    filteredProvinceOptions: Observable<any> = new Observable;
    filteredCityOptions: Observable<any> = new Observable;
    filteredSubdivisionOptions: Observable<any> = new Observable;

    constructor(
        // PUBLIC
        public userDetailsService: UserDetailsService,
        public formService: FormService,
        public autocompleteService: AutocompleteService,
        public parameterService: ParameterService,

        // PRIVATE
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private productService: ProductService,
        private jsonDataService: JsonDataService,
        private formBuilder: FormBuilder,
        private securityService: SecurityService,
        private downloadService: DownloadService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.productInfo = this.productService.productInfo;
        this.setWordings();
        this.setPageDisplay();
        this.allowedFileExt = this.parameterService.paramV("RISK_DTL_FILE_EXT").split(",");
        this.allowedFileSize = this.parameterService.paramN("RISK_DTL_FILE_SIZE");
        this.setMortNotice();
        this.stepperDtlService.showStepperDtl = false;
        this.pageValidationService.validatePageAccessibility();
        this.reqForQuMsg = this.parameterService.paramV('REQUEST_FOR_QU_MSG') ?? "";
    }

    private setWordings(): void {
        this.wordings = this.jsonDataService.data.propAssessmentWordings.filter((a: any) => a.language == this.productInfo.language);
    }

    private setMortNotice(): void {
        this.mortCaveat = this.parameterService.paramV("MORT_ACCT_TEXT") || "";
        this.mortLinkText = "For mortgaged properties, click/tap here for further reminders.";
        this.mortLink = this.parameterService.paramV("MORT_ACCT_LINK") || "";
        if (this.productInfo.language === 'T') {
            this.mortCaveat = this.parameterService.paramV("MORT_ACCT_TEXT2") || this.parameterService.paramV("MORT_ACCT_TEXT") || "";
            this.mortLinkText = "Para sa property na naka loan o planong ipasok sa isang loan contract, maaaring magclick/magtap dito para sa mas detalyadong reminders.";
            this.mortLink = this.parameterService.paramV("MORT_ACCT_LINK2") || this.parameterService.paramV("MORT_ACCT_LINK") || "";
        }
    }

    private setPageDisplay(): void {
        if (this.productInfo.planEvaluated === 1) {
            this.showQuestion1 = false;
            this.showQuestion3 = true;
            this.reloadForm();
            this.displayQuestion3();
        } else if (this.productInfo.planEvaluated === 4) {
            this.showQuestion1 = false;
            this.showQuestion4 = true;
            this.reloadForm();
            this.displayQuestion4();
        } else if (this.productInfo.planEvaluated === 2) {
            this.showQuestion1 = false;
            this.showQuestion5 = true;
            this.reloadForm();
            this.displayQuestion5();
        } else if (this.productInfo.planEvaluated === 3) {
            this.showQuestion1 = false;
            this.showQuestion6 = true;
            this.reloadForm();
            this.displayQuestion6();
        } else if (this.productInfo.planEvaluated === 10) {
            this.showQuestion1 = false;
            this.reloadForm();
            this.displayRequestQu();
        } else if (this.productInfo.planEvaluated === 11) {
            this.productInfo.propertyAssessment.isCOV = 'Y';
            this.showQuestion1 = false;
            this.reloadForm();
            this.displayNotInsurable();
        } else if (this.productInfo.planEvaluated === 9) {
            this.showQuestion1 = false;
            this.reloadForm();
            this.displayNotInsurable();
        } else {
            this.productInfo.planEvaluated = 0;
            this.productInfo.propertyAssessment = this.form.getRawValue();
            this.productService.setProductInfo2(this.productInfo);
            this.displayQuestion1();
        }
    }

    private displayQuestion1(): void {
        if (this.productService.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Quotation_ Property_Assessment1')
        }

        this.filteredBankDetailOptions = this.autocompleteService.set(this.form, "bankDetail", this.getBanks());
        this.form.patchValue({
            isMORT: this.productInfo.propertyAssessment.isMORT || "Y"
        });

        this.showCaveat = this.form.get("isMORT")?.value === "Y" ? true : false;

        for (const q of this.wordings) {
            if (q.assessmentCd === 'MORT') {
                this.imagePath = q.imagePath.substr(1);
                this.title = q.title;
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
                this.question1 = q.wordings;
                this.nextBtnLabel = "Next Question";
            }
        }
        this.validateAnswer1();
        this.questionNo = 1;
    }

    validateAnswer1(): void {
        if (this.form.get("isMORT")?.value === 'N') {
            this.nextDisabled = false;
        } else if (this.form.get("isMORT")?.value === 'Y') {
            //if (this.form.get("bankDetail")?.value?.value && this.form.get("loanAccountNo")?.value) {
            if (this.form.get("bankDetail")?.value?.value) {
                this.nextDisabled = false;
            } else {
                this.nextDisabled = true;
            }
        }
    }

    private displayQuestion2(): void {
        if (this.productService.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Quotation_ Property_Assessment2')
        }

        this.form.get('city')?.disable();
        this.form.get('subdivision')?.disable();
        this.nextDisabled = true;
        this.nextBtnLabel = "Next Question";
        this.filteredProvinceOptions = this.autocompleteService.set(this.form, "province", this.getProvinces());
        if (this.form.get("city")?.value?.value && this.form.get("city")?.value?.value) {
            this.filteredCityOptions = this.autocompleteService.set(this.form, "city", this.getCities(this.form.get("province")?.value?.value));
            this.filteredSubdivisionOptions = this.autocompleteService.set(this.form, "subdivision", this.getSubdivisions());
        }
        for (const q of this.wordings) {
            if (q.assessmentCd === 'ASUBD') {
                this.imagePath = q.imagePath.substr(1);
                this.title = q.title;
                this.question1 = q.wordings;
            }
            if (q.assessmentCd === 'NSUBD') {
                this.question2 = q.wordings;
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
            }
            if (q.assessmentCd === "CB1") {
                this.cbCondition1 = q.wordings;
                this.cb1AnsYes = q.answerYes;
                this.cb1AnsNo = q.answerNo;
            }
            if (q.assessmentCd === "CB2") {
                this.cbCondition2 = q.wordings;
                this.cb2AnsYes = q.answerYes;
                this.cb2AnsNo = q.answerNo;
            }
            if (q.assessmentCd === "CB3") {
                this.cbCondition3 = q.wordings;
                this.cb3AnsYes = q.answerYes;
                this.cb3AnsNo = q.answerNo;
            }
        }
        this.validateAnswer2();
        this.questionNo = 2;
    }

    validateAnswer2(): void {
        if (!(this.form.get("city")?.value?.value && this.form.get("city")?.value?.value)) {
            this.form.get('city')?.disable();
            this.form.get('subdivision')?.disable();
        }
        if (this.form.get("province")?.value?.value) {
            this.form.get('city')?.enable();
        }
        if (this.form.get("city")?.value?.value) {
            this.form.get('subdivision')?.enable();
        }

        // if (this.form.get("subdivision")?.value?.value && this.form.get("city")?.value?.value && this.form.get("city")?.value?.value !== 0) {
        //     this.nextDisabled = false;
        // } else {
        //     if (this.form.get("isNSUBD")?.value === 'Y' && this.form.get("subdivName")?.value && (this.form.get("houseNo")?.value)) {
        //         this.nextDisabled = false;
        //     } else if (this.form.get("isNSUBD")?.value === 'N' && (this.form.get("houseNo")?.value)) {
        //         this.nextDisabled = false;
        //     } else {
        //         this.nextDisabled = true;
        //     }
        // }

        if (this.form.get("subdivision")?.value?.value === 0) {
            this.isSubdivOthers = true;
        } else {
            this.isSubdivOthers = false;
        }

        if ((this.form.get("city")?.value?.value && (this.form.get("subdivision")?.value?.value || this.form.get("subdivision")?.value?.value == 0))
            && !([this.form.get('isCB1')?.value, this.form.get('isCB2')?.value, this.form.get('isCB3')?.value].includes(''))) {
            this.nextDisabled = false;
        }
        else {
            this.nextDisabled = true;
        }
    }

    setQuestion2AnsYN(ans: any, param: string) {
        if (param === 'CB1') {
            if (ans === 'Y') {
                this.form.patchValue({ isCB1: "Y" });
            } else {
                this.form.patchValue({ isCB1: "N" });
            }
        } else if (param === 'CB2') {
            if (ans === 'Y') {
                this.form.patchValue({ isCB2: "Y" });
            } else {
                this.form.patchValue({ isCB2: "N" });
            }
        } else if (param === 'CB3') {
            if (ans === 'Y') {
                this.form.patchValue({ isCB3: "Y" });
            } else {
                this.form.patchValue({ isCB3: "N" });
            }
        }

        this.form.patchValue({
            isCB1: this.cbCondition1 ? this.form.get('isCB1')?.value : 'Y',
            isCB2: this.cbCondition2 ? this.form.get('isCB2')?.value : 'Y',
            isCB3: this.cbCondition3 ? this.form.get('isCB3')?.value : 'Y',
        });

        this.validateAnswer2();
    }

    private displayQuestion2Subdiv(): void {
        this.nextDisabled = true;
        this.nextBtnLabel = "Next Question";
        for (const q of this.wordings) {
            if (q.assessmentCd === 'ASUBD') {
                this.imagePath = q.imagePath.substr(1);
                this.title = q.title;
            }
            if (q.assessmentCd === 'NSUBD') {
                this.question1 = q.wordings;
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
            }
        }
        this.validateAnswer2subdiv();
    }

    validateAnswer2subdiv(): void {
        if (this.form.get("isNSUBD")?.value === 'Y' && this.form.get("subdivName")?.value && (this.form.get("houseNo")?.value)) {
            this.nextDisabled = false;
        } else if (this.form.get("isNSUBD")?.value === 'N' && (this.form.get("houseNo")?.value)) {
            this.nextDisabled = false;
        } else {
            this.nextDisabled = true;
        }
    }

    private displayQuestion3(): void {
        if (this.productService.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Quotation_ Property_Assessment3')
        }

        this.nextDisabled = true;
        this.nextBtnLabel = "Next: Generate Packages";
        for (const q of this.wordings) {
            if (q.assessmentCd === 'ROOF') {
                this.question1 = q.wordings;
            }
            if (q.assessmentCd === 'WALL') {
                this.question2 = q.wordings;
            }
            if (q.assessmentCd === 'FLR') {
                this.question3 = q.wordings;
                this.title = q.title;
                this.imagePath = q.imagePath.substr(1);
            }
        }
        this.initializeRoofWallValues();
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.productService.setProductInfo2(this.productInfo);
        this.validateAnswer3();
        this.questionNo = 3;
    }

    private initializeRoofWallValues() {
        if (this.roofOptions.length === 0) {
            for (const q of this.jsonDataService.data.propertyDetails.filter((a: any) => a.propDtlType === 'R')) {
                if (this.productInfo.language === 'E') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'F') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.descFil,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'T') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.descTaglish,
                        classCd: q.classCd
                    });
                }
            }
            /*this.form.patchValue({
                numROOF: this.productInfo.propertyAssessment.numROOF || 1
            });*/
        }

        if (this.wallOptions.length === 0) {
            for (const q of this.jsonDataService.data.propertyDetails.filter((a: any) => a.propDtlType === 'W')) {
                if (this.productInfo.language === 'E') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'F') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'T') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                }
            }
            /*this.form.patchValue({
                numWALL: this.productInfo.propertyAssessment.numWALL || (this.roofOptions.length + 1)
            });*/
        }
        for (const r of this.roofOptions.filter((c: any) => c.value == this.form.get("numROOF")?.value)) {
            this.form.controls["roof"].setValue(r);
        }
        for (const r of this.wallOptions.filter((c: any) => c.value == this.form.get("numWALL")?.value)) {
            this.form.controls["wall"].setValue(r);
        }
    }

    validateAnswer3(): void {
        if (this.form.get("numFLR")?.value && !this.floorValidation()) {
            let roof = this.form.get("numROOF")?.value || "";
            let wall = this.form.get("numWALL")?.value || "";
            if (roof != "" && wall != "") {
                this.nextDisabled = false;
            } else {
                this.nextDisabled = true;
            }
        } else {
            this.nextDisabled = true;
        }
    }

    private displayQuestion4(): void {
        this.form.patchValue({
            isHIST: this.productInfo.propertyAssessment.isHIST || "Y"
        });
        for (const q of this.wordings) {
            if (q.assessmentCd === 'HIST') {
                this.imagePath = q.imagePath.substr(1);
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
                this.title = q.title;
                this.question1 = q.wordings;
                this.nextBtnLabel = "Next Question";
            }
        }
        this.validateAnswer4();
        this.questionNo = 4;
    }

    validateAnswer4(): void {
        if (this.form.get("isHIST")?.value === 'Y' && this.form.get("numYear")?.value && !this.yearValidation()) {
            this.nextDisabled = false;
            this.form.get('numYear')?.enable();
            this.isQuestion4FieldsRequired = true;
        } else if (this.form.get("isHIST")?.value === 'Y') {
            this.nextDisabled = true;
            this.form.get('numYear')?.enable();
            this.isQuestion4FieldsRequired = true;
        } else if (this.form.get("isHIST")?.value === 'N') {
            this.nextDisabled = false;
            this.form.get('numYear')?.disable();
            this.isQuestion4FieldsRequired = false;
        } else {
            this.nextDisabled = true;
            this.form.get('numYear')?.disable();
            this.isQuestion4FieldsRequired = false;
        }
    }

    private displayQuestion5(): void {
        this.nextDisabled = true;
        this.form.patchValue({
            isHH1: this.productInfo.propertyAssessment.isHH1 || "Y"
        });
        for (const q of this.wordings) {
            if (q.assessmentCd === 'HH1') {
                this.imagePath = q.imagePath.substr(1);
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
                this.title = q.title;
                this.question1 = q.wordings;
                this.nextBtnLabel = "Next Question";
            }
            if (q.assessmentCd === 'HH2') {
                this.sentence1 = q.wordings;
            }
            if (q.assessmentCd === 'HH3') {
                this.sentence2 = q.wordings;
            }
        }
        this.validateAnswer5();
        this.questionNo = 5;
    }

    private validateAnswer5() {
        this.nextDisabled = false;
    }

    private displayQuestion6(): void {
        this.nextDisabled = true;
        this.form.patchValue({
            isHH4: this.productInfo.propertyAssessment.isHH4 || "Y"
        });
        for (const q of this.wordings) {
            if (q.assessmentCd === 'HH4') {
                this.imagePath = q.imagePath.substr(1);
                this.answerYes = q.answerYes;
                this.answerNo = q.answerNo;
                this.title = q.title;
                this.question1 = q.wordings;
                this.nextBtnLabel = "Next: Generate Packages";
            }
            if (q.assessmentCd === 'HH5') {
                this.sentence1 = q.wordings;
            }
        }
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.productService.setProductInfo2(this.productInfo);
        this.validateAnswer6();
        this.questionNo = 6;
    }

    private validateAnswer6() {
        if (this.isHazardHunter && this.form.controls['supportingDocs'].value.length > 0) {
            this.nextDisabled = false;
        } else {
            this.nextDisabled = true;
        }
    }

    private displayNotInsurable(): void {
        if (this.productService.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Contact_Us')
        }

        this.showNotInsurable = true;
        this.nextBtnLabel = "Contact BPI MS";
        this.nextDisabled = false;
        for (const q of this.wordings) {
            if (q.assessmentCd === 'INS') {
                this.question1 = q.wordings;
            }
            if (q.assessmentCd === 'ASUBD') {
                this.imagePath = q.imagePath.substr(1);
            }
        }
    }

    private displayRequestQu(): void {
        if (this.productService.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Contact_Us')
        }
        
        this.showRequestQuPrompt = true;
        this.nextBtnLabel = "Contact BPI MS";
        this.nextDisabled = false;
        for (const q of this.wordings) {
            if (q.assessmentCd === 'ASUBD') {
                this.title = "";
                this.imagePath = q.imagePath.substr(1);
            }
        }
    }

    setRoofValue(roof: any) {
        this.form.patchValue({
            numROOF: roof
        });
        for (const r of this.roofOptions.filter((c: any) => c.value == roof)) {
            this.form.controls["roof"].setValue(r);
        }
        this.validateAnswer3();
    }

    setWallValue(wall: any) {
        this.form.patchValue({
            numWALL: wall
        });
        for (const w of this.wallOptions.filter((w: any) => w.value == wall)) {
            this.form.controls["wall"].setValue(w);
        }
        this.validateAnswer3();
    }

    reloadForm() {
        this.form.patchValue({
            isMORT: this.productInfo.propertyAssessment.isMORT,
            isASUBD: this.productInfo.propertyAssessment.isASUBD,
            isNSUBD: this.productInfo.propertyAssessment.isNSUBD,
            isCB1: this.productInfo.propertyAssessment.isCB1,
            isCB2: this.productInfo.propertyAssessment.isCB2,
            isCB3: this.productInfo.propertyAssessment.isCB3,
            numFLR: this.productInfo.propertyAssessment.numFLR,
            numROOF: this.productInfo.propertyAssessment.numROOF,
            numWALL: this.productInfo.propertyAssessment.numWALL,
            loanAccountNo: this.productInfo.propertyAssessment.loanAccountNo,
            subdivName: this.productInfo.propertyAssessment.subdivName,
            subdivAddress: this.productInfo.propertyAssessment.subdivAddress,
            houseNo: this.productInfo.propertyAssessment.houseNo,
            street: this.productInfo.propertyAssessment.street,
            brgy: this.productInfo.propertyAssessment.brgy,
            isHH1: this.productInfo.propertyAssessment.isHH1,
            isHH4: this.productInfo.propertyAssessment.isHH4,
        });
        for (const bank of this.getBanks()) {
            if (bank.text === this.productInfo.propertyAssessment.bankDetail.text) {
                this.form.controls["bankDetail"].setValue(bank);
            }
        }
        for (const p of this.getProvinces()) {
            if (p.text === this.productInfo.propertyAssessment.province.text) {
                this.form.controls["province"].setValue(p);
            }
        }
        for (const p of this.getCities(this.productInfo.propertyAssessment.province.value)) {
            if (p.text === this.productInfo.propertyAssessment.city.text) {
                this.form.controls["city"].setValue(p);
            }
        }
        for (const p of this.getSubdivisions()) {
            if (p.text === this.productInfo.propertyAssessment.subdivision.text) {
                this.form.controls["subdivision"].setValue(p);
            }
        }
        this.initializeRoofWallValues();
        for (const r of this.roofOptions.filter((c: any) => c.value == this.productInfo.propertyAssessment.numROOF)) {
            this.form.controls["roof"].setValue(r);
        }
        for (const w of this.wallOptions.filter((w: any) => w.value == this.productInfo.propertyAssessment.numWALL)) {
            this.form.controls["wall"].setValue(w);
        }
        if (this.form.get("isMORT")?.value === 'N') {
            this.form.get('bankDetail')?.disable();
            this.form.get('loanAccountNo')?.disable();
            this.isQuestion1FieldsRequired = false;
        } else {
            this.form.get('bankDetail')?.enable();
            this.form.get('loanAccountNo')?.enable();
            this.isQuestion1FieldsRequired = true;
        }
        if (this.form.get("isNSUBD")?.value === 'Y') {
            this.isSubdivSecured = true;
        } else {
            this.isSubdivSecured = false;
        }
        if (this.productInfo.propertyAssessment.suppDocs) {
            if (this.productInfo.creationProcess === "quotation" && this.productInfo.propertyAssessment.suppDocs.length > 0) {
                let supportingDocs = this.form.controls["supportingDocs"].value;
                for (const supp of this.productInfo.propertyAssessment.supportingDocs) {
                    supportingDocs.push(supp);
                }
                this.supportingDocsFiles = this.productInfo.propertyAssessment.suppDocs;
                this.form.patchValue({
                    suppDocs: this.productInfo.propertyAssessment.suppDocs,
                });
                this.form.controls["supportingDocs"].setValue(supportingDocs);
            }
        }
    }

    /* BANK AUTO-COMPLETE FUNCTIONS */

    getBanks(): any[] {
        let bankOptions = [];
        for (let bank of this.jsonDataService.data.banks) {
            bankOptions.push({
                value: bank.bankCd,
                text: bank.bankName,
                mortAccGrp: bank.mortAccGrp
            });
        }
        bankOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return bankOptions;
    }
    setBankViaInput(event: any): void {
        let bankFound = false;
        this.formService.setFieldToUpperCase(this.form, "bankDetail", true, event);
        for (const bank of this.getBanks()) {
            if (bank.text === this.form.controls["bankDetail"].value.text) {
                this.form.controls["bankDetail"].setValue(bank);
                bankFound = true;
            }
        }

        if (bankFound) {
            this.form.controls["bankDetail"].setErrors(null);
        } else {
            this.form.controls["bankDetail"].setErrors({ "autocomplete": true });
            this.validateAnswer1();
        }
    }
    setBankViaOption(option: any): void {
        this.validateAnswer1();
    }

    /* PROVINCE AUTO-COMPLETE FUNCTIONS */

    getProvinces(): any[] {
        let provinceOptions = [];
        for (let p of this.jsonDataService.data.province) {
            provinceOptions.push({
                value: p.provinceCd,
                text: p.provinceName
            });
        }
        provinceOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return provinceOptions;
    }

    setProvinceViaInput(event: any): void {
        let provinceFound = false;
        this.formService.setFieldToUpperCase(this.form, "province", true, event);
        for (const p of this.getProvinces()) {
            if (p.text === this.form.controls["province"].value.text) {
                this.form.controls["province"].setValue(p);
                provinceFound = true;
            }
        }

        if (provinceFound) {
            this.form.controls["province"].setErrors(null);
        } else {
            this.form.controls["province"].setErrors({ "autocomplete": true });
            this.form.patchValue({
                city: "",
                subdivision: "",
                subdivName: "",
                subdivAddress: "",
                houseNo: "",
                street: "",
                brgy: "",
                isASUBD: "",
                isNSUBD: ""
            });
            this.form.get('city')?.disable();
            this.form.get('subdivision')?.disable();
            this.isSubdivOthers = false;
            this.isSubdivSecured = true;
            this.nextDisabled = true;
        }

    }
    setProvinceViaOption(option: any): void {
        this.isSubdivOthers = false;
        this.form.patchValue({
            city: "",
            subdivision: "",
            subdivName: "",
            subdivAddress: "",
            houseNo: "",
            street: "",
            brgy: "",
            isASUBD: "",
            isNSUBD: ""
        });
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.validateAnswer2();
        this.filteredCityOptions = this.autocompleteService.set(this.form, "city", this.getCities(option.value.value));
        this.filteredSubdivisionOptions = this.autocompleteService.set(this.form, "subdivision", this.getSubdivisions());
    }

    /* CITY AUTO-COMPLETE FUNCTIONS */

    getCities(provinceCd: any): any[] {
        let cityOptions = [];
        for (let c of this.jsonDataService.data.city.filter((c: any) => c.provinceCd == provinceCd)) {
            cityOptions.push({
                value: c.cityCd,
                text: c.cityName
            });
        }
        cityOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return cityOptions;
    }
    setCityViaInput(event: any): void {
        let cityFound = false;
        this.formService.setFieldToUpperCase(this.form, "city", true, event);
        for (const p of this.getCities(event.option)) {
            if (p.text === this.form.controls["city"].value.text) {
                this.form.controls["city"].setValue(p);
                cityFound = true;
            }
        }

        if (cityFound) {
            this.form.controls["city"].setErrors(null);
        } else {
            this.form.controls["city"].setErrors({ "autocomplete": true });
            this.form.patchValue({
                subdivision: "",
                subdivName: "",
                subdivAddress: "",
                houseNo: "",
                street: "",
                brgy: "",
                isASUBD: "",
                isNSUBD: ""
            });
            this.form.get('subdivision')?.disable();
            this.isSubdivOthers = false;
            this.isSubdivSecured = true;
            this.nextDisabled = true;
        }
    }
    setCityViaOption(option: any): void {
        this.isSubdivOthers = false;
        this.form.patchValue({
            subdivision: "",
            isASUBD: "",
            isNSUBD: ""
        });
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.validateAnswer2();
        this.filteredSubdivisionOptions = this.autocompleteService.set(this.form, "subdivision", this.getSubdivisions());
    }

    /* SUBDIVISION AUTO-COMPLETE FUNCTIONS */

    getSubdivisions(): any[] {
        let subdivOptions = [];
        for (let s of this.jsonDataService.data.preApprovedSubdivision.filter((s: any) =>
            s.provinceCd == this.form.get("province")?.value?.value && s.cityCd == this.form.get("city")?.value?.value)) {
            subdivOptions.push({
                value: s.subdivCd,
                text: s.subdivName,
                zoneCd: s.zoneCd
            });
        }
        subdivOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return subdivOptions;
    }
    setSubdivisionViaInput(event: any): void {
        let subdivisionFound = false;
        this.formService.setFieldToUpperCase(this.form, "subdivision", true, event);
        for (const p of this.getSubdivisions()) {
            if (p.text === this.form.controls["subdivision"].value.text) {
                this.form.controls["subdivision"].setValue(p);
                subdivisionFound = true;
            }
        }
        if (subdivisionFound) {
            this.form.controls["subdivision"].setErrors(null);
        } else {
            this.form.controls["subdivision"].setErrors({ "autocomplete": true });
            this.form.patchValue({
                subdivName: "",
                subdivAddress: "",
                houseNo: "",
                street: "",
                brgy: "",
            });
            this.isSubdivOthers = false;
            this.nextDisabled = true;
        }
    }
    setSubdivisionViaOption(option: any): void {
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.validateAnswer2();
        if (this.productInfo.propertyAssessment.subdivision.value != 0) {
            this.isSubdivOthers = false;
            this.form.patchValue({
                isASUBD: "Y",
                isNSUBD: "Y",
                subdivName: "",
                subdivAddress: "",
                houseNo: "",
                street: "",
                brgy: "",
            });
        } else {
            this.isSubdivOthers = true;
            this.isSubdivSecured = true;
            this.form.patchValue({
                isASUBD: "N",
                isNSUBD: "Y"
            });
        }
    }

    checkMortgageFieldDisable(mortgage: string): void {
        this.form.patchValue({
            isMORT: mortgage
        });
        if (mortgage === 'Y') {
            this.form.get('bankDetail')?.enable();
            this.form.get('loanAccountNo')?.enable();
            this.isQuestion1FieldsRequired = true;
            this.showCaveat = true;
        } else {
            this.form.patchValue({
                bankDetail: "",
                loanAccountNo: "",
            });
            this.form.get('bankDetail')?.disable();
            this.form.get('loanAccountNo')?.disable();
            this.isQuestion1FieldsRequired = false;
            this.showCaveat = false;
        }
        this.validateAnswer1();
    }

    checkSubdivOthersFieldDisable(secured: string): void {
        this.form.patchValue({
            isNSUBD: secured
        });
        if (secured === 'Y') {
            this.isSubdivSecured = true;
        } else {
            this.form.patchValue({
                subdivName: ""
            });
            this.isSubdivSecured = false;
        }
        this.validateAnswer2subdiv();
    }

    checkHistFieldDisable(hist: string): void {
        this.form.patchValue({
            isHIST: hist,
            numYear: ''
        });
        this.validateAnswer4();
    }

    checkHazardHunter(event: any) {
        if (event.checked) {
            this.isHazardHunter = event.checked;
        } else {
            this.isHazardHunter = event.checked;
        }
        this.validateAnswer6();
    }

    contactBpiMs(): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(["/contact-us"]));
        this.router.navigateByUrl(url);
    }

    floorValidation(): boolean {
        let error = false;
        if (this.form.controls["numFLR"].value) {
            const numFLR = this.form.controls["numFLR"].value;
            const min = this.parameterService.paramN("NO_OF_FLOOR_MIN");
            const max = this.parameterService.paramN("NO_OF_FLOOR_MAX");
            if (numFLR === 0 || numFLR < min || numFLR > max) {
                error = true;
            }

            if (error) {
                this.form.controls["numFLR"].setErrors({ "incorrect": true });
                this.form.controls["numFLR"].markAsTouched();
            }
        }
        return error;
    }

    yearValidation(): boolean {
        let error = false;
        let numHist: number = 0;
        if (this.form.controls["numYear"].value) {
            const numYear = this.form.controls["numYear"].value;
            const min = this.parameterService.paramN("MAX_FIRE_HIST_YR");
            const max = Number(new Date().getFullYear());
            if (numYear === 0 || numYear < min || numYear > max) {
                error = true;
            }

            if (error) {
                this.form.controls["numYear"].setErrors({ "incorrect": true });
                this.form.controls["numYear"].markAsTouched();
            } else {
                numHist = max - numYear;
                this.form.patchValue({
                    numYear: numYear,
                    numHIST: numHist
                });
            }
        }
        return error;
    }

    goToLink(): void {
        let hazardHunterLink = this.parameterService.paramV("HAZARD_HUNTER_LINK");
        window.open(hazardHunterLink);
    }

    onFileChange(event: any): void {
        this.withSupportingDocChange = true;

        let totalSize = 0;
        this.form.controls["supportingDocs"].value.forEach((el: any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (event.target.files && event.target.files.length) {
            const files = event.target.files;

            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                const element = files[index];

                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.supportDocErrorMsg = "";
                    const type = mimeTypes.getExtension(element.type) || "";

                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        this.supportDocErrorMsg = "Some files were not attached. Accepted file types: " + this.allowedFileExt.join(", ");
                    } else {
                        totalSize += (element.size / 1024 / 1024);

                        if (totalSize > this.allowedFileSize) {
                            this.supportDocErrorMsg = "You have reached the maximum file size for the attachment. Some files were not uploaded.";
                        } else {
                            const supportingDocs = this.form.controls["supportingDocs"].value;
                            supportingDocs.push({
                                filename: element.name,
                                size: element.size
                            });

                            this.emailAttachment.push({
                                filename: element.name,
                                path: reader.result,
                                size: element.size,
                                type: element.type
                            });

                            this.supportingDocsFiles.push(element);
                            this.form.controls["supportingDocs"].setValue(supportingDocs);
                            this.form.controls["emailAttachment"].setValue(this.emailAttachment);
                            this.form.markAsDirty();

                            this.productInfo.propertyAssessment = this.form.getRawValue();
                            //this.productInfo.propAssSuppDocs = this.supportingDocsFiles;
                            this.productInfo.propertyAssessment.suppDocs.push(element); //= this.supportingDocsFiles;
                            this.productService.setProductInfo2(this.productInfo);
                            this.validateAnswer6();
                        }
                    }

                    if (this.supportDocErrorMsg) {
                        setTimeout(() => {
                            this.supportDocErrorMsg = "";
                        }, 5000);
                    }
                };
            }
        }
        this.validateAnswer6();
    }

    showMortLink() {
        window.open(this.mortLink);
    }

    onClickView(index: number): void {
        const filename = this.form.controls["supportingDocs"].value[index].filename;

        let newFile = false;
        for (const supportingDocsFile of this.supportingDocsFiles) {
            if (supportingDocsFile.name === filename) {
                newFile = true;
                this.downloadService.downloadFile(supportingDocsFile);
            }
        }
    }

    onClickDelete(index: number): void {
        this.form.controls["supportingDocs"].setValue(
            this.form.controls["supportingDocs"].value.filter((a: any, i: number) => { return i !== index })
        );
        this.supportingDocsFiles.splice(index, 1);
        this.form.markAsDirty();
        this.withSupportingDocChange = true;
        this.validateAnswer6();
    }

    move(action: string): void {
        const choosePlanIsCov = this.productInfo.propertyAssessment.isCOV;
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.productService.setProductInfo2(this.productInfo);
        if (action === "back") {
            //this.router.navigate(["/quotation/choose-plan"]);
            if (this.showQuestion6) {
                this.showQuestion6 = false;
                this.showQuestion5 = true;
                this.displayQuestion5();
            } else if (this.showQuestion5) {
                this.showQuestion5 = false;
                //this.showQuestion4 = true; comment out until further notice
                //this.displayQuestion4();
                this.productInfo.planEvaluated = 1;
                this.productService.setProductInfo2(this.productInfo);
                this.router.navigate(["/quotation/choose-plan"]);
            } else if (this.showQuestion4) {
                this.showQuestion4 = false;
                this.productInfo.planEvaluated = 1;
                this.productService.setProductInfo2(this.productInfo);
                this.router.navigate(["/quotation/choose-plan"]);
            } else if (this.showQuestion3) {
                this.showQuestion3 = false;
                if (this.form.get("subdivision")?.value?.value === 0) { // ? is subdiv others
                    this.showQuestion2subdiv = true;
                    this.displayQuestion2Subdiv();
                }
                else {
                    this.showQuestion2 = true;
                    this.displayQuestion2();
                }
            } else if (this.showQuestion2subdiv) {
                this.showQuestion2subdiv = false;
                this.showQuestion2 = true;
                this.displayQuestion2();
            } else if (this.showQuestion2) {
                this.showQuestion2 = false;
                this.showQuestion1 = true;
                this.displayQuestion1();
            } else if (this.showQuestion1) {
                this.showQuestion1 = false;
                this.router.navigate(["/quotation"]);
            } else if (this.showNotInsurable) {
                this.showNotInsurable = false;
                this.reloadForm();
                if (this.productInfo.propertyAssessment.isHIST) {
                    this.showQuestion5 = true;
                    this.displayQuestion5();
                } else if ([this.form.get('isCB1')?.value, this.form.get('isCB2')?.value, this.form.get('isCB3')?.value].includes('N')) {
                    this.showQuestion2 = true;
                    this.displayQuestion2();
                } else if (choosePlanIsCov === 'Y') {
                    this.productInfo.planEvaluated = 1;
                    this.productInfo.propertyAssessment.isCOV = choosePlanIsCov;
                    this.productService.setProductInfo2(this.productInfo);
                    this.router.navigate(["/quotation/choose-plan"]);
                } else {
                    this.showQuestion3 = true;
                    this.displayQuestion3();
                }
            } else if (this.showRequestQuPrompt) {
                this.showRequestQuPrompt = false;
                this.reloadForm();
                this.showQuestion1 = true;
                this.displayQuestion1();
            }
        } else if (action === "next") {
            if (this.showQuestion1) {
                this.showQuestion1 = false;
                if (!this.reqForQuBankSw?.includes(this.userDetailsService.accountType || 'P') && this.reqForQuBank.includes(this.form.get("bankDetail")?.value?.mortAccGrp)) {
                    this.displayRequestQu();
                } else {
                    this.showQuestion2 = true;
                    this.displayQuestion2();
                }
            } else if (this.showQuestion2) {
                this.showQuestion2 = false;
                if ([this.form.get('isCB1')?.value, this.form.get('isCB2')?.value, this.form.get('isCB3')?.value].includes('N')) {
                    this.productInfo.planEvaluated = 9;
                    this.productService.setProductInfo2(this.productInfo);
                    this.displayNotInsurable();
                } else {
                    if (this.form.get("subdivision")?.value?.value === 0) { // ? is subdiv others
                        this.showQuestion2subdiv = true;
                        this.displayQuestion2Subdiv();
                    }
                    else {
                        this.showQuestion3 = true;
                        this.displayQuestion3();
                    }
                }
            } else if (this.showQuestion2subdiv) {
                this.showQuestion2subdiv = false;
                this.showQuestion3 = true;
                this.displayQuestion3();
            } else if (this.showQuestion3) {
                this.showQuestion3 = false;
                this.productInfo.propertyAssessment.isHH1 = null;
                this.productInfo.propertyAssessment.isHH4 = null;
                this.productInfo.planEvaluated = 1;
                this.productService.setProductInfo2(this.productInfo);
                this.router.navigate(["/quotation/choose-plan"]);
            } else if (this.showQuestion4) {
                this.showQuestion4 = false;
                this.showQuestion5 = true;
                this.displayQuestion5();
            } else if (this.showQuestion5) {
                this.showQuestion5 = false;
                this.showQuestion6 = true;
                if (this.form.get("isHH1")?.value === 'N') {
                    // back to f&l only
                    this.productInfo.planEvaluated = 1;
                    this.productService.setProductInfo2(this.productInfo);
                    this.router.navigate(["/quotation/choose-plan"]);
                } else {
                    this.displayQuestion6();
                }
            } else if (this.showQuestion6) {
                if(this.nextDisabled){
                    return;
                }
                this.showQuestion6 = false;
                this.productInfo.planEvaluated = 3;
                this.productService.setProductInfo2(this.productInfo);
                if (this.form.get("isHH4")?.value === 'N') {
                    // back to f&l only 
                    this.productInfo.planEvaluated = 1;
                    this.productService.setProductInfo2(this.productInfo);
                    this.router.navigate(["/quotation/choose-plan"]);
                } else if (this.form.get("isHH1")?.value === 'Y' && this.form.get("isHH4")?.value === 'Y' && !this.isAllowedNonSubd) {
                    this.router.navigate(["/request-quotation"]);
                } else {
                    // to choose plan aside from f&l
                    this.router.navigate(["/quotation/choose-plan"]);
                }
            } else if (this.showNotInsurable) {
                this.reqForQuProceed();
            }
            else if (this.showRequestQuPrompt) {
                this.reqForQuProceed();
            }
        }
    }

    public reqForQuProceed(): void {
        const url = this.router.serializeUrl(this.router.createUrlTree(["/request-quotation"]));
        this.router.navigateByUrl(url);
    }

    allowNumericDigitsOnlyWODecimal(event: any) {
        try {
            if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
                return true;
            }
            else {
                event.preventDefault();
                return false;
            }
        }
        catch (e) {
            return undefined;
        }
    }
}