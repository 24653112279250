import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { JsonDataService } from 'src/app/services/json-data.service';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FormService } from 'src/app/services/form.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    templateUrl: './profile.html',
})

export class ProfileComponent {

    profileForm: FormGroup = this.formBuilder.group ({
        brTag: [""],
        emailAdd: [""],
        lastName: [""],
        firstName: [""],
        middleName: [""],
        suffix: [""],
        companyName: [""],
        mobileNo: ["", [Validators.required]],
        telNo: [""],
        tin: ["", [this.formService.tinValidator()]],
        birthRegistrationDate: ["", [Validators.required]],
        genderDetail: ["", [this.formService.autocomplete()]],
        maritalStatDetail: ["", [this.formService.autocomplete()]],
        registrationNo: [""],
        countryOfBirthOriginDetail: ["", [this.formService.autocomplete()]],
        provOfBirthOriginDetail: ["", [this.formService.autocomplete()]],
        provOfBirthOrigin: [""],
        nationalityDetail: ["", [this.formService.autocomplete()]],
        permanentAddr: [""],
        permanentCountryDetail: ["", [this.formService.autocomplete()]],
        permanentProvinceDetail: ["", [this.formService.autocomplete()]],
        permanentProvince: [""],
        permanentPostalCodeDetail: ["", [this.formService.autocomplete()]],
        permanentPostalCode: [""],
        sameAddress: [""],
        presentAddr: [""],
        presentCountryDetail: ["", [this.formService.autocomplete()]],
        presentProvinceDetail: ["", [this.formService.autocomplete()]],
        presentProvince: [""],
        presentPostalCodeDetail: ["", [this.formService.autocomplete()]],
        presentPostalCode: [""]
    });

    // AUTO-COMPLETE OPTIONS
    filteredGenderDetailOptions:Observable<any>;
    filteredMaritalStatDetailOptions:Observable<any>;
    filteredCountryOfBirthOriginDetailOptions:Observable<any>;
    filteredProvOfBirthOriginDetailOptions!:Observable<any>;
    filteredNationalityDetailOptions:Observable<any>;
    filteredPermanentCountryDetailOptions:Observable<any>;
    filteredPermanentProvinceDetailOptions!:Observable<any>;
    filteredPermanentPostalCodeDetailOptions!:Observable<any>;
    filteredPresentCountryDetailOptions:Observable<any>;
    filteredPresentProvinceDetailOptions!:Observable<any>;
    filteredPresentPostalCodeDetailOptions!:Observable<any>;

    maxDate:Date = new Date();
    showBirthOriginPhilippinesDependent:boolean = false;
    showPermanentPhilippinesDependent:boolean = false;
    showPresentPhilippinesDependent:boolean = false;

    constructor (
        // PUBLIC
        public userDetailsService: UserDetailsService,
        public autocompleteService: AutocompleteService,
        public formService: FormService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private parameterService: ParameterService,
        private securityService: SecurityService
    ) {
        this.pageValidationService.validatePageAccessibility();

        // SET AUTO-COMPLETE INITIAL VALUES
        this.filteredGenderDetailOptions = this.autocompleteService.set(this.profileForm, "genderDetail", this.getGenders());
        this.filteredMaritalStatDetailOptions = this.autocompleteService.set(this.profileForm, "maritalStatDetail", this.getMaritalStatus());
        this.filteredCountryOfBirthOriginDetailOptions = this.autocompleteService.set(this.profileForm, "countryOfBirthOriginDetail", this.getCountries());
        this.filteredNationalityDetailOptions = this.autocompleteService.set(this.profileForm, "nationalityDetail", this.getNationality());
        this.filteredPermanentCountryDetailOptions = this.autocompleteService.set(this.profileForm, "permanentCountryDetail", this.getCountries());
        this.filteredPresentCountryDetailOptions = this.autocompleteService.set(this.profileForm, "presentCountryDetail", this.getCountries());

        this.initializeProfileInfo();
        this.productService.setProductInfo2("");
    }

    initializeProfileInfo():void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getProfileInfo().subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status == "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        res.birthRegistrationDate = this.datePipe.transform(res.birthRegistrationDate, "YYYY-MM-dd");
                        this.profileForm.patchValue(res);
                        this.setDefaultFieldValues();
                        this.checkCountryDependentFields("birth");
                        this.checkCountryDependentFields("permanent");
                        this.checkCountryDependentFields("present");

                        if (this.profileForm.controls["brTag"].value === "R") {
                            this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
                            this.profileForm.controls["lastName"].setValidators([Validators.required]);
                            this.profileForm.controls["lastName"].updateValueAndValidity();
                            this.profileForm.controls["firstName"].setValidators([Validators.required]);
                            this.profileForm.controls["firstName"].updateValueAndValidity();
                        } else {
                            this.profileForm.controls["companyName"].setValidators([Validators.required]);
                            this.profileForm.controls["companyName"].updateValueAndValidity();
                        }

                        if (this.profileForm.controls["sameAddress"].value === "Y") {
                            this.profileForm.controls["presentAddr"].disable();
                            this.profileForm.controls["presentCountryDetail"].disable();
                            this.profileForm.controls["presentProvinceDetail"].disable();
                            this.profileForm.controls["presentProvince"].disable();
                            this.profileForm.controls["presentPostalCodeDetail"].disable();
                            this.profileForm.controls["presentPostalCode"].disable();
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("Error getting your profile information. Please try again later.", "error");
                }
            });
        });
    }

    setDefaultFieldValues():void {
        if (!this.profileForm.controls["countryOfBirthOriginDetail"].value) {
            for (let country of this.jsonDataService.data.country.filter((a:any) =>
                a.countryCd === this.parameterService.paramN("DEFAULT_ASSD_COUNTRY")
            )) {
                this.profileForm.controls["countryOfBirthOriginDetail"].patchValue({
                    value: country.countryCd,
                    text: country.countryName
                });
            }
        }

        if (!this.profileForm.controls["nationalityDetail"].value) {
            for (const nationality of this.jsonDataService.data.nationality.filter((a:any) =>
                a.nationCd === this.parameterService.paramN("DEFAULT_ASSD_NATIONALITY")
            )) {
                this.profileForm.controls["nationalityDetail"].patchValue({
                    value: nationality.nationCd,
                    text: nationality.nationDesc
                });
            }
        }

        if (!this.profileForm.controls["permanentCountryDetail"].value) {
            for (let country of this.jsonDataService.data.country.filter((a:any) =>
                a.countryCd === this.parameterService.paramN("DEFAULT_ASSD_COUNTRY")
            )) {
                this.profileForm.controls["permanentCountryDetail"].patchValue({
                    value: country.countryCd,
                    text: country.countryName
                });
            }
        }

        if (!this.profileForm.controls["presentCountryDetail"].value) {
            for (let country of this.jsonDataService.data.country.filter((a:any) =>
                a.countryCd === this.parameterService.paramN("DEFAULT_ASSD_PRE_COUNTRY")
            )) {
                this.profileForm.controls["presentCountryDetail"].patchValue({
                    value: country.countryCd,
                    text: country.countryName
                });
            }
        }
    }

    checkCountryDependentFields(field:string):void {
        const philippinesCd = this.parameterService.paramN("PH_COUNTRY_CD");
        if (field === "birth") {
            if (this.profileForm.controls["countryOfBirthOriginDetail"].value.value === philippinesCd) {
                this.showBirthOriginPhilippinesDependent = true;
            } else {
                this.showBirthOriginPhilippinesDependent = false;
            }
        } else if (field === "permanent") {
            if (this.profileForm.controls["permanentCountryDetail"].value.value === philippinesCd) {
                this.showPermanentPhilippinesDependent = true;
            } else {
                this.showPermanentPhilippinesDependent = false;
            }
        } else if (field === "present") {
            if (this.profileForm.controls["presentCountryDetail"].value.value === philippinesCd) {
                this.showPresentPhilippinesDependent = true;
            } else {
                this.showPresentPhilippinesDependent = false;
            }
        }

        this.checkCountryDependentRequiredFields(field);
    }

    checkCountryDependentRequiredFields(field:string):void {
        if (field === "birth") {
            if (this.showBirthOriginPhilippinesDependent) {
                this.profileForm.controls["provOfBirthOrigin"].setValue("");
                this.filteredProvOfBirthOriginDetailOptions = this.autocompleteService.set(this.profileForm, "provOfBirthOriginDetail", this.getProvinces("countryOfBirthOriginDetail"));
            } else {
                this.profileForm.controls["provOfBirthOriginDetail"].setValue("");
            }
        } else if (field === "permanent") {
            if (this.showPermanentPhilippinesDependent) {
                this.profileForm.controls["permanentProvince"].setValue("");
                this.filteredPermanentProvinceDetailOptions = this.autocompleteService.set(this.profileForm, "permanentProvinceDetail", this.getProvinces("permanentCountryDetail"));
            } else {
                this.profileForm.controls["permanentProvinceDetail"].setValue("");
            }

            if (this.showPermanentPhilippinesDependent) {
                this.profileForm.controls["permanentPostalCode"].setValue("");
                this.filteredPermanentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "permanentPostalCodeDetail", this.getPostalCodes("permanent"));
            } else {
                this.profileForm.controls["permanentPostalCodeDetail"].setValue("");
            }
        } else if (field === "present") {
            if (this.showPresentPhilippinesDependent) {
                this.profileForm.controls["presentProvince"].setValue("");
                this.filteredPresentProvinceDetailOptions = this.autocompleteService.set(this.profileForm, "presentProvinceDetail", this.getProvinces("presentCountryDetail"));
            } else {
                this.profileForm.controls["presentProvinceDetail"].setValue("");
            }

            if (this.showPresentPhilippinesDependent) {
                this.profileForm.controls["presentPostalCode"].setValue("");
                this.filteredPresentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "presentPostalCodeDetail", this.getPostalCodes("present"));
            } else {
                this.profileForm.controls["presentPostalCodeDetail"].setValue("");
            }
        }
    }

    validateBirthDate():void {
        if (this.profileForm.controls["brTag"].value === "R") {
            const birthDate = this.profileForm.controls["birthRegistrationDate"].value;
            if (birthDate && birthDate > this.maxDate) {
                this.appMessageService.showAppMessage("You must be 18 years old and above to proceed.", "error");
                this.profileForm.patchValue({birthRegistrationDate: ""});
            }
        }
    }

    setPresentAddress(field:string):void {
        if (this.profileForm.controls["sameAddress"].value === "Y") {
            if (field === "permanentAddr") {
                this.profileForm.controls["presentAddr"].setValue(this.profileForm.controls["permanentAddr"].value);
            } else if (field === "permanentCountry") {
                this.profileForm.controls["presentCountryDetail"].setValue(this.profileForm.controls["permanentCountryDetail"].value);
            } else if (field === "permanentProvince") {
                this.profileForm.controls["presentProvinceDetail"].setValue(this.profileForm.controls["permanentProvinceDetail"].value);
                this.profileForm.controls["presentProvince"].setValue(this.profileForm.controls["permanentProvince"].value);
                this.profileForm.controls["presentPostalCodeDetail"].setValue(this.profileForm.controls["permanentPostalCodeDetail"].value);
            } else if (field === "permanentPostalCode") {
                this.profileForm.controls["presentPostalCodeDetail"].setValue(this.profileForm.controls["permanentPostalCodeDetail"].value);
                this.profileForm.controls["presentPostalCode"].setValue(this.profileForm.controls["permanentPostalCode"].value);
            }
        }
    }

    sameAsPermanentAddressEvent(event:any):void {
        this.profileForm.controls["sameAddress"].setValue(event.checked ? "Y" : "N");
        if (event.checked) {
            this.profileForm.controls["presentAddr"].disable();
            this.profileForm.controls["presentCountryDetail"].disable();
            this.profileForm.controls["presentProvinceDetail"].disable();
            this.profileForm.controls["presentProvince"].disable();
            this.profileForm.controls["presentPostalCodeDetail"].disable();
            this.profileForm.controls["presentPostalCode"].disable();
        } else {
            this.profileForm.controls["presentAddr"].enable();
            this.profileForm.controls["presentCountryDetail"].enable();
            this.profileForm.controls["presentProvinceDetail"].enable();
            this.profileForm.controls["presentProvince"].enable();
            this.profileForm.controls["presentPostalCodeDetail"].enable();
            this.profileForm.controls["presentPostalCode"].enable();
        }

        this.setPresentAddress("permanentAddr");
        this.setPresentAddress("permanentCountry");
        this.setPresentAddress("permanentProvince");
        this.setPresentAddress("permanentPostalCode");
        this.checkCountryDependentFields("present");
    }

    move():void {
        this.router.navigate(["/dashboard"]);
    }

    onSubmit():void {
        if (this.profileForm.dirty) {
            if (this.profileForm.valid) {
                const form = this.profileForm.getRawValue();
                if (form.birthRegistrationDate && typeof form.birthRegistrationDate !== "string") {
                    form.birthRegistrationDate = form.birthRegistrationDate.format("MM/DD/YYYY");
                }

                this.jsonDataService.contorlLoading(true);
                this.apiCallService.saveProfileInfo({
                    lastName: form.lastName,
                    firstName: form.firstName,
                    middleName: form.middleName,
                    suffix: form.suffix,
                    companyName: form.companyName,
                    mobileNo: form.mobileNo,
                    telNo: form.telNo,
                    tin: form.tin,
                    birthRegistrationDate: form.birthRegistrationDate,
                    gender: form.genderDetail.value,
                    maritalStat: form.maritalStatDetail.value,
                    registrationNo: form.registrationNo,
                    countryOfBirthOrigin: form.countryOfBirthOriginDetail.value,
                    cityOfBirthOrigin: form.provOfBirthOriginDetail.value,
                    provinceOfBirthOriginCd: form.provOfBirthOriginDetail.provinceCd,
                    provinceOfBirthOriginDesc: form.provOfBirthOrigin,
                    nationality: form.nationalityDetail.value,
                    permanentAddr: form.permanentAddr,
                    permanentCountry: form.permanentCountryDetail.value,
                    permanentProvinceCd: form.permanentProvinceDetail.provinceCd,
                    permanentProvinceDesc: form.permanentProvince,
                    permanentCity: form.permanentProvinceDetail.value,
                    permanentPostalCode: form.permanentPostalCodeDetail.value,
                    permanentPostalCodeDesc: form.permanentPostalCode,
                    sameAddress: form.sameAddress || "N",
                    presentAddr: form.presentAddr,
                    presentCountry: form.presentCountryDetail.value,
                    presentProvinceCd: form.presentProvinceDetail.provinceCd,
                    presentProvinceDesc: form.presentProvince,
                    presentCity: form.presentProvinceDetail.value,
                    presentPostalCode: form.presentPostalCodeDetail.value,
                    presentPostalCodeDesc: form.presentPostalCode
                }).subscribe((response:any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.appMessageService.showAppMessage("Successfully saved.", "success");
                        } else {
                            this.appMessageService.showAppMessage("Error saving your personal information.", "error");
                        }
                    });
                });
            } else {
                this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
            }
        } else {
            this.appMessageService.showAppMessage("You don't have any changes to save.", "info");
        }
    }

    getCountries():any[] {
        let countryOptions = [];
        for (let country of this.jsonDataService.data.country) {
            countryOptions.push({
                value: country.countryCd,
                text: country.countryName
            });
        }

        if (countryOptions.length === 0) {
            countryOptions.push({
                value: "",
                text: "No Available Country"
            });
        }

        return countryOptions;
    }

    getProvinces(countryField:string):any[] {
        let provinceCityOptions = [];
        for (let province of this.jsonDataService.data.province.filter((a:any) =>
            a.countryCd === this.profileForm.controls[countryField].value.value
        )) {
            for (let city of this.jsonDataService.data.city.filter((a:any) =>
                   a.countryCd === province.countryCd
                && a.provinceCd === province.provinceCd
            )) {
                provinceCityOptions.push({
                    value: city.cityCd,
                    text: city.cityName + ", " + province.provinceName,
                    provinceCd: city.provinceCd
                });
            }
        }

        if (provinceCityOptions.length === 0) {
            provinceCityOptions.push({
                value: "",
                text: "No Available Province"
            });
        }

        provinceCityOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return provinceCityOptions;
    }

    getPostalCodes(field:string):any[] {
        const countryField = field + "CountryDetail";
        const provinceField = field + "ProvinceDetail";
        const postalCodeField = field + "PostalCodeDetail";

        let postalOptions: any[] = [];
        for (let postal of this.jsonDataService.data.postalCds.filter((a:any) =>
               a.countryCd === this.profileForm.controls[countryField].value.value
            && a.provinceCd === (this.profileForm.controls[provinceField].value.provinceCd || a.provinceCd)
            && a.cityCd === (this.profileForm.controls[provinceField].value.value || a.cityCd)
        )) {
            postalOptions.push({
                value: postal.postalCd,
                text: postal.bmCode + ' - ' + postal.postalDesc,
                provinceCd: postal.provinceCd,
                cityCd: postal.cityCd
            });
        }

        if (postalOptions.length === 0) {
            postalOptions.push({
                value: "",
                text: "No Available Postal Code"
            });
        } else if (postalOptions.length === 1) {
            this.profileForm.controls[postalCodeField].setValue(postalOptions[0]);
            this.setPresentAddress("permanentPostalCode");
        }

        postalOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return postalOptions;
    }

    setPostalDependent(field:string):void {
        let countryField = "permanentCountryDetail";
        let provinceField = "permanentProvinceDetail";
        let postalCodeField = "permanentPostalCodeDetail";
        if (field === "present") {
            countryField = "presentCountryDetail";
            provinceField = "presentProvinceDetail";
            postalCodeField = "presentPostalCodeDetail";
        }

        const postalCodeDetail = this.profileForm.controls[postalCodeField].value;
        for (const province of this.getProvinces(countryField)) {
            if (province.value === postalCodeDetail.cityCd && province.provinceCd === postalCodeDetail.provinceCd) {
                this.profileForm.controls[provinceField].setValue(province);
                this.filteredPermanentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, postalCodeField, this.getPostalCodes(field));
            }
        }
        this.setPresentAddress("permanentProvince");
    }

    /*******************************************************************************/
    /*                                                                             */
    /*                           AUTO-COMPLETE FUNCTIONS                           */
    /*                                                                             */
    /*******************************************************************************/

    /* GENDER AUTO-COMPLETE FUNCTIONS */

    getGenders():any[] {
        let genderOptions = [];
        for (let gender of this.jsonDataService.data.gender) {
            genderOptions.push({
                value: gender.genderCd,
                text: gender.bmDesc
            });
        }
        genderOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return genderOptions;
    }
    setGenderViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "genderDetail", true, event);
        for (const gender of this.getGenders()) {
            if (gender.text === this.profileForm.controls["genderDetail"].value.text) {
                this.profileForm.controls["genderDetail"].setValue(gender);
            }
        }
    }

    /* MARITAL STATUS AUTO-COMPLETE FUNCTIONS */

    getMaritalStatus():any[] {
        let maritalStatOptions: any[] = [];
        for (let maritalStat of this.jsonDataService.data.maritalStat) {
            maritalStatOptions.push({
                value: maritalStat.maritalStatCd,
                text: maritalStat.bmDesc || ''
            });
        }
        maritalStatOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return maritalStatOptions;
    }
    setMaritalViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "maritalStatDetail", true, event);
        for (const marital of this.getMaritalStatus()) {
            if (marital.text === this.profileForm.controls["maritalStatDetail"].value.text) {
                this.profileForm.controls["maritalStatDetail"].setValue(marital);
            }
        }
    }

    /* COUNTRY OF BIRTH AUTO-COMPLETE FUNCTIONS */

    setCountryOfBirthOriginViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "countryOfBirthOriginDetail", true, event);
        for (const country of this.getCountries()) {
            if (country.text === this.profileForm.controls["countryOfBirthOriginDetail"].value.text) {
                this.profileForm.controls["countryOfBirthOriginDetail"].setValue(country);
            }
        }
        this.checkCountryDependentFields("birth");
    }
    setCountryOfBirthOriginViaOption():void {
        this.checkCountryDependentFields("birth");
    }

    /* PROVINCE OF BIRTH AUTO-COMPLETE FUNCTIONS */

    setProvOfBirthOriginViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "provOfBirthOriginDetail", true, event);
        for (const province of this.getProvinces("countryOfBirthOriginDetail")) {
            if (province.text === this.profileForm.controls["provOfBirthOriginDetail"].value.text) {
                this.profileForm.controls["provOfBirthOriginDetail"].setValue(province);
            }  
        }
    }

    /* NATIONALITY AUTO-COMPLETE FUNCTIONS */

    getNationality():any[] {
        let nationalityOptions = [];
        for (let nationality of this.jsonDataService.data.nationality) {
            nationalityOptions.push({
                value: nationality.nationCd,
                text: nationality.nationDesc
            });
        }
        nationalityOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return nationalityOptions;
    }
    setNationalityViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "nationalityDetail", true, event);
        for (const nation of this.getNationality()) {
            if (nation.text === this.profileForm.controls["nationalityDetail"].value.text) {
                this.profileForm.controls["nationalityDetail"].setValue(nation);
            }
        }
    }

    /* PERMANENT COUNTRY AUTO-COMPLETE FUNCTIONS */

    setPermanentCountryViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "permanentCountryDetail", true, event);
        for (const country of this.getCountries()) {
            if (country.text === this.profileForm.controls["permanentCountryDetail"].value.text) {
                this.profileForm.controls["permanentCountryDetail"].setValue(country);
            }
        }
        this.validatedCountry();
    }
    setPermanentCountryViaOption():void {
        this.validatedCountry();
    }
    validatedCountry():void {
        this.setPresentAddress("permanentCountry");
        this.setPresentAddress("permanentProvince");
        this.setPresentAddress("permanentPostalCode");
        this.checkCountryDependentFields("permanent");
        this.checkCountryDependentFields("present");
    }

    /* PERMANENT PROVINCE AUTO-COMPLETE FUNCTIONS */

    setPermanentProvinceViaInput(event:any, autocomplete:boolean):void {
        if (autocomplete) {
            this.profileForm.controls["permanentPostalCodeDetail"].setValue("");
            this.formService.setFieldToUpperCase(this.profileForm, "permanentProvinceDetail", true, event);
            for (const province of this.getProvinces("permanentCountryDetail")) {
                if (province.text === this.profileForm.controls["permanentProvinceDetail"].value.text) {
                    this.profileForm.controls["permanentProvinceDetail"].setValue(province);
                }
            }
        }
        this.setPresentAddress("permanentProvince");
        this.filteredPermanentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "permanentPostalCodeDetail", this.getPostalCodes("permanent"));
    }
    setPermanentProvinceViaOption():void {
        this.setPresentAddress("permanentProvince");
        this.filteredPermanentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "permanentPostalCodeDetail", this.getPostalCodes("permanent"));
    }

    /* PERMANENT POSTAL CODE AUTO-COMPLETE FUNCTIONS */

    setPermanentPostalCodeViaInput(event:any, autocomplete:boolean):void {
        if (autocomplete) {
            this.formService.setFieldToUpperCase(this.profileForm, "permanentPostalCodeDetail", true, event);
            for (const postal of this.getPostalCodes("permanent")) {
                if (postal.text === this.profileForm.controls["permanentPostalCodeDetail"].value.text) {
                    this.profileForm.controls["permanentPostalCodeDetail"].setValue(postal);
                }
            }
        }
        this.setPresentAddress("permanentPostalCode");
        this.setPostalDependent("permanent");
    }
    setPermanentPostalCodeViaOption():void {
        this.setPresentAddress("permanentPostalCode");
        this.setPostalDependent("permanent");
    }

    /* PRESENT COUNTRY AUTO-COMPLETE FUNCTIONS */

    setPresentCountryViaInput(event:any):void {
        this.formService.setFieldToUpperCase(this.profileForm, "presentCountryDetail", true, event);
        for (const country of this.getCountries()) {
            if (country.text === this.profileForm.controls["presentCountryDetail"].value.text) {
                this.profileForm.controls["presentCountryDetail"].setValue(country);
            }
        }
        this.checkCountryDependentFields("present");
    }
    setPresentCountryViaOption():void {
        this.checkCountryDependentFields("present");
    }

    /* PRESENT PROVINCE AUTO-COMPLETE FUNCTIONS */

    setPresentProvinceViaInput(event:any):void {
        this.profileForm.controls["presentPostalCodeDetail"].setValue("");
        this.formService.setFieldToUpperCase(this.profileForm, "presentProvinceDetail", true, event);
        for (const province of this.getProvinces("presentCountryDetail")) {
            if (province.text === this.profileForm.controls["presentProvinceDetail"].value.text) {
                this.profileForm.controls["presentProvinceDetail"].setValue(province);
            }
        }
        this.filteredPresentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "presentPostalCodeDetail", this.getPostalCodes("present"));
    }
    setPresentProvinceViaOption():void {
        this.filteredPresentPostalCodeDetailOptions = this.autocompleteService.set(this.profileForm, "presentPostalCodeDetail", this.getPostalCodes("present"));
    }

    /* PRESENT POSTAL CODE AUTO-COMPLETE FUNCTIONS */

    setPresentPostalCodeViaInput(event:any, autocomplete:boolean):void {
        if (autocomplete) {
            this.formService.setFieldToUpperCase(this.profileForm, "presentPostalCodeDetail", true, event);
            for (const postal of this.getPostalCodes("present")) {
                if (postal.text === this.profileForm.controls["presentPostalCodeDetail"].value.text) {
                    this.profileForm.controls["presentPostalCodeDetail"].setValue(postal);
                }
            }
        }
        this.setPostalDependent("present");
    }
    setPresentPostalCodeViaOption():void {
        this.setPostalDependent("present");
    }
}