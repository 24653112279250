<section id="email-print" class="proxima">
    <div class="container">
        <form [formGroup]="holderForm">
            <mat-checkbox formControlName="sameAcctTag" *ngIf="userService.accountType == 'P' && showSameAcctTag">
                Same as Account Holder
            </mat-checkbox>
            <div class="form">
                <div class="col-case col-3">
                    <div class="field required field-1">
                        <input type="hidden" formControlName="assdNo">
                        <label>Name of the Policy Holder</label>
                        <mat-form-field appearance="fill">
                            <input matInput class='weighted' formControlName="displayName" uppercase trim>
                            <mat-icon (click)="lov.open()" *ngIf="showLov"> search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="field required field-2">
                        <label>Complete Address of the Policy Holder</label>
                        <mat-form-field appearance="fill">
                            <input matInput class='weighted' formControlName="address" uppercase trim>
                        </mat-form-field>
                    </div>
                </div>
                <div class="field instructions" *ngIf="holderForm.enabled && userService.accountType == 'P'">
                    <div class="field border">
                        <span>To proceed to E-Policy issuance and premium payment, please save the quotation first.</span>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="container">
        <div class="form-wrapper">
            <iframe [src]="pdfUrl+ '#toolbar=0' | safe:'resourceUrl'" width="100%" height="500px" *ngIf="!noHolder && pdfUrl!=''">
            </iframe>
            <ng-container *ngIf="!noHolder && pdfUrl==''">
                <div class="spinner-container">
                    <mat-spinner></mat-spinner>
                </div>
                <p>Generating PDF...</p>
            </ng-container>
            <p *ngIf="noHolder">To proceed to E-Policy issuance and premium payment, please save the quotation first.</p>
        </div>
    </div>
    <div class="container">
        <div class="instructions-bottom">
            {{instructions}}
        </div>
        <form>
            <div class="form-btns">
                <button class="btn btn4" (click)="move()">Back</button>
                <button class="btn btn5" (click)="savePolicyHolder()">Save PDF</button>
                <button class="btn btn2" (click)="openEmail()" [disabled]="this.disableEmail">Email</button>

                <!-- <button class="btn btn5" (click)="savePolicyHolder()" *ngIf="!forPol">Save</button>
                <button class="btn btn2" (click)="onClickPrint()">
                    <mat-icon>help_outline</mat-icon>
                    Print
                </button>
                <button class="btn btn2" (click)="openEmail()" [disabled]="this.disableEmail">Email</button>
                <button class="btn btn3" (click)="toDashboardd()" *ngIf="!forPol">Go to Dashboard</button>
                <button class="btn btn3" (click)="toPolcy()" *ngIf="forPol">Continue to E-Policy</button> -->
            </div>
        </form>
    </div>
</section>

<overlay #emailOverlay [title]="'Email Quotation'">
    <div content id="email-overlay">
        <form form [formGroup]="emailForm">
            <div class="form-note">
                <p>{{emailNote}}</p>
            </div>
            <div class="form">
                <div class="col-1">
                    <div class="field required">
                        <p class="field-desc">To</p>
                        <email-list formControlName="recipient" class="mat-form-field-appearance-fill required"></email-list>
                        <p class="field-error" *ngIf="formService.isInvalid(emailForm, 'recipient')">
                            {{formService.getFieldErrorMsg(emailForm, "recipient")}}
                        </p>
                    </div>
                    <div class="field">
                        <p class="field-desc">Cc</p>

                        <email-list formControlName="cc"></email-list>
                        <p class="field-error" *ngIf="formService.isInvalid(emailForm, 'cc')">
                            {{formService.getFieldErrorMsg(emailForm, "cc")}}
                        </p>
                    </div>
                    <div class="field required">
                        <p class="field-desc">Subject</p>
                        <mat-form-field appearance="fill" class="required">
                            <input matInput class='weighted' formControlName="subject">
                        </mat-form-field>
                    </div>
                    <div class="field required">
                        <p class="field-desc">Message</p>
                        <mat-form-field appearance="fill" class="required">
                            <textarea matInput rows="5" formControlName="emailContent"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary btn" (click)="sendMail()" [disabled]="!emailForm.valid">Send Email</button>
</overlay>

<overlay #confirmPrintInfo [title]="'Confirmation'">
    <div content>
        Are you satisfied with the contents of the draft quotation? If yes, you may now finalize the quotation by generating the Quotation Number.
    </div>
    <button mat-button buttons class="btn btn-primary" (click)="generateQuoteNo();confirmPrintInfo.close()">Generate Quotation No.</button>
</overlay>

<!-- <overlay #generateQuoteMessage [title]="'Quotation Successfully Generated'"> //Replacement if message box did not work
    <div content>
        <p class="referral-text montserrat">
            Your Quotation No. <b> {{quoteNo}} </b> was successfully generated. 
            You may opt to send a copy of the quotation to your Email address or 
            proceed to E-policy issuance and premium payment.
        </p>
    </div>
</overlay> -->

<lov #lov (selected)="onLovOk($event)" [type]="'clients'" [params]="lovParams"></lov>