<section id="customer-information">
    <div class="container">
        <form [formGroup]="profileForm">
            <div class="form">
                <div class="col-case col-3">
                    <!-- Account Type Field -->
                    <div class="field required">
                        <label>Client Type</label>
                        <mat-form-field appearance="fill">
                            <mat-select placeholder="Account type" formControlName="brTag" (selectionChange)="resetValue('brTag')">
                                <mat-option *ngFor="let clientType of varClientTypes" [value]="clientType.value">{{clientType.text}} </mat-option>
                            </mat-select>
                            <!--<mat-select placeholder="Account type" (selectionChange)="resetValue('brTag')" formControlName="brTag">
                                <mat-option value="R">PERSONAL</mat-option>
                                <mat-option value="B">CORPORATE</mat-option>
                            </mat-select>-->
                            <mat-error *ngIf="profileForm.get('brTag')?.invalid">Please choose an Account type</mat-error>  
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="field instructions">
                    <div class="field border">
                        <span>{{instructions}}&nbsp;</span>
                        <!--<button type="button" [matTooltip]="listId | tooltipList" matTooltipClass="tooltip-list" class="viewList" (click)="showListId()"> View the list of valid IDs here.</button>-->
                        <button type="button" class="viewList" (click)="showListId()">View the list of valid IDs here.</button>
                    </div>
                </div>
                <div class="col title red">UPLOAD IDS</div>
                <pre class="field instructions2">{{instructions2}}</pre>
                <div class="col-case col-3">
                    <ng-container class="field">
                    <form [formGroup]="idForm">
                        <div formArrayName="ids">
                            <ng-container *ngFor="let id of ids.controls; let i=index">
                                <ng-container [formGroupName]="i">
                                    <div class="field">
                                        <mat-card class="idGrp"> <!--border-->
                                            <!-- Type of ID 1 Field -->
                                            <div class="field upload required">
                                                <label>Type of ID</label>
                                                <mat-form-field class="select" appearance="fill">
                                                    <input matInput [placeholder]="setIdPlaceholder1(i)" formControlName="typeId" [matAutocomplete]="autoId1" (input)="setIdViaInput1(i)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                                    <mat-autocomplete #autoId1="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setIdViaOption1($event.option, i)">
                                                        <mat-option *ngFor="let option of filteredId1[i] | async" [value]="option">{{option.text}}</mat-option>
                                                    </mat-autocomplete>    
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'typeId')">
                                                    {{formService.getFieldErrorMsg(profileForm, "typeId")}}
                                                </p>
                                            </div>
                                            <!-- Upload ID 1 -->
                                            <div class="field upload" [ngClass]="idReq ? this.containers[i] != undefined ? '': 'redBorder' : ''">
                                                <mat-card>
                                                    <div [ngClass]="this.containers[i] != undefined ? 'hide': ''">
                                                        <!--<div class="upload-text">
                                                            <p class="drag">Drag image here</p>
                                                            <p>or</p>
                                                        </div> -->
                                                        <div class="button-id">
                                                            <label class="image-upload-container btn btn-bwm">
                                                                <span class="upload-image">Upload Image</span>
                                                                <ng-container *ngIf="checkField(i) else showButton">
                                                                    <input #imageInput1 type="file" accept="image/jpeg, image/png" (change)="processFile(imageInput1, i, 'new')" class="image">
                                                                </ng-container>
                                                                <ng-template #showButton>
                                                                    <button type="button" class="image" (click)="checkType(i)">Upload Image</button>
                                                                </ng-template>
                                                            </label>
                                                            <button type="button" mat-button class="decrease" (click)="removeId(i)">Delete</button>
                                                        </div>
                                                    </div>
                                                    <div [ngClass]="this.containers[i] === undefined ? 'hide': ''">
                                                        <div class="img-preview-container">
                                                            <ng-container *ngIf="ocrStat[i] === 'SUCCESS' else showLoading">
                                                                <img id="{{i}}" class="idImg img-preview" [src]="this.containers[i]" >
                                                            </ng-container>
                                                            <ng-template #showLoading>
                                                                <div class="textract-container">
                                                                    <div class="textract-loader"></div>
                                                                </div>
                                                            </ng-template>
                                                            <!--<div id="{{i}}" class="idImg img-preview" [ngStyle]="{'background-image': 'url('+ selectedFile1.src + ')'}">
                                                            </div>
                                                            <div *ngIf="selectedFile1.pending" class="img-loading-overlay">
                                                                <div class="img-spinning-circle"></div>
                                                            </div>
                                                            <div *ngIf="selectedFile.status === 'ok'" class="alert alert-success"> Image Uploaded Succesfuly!</div>
                                                            <div *ngIf="selectedFile.status === 'fail'" class="alert alert-danger"> Image Upload Failed!</div>-->
                                                            
                                                        </div>
                                                        <mat-card-actions>
                                                            <button type="button" mat-button class="delete" (click)="deleteImage(i)">Delete</button>
                                                            <label class="replace btn">
                                                                <span class="replace-image">Replace image</span>
                                                                <ng-container *ngIf="checkField(i) else showRepButton">
                                                                    <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput, i, 'replace')" class="repImage">
                                                                </ng-container>
                                                                <ng-template #showRepButton>
                                                                    <button type="button" class="repImage" (click)="checkType(i)">Replace image</button>
                                                                </ng-template>
                                                            </label>
                                                        </mat-card-actions>
                                                    </div>
                                                </mat-card>
                                            </div>
                                            <!-- ID no. 1 Field -->
                                            <div class="field upload required">
                                                <label>ID No.</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="Enter ID No." formControlName="idNo" [attr.maxlength]="this.idMax">
                                                    <mat-error *ngIf="profileForm.get('idNo')?.invalid">Please enter an ID number</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <!-- Expiry Date Field -->
                                            <div class="field upload" [ngClass]="reqExpiryDate(i) ? 'required': this.chkExp === 1 ? 'hidden':'hide'">
                                                <label>Expiry Date</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="MM/DD/YYYY" [min]="minExpDate" [matDatepicker]="ExpPicker" formControlName="expDate" (dateChange)="chkExpDate(i)">
                                                    <mat-datepicker-toggle matSuffix [for]="ExpPicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #ExpPicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </mat-card> <!--border-->
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </form>
                    </ng-container>
                    <div class="field addId">
                        <mat-card class="id">
                            <button type="button" mat-button class="add" (click)="addId()" *ngIf="ids.length < this.minAssdId">Add Valid ID (Required)</button>
                            <button type="button" mat-button class="add" (click)="addId()" *ngIf="ids.length >= this.minAssdId">Add Additional ID (Optional)</button>
                        </mat-card>
                    </div>
                </div>
                <span>Please make sure that the uploaded image is a valid ID to avoid inconvenience in the future.</span>    
            </div>    
            <div class="form">
                <div class="col title">PERSONAL INFORMATION</div>
                <div class="col-case"><span>Please make sure that personal information match the uploaded ID to avoid inconvenience in the future.</span></div>
                <ng-container *ngIf="profileForm.controls['brTag'].value === 'R' else showCompany">
                    <div class="col-case col-3">
                        <!-- Last name Field -->
                        <div class="field required">
                            <label>Last Name</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Last name" formControlName="lastName" maxlength="30" uppercase trim>
                                <mat-error *ngIf="profileForm.get('lastName')?.invalid">Please enter your Last name</mat-error>
                            </mat-form-field>
                        </div>
                        <!-- First name Field -->
                        <div class="field required">
                            <label>First Name</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="First name" formControlName="firstName" maxlength="20" uppercase trim>
                                <mat-error *ngIf="profileForm.get('firstName')?.invalid">Please enter your First name</mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Middle name Field -->
                        <div class="field middle" [ngClass]="reqMiddleName === 'P'? 'required': ''">
                            <label>Middle Name</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Middle name" formControlName="middleName"  maxlength="30" uppercase trim>
                                <mat-error *ngIf="profileForm.get('middleName')?.invalid">Please enter your Middle name</mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Suffix Field -->
                        <div class="field suffix">
                            <label>Suffix</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Suffix" formControlName="suffix" maxlength="5" uppercase trim>
                                <mat-error *ngIf="profileForm.get('suffix')?.invalid">Please enter your Suffix</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showCompany>
                    <div class="compName">
                        <div class="field required">
                            <label>Company Name</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Company Name" formControlName="compName" uppercase trim>
                                <mat-error *ngIf="profileForm.get('compName')?.invalid">Please enter your Company Name</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-template>
                <div class="backColor" *ngIf="userType != 'P'">
                    <div class="displayName">
                        <!-- Name will appear on your policy Field -->
                        <div class="field required">
                            <label>Please indicate the complete name that will appear on your policy.</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Name" formControlName="dispName" uppercase trim>
                                <mat-error *ngIf="profileForm.get('dispName')?.invalid">Please enter the name you want to appear on your policy</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Email address Field -->
                    <div class="field" [ngClass]="reqAssdEmail === 'P'? 'required': ''">
                        <label>Email Address</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Email address" formControlName="emailAdd" value="" emailAdd>
                            <mat-error *ngIf="profileForm.get('emailAdd')?.invalid">Please enter your Email address</mat-error>
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'emailAdd')">
                            {{formService.getFieldErrorMsg(profileForm, "emailAdd")}}
                        </p>
                    </div>
                    <!-- Mobile No. Field -->
                    <div class="field" [ngClass]="reqMobileNo === 'P'? 'required': ''">
                        <label>Mobile No.</label>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="mobileNo" mobileNo>
                        </mat-form-field>
                        <p class="field-error" *ngIf="profileForm.get('mobileNo')?.invalid">
                            <span *ngIf="profileForm.get('mobileNo')?.errors?.mobileNo">
                                Invalid mobile number.
                            </span>
                        </p>
                    </div>
                    <!-- Telephone No. Field -->
                    <div class="field" [ngClass]="reqTelNo === 'P'? 'required': ''">
                        <label>Telephone No.</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Telephone No." formControlName="telNo" numbersOnly>
                            <mat-error *ngIf="profileForm.get('telNo')?.invalid">Please enter your Telephone No.</mat-error>
                        </mat-form-field>
                        <p class="field-error" *ngIf="profileForm.get('telNo')?.invalid">
                            <span *ngIf="profileForm.get('telNo')?.errors?.numbersOnly">
                                Please enter your Telephone No.
                            </span>
                        </p>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Birth date Field -->
                    <div class="field" [ngClass]="reqBirthDate === 'P'? 'required': ''">
                        <ng-container *ngIf="profileForm.controls['brTag'].value === 'R' else showDateOfReg">
                            <label>Birth Date</label>
                            <mat-form-field appearance="fill">
                                <input matInput birthDate placeholder="MM/DD/YYYY" [max]="maxDate" [matDatepicker]="picker" formControlName="birthDate" (dateChange)="chkValidDate()" [attr.maxlength]="this.birthMax" (input)="setBirthMax()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>
                        <ng-template #showDateOfReg>
                            <label>Date of Registration</label>
                            <mat-form-field appearance="fill">
                                <input matInput regDate placeholder="MM/DD/YYYY" [max]="maxDate" [matDatepicker]="picker" formControlName="birthDate" (dateChange)="chkValidDate()" [attr.maxlength]="this.birthMax" (input)="setBirthMax()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <!-- Gender Field -->
                    <div class="field" *ngIf="profileForm.controls['brTag'].value === 'R'" [ngClass]="reqGender === 'P'? 'required': ''">
                        <label>Gender</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setGenderPlaceholder()" formControlName="gender" [matAutocomplete]="autoGender" (input)="setGenderViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoGender="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setGenderViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredGender | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'gender')">
                            {{formService.getFieldErrorMsg(profileForm, "gender")}}
                        </p>
                    </div>
                    <!-- Registration No. Field -->
                    <div class="field" *ngIf="profileForm.controls['brTag'].value === 'B'" [ngClass]="reqRegNo === 'P'? 'required': ''">
                        <label>Registration No.</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Registration No." formControlName="regNo">
                            <mat-error *ngIf="profileForm.get('regNo')?.invalid">Please enter your Registration No.</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Marital Status Field -->
                    <div class="field" *ngIf="profileForm.controls['brTag'].value === 'R'" [ngClass]="reqMarital === 'P'? 'required': ''">
                        <label>Marital Status</label>
                        <p *ngIf="(productInfo.lineCd=='PA' && productInfo.creationProcess=='renewal') else showMaritalStat" class="marital-stat">{{productInfo.customerInfo.maritalDetail?.text.toUpperCase() || '-'}}</p>
                        <ng-template #showMaritalStat>
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [placeholder]="setMaritalPlaceholder()" formControlName="maritalStat" [matAutocomplete]="autoMarital" (input)="setMaritalViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <mat-autocomplete #autoMarital="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setMaritalViaOption($event.option)">
                                    <mat-option *ngFor="let option of filteredMaritalStat | async" [value]="option">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'maritalStat')">
                                {{formService.getFieldErrorMsg(profileForm, "maritalStat")}}
                            </p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Country of Birth Field -->
                    <div class="field" [ngClass]="reqCountryOfBirth === 'P'? 'required': ''">
                        <ng-container *ngIf="profileForm.controls['brTag'].value === 'R' else showCountryOrigin">
                            <label>Country of Birth</label>
                        </ng-container>
                        <ng-template #showCountryOrigin>
                            <label>Country of Origin</label>
                        </ng-template>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setCountryPlaceholder('countryOfBirth')" formControlName="countryOfBirth" [matAutocomplete]="autoCountryOfBirth" (input)="setCountryViaInput('countryOfBirth')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoCountryOfBirth="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryViaOption($event.option, 'countryOfBirth')">
                                <mat-option *ngFor="let option of filteredCountryOfBirth | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'countryOfBirth')">
                            {{formService.getFieldErrorMsg(profileForm, "countryOfBirth")}}
                        </p>
                    </div>
                    <!-- Province or City of Birth Field -->
                    <!--<div class="field" [ngClass]="reqProvOfBirth === 'P'? 'required': ''">
                        <ng-container *ngIf="profileForm.controls['brTag'].value === 'R' else showProvOrigin">
                            <label>Province or City of Birth</label>
                        </ng-container>
                        <ng-template #showProvOrigin>
                            <label>Province or City of Origin</label>
                        </ng-template>
                        <mat-form-field appearance="fill">
                            <input matInput class='weighted' formControlName="provOrCityOfBirth">
                            <mat-icon (click)="lov.open()" *ngIf="showLov"> search</mat-icon>
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'provOrCityOfBirth')">
                            {{formService.getFieldErrorMsg(profileForm, "provOrCityOfBirth")}}
                        </p>
                    </div>-->

                    <div class="field" [ngClass]="reqProvOfBirth === 'P'? 'required': ''">
                        <ng-container *ngIf="profileForm.controls['brTag'].value === 'R' else showProvOrigin">
                            <label>City of Birth</label>
                        </ng-container>
                        <ng-template #showProvOrigin>
                            <label>City of Origin</label>
                        </ng-template>
                        <ng-container *ngIf="profileForm.controls['countryOfBirth'].value.value == defaultCountry.toString() && !profileForm.controls['provOrCityOfBirthDesc'].value else showProvOrCityofBirth">
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [placeholder]="setProvPlaceholder('provOrCityOfBirth')" formControlName="provOrCityOfBirth" [matAutocomplete]="autoProvOfBirth" (input)="setProvViaInput('provOrCityOfBirth', $event)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <mat-autocomplete #autoProvOfBirth="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvViaOption($event.option, 'none')">
                                    <mat-option *ngFor="let option of filteredProvOfBirth | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'provOrCityOfBirth')">
                                {{formService.getFieldErrorMsg(profileForm, "provOrCityOfBirth")}}
                            </p>
                        </ng-container>
                        <ng-template #showProvOrCityofBirth>
                            <mat-form-field appearance="fill">
                                <input matInput [placeholder]="setProvPlaceholder('provOrCityOfBirth')" formControlName="provOrCityOfBirthDesc" uppercase>
                                <mat-error *ngIf="profileForm.get('provOrCityOfBirth')?.invalid">Please enter your City of Birth</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <!-- Nationality Field -->
                    <div class="field" [ngClass]="reqNationality === 'P'? 'required': ''">
                        <label>Citizenship / Nationality</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setNationalityPlaceholder()" formControlName="nationality" [matAutocomplete]="autoNationality" (input)="setNationalityViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setNationalityViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredNationality | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'nationality')">
                            {{formService.getFieldErrorMsg(profileForm, "nationality")}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="col-case">
                    <!-- Complete/Permanent address Field -->
                    <div class="field" [ngClass]="reqAddress === 'P'? 'required': ''">
                        <label>Permanent Address</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Complete Address" formControlName="compAdd" value="" (input)="setPresent('compAdd')" uppercase trim>
                            <mat-error *ngIf="profileForm.get('compAdd')?.invalid">Please enter your Complete address</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Country Field -->
                    <div class="field" [ngClass]="reqCountry === 'P'? 'required': ''">
                        <label>Country</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setCountryPlaceholder('countryBirth')" formControlName="countryBirth" [matAutocomplete]="autoCountry" (input)="setCountryViaInput('countryBirth')" (change)="setPresent('countryBirth')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryViaOption($event.option, 'countryBirth');setPresent('countryBirth')">
                                <mat-option *ngFor="let option of filteredCountry | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'countryBirth')">
                            {{formService.getFieldErrorMsg(profileForm, "countryBirth")}}
                        </p>
                    </div>
                    <!-- Province or City Field -->
                    <div class="field" [ngClass]="reqProvince === 'P'? 'required': ''">
                        <label>City</label>
                        <ng-container *ngIf="profileForm.controls['countryBirth'].value.value == defaultCountry.toString() else showProvOrCity">
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [placeholder]="setProvPlaceholder('provOrCity')" formControlName="provOrCity" [matAutocomplete]="autoProv" (input)="setProvViaInput('provOrCity', $event)" (change)="setPresent('provOrCity')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <mat-autocomplete #autoProv="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvViaOption($event.option, 'postCode'); setPresent('provOrCity')">
                                    <mat-option *ngFor="let option of filteredProvince | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'provOrCity')">
                                {{formService.getFieldErrorMsg(profileForm, "provOrCity")}}
                            </p>
                        </ng-container>
                        <ng-template #showProvOrCity>
                            <mat-form-field appearance="fill">
                                <input matInput [placeholder]="setProvPlaceholder('provOrCity')" formControlName="provOrCityDesc" uppercase>
                                <mat-error *ngIf="profileForm.get('provOrCity')?.invalid">Please enter your City</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <!-- Postal Code Field -->
                    <div class="field" [ngClass]="reqPostal === 'P'? 'required': ''">
                        <label>Postal Code</label>
                        <ng-container *ngIf="profileForm.controls['countryBirth'].value.value == defaultCountry.toString() else showPostal">
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [placeholder]="setPostalPlaceholder('postCode')" formControlName="postCode" [matAutocomplete]="autoPostal" (input)="setPostalViaInput('postCode')" (change)="setPresent('postCode')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <mat-autocomplete #autoPostal="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPostalViaOption($event.option, 'provOrCity'); setPresent('postCode')">
                                    <mat-option *ngFor="let option of filteredPostal | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd" [attr.cityCd]="option.cityCd">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'postCode')">
                                {{formService.getFieldErrorMsg(profileForm, "postCode")}}
                            </p>
                        </ng-container>
                        <ng-template #showPostal>
                            <mat-form-field appearance="fill">
                                <input matInput [placeholder]="setPostalPlaceholder('postCode')" formControlName="postCodeDesc" uppercase>
                                <mat-error *ngIf="profileForm.get('postCode')?.invalid">Please enter your Postal</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>
                <div class="col-case">
                    <!-- Present Address Field -->
                    <div class="field" [ngClass]="reqPresAdd === 'P'? 'required': ''">
                        <label>Present Address</label>
                    </div>
                    <div class="checkbox">
                        <mat-checkbox (change)="checked($event)" [checked]="sameAsPermanent">Same as Permanent Address</mat-checkbox>    
                    </div>
                    <div class="field" [ngClass]="reqAddress === 'P'? 'required': ''">
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Complete Address" formControlName="presAdd" value="" [ngClass]="sameAsPermanent ? 'hide' : ''" uppercase trim>
                            <input matInput placeholder="Complete Address" [value]="profileForm.get('presAdd')?.value" readonly [ngClass]="sameAsPermanent ? '' : 'hide'">
                            <mat-error *ngIf="profileForm.get('presAdd')?.invalid">Please enter your Complete address</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Present Country Field -->
                    <div class="field" [ngClass]="reqPresCountry === 'P'? 'required': ''">
                        <label>Country</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [ngClass]="sameAsPermanent ? 'hide' : ''" [placeholder]="setCountryPlaceholder('presCountry')" formControlName="presCountry" [matAutocomplete]="autoPresCountry" (input)="setCountryViaInput('presCountry')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <input matInput [value]="profileForm.get('presCountry')?.value.text" readonly [ngClass]="sameAsPermanent ? '' : 'hide'">
                            <mat-autocomplete #autoPresCountry="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryViaOption($event.option, 'presCountry')">
                                <mat-option *ngFor="let option of filteredPresCountry | async" [value]="option" [ngClass]="sameAsPermanent ? 'hide' : ''">{{option.text}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'presCountry')">
                            {{formService.getFieldErrorMsg(profileForm, "presCountry")}}
                        </p>
                    </div>
                    <!-- Present Province or City Field -->
                    <div class="field" [ngClass]="reqPresProv === 'P'? 'required': ''">
                        <label>City</label>
                        <ng-container *ngIf="profileForm.controls['presCountry'].value.value == defaultCountry.toString() else showPresProv">
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [ngClass]="sameAsPermanent ? 'hide' : ''" [placeholder]="setProvPlaceholder('presProv')" formControlName="presProv" [matAutocomplete]="autoPresProv" (input)="setProvViaInput('presProv', $event)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <input matInput [value]="profileForm.get('presProv')?.value.text" [placeholder]="setProvPlaceholder('presProv')" readonly [ngClass]="sameAsPermanent ? '' : 'hide'">
                                <mat-autocomplete #autoPresProv="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvViaOption($event.option, 'presPostCode')">
                                    <mat-option *ngFor="let option of filteredPresProv | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd" [ngClass]="sameAsPermanent ? 'hide' : ''">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'presProv')">
                                {{formService.getFieldErrorMsg(profileForm, "presProv")}}
                            </p>
                        </ng-container>
                        <ng-template #showPresProv>
                            <mat-form-field appearance="fill">
                                <input matInput [placeholder]="setProvPlaceholder('presProv')" formControlName="presProvDesc" [value]="profileForm.get('provOrCityDesc')?.value" readonly [ngClass]="sameAsPermanent ? '' : 'hide'" uppercase>
                                <input matInput [placeholder]="setProvPlaceholder('presProv')" formControlName="presProvDesc" [ngClass]="sameAsPermanent ? 'hide' : ''" uppercase>
                                <mat-error *ngIf="profileForm.get('presProvDesc')?.invalid">Please enter your Present Province</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <!-- Present Postal Code Field -->
                    <div class="field" [ngClass]="reqPresPostCode === 'P'? 'required': ''">
                        <label>Postal Code</label>
                        <ng-container *ngIf="profileForm.controls['presCountry'].value.value == defaultCountry.toString() else showPresPostCode">
                            <mat-form-field class="select" appearance="fill">
                                <input matInput [ngClass]="sameAsPermanent ? 'hide' : ''" [placeholder]="setPostalPlaceholder('presPostCode')" formControlName="presPostCode" [matAutocomplete]="autoPresPostal" (input)="setPostalViaInput('presPostCode')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                <input matInput [value]="profileForm.get('presPostCode')?.value.text" [placeholder]="setPostalPlaceholder('presPostCode')" readonly [ngClass]="sameAsPermanent ? '' : 'hide'">
                                <mat-autocomplete #autoPresPostal="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPostalViaOption($event.option, 'presProv')">
                                    <mat-option *ngFor="let option of filteredPresPostCode | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd" [attr.cityCd]="option.cityCd" [ngClass]="sameAsPermanent ? 'hide' : ''">{{option.text}}</mat-option>
                                </mat-autocomplete>    
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'presPostCode')">
                                {{formService.getFieldErrorMsg(profileForm, "presPostCode")}}
                            </p>
                        </ng-container>
                        <ng-template #showPresPostCode>
                            <mat-form-field appearance="fill">
                                <input matInput [placeholder]="setPostalPlaceholder('presPostCode')" formControlName="presPostCodeDesc" [value]="profileForm.get('postCodeDesc')?.value" [readonly]="sameAsPermanent" [ngClass]="sameAsPermanent ? '' : 'hide'" uppercase>
                                <input matInput [placeholder]="setPostalPlaceholder('presPostCode')" formControlName="presPostCodeDesc" [value]="profileForm.get('presPostCodeDesc')?.value" [ngClass]="sameAsPermanent ? 'hide' : ''" uppercase>
                                <mat-error *ngIf="profileForm.get('presPostCodeDesc')?.invalid">Please enter your Present Postal Code</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="col-case col-3">
                    <!-- Source of Funds Field -->
                    <div class="field" [ngClass]="reqFunds === 'P'? 'required': ''">
                        <label>Source of Funds</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setFundsPlaceholder()" formControlName="sourceFunds" [matAutocomplete]="autoSourceFunds" (input)="setFundsViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoSourceFunds="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setFundsViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredFunds | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'sourceFunds')">
                            {{formService.getFieldErrorMsg(profileForm, "sourceFunds")}}
                        </p>
                    </div>
                    <!-- Name of Employer/Business Field -->
                    <div class="field extend" [ngClass]="reqNameEmployer === 'P'? 'required': ''">
                        <label>Name of Employer/Business</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Employer/Business" formControlName="nameEmployer" value="">
                            <mat-error *ngIf="profileForm.get('nameEmployer')?.invalid">Please enter the Name of your Employer or Business</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Nature of Employment/Business Field-->
                    <div class="field" [ngClass]="reqNatureEmployment === 'P'? 'required': ''">
                        <label>Nature of Employment/Business</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Employment/Business" formControlName="natureEmployment" value="">
                            <mat-error *ngIf="profileForm.get('natureEmployment')?.invalid">Please enter the Nature of your Employment</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Occupation Field -->
                    <div class="field" [ngClass]="reqOccupation === 'P'? 'required': ''">
                        <label>Occupation</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setOccupationPlaceholder()" formControlName="occupation" [matAutocomplete]="autoOccupation" (input)="setOccupationViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoOccupation="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setOccupationViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredOccupation | async" [value]="option" [attr.catCd]="option.catCd">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'occupation')">
                            {{formService.getFieldErrorMsg(profileForm, "occupation")}}
                        </p>
                    </div>
                    <!-- Others - Please Specify Field -->
                    <div class="field bottom" [ngClass]="profileForm.get('occupation')?.value.value == this.occOther ? 'required': ''">
                        <label>Others - Please Specify</label>
                        <mat-form-field appearance="fill">
                            <input matInput placeholder="Occupation" formControlName="occOther" value="" [attr.maxlength]="50" uppercase>
                            <!--<input matInput placeholder="Occupation" formControlName="occOther" value="" [attr.maxlength]="50" uppercase [ngClass]="profileForm.get('occupation')?.value.value == this.occOther ? '' : 'hide'">
                            <input matInput placeholder="Occupation" readonly [ngClass]="profileForm.get('occupation')?.value.value == this.occOther ? 'hide' : ''"> -->
                            <mat-error *ngIf="profileForm.get('occOther')?.invalid">Please enter your Occupation</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-case col-3">
                    <!-- Tax Identification No. Field -->
                    <div class="field">
                        <div class="field tin" [ngClass]="reqTin === 'P'? 'required': ''">
                            <label>Tax Identification No.</label>
                            <mat-form-field appearance="fill">
                                <input matInput tin formControlName="tinNo" value="" [attr.maxlength]="this.tinMax" (input)="setTinMax()">
                                <!--<input matInput tin formControlName="tinNo" value="" [attr.maxlength]="this.tinMax" (input)="setTinMax()" [ngClass]="noTin ? 'hide' : ''">
                                <input matInput placeholder="No TIN" readOnly value="No TIN" [ngClass]="noTin ? '' : 'hide'"> -->
                                <!--<mat-error *ngIf="profileForm.get('tinNo')?.invalid">Please enter your Tax Identification No.</mat-error> -->
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'tinNo')">
                                {{formService.getFieldErrorMsg(profileForm, "tinNo")}}
                            </p>
                            <p class="field-error" *ngIf="!formService.isInvalid(profileForm, 'tinNo') && tinErrorMessage">
                                {{tinErrorMessage}}
                            </p>
                        </div>
                        <ng-container *ngIf="setNoTinBox">
                            <div class="checkbox tin">
                                <mat-checkbox (change)="chkNoTin($event)" [checked]="noTin">No TIN</mat-checkbox>
                            </div>
                        </ng-container>
                    </div>
                    <!-- Job Category Field -->
                    <div class="field" [ngClass]="(userType === 'P'||!userDetailsService.userId) && reqCategory === 'P'? 'required hide': reqCategory === 'P' && userType != 'P'? 'required': reqCategory != 'P' && (userType === 'P'||!userDetailsService.userId) ? 'hide': ''">
                        <label>Job Category</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setCategoryPlaceholder()" formControlName="category" [matAutocomplete]="autoCategory" (input)="setCategoryViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCategoryViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredCategory | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'category')">
                            {{formService.getFieldErrorMsg(profileForm, "category")}}
                        </p>
                    </div>
                    <ng-container *ngIf="this.userDetailsService.userId">
                        <div class="field bottom" [ngClass]="(userType == 'E' || userType == 'B') && reqRmNo === 'P'? 'required': reqRmNo === 'P' && (userType == 'P' || userType == 'A')? 'hide': reqRmNo != 'P' && (userType == 'P' || userType == 'A')? 'hide': ''">
                            <label>RM No.</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="RM No." formControlName="rmNo" value="" [attr.maxlength]="this.rmLength" rmNo>
                                <mat-error *ngIf="profileForm.get('rmNo')?.invalid">Please enter your RM No.</mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
                <!-- <div class="col-case col-3">
                    <div class="field" >
                        <label>Supporting Documents</label>
                        <label class="upload-docs-container btn">
                            <span class="upload-support-docs">Upload File (Total file size up to 10MB)</span>
                            <ng-container>
                                <input #imageInput2 type="file" accept="image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="addSupportDocs(imageInput2)" class="image">
                                <input #imageInput2 type="file" (change)="addSupportDocs(imageInput2, 1)" class="image">
                            </ng-container>
                        </label>
                        
                    </div>
                </div> -->
                <div class="col-case col-3">
                    <!-- Risk profiling Field -->
                    <div class="field" [ngClass]="(userType != 'E') && reqRisk === 'P'? 'required hide': reqRisk === 'P' && (userType == 'E')? 'required': reqRisk != 'P' && (userType != 'E')? 'hide': ''">
                        <label>Risk Profiling</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput [placeholder]="setRiskPlaceholder()" formControlName="riskPro" [matAutocomplete]="autoRisk" (input)="setRiskViaInput()" oninput="this.value = this.value.toUpperCase()" autocomplete>
                            <mat-autocomplete #autoRisk="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setRiskViaOption($event.option)">
                                <mat-option *ngFor="let option of filteredRisk | async" [value]="option">{{option.text}}</mat-option>
                            </mat-autocomplete>    
                        </mat-form-field>
                        <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'riskPro')">
                            {{formService.getFieldErrorMsg(profileForm, "riskPro")}}
                        </p>
                    </div>
                    <!-- Supporting Documents Field -->
                    <div class="field supporting-docs">
                        <label>Supporting Documents</label>
                        <button type="button" class="btn btn6 proxima" (click)="fileInput.click()">Upload File (Total file size up to 10MB)</button>
                        <input type="file" #fileInput (change)="addSupportDocs($event)" multiple hidden>
                        <p class="error-msg" *ngIf="supportDocErrorMsg">{{supportDocErrorMsg}}</p>
                        <div class="files" *ngFor="let a of profileForm.controls['supportingDocs'].value; index as i">
                            <p>{{a.filename}}</p>
                            <div class="buttons">
                                <p class="view" (click)="viewFile(i)">View</p>
                                <p class="remove" (click)="removeFile(i)">Remove</p>
                            </div>
                        </div>
                    </div>
                    <!--<table class="table-headed table-striped"> 
                        <tbody>
                            <tr *ngFor="let item of queue; let j=index" class="row-file-name">
                                <td class="file-name">{{ item.file.name }}</td>
                                <td class="file-view">
                                    <button type="button" class="support-docs-buttons" (click)="viewFile()">View</button>
                                </td>
                                <td class="file-remove">
                                    <button type="button" class="support-docs-buttons" (click)="removeFile(j)">Remove</button>
                                </td>
                                <td class="text-right">{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                                <td>{{ item.progress + ' %' }}</td>
                                <td>
                                    <span *ngIf="item.isPending()" class="tag tag-default"></span>
                                    <span *ngIf="item.isSuccess()" class="tag tag-success"></span>
                                    <span *ngIf="item.inProgress()" class="tag tag-warning"></span>
                                    <span *ngIf="item.isError()" class="tag tag-danger"></span>
                                </td>
                                <td class="text-right">
                                    <a tooltip="Upload file" (click)="item.upload()" *ngIf="item.isUploadable()">
                                        <i class="fa fa-upload"></i>
                                    </a>
                                    <a tooltip="Cancel upload" (click)="item.cancel()" *ngIf="item.inProgress()">
                                        <i class="fa fa-times-circle"></i>
                                    </a>
                                    <a tooltip="Remove from queue" (click)="item.remove()" *ngIf="!item.inProgress()">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>-->
                    <!--<div>
                        <a class="button" (click)="uploader.clearQueue()">Clear queue</a>
                        <a class="button button-primary" (click)="uploader.uploadAll()">Upload all</a>
                    </div>-->
                </div>
            </div>
            <div class="form-btns">
                <button type="button" class="btn btn4" (click)="move('back')">Back</button>
                <button type="button" class="btn btn5" (click)="onSubmit()">Save</button>
                <button type="button" class="btn btn2" *ngIf="productInfo.creationProcess!='renewal'" (click)="move('forward')">Proceed</button>
            </div> 
        </form>
    </div>
</section>

<overlay #validIdList class="overlay-idlist" [title]="'List of Valid IDs'">
    <div content>
        <ul class="list">
            <li class="overlay" *ngFor="let valid of listId">
                {{valid.idTypeList}} <span class="ocrTag" *ngIf="valid.ocrTag === 'Y'"> (OCR enabled)</span>
                <!--<div class="idList">
                    <span>{{valid.idTypeList}}</span>
                    <span class="ocrTag" *ngIf="valid.primaryTag === 'Y'"> (OCR enabled)</span>
                </div>-->
            </li>
        </ul>
    </div>
</overlay>

<overlay #rmConsent class="overlay-idlist" [title]="'Consent'" [showButton]="false">
    <div content class="rm-consent">
        {{bpiRmConsentText}}
        <br><br>
        <div class="buttons">
            <button mat-button class="btn-primary btn" (click)="toBpiRMLogin()">Use My BPI Data to Apply</button>
            <div class="recommended">(Recommended for smoother application experience)</div>
            <button mat-button class="btn-primary btn" (click)="rmConsent.close()">Skip to Application Form</button>
        </div>
    </div>
</overlay>

<!--<lov #lov (selected)="onLovOk($event)" [type]="'province'" [params]="lovParams"></lov>-->