import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

    public showMsgBox = false;

    public header: string = "";
    public message: SafeHtml = "";
    public btns: any[] = [];

    constructor (
        private _sanitizer: DomSanitizer
    ) { }

    private transform(html: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html); 
    }

    private show(header: string, message: string): void {
        switch (header) {
            case "S":
                this.header = "Success Message";
                break;
            case "E":
                this.header = "Error Message";
                break;
            case "I":
                this.header = "Information Message";
                break;
            case "C":
                this.header = "Confirmation Message";
                break;
            default:
                this.header = header;
        }

        this.message = this.transform(message);
        this.showMsgBox = true;
    }

    public show1(header: string, message: string, btn1Lbl: string, btn1Func: any): void {
        this.btns = [{
            btnLbl: btn1Lbl,
            btnFunc: () => {
                if (btn1Func) {
                    btn1Func();
                }

                this.showMsgBox = false;
            }
        }];

        this.show(header, message);
    }

    public show2(header: string, message: string, btn1Lbl: string, btn1Func: any, btn2Lbl: string, btn2Func: any): void {
        this.btns = [{
            btnLbl: btn1Lbl,
            btnFunc: () => {
                if (btn1Func) {
                    btn1Func();
                }

                this.showMsgBox = false;
            }
        }, {
            btnLbl: btn2Lbl,
            btnFunc: () => {
                if (btn2Func) {
                    btn2Func();
                }

                this.showMsgBox = false;
            }
        }];

        this.show(header, message);
    }

}
