import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    templateUrl: './payment-redirect.html'
})
export class PaymentRedirectComponent {

    public form: FormGroup = this.formBuilder.group({
        answer: ["", [Validators.required]]
    });

    public showPasswordValue: boolean = false;
    private policyId: string = "";

    constructor (
        // PUBLIC
        public formService: FormService,

        // PRIVATE
        private formBuilder: FormBuilder,
        private router: Router,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private productService: ProductService,
        private appMessageService: AppMessageService,
        private activatedRoute: ActivatedRoute,
        private parameterService: ParameterService
    ) { 
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params.cpp && p.params.ct) {
                const cpp = decodeURIComponent(p.params.cpp);
                const ct = decodeURIComponent(p.params.ct);

                if (this.hasValidCsrfToken(cpp, ct)) {
                    const params = JSON.parse(this.jsonDataService.decrypt(cpp));
                    const paytLinkExpiry = this.parameterService.paramN("PAYT_LINK_EXPIRY") || 0;
                    if (paytLinkExpiry > 0) {
                        if (this.daysDiff(params.dateCreated) < paytLinkExpiry) {
                            this.policyId = params.policyId;
                        } else {
                            router.navigateByUrl("/");
                        }
                    } else {
                        this.policyId = params.policyId;
                    }
                } else {
                    router.navigateByUrl("/");
                }
            } else {
                router.navigateByUrl("/");
            }
        });
    }

    // Parameter format should be in MM/DD/YYYY (11/05/2021)
    private daysDiff(pastDate: any, futureDate: any = new Date().toLocaleDateString()): number {
        pastDate = pastDate.split("/");
        pastDate = new Date(pastDate[2], pastDate[0]-1, pastDate[1]);

        futureDate = futureDate.split("/");
        futureDate = new Date(futureDate[2], futureDate[0]-1, futureDate[1]);

        return Math.round((futureDate - pastDate) / (1000 * 60 * 60 * 24));
    }

    private hasValidCsrfToken(encryptedResponse: string, csrfToken: string): boolean {
        try {
            const csrfTokens = csrfToken.split(".");
            const token1 = this.isValidToken(csrfTokens[2], csrfTokens[1]);
            const token2 = this.isValidToken(csrfTokens[1], csrfTokens[0]);
            const token3 = this.isValidToken(csrfTokens[0], encryptedResponse);
            return token1 && token2 && token3;
        } catch (e) {
            return false;
        }
    }

    private isValidToken(token1: string, token2: string): boolean {
        const decryptedToken = this.jsonDataService.decrypt(token1);

        if (   decryptedToken.substr(0, 1) !== token2.substr(30, 1)
            || decryptedToken.substr(1, 1) !== token2.substr(10, 1)
            || decryptedToken.substr(2, 1) !== token2.substr(40, 1)
            || decryptedToken.substr(3, 1) !== token2.substr(15, 1)
            || decryptedToken.substr(4, 1) !== token2.substr(35, 1)
            || decryptedToken.substr(5, 1) !== token2.substr( 5, 1)
            || decryptedToken.substr(6, 1) !== token2.substr(25, 1)
            || decryptedToken.substr(7, 1) !== token2.substr(20, 1)
        ) {
            return false;
        }

        return true;
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getRenewalInfo({
            policyId: this.policyId,
            answer: this.form.controls["answer"].value
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            if (response.status === "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (res.error) {
                        this.appMessageService.showAppMessage("Invalid password.", "error");
                    } else {
                        this.productService.setProductInfo2(res);
                        this.productService.setProductInfo("creationProcess", "renewal");
                        // ? This sets fromLink property to payment because its from payment link
                        this.productService.setProductInfo("fromLink", "payment");
                        this.router.navigate(["/policy/summary"]);
                    }
                });
            } else {
                this.appMessageService.showAppMessage("Invalid password.", "error");
            }
      	}); 
    }

}