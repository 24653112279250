import { APP_INITIALIZER, NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';

// MODULES
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { ThirdPartyModule } from './third-party.module';

// COMPONENTS
import { AppComponent } from './app.component';
import { MaintenanceComponent } from './components/maintenance/maintenance';
import { ExpiredReportLinkComponent } from './components/expired-report-link/expired-report-link';
import { AppMessageComponent } from './components/common/app-message/app-message';
import { UseOfCookiesComponent } from './components/use-of-cookies/use-of-cookies';
import { HeaderComponent } from './components/header/header';
import { FooterComponent } from './components/footer/footer';
import { OverlayComponent } from './components/common/overlay/overlay';
import { StepperComponent } from './components/forms/stepper/stepper';
import { StepperDetailComponent } from './components/forms/stepper/stepper-dtl/stepper-dtl';
import { MaterialElevationDirective } from './components/forms/quotation/choose-plan/elevation.directive';
import { RegistrationComponent } from './components/forms/login-register/registration/registration';
import { LoginComponent } from './components/forms/login-register/login/login';

// SUBFORM COMPONENTS
import { SubLoginComponent } from './components/forms/login-register/login/subform/sub-login/sub-login';
import { SubOtpComponent } from './components/forms/login-register/login/subform/sub-otp/sub-otp';
import { SubVerifiedComponent } from './components/forms/login-register/login/subform/sub-verified/sub-verified';
import { SubInitialComponent } from './components/forms/login-register/login/subform/sub-initial/sub-initial';
import { SubForgotComponent } from './components/forms/login-register/login/subform/sub-forgot/sub-forgot';
import { SubResetComponent } from './components/forms/login-register/login/subform/sub-reset/sub-reset';
import { SubRegisterComponent } from './components/forms/login-register/registration/subform/sub-register/sub-register';
import { SubVerifyComponent } from './components/forms/login-register/registration/subform/sub-verify/sub-verify';

// SERVICES
import { JsonDataService } from './services/json-data.service';
import { SafePipe } from './pipes/safe.pipe';
import { LovComponent } from './components/common/lov/lov.component';
import { TooltipListPipe } from './pipes/tooltipList';

import * as alasql from 'alasql';
import { LoadingComponent } from './components/common/loading/loading.component';
import { MessageBoxComponent } from './components/common/message-box/message-box';
import { DialogBoxComponent } from './components/common/dialog-box/dialog-box';
import { ConfirmLeaveComponent } from './components/common/confirm-leave/confirm-leave.component';
import { ConfirmLeaveGuard } from './guards/confirm-leave.guard';

// DIRECTIVES
import { AmountDirective } from './directives/amount.directive';
import { BirthDateDirective } from './directives/birth-date.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { MobileNoDirective } from './directives/mobile-no.directive';
import { TinDirective } from './directives/tin.directive';
import { EmailAddDirective } from './directives/email-add.directive';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { PmToNoonPipe } from './pipes/pm-to-noon.pipe';
import { RegistrationDateDirective } from './directives/registration-date.directive';
import { EmailListComponent } from './components/common/email-list/email-list.component';
import { RmNoDirective } from './directives/rmno.directive';
import { NoSpecialCharDirective } from './directives/no-special-char.directive';
import { PromoCodeDirective } from './directives/promo-code.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { BpiOtpInputDirective } from './components/forms/policy/payment/payment';
import { PopupComponent } from './components/common/popup/popup.component';
import { ParameterService } from './services/parameter.service';
import { LatLongDirective } from './directives/lat-long.directive';
import { PaymentAndRenewalComponent } from './components/forms/payment-and-renewal/payment-and-renewal';
import { PolicyDetailsValidationComponent } from './components/forms/payment-and-renewal/policy-details-validation/policy-details-validation';
import { PaytRefNoDirective } from './directives/payt-ref-no.directive';
import { PolicyDetailsErrMsgPipe } from './pipes/policy-details-err-msg.pipe';
import { LineLimitDirective } from './directives/line-limit.directive';
import { TrimDirective } from './directives/trim.directive';

// let apiKey: string = 'AIzaSyAorK0eB6DQnlhm9LE-UwysPx8dHleBTfE';

@Injectable()
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral{
    public apiKey: string;
    public libraries: string[];
    constructor( parameterService: ParameterService ) {
        this.apiKey =  parameterService.paramV('GMAP_KEY');
        this.libraries = ['places'];
        // apiKey = parameterService.paramV('GMAP_KEY');
    }
}

@NgModule({
  declarations: [
    RoutingComponents,
    AppComponent,
    MaintenanceComponent,
    ExpiredReportLinkComponent,
    AppMessageComponent,
    UseOfCookiesComponent,
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    StepperComponent,
    StepperDetailComponent,
    MaterialElevationDirective,
    RegistrationComponent,
    SubRegisterComponent,
    SubVerifyComponent,
    LoginComponent,
    SubLoginComponent,
    SubOtpComponent,
    SubInitialComponent,
    SubForgotComponent,
    SubResetComponent,
    SafePipe,
    LovComponent,
    LoadingComponent,
    MessageBoxComponent,
    DialogBoxComponent,
    ConfirmLeaveComponent,
    TooltipListPipe,
    AmountDirective,
    UppercaseDirective,
    EmailAddDirective,
    MobileNoDirective,
    BirthDateDirective,
    RegistrationDateDirective,
    TinDirective,
    AutocompleteDirective,
    NumbersOnlyDirective,
    PmToNoonPipe,
    SubVerifiedComponent,
    EmailListComponent,
    RmNoDirective,
    NoSpecialCharDirective,
    PromoCodeDirective,
    BpiOtpInputDirective,
    PopupComponent,
    LatLongDirective,
    PaymentAndRenewalComponent,
    PolicyDetailsValidationComponent,
    PaytRefNoDirective,
    PolicyDetailsErrMsgPipe,
    LineLimitDirective,
    TrimDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NoopAnimationsModule,
    AppRoutingModule,
    ThirdPartyModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot(
      // {
        // apiKey: 'AIzaSyAorK0eB6DQnlhm9LE-UwysPx8dHleBTfE',
        // libraries: ['places']
    //  }
    )
  ],
  entryComponents: [
    DialogBoxComponent,
    ConfirmLeaveComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [JsonDataService],
      useFactory: (jsonDataService:JsonDataService) => () => jsonDataService.init()
    },
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig},
    ConfirmLeaveGuard,
    DatePipe,
    TitleCasePipe,
    DecimalPipe
    //{ provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
