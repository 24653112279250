import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    public downloadFile(file: File): void {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);

        const anchorTag = document.createElement("a");
        anchorTag.setAttribute("href", url);
        anchorTag.setAttribute("download", file.name);
        anchorTag.style.display = "none";

        document.body.appendChild(anchorTag);
        anchorTag.click();
        document.body.removeChild(anchorTag);
    }

    public downloadFileUsingPresignedUrl(presignedUrl: string, filename: string): void {
        const anchorTag = document.createElement("a");
        anchorTag.setAttribute("href", presignedUrl);
        anchorTag.setAttribute("download", filename);
        anchorTag.style.display = "none";

        document.body.appendChild(anchorTag);
        anchorTag.click();
        document.body.removeChild(anchorTag);
    }

}