import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'confirm-leave',
    templateUrl: './confirm-leave.component.html'
})
export class ConfirmLeaveComponent {

    constructor (
        public dialogRef: MatDialogRef<ConfirmLeaveComponent>
    ) { }
}