import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  templateUrl: './policy-details-validation.html'
})
export class PolicyDetailsValidationComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    polNo: ["", [Validators.required]],
    paytRefNo: ["", [Validators.required]],
    lastName: ["", [Validators.required]],
    birthdate: ["", [Validators.required]]
  });

  otpForm: FormGroup = this.formBuilder.group({
    otp: [""]
  });

  otpDetails: any = {
    mobileNo: "",
    email: ""
  };

  policyId: number = 0;
  policyDetailsErrorMsg: string = "";
  paytRefNoMax: number = 19;

  showOtpForm: boolean = false;
  sendOtpInterval: number = 0;
  otpMethod: string = "";
  otpErrorMsg: string = "";
  otpReceiver: string = "";
  withOtpMethod: boolean = false;

  maxDate: Date = new Date();

  polNoTooltip: string = 'The Policy Number may be found in the electronic notice sent to you by BPI MS.';
  paytRefNoTooltip: string = 'The Payment Reference Number may be found in the electronic notice sent to you by BPI MS.';

  constructor(
    public formService: FormService,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private parameterService: ParameterService,
    private productService: ProductService
  ) {
    setInterval(() => {
      if (this.sendOtpInterval > 0) {
        this.sendOtpInterval -= 1;
      }
    }, 1000);
  }

  ngOnInit(): void {
  }

  onClickBack() {
    this.router.navigate(["/"]);
  }

  setPaytRefNoMax() {
    if (this.form.controls['paytRefNo'].value.includes('-') || this.form.controls['paytRefNo'].value === "") {
      this.paytRefNoMax = 19;
    } else {
      this.paytRefNoMax = 16;
    }
  }

  onClickProceed() {
    this.policyDetailsErrorMsg = "";
    if(this.form.valid) {
      var f = this.form.getRawValue();

      f.birthdate = f.birthdate.format('YYYY/MM/DD');

      this.jsonDataService.contorlLoading(true);
      this.apiCallService.validateRenewalDetails({
        polNo: f.polNo,
        paytRefNo: f.paytRefNo,
        lastName: f.lastName,
        birthdate: f.birthdate
      }).subscribe((response: any) => {
        this.jsonDataService.contorlLoading(false);
        this.securityService.checkRequestKeyResponse(response, () => {
          if(response.status === "SUCCESS") {
            const res = JSON.parse(this.jsonDataService.decrypt(response.response));

            if(res.length > 0) {
              this.showOtpForm = true;
              this.otpDetails['mobileNo'] = res[0].mobileNo;
              this.otpDetails['email'] = res[0].email;
              this.policyId = res[0].policyId;
            } else {
              this.policyDetailsErrorMsg = this.parameterService.paramV("POL_VALIDATION_DTLS_ERR_MSG");
            }
          } else {
            this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
          }
        });
      });
    } else {
      this.policyDetailsErrorMsg = "There are missing or invalid information.";
    }
  }

  sendRenewOtp(method: any, withTimeInterval: any) {
    this.otpErrorMsg = "";
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.sendRenewOtp({
      policyId: this.policyId,
      method: method,
      withTimeInterval: withTimeInterval
    }).subscribe((response: any) => {
      this.securityService.checkRequestKeyResponse(response, () => {
        this.jsonDataService.contorlLoading(false);
        if (response.status === "SUCCESS") {
          this.sendOtpInterval = this.parameterService.paramN("OTP_REQUEST_INTERVAL");
          this.withOtpMethod = true;
          this.otpMethod = method;
          this.otpForm.controls["otp"].setValue("");

          if (method === "mobile") {
            this.otpReceiver = this.otpDetails.mobileNo;
          } else {
            this.otpReceiver = this.otpDetails.email;
          }
        } else {
          this.appMessageService.showAppMessage("There's something wrong sending your OTP. Please try again later.", "error");
        }
      });
    });
  }

  verifyRenewOtp() {
    if (this.otpForm.controls["otp"].value) {
      this.otpErrorMsg = "";
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.verifyRenewOtp({
        policyId: this.policyId,
        otp: this.otpForm.controls["otp"].value
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            this.jsonDataService.contorlLoading(false);
            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
            if (res === "SUCCESS") {
              this.jsonDataService.contorlLoading(true);
              this.apiCallService.getRenewalInfo({
                policyId: this.policyId
              }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                  if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                      const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                      this.productService.setProductInfo2(res);
                      this.productService.setProductInfo("creationProcess", "renewal");
                      if(res.renewTag == 'Y') {
                        // ? This sets fromLink property to renewal because its from renewal link
                        this.productService.setProductInfo("fromLink", "renewal");
                      } else {
                        this.productService.setProductInfo("fromLink", "payment");
                      }
                      
                      this.router.navigate(["/policy/summary"]);
                    });
                  } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                  }
                });
              }); 
            } else if (res === "WRONG_OTP") {
              this.otpErrorMsg = "Sorry, you provided the wrong OTP. Please try again or resend another OTP.";
            } else if (res === "EXPIRED") {
              this.otpErrorMsg = "Sorry, the OTP you’ve keyed in has expired. Please click Resend OTP and try again.";
            } else {
              this.otpErrorMsg = "There's something wrong verifying your OTP. Please try again later.";
            }
          });
        });
      });
    } else {
      this.otpErrorMsg = "Complete the OTP to help us verify your identity.";
    }
  }

  cancelOtp() {
    this.showOtpForm = false;
    this.otpErrorMsg = "";
    this.withOtpMethod = false;
    this.form.patchValue({
      polNo: "",
      paytRefNo: "",
      lastName: "",
      birthdate: ""
    });
  }

  public signInAnotherWay(): void {
    this.otpErrorMsg = "";
    this.withOtpMethod = false;
  }

}
