<section id="profile">
    <div class="container">
        <div class="section-title">
            <h2>User Profile</h2>
            <div class="form-btns">
                <button class="btn btn4" (click)="move()">Back</button>
                <button class="btn btn5" (click)="onSubmit()">Save</button>
            </div>
        </div>
        <form [formGroup]="profileForm">
            <div id="profile-form">
                <div class="form-fields">
                    <div class="form">
                        <div class="col-case col-3">
                            <div class="field detail">
                                <label>Registered Email Address</label>
                                <div class="value">{{profileForm.controls['emailAdd'].value || '-'}}</div>
                            </div>
                            <div class="field detail">
                                <label>Client Type</label>
                                <div class="value" *ngIf="profileForm.controls['brTag'].value === 'R'">PERSONAL</div>
                                <div class="value" *ngIf="profileForm.controls['brTag'].value === 'B'">CORPORATE</div>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <h2 class="title">Personal Information</h2>
                        <div class="col-case col-3 name" *ngIf="profileForm.controls['brTag'].value === 'R'">
                            <div class="field required">
                                <label>Last Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Last Name" formControlName="lastName" maxlength="30" uppercase trim>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'lastName')">
                                    {{formService.getFieldErrorMsg(profileForm, "lastName")}}
                                </p>
                            </div>
                            <div class="field required">
                                <label>First Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="First Name" formControlName="firstName" maxlength="20" uppercase trim>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'firstName')">
                                    {{formService.getFieldErrorMsg(profileForm, "firstName")}}
                                </p>
                            </div>
                            <div class="field">
                                <label>Middle Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Middle Name" formControlName="middleName" maxlength="30" uppercase trim>
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>Suffix</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Suffix" formControlName="suffix" maxlength="5" uppercase trim>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="profileForm.controls['brTag'].value === 'B'">
                            <div class="field required">
                                <label>Company Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Company Name" formControlName="companyName" maxlength="63" uppercase trim>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'companyName')">
                                    {{formService.getFieldErrorMsg(profileForm, "companyName")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field required">
                                <label>Mobile No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="mobileNo" maxlength="11" mobileNo>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(profileForm, 'mobileNo')">
                                    Invalid mobile number.
                                </p>
                            </div>
                            <div class="field">
                                <label>Telephone No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Telephone No." formControlName="telNo" maxlength="15">
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>Tax Identification No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="tin" tin>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field required">
                                <label>{{profileForm.controls['brTag'].value === 'R' ? 'Birth Date' : 'Date of Registration'}}</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="MM/DD/YYYY" formControlName="birthRegistrationDate" [max]="maxDate" [matDatepicker]="picker" (dateChange)="validateBirthDate()">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <ng-container *ngIf="profileForm.controls['brTag'].value === 'R'">
                                <div class="field">
                                    <label>Gender</label>
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="Gender" formControlName="genderDetail" [matAutocomplete]="autoGenderDetail" (input)="setGenderViaInput($event)" autocomplete>
                                        <mat-autocomplete #autoGenderDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                            <mat-option *ngFor="let option of filteredGenderDetailOptions | async" [value]="option">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="field">
                                    <label>Marital Status</label>
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="Marital Status" formControlName="maritalStatDetail" [matAutocomplete]="autoMaritalDetail" (input)="setMaritalViaInput($event)" autocomplete>
                                        <mat-autocomplete #autoMaritalDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                            <mat-option *ngFor="let option of filteredMaritalStatDetailOptions | async" [value]="option">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <div class="field" *ngIf="profileForm.controls['brTag'].value === 'B'">
                                <label>Registration No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Registration No." formControlName="registrationNo" maxlength="15">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field">
                                <label>{{profileForm.controls['brTag'].value === 'R' ? 'Country of Birth' : 'Country of Origin'}}</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="profileForm.controls['brTag'].value === 'R' ? 'Country of Birth' : 'Country of Origin'" formControlName="countryOfBirthOriginDetail" [matAutocomplete]="autoCountryOfBirthOriginDetail" (input)="setCountryOfBirthOriginViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoCountryOfBirthOriginDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryOfBirthOriginViaOption()">
                                        <mat-option *ngFor="let option of filteredCountryOfBirthOriginDetailOptions | async" [value]="option" [disabled]="!option.value">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>{{profileForm.controls['brTag'].value === 'R' ? 'City of Birth' : 'City of Origin'}}</label>
                                <ng-container *ngIf="showBirthOriginPhilippinesDependent">
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput [placeholder]="profileForm.controls['brTag'].value === 'R' ? 'City of Birth' : 'City of Origin'" formControlName="provOfBirthOriginDetail" [matAutocomplete]="autoProvOfBirthOriginDetail" (input)="setProvOfBirthOriginViaInput($event)" autocomplete>
                                        <mat-autocomplete #autoProvOfBirthOriginDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                            <mat-option *ngFor="let option of filteredProvOfBirthOriginDetailOptions | async" [value]="option" [attr.provinceCd]="option.provinceCd" [disabled]="!option.value">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!showBirthOriginPhilippinesDependent">
                                    <mat-form-field appearance="fill">
                                        <input matInput [placeholder]="profileForm.controls['brTag'].value === 'R' ? 'City of Birth' : 'City of Origin'" formControlName="provOfBirthOrigin" maxlength="50" uppercase>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                            <div class="field">
                                <label>Citizenship / Nationality</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Nationality" formControlName="nationalityDetail" [matAutocomplete]="autoNationalityDetail" (input)="setNationalityViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoNationalityDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredNationalityDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case">
                            <div class="field">
                                <label>Permanent Address</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Complete Address" formControlName="permanentAddr" maxlength="160" (input)="setPresentAddress('permanentAddr')" uppercase trim>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field">
                                <label>Country</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Country" formControlName="permanentCountryDetail" [matAutocomplete]="autoPermanentCountryDetail" (input)="setPermanentCountryViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoPermanentCountryDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPermanentCountryViaOption()">
                                        <mat-option *ngFor="let option of filteredPermanentCountryDetailOptions | async" [value]="option" [disabled]="!option.value">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>    
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>City</label>
                                <ng-container *ngIf="showPermanentPhilippinesDependent">
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="City" formControlName="permanentProvinceDetail" [matAutocomplete]="autoPermanentProvinceDetail" (input)="setPermanentProvinceViaInput($event, true)" autocomplete>
                                        <mat-autocomplete #autoPermanentProvinceDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPermanentProvinceViaOption()">
                                            <mat-option *ngFor="let option of filteredPermanentProvinceDetailOptions | async" [value]="option" [attr.provinceCd]="option.provinceCd" [disabled]="!option.value">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!showPermanentPhilippinesDependent">
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="City" formControlName="permanentProvince" maxlength="50" (input)="setPermanentProvinceViaInput($event, false)" uppercase>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                            <div class="field">
                                <label>Postal Code</label>
                                <ng-container *ngIf="showPermanentPhilippinesDependent">
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="Postal Code" formControlName="permanentPostalCodeDetail" [matAutocomplete]="autoPermanentPostalCodeDetail" (input)="setPermanentPostalCodeViaInput($event, true)" autocomplete>
                                        <mat-autocomplete #autoPermanentPostalCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPermanentPostalCodeViaOption()">
                                            <mat-option *ngFor="let option of filteredPermanentPostalCodeDetailOptions | async" [value]="option" [attr.provinceCd]="option.provinceCd" [attr.cityCd]="option.cityCd" [disabled]="!option.value">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>    
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!showPermanentPhilippinesDependent">
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="Postal Code" formControlName="permanentPostalCode" maxlength="30" (input)="setPermanentPostalCodeViaInput($event, false)" uppercase>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case chk-same-address">
                            <mat-checkbox (change)="sameAsPermanentAddressEvent($event)" [checked]="profileForm.controls['sameAddress'].value === 'Y'">
                                Same as Permanent Address
                            </mat-checkbox>
                        </div>
                        <div class="col-case">
                            <div class="field">
                                <label>Present Address</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Complete Address" formControlName="presentAddr" maxlength="160" uppercase trim>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field">
                                <label>Country</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Country" formControlName="presentCountryDetail" [matAutocomplete]="autoPresentCountryDetail" (input)="setPresentCountryViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoPresentCountryDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPresentCountryViaOption()">
                                        <mat-option *ngFor="let option of filteredPresentCountryDetailOptions | async" [value]="option" [disabled]="!option.value">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>City</label>
                                <ng-container *ngIf="showPresentPhilippinesDependent">
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="City" formControlName="presentProvinceDetail" [matAutocomplete]="autoPresentProvinceDetail" (input)="setPresentProvinceViaInput($event)" autocomplete>
                                        <mat-autocomplete #autoPresentProvinceDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPresentProvinceViaOption()">
                                            <mat-option *ngFor="let option of filteredPresentProvinceDetailOptions | async" [value]="option" [attr.provinceCd]="option.provinceCd" [disabled]="!option.value">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!showPresentPhilippinesDependent">
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="City" formControlName="presentProvince" maxlength="50" uppercase trim>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                            <div class="field">
                                <label>Postal Code</label>
                                <ng-container *ngIf="showPresentPhilippinesDependent">
                                    <mat-form-field class="select" appearance="fill">
                                        <input matInput placeholder="Postal Code" formControlName="presentPostalCodeDetail" [matAutocomplete]="autoPresentPostalCodeDetail" (input)="setPresentPostalCodeViaInput($event, true)" autocomplete>
                                        <mat-autocomplete #autoPresentPostalCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPresentPostalCodeViaOption()">
                                            <mat-option *ngFor="let option of filteredPresentPostalCodeDetailOptions | async" [value]="option" [attr.provinceCd]="option.provinceCd" [attr.cityCd]="option.cityCd" [disabled]="!option.value">
                                                {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>    
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="!showPresentPhilippinesDependent">
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="Postal Code" formControlName="presentPostalCode" maxlength="30" (input)="setPresentPostalCodeViaInput($event, false)" uppercase>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-btns">
                <button class="btn btn4" (click)="move()">Back</button>
                <button class="btn btn5" (click)="onSubmit()">Save</button>
            </div> 
        </form>
    </div>
</section>