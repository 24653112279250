import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

    /************************** FIELD FORMATTERS **************************/
    
    setFieldToUpperCase(form:FormGroup, field:string, autocomplete:boolean, event:any):void {
        if (autocomplete) {
            if (event.target.value) {
                form.controls[field].setValue({
                    text: event.target.value.toUpperCase()
                });
            } else {
                form.controls[field].setValue("");
            }
        } else {
            form.controls[field].setValue(event.target.value.toUpperCase());
        }
    }

    formatAmount(amount:number):string {
        const nm = (amount + "").split(".");
        let wn = nm[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        wn = wn.substr(0, 1) === "0" ? wn.substr(1) : wn;
        const dn = parseFloat("0." + (nm[1] || "00")).toFixed(2).substr(1);
        return (wn || "0") + dn;
    }

    /************************** CUSTOM VALIDATORS **************************/

    autocomplete:any = ():ValidatorFn => {
        return (control:AbstractControl): { [key:string]:any } | null => {
            if (control.value !== "" && !control.value.value) {
                return { "autocomplete": { value: control.value } }
            }

            return null;
        }
    };

    tinValidator:any = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const allowed = (/^([0-9]{3}\-){3}[0-9]{3,5}?$/g.test(control.value) && control.value.split('').some((a:any)=>a!=control.value[0] && a!='-')) || control.value == '';
            return !allowed ? { 'tin': { value: true } } : null;
        };
    }

    latLongValidator:any = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value){
                return null;
            }else{
                let parts:string[] = control.value.split('.')
                const allowed = parts.length == 2 && parts[0].length <= 3 && /[0-9]+/.test(parts[0]) && /[0-9]+/.test(parts[1]);
                return !allowed ? { 'latLongValidator': { value: true } } : null;
            }
        };
    }

    constYear:any = (minYear?: number): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let currentYear = Number(new Date().getFullYear());
            let year = control.value ? parseInt(control.value) : 0;
            //const allowed = /^([0-9]{3}\-){3}[0-9]{3,5}?$/g.test(control.value) || control.value == '';
            if (!(currentYear - year > -1)) {
                return { 'constYearMax': { value: true } };
            }
            if (year < (minYear ?? 0)) {
                return { 'constYearMin': { value: true, minYear: minYear } };
            }
            return null;
        };
    }

    // email:any = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    /************************** FIELD MESSAGE **************************/

    fieldMsg:any = {
        required: "This field is required.",
        email: "Not a valid email address.",
        autocomplete: "Value is not recognized. Please click one of the options.",
        tinValidator: "TIN format must be 12-15 digit numbers separated by dash (-). Example 999-999-999-000 or 999-999-999-00000",
        constYearMax: "Construction year must be earlier or the same to the current year.",
        constYearMin: "Construction year must be later than NUMBERYEAR.",
        latLong: "Please enter value in Decimal degrees (DD) format. Example: 15.1188338"
    };

    isInvalid(form:any, field:string):boolean {
        return form.get(field)?.touched && form.get(field).invalid;
    }

    getFieldErrorMsg(form:any, field:string):string {
        let msg = "";
        if (form.get(field).hasError("required")) {
            msg = this.fieldMsg.required;
        } else if (form.get(field).hasError("email")) {
            msg = this.fieldMsg.email;
        } else if (form.get(field).hasError("autocomplete")) {
            msg = this.fieldMsg.autocomplete;
        } else if (form.get(field).hasError("tin")) {
            msg = this.fieldMsg.tinValidator;
        } else if (form.get(field).hasError("constYearMax")) {
            msg = this.fieldMsg.constYearMax;
        } else if (form.get(field).hasError("constYearMin")) {
            msg = this.fieldMsg.constYearMin.replace("NUMBERYEAR", form.get(field)?.errors?.constYearMin?.minYear);
        } else if (form.get(field).hasError("latLongValidator")) {
            msg = this.fieldMsg.latLong;
        } 
        return msg;
    }

    /************************** FORM MESSAGE **************************/

    msgType:any = {
        S: "success",
        E: "error",
        I: "info"
    };

    showFormMsg(elementClass:string, message:string, msgType:string):void {
        const element = document.querySelectorAll("." + elementClass);
        element.forEach((field) => {
            field.innerHTML = `
                <div class="form-msg ${this.msgType[msgType]}">
                    <p>${message}</p>
                </div>
            `;
        });
    }

    hideFormMsg(elementClass:string):void {
        const element = document.querySelectorAll("." + elementClass);
        element.forEach((field) => {
            field.innerHTML = "";
        });
    }

    /************************** FORM LOADER **************************/

    showFormLoader(component:any, formId:string, message:string, func:any, funcParams?:any, hideFormBtns?:boolean):void {
        const form = document.querySelector("#" + formId + " .form-loader");
        if (form !== null) {
            form.innerHTML = `
                <div class="form-loader-container">
                    <img src="./assets/images/common/bpims-logo.png">
                    <div class="loader-logo">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                        <div class="circle circle-3"></div>
                    </div>
                    <p class="loader-msg montserrat">${message}</p>
                </div>
            `;

            const formField = document.querySelector("#" + formId + " > .form-fields");
            if (formField !== null) formField.classList.add("hide");

            if (hideFormBtns !== undefined ? hideFormBtns : true) {
                const stepperBtns = document.querySelector("#stepper-dtl .btn-wrap");
                if (stepperBtns !== null) stepperBtns.classList.add("hide");

                const formBtns = document.querySelector("#" + formId + " ~ .form-btns");
                if (formBtns !== null) formBtns.classList.add("hide");
            }

            setTimeout(() => {
                component[func](funcParams);
            }, 300);
        }
    }

    updateFormLoaderMsg(formId:string, message:string):void {
        const form = document.querySelector("#" + formId + " .form-loader .form-loader-container .loader-msg");
        if (form !== null) {
            form.innerHTML = message;
        }
    }

    hideFormLoader(formId:string):void {
        const formLoader = document.querySelector("#" + formId + " > .form-loader");
        if (formLoader !== null) {
            formLoader.innerHTML = "";

            const stepperBtns = document.querySelector("#stepper-dtl .btn-wrap");
            if (stepperBtns !== null) stepperBtns.classList.remove("hide");

            const formField = document.querySelector("#" + formId + " > .form-fields");
            if (formField !== null) formField.classList.remove("hide");

            const formBtns = document.querySelector("#" + formId + " ~ .form-btns");
            if (formBtns !== null) formBtns.classList.remove("hide");
        }
    }

    async getFileAsBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64String = e.target?.result as string;
                resolve(base64String);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }
}