import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
	selector: 'sub-forgot',
  	templateUrl: './sub-forgot.html'
})
export class SubForgotComponent {

    @Input() parentChildCommon:any;

    form:FormGroup = this.formBuilder.group ({
        email: ["", [Validators.required, Validators.email]],
    	accountType: ["P", [Validators.required]],
        userId: ["", [Validators.required]]
    });

    varAccountTypes:any = [];

  	constructor (
        public router: Router,
        public formService: FormService,
    	private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService
  	) {
        for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.USER_TYPE")) {
            this.varAccountTypes.push({
                value: refCd.rvLowValue,
                text: refCd.rvMeaning
            });
        }
        this.checkAccountType();
    }

    checkAccountType():void {
		this.form.controls["email"].reset();
		this.form.controls["userId"].reset();

    	if (this.form.value.accountType === "P") {
			this.form.controls["email"].enable();
			this.form.controls["userId"].disable();
			this.form.controls["email"].setValue("");
    	} else { 
			this.form.controls["email"].disable();
			this.form.controls["userId"].enable();
			this.form.controls["userId"].setValue("");
		}

		this.form.controls["email"].updateValueAndValidity();
        this.form.controls["userId"].updateValueAndValidity();
  	}

    // MOVE THIS FUNCTION TO login.ts
    forgotPassword(): void {
        this.formService.hideFormMsg("forgotFormMsg");
        const emailValidator = this.validateEmail();
        if(this.form.valid) {
            if(emailValidator.status === "invalid") {
                this.formService.showFormMsg("forgotFormMsg", emailValidator.errorMessage, "E");
            } else {
                const loginFormBtns = document.querySelectorAll("#sub-forgot .hwl");
                loginFormBtns.forEach((field) => {
                    field.classList.add("hide");
                });
                this.sendRecoveryEmail();
                // this.formService.showFormLoader(this, "sub-forgot-form", "Sending email.<br>Please wait ...", "sendRecoveryEmail", "");
            }
        } else {
            this.formService.showFormMsg("forgotFormMsg", "Please fill up all the required fields", "E")
        }
    }

    // MOVE THIS FUNCTION TO login.ts
    sendRecoveryEmail():void {
        this.parentChildCommon.sendRecoveryEmail(this.form.value);
    }

	goToForm(formName: string) {
		this.parentChildCommon.goToForm(formName);
	}

    //VALIDATE EMAIL ADDRESS
    validateEmail(): any {
        const emailFormatChecker = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errorMessage = "";
        if(!emailFormatChecker.test(this.form.get("email")?.value) && this.form.get('accountType')?.value == 'P') {
            errorMessage = "Email address is invalid. ";
        }

        return {
            status: errorMessage ? "invalid" : "valid",
            errorMessage: errorMessage
        };
    }
}