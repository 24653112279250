<section id="pol-summary" class="product-details">
    <div class="container">
        <div class="quote-policy-details montserrat left-aligned">
            <div class="detail">
                <div class="label">Policy No.</div>
                <div class="value">{{productInfo.policyNo || '-'}}</div>
            </div>
            <div class="detail">
                <div class="label">Contract Type</div>
                <div class="value">{{productInfo.contractTypeDesc || '-'}}</div>
            </div>
            <div class="detail">
                <div class="label">{{(productService.productInfo.fromLink=='payment' && productService.productInfo.lineCd!='PA') ? 'Insurance Plan' : 'Coverage'}}</div>
                <div class="value">{{productInfo.planInfo.planName || '-'}}</div>
            </div>
            <div class="detail">
                <div class="label">Policy Start Date</div>
                <div class="value">{{productInfo.coverageInfo.effDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
            </div>
            <div class="detail">
                <div class="label">Policy End Date</div>
                <div class="value">{{productInfo.coverageInfo.expDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
            </div>
            <div class="detail">
                <div class="label">Status</div>
                <div class="value" [class.green]="productInfo.polStatCd==5">{{productInfo.polStatDesc}}</div>
            </div>
        </div>
        <div class="form-note">
            <p>{{contactUsText}} <a (click)="contactBPI()">Contact BPI MS</a></p>
        </div>
        <form [formGroup]="form">
            <div class="quote-policy-summary">
                <div class="summary left-aligned">
                    <h2>Policy Holder Information</h2>
                    <div class="box-1">
                        <div class="box-holder style-2">
                            <div class="detail">
                                <div class="label">Complete Name to appear in the Policy</div>
                                <div class="value">{{ productInfo.policyHolderDetails.policy.displayName || "-" }}</div>
                            </div>
                            <div class="detail">
                                <div class="label required">Complete Mailing Address</div>
                                <div class="value">{{ productInfo.policyHolderDetails.policy.policyAddress ? productInfo.policyHolderDetails.policy.policyAddress : productInfo.customerInfo.permanentAddress || "-" }}</div>
                            </div>
                        </div>
                        <div class="see-more">
                            <ng-container *ngIf="seeMorePolicyHolder">
                                <div class="box-holder" [class.style-2]="productInfo.customerInfo.clientTypeDetail.value === 'B'" [class.style-4]="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                    <ng-container *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="detail">
                                            <div class="label">Last Name</div>
                                            <div class="value">{{ productInfo.customerInfo.lastName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">First Name</div>
                                            <div class="value">{{ productInfo.customerInfo.firstName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">Middle Name</div>
                                            <div class="value">{{ productInfo.customerInfo.middleName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">Suffix</div>
                                            <div class="value">{{ productInfo.customerInfo.suffix || "-" }}</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="detail">
                                            <div class="label">Company Name</div>
                                            <div class="value">{{ productInfo.customerInfo.companyName || "-" }}</div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Email Address</div>
                                        <div class="value email">{{ productInfo.customerInfo.emailAddress || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Mobile No.</div>
                                        <div class="value">{{ productInfo.customerInfo.mobileNo || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Telephone No.</div>
                                        <div class="value">{{ productInfo.customerInfo.telNo || "-" }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Birth Date</div>
                                        <div class="value">{{ productInfo.customerInfo.birthDate ? (productInfo.customerInfo.birthDate | date: "MM/dd/yyyy") : "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="label">Date of Registration</div>
                                        <div class="value">{{ productInfo.customerInfo.birthDate ? (productInfo.customerInfo.birthDate | date: "MM/dd/yyyy") : "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Gender</div>
                                        <div class="value">{{ productInfo.customerInfo.genderDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="label">Registration No.</div>
                                        <div class="value">{{ productInfo.customerInfo.registrationNo || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Marital Status</div>
                                        <div class="value">{{ productInfo.customerInfo.maritalDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Citizenship / Nationality</div>
                                        <div class="value">{{ productInfo.customerInfo.nationalityDetail?.text || "-" }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Country of {{ productInfo.customerInfo.clientTypeDetail.value === "R" ? "Birth" : "Origin" }}</div>
                                        <div class="value">{{ productInfo.customerInfo.countryOfBirthOriginDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Province or City</div>
                                        <div class="value">{{ productInfo.customerInfo.permanentCityDetail?.text ? productInfo.customerInfo.permanentCityDetail?.text : (productInfo.customerInfo.cityDesc || "-") }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Postal Code</div>
                                        <div class="value">{{ productInfo.customerInfo.permanentPostalDetail?.text ? productInfo.customerInfo.permanentPostalDetail?.text : (productInfo.customerInfo.postalDesc || "-") }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Tax Identification No.</div>
                                        <div class="value">{{ productInfo.customerInfo.tinNo || "-" }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4" *ngIf="userDetails.accountType !== 'P'">
                                    <div class="detail" *ngIf="userDetails.accountType !== 'P'">
                                        <div class="label">Category</div>
                                        <div class="value">{{ productInfo.customerInfo.jobCategoryDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="userDetails.accountType === 'E'">
                                        <div class="label">Risk Profiling</div>
                                        <div class="value" [ngClass]="{'red': productInfo.customerInfo.riskProfilingDetail?.value === 1}">
                                            {{ productInfo.customerInfo.riskProfilingDetail?.text || "-" }}
                                        </div>
                                    </div>
                                </div>
                                <div id="ids" class="box-holder style-4" *ngIf="productInfo.customerIds && productInfo.customerIds?.length !== 0">
                                    <div class="detail" *ngFor="let id of productInfo.customerIds">
                                        <div class="label">Type of ID</div>
                                        <div class="value">{{ id.idTypeDetail.text }}</div>
                                        <div class="label">ID No.</div>
                                        <div class="value">{{ id.idNo }}</div>
                                        <div class="label">
                                            <a class="download-btn"  (click)="viewFile(id,'id')">Download</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-holder style-1" *ngIf="(holderSuppDocs && holderSuppDocs.length > 0)">
                                    <div class="detail">
                                        <div class="label">Supporting Documents</div>
                                        <div class="value" *ngFor="let doc of holderSuppDocs">
                                            {{doc.filename}} <a class="view-btn" (click)="viewFile(doc,'holder')">View</a>
                                        </div>
                                        <!-- <div class="value" *ngFor="let id of productInfo.customerIds">
                                            {{ id.filename }}  <a class="view-btn"  (click)="viewFile(id,'id')">View</a>
                                        </div> -->
                                    </div>
                                </div>
                            </ng-container>
                            <div class="button grid">
                                <button *ngIf="saveButton && productInfo.creationProcess === 'renewal' && productInfo.fromLink !== 'payment'" class="btn btn2 left update-button" (click)="gotoKYC()">
                                    View & Update Personal Info
                                </button>
                                <button class="btn btn2 blue" (click)="seeMorePolicyHolder = !seeMorePolicyHolder">
                                    See {{ seeMorePolicyHolder ? "Less" : "More" }} Policy Holder Information
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="form-note italic" *ngIf="productInfo.creationProcess=='renewal' && kycComplianceText && productInfo.fromLink != 'payment' && productInfo.polStatCd!=5">
                    <p class="center justify">{{kycComplianceText}}</p>
                </div>
                <!-- ? only exist if access via email payment link -->
                <div class="form-note" *ngIf="!this.userDetailsService.userId && productInfo.fromLink === 'payment' && updateClientInfoText">
                    <p>{{updateClientInfoText}}</p>
                </div>
                <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'MC'">
                    <h2>Vehicle Information</h2>
                    <div class="box-1">
                        <div class="box-holder custom-style-1" *ngIf="productInfo.vehicleInfo.mortgageType === 'Y'">
                            <div class="detail">
                                <div class="label">Mortgage Details</div>
                                <div class="value">{{productInfo.vehicleInfo.bankDetail?.text || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Loan Account No.</div>
                                <div class="value">{{productInfo.vehicleInfo.loanAccountNo || '-'}}</div>
                            </div>
                            <div class="detail" *ngIf="productInfo.vehicleInfo.bankDetail?.alfesTag === 'Y'">
                                <div class="label">ALFES No.</div>
                                <div class="value">{{productInfo.vehicleInfo.alfesNo || '-'}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Vehicle Type</div>
                                <div class="value">{{productInfo.vehicleInfo.vehicleType === '1' ? 'Brand New' : 'Used Vehicle'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Year Model</div>
                                <div class="value">{{productInfo.vehicleInfo.yearModelDetail.text}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Brand & Model</div>
                                <div class="value">{{productInfo.vehicleInfo.makeDetail.text + ' ' + productInfo.vehicleInfo.modelDetail.text}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Variant</div>
                                <div class="value">{{productInfo.vehicleInfo.subModelDetail.text}}</div>
                            </div>
                        </div>
                        <div class="see-more">
                            <ng-container *ngIf="seeMoreVehicleDetails">
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Plate No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.plateNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Engine No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.engineNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Chassis No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.chassisNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">No. of Seats</div>
                                        <div class="value">{{productInfo.vehicleInfo.noOfSeats || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Make Type Code</div>
                                        <div class="value">{{productInfo.vehicleInfo.makeTypeCd || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">CC/WT</div>
                                        <div class="value">{{productInfo.vehicleInfo.ccWt || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Fuel Type</div>
                                        <div class="value">{{productInfo.vehicleInfo.gasDesc || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Seats Covered</div>
                                        <div class="value">{{productInfo.vehicleInfo.seatsCovered || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Exterior Color</div>
                                        <div class="value">{{productInfo.vehicleInfo.color || '-'}}</div>
                                    </div>
                                    <div class="detail" *ngIf="userDetails.accountType !== 'P'">
                                        <div class="label">Remarks</div>
                                        <div class="value">{{productInfo.vehicleInfo.remarks || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder custom-style-2" *ngIf="productInfo.sublineCd === 'CTPL'">
                                    <div class="detail">
                                        <div class="label">MV File No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.mvFileNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">MV Type</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.mvTypeDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">MV Prem Type</div>
                                        <div class="value">{{productInfo.vehicleInfo.mvPremTypeDetail?.text || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder custom-style-4" *ngIf="productInfo.sublineCd === 'CTPL'">
                                    <div class="detail">
                                        <div class="label">COC No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.cocNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">COC Authentication No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.cocAuthNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">COC Authentication Date</div>
                                        <div class="value">{{productInfo.vehicleInfo.cocAuthDate ? (productInfo.vehicleInfo.cocAuthDate | date : 'MM/dd/YYYY') : '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">IC Authentication No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.icAuthNo || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-1" *ngIf="detailsSuppDocs && detailsSuppDocs.length > 0">
                                    <div class="detail">
                                        <div class="label">Supporting Documents</div>
                                        <div class="value" *ngFor="let doc of detailsSuppDocs">
                                            {{doc.filename}} <a class="view-btn" (click)="viewFile(doc,'detail')">View</a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="button">
                                <button class="btn btn2 blue" *ngIf="!seeMoreVehicleDetails" (click)="seeMoreVehicleDetails = true">See More Vehicle Information</button>
                                <button class="btn btn2 blue" *ngIf="seeMoreVehicleDetails" (click)="seeMoreVehicleDetails = false">See Less Vehicle Information</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'HA' && productInfo.fromLink!='renewal'">
                    <h2>Property Details</h2>
                    <div class="box-1">
                        <!-- ROW 1 -->
                        <div class="box-holder style-1">
                            <div class="detail">
                                <div class="label">Address</div>
                                <div class="value">{{productInfo.propertyInfo.compAdd}}</div>
                            </div>
                        </div>

                        <!-- ROW 2 -->
                        <div class="box-holder style-3">
                            <div class="detail">
                                <div class="label">Country</div>
                                <div class="value">{{productInfo.propertyInfo.country.text}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Province or City</div>
                                <div class="value">{{productInfo.propertyInfo.provOrCity.text}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Postal Code</div>
                                <div class="value">{{productInfo.propertyInfo.postCode.text ? productInfo.propertyInfo.postCode.text.slice(0,4) : ''}}</div>
                            </div>
                        </div>

                        <!-- ROW 3 -->
                        <div class="box-holder style-3">
                            <div class="detail">
                                <div class="label">Latitude</div>
                                <div class="value">{{productInfo.propertyInfo.latitude}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Longitude</div>
                                <div class="value">{{productInfo.propertyInfo.longitude}}</div>
                            </div>
                        </div>

                        <!-- ROW 4 -->
                        <div class="box-holder style-1 insurance-amount">
                            <div class="detail">
                                <div class="label">Amount of Insurance Applied for</div>
                            </div>
                        </div>
                        <div class="box-holder style-2 contents" *ngFor="let row of longerRow;index as idx" [class.insurance-amount]="idx+1 != longerRow.length">
                            <div class="detail">
                                <div class="value"  *ngIf="insuredCol1[idx]">
                                    <mat-checkbox 
                                        class="checkbox"
                                        [checked]="insuredCol1[idx].amt"
                                        [disableRipple]="true"
                                        (click)="$event.preventDefault()">
                                        {{insuredCol1[idx].label}}&nbsp;
                                    </mat-checkbox>
                                    <div class="currency-amount-container">
                                        <span class="currency">
                                            {{insuredCol1[idx].curr}}
                                        </span>
                                        <span class="amount">
                                            {{insuredCol1[idx].amt | number:'1.2-2':'en-US'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="detail">
                                <div class="value" *ngIf="insuredCol2[idx]">
                                    <mat-checkbox 
                                        class="checkbox"
                                        [checked]="insuredCol2[idx].amt"
                                        [disableRipple]="true"
                                        (click)="$event.preventDefault()">
                                        {{insuredCol2[idx].label}}&nbsp;
                                    </mat-checkbox>
                                    <div class="currency-amount-container">
                                        <span class="currency">
                                            {{insuredCol2[idx].curr}}
                                        </span>
                                        <span class="amount">
                                            {{insuredCol2[idx].amt | number:'1.2-2':'en-US'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-holder style-2">
                            <div class="detail">
                                <div class="value">
                                    <span>Total Sum Insured&nbsp;&nbsp;&nbsp;</span>
                                    <div class="currency-amount-container">
                                        <span class="currency">
                                            {{ displayedCurrency }}
                                        </span>
                                        <span class="amount">
                                            {{total | number:'1.2-2':'en-US'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary" *ngIf="(productInfo.lineCd == 'HA' || productInfo.lineCd == 'FI') && productInfo.creationProcess=='renewal' && productInfo.fromLink === 'renewal'">
                    <h2>Property Information</h2>
                    <div class="box-1">
                        <div class="box-holder style-2">
                            <div class="detail">
                                <div class="label">Risk No.</div>
                                <div class="value">{{productInfo.firePropertyInfo.riskNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Location of Risk</div>
                                <div class="value">{{productInfo.firePropertyInfo.riskName || '-'}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-2" [class.style-4]="userDetails.accountType == 'B'">
                            <div class="detail">
                                <div class="label">City/Municipality</div>
                                <div class="value">{{productInfo.firePropertyInfo.cityDesc || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">District</div>
                                <div class="value">{{productInfo.firePropertyInfo.districtDesc || '-'}}</div>
                            </div>
                            <ng-container *ngIf="userDetails.accountType == 'B'">
                                <div class="detail">
                                    <div class="label">Block</div>
                                    <div class="value">{{productInfo.firePropertyInfo.blockDesc || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Tariff Interpretation Risk Code</div>
                                    <div class="value">{{productInfo.firePropertyInfo.tariffCd || '-'}}</div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="see-more">
                            <ng-container *ngIf="seeMoreFirePropertyInfo">
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label bold">Construction</div>
                                        <div class="value expand" *ngIf="!seeMoreConstruction">{{(productInfo.firePropertyInfo.constructionDesc | slice:0:80) || '-'}}</div>
                                        <div class="value pre" *ngIf="seeMoreConstruction">{{productInfo.firePropertyInfo.constructionDesc || '-'}}</div>
                                    </div>
                                </div>
                                <ng-container *ngIf="productInfo.firePropertyInfo.constructionDesc">
                                    <div class="button" *ngIf="productInfo.firePropertyInfo.constructionDesc.length > 100">
                                        <button class="btn btn2 right blue" (click)="seeMoreConstruction = !seeMoreConstruction">
                                            See {{ seeMoreConstruction ? "Less" : "More" }}
                                        </button>
                                    </div>
                                </ng-container>
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label bold">Occupancy</div>
                                        <div class="value expand" *ngIf="!seeMoreOccupancy">{{(productInfo.firePropertyInfo.occupancyDesc | slice:0:80) || '-'}}</div>
                                        <div class="value pre" *ngIf="seeMoreOccupancy">{{productInfo.firePropertyInfo.occupancyDesc || '-'}}</div>
                                    </div>
                                </div>
                                <ng-container *ngIf="productInfo.firePropertyInfo.occupancyDesc">
                                    <div class="button" *ngIf="productInfo.firePropertyInfo.occupancyDesc.length > 100">
                                        <button class="btn btn2 right blue" (click)="seeMoreOccupancy = !seeMoreOccupancy">
                                            See {{ seeMoreOccupancy ? "Less" : "More" }}
                                        </button>
                                    </div>
                                </ng-container>
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label bold">Other Risk Detail</div>
                                        <div class="value expand" *ngIf="!seeMoreOtherRiskDetail">{{(productInfo.firePropertyInfo.otherRiskDetail | slice:0:80) || '-'}}</div>
                                        <div class="value pre" *ngIf="seeMoreOtherRiskDetail">{{productInfo.firePropertyInfo.otherRiskDetail || '-'}}</div>
                                    </div>
                                </div>
                                <ng-container *ngIf="productInfo.firePropertyInfo.otherRiskDetail">
                                    <div class="button" *ngIf="productInfo.firePropertyInfo.otherRiskDetail.length > 100">
                                        <button class="btn btn2 right blue" (click)="seeMoreOtherRiskDetail = !seeMoreOtherRiskDetail">
                                            See {{ seeMoreOtherRiskDetail ? "Less" : "More" }}
                                        </button>
                                    </div>
                                </ng-container>
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label bold">Interested Party</div>
                                        <div class="value expand" *ngIf="!seeMoreInterestedParty">{{(productInfo.firePropertyInfo.bankName | slice:0:80) || '-'}}</div>
                                        <div class="value pre" *ngIf="seeMoreInterestedParty">{{productInfo.firePropertyInfo.bankName || '-'}}</div>
                                    </div>
                                </div>
                                <ng-container *ngIf="productInfo.firePropertyInfo.bankName">
                                    <div class="button" *ngIf="productInfo.firePropertyInfo.bankName.length > 100">
                                        <button class="btn btn2 right blue" (click)="seeMoreInterestedParty = !seeMoreInterestedParty">
                                            See {{ seeMoreInterestedParty ? "Less" : "More" }}
                                        </button>
                                    </div>
                                </ng-container>
                                <div class="box-holder style-1">
                                    <div class="detail">
                                        <div class="label bold">Risk Endorsements/Warranties/Clauses</div>
                                        <div class="value expand left-margin" *ngIf="!seeMoreFireRiskEndo">{{(productInfo.firePropertyInfo.riskEndo | slice:0:80) || '-'}}</div>
                                        <ng-container *ngIf="!seeMoreFireRiskEndo&&productInfo.firePropertyInfo.riskEndo">
                                            <p class="italic wac-mobile">
                                                {{wacText}}
                                                <a href="{{wacLink}}" target="_blank">Warranties and Clauses</a>
                                            </p>
                                        </ng-container>
                                        <div class="value pre left-margin" *ngIf="seeMoreFireRiskEndo">{{productInfo.firePropertyInfo.riskEndo || '-'}}
                                            <br>
                                            <br>
                                            <p class="italic">
                                                {{wacText}}
                                                <a href="{{wacLink}}" target="_blank">Warranties and Clauses</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-holder style-1" *ngIf="detailsSuppDocs && detailsSuppDocs.length > 0">
                                    <div class="detail">
                                        <div class="label">Supporting Documents</div>
                                        <div class="value" *ngFor="let doc of detailsSuppDocs">
                                            {{doc.filename}} <a class="view-btn" (click)="viewFile(doc,'detail')">View</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="button margin-bottom" *ngIf="productInfo.firePropertyInfo.riskEndo.length > 80">
                                    <button class="btn btn2 right blue" (click)="seeMoreFireRiskEndo = !seeMoreFireRiskEndo">
                                        See {{ seeMoreFireRiskEndo ? "Less" : "More" }}
                                    </button>
                                </div>
                            </ng-container>
                            <div class="button">
                                <button class="btn btn2 blue" *ngIf="!seeMoreFirePropertyInfo" (click)="seeMoreFirePropertyInfo = true">See More Property Information</button>
                                <button class="btn btn2 blue" *ngIf="seeMoreFirePropertyInfo" (click)="seeMoreFirePropertyInfo = false">See Less Property Information</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary coverage" *ngIf="productInfo.lineCd === 'PA' || productInfo.lineCd === 'CI'">
                    <h2>Information of Covered Person/s</h2>
                    <div class="box-1">
                        <div class="box-holder style-2">
                            <div class="detail">
                                <div class="label">Age</div>
                                <div class="value">{{ (productInfo?.paPropertyInfo?.age || productInfo.personalInfo?.age) || "-" }}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Occupation</div>
                                <div class="value">{{ (productInfo?.paPropertyInfo?.occupationDesc || productInfo?.personalInfo?.principalOccupation?.text) || "-" }}</div>
                            </div>
                        </div>
                        <div class="see-more" *ngIf="productInfo.creationProcess === 'renewal' && productInfo.fromLink !== 'payment'">
                            <ng-container *ngIf="seeMorePAPropertyInfo">
                                <ng-container *ngIf="productInfo.creationProcess === 'renewal' && (productInfo.dependents.length > 0 && productInfo.fromLink!='payment') && (productInfo.dependents && (productInfo.lineCd == 'PA' || productInfo.lineCd == 'CI'))">
                                    <h3 class="bold-text">Covered Members</h3>
                                    <div class="custom-table dependent">
                                        <ul>
                                            <li class="header">
                                                <p>Name</p>
                                                <p>Relationship</p>
                                                <p>Nationality</p>
                                                <p>Date of Birth</p>
                                                <p>Age</p>
                                            </li>
                                            <li *ngFor="let dependent of productInfo.dependents; last as l" class=" bold">
                                                <div>
                                                    <p>{{(dependent.dependentName | titlecase) || '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{(dependent.relationshipDesc | titlecase) || '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{(dependent.nationalityDesc | titlecase )|| '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{dependent.birthDate ? (dependent.birthDate | date : 'MM/dd/YYYY') : '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{dependent.age || '-'}}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-holder"></div>
                                </ng-container>
                                <ng-container *ngIf="productInfo.creationProcess=='renewal' && productInfo.fromLink!='payment'">
                                    <h3 class="bold-text">Beneficiaries</h3>
                                    <div class="custom-table beneficiary">
                                        <ul>
                                            <li class="header">
                                                <p class="pa">Name</p>
                                                <p class="pa">Relationship</p>
                                                <p class="pa">Nationality</p>
                                                <p class="pa">Date of Birth</p>
                                                <p class="pa">Age</p>
                                            </li>
                                            <li *ngFor="let beneficiary of productInfo.beneficiaries; last as l" class="bold try">
                                                <div>
                                                    <p>{{(beneficiary.beneficiaryName | titlecase) || '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{(beneficiary.relationshipDesc | titlecase) || '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{(beneficiary.nationalityDesc | titlecase) || '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{beneficiary.birthDate ? (beneficiary.birthDate | date : 'MM/dd/YYYY') : '-'}}</p>
                                                </div>
                                                <div class="pa">
                                                    <p>{{beneficiary.age || '-'}}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="box-holder"></div>
                                    <div class="box-holder style-1">
                                        <div class="detail">
                                            <div class="label bold">Other Risk Detail</div>
                                            <div class="value expand left-margin" *ngIf="!seeMorePAOtherRiskDetail">{{ (productInfo.paPropertyInfo.otherRiskDetail | slice:0:80) || "-" }}</div>
                                            <div class="value pre left-margin" *ngIf="seeMorePAOtherRiskDetail">{{ productInfo.paPropertyInfo.otherRiskDetail || "-" }}</div>
                                        </div>
                                    </div>
                                    <div class="button" *ngIf="productInfo.paPropertyInfo.otherRiskDetail.length > 80">
                                        <button class="btn btn2 right blue" (click)="seeMorePAOtherRiskDetail = !seeMorePAOtherRiskDetail">
                                            See {{ seeMorePAOtherRiskDetail ? "Less" : "More" }}
                                        </button>
                                    </div>
                                    <div class="box-holder style-1">
                                        <div class="detail">
                                            <div class="label bold">Interested Party</div>
                                            <div class="value expand left-margin" *ngIf="!seeMorePAInterestedParty">{{ (productInfo.paPropertyInfo.interestedParty | slice:0:80) || "-" }}</div>
                                            <div class="value pre left-margin" *ngIf="seeMorePAInterestedParty">{{ productInfo.paPropertyInfo.interestedParty || "-" }}</div>
                                        </div>
                                    </div>
                                    <div class="button" *ngIf="productInfo.paPropertyInfo.interestedParty.length > 80">
                                        <button class="btn btn2 right blue" (click)="seeMorePAInterestedParty = !seeMorePAInterestedParty">
                                            See {{ seeMorePAInterestedParty ? "Less" : "More" }}
                                        </button>
                                    </div>
                                    <div class="box-holder style-1">
                                        <div class="detail">
                                            <div class="label bold">Risk Endorsements/Warranties/Clauses</div>
                                            <div class="value expand left-margin" *ngIf="!seeMorePARiskEndo">{{ (productInfo.paPropertyInfo.riskEndo | slice:0:80) || "-" }}</div>
                                            <ng-container *ngIf="!seeMorePARiskEndo&&productInfo.paPropertyInfo.riskEndo">
                                                <p class="italic wac-mobile">
                                                    {{wacText}}
                                                    <a href="{{wacLink}}" target="_blank">Warranties and Clauses</a>
                                                </p>
                                            </ng-container>
                                            <div class="value pre left-margin" *ngIf="seeMorePARiskEndo">{{ productInfo.paPropertyInfo.riskEndo || "-" }}
                                                <br>
                                                <br>
                                                <p class="italic">
                                                    {{wacText}}
                                                    <a href="{{wacLink}}" target="_blank">Warranties and Clauses</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button margin-bottom" *ngIf="productInfo.paPropertyInfo.riskEndo.length > 80">
                                        <button class="btn btn2 right blue" (click)="seeMorePARiskEndo = !seeMorePARiskEndo">
                                            See {{ seeMorePARiskEndo ? "Less" : "More" }}
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="productInfo.creationProcess === 'renewal' && productInfo.fromLink !== 'payment' && (productInfo.lineCd === 'PA' || productInfo.lineCd === 'CI')">
                                <div class="button">
                                    <button class="btn btn2 blue" (click)="seeMorePAPropertyInfo = !seeMorePAPropertyInfo">
                                        See {{ seeMorePAPropertyInfo ? "Less" : "More" }} Information of Covered Person/s
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="summary coverage" *ngIf="productInfo.lineCd!=='PA'&&productInfo.lineCd!=='CI'">
                    <h2>Coverage</h2>
                    <div class="box-1">
                        <div class="custom-table">
                            <ng-container *ngIf="productInfo.menuLineCd == 'FI' && productInfo.fromLink !== 'payment' else notFI">
                                <ng-container *ngFor="let item of coverageItems">
                                    <div class="bold-text margin-top">
                                        {{'Item No. '+item.itemNo+ ': ' + item.itemTitle}}
                                    </div>
                                    <ul>
                                        <li class="header">
                                            <p (click)="setCoverages('coverage', 'perilName')" [class.sort]="lastSortedColumn === 'coverage'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Coverage
                                            </p>
                                            <p (click)="setCoverages('amt-covered', 'tsiAmt')" [class.sort]="lastSortedColumn === 'amt-covered'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                                Amount Covered
                                            </p>
                                            <p (click)="setCoverages('premium', 'premAmt')" [class.sort]="lastSortedColumn === 'premium'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                                Premium
                                            </p>
                                        </li>
                                        <ng-container *ngFor="let coverage of coverages; last as l">
                                            <li [class.body]="!l" [class.footer]="l" [class.white]="coverage.perilName=='Item Premium'">
                                                <ng-container *ngIf="coverage.itemNo == item.itemNo">
                                                    <div [class.bold-text]="coverage.perilName=='Item Premium'">
                                                        <p>{{coverage.perilName}}</p>
                                                        <ng-container *ngIf="!l">
                                                            <div>
                                                                <p [class.item-premium-mobile]="coverage.perilName=='Item Premium'">Coverage</p>
                                                                <p [class.item-premium-mobile-size]="coverage.perilName=='Item Premium'">{{coverage.perilName}}</p>
                                                            </div>
                                                            <div>
                                                                <p [class.item-premium-mobile]="coverage.perilName=='Item Premium'">Amount Covered</p>
                                                                <p [class.item-premium-mobile]="coverage.perilName=='Item Premium'">{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                            </div>
                                                            <div>
                                                                <p [class.item-premium-mobile]="coverage.perilName=='Item Premium'">Premium</p>
                                                                <p [class.item-premium-mobile-size]="coverage.perilName=='Item Premium'">
                                                                    <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                                        FREE
                                                                    </ng-container>
                                                                    <ng-template #paidPrem>
                                                                        {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                                    </ng-template>
                                                                </p>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div [class.bold-text]="coverage.perilName=='Item Premium'">
                                                        <ng-container *ngIf="!l">
                                                            <p class="ralign">{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                        </ng-container>

                                                        <!-- TOTAL PREMIUM BELOW AMOUNT COVERED -->
                                                        <ng-container *ngIf="l && item.itemNo == coverageItems.length">
                                                            <p class="ralign prem-total-1">
                                                                <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                                    FREE
                                                                </ng-container>
                                                                <ng-template #paidPrem>
                                                                    {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                                </ng-template>
                                                            </p>
                                                        </ng-container>
                                                    </div>
                                                    <div [class.bold-text]="coverage.perilName=='Item Premium'">
                                                        <p class="ralign">
                                                            <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                                FREE
                                                            </ng-container>
                                                            <ng-template #paidPrem>
                                                                {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                            </ng-template>
                                                        </p>
                                                    </div>
                                                </ng-container>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </ng-container>
                            <ng-template #notFI>
                                <ul>
                                    <li class="header">
                                        <p (click)="setCoverages('coverage', 'perilName')" [class.sort]="lastSortedColumn === 'coverage'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                            Coverage
                                        </p>
                                        <p (click)="setCoverages('amt-covered', 'tsiAmt')" [class.sort]="lastSortedColumn === 'amt-covered'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                            Amount Covered
                                        </p>
                                        <p (click)="setCoverages('premium', 'premAmt')" [class.sort]="lastSortedColumn === 'premium'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                            Premium
                                        </p>
                                    </li>
                                    <li *ngFor="let coverage of coverages; last as l" [class.body]="!l" [class.footer]="l">
                                        <ng-container *ngIf="coverage.perilName!='Item Premium'">
                                            <div>
                                                <p>{{coverage.perilName}}</p>
                                                <ng-container *ngIf="!l">
                                                    <div>
                                                        <p>Coverage</p>
                                                        <p>{{coverage.perilName}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Amount Covered</p>
                                                        <p>{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Premium</p>
                                                        <p>
                                                            <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                                FREE
                                                            </ng-container>
                                                            <ng-template #paidPrem>
                                                                {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                            </ng-template>
                                                        </p>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <ng-container *ngIf="!l">
                                                    <p class="ralign">{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </ng-container>

                                                <!-- TOTAL PREMIUM BELOW AMOUNT COVERED -->
                                                <ng-container *ngIf="l">
                                                    <p class="ralign prem-total-1">
                                                        <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                            FREE
                                                        </ng-container>
                                                        <ng-template #paidPrem>
                                                            {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                        </ng-template>
                                                    </p>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                        FREE
                                                    </ng-container>
                                                    <ng-template #paidPrem>
                                                        {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </ng-container>
                                    </li>
                                </ul>
                            </ng-template>
                        </div>
                    </div>
                    <span *ngIf="productInfo.coverageInfo?.minPremTag == 'Y'"><br>{{minPremMsg}}</span>
                </div>
                <div class="summary benCovFPA" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'FPA' && showBenCovDetails && (productInfo.creationProcess != 'renewal' || productInfo.fromLink=='payment')">
                    <h2>Benefits and Coverages</h2>
                    <div class="box-1">
                        <div class="custom-table">
                            <ul>
                                <li class="header">
                                    <p></p>
                                    <p class="ralign">
                                        Principal
                                    </p>
                                    <p class="ralign">
                                        Spouse/Parents
                                    </p>
                                    <p class="ralign">
                                        Children
                                    </p>
                                </li>
                                <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesFPA">
                                    <div class="sp-1">
                                        <p>{{benefitCoverage.perilName}}</p>
                                        <div>
                                            <p>{{benefitCoverage.perilName}}</p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <p>Principal</p>
                                            <p>{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </div>
                                        <div>
                                            <p>Spouse/Parents</p>
                                            <p>
                                                {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                            </p>
                                        </div>
                                        <div>
                                            <p>Children</p>
                                            <p>
                                                {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="ralign">{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                    </div>
                                    <div>
                                        <p class="ralign">
                                            {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                        </p>
                                    </div>
                                    <div>
                                        <p class="ralign">
                                            {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="summary benCovIND" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'IND' && showBenCovDetails && (productInfo.creationProcess != 'renewal' || productInfo.fromLink=='payment')">
                    <h2>Benefits and Coverages</h2>
                    <div class="box-1">
                        <div class="custom-table">
                            <ul>
                                <li class="header">
                                    <p>
                                        Principal
                                    </p>
                                </li>
                                <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesIND">
                                    <div class="sp-1">
                                        <p>{{benefitCoverage.perilName}}</p>
                                        <div>
                                            <p>{{benefitCoverage.perilName}}</p>
                                            <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="summary benCovCI" *ngIf="productInfo.lineCd === 'CI' && showBenCovDetails && (productInfo.creationProcess != 'renewal' || productInfo.fromLink=='payment')">
                    <h2>Benefits and Coverages</h2>
                    <div class="box-1">
                        <div class="custom-table">
                            <ul>
                                <li class="header">
                                    <p>
                                        Principal
                                    </p>
                                </li>
                                <li class="body">
                                    <div class="sp-1">
                                        <p>Daily Cash Assistance</p>
                                        <div>
                                            <p>Daily Cash Assistance</p>
                                            <p class="ralign">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </div>
                                        <div>
                                            <p>Maximum No. of Confinement Days</p>
                                            <p class="ralign">{{benefitsCoveragesCI.noOfDays}}</p>
                                        </div>
                                        <div>
                                            <p>Maximum Benefit</p>
                                            <p class="ralign">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Maximum No. of Confinement Days</p>
                                    </div>
                                    <div>
                                        <p>Maximum Benefit</p>
                                    </div>
                                </li>
                                <li class="body2">
                                    <div>
                                        <p class="value">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                    </div>
                                    <div>
                                        <p class="value">{{benefitsCoveragesCI.noOfDays}}</p>
                                    </div>
                                    <div>
                                        <p class="value">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="summary benCovIND" *ngIf="(productInfo.lineCd === 'PA' || productInfo.lineCd === 'CI') && showBenCovDetails && (productInfo.creationProcess=='renewal' && productInfo.fromLink!='payment')">
                    <h2>Benefits and Coverages</h2>
                    <div class="box-1">
                        <div class="custom-table">
                            <ul>
                                <li class="header">
                                    <p>
                                        Principal
                                    </p>
                                </li>
                                <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesREN">
                                    <div class="sp-1">
                                        <p>{{benefitCoverage.perilName}}</p>
                                        <div>
                                            <p>{{benefitCoverage.perilName}}</p>
                                            <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="summary other-info" *ngIf="productInfo.lineCd == 'MC'">
                    <h2>Other Information</h2>
                    <div class="box-1">
                        <div class="box-holder" [class.style-2]="productInfo.sublineCd === 'CTPL'" [class.style-3]="productInfo.sublineCd !== 'CTPL'">
                            <div class="detail">
                                <div class="label">Deductible Amount
                                    <div class="help-icon">
                                        <mat-icon matTooltip="{{dedAmtComment}}" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon>
                                    </div>
                                </div>
                                <div class="value">{{premiumCharges.deductibleAmt ? (premiumCharges.deductibleAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div>
                            <div class="detail" *ngIf="productInfo.sublineCd !== 'CTPL'">
                                <div class="label">Towing Limit
                                    <div class="help-icon">
                                        <mat-icon matTooltip="{{towingAmtComment}}" #tooltip2="matTooltip" (click)="tooltip2.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon>
                                    </div>
                                </div>
                                <div class="value">{{premiumCharges.towingAmt ? (premiumCharges.towingAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Authorized Repair Limit
                                    <div class="help-icon">
                                        <mat-icon matTooltip="{{repairAmtComment}}" #tooltip3="matTooltip" (click)="tooltip3.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon>
                                    </div>
                                </div>
                                <div class="value">{{premiumCharges.repairLimitAmt ? (premiumCharges.repairLimitAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary other-info" *ngIf="productInfo.lineCd === 'HA'">
                    <h2>Deductibles</h2>
                    <div class="box-1">
                        <!-- ROW 1 -->
                        <div class="box-holder style-1">
                            <div class="detail">
                                <!-- <div class="label"></div> -->
                                <div class="value deductible-text">
                                    <span *ngFor="let deductible of productInfo.deductibles">{{deductible.deductibleText}}
                                        <br>
                                        <br>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary vehicle-information left-aligned" *ngIf="quickLink.count>0">
                    <h2>Quick Links</h2>
                    <div class="box-1">
                        <div class="box-holder" [class.style-1]="quickLink.count == 1" [class.style-2]="quickLink.count == 2" [class.style-3]="quickLink.count == 3" [class.style-4]="quickLink.count == 4">
                            <div class="detail" *ngIf="quickLink.quickLinkPdfPath1&&quickLink.quickLinkPdfName1">
                                <div class="value"><a class="link" href="{{quickLink.quickLinkPdfPath1}}" target="_blank">{{quickLink.quickLinkPdfName1}}</a></div>
                            </div>
                            <div class="detail" *ngIf="quickLink.quickLinkPdfPath2&&quickLink.quickLinkPdfName2">
                                <div class="value"><a class="link" href="{{quickLink.quickLinkPdfPath2}}" target="_blank">{{quickLink.quickLinkPdfName2}}</a></div>
                            </div>
                            <div class="detail" *ngIf="quickLink.quickLinkPdfPath3&&quickLink.quickLinkPdfName3">
                                <div class="value"><a class="link" href="{{quickLink.quickLinkPdfPath3}}" target="_blank">{{quickLink.quickLinkPdfName3}}</a></div>
                            </div>
                            <div class="detail" *ngIf="quickLink.quickLinkPdfPath4&&quickLink.quickLinkPdfName4">
                                <div class="value"><a class="link" href="{{quickLink.quickLinkPdfPath4}}" target="_blank">{{quickLink.quickLinkPdfName4}}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary coverage" *ngIf="(this.userDetailsService.userId || (!this.userDetailsService.userId && productInfo.fromLink === 'payment')) && (productInfo.dependents?.length > 0 && (productInfo.lineCd === 'PA' || productInfo.lineCd === 'CI'))">
                    <h2>Dependents</h2>
                    <div class="box-1">
                        <ng-container *ngIf="productInfo.dependents!=null">
                            <div class="custom-table dependent">
                                <ul>
                                    <li class="header">
                                        <p>Name</p>
                                        <p>Relationship</p>
                                        <p>Nationality</p>
                                        <p>Date of Birth</p>
                                        <p>Age</p>
                                    </li>
                                    <li *ngFor="let dependent of productInfo.dependents; last as l" class=" bold">
                                        <div>
                                            <p>{{(dependent.dependentName | titlecase) || '-'}}</p>
                                        </div>
                                        <div class="pa">
                                            <p>{{(dependent.relationshipDesc | titlecase) || '-'}}</p>
                                        </div>
                                        <div class="pa">
                                            <p>{{(dependent.nationalityDesc | titlecase )|| '-'}}</p>
                                        </div>
                                        <div class="pa">
                                            <p>{{dependent.birthDate ? (dependent.birthDate | date : 'MM/dd/YYYY') : '-'}}</p>
                                        </div>
                                        <div class="pa">
                                            <p>{{dependent.age || '-'}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="summary premium-charges">
                    <h2>Premium Charges</h2>
                    <div class="box-2">
                        <div class="box-holder">
                            <div class="detail">
                                <div class="label">Premium</div>
                                <div class="value">{{premiumCharges.premiumAmt | currency: 'PHP': '₱ '}}</div>
                            </div>
                            <div class="details">
                                <div class="detail" *ngFor="let tax of premiumCharges.taxes">
                                    <div class="label">{{tax.name}}</div>
                                    <div class="value">{{tax.value | currency: 'PHP': '₱ '}}</div>
                                </div>
                            </div>
                            <div class="detail total-amt">
                                <div class="label">Amount Due</div>
                                <div class="value">{{premiumCharges.amountDue | currency: 'PHP': '₱ '}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="box-1" *ngIf="(userDetails.userId && userDetails.accountType !== 'P') && showComm">
                        <div class="box-holder style-2">
                            <div class="detail">
                                <div class="label">Commission Amount</div>
                                <div class="value">{{premiumCharges.commissionAmt | currency: 'PHP': '₱ '}}</div>
                            </div>
                            <div class="detail total-amt">
                                <div class="label">Net Due</div>
                                <div class="value">{{premiumCharges.netDue | currency: 'PHP': '₱ '}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary payment left-aligned">
                    <h2>Payment Details</h2>
                    <div class="box-1">
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Payment Reference No.</div>
                                <div class="value">{{productInfo.paymentInfo.paytRefNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Transaction No.</div>
                                <div class="value">{{productInfo.paymentInfo.tranNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Mode of Payment</div>
                                <div class="value">{{productInfo.paymentInfo.paymentMode || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Payment Date</div>
                                <div class="value">{{productInfo.paymentInfo.paytDate ? (productInfo.paymentInfo.paytDate | date : 'MM/dd/YYYY') : '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary policy-header left-aligned" *ngIf="(userDetails.accountType || 'P') !== 'P' && userDetails.accountType !== 'B'">
                    <h2>Policy Header Details</h2>
                    <ng-container *ngIf="userDetails.accountType !=='A' else showAgentBox">
                        <div class="box-1">
                            <div class="box-holder style-3">
                                <div class="detail">
                                    <div class="label">Agent</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.agentDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Source Code</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.sourceCodeDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Source Extension</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.sourceExtensionDetail?.text || '-'}}</div>
                                </div>
                            </div>
                            <div class="box-holder style-3">
                                <div class="detail">
                                    <div class="label">BMA</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.bmaDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Referrer</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.referrorCodeDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Project Code</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.projectCodeDetail?.text || '-'}}</div>
                                </div>
                            </div>
                            <div class="box-holder style-3">
                                <div class="detail">
                                    <div class="label">Client Type Group</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.clientTypeGroupDetail?.text || '-'}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #showAgentBox>
                        <div class="box-1" *ngIf="agentType != 'D' else showDealershipBox">
                            <div class="box-holder style-3">
                                <div class="detail">
                                    <div class="label">Agent</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.agentDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">BMA</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.bmaDetail?.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Client Type Group</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.clientTypeGroupDetail?.text || '-'}}</div>
                                </div>
                            </div>
                        </div>
                        <ng-template #showDealershipBox>
                            <div class="box-1">
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Agent</div>
                                        <div class="value">{{productInfo.policyHeaderDetails.agentDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">BMA</div>
                                        <div class="value">{{productInfo.policyHeaderDetails.bmaDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Client Type Group</div>
                                        <div class="value">{{productInfo.policyHeaderDetails.clientTypeGroupDetail?.text || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Source Code</div>
                                        <div class="value">{{productInfo.policyHeaderDetails.sourceCodeDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Source Extension</div>
                                        <div class="value">{{productInfo.policyHeaderDetails.sourceExtensionDetail?.text || '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </form>
        <!-- ? if link is from email -->
        <div class="form terms" *ngIf="showCheckBox">
            <div class="col-case">
                <mat-checkbox (ngModelChange)="updateIsAccepted($event)" [ngModel]="isAccepted" [disabled]="!payButton"></mat-checkbox>
                <!-- ? for payment link from email -->
                <div class='terms-text' *ngIf="productInfo.fromLink === 'payment'">I have read, understood and agree to the <a (click)="evaluateGetPdf()">Product's Insuring Terms & Conditions</a> and all its clauses. <span [innerHTML]="termsPaymentText"></span></div>
                <!-- ? for renewal link from email-->
                <div class='terms-text' *ngIf="productInfo.fromLink === 'renewal'"><span [innerHTML]="termsRenewalText"></span></div>
            </div>
        </div>

        <!-- Para sa "Cancellation of policy without payment yet for certain users." -->
        <ng-container *ngIf="productInfo.quoteStatCd === 9 && validCancellationText">
            <div class="form form-note-cancel">
                <p>{{policyCancellationText}} <a (click)="cancelPol.open('Return')">Click Here.</a></p>
            </div>
        </ng-container>
        <!-- Hanggang Dito yung changes ng "Cancellation of policy without payment yet for certain users." -->

        <div class="form-btns" [class.style-3]="this.printPolButton || this.payButton" [class.style-3]="this.sendNotice && this.saveButton" [class.style-4]="this.printPolButton && this.payButton">
            <button class="btn btn4" (click)="toDashboard()">Back</button>
            <button class="btn btn2" *ngIf="productService.productInfo.creationProcess !== 'renewal'" (click)="onClickPrint('quote')">Print Quotation</button>
            <button class="btn btn2" (click)="onClickPrint('policy')" *ngIf="this.printPolButton">Print Policy</button>
            <button class="btn btn2" (click)="sendMail()" *ngIf="this.sendNotice && productService.productInfo.creationProcess == 'renewal'">Send Notice</button>
            <button class="btn btn2" (click)="onClickPay()" *ngIf="this.payButton" [disabled]="!isAccepted">Pay</button>
        </div>
    </div>
</section>




<overlay id="vehicle-detail-overlay" #vehicleDetail class="vehicle-details-title" [title]="'Vehicle Details'">
    <div content class="vehicle-details-overlay">
        <div class="vd-first-text">
            <label>Please provide your vehicle details before proceeding with your payment.</label>
        </div>
        <form [formGroup]="vehicleForm">
            <div class="form">
                <div class="col-case">
                    <div class="field vd-input">
                        <label class="vehicle-details-text">Plate No./Conduction No.</label>
                        <label class="red">*</label>
                        <!-- <ng-container *ngIf="plateNoCheck else showPlateNo;"> -->
                            <mat-form-field appearance="fill">
                                <input class="vehicle-detail-input" matInput placeholder="Please type your Plate No./Conduction No." formControlName="plateNo" [maxlength]="plateNoMaxLength" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <label class="vd-error-text" *ngIf="vehicleForm.controls['plateNo']?.value?.length === 0&&vehicleForm.controls['plateNo']?.touched">
                                Plate No or Conduction No. is required in order to proceed.
                            </label>
                        <!-- </ng-container>
                        <ng-template #showPlateNo>
                            <p class="vehicle-form-text">{{this.productInfo?.vehicleInfo?.plateNo}}</p>
                        </ng-template> 
                        -->
                    </div>
                    <div class="field vd-input">
                        <label class="vehicle-details-text">Engine No.</label>
                        <label *ngIf="engineNoCheck" class="red">*</label>
                        <ng-container *ngIf="engineNoCheck else showEngineNo;">
                            <mat-form-field appearance="fill">
                                <input class="vehicle-detail-input" matInput placeholder="Please type your Engine No." formControlName="engineNo" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <label class="vd-error-text" *ngIf="vehicleForm.controls['engineNo']?.value?.length === 0&&vehicleForm.controls['engineNo']?.touched">
                                Engine No. is required in order to proceed.
                            </label>
                        </ng-container>
                        <ng-template #showEngineNo>
                            <p class="vehicle-form-text">{{this.productInfo?.vehicleInfo?.engineNo}}</p>
                        </ng-template>
                    </div>
                    <div class="field vd-input">
                        <label class="vehicle-details-text">Chassis No.</label>
                        <label *ngIf="chassisNoCheck" class="red">*</label>
                        <ng-container *ngIf="chassisNoCheck else showChassisNo;">
                            <mat-form-field appearance="fill">
                                <input class="vehicle-detail-input" matInput placeholder="Please type your Chassis No." formControlName="chassisNo" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <label class="vd-error-text" *ngIf="vehicleForm.controls['chassisNo']?.value?.length === 0&&vehicleForm.controls['chassisNo']?.touched">
                                Chassis No. is required in order to proceed.
                            </label>
                        </ng-container>
                        <ng-template #showChassisNo>
                            <p class="vehicle-form-text">{{this.productInfo?.vehicleInfo?.chassisNo}}</p>
                        </ng-template>
                    </div>
                    <div class="field vd-input">
                        <label class="vehicle-details-text">MV File No.</label>
                        <label class="red">*</label>
                        <!-- <ng-container *ngIf="mvFileNoCheck  else showMVFileNo;"> -->
                            <mat-form-field appearance="fill">
                                <input class="vehicle-detail-input" matInput placeholder="Please type your MV File No." formControlName="mvFileNo" [maxlength]="mvFileNoLength" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <label class="vd-error-text" *ngIf="vehicleForm.controls['mvFileNo']?.value?.length !== mvFileNoLength && vehicleForm.controls['mvFileNo']?.touched">
                                 MV File No must be at least {{mvFileNoLength||0}} characters in length in order to proceed.
                            </label>
                        <!-- </ng-container>
                        <ng-template #showMVFileNo>
                            <p class="vehicle-form-text">{{this.productInfo?.vehicleInfo?.mvFileNo}}</p>
                        </ng-template>
                        -->
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn btn2 update-button" [disabled]="vehicleForm.invalid" (click)="updateVehicleDetails(vehicleForm.controls['plateNo'].value, vehicleForm.controls['engineNo'].value,vehicleForm.controls['chassisNo'].value, vehicleForm.controls['mvFileNo'].value);vehicleDetail.close()">Proceed</button>
</overlay>

<overlay #cancelPol id="cancel-title" [title]="'Confirmation'">
    <div content id="cancel-overlay">
        <div id="cancel-text">Do you really want to cancel your policy?</div>
        <form [formGroup]="cancellationForm">
            <div class="form">
                <div class="col-case">
                    <div class="field">
                        <label id="cancel-reason-text">Reason for Cancellation</label><label id="cancel-red-mark">*</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput placeholder="Select Reason for cancellation" formControlName="reason" [matAutocomplete]="autoReason" (input)="setReasonViaInput($event)" autocomplete>
                            <mat-autocomplete #autoReason="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                <mat-option *ngFor="let option of filteredReasonOptions | async" [value]="option">
                                    {{option.text}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary" (click)="cancelPolicy(cancellationForm.controls['reason'].value.value);cancelPol.close()">Proceed</button>
</overlay>