import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JsonDataService } from '../services/json-data.service';

@Injectable({
    providedIn: 'root'
})
export class CheckJsonDataGuard implements CanDeactivate<unknown> {

    constructor(
        private jsonDataService: JsonDataService
    ) { }

    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.jsonDataService.loadChildParams(nextState?.url.substring(1) || '');
        return this.jsonDataService.checkLoadingParams(nextState?.url);
    }

}
