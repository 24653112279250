import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RenewalService {
    renewalForm:any = {
        status: "allRenewal",
        search: "",
        filterBy: "all",
        sortBy: "dateCreated",
        sortDir: "desc",
        pageNo: 1
    }
}