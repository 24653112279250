import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Validator, AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';

import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProductService } from 'src/app/services/product.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
    selector: 'sub-register',
    templateUrl: './sub-register.html'
})
export class SubRegisterComponent {

    @Input() parentChildCommon:any;

    showPersonalFields:boolean = true;
    showBusinessFields:boolean = false;
    showPasswordValue:boolean = false;
    labelDate:string = "Birth date";
    maxDate:Date = new Date();
    minDate:Date = new Date("01/01/2000");
    birthMax: Number = 10;
    acceptReg: string = "";
    acceptRegLine: string = "";
    acceptRegLink: string = "";
    accept: boolean = false;
    
    form:FormGroup = this.formBuilder.group({
        clientType: ["", [Validators.required]],
        lastName: [""],
        firstName: [""],
        middleName: [""],
        companyName: [""],
        date: ["", [Validators.required]],
        mobile: [""],
        email: ["", [Validators.required, Validators.email]],
        suffix: [""],
        acceptTag:[""]
    });

    varClientTypes:any = [];

    constructor (
        public formService: FormService,
        private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService,
        private parameterService: ParameterService,
        private productService: ProductService
    ) {
        for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM043.BR_TAG")) {
            this.varClientTypes.push({
                value: refCd.rvLowValue,
                text: refCd.rvMeaning
            });
        }

        this.form.controls["clientType"].setValue("R");
        this.checkClientType();
        this.acceptReg = this.parameterService.paramV('ACCEPT_PU_REGISTRATION').substr(0, this.parameterService.paramV('ACCEPT_PU_REGISTRATION').indexOf('B'));
        this.acceptRegLine = this.parameterService.paramV('ACCEPT_PU_REGISTRATION').substr(this.parameterService.paramV('ACCEPT_PU_REGISTRATION').indexOf('B'));
        this.acceptRegLink = this.parameterService.paramV('ACCEPT_PU_REGISTRATION_LINK');
        if (this.productService.productInfo.personalInfo) {
            this.populatePrincipalInfoToRegisterInfo();
        }
    }

    checkClientType():void {
        this.showPersonalFields = false;
        this.showBusinessFields = false;

        this.form.controls["lastName"].reset();
        this.form.controls["firstName"].reset();
        this.form.controls["middleName"].reset();
        this.form.controls["suffix"].reset();
        this.form.controls["companyName"].reset();
        this.form.controls["date"].reset();

        this.form.controls["mobile"].setValidators(this.form.controls["mobile"].validator ? [this.form.controls["mobile"].validator ,Validators.required] : [Validators.required] );

        this.maxDate = new Date();
        this.minDate = new Date("01/01/2000");
        if (this.form.value.clientType === "R") {
            this.showPersonalFields = true;
            this.labelDate = "Birth Date";
            this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
            this.minDate.setFullYear(new Date().getFullYear() - 100);

            this.form.controls["lastName"].setValidators([Validators.required]);
            this.form.controls["firstName"].setValidators([Validators.required]);
            this.form.controls["companyName"].clearValidators();
        } else {
            this.showBusinessFields = true;
            this.labelDate = "Date of Registration";

            this.form.controls["lastName"].clearValidators();
            this.form.controls["firstName"].clearValidators();
            this.form.controls["companyName"].setValidators([Validators.required]);
        }

        this.form.controls["lastName"].updateValueAndValidity();
        this.form.controls["firstName"].updateValueAndValidity();
        this.form.controls["companyName"].updateValueAndValidity();
    }

    birthDateValidator(control: AbstractControl): {[key: string]: boolean} | null {
        if (control.value !== undefined && (new Date(control.value).getTime() < 18)) {
            return { 'ageRange': true };
        }
        return null;
    }

    processRegister():void {
        this.formService.hideFormMsg("registration-form-msg");
        if (this.form.valid) {
            const form = this.form.value;

            if (form.date && typeof form.date !== "string") {
                form.date = form.date.format("YYYY-MM-DD");
            }

            this.register({
                clientType: form.clientType,
                lastName: form.lastName,
                firstName: form.firstName,
                middleName: form.middleName,
                companyName: form.companyName,
                date: form.date,
                mobile: form.mobile,
                email: form.email,
                suffix: form.suffix,
                acceptTag: form.acceptTag
            });
        } else {
            this.formService.showFormMsg("registration-form-msg", "Please fill all the required fields.", "E");
        }
    }

    register(params: any) {
        this.jsonDataService.contorlLoading(true);
        this.parentChildCommon.registerFunction(params);
    }

    setBirthMax() {
        if (this.form.controls['date'].value === null) {
            this.birthMax = 8;
        } else {
            if (this.form.controls['date'].value._i.includes('/') || this.form.controls['date'].value._i.includes('-')) {
                this.birthMax = 10;
            } else {
                this.birthMax = 8;
            }
        }
    }

    chkAcceptReg(event: any) {
        if (event.checked) {
            this.form.patchValue({acceptTag: "Y"});
            this.accept = event.checked;
        } else {
            this.form.patchValue({acceptTag: "N"});
            this.accept = event.checked;
        }
    }

    getPdf(){
        window.open(this.acceptRegLink);
    }

    populatePrincipalInfoToRegisterInfo(): void {
        const principalInfo = this.productService.productInfo.personalInfo;
        this.form.patchValue({
            lastName: principalInfo.principalLastName,
            firstName: principalInfo.principalFirstName,
            date: principalInfo.principalBirthDate
        });
    }
}