<section id="renewal">
    <div class="container arial">
        <div class="case top-border border4">
            <form [formGroup]="form">
                <div id="renewal-form">
                    <div class="form-fields">
                        <h2>Verification</h2>
                        <div class="form">
                            <div class="col-case">
                                <div class="field required">
                                    <label>Please enter password to continue with the renewal:</label>
                                    <mat-form-field appearance="fill">
                                        <input autocomplete="off" tabindex="1" matInput formControlName="answer" [type]="showPasswordValue ? 'text' : 'password'">
                                        <mat-icon matSuffix (click)="showPasswordValue = !showPasswordValue">{{showPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'answer')">
                                        {{formService.getFieldErrorMsg(form, "answer")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-btns">
                    <button class="btn btn3" (click)="onSubmit()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</section>