import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[autocomplete]'
})
export class AutocompleteDirective implements AfterViewInit {

    constructor (
        private element:ElementRef,
        private control:NgControl
    ) {}
    
    ngAfterViewInit(): void {
        this.element.nativeElement.setAttribute('autocomplete','off')
    }

    @HostListener("click", ["$event"])
    onFocus() {
        if (this.control.value) {
            this.element.nativeElement.select();
        }
    }
}