import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor() { }

    private formGroup!: FormGroup;

    public getFormGroup():FormGroup {
        return this.formGroup;
    }

    public setFormGroup(fg:FormGroup) {
        this.formGroup = fg;
    }
}