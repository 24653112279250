<section id="property-assessment">
    <div class="container proxima">
        <div class="intro-big-title">
            {{ title }}
        </div>
        <div class="intro-box">
            <div class="intro-pic">
                <img [src]="imagePath" class="image" [class]="'imageNo' + questionNo">
            </div>
            <div class="intro-content">
                <form [formGroup]="form">
                    <div class="form" *ngIf="showQuestion1">
                        <div class="field">
                            <div class="main-question">{{ question1 }}</div>
                            <mat-radio-group formControlName="isMORT" class="radio-group" (change)="checkMortgageFieldDisable($event.value)">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ answerYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ answerNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field" *ngIf="isQuestion1FieldsRequired" [class.required]="isQuestion1FieldsRequired">
                            <label>Bank or Lender Name</label>
                            <mat-form-field class="select" appearance="fill">
                                <input matInput placeholder="Bank Name" formControlName="bankDetail" [matAutocomplete]="autoBankDetail" (input)="setBankViaInput($event)" autocomplete>
                                <mat-autocomplete #autoBankDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setBankViaOption($event.option)">
                                    <mat-option *ngFor="let option of filteredBankDetailOptions | async" [value]="option">
                                        {{option.text}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'bankDetail')">
                                {{formService.getFieldErrorMsg(form, "bankDetail")}}
                            </p>
                        </div>
                        <div class="field" *ngIf="isQuestion1FieldsRequired">
                            <label>Loan Account No.</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Loan Account No." formControlName="loanAccountNo" maxlength="20" (input)="validateAnswer1()">
                            </mat-form-field>
                        </div>
                        <div class="form-note montserrat" *ngIf="showCaveat">
                            <div [innerHTML]="mortCaveat"></div>
                            <p><a class="link" (click)="showMortLink()">{{ mortLinkText }}</a></p>
                        </div>
                    </div>
                    <div class="form" *ngIf="showQuestion2">
                        <div class="main-question">{{ question1 }}</div>
                        <div class="field required province">
                            <label>Province</label>
                            <mat-form-field class="select" appearance="fill">
                                <input matInput placeholder="Province" formControlName="province" [matAutocomplete]="autoProvinceDetail" (input)="setProvinceViaInput($event)" autocomplete>
                                <mat-autocomplete #autoProvinceDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvinceViaOption($event.option)">
                                    <mat-option *ngFor="let option of filteredProvinceOptions | async" [value]="option">
                                        {{option.text}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'province')">
                                {{formService.getFieldErrorMsg(form, "province")}}
                            </p>
                        </div>
                        <div class="field required city">
                            <label>City</label>
                            <mat-form-field class="select" appearance="fill">
                                <input matInput placeholder="City" formControlName="city" [matAutocomplete]="autoCityDetail" (input)="setCityViaInput($event)" autocomplete>
                                <mat-autocomplete #autoCityDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCityViaOption($event.option)">
                                    <mat-option *ngFor="let option of filteredCityOptions | async" [value]="option">
                                        {{option.text}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'city')">
                                {{formService.getFieldErrorMsg(form, "city")}}
                            </p>
                        </div>
                        <div class="field required">
                            <label>Subdivision</label>
                            <mat-form-field class="select" appearance="fill">
                                <input matInput placeholder="Subdivision" formControlName="subdivision" [matAutocomplete]="autoSubdivisionDetail" (input)="setSubdivisionViaInput($event)" autocomplete>
                                <mat-autocomplete #autoSubdivisionDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setSubdivisionViaOption($event.option)">
                                    <mat-option *ngFor="let option of filteredSubdivisionOptions | async" [value]="option">
                                        {{option.text}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'subdivision')">
                                {{formService.getFieldErrorMsg(form, "subdivision")}}
                            </p>
                        </div>
                        <div class="field" *ngIf="cbCondition1">
                            <div class="main-question">{{ cbCondition1 }}</div>
                            <mat-radio-group formControlName="isCB1" class="radio-group" (change)="setQuestion2AnsYN($event.value, 'CB1')">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ cb1AnsYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ cb1AnsNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field" *ngIf="cbCondition2">
                            <div class="main-question">{{ cbCondition2 }}</div>
                            <mat-radio-group formControlName="isCB2" class="radio-group" (change)="setQuestion2AnsYN($event.value, 'CB2')">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ cb2AnsYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ cb2AnsNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field bottom-space" *ngIf="cbCondition3">
                            <div class="main-question">{{ cbCondition3 }}</div>
                            <mat-radio-group formControlName="isCB3" class="radio-group" (change)="setQuestion2AnsYN($event.value, 'CB3')">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ cb3AnsYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ cb3AnsNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="form" *ngIf="showQuestion2subdiv" >
                        <div class="main-question">{{ question1 }}</div>
                        <div class="field">
                            <mat-radio-group formControlName="isNSUBD" class="radio-group" (change)="checkSubdivOthersFieldDisable($event.value)">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ answerYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ answerNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field required" *ngIf="isSubdivSecured">
                            <label>Name of Subdivision</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Name of Subdivision" formControlName="subdivName" maxlength="100" uppercase [withSpace]="true" (input)="validateAnswer2subdiv()">
                            </mat-form-field>
                        </div>
                        <!-- <div class="field required">
                            <label>Complete Address</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Complete Address" formControlName="subdivAddress" maxlength="100" uppercase [withSpace]="true" (input)="validateAnswer2subdiv()">
                            </mat-form-field>
                        </div> -->
                        <div class="field required">
                            <label>House No./Lot/Block</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="House No./Lot/Block" formControlName="houseNo" maxlength="50" uppercase [withSpace]="true" (input)="validateAnswer2subdiv()">
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>Street</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Street" formControlName="street" maxlength="250" uppercase [withSpace]="true" (input)="validateAnswer2subdiv()">
                            </mat-form-field>
                        </div>
                        <div class="field bottom-space">
                            <label>Barangay</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Barangay" formControlName="brgy" maxlength="250" uppercase [withSpace]="true" (input)="validateAnswer2subdiv()">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form" *ngIf="showQuestion3">
                        <div class="field required bottom-space">
                            <label class="bold">{{ question3 }}</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="No. of Storeys/Floors" type="text" formControlName="numFLR" class="noArrow" (input)="validateAnswer3()" (keypress)="allowNumericDigitsOnlyWODecimal($event)">
                            </mat-form-field>
                            <p class="field-error" *ngIf="floorValidation()">
                                The value you have entered is not within the allowable limit of storeys/floors.
                            </p>
                        </div>
                        <div class="field required bottom-space">
                            <label class="rdo-label bold">{{ question1 }}</label>
                            <mat-radio-group formControlName="numROOF" class="radio-group" (change)="setRoofValue($event.value)">
                                <mat-radio-button class="radio-button roof" *ngFor="let roof of roofOptions" [value]="roof.value">
                                    <p class="radio-lbl-roof">{{roof.text}}</p>
                                </mat-radio-button> 
                            </mat-radio-group>
                        </div>
                        <div class="field required bottom-space">
                            <label class="rdo-label bold">{{ question2 }}</label>
                            <mat-radio-group formControlName="numWALL" class="radio-group" (change)="setWallValue($event.value)">
                                <mat-radio-button class="radio-button wall" *ngFor="let wall of wallOptions" [value]="wall.value">
                                    <p class="radio-lbl-wall">{{wall.text}}</p>
                                </mat-radio-button> 
                            </mat-radio-group>
                        </div>
                    </div>
                    <!-- <div class="form" *ngIf="showQuestion4">  //comment out until further notice
                        <div class="field">
                            <div class="main-question">{{ question1 }}</div>
                            <mat-radio-group formControlName="isHIST" class="radio-group" (change)="checkHistFieldDisable($event.value)">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ answerYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ answerNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field num-year" [class.required]="isQuestion4FieldsRequired">
                            <label>When?</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="YYYY" type="number" formControlName="numYear" class="noArrow" (input)="validateAnswer4()">
                            </mat-form-field>
                            <p class="field-error" *ngIf="yearValidation()">
                                The value you have entered is not within the allowable years.
                            </p>
                        </div>
                    </div> -->
                    <div class="form" *ngIf="showQuestion5">
                        <div class="field question5">
                            <div class="main-question">{{ question1 }}</div>
                            <mat-radio-group formControlName="isHH1" class="radio-group">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ answerYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ answerNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="prop-note montserrat">
                            <p class="sentence1">{{ sentence1 }}</p>
                            <a (click)="goToLink()" class="btn btn3 btn-avail">Open Hazard Hunter</a>
                            <p class="sentence2">{{ sentence2 }}</p>
                        </div>
                    </div>
                    <div class="form" *ngIf="showQuestion6">
                        <div class="field question5">
                            <div class="main-question">{{ question1 }}</div>
                            <mat-radio-group formControlName="isHH4" class="radio-group">
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="Y">
                                        <p class="radio-lbl">{{ answerYes }}</p>
                                    </mat-radio-button>
                                </div>
                                <div class="radio-btn-slot">
                                    <mat-radio-button class="radio-button" value="N">
                                        <p class="radio-lbl">{{ answerNo }}</p>
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="field required supporting-docs">
                            <label class="supp-doc-question">Upload Assessment Results</label>
                            <div class="supp-doc-question2">from Hazard Hunter</div>
                            <button class="btn btn6 proxima" (click)="fileInput.click()">Upload File</button>
                            <input type="file" #fileInput (change)="onFileChange($event)" multiple hidden>
                            <p class="error-msg" *ngIf="supportDocErrorMsg">{{supportDocErrorMsg}}</p>
                            <div class="files" *ngFor="let a of form.controls['supportingDocs'].value; index as i">
                                <p>{{a.filename}}</p>
                                <div class="buttons">
                                    <p class="view" (click)="onClickView(i)">View</p>
                                    <p class="remove" (click)="onClickDelete(i)">Remove</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="checkbox hazard-hunter">
                                <mat-checkbox (change)="checkHazardHunter($event)" [checked]="isHazardHunter">{{ sentence1 }}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form" *ngIf="showNotInsurable">
                        <div class="field not-insurable">
                            <p>{{ question1 }}</p>
                        </div>
                    </div>
                    <div class="form" *ngIf="showRequestQuPrompt">
                        <div class="field not-insurable">
                            <p>{{ reqForQuMsg }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="form-btns style-1">
            <button class="btn btn4" (click)="move('back')">Back</button>
            <button class="btn btn2" (click)="move('next')" [disabled]='nextDisabled'>{{ nextBtnLabel }}</button>
        </div>
    </div>
</section>