import { Injectable } from '@angular/core';

import { JsonDataService } from 'src/app/services/json-data.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    productInfo:any = {

		/******************************* DON'T MAKE DUPLICATE KEY *******************************/

        // String
        // - quotation  (If processing quotation)
        // - policy     (If processing policy)
        // - renewal    (For Renewal)
        creationProcess: "quotation",

        // ? from Link (for Renewal Links)
        // String
        // - payment (If from payment link)
        // - renewal (If from renewal link)
        fromLink: null,

		// Number
        // Connected with quoteStatDesc. Please search and use the setQuoteStat function
		// - 1	(Draft)
		// - 2	(Pending Approval)
		// - 3	(Pending Acceptance)
		// - 4	(Completed)
		// - 5	(Expired) (Obsolete - WAG NA GAMITIN)
		// - 6	(Deleted)
		// - 7	(Cancelled)
        // - 8	(Pending Payment)
        // - 9	(Completed (Without Payment))
		quoteStatCd: null,

        // String
        // Connected with quoteStatCd. Please search and use the setQuoteStat function
        quoteStatDesc: "",

        // Number
        // Connected with polStatDesc. Please search and use the setPolStat function
        // - 1  (Draft)
        // - 2  (In Force)
        polStatCd: null,

        // String
        // Connected with polStatCd. Please search and use the setPolStat function
        polStatDesc: "",

        // Number
        // Connected with contractTypeDesc. Please search and use the setContractType function
        contractTypeCd: null,

        // String
        // Connected with contractTypeCd. Please search and use the setContractType function
        contractTypeDesc: "",

        // Number
        quoteId: null,
		policyId: null,
		reasonCd: null,

        // String
        quoteNo: "",
		policyNo: "",
		lineCd: "",
		sublineCd: "",
        menuLineCd: "",
        assdEmailAddress: "",
        assdFirstName: "",
        language: "",
        paytTag: "",

		// Boolean
		// This is used when a user updates vehicle details that will recompute the coverages
		recomputeFlag: false,

		// Boolean
		// This is used when a user updates plate no that will recheck eff date based on new ic policy
		reEffDateFlag: false,

        /*
            planInfo: {
				planCd: null,					Number
                planName: ""					String
                premiumText:                    String
                referralTag:                    String
                currencyCd:                     String
			}
        */
        planInfo: {},

        /*
            vehicleInfo: {
				mortgageType: "",				String
				bankDetail: {					Object (Empty string if no value)
              		value: null,                Number
              		text: "",                   String
                    alfesTag: ""                String
				},
				loanAccountNo: "",             	String
                alfesNo: "",                    String
				vehicleType: "",	           	String
				plateNo: "",	             	String
				yearModelDetail: {				Object (Empty string if no value)
              		value: null,             	Number
              		text: ""                 	String
				},
				makeDetail: {					Object (Empty string if no value)
              		value: null,             	Number
              		text: ""                 	String
				},
				modelDetail: {					Object (Empty string if no value)
              		value: null,             	Number
              		text: ""                 	String
				},
				subModelDetail: {				Object (Empty string if no value)
              		value: null,             	Number
              		text: "",                 	String
					makeTypeCd: "",            	String
					ccWt: null,               	Number
					seats: null,             	Number
					gasType: "",             	String
					fmvMinValue: null,      	Number
					fmvMaxValue: null          	Number
				},
				makeTypeCd: "",              	String (Same value with subModelDetail.makeTypeCd)
				ccWt: null,                  	Number (Same value with subModelDetail.ccWt)
				gasType: "",                   	String (Same value with subModelDetail.gasType)
				gasDesc: "",                   	String
				noOfSeats: null,               	Number (Same value with subModelDetail.seats)
				fmvMinValue: null,            	Number (Same value with subModelDetail.fmvMinValue)
				fmvMaxValue: null,            	Number (Same value with subModelDetail.fmvMaxValue)
				seatsCovered: null,          	Number
				color: "",                     	String
				purchasePrice: "",             	String
				engineNo: "",                  	String
				chassisNo: "",                 	String
				beingUsedDetail: {				Object (Empty string if no value)
					value: null,             	Number
              		text: ""                 	String
				},
				mvFileNo: "",                  	String
				mvTypeDetail: {					Object (Empty string if no value)
					value: "",             		String
              		text: ""                 	String
				},
				mvPremTypeDetail: {				Object (Empty string if no value)
					value: "",             		String
              		text: ""                 	String
				},
				remarks: "",                   	String
				cocNo: "",						String
				cocAuthNo: "",					String
                icAuthNo: "",					String
				cocAuthDate: "",				String
                supportingDocs: [{              Array of object (Empty array if no value)
                    filename: "",               String
                    size: null                  Number
                }],
                uppaText: ""                    String 
			}
        */
        vehicleInfo: {},

        /*
            personalInfo: {
				lastName: "",				    String
				firstName: "",             	    String
                middleName: "",                 String
				suffix: "",	           	        String
				birthdate: "",	             	String
				occupation: "",              	String 
				maritalStatus: null,            String  
				address: "",                   	String 
			}
        */
        personalInfo: {},
        /*  RENEWAL
            dependents: {
                dependentName: "",              String
                relationshipDesc: "",           String
                nationalityDesc: "",            String
                birthDate: "",                  String
                age: null,                      Number
            }
        */
        dependents: [],
        dependentsEdit: [],

        /*
        propertyAssessment: {
            isMORT: "Y",				    //String
            bankDetail: {					//Object (Empty string if no value)  bpims.BMM045
                value: 2,                   //Number
                text: "88 ASIA FINANCE CORPATION"                    //String
            },
            loanAccountNo: "123456",             	//String  (free text)
            province: {					    //Object (Empty string if no value) bpims.BMM047
                value: 53,					//Number
                text: "NCR, 3rd district"					//String
            },
            city: {					        //Object (Empty string if no value) bpims.BMM092
                value: 30001,				//Number
                text: "Caloocan",					//String
            },
            zone: {					        //Object (Empty string if no value) bpims.BMM167
                value: 1,				//Number
                text: "Caloocan",					//String
            },
            subdivision: {					//Object (Empty string if no value) bpims.BMM165
                value: 1,				//Number
                text: "Vista Verde North",					//String
                zoneCd: 1               //Number
            },
            isASUBD: "Y",				    //String
            isNSUBD: "N",				    //String
            subdivName: "",					//String (free text)
            subdivAddress: "",              //String (free text)
            numFLR: 1,                      //Number
            numROOF: 2,                     //Number
            numWALL: 6,                     //Number
            roof: {					//Object (Empty string if no value) bpims.BMM160
                value: 3,				//Number
                text: "Reinforced Concrete",					//String
                classCd: "A"               //String
            },
            wall: {					//Object (Empty string if no value) bpims.BMM160
                value: 6,				//Number
                text: "Timber Blocks",					//String
                classCd: "A"               //String
            },
            isCOV: "Y",				        //String
            isCB1: "Y",				        //String
            isCB2: "N",				        //String
            isCB3: "N",				        //String
            numHIST: 3,				        //Number
            yearHIST: 2019,                 //Number (YYYY)
            isHH1: "Y",				        //String
            isHH4: "Y",				        //String
        },
        */
        
        propertyAssessment: {},

        /*
            COVERAGE / PERILS INFORMATION
            - conType                   Number
            - noOfYears                 Number
            - effDate                   Date
            - expDate                   String
            - covCompTag                String
        */
        coverageInfo: {},
        coverages: [],
        perilInfo: [],
        premiumCharges: [],
        ara:[],
        peril:[],
        taxes:[],
        benefitsCoverages: [],

        /*
            policyHolderDetails: {
                quote: {
                    displayName: "",            String
                    address: ""                 String
                },
                policy: {
                    displayName: "",            String
                    address: ""                 String
                    policyAddress: ""           String
                }
            }
        */
        policyHolderDetails: {},

		/*
            policyHeaderDetails: {
				agentDetail: {					Object (Empty string if no value)
					value: "",					String
					text: ""					String
				},
				sourceCodeDetail: {				Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
				sourceExtensionDetail: {		Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
				bmaDetail: {					Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
				referrorCodeDetail: {			Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
				projectCodeDetail: {			Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
				clientTypeGroupDetail: {		Object (Empty string if no value)
					value: "",					String
					text: ""					String
				}
			}
        */
		policyHeaderDetails: {},

		/*
			ETO LANG NEED KO SA KYC FORM PARA SA PAYMENT FORM AT E-SUMMARY.
            KAPAG MAY IBANG NEED, ADD NYO NA LANG.

			customerInfo: {
                clientTypeDetail: {             Object (Empty string if no value)
                    value: "",				    String
					text: ""					String
                }
                lastName: "",					String
				firstName: "",					String
                middleName: "",                 String
                suffix: "",                     String
                companyName: "",                String
				emailAddress: "",				String
                mobileNo: "",                   String
                telNo: "",                      String
                birthDate: "",                  String
                genderDetail: {				    Object (Empty string if no value)
					value: null,				Number
					text: ""					String
				},
                maritalDetail: {                Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                },
                countryOfBirthOriginDetail: {   Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                },
                nationalityDetail: {            Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                },
                registrationNo: "",             String
                permanentAddress: "",           String
                permanentCityDetail: {          Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                    countryCd: null             Number
                },
                permanentPostalDetail: {        Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number (Same value with permanentCityDetail.provCd)
                    countryCd: null,            Number (Same value with permanentCityDetail.countryCd)
                    cityCd: null                Number (Same value with permanentCityDetail.value)
                },
                tinNo: "",                      String
                jobCategoryDetail: {            Object (Empty string if no value)
                    value: null                 Number
                    text: ""                    String
                },
                riskProfilingDetail: {          Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                },
                birthCityDesc: "",              String
                cityDesc: "",                   String
                postalDesc: "",                 String
                presentCityDesc: "",            String
                presentPostalDesc: ""           String
			}
		*/
		customerInfo: {},

        /*
            customerIds: [{
                idTypeDetail: {                 Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                }
                idNo: "",                       String
                expiryDate: ""                  String
            }]
        */
        customerIds: [],

        /*
            paymentInfo: {
                paytRefNo: "",                  String
                tranNo: "",                     String
                paymentMode: "",                String
                paytDate: ""                    String
            }
        */
        paymentInfo: {},
        /*
            paPropertyInfo: {
                age: null,                      Number
                occupationDesc: "",             String
                otherRiskDetail: "",            String
                interestedParty: "",            String
                remarks: ""                     String
            }
        */
        paPropertyInfo: {},
        /*
            firePropertyInfo: {
                riskNo: null,                   Number
                riskName: "",                   String
                cityDesc: "",                   String
                districtDesc: "",               String
                blockDesc: "",                  String
                tariffRiskCd: "",               String
                construction: "",               String
                occupancy: "",                  String
                otherRiskDetail: "",            String
                bankName: "",                   String
                remarks: ""                     String
            }
        */
        firePropertyInfo: {},
        /*
            beneficiaries: {
                beneficiaryName: "",            String
                relationshipDesc: "",           String
                nationalityDesc: "",            String
                birthDate: "",                  String
                age: null,                      Number
            }
        */
        beneficiaries: {},
        /*
            prodVouchInfo: {
                prodVouchCd: "",                String
                lineCd: "",                     String
                sublineCd: "",                  String
                planCd: "",                     String
                clientCd: "",
                projectCd: "",
                agentCd: "",
                bmaCd: "",
                referrorCd: "",
                srcCd: "",
                srcExtCd: "", 
            }
        */
        prodVouchInfo: {},
        /*
            propertyInfo: {
                addType: "",                    Number
                compAdd: "",                    String
                houseNo: "",                    String
                street: "",                     String
                brgy: "",                       String
                presentAdd: "",                 String
                permanentAdd: "",               String
                country: {                      Object (Empty string if no value)
                    value: "",                  Number
                    text: ""                    String
                },
                presentProv: {                  Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                },
                presentPostal: {                Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                    cityCd: null                Number 
                },
                permanentProv: {                Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                },
                permanentPostal: {              Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                    cityCd: null                Number 
                },
                provOrCity: {                   Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                },
                postCode: {                     Object (Empty string if no value)
                    value: null,                Number
                    text: "",                   String
                    provCd: null,               Number
                    cityCd: null                Number 
                },                              
                latitude: "",                   String
                longitude: "",                  String
                currency: "",                   String
                bldgTag: "",                    String
                bldgInsuredAmt: "",             Number/Decimal
                contTag: "",                    String
                contInsuredAmt: "",             Number/Decimal
                constYear: "",                  Number
                ageBldg: "",                    Number
                noOfFloor: "",                  Number
                nearLand: "",                   String
                roofPropDtl: "",                Number
                roofOthers: "",                 String
                wallPropDtl: "",                Number
                wallOthers: "",                 String
                mortAccTag: "",                 String
                bank: {                         Object (Empty string if no value)
                    value: null,                Number
                    text: ""                    String
                },
                loanAcctNo: "",                  String
                existInsTag: "",                String
                existInsComp: "",               String
                existInsBldgTag: "",            String
                existInsContTag: "",            String
                occupancyF: "",                 String
                occupancyR: "",                 String
                occupancyL: "",                 String
                occupancyB: "",                 String
                storeyF: "",                    String
                storeyR: "",                    String
                storeyL: "",                    String
                storeyB: "",                    String
                roofF: "",                      String
                roofR: "",                      String
                roofL: "",                      String
                roofB: "",                      String
                exWallF: "",                    String
                exWallR: "",                    String
                exWallL: "",                    String
                exWallB: "",                    String
                surroundF: "",                  String
                surroundR: "",                  String
                surroundL: "",                  String
                surroundB: "",                  String
                supportingDocs: [{              Array of object (Empty array if no value)
                    filename: "",               String
                    size: null                  Number
                }]
            }
        */
        propertyInfo: {},
        deductibles:{}
    };

    constructor (
        private jsonDataService: JsonDataService
    ) {
        try {
			this.productInfo = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")) || this.productInfo;
		} catch (e) {
			console.error("There's something wrong getting the product information.");
		}
    }

    setProductInfo(key:string, value:any):void {
        if (key === "") {
            this.productInfo = {};
        } else {
            this.productInfo[key] = value;
        }

        this.jsonDataService.saveToStorage("productInfo", this.productInfo);
    }

	setProductInfo2(productInfo:any):void {
		this.productInfo = productInfo === "" ? {} : productInfo;
		this.jsonDataService.saveToStorage("productInfo", this.productInfo);
	}

    // Please use this function to set value to quoteStatCd.
    // This will also set the status description based on the status code
    setQuoteStat(quoteStatCd:number):void {
        const statCd = Number(quoteStatCd);
        this.productInfo.quoteStatCd = statCd;
        this.productInfo.quoteStatDesc = this.jsonDataService.data.refCds.filter((a:any) => Number(a.rvLowValue) === statCd && a.rvDomain === "BMT011.QUOTE_STAT_CD")[0].rvMeaning;
        this.jsonDataService.saveToStorage("productInfo", this.productInfo);
    }

    // Please use this function to set value to polStatCd.
    // This will also set the status description based on the status code
    setPolStat(polStatCd:number):void {
        const statCd = Number(polStatCd);
        this.productInfo.polStatCd = statCd;
        this.productInfo.polStatDesc = this.jsonDataService.data.refCds.filter((a:any) => Number(a.rvLowValue) === statCd && a.rvDomain === "BMP011.POL_STAT_CD")[0].rvMeaning;
        this.jsonDataService.saveToStorage("productInfo", this.productInfo);
    }

    // Please use this function to set value to contractTypeCd.
    // This will also set the contract type description based on the contract type code
    setContractType(contractTypeCd:number):void {
        let contractCd = Number(contractTypeCd);
        /*if (this.productInfo.sublineCd === "CTPL") {
            contractCd = 3;
        }*/

        this.productInfo.contractTypeCd = contractCd;
        if (this.productInfo.lineCd === "CI") {
            this.productInfo.contractTypeDesc = this.jsonDataService.data.lines.filter((a:any) => a.lineCd === this.productInfo.lineCd)[0]?.lineName;
        }
        else {
            this.productInfo.contractTypeDesc = this.jsonDataService.data.contracts.filter((a:any) => a.contractTypeCd === this.productInfo.contractTypeCd)[0]?.contractTypeDesc;
        }
        this.jsonDataService.saveToStorage("productInfo", this.productInfo);
    }

    setEffDateParams(effDate: Date, isAllowed: boolean, disableEffDate:boolean):void{
        if(!this.productInfo.coverageInfo){
            this.productInfo.coverageInfo = {};    
        }
        if(!isAllowed){
            this.productInfo.coverages=  [];
            this.productInfo.perilInfo=  [];
            this.productInfo.premiumCharges=  [];
            this.productInfo.ara= [];
            this.productInfo.peril= [];
            this.productInfo.taxes= [];
        }
        this.productInfo.coverageInfo.effDate = effDate;
        this.productInfo.coverageInfo.isAllowed = isAllowed;
        this.productInfo.coverageInfo.disableEffDate = disableEffDate;
        this.jsonDataService.saveToStorage("productInfo", this.productInfo);
    }
}