import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FAQService {
    faqInfo: any = {
        title: "",
        lineCd: "",
        sublineCd: "",
        content: []
    }
}