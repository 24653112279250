<section id="rev-prem-charges" class="product-details">
    <div class="container">
        <form>
            <div id="rev-prem-charges-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div class="quote-policy-details montserrat left-aligned">
                        <div class="detail">
                            <div class="label">Quotation No.</div>
                            <div class="value">{{productInfo.quoteNo || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Contract Type</div>
                            <div class="value">{{productInfo.contractTypeDesc || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Insurance Plan</div>
                            <div class="value">{{productInfo.planInfo.planName || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Start Date</div>
                            <div class="value">{{productInfo.coverageInfo.effDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Policy End Date</div>
                            <div class="value">{{productInfo.coverageInfo.expDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Status</div>
                            <div class="value">{{productInfo.quoteStatDesc}}</div>
                        </div>
                    </div>
                    <div class="quote-policy-summary">
                        <div class="summary left-aligned" *ngIf="productInfo.policyHolderDetails?.quote?.displayName || productInfo.policyHolderDetails?.policy?.displayName">
                            <h2>Policy Holder Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="label">Name</div>
                                        <div class="value" *ngIf="!productInfo.policyHolderDetails?.policy?.displayName">
                                            {{productInfo.policyHolderDetails.quote.displayName || '-'}}
                                        </div>
                                        <div class="value" *ngIf="productInfo.policyHolderDetails?.policy?.displayName">
                                            {{productInfo.policyHolderDetails.policy.displayName || '-'}}
                                        </div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Address</div>
                                        <div class="value" *ngIf="!productInfo.policyHolderDetails?.policy?.address">
                                            {{productInfo.policyHolderDetails.quote.address || '-'}}
                                        </div>
                                        <div class="value" *ngIf="productInfo.policyHolderDetails?.policy?.address">
                                            {{productInfo.policyHolderDetails.policy.address || '-'}}
                                        </div>
                                    </div>
                                </div>
                            
                        <div *ngIf="productInfo.quoteStatCd === 7" class="see-more">
                            <ng-container *ngIf="seeMorePolicyHolder">
                                <div class="box-holder" [class.style-2]="productInfo.customerInfo.clientTypeDetail.value === 'B'" [class.style-4]="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                    <ng-container *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="detail">
                                            <div class="label">Last Name</div>
                                            <div class="value">{{ productInfo.customerInfo.lastName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">First Name</div>
                                            <div class="value">{{ productInfo.customerInfo.firstName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">Middle Name</div>
                                            <div class="value">{{ productInfo.customerInfo.middleName || "-" }}</div>
                                        </div>
                                        <div class="detail">
                                            <div class="label">Suffix</div>
                                            <div class="value">{{ productInfo.customerInfo.suffix || "-" }}</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="detail">
                                            <div class="label">Company Name</div>
                                            <div class="value">{{ productInfo.customerInfo.companyName || "-" }}</div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Email Address</div>
                                        <div class="value email">{{ productInfo.customerInfo.emailAddress || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Mobile No.</div>
                                        <div class="value">{{ productInfo.customerInfo.mobileNo || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Telephone No.</div>
                                        <div class="value">{{ productInfo.customerInfo.telNo || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Birth Date</div>
                                        <div class="value">{{ productInfo.customerInfo.birthDate ? (productInfo.customerInfo.birthDate | date: "MM/dd/yyyy") : "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="label">Date of Registration</div>
                                        <div class="value">{{ productInfo.customerInfo.birthDate ? (productInfo.customerInfo.birthDate | date: "MM/dd/yyyy") : "-" }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Gender</div>
                                        <div class="value">{{ productInfo.customerInfo.genderDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'B'">
                                        <div class="label">Registration No.</div>
                                        <div class="value">{{ productInfo.customerInfo.registrationNo || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Marital Status</div>
                                        <div class="value">{{ productInfo.customerInfo.maritalDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Province or City</div>
                                        <div class="value">{{ productInfo.customerInfo.permanentCityDetail?.text ? productInfo.customerInfo.permanentCityDetail?.text : (productInfo.customerInfo.cityDesc || "-") }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Postal Code</div>
                                        <div class="value">{{ productInfo.customerInfo.permanentPostalDetail?.text ? productInfo.customerInfo.permanentPostalDetail?.text : (productInfo.customerInfo.postalDesc || "-") }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Country of {{ productInfo.customerInfo.clientTypeDetail.value === "R" ? "Birth" : "Origin" }}</div>
                                        <div class="value">{{ productInfo.customerInfo.countryOfBirthOriginDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.customerInfo.clientTypeDetail.value === 'R'">
                                        <div class="label">Citizenship / Nationality</div>
                                        <div class="value">{{ productInfo.customerInfo.nationalityDetail?.text || "-" }}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Tax Identification No.</div>
                                        <div class="value">{{ productInfo.customerInfo.tinNo || "-" }}</div>
                                    </div>
                                    <div class="detail" *ngIf="userDetails.accountType !== 'P'">
                                        <div class="label">Category</div>
                                        <div class="value">{{ productInfo.customerInfo.jobCategoryDetail?.text || "-" }}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4" *ngIf="userDetails.accountType === 'E'">
                                    <div class="detail">
                                        <div class="label">Risk Profiling</div>
                                        <div class="value" [ngClass]="{'red': productInfo.customerInfo.riskProfilingDetail?.value === 1}">
                                            {{ productInfo.customerInfo.riskProfilingDetail?.text || "-" }}
                                        </div>
                                    </div>
                                </div>
                                <div id="ids" class="box-holder style-4" *ngIf="productInfo.customerIds && productInfo.customerIds?.length !== 0">
                                    <div class="detail" *ngFor="let id of productInfo.customerIds">
                                        <div class="label">Type of ID</div>
                                        <div class="value">{{ id.idTypeDetail.text }}</div>
                                        <div class="label">ID No.</div>
                                        <div class="value">{{ id.idNo }}</div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="button grid">
                                <button class="btn btn2 blue" (click)="seeMorePolicyHolder = !seeMorePolicyHolder">
                                    See {{ seeMorePolicyHolder ? "Less" : "More" }} Policy Holder Information
                                </button>
                            </div>
                        </div>    
                            </div>
                        </div>
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'MC'">
                            <h2>Vehicle Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Year Model</div>
                                        <div class="value">{{productInfo.vehicleInfo.yearModelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Brand & Model</div>
                                        <div class="value">{{productInfo.vehicleInfo.makeDetail.text + ' ' + productInfo.vehicleInfo.modelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Variant</div>
                                        <div class="value">{{productInfo.vehicleInfo.subModelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Color</div>
                                        <div class="value">{{productInfo.vehicleInfo.color || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Plate No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.plateNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Engine No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.engineNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Chassis No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.chassisNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Seating Capacity</div>
                                        <div class="value">{{productInfo.vehicleInfo.seatsCovered || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder custom-style-2" *ngIf="productInfo.sublineCd === 'CTPL'">
                                    <div class="detail">
                                        <div class="label">MV File No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.mvFileNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">MV Type</div>
                                        <div class="value">{{productInfo.vehicleInfo.mvTypeDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">MV Prem Type</div>
                                        <div class="value">{{productInfo.vehicleInfo.mvPremTypeDetail?.text || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder custom-style-1" *ngIf="productInfo.vehicleInfo.mortgageType === 'Y'">
                                    <div class="detail">
                                        <div class="label">Mortgage Details</div>
                                        <div class="value">{{productInfo.vehicleInfo.bankDetail?.text || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Loan Account No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.loanAccountNo || '-'}}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.vehicleInfo.bankDetail?.alfesTag === 'Y'">
                                        <div class="label">ALFES No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.alfesNo || '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.menuLineCd === 'PA'">
                            <h2>Principal Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Name</div>
                                        <div class="value">{{productInfo.personalInfo.principalFullName}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Birthdate</div>
                                        <div class="value">{{productInfo.personalInfo.principalBirthDateFormat}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Occupation</div>
                                        <div class="value">{{productInfo.personalInfo.principalOccupation.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Marital Status</div>
                                        <div class="value">{{productInfo.personalInfo.principalMaritalStatus.text}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Start of changes for FIRE -->
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'HA'">
                            <h2>Property Details</h2>
                            <div class="box-1">
                                <!-- ROW 1 -->
                                <div class="box-holder style-1">
                                    <div class="detail">
                                        <div class="label">Address</div>
                                        <div class="value">{{productInfo.propertyInfo.compAdd}}</div>
                                    </div>
                                </div>

                                <!-- ROW 2 -->
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Country</div>
                                        <div class="value">{{productInfo.propertyInfo.country.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Province or City</div>
                                        <div class="value">{{productInfo.propertyInfo.provOrCity.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Postal Code</div>
                                        <div class="value">{{productInfo.propertyInfo.postCode.text ? productInfo.propertyInfo.postCode.text.slice(0,4) : ''}}</div>
                                    </div>
                                </div>

                                <!-- ROW 3 -->
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Latitude</div>
                                        <div class="value">{{productInfo.propertyInfo.latitude}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Longitude</div>
                                        <div class="value">{{productInfo.propertyInfo.longitude}}</div>
                                    </div>
                                </div>

                                <!-- ROW 4 -->
                                <div class="box-holder style-1 insurance-amount">
                                    <div class="detail">
                                        <div class="label">Amount of Insurance Applied for</div>
                                    </div>
                                </div>
                                <div class="box-holder style-2 contents" *ngFor="let row of longerRow;index as idx" [class.insurance-amount]="idx+1 != longerRow.length">
                                    <div class="detail">
                                        <div class="value"  *ngIf="insuredCol1[idx]">
                                            <mat-checkbox 
                                                class="checkbox"
                                                [checked]="insuredCol1[idx].amt"
                                                [disableRipple]="true"
                                                (click)="$event.preventDefault()">
                                                {{insuredCol1[idx].label}}&nbsp;
                                            </mat-checkbox>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{insuredCol1[idx].curr}}
                                                </span>
                                                <span class="amount">
                                                    {{insuredCol1[idx].amt | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="detail">
                                        <div class="value" *ngIf="insuredCol2[idx]">
                                            <mat-checkbox 
                                                class="checkbox"
                                                [checked]="insuredCol2[idx].amt"
                                                [disableRipple]="true"
                                                (click)="$event.preventDefault()">
                                                {{insuredCol2[idx].label}}&nbsp;
                                            </mat-checkbox>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{insuredCol2[idx].curr}}
                                                </span>
                                                <span class="amount">
                                                    {{insuredCol2[idx].amt | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="value">
                                            <span>Total Sum Insured&nbsp;&nbsp;&nbsp;</span>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{ displayedCurrency }}
                                                </span>
                                                <span class="amount">
                                                    {{total | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End of changes for FIRE -->

                        <div class="summary other-info" *ngIf="productInfo.lineCd === 'MC'">
                            <h2>Other Information</h2>
                            <div class="box-1">
                                <div class="box-holder" [class.style-2]="productInfo.sublineCd === 'CTPL'" [class.style-3]="productInfo.sublineCd !== 'CTPL'">
                                    <div class="detail">
                                        <div class="label">Deductible Amount<div class="help-icon"><mat-icon matTooltip="{{dedAmtComment}}" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.deductibleAmt ? (premiumCharges.deductibleAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.sublineCd !== 'CTPL'">
                                        <div class="label">Towing Limit<div class="help-icon"><mat-icon matTooltip="{{towingAmtComment}}" #tooltip2="matTooltip" (click)="tooltip2.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.towingAmt ? (premiumCharges.towingAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Authorized Repair Limit<div class="help-icon"><mat-icon matTooltip="{{repairAmtComment}}" #tooltip3="matTooltip" (click)="tooltip3.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.repairLimitAmt ? (premiumCharges.repairLimitAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary dependents" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd !== 'IND' && showDepDetails">
                            <h2>Dependents</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p (click)="sortDependents('name', 'name')" [class.sort]="lastSortedColumn === 'name'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Name
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('relationship', 'relationship')" [class.sort]="lastSortedColumn === 'relationship'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Relationship
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('occupation', 'occupation')" [class.sort]="lastSortedColumn === 'occupation'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Occupation
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('maritalStatus', 'maritalStatus')" [class.sort]="lastSortedColumn === 'maritalStatus'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Marital Status
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('birthDate', 'birthDate')" [class.sort]="lastSortedColumn === 'birthDate'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Birthdate
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('age', 'age')" [class.sort]="lastSortedColumn === 'age'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Age
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let dependent of dependents">
                                            <div class="sp-1">
                                                <p>{{dependent.name}}</p>
                                                <div>
                                                    <p>Name</p>
                                                    <p>{{dependent.name}}</p>
                                                </div>
                                                <div>
                                                    <p>Relationship</p>
                                                    <p>{{dependent.relationship}}</p>
                                                </div>
                                                <div>
                                                    <p>Occupation</p>
                                                    <p>
                                                        {{dependent.occupation || '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Marital Status</p>
                                                    <p>
                                                        {{dependent.maritalStatus}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Birthdate</p>
                                                    <p>
                                                        {{dependent.birthDate}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Age</p>
                                                    <p>
                                                        {{dependent.age}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="centerAlign">{{dependent.relationship}}</p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.occupation || '-'}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.maritalStatus}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.birthDate}}
                                                </p>
                                            </div>
                                            <div class="sp-6">
                                                <p class="centerAlign">{{dependent.age}}</p>
                                                <div>
                                                    <p>Name</p>
                                                    <p>{{dependent.name}}</p>
                                                </div>
                                                <div>
                                                    <p>Relationship</p>
                                                    <p>{{dependent.relationship}}</p>
                                                </div>
                                                <div>
                                                    <p>Occupation</p>
                                                    <p>
                                                        {{dependent.occupation || '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Marital Status</p>
                                                    <p>
                                                        {{dependent.maritalStatus}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Birthdate</p>
                                                    <p>
                                                        {{dependent.birthDate}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Age</p>
                                                    <p>
                                                        {{dependent.age}}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovFPA" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'FPA' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p></p>
                                            <p class="ralign">
                                                Principal
                                            </p>
                                            <p class="ralign">
                                                Spouse/Parents
                                            </p>
                                            <p class="ralign">
                                                Children
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesFPA">
                                            <div class="sp-1">
                                                <p>{{benefitCoverage.perilName}}</p>
                                                <div>
                                                    <p>{{benefitCoverage.perilName}}</p>
                                                    <p></p>
                                                </div>
                                                <div>
                                                    <p>Principal</p>
                                                    <p>{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                                <div>
                                                    <p>Spouse/Parents</p>
                                                    <p>
                                                        {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Children</p>
                                                    <p>
                                                        {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="ralign">{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovIND" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'IND' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p>
                                                Principal
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesIND">
                                            <div class="sp-1">
                                                <p>{{benefitCoverage.perilName}}</p>
                                                <div>
                                                    <p>{{benefitCoverage.perilName}}</p>
                                                    <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovCI" *ngIf="productInfo.lineCd === 'CI' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p>
                                                Principal
                                            </p>
                                        </li>
                                        <li class="body">
                                            <div class="sp-1">
                                                <p>Daily Cash Assistance</p>
                                                <div>
                                                    <p>Daily Cash Assistance</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                                <div>
                                                    <p>Maximum No. of Confinement Days</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.noOfDays}}</p>
                                                </div>
                                                <div>
                                                    <p>Maximum Benefit</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p>Maximum No. of Confinement Days</p>
                                            </div>
                                            <div>
                                                <p>Maximum Benefit</p>
                                            </div>
                                        </li>
                                        <li class="body2">
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.noOfDays}}</p>
                                            </div>
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary other-info" *ngIf="productInfo.lineCd === 'HA'">
                            <h2>Deductibles</h2>
                            <div class="box-1">
                                <!-- ROW 1 -->
                                <div class="box-holder style-1">
                                    <div class="detail">
                                        <!-- <div class="label"></div> -->
                                        <div class="value deductible-text">
                                            <span *ngFor="let deductible of deductibles">{{deductible.deductibleText}}
                                                <br>
                                                <br>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary premium-charges">
                            <h2>Premium Charges</h2>
                            <div class="box-2">
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label">Premium</div>
                                        <div class="value">{{premiumCharges.premiumAmt | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                    <div class="details">
                                        <div class="detail" *ngFor="let tax of premiumCharges.taxes">
                                            <div class="label">{{tax.name}}</div>
                                            <div class="value">{{tax.value | currency: 'PHP': '₱ '}}</div>
                                        </div>
                                    </div>
                                    <div class="detail total-amt">
                                        <div class="label">Amount Due</div>
                                        <div class="value">{{premiumCharges.amountDue | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-1" *ngIf="(userDetails.userId && userDetails.accountType !== 'P') && showComm">
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="label">Commission Amount</div>
                                        <div class="value">{{premiumCharges.commissionAmt | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                    <div class="detail total-amt">
                                        <div class="label">Net Due</div>
                                        <div class="value">{{premiumCharges.netDue | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary coverage" *ngIf="summarizedPremTag == 'N'">
                            <div class="box-1">
                                <div class="custom-table-2 no-sort">
                                    <ul>
                                        <li class="header">
                                            <div>
                                                <p></p>
                                                <p class="bold ralign">Amount</p>
                                            </div>
                                        </li>

                                        <!-- NET PREMIUMS -->
                                        <li class="body">
                                            <div class="category">
                                                <p>Net Premiums</p>
                                            </div>
                                        </li>
                                        <li class="body" *ngFor="let peril of premiumCharges.perils">
                                            <div class="sub-category">
                                                <p>{{peril.name}}</p>
                                                <p *ngIf="peril.value === 0" class="ralign">FREE</p>
                                                <p *ngIf="peril.value !== 0" class="ralign">{{peril.value | currency: 'PHP': '₱ '}}</p>
                                            </div>
                                        </li>
                                        <li class="body">
                                            <div class="sub-category">
                                                <p class="bold">Subtotal</p>
                                                <p class="bold ralign">{{premiumCharges.totalPeril | currency: 'PHP': '₱ '}}</p>
                                            </div>
                                        </li>

                                        <!-- TAXES -->
                                        <li class="body">
                                            <div class="category">
                                                <p>Taxes</p>
                                            </div>
                                        </li>
                                        <li class="body" *ngFor="let tax of premiumCharges.taxes">
                                            <div class="sub-category">
                                                <p>{{tax.name}}</p>
                                                <p *ngIf="tax.name !== 'Other Charges' && tax.value === 0" class="ralign">FREE</p>
                                                <p *ngIf="tax.value !== 0" class="ralign">{{tax.value | currency: 'PHP': '₱ '}}</p>
                                            </div>
                                        </li>
                                        <li class="body">
                                            <div class="sub-category">
                                                <p class="bold">Subtotal</p>
                                                <p class="bold ralign">{{premiumCharges.totalTax | currency: 'PHP': '₱ '}}</p>
                                            </div>
                                        </li>

                                        <!-- TOTAL AMOUNT DUE -->
                                        <li class="body">
                                            <div>
                                                <p class="bold">Total Amount Due (Gross Premiums Due)</p>
                                                <p class="bold ralign">{{premiumCharges.amountDue | currency: 'PHP': '₱ '}}</p>
                                            </div>
                                        </li>

                                        <!-- COMMISSION AMOUNT AND NET DUE -->
                                        <ng-container *ngIf="(userDetails.accountType === 'A' || userDetails.accountType === 'E') && showComm">
                                            <li class="body">
                                                <div>
                                                    <p>Commission Amount</p>
                                                    <p class="ralign">{{premiumCharges.commissionAmt | currency: 'PHP': '₱ '}}</p>
                                                </div>
                                            </li>
                                            <li class="body">
                                                <div>
                                                    <p class="bold">Net Due</p>
                                                    <p class="bold ralign">{{premiumCharges.netDue | currency: 'PHP': '₱ '}}</p>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-btns">
                        <div class="form-note cancel-note">
                            <p *ngIf="isUserAllowedToCancel && productInfo.quoteStatCd !== 7">
                                {{parameterService.paramV("CANCELLATION_TEXT")}} <a (click)="cancelQuo.open('Return')">Click Here</a>
                            </p>
                            <p *ngIf="productInfo.quoteStatCd === 7">
                                {{'Reason for Cancellation: ' + reason}}
                                {{ reason && cocErrorMsg ? ' - ' : ""}}
                                {{ cocErrorMsg ? (cocErrorMsg) : "" }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button class="btn btn4" (click)="move(productInfo.quoteStatCd !== 7 ? 'back' : 'dashboard')">Back</button>
                            <button class="btn btn2" *ngIf="productInfo.quoteStatCd !== 7" (click)="move('next')">Next: Quotation</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<overlay #cancelQuo id="cancel-title" [title]="'Confirmation'">
    <div content id="cancel-overlay">
        <div id="cancel-text">Do you really want to cancel your quotation?</div>
        <form [formGroup]="form">
            <div class="form">
                <div class="col-case">
                    <div class="field">
                        <label id="cancel-reason-text">Reason for Cancellation</label><label id="cancel-red-mark">*</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput placeholder="Select Reason for cancellation" formControlName="reason" [matAutocomplete]="autoReason" (input)="setReasonViaInput($event)" autocomplete>
                            <mat-autocomplete #autoReason="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                <mat-option *ngFor="let option of filteredReasonOptions | async" [value]="option">
                                    {{option.text}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary" (click)="cancelQuotation(form.controls['reason'].value.value);cancelQuo.close()">Proceed</button>
</overlay>

<overlay #verifyEmail [title]="''">
    <div content>
        <div id="verifyEmailRevPrem">
            <h2>Verify your Account</h2>
            <div>
                <p>We have sent an email to <b>{{ userDetails.email }}</b></p>
                <br><br>
                <p>You need to verify your email to continue. If you have not received the verification email, please check your "Spam folder". You can also click the resend button below to have another email sent to you.</p>
                <br><br>
                <a [routerLink]="" (click)="resendEmail()">Resend Verification Email</a>
            </div>
        </div>
    </div>
    <button mat-button buttons class="btn" (click)="contactBpiMs();verifyEmail.close()">Contact BPI MS</button>
</overlay>