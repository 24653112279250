<section id="stepper" class="bg1" [class.no-bar-img]="!stepperDtlService.showStepNo">
    <div class="container">
        <div class="stepper">
            <h2 class="title montserrat">{{titleStepper}}</h2>
            <div class="bar-img">
                <ul>
                    <li *ngFor="let step of getSteps()" [class.active]="step.stepNo <= formStatus"></li>
                </ul>
            </div>
        </div>
    </div>
    <img class="bg-stepper" src="./assets/images/stepper/background.png">
</section>