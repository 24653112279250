import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';

import { OverlayComponent } from 'src/app/components/common/overlay/overlay';

import { ProductService } from 'src/app/services/product.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { APICallService } from 'src/app/services/api-call.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { formatDate } from '@angular/common';
import { SecurityService } from 'src/app/services/security.service';
import { FormService } from 'src/app/services/form.service';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { PopupService } from 'src/app/services/popup.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

export interface CoverageAmount {
    coverage:string;
    coveragePerYear:string;
    total:string;
};

@Component({
    templateUrl: './email-print.html'
})
export class EmailPrintPolicyComponent implements OnInit {
    @ViewChild('confirmPrintInfo') confirmPrint!: OverlayComponent;
    @ViewChild('emailOverlay') emailOverlay!: OverlayComponent;
    @ViewChild('confirmReEmail') confirmReEmail!: OverlayComponent;

    commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = control.value?.split(',').map((e: any) => e.trim());
        const forbidden = emails?.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { 'email': { value: control.value } } : null;
    };
    
    sameAcctTag: any = true;

    data:any = {
        quoteNo: '12312',
        polNo: '231231',
        status: 'To Be Send',
        category: 'Insurance',

        displayName: '',
        address: '',
        assdNo: ''
    };
    quoteId: String = this.productService.productInfo.quoteId;
    policyId: String = this.productService.productInfo.policyId;
    policyNo: String = this.productService.productInfo.policyNo;
    expDate: String = formatDate(this.productService.productInfo.coverageInfo.expDate, "MM/dd/yyyy h:mm a","en-us").replace('12:00 PM', '12:00 NOON');
    effDate: String = formatDate(this.productService.productInfo.coverageInfo.effDate, "MM/dd/yyyy h:mm a","en-us").replace('12:00 PM', '12:00 NOON');
    lineCd = this.productService.productInfo.lineCd;
    sublineCd = this.productService.productInfo.sublineCd;
    planName = this.productService.productInfo.planInfo.planName;
    yearModel = this.productService.productInfo.vehicleInfo?.yearModelDetail?.value || "";
    make= this.productService.productInfo.vehicleInfo?.makeDetail?.text || "";
    model = this.productService.productInfo.vehicleInfo?.modelDetail?.text || "";
    subModel = this.productService.productInfo.vehicleInfo?.subModelDetail?.text || "";
    salutationName = this.productService.productInfo.customerInfo.clientTypeDetail == "B" 
                   ? this.productService.productInfo.customerInfo.companyName
                   : this.productService.productInfo.customerInfo.firstName;
    pdfUrl: any = '';
    dispName: String = this.productService.productInfo.policyHolderDetails.policy.displayName;
    showLov: boolean = true;
    disableEmail: boolean = false;
    numReloader: number = 0;
    emailSent: boolean = false;

    holderForm = this.fb.group({
        sameAcctTag: [false],
        displayName: [, [Validators.required]],
        address: [],
        assdNo: []
    })

    emailForm = this.fb.group({
        recipient: ['', [Validators.required, this.commaSepEmail]],
        cc: ['', this.commaSepEmail],
        subject: ['', [Validators.required]],
        emailContent: ['', [Validators.required]],
    });

    lovParams:any = {
        userId : this.userService.userId
    }

    forPol:boolean = false;

    from:string= '';

    emailNote:string = '';
    instructions:string = '';

    encryptedFolderName:string = '';
    renewalAgentCd:string = '';

    constructor (
        // PUBLIC
        public formService: FormService,

        // PRIVATE
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private router: Router,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private jsonDataService: JsonDataService,
        private messageBoxService: MessageBoxService,
        private pageValidationService: PageValidationService,
        private paramService: ParameterService,
        private productService: ProductService,
        private securityService: SecurityService,
        private stepperDtlService: StepperDtlService,
        private userService: UserDetailsService,
        private popupService: PopupService,
        private fbAnalyticsService: FacebookEventAnalyticsService,
    ) {
        //this.expDate = formatDate(this.productService.productInfo.coverageInfo.expDate, "MM/dd/YYYY h:mm a","en-us").replace('12:00 PM', '12:00 NOON'); 
        //this.effDate = formatDate(this.productService.productInfo.coverageInfo.effDate, "MM/dd/YYYY h:mm a","en-us").replace('12:00 PM', '12:00 NOON');
        this.pageValidationService.validatePageAccessibility();
        this.productService.productInfo.quoteStatCd === 8 ? this.productService.setQuoteStat(4) : "";

        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = 5;
        this.stepperDtlService.titleStepperDtl = "E-Policy";
        if(!this.userService.sessionExists()){
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: 'toPolicy' }
            ];
        }else{
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: 'toPolicy' },
                { class: "btn3", label: "Continue to Dashboard", action: 'toDashboard' }
            ];
        }
        
        this.stepperDtlService.childInstance = this;
        
        if(userService.accountType!='P'){
            this.holderForm.get('sameAcctTag')?.disable({ onlySelf: true, emitEvent: false });
        }

        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.from = p.params?.from;
        });

        this.emailNote = paramService.paramV('INSTRUCTIONS_EMAIL');
        this.instructions = this.paramService.paramV("INSTRUCTIONS_PRINT_SCRN_POL");
        this.renewalAgentCd = this.paramService.paramV("REN_AGENT_CD");

        setTimeout((e:any)=>
            popupService.popup.open('PR'), 0);
    }

    ngOnInit(): void {
        this.generateReport();
        this.holderForm.get('sameAcctTag')?.valueChanges.subscribe(e => {
            if (e) {
                this.holderForm.patchValue({
                    displayName: '',
                    address: '',
                    assdNo: '',
                })
                setTimeout((e:any)=>{
                    this.holderForm.get('displayName')?.disable({ onlySelf: true, emitEvent: false });
                    this.holderForm.get('address')?.disable({ onlySelf: true, emitEvent: false });
                    this.holderForm.updateValueAndValidity();
                },0)
            } else {
                setTimeout((e:any)=>{
                    this.holderForm.get('displayName')?.enable();
                    this.holderForm.get('address')?.enable();
                    this.holderForm.updateValueAndValidity();
                },0)
            }
            this.showLov = !e;
        })
        if(this.quoteId && this.productService.productInfo.creationProcess == 'renewal'){
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getPolicyHolder({
                quoteId: this.quoteId.toString()
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            res.sameAcctTag = res.sameAcctTag === "Y";
    
                            this.productService.productInfo.quoteNo = res.quoteNo;
                            this.productService.setProductInfo2(this.productService.productInfo);
                            this.emailSent = res.emailSent === "Y";
                            this.holderForm.patchValue({
                                sameAcctTag: res.sameAcctTag,
                                displayName: res.displayName,
                                address: (res.addressPt1 || "") + (res.addressPt2 || "") + (res.addressPt3 || "") + (res.addressPt4 || ""),
                                assdNo: res.assdNo,
                            });
    
                            this.dispName = res.displayName;
                            this.sameAcctTag = res.sameAcctTag
                            this.disableFieldsonStat(res.quoteStatCd);
                        });
                    } else {
                        this.appMessageService.showAppMessage("Unable to get the policy holder. Please try again later", "error");
                    }
                });
            });
        }   

        let emailEditAccts:any[] = this.paramService.paramV('POL_EMAIL_EDIT').split(',');
        if(emailEditAccts.indexOf(this.userService.accountType) == -1){
            this.emailForm.get('subject')?.disable();
            this.emailForm.get('emailContent')?.disable();
        }

        if (this.productService.productInfo.creationProcess == 'policy') {
            if (this.productService.productInfo.sublineCd === 'COM' || this.productService.productInfo.sublineCd === 'PCP') {
                this.fbAnalyticsService.trackEvent('Motor_Epolicy_Purchase')
            } else if (this.productService.productInfo.sublineCd === 'CTPL') {
                this.fbAnalyticsService.trackEvent('Liability_Epolicy_Purchase')
            } else if (this.productService.productInfo.sublineCd === 'FPA') {
                this.fbAnalyticsService.trackEvent('Family_Epolicy_Purchase')
            } else if (this.productService.productInfo.sublineCd === 'IND') {
                this.fbAnalyticsService.trackEvent('Personal_Epolicy_Purchase')
            } else if (this.productService.productInfo.sublineCd === 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_Epolicy_Purchase')
            }
        }
    }

    disableFieldsonStat(status: number): void {
        if (status >= 3) {
            this.holderForm.disable();
            this.showLov = false;
            this.disableEmail = false;
        } else {
            this.holderForm.enable();
            if(this.userService.accountType!='P'){
                this.holderForm.get('sameAcctTag')?.disable({ onlySelf: true, emitEvent: false });
            }
            this.showLov = !this.holderForm.get('sameAcctTag')?.value
            this.stepperDtlService.stepperDtlBtns[2].disabled = true;
            this.disableEmail = true;
        }
    }

    updateDefaultEmail(): void {
        let linkName = this.jsonDataService.data.quickLinks.find((a: any) => a.lineCd === this.productService.productInfo.lineCd && a.sublineCd === this.productService.productInfo.sublineCd && a.planCd === this.productService.productInfo.planInfo.planCd && a.linkNo == 1)?.linkName || '';
        let email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) => 
            policyEmail.lineCd == this.lineCd && 
            policyEmail.sublineCd == this.sublineCd && 
            policyEmail.emailType == 3)[0];
        let emailSubject = email.emailSubject.split('P_DISPLAY_NAME').join(this.dispName);
            emailSubject = emailSubject.split('P_POLICY_NUMBER').join(this.policyNo);
            emailSubject = emailSubject.split('P_FIRST_NAME').join(this.productService.productInfo?.customerInfo?.firstName);
        let emailBody = email.emailBody.split('P_VEHICLE_INFORMATION').join(this.yearModel?.toString()+' '+this.make+' '+this.model+' '+this.subModel);
            emailBody = emailBody.split('P_POLICY_NUMBER').join(this.policyNo);
            emailBody = emailBody.split('P_VALIDITY_DATE').join(this.effDate+' - '+this.expDate);
            emailBody = emailBody.split('P_FIRST_NAME').join(this.salutationName);
            emailBody = emailBody.split('P_PLAN_DESC').join(this.planName);
            emailBody = emailBody.split('P_LINK').join(linkName ? `You may also download the complete ${linkName}.` : '');

            let recipient = '';
            let cc = '';
            if(this.userService.accountType == 'P'){
                recipient = this.userService.email;
            }else{
                recipient = this.productService.productInfo?.customerInfo?.emailAddress;
                cc = this.userService.email;
            }

            if(this.productService.productInfo.creationProcess=='renewal') {
                recipient = this.productService.productInfo?.customerInfo?.emailAddress;
                cc = this.userService.email;
            }

        this.emailForm.patchValue({ 
            subject: emailSubject, 
            emailContent: emailBody,
            recipient: recipient,
            cc: cc
        });
    }

    openEmail(): void {
        if (!this.disableEmail) {
            this.updateDefaultEmail();
            this.emailOverlay.open();
        }
    }

    onLovOk(data: any): void {
        this.holderForm.patchValue({
            displayName: data['displayName'],
            address: data['addressPt1'],
            assdNo: data['assdNo'],
        });
        this.holderForm.markAsDirty();
    }

    savePolicyHolder():void {
        if (this.holderForm.status === "VALID") {
            const form = this.holderForm.getRawValue();
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.savePolicyHolder({
                quoteId: this.quoteId,
                sameAcctTag: form.sameAcctTag,
                displayName: form.displayName,
                address: form.address,
                assdNo: form.assdNo
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.generateReport();
                        this.holderForm.markAsPristine();
                        this.appMessageService.showAppMessage("Successfully saved.", "success");
                    } else {
                        this.appMessageService.showAppMessage("Error saving your policy holder.", "error");
                    }
                });
            });
        } else {
            this.jsonDataService.contorlMsgBox("info", "There are missing information. Please provide necessary information needed.", "Notice");
        }
    }

    onClickPrint(): void {
        window.open(this.pdfUrl);
    }

    private generateReport(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.printPDF({
            reportType: "BMR002",
            policyId: this.policyId
        }).subscribe((res:any) => {
            this.jsonDataService.contorlLoading(false);
            if (res.status === "SUCCESS") {
                let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                let link = response.link.split("/");
                this.encryptedFolderName = response.link.split("/")[3];
                link[3] = encodeURIComponent(link[3]);
                link = link.join("/");

                const bssid = sessionStorage.getItem("bssid") || "1";
                const encryptedFolderName = link.split("/")[3];
                const token = encryptedFolderName.substr(30, 1) + bssid.substr(30, 1) +
                              encryptedFolderName.substr(10, 1) + bssid.substr(10, 1) +
                              encryptedFolderName.substr(40, 1) + bssid.substr(40, 1) +
                              encryptedFolderName.substr(15, 1) + bssid.substr(15, 1) +
                              encryptedFolderName.substr(35, 1) + bssid.substr(35, 1) +
                              encryptedFolderName.substr( 5, 1) + bssid.substr( 5, 1) +
                              encryptedFolderName.substr(25, 1) + bssid.substr(25, 1) +
                              encryptedFolderName.substr(20, 1) + bssid.substr(20, 1);

                const param = this.jsonDataService.encrypt({
                    uid: sessionStorage.getItem("bssid") || "",
                    token: token,
                    dateCreated: new Date()
                });

                link = link.split("/");
                link[4] = link[4].split(".")[0] + " - " + this.policyId + ".pdf";
                link = link.join("/");
                this.pdfUrl = `https://${link}?v=${this.randomizer()}&report=${param}`;

                this.activatedRoute.queryParamMap.subscribe((p: any) => {
                    if (p.params?.se) {
                        this.updateDefaultEmail();
                        this.sendMail(true);
                        this.location.replaceState("/policy/email-print");
                    }
                });
            } else {
                this.appMessageService.showAppMessage("There's something wrong generating the report. Please contact BPI MS for more information.", "error");
            }
        });
    }

    randomizer(): number {
        let num: number = Math.floor((Math.random() * 100) + 1);
        while (true) {
            if (num == this.numReloader) {
                num = Math.floor((Math.random() * 100) + 1);
            } else {
                this.numReloader = num;
                break;
            }
        }
        return num;
    }

    public sendMail(bgProcess: boolean = false): void {
        let header = null;
        let emailType = 3;
        const productInfo = this.productService.productInfo;
        header = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
            policyEmail.lineCd == productInfo.lineCd &&
            policyEmail.sublineCd == productInfo.sublineCd &&
            policyEmail.emailType == emailType)[0]?.headerImgPath;
        header = "https://"+ window.location.hostname + header;
        header = header.replace(/\\/g,"/");

        this.emailForm.get('recipient')?.setValue(this.emailForm.get('recipient')?.value.trim());
        this.emailForm.get('cc')?.setValue(this.emailForm.get('cc')?.value.trim());

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendMail({
            ...this.emailForm.getRawValue(), 
            policyId: this.policyId,
            emailType: emailType,
            userId: this.userService.userId,
            lineCd: this.productService.productInfo.lineCd,
            sublineCd: this.productService.productInfo.sublineCd,
            folderName: this.encryptedFolderName,
            header: header
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                this.securityService.hasValidCsrfToken(response, () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (response.status === "SUCCESS") {
                        this.emailSent = true;
                        bgProcess ? "" : this.messageBoxService.show1("S", res.message, "Close", "");
                    } else {
                        bgProcess ? "" : this.messageBoxService.show1("E", res.message, "Close", "");
                    }
                });
            });
        });
        this.emailOverlay.close();
    }

    validateReEmail(): void{
        if(this.emailSent){
            this.confirmReEmail.open();
        }else{
            this.sendMail();
        }
    }

    toDashboard(){
        this.router.navigate(["/dashboard"]);
    }

    toPolicy(){
        if(this.productService.productInfo.creationProcess=='renewal'&&this.productService.productInfo.polStatCd>2){
        let productInfo = this.productService.productInfo;
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getRenewalInfo({
            policyId: productInfo.policyId
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        sessionStorage.setItem("rid", res.recordId);
                        
                        //Check Report Type
                        if(productInfo.lineCd=="MC"){
                            res.reportName = "BMR002";
                        } else {
                            if(productInfo.clientCd=='BPIMS'){
                                res.reportName = "BMR011";
                            }
                            else {
                                if(productInfo.agentCd==this.renewalAgentCd){
                                    res.reportName = 'BMR008';
                                }
                                else{
                                    res.reportName = 'BMR010';
                                }
                            }
                        }
                        
                        this.apiCallService.printPDF({
                            reportType: res.reportName,
                            policyId: productInfo.policyId
                        }).subscribe((data:any) => {
                            if (data.status === 'SUCCESS') {
                                let response = JSON.parse(this.jsonDataService.decrypt(data.content));
                                res.folderName = response.link.split("/")[3];
                                if(res.paymentInfo.tranNo!=''&&res.paymentInfo.paymentMode!=''&&res.paymentInfo.paytDate!=''&&res.polStatCd!=5&&res.polStatCd>2){
                                    res.polStatCd = 5;
                                    res.polStatDesc = this.jsonDataService.data.refCds.filter((ref: any) => ref.rvLowValue == 5 && ref.rvDomain == 'BMP011.POL_STAT_CD')[0]?.rvMeaning;
                                    this.apiCallService.updatePolicyStatus({
                                        policyId: productInfo.policyId,
                                        newStatus: 5
                                    }).subscribe((response:any) => {
                                        this.securityService.checkRequestKeyResponse(response, () => {
                                        if (response.status === "SUCCESS") {
                                            this.jsonDataService.contorlLoading(false);
                                            this.productService.setProductInfo2(res);
                                            this.productService.setProductInfo("creationProcess", "renewal");
                                            this.router.navigate(["/policy/summary"]);
                                        }
                                        else{
                                            this.jsonDataService.contorlLoading(false);
                                            this.productService.setProductInfo2(res);
                                            this.productService.setProductInfo("creationProcess", "renewal");
                                            this.router.navigate(["/policy/summary"]);
                                        }
                                        });
                                    });
                                }
                                else{
                                this.jsonDataService.contorlLoading(false);
                                this.productService.setProductInfo2(res);
                                this.productService.setProductInfo("creationProcess", "renewal");
                                this.router.navigate(["/policy/summary"]);
                                }
                            } else {
                                this.appMessageService.showAppMessage("There's something wrong getting the report. Please contact BPI MS for more information.", "error");
                            }
                        });
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
      	});
        }
        else {
            this.router.navigate(["/policy/summary"]);
        }
        // if(this.from == 'summary'){
        //     this.router.navigate(["/policy/summary"]);
        // }else{
        //     this.router.navigate(["/policy/acceptance"]);
        // }
    }
}
