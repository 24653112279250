import { Component, OnInit, ElementRef, QueryList, TemplateRef, ViewChild, ViewChildren, HostListener, Directive, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { FormService } from 'src/app/services/form.service';
import { SecurityService } from 'src/app/services/security.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';
import * as moment from 'moment';

declare function setInputFormatter(): any;

@Component({
    templateUrl: './payment.html',
})
export class PaymentComponent implements OnInit {

    paytBreakdown: any[] = [];
    totalAmount: number = 0;

    paytOptions: any[] = [];
    selectedPaytOptId: number = 0;
    //selectedPaytOptId!: number;
    paynamicsCd: string = "";
    
    @ViewChildren('otpInputRef') otpInputRefList!: QueryList<ElementRef>;

    otpIconLoc: string = '';

    // CREDIT DEBIT FORM
    creditDebitForm: FormGroup = this.formBuilder.group({
        installmentType: [""],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        address: ["", Validators.required],
        email: ["", Validators.required],
        city: ["", Validators.required],
        postalCode: ["", Validators.required]
    });

    // BPI
    bpiForm: FormGroup = this.formBuilder.group({
        account: [],
        accountNumber: ["", Validators.required],
        accountNumberToken: ["", Validators.required],
        accountPreferredName: [],
        accountType: [],
    });

    // PAYROLL DEDUCTION FORM
    payrollDeductionForm: FormGroup = this.formBuilder.group({
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        employeeNo: ["", Validators.required],
        email: ["", Validators.required]
    });

    productInfo:any = {};
    bpilAccounts: any[] = [];
    bpiToken: String = '';
    bpiRefreshToken: String = '';
    transactionId: any = '';
    bpiReqId: any = '';
    noBpiAcct: boolean = true;
    loadingAccts: boolean = false;
    sendingOtp: boolean = false;
    showSendPaymentLinkBtn: boolean = false;
    willCheckCocaf: boolean = false;

    paytUnderTaking:string  = '';
    isAccepted:boolean = false;
    termsText:String = '';
    genLinkText:string = "";
    insuringTerms: string = "";
    insuringTermsGrp: string = "";
    termsRenewalText: string = "";

    paymentRetrievalRetries: number = 0;
    paymentRetrievalRetriesThreshold: number = 4;
    paymentRetrievalHasError: boolean = false;

    otpObj: any = {
        mobileNumber: '',
        mobileNumberToken: ''
    }

    public paytTerms: any;

    constructor (
        // PUBLIC
        public dialog: MatDialog,
        public formService: FormService,
        public jsonDataService: JsonDataService,
        public productService: ProductService,
        public userDetailsService: UserDetailsService,

        // PRIVATE
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private dialogBoxService: DialogBoxService,
        private messageBoxService: MessageBoxService,
        private pageValidationService: PageValidationService,
        private parameterService: ParameterService,
        private securityService: SecurityService,
        private stepperDtlService: StepperDtlService,
        private fbAnalyticsService: FacebookEventAnalyticsService,
    ) {
        if (this.fromPaynamics()) {
            this.appMessageService.showAppMessage("Retrieving payment status.", "info");
            this.jsonDataService.contorlLoading(true);
            this.paymentRetrievalRetries = 0;
            this.paymentRetrievalHasError = false;
            this.getPaymentDetails(this.productService.productInfo.quoteId);
        }

        this.pageValidationService.validatePageAccessibility();
        this.productInfo = this.productService.productInfo;
        this.generateSummaryAmts();
        this.setPaytOptions();
        this.initializeStepperDetail();

        this.genLinkText = this.parameterService.paramV("GEN_LINK_TEXT") || "";
        const genLinkUserType = this.parameterService.paramV("GEN_LINK_USER_TYPE")?.split(",") || [];
        this.showSendPaymentLinkBtn = genLinkUserType.indexOf(this.userDetailsService.accountType) >= 0;

        if (this.productInfo.fromLink === 'renewal')
            this.termsRenewalText = this.parameterService.paramV("ACCEPT_RENEWAL_PAYT") ?? "null : No Setup in [ACCEPT_RENEWAL_PAYT]";

        this.paytTerms = this.jsonDataService.data.userType.filter((a: any) =>
            a.userType === this.userDetailsService.accountType
        )[0];

        this.setPaymentTerms();

        this.insuringTermsGrp = this.parameterService.paramV("GPA_TERMS_PATH");
        this.insuringTerms = this.jsonDataService.data.plans.filter((e: any) =>
            e.lineCd == this.productInfo.lineCd
            && e.sublineCd == this.productInfo.sublineCd
            && e.planCd == this.productInfo.planInfo.planCd
        )[0]?.termsPath;

        let defPaytOption = this.paytOptions.filter((a: any) => a.defaultTag === "Y")[0];
        defPaytOption && this.setSelectedPaymentMethod(defPaytOption);

        this.bpiForm.get('account')?.valueChanges.subscribe(e => {
            this.bpiForm.patchValue(e, { emitEvent: false });
        });
        this.bpiForm.get('accountPreferredName')?.disable();
        this.bpiForm.get('accountType')?.disable();

        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params.params) {
                if (p.params.from === "bpi") {
                    this.selectedPaytOptId = 2;
                    this.paynamicsCd = "";
                    let params: any = jsonDataService.parseBase64(p.params?.params);
                    params = JSON.parse(JSON.parse(jsonDataService.decrypt(params)));
                    this.bpiRefreshToken = params.refresh_token;
                    this.bpiToken = params.access_token;
                    this.setBPIAccounts();
                }
            }
        });

        const customerInfo = this.productService.productInfo.customerInfo;
        if (customerInfo) {
            this.creditDebitForm.controls["firstName"].setValue(customerInfo.firstName);
            this.creditDebitForm.controls["lastName"].setValue(customerInfo.lastName);
            this.creditDebitForm.controls["address"].setValue(customerInfo.permanentAddress);
            this.creditDebitForm.controls["email"].setValue(customerInfo?.emailAddress);
            this.creditDebitForm.controls["city"].setValue(customerInfo.permanentCityDetail === "" ? customerInfo.cityDesc : customerInfo.permanentCityDetail?.text);
            this.creditDebitForm.controls["postalCode"].setValue(customerInfo.permanentPostalDetail?.text?.split(" - ")[0] || customerInfo.postalDesc);

            this.payrollDeductionForm.controls["firstName"].setValue(customerInfo.firstName);
            this.payrollDeductionForm.controls["lastName"].setValue(customerInfo.lastName);
            this.payrollDeductionForm.controls["email"].setValue(customerInfo?.emailAddress);
        }
    }

    ngOnInit(): void {
        setInputFormatter();
    }

    private setPaymentTerms(): void {
        if (this.userDetailsService.accountType !== "P") {
            let termsLinkConnector = "";
            let termsLink = "";

            if (this.paytTerms?.paytTermsPath) {
                termsLinkConnector = "Premium Payment Undertaking";
                termsLink = "<a href='" + this.paytTerms.paytTermsPath + "' target='_blank'\>" + termsLinkConnector + "</a>";
                this.termsText = this.parameterService.paramV("ACCEPT_PREMIUM_PAYT");
            }

            if (this.selectedPaytOptId === 6) {
                termsLinkConnector = "Payroll Deduction Undertaking";
                termsLink = "<a href='" + this.parameterService.paramV("PAYROLL_DEDUCTION_UNDERTAKING") + "' target='_blank'\>" + termsLinkConnector + "</a>";
                this.termsText = this.parameterService.paramV("PAYROLL_DEDUCTION_TERMS");
            }

            this.termsText = this.termsText.replace(termsLinkConnector, termsLink);
            this.updateIsAccepted(false);
        }
    }

    private setPaytOptions(): void {
        for (let counter=0; counter < this.jsonDataService.data.paytOptPerLine.length; counter++) {
            if (   this.jsonDataService.data.paytOptPerLine[counter].lineCd === this.productInfo.lineCd
                && this.jsonDataService.data.paytOptPerLine[counter].sublineCd === this.productInfo.sublineCd
                && this.jsonDataService.data.paytOptPerLine[counter].planCd === this.productInfo.planInfo.planCd
                && this.jsonDataService.data.paytOptPerLine[counter].userType === (this.userDetailsService.accountType || "P")
            ) {
                for (let counter2=0; counter2 < this.jsonDataService.data.paymentOptions.length; counter2++) {
                    if (   this.jsonDataService.data.paymentOptions[counter2].paytCd === this.jsonDataService.data.paytOptPerLine[counter].paytCd
                        && this.totalAmount >= (this.jsonDataService.data.paymentOptions[counter2].minPaytAmt || 0)
                    ) {
                        this.paytOptions.push(this.jsonDataService.data.paymentOptions[counter2]);
                    }
                }
            }
        }
    }

    initializeStepperDetail(): void {

        if (this.productService.productInfo.fromLink) { 
            this.isAccepted = true;
        }
        
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = 4;
        this.stepperDtlService.titleStepperDtl = "Payment";

        if ((this.userDetailsService.accountType || "P") !== "P") {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move", actionParam: "back" },
                { class: "btn5", label: "Skip", action: "move", actionParam: "next" },
                { class: "btn2", label: "Pay",  action: "pay",  disabled: true }
            ];
        } else {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move", actionParam: "back" },
                //{ class: "btn2", label: "Pay",  action: "pay",  disabled: (!this.isAccepted && this.userDetailsService.accountType !== "P") || this.paytOptions.length === 0 }
                { class: "btn2", label: "Pay",  action: "pay",  disabled: (!this.isAccepted && this.userDetailsService.accountType !== "P") || this.paytOptions.length === 0 || this.selectedPaytOptId === 0 }
            ];
        }
    }

    generateSummaryAmts() {
        const data = this.productService.productInfo;

        let premium = 0;
        if (data.lineCd === "MC") {
            premium = data.ara.map((a: any) => a.premAmt).reduce((a: any, b: any) => a + b);
        } else {
            for (const coverage of data.coverages) {
                premium += coverage.premAmt;
            }
        }

        this.totalAmount += premium;
        this.paytBreakdown.push({ name: "Premium", value: premium });

        let othChargesAmt = 0;
        for (let tax of this.jsonDataService.data.tax.filter((a: any) => data.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
            let taxAmt = 0;

            if (data.lineCd === "MC") {
                for (let key of data.ara.map((b: any) => b.year)) {
                    taxAmt += data.taxes.filter((a: any) => a.year == key && a.taxCd == tax.taxCd)[0].taxAmt;
                }
            } else if(data.lineCd === "HA") {
                data.taxes.filter((a: any) => a.taxCd === tax.taxCd).map((b: any) => {
                    taxAmt += b.taxAmt;
                });
            } else {
                taxAmt = data.taxes.filter((a: any) => a.taxCd === tax.taxCd)[0].taxAmt;
            }

            if (tax.ocTag === "N") {
                this.paytBreakdown.push({ name: tax.taxName, value: taxAmt });
            } else {
                othChargesAmt += taxAmt;
            }

            this.totalAmount += taxAmt;
        }

        if (othChargesAmt) {
            this.paytBreakdown.push({ name: "Other Charges", value: othChargesAmt });
        }
    }

    move(action:string):void {
        if (action === "next") {
            if((this.productInfo.polStatCd==3||this.productInfo.polStatCd==6)&&this.productInfo.creationProcess==='renewal'){
                if((this.productInfo.customerInfo.riskProfilingDetail.value == 1||this.productInfo.customerInfo.blockedTag=='Y'||this.productInfo.customerInfo.totalLossTag=='Y')&&this.productInfo.creationProcess === 'renewal'){
                    if (this.productInfo.lineCd === 'MC') {
                        let vehicleInfo = this.productService.productInfo.vehicleInfo;
                        this.dialogBoxService.open({
                            messageType: "I",
                            content: 'Payment is subject for review and approval. One of our representatives will contact you as soon as possible or you may contact BPI MS for more information.',
                            buttons: [{
                                text: "Contact BPI MS",
                                action: () => {
                                    const url = this.router.serializeUrl(
                                        this.router.createUrlTree(["/contact-us"], {
                                            queryParams: {
                                                brand: vehicleInfo.makeDetail.text,
                                                yearModel: vehicleInfo.yearModelDetail.value,
                                                model: vehicleInfo.modelDetail.text + " " + vehicleInfo.subModelDetail.text,
                                                srp: vehicleInfo.purchasePrice
                                            }
                                        })
                                    );
                                    this.router.navigateByUrl(url);
                                }
                            }, {
                                text: "Close"
                            }]
                        });
                    } else {
                        this.dialogBoxService.open({
                            messageType: "I",
                            content: 'Payment is subject for review and approval. One of our representatives will contact you as soon as possible or you may contact BPI MS for more information.',
                            buttons: [{
                                text: "Contact BPI MS",
                                action: () => {
                                    const url = this.router.serializeUrl(
                                        this.router.createUrlTree(["/contact-us"], {})
                                    );
                                    this.router.navigateByUrl(url);
                                }
                            }, {
                                text: "Close"
                            }]
                        });
                    }
                } else {
                    this.jsonDataService.contorlLoading(true);
                    this.apiCallService.updatePolicyStatus({
                        policyId: this.productInfo.policyId,
                        newStatus: 4
                    }).subscribe((response:any) => {
                        this.securityService.checkRequestKeyResponse(response, () => {
                            if (response.status === "SUCCESS") {
                                this.jsonDataService.contorlLoading(false);
                                this.productInfo.polStatDesc = "Renewed (Without Payment)";
                                this.productInfo.polStatCd = 4;
                                this.router.navigate(["/policy/email-print"]);
                            }
                        });
                    });
                }
            } else {
                this.router.navigate(["/policy/email-print"]);
            }
        } else {
            if (this.productInfo.policyNo && (this.productInfo.polStatCd === 2 || this.productInfo.polStatCd === 3)) {
                this.router.navigate(["/policy/summary"]);
            } else {
                if(this.productInfo.creationProcess==='renewal'){
                    this.router.navigate(["/policy/summary"]);
                }
                else{
                    this.router.navigate(["/policy/acceptance"]);
                }
            }
        }
    }

    setBPIAccounts():void {
        this.loadingAccts = true;
        this.apiCallService.getBpiPaymentAccounts({
            token: this.bpiToken,
            refresh_token: this.bpiRefreshToken
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if(res.status != 'error'){
                            if (res.body?.transactionalAccounts) {
                                this.noBpiAcct = false;
                                this.bpilAccounts = res.body.transactionalAccounts;
                                this.bpiForm.get('account')?.setValue(this.bpilAccounts.filter((a: any) => a.displayOrder == 1)[0]);
                            } else {
                                this.loadingAccts = false;
                            }
                        }else{
                            this.showError(res);
                            this.loadingAccts = false;
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in retrieving BPI payment accounts. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    @ViewChild('otpTemplate') otpTemplateRef!: TemplateRef<any>;
    otpDialogRef!: MatDialogRef<any>;

    otpForm: FormGroup = this.formBuilder.group({
        'otp1': [''],
        'otp2': [''],
        'otp3': [''],
        'otp4': [''],
        'otp5': [''],
        'otp6': [''],
    });

    otpDisplay: string = '';
    otpLoading: boolean = false;
    otpError: string = '';

    public pay(): void {
        if (this.selectedPaytOptId === 2) {
            this.openOtpScreen()
        } else if (this.selectedPaytOptId === 6) {
            this.processPayrollDeductionPayment();
        } else {
            this.processPaymentThruPaynamics();
        }
    }

    private processPayrollDeductionPayment(): void {
        if (this.payrollDeductionForm.valid) {
            // this.fbAnalyticsService.trackEvent('AddPaymentInfo');
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.processPayrollDeductionPayment({
                policyId: this.productService.productInfo.policyId,
                fname: this.payrollDeductionForm.controls["firstName"].value,
                lname: this.payrollDeductionForm.controls["lastName"].value,
                employeeNo: this.payrollDeductionForm.controls["employeeNo"].value,
                email: this.payrollDeductionForm.controls["email"].value
            }).subscribe((response: any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        if (this.productInfo.sublineCd === 'COM' || this.productInfo.sublineCd === 'PCP') {
                            this.fbAnalyticsService.trackEvent('Motor_Epolicy_Initiate Checkout')
                        } else if (this.productInfo.sublineCd === 'CTPL') {
                            this.fbAnalyticsService.trackEvent('Liability_Epolicy_Initiate Checkout')
                        } else if (this.productInfo.sublineCd === 'FPA') {
                            this.fbAnalyticsService.trackEvent('Family_Epolicy_Initiate Checkout')
                        } else if (this.productInfo.sublineCd === 'IND') {
                            this.fbAnalyticsService.trackEvent('Personal_Epolicy_Initiate Checkout')
                        } else if (this.productInfo.sublineCd === 'HCA') {
                            this.fbAnalyticsService.trackEvent('Home_Epolicy_Initiate Checkout')
                        }

                        // this.fbAnalyticsService.trackEventWithData('Purchase', {value: 0.00, currency: 'USD'});
                        this.messageBoxService.show1("S", this.parameterService.paramV("PAYROLL_DEDUCTION_SUCCESS"), "Ok", () => {
                            this.router.navigate(["/policy/email-print"]);
                        });
                    } else {
                        this.appMessageService.showAppMessage(response.message, "error");
                    }
                });
            });
        } else {
            this.messageBoxService.show1("E", "There are missing information. Please complete all fields.", "Ok", "");
        }
    }
//
    private processPaymentThruPaynamics(): void {
        if (this.creditDebitForm.valid) {
            // this.fbAnalyticsService.trackEvent('AddPaymentInfo');
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.createPaymentRequest({
                policyId: this.productService.productInfo.policyId,
                paytOptId: this.selectedPaytOptId,
                installmentType: this.creditDebitForm.controls["installmentType"].value,
                fname: this.creditDebitForm.controls["firstName"].value,
                lname: this.creditDebitForm.controls["lastName"].value,
                address: this.creditDebitForm.controls["address"].value,
                email: this.creditDebitForm.controls["email"].value,
                city: this.creditDebitForm.controls["city"].value,
                postalCode: this.creditDebitForm.controls["postalCode"].value
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (this.productInfo.sublineCd === 'COM' || this.productInfo.sublineCd === 'PCP') {
                                this.fbAnalyticsService.trackEvent('Motor_Epolicy_Initiate Checkout')
                            } else if (this.productInfo.sublineCd === 'CTPL') {
                                this.fbAnalyticsService.trackEvent('Liability_Epolicy_Initiate Checkout')
                            } else if (this.productInfo.sublineCd === 'FPA') {
                                this.fbAnalyticsService.trackEvent('Family_Epolicy_Initiate Checkout')
                            } else if (this.productInfo.sublineCd === 'IND') {
                                this.fbAnalyticsService.trackEvent('Personal_Epolicy_Initiate Checkout')
                            } else if (this.productInfo.sublineCd === 'HCA') {
                                this.fbAnalyticsService.trackEvent('Home_Epolicy_Initiate Checkout')
                            }

                            // this.fbAnalyticsService.trackEventWithData('Purchase', {value: 0.00, currency: 'USD'});
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            window.location.href = res.redirectUrl;

                            // const form = document.createElement("form");
                            // form.method = "post";
                            // form.action = res.paymentGateway;

                            // const params:any = { paymentRequest: res.paymentRequest };
                            // for (const key in params) {
                            //     if (Object.prototype.hasOwnProperty.call(params, key)) {
                            //         const hiddenField = document.createElement("input");
                            //         hiddenField.type = "hidden";
                            //         hiddenField.name = key;
                            //         hiddenField.value = params[key];
                            //         form.appendChild(hiddenField);
                            //     }
                            // }

                            // document.body.appendChild(form);
                            // form.submit();
                        });
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage(response.message, "error");
                    }
                });
            });
        } else {
            this.messageBoxService.show1("Error", "There are missing information. Please complete all fields.", "Ok", "");
        }
    }

    public displayToConsole(option: string): void {
        this.messageBoxService.showMsgBox = false;
    }

    public openPaytLinkSendOpts(): void {
        if (this.selectedPaytOptId !== 6) {
            const mobile = this.productInfo.customerInfo.mobileNo || "";
            const email = this.productInfo.customerInfo.emailAddress || "";
    
            this.messageBoxService.show1("Choose sending option", `
                <div class="payt-link-send-opts">
                    ${ mobile ? `
                    <div id="mobile" class="payt-link-send-opt">
                        <p>Text</p><span>${mobile}</span>
                    </div>` : ""}
                    ${ email ? `
                    <div id="email" class="payt-link-send-opt">
                        <p>Email</p><span>${email}</span>
                    </div>` : ""}
                    ${ mobile && email ? `
                    <div id="both" class="payt-link-send-opt">
                        <p>Both</p>
                    </div>` : ""}
                </div>
            `, "Close", "");
    
            setTimeout(() => {
                const paytLinkSendOptElements: any = document.getElementsByClassName("payt-link-send-opt");
                for (const paytLinkSendOpt of paytLinkSendOptElements) {
                    paytLinkSendOpt.addEventListener("click", () => {
                        this.sendPaymentLinkToCustomer(paytLinkSendOpt.id);
                        this.messageBoxService.showMsgBox = false;
                    });
                }
            }, 0);
        } else {
            this.sendNotificationToCustomer();
        }
    }

    private sendPaymentLinkToCustomer(option: string): void {
        let sendTo = "";
        if (option === "mobile") {
            sendTo = "mobile number";
        } else if (option === "email") {
            sendTo = "email address";
        } else {
            sendTo = "mobile number and email address";
        }

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendPaymentLinkToCustomer({
            policyId: this.productInfo.policyId,
            option,
            type: "paymentLink",
            empNo: ""
        }).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.appMessageService.showAppMessage(`Payment instructions has been sent to the policy holder's ${sendTo}.`, "success");
                } else {
                    this.appMessageService.showAppMessage("There's something wrong sending the payment instructions to customer. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    private sendNotificationToCustomer(): void {
        this.jsonDataService.contorlLoading(true);
        const form = this.payrollDeductionForm.value;
        if(this.payrollDeductionForm.valid&&form.employeeNo.length<=10){
            this.apiCallService.sendPaymentLinkToCustomer({
                policyId: this.productInfo.policyId,
                option: "email",
                type: "notification",
                empNo: form.employeeNo
            }).subscribe((response: any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.appMessageService.showAppMessage("Notification has been sent to the policy holder's email address.", "success");
                    } else {
                        this.appMessageService.showAppMessage("There's something wrong sending the notification to customer. Please contact BPI MS for more information.", "error");
                    }
                });
            });
        }
        else if(form.employeeNo.length>10){
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage("Employee Number must not be more than 10 characters.", "info");
        }
        else{
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage("Please fill all the required fields.", "info");
        }
    }

    setSelectedPaymentMethod(paymentOption: any): void {
        if (paymentOption.paytCd === this.selectedPaytOptId) {
            this.selectedPaytOptId = 0;
            this.paynamicsCd = "";
            this.setPaymentTerms();
            this.creditDebitForm.controls["installmentType"].setValue(paymentOption.paytCd === 4 ? 1 : "");
            this.creditDebitForm.controls["city"].setValue(this.productService.productInfo.customerInfo.permanentCityDetail === "" ? this.productService.productInfo.customerInfo.cityDesc : this.productService.productInfo.customerInfo.permanentCityDetail.text);
        } else { 
            this.selectedPaytOptId = paymentOption.paytCd;
            this.paynamicsCd = paymentOption.paynamicsCd;
            this.setPaymentTerms();
            this.creditDebitForm.controls["installmentType"].setValue(paymentOption.paytCd === 4 ? 1 : "");
            this.creditDebitForm.controls["city"].setValue(this.productService.productInfo.customerInfo.permanentCityDetail === "" ? this.productService.productInfo.customerInfo.cityDesc : this.productService.productInfo.customerInfo.permanentCityDetail.text);
        }
        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: "move", actionParam: "back" },
            { class: "btn2", label: "Pay",  action: "pay",  disabled: (!this.isAccepted && this.userDetailsService.accountType !== 'P') || this.paytOptions.length === 0 || this.selectedPaytOptId === 0 || (this.noBpiAcct && this.selectedPaytOptId === 2) }
        ];
    }

    openOtpScreen(): void {
        this.otpDisplay = 'processing';
        this.otpLoading = false;
        this.otpForm.setValue({
            'otp1': '',
            'otp2': '',
            'otp3': '',
            'otp4': '',
            'otp5': '',
            'otp6': ''
        });
        this.otpDialogRef = this.dialog.open(this.otpTemplateRef, { disableClose: true });

        this.apiCallService.initBPIPayment({
            policyId: this.productService.productInfo.policyId,
            instNo: 1,
            token: this.bpiToken,
            accountNumberToken: this.bpiForm.get('accountNumberToken')?.value
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        res.content = JSON.parse(res.content);
    
                        this.otpDisplay = "send";
                        if (res.content?.code == 0) {
                            this.otpObj = res.content?.body;
                            this.transactionId = res.transactionid;
                            this.bpiReqId = res.reqId;
                        } else {
                            this.showError(res.content);
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in initializing BPI payment. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    sendOTP():void {
        this.otpDisplay === "send" ? this.otpLoading = true : this.sendingOtp = true;
        this.apiCallService.otpBPIPayment({
            token: this.bpiToken,
            transactionId: this.transactionId,
            mobileNumberToken: this.otpObj.mobileNumberToken,
            reqId: this.bpiReqId
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        this.sendingOtp = false;
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
    
                        if (res?.code == 0 || res?.code?.substr(-3) == '999') {
                            this.otpDisplay = 'input'
                            this.otpLoading = false;
                            setTimeout(() => {
                                this.otpInputRefList.get(0)?.nativeElement.focus()
                            }, 1)
                        } else {
                            this.showError(res);
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in OTP. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    submitOTP():void {
        const otpValue = this.otpForm.getRawValue();
        const otp = "" + otpValue.otp1 + otpValue.otp2 + otpValue.otp3 + otpValue.otp4 + otpValue.otp5 + otpValue.otp6;
        this.otpLoading = true;
        this.apiCallService.processBPIPayment({
            token: this.bpiToken,
            transactionId: this.transactionId,
            otp: otp,
            reqId: this.bpiReqId,
            userId: this.userDetailsService.userId
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        this.otpLoading = false;
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
    
                        if (res?.code == 0) {
                            // this.checkStatus();
                            this.otpDialogRef.close();
                            this.messageBoxService.show1(
                                "S", `Thank you for your payment. Here is your transaction number <b>${res.body?.confirmationNumber}</b>.<br>An email will be sent to your email address for this transaction.`,
                                "Close", () => { this.move("next"); }
                            );
                        } else {
                            this.showError(res);
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in with the payment. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    // NOT YET USED. FOR REFERENCE ONLY IN CASE OF FUTURE USE.
    // checkStatus(): void {
    //     this.otpDisplay = "processing";
    //     this.apiCallService.statusBPIPayment({
    //         policyId: this.productService.productInfo.policyId,
    //         instNo: 1,
    //         token: this.bpiToken,
    //         reqId: this.bpiReqId,
    //         transactionId: this.transactionId
    //     }).subscribe((response:any) => {
    //     })
    // }

    onOtpInputFocus(event: any) {
        this.otpForm.get('otp' + event)?.setValue('');
    }

    toBpiLogin():void {
        this.loadingAccts = true;
        this.apiCallService.redirectToBPIPayment().subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        window.location.href = JSON.parse(this.jsonDataService.decrypt(response.response));
                    });
                } else {
                    this.appMessageService.showAppMessage(response.message, "error");
                }
            });
        });
    }

    showError(token: any): void {
        this.otpError = "";
        let category = token.code?.substr(3, 2);
        if (category === "SC" || category === "IE" || category === "BE" || token.code?.substr(-3) === "999") {
            this.otpDialogRef?.close();
            // this.jsonDataService.contorlMsgBox('error', token.description);
            this.appMessageService.showAppMessage(token.description, "error");
        } else if (token.code === "FTUBE003") {
            this.otpError = token.description;
        } else {
            this.otpDialogRef?.close();
            // this.jsonDataService.contorlMsgBox('error');
            this.appMessageService.showAppMessage("There is something wrong in processing your payment. Please try again later.", "error");
        }
    }

    updateIsAccepted(event: any): void {
        this.isAccepted = event;
        if ((this.userDetailsService.accountType || "P") !== "P") {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move", actionParam: "back" },
                { class: "btn5", label: "Skip", action: "move", actionParam: "next" },
                //{ class: "btn2", label: "Pay",  action: "pay",  disabled: !this.isAccepted || this.paytOptions.length === 0 }
                { class: "btn2", label: "Pay",  action: "pay",  disabled: !this.isAccepted || this.paytOptions.length === 0 || this.selectedPaytOptId === 0 }
            ];
        } else {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "move", actionParam: "back" },
                //{ class: "btn2", label: "Pay",  action: "pay",  disabled: !this.isAccepted || this.paytOptions.length === 0 }
                { class: "btn2", label: "Pay",  action: "pay",  disabled: !this.isAccepted || this.paytOptions.length === 0 || this.selectedPaytOptId === 0 }
            ];
        }
    }

    evaluateGetPdf(): void {
        if (this.productInfo.lineCd === 'PA' 
        && this.productInfo.sublineCd === 'IND'
        && this.productInfo.coverageInfo.grpTag === 'Y') {
            this.getPdf('insuringtermsgrp');
        }
        else {
            this.getPdf('insuringterms');
        }
    }

    getPdf(pdfName: string): void {
        if (pdfName === "insuringterms") {
            window.open(this.insuringTerms);
        }
        else if (pdfName === "insuringtermsgrp") {
            window.open(this.insuringTermsGrp);
        }
    }

    private getPaymentDetails(quoteId: number): void {
        const bdayFormat = moment(this.productService.productInfo?.customerInfo?.birthDate, 'YYYY-MM-DD').format('MMDDYYYY');
        const answer = this.productService.productInfo?.customerInfo?.lastName.substring(0,1) + this.productService.productInfo?.customerInfo?.firstName.substring(0,1) + bdayFormat;
        this.apiCallService.getProductInfo({
            quoteId: quoteId || '',
            requestId: this.productService.productInfo?.requestId || '',
            policyId: this.productService.productInfo?.policyId || '',
            answer: answer
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        sessionStorage.setItem("rid", res.recordId);
                        this.productInfo = res;
                        if ((this.productInfo?.quoteStatCd !== 4 && this.productInfo?.polStatCd !== 5) && this.paymentRetrievalRetries !== this.paymentRetrievalRetriesThreshold && !this.paymentRetrievalHasError) {
                            this.paymentRetrievalRetries++;
                            setTimeout(() => {
                                this.getPaymentDetails(this.productInfo.quoteId);
                            }, 1000)
                        } else if (this.productInfo.quoteStatCd === 4) {
                            this.productService.setProductInfo2(this.productInfo);
                            this.productService.setProductInfo("fromLink", "payment");
                            this.productService.setProductInfo("creationProcess", "policy");
                            this.appMessageService.showAppMessage("Successfully retrieved payment status.", "success");
                            this.jsonDataService.contorlLoading(false);
                            this.router.navigateByUrl("/policy/email-print?se=1");
                        } else if (this.productInfo?.polStatCd === 5) {
                            this.productService.setProductInfo2(this.productInfo);
                            this.productService.setProductInfo("fromLink", "payment");
                            this.productService.setProductInfo("creationProcess", "renewal");
                            this.appMessageService.showAppMessage("Successfully retrieved payment status.", "success");
                            this.jsonDataService.contorlLoading(false);
                            this.router.navigateByUrl("/policy/email-print?se=1");
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage("Unable to retrieve payment status. Please contact BPI MS for more information.", "error");
                        }

                        // ? This sets fromLink property to null because its from dashboard
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                    this.paymentRetrievalHasError = true;
                }
            });
        });
    }

    private fromPaynamics(): boolean {
        let fromPaynamics: boolean = false;

        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params?.from === "paynamics") {
                fromPaynamics = true
            } else {
                fromPaynamics = false
            }
        });

        return fromPaynamics;
    }
}

@Directive({
    selector: '[bpiOtpInput]'
})
export class BpiOtpInputDirective implements AfterViewInit {

    @Input() otpInputRefList!: QueryList<ElementRef> ;

    // index
    @Input() bpiOtpInput!: number;

    constructor(
        private control: NgControl,
        private el: ElementRef
    ) {}

    ngAfterViewInit(): void {
        // this.control.valueChanges?.subscribe(data=>{
        //     let start=this.el.nativeElement.selectionStart;
        //     let end = this.el.nativeElement.selectionEnd;
        //     this.control.control?.setValue(String(data || '').toUpperCase(),{emitEvent:false});
        //     this.el.nativeElement.setSelectionRange(start,end);
        // })
    }

    @HostListener("textInput", ["$event"])
    onKeyDown(e: any) {

        if (!e.data.match(/[0-9]/g)) {
            e.preventDefault();
        }else{
            setTimeout((e:any)=>{
                this.otpInputRefList.get(this.bpiOtpInput)?.nativeElement.focus() || this.otpInputRefList.get(this.bpiOtpInput - 1)?.nativeElement.blur();
            },0)
        }
    }

    // @HostListener("paste", ["$event"])
    // onPaste(event: ClipboardEvent) {
    //     event.preventDefault();
    //     let pastedInput = '';
    //     if(!this.withSpecialCharacters){
    //         pastedInput = String(this.withSpace ? event.clipboardData?.getData("text/plain").replace(/[^a-zA-Z0-9\s]/g, "") : event.clipboardData?.getData("text/plain").replace(/[^a-zA-Z0-9]/g, "")).toUpperCase();
    //     }else{
    //         pastedInput = String(event.clipboardData?.getData("text/plain")).toUpperCase();
    //     }
        
    //     pastedInput && document.execCommand("insertText", false, pastedInput);
    // }
    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
    }
}