<section id="home">
    <div class="container">
        <div class="banner montserrat">
            <div>
                <img src="./assets/images/landing-page/banner/motor.jpg">
                <p class="banner-text">
                    <span>Quality auto insurance</span><br>
                    on and off the road
                </p>
            </div>
            <div>
                <img src="./assets/images/landing-page/banner/personal-accident.jpg">
                <p class="banner-text">
                    <span>Worry-free days</span><br>
                    thru 24/7 personal protection
                </p>
            </div>
            <div>
                <img src="./assets/images/landing-page/banner/home-protect.png">
                <p class="banner-text">
                    <span>Home protection</span><br>
                    for secured living
                </p>
            </div>
        </div>
        <div class="product-content">
            <div class="choices proxima">
                <h2 class="montserrat">Products</h2>
                <div class="purpose">
                    <h2 class="title montserrat">Purpose</h2>
                    <ul class="purpose-list arial">
                        <li *ngFor="let purpose of purposes" (click)="setProductPurpose(purpose.rvLowValue)" [class.selected]="purpose.rvLowValue == purpose">
                            <a>{{purpose.rvMeaning}}</a>
                        </li>
                    </ul>
                </div>
                <div class="products">
                    <ul>
                        <li (click)="setProductLineAndPurpose('*')" [class.selected]="productLine === '*'">
                            <a>
                                <img src="./assets/images/landing-page/products/line/gray/icon-all-products.png" [class.hide]="productLine === '*'">
                                <img src="./assets/images/landing-page/products/line/blue/icon-all-products.png" [class.hide]="productLine !== '*'">
                                <span>All insurance</span>
                            </a>
                        </li>
                        <li *ngFor="let line of paramLines" (click)="setProductLineAndPurpose(line.lineCd)" [class.selected]="productLine === line.lineCd">
                            <a>
                                <img [src]="'./assets/images/landing-page/products/line/gray/' + line.imagePath" [class.hide]="productLine === line.lineCd">
                                <img [src]="'./assets/images/landing-page/products/line/blue/' + line.imagePath" [class.hide]="productLine !== line.lineCd">
                                <span>{{line.lineName}}</span>
                            </a>
                        </li>
                        <li (click)="setProductLineAndPurpose('paytRenewal')" class="payt-renewal" [class.selected]="productLine === 'paytRenewal'">
                            <a>
                                <img src="./assets/images/landing-page/products/line/gray/icon-renewal.png" [class.hide]="productLine === 'paytRenewal'">
                                <img src="./assets/images/landing-page/products/line/blue/icon-renewal.png" [class.hide]="productLine !== 'paytRenewal'">
                                <span>Renewal & Payments</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="select-view">
                        <li id="selected-product" (click)="showSelectProductList = !showSelectProductList" [class.renpayt]="productLine === 'paytRenewal'">
                            <ng-container *ngIf="productLine === '*'">
                                <a>
                                    <img src="./assets/images/landing-page/products/line/blue/icon-all-products.png">
                                    <span>All insurance</span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="productLine === 'paytRenewal'">
                                <a>
                                    <img src="./assets/images/landing-page/products/line/gray/icon-all-products.png">
                                    <span>All insurance</span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="productLine !== '*'">
                                <ng-container *ngFor="let line of paramLines">
                                    <a *ngIf="line.lineCd === productLine">
                                        <img [src]="'./assets/images/landing-page/products/line/blue/' + line.imagePath">
                                        <span>{{line.lineName}}</span>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </li>
                        <div class="select-product-list" *ngIf="showSelectProductList">
                            <li (click)="setProductLineAndPurpose('*')" [class.selected]="productLine === '*'">
                                <a>
                                    <img src="./assets/images/landing-page/products/line/gray/icon-all-products.png">
                                    <span>All insurance</span>
                                </a>
                            </li>
                            <li *ngFor="let line of paramLines" (click)="setProductLineAndPurpose(line.lineCd)" [class.selected]="productLine === line.lineCd">
                                <a>
                                    <img [src]="'./assets/images/landing-page/products/line/gray/' + line.imagePath">
                                    <span>{{line.lineName}}</span>
                                </a>
                            </li>
                        </div>
                    </ul>

                    <ul class="select-view">
                        <li id="selected-product-2" (click)="setProductLineAndPurpose('paytRenewal')" [class.selected]="productLine === 'paytRenewal'">
                            <ng-container *ngIf="productLine === 'paytRenewal'">
                                <a>
                                    <img src="./assets/images/landing-page/products/line/blue/icon-renewal.png">
                                    <span>Renewal & Payments</span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="productLine !== 'paytRenewal'">
                                <a>
                                    <img src="./assets/images/landing-page/products/line/gray/icon-renewal.png">
                                    <span>Renewal & Payments</span>
                                </a>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content">
                <p class="result">{{noOfProductsResult}} {{noOfProductsResult === 0 || noOfProductsResult === 1 ? 'result' : 'results'}} found</p>
                <div class="case-wrap">
                    <div *ngFor="let product of products" class="pack" [class]="product.sublineCd">
                        <img [src]="'./assets/images/landing-page/products/subline/' + product.imagePath">
                        <div class="item">
                            <div class="description">
                                <h2 class="tit-case montserrat">{{product.sublineName}}</h2>
                                <p>{{product.shortDesc}}</p>
                            </div>
                            <ng-container *ngIf="productLine !== 'paytRenewal'">
                                <div class="info-wrap proxima" [class.one]="product.availTag != 'Y'">
                                    <a (click)="createQuotation(product.lineCd, product.sublineCd)" class="btn btn3 btn-avail" [hidden]="product.availTag != 'Y'">Avail now</a>
                                    <a (click)="setFAQ(product.lineCd, product.sublineCd)" class="btn-link">Learn more</a>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="productLine === 'paytRenewal'">
                                <div class="info-wrap proxima">
                                    <a (click)="goToPaytRenewal()" class="btn btn3 btn-avail">Pay now</a>
                                    <a (click)="setFAQ('paytRenewal', 'paytRenewal')" class="btn-link">Learn more</a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</section>