<div id="email-list-input" #emailListContainer class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-can-float">
    <div class="mat-form-field-wrapper">
        <div class="mat-form-field-flex">
            <div class="mat-form-field-infix" #background>
                <ng-container *ngFor="let email of emailList">
                    <span *ngIf="!email.isEdit" [class.error-email]="!email.isValid" class="cc-list" (dblclick)="onDbClickEmail(email)">
                        {{email.email}}
                        <mat-icon class="email-close" (click)="deleteEmail(email)">close</mat-icon>
                    </span>
                    <input *ngIf="email.isEdit" (keyup)="onListKeyDown($event, email)" [size]="email.email.length || 1" [(ngModel)]="email.email" (blur)="onEditBlur(email)" class="mat-input-element mat-form-field-autofill-control weighted">
                </ng-container>
                <input #emailListInput type="text" *ngIf="!hideInput" [size]="emailInput?.length || 1" [ngModel]="emailInput" (ngModelChange)="onChange($event)" (blur)="touched();" [disabled]="isDisabled" (textInput)="onKeyDown($event)" class="mat-input-element mat-form-field-autofill-control weighted">
            </div>
        </div>
    </div>
</div>