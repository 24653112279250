<section id="stepper-dtl" [ngClass]="getCustomClass()">
    <div class="container">
        <h2 class="proxima">
            <span class="step-num" *ngIf="stepperDtlService.showStepNo">{{ stepperDtlService.stepperDtlStepNo === 0 ? "" : stepperDtlService.stepperDtlStepNo }}</span>
            <span class="step-title">{{ stepperDtlService.titleStepperDtl }}</span>
        </h2>
        <!-- {
            id: "btn-save",             - String (Optional)
            class: "btn4",              - String (Required)
            label: "Back",              - String (Required)
            isShow: "true",             - String (Optional)
            action: "testFunction"      - String (Required)
            disabled: true              - Boolean (Optional)
        } -->
        <div *ngIf="stepperDtlService.stepperDtlBtns.length > 0" class="btn-wrap right-aligned" [class.odd-btn]="btnCount === 3">
            <ng-container *ngFor="let btn of stepperDtlService.stepperDtlBtns">
                <button *ngIf="[undefined, 'true'].indexOf(btn.isShow) >= 0" [id]="btn.id" class="btn proxima" [class]="btn.class" (click)="stepperDtlService.childInstance[btn.action](btn.actionParam)" [disabled]="btn.disabled">
                    {{ btn.label }}
                </button>
            </ng-container>
        </div>
    </div>
</section>
