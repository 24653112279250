import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sub-verified',
  templateUrl: './sub-verified.html'
})
export class SubVerifiedComponent{

  constructor(
      private router: Router
  ) { }

  goHome(){
    this.router.navigate(['/'])
  }

}
