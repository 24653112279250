import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { JsonDataService } from './json-data.service';
import { MessageBoxService } from './message-box.service';
import { AppMessageService } from './app-message.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

    private headers: HttpHeaders = new HttpHeaders;
    private apiUrl: string = this.jsonDataService.apiUrl || "/api";

    public lastActionTime: any = new Date();
    private lastActionSavedTime: any = new Date();

    private _accountType: string = "";
    public get accountType(): string{
        this.validateUser();
        return this._accountType;
    }
    userId: string = "";
    name: string = "";
    email: string = "";
    windowId: number = 0;
    premTag: string = "";
    agentGrp: string = "";
    userLevel: string = "0";
    issCd: string = "";
    verificationTag: string = "";
    unchangedPw: string = 'N';
    mobileNo: string = "";
    displayName: string = "";

    userIdSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
    userIdSubj$:Observable<string> = this.userIdSubj.asObservable();

    constructor (
        private router: Router,
        private httpClient: HttpClient,
        private jsonDataService: JsonDataService,
        private messageBoxService: MessageBoxService,
        private appMessageService: AppMessageService
    ) { }

    public sessionExists(): boolean {
        return !!this.userId;
    }

    public setUserDetails(userDtls: any): void {
        this._accountType = userDtls.accountType;
        this.userId = userDtls.userId;
        this.name = userDtls.name;
        this.email = userDtls.email;
        this.windowId = userDtls.windowId || 0;
        this.premTag = userDtls.premTag;
        this.agentGrp = userDtls.agentGrp;
        this.userLevel = userDtls.userLevel || "0";
        this.issCd = userDtls.issCd;
        this.verificationTag = userDtls.verificationTag || "Y";
        this.unchangedPw = userDtls.unchangedPw || "N";
        this.mobileNo = userDtls.mobileNo;
        this.displayName = userDtls.displayName;
        this.userIdSubj.next(this.userId);

        // this.updateOtpMethod().subscribe();
        this.startSessionChecker(userDtls.expiry);
    }

    public resetUserDetails(): void {
        this._accountType = "P";
        this.userId = "";
        this.name = "";
        this.email = "";
        this.windowId = 0;
        this.premTag = "";
        this.agentGrp = "";
        this.userLevel = "";
        this.issCd = "";
        this.verificationTag = "";
        this.unchangedPw = "";
        this.mobileNo = "";
        this.displayName = "";
        this.userIdSubj.next("");
    }

    // expiry - Value in seconds. If session timeout is 5 minutes, value of this variable should be 300. [5 * 60 = 300]
    private startSessionChecker(expiry: number): void {
        const bssid = sessionStorage.getItem("bssid") || "";
        if ((expiry || 0) > 0 && bssid && bssid !== "1") {
            const now: any = new Date();
            if (this.lastActionTime - this.lastActionSavedTime === 0 && now - this.lastActionSavedTime >= (expiry * 1000)) {
                this.logout().subscribe();
                sessionStorage.removeItem("bssid");
                sessionStorage.removeItem("rkc");
                this.router.navigate(["/"]);
                this.resetUserDetails();
                this.messageBoxService.show1("Session Timeout", "Your session has expired. Please login again.", "Ok", "");
            } else if (this.lastActionTime - this.lastActionSavedTime > 0 && now - this.lastActionSavedTime >= 60000) {
                const now = new Date();
                this.lastActionTime = now;
                this.lastActionSavedTime = now;
                this.updateActionDate().subscribe();
                setTimeout(() => this.startSessionChecker(expiry), 500);
            } else {
                setTimeout(() => this.startSessionChecker(expiry), 500);
            }
        }
    }

    private generateBssidRequestKey(): string {
        const bssid = sessionStorage.getItem("bssid") || "1";
        const rkc = sessionStorage.getItem("rkc") || "0";

        if (bssid !== "1" && rkc !== "0") {
            let token1 = this.createToken(bssid);
            let token2 = this.createToken(token1);
            let token3 = this.createToken(token2);

            token1 = token1.substr(0,  8) + rkc + token1.substr( 8);
            token2 = token2.substr(0, 16) + rkc + token2.substr(16);
            token3 = token3.substr(0, 24) + rkc + token3.substr(24);

            return `${token1}.${token2}.${token3}`;
        }

        return "1";
    }

    private createToken(data: string): string {
        return this.jsonDataService.encrypt(
            data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) +
            data.substr(15, 1) + data.substr(35, 1) + data.substr( 5, 1) +
            data.substr(25, 1) + data.substr(20, 1));
    }

    private baseRequest(): void {
        this.headers = this.headers
            .set("uid", sessionStorage.getItem("bssid") || "1")
            .set("rk", this.generateBssidRequestKey())
            .set("ct", "1");
    }

    private updateOtpMethod(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/uom", {}, { headers: this.headers });
    }

    private updateActionDate(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/update-action-date", {}, { headers: this.headers });
    }

    private logout(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/logout", {}, { headers: this.headers });
    }

    validateUser(){
        const bssid = sessionStorage.getItem("bssid") || 0;

        if(!bssid || (bssid == '1')){
            if(this._accountType && this._accountType != 'P' ){
                this.onError();
                throw new Error();
            }
        }else{
            const ref = JSON.parse(atob(bssid.split('.')[2]));
            if(ref.accountType != this._accountType){
                this.onError();
            }
        }
    }

    onError(){
        this.jsonDataService.contorlLoading(false);
        this.removeSession();
        localStorage.clear();
        this.appMessageService.showAppMessage("We are unable to process your request. Please try again later.", "error");
    }

    public removeSession():void {
        this.logout().subscribe();
        sessionStorage.removeItem("bssid");
        sessionStorage.removeItem("rkc");
        this.router.navigate(["/"]);
        this.resetUserDetails();
    }

}