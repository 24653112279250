import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { JsonDataService } from './json-data.service';
import { SecurityService } from './security.service';
import { UserDetailsService } from './user-details.service';
import { AppMessageService } from './app-message.service';

@Injectable({
  providedIn: 'root'
})
export class APICallService {

    /*
        REQUEST FORMAT

        ==================================== GET REQUEST ====================================

        this.httpClient.get(URL, {
            params: new HttpParams().set("params", encodeURIComponent(this.encryptedParams)),
            headers: this.headers
        });
        
        ==================================== POST REQUEST ===================================

        - With Parameter
        this.httpClient.post(URL, { params: this.encryptedParams }, { headers: this.headers });

        - Without Parameter
        this.httpClient.post(URL, {}, { headers: this.headers });

    */

    private headers:HttpHeaders = new HttpHeaders;

    private encryptedParams:string = "";
    private apiUrl:string = "";
    private apiMaintenanceUrl:string = "";
    private apiQuotationUrl:string = "";
    private apiPolicyUrl:string = "";
    private apiDashboardUrl:string = "";

    constructor (
        private httpClient: HttpClient,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService
    ) {
        this.apiUrl = this.jsonDataService.apiUrl || "/api";
        this.apiMaintenanceUrl = this.apiUrl + "/list";
        this.apiQuotationUrl = this.apiUrl + "/quotation";
        this.apiPolicyUrl = this.apiUrl + "/policy";
        this.apiDashboardUrl = this.apiUrl + "/dashboard";
    }

    private baseRequest(params:any = "-1"):void {
        this.userDetailsService.lastActionTime = new Date();
        this.headers = this.headers
            .set("uid", sessionStorage.getItem("bssid") || "1")
            .set("rk", this.securityService.generateBssidRequestKey())
            .set("rid", sessionStorage.getItem("rid") || "1")
            .set("ct", "1")
            .set("X-Original-User-Agent", navigator.userAgent);

        if (params && params !== "-1") {
            this.encryptedParams = this.jsonDataService.encrypt(params);
            this.headers = this.headers.set("ct", this.securityService.buildCsrfToken(this.encryptedParams));
        }
    }

    // APPLICATION COMPONENT
    public checkAuthorization():any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/check-authorization", {}, { headers: this.headers });
    }

    // REGISTRATION
    public register(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/register", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // LOGIN
    public login(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/login", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // VERIFY OTP
    public sendOtp(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiUrl + "/otp/send", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // VERIFY OTP
    public verifyOtp(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiUrl + "/otp/verify", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public security(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiUrl + "/otp", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOtpToken(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiUrl + "/otp/token", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // INITIAL PASSWORD
    public setPassword(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/password/set", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // RESET PASSWORD
    public resetPassword(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/password/reset", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CHANGE PASSWORD
    public changePassword(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/password/change", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // LOGOUT
    public logout():any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/logout", {}, { headers: this.headers });
    }

    // LOV
    public getClients(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMaintenanceUrl + "/clients", {
            params: this.encryptedParams
        }, { headers: this.headers }).pipe(map((response:any) => {
            let clients = {};
            this.securityService.checkRequestKeyResponse(response, () => {
                if(response.status === 'SUCCESS') {
                    this.securityService.hasValidCsrfToken(response, () => {
                        let data = JSON.parse(this.jsonDataService.decrypt(response.response));
                        data.content = data.content.map((a:any) => {
                            a.addressPt1 = (a.addressPt1 || "") + (a.addressPt2 || "") + (a.addressPt3 || "") + (a.addressPt4 || "");
                            return a;
                        });
                        clients = data;
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
            return clients;
        }));
    }

    // LOGIN
    // SUB-FORGOT
    // SUB-RESET
    // REGISTRATION
    // REVIEW PREMIUM AND CHARGES QUOTATION
    public sendRecoveryEmail(type:string, params:any):any {
        this.baseRequest({ params: params, type: type });
        return this.httpClient.post(this.apiUrl + "/password/send-recovery-email", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CONTACT US
    // EMAIL/PRINT QUOTATION
    // EMAIL/PRINT POLICY
    public sendMail(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/mail", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PROFILE
    public getProfileInfo():any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/profile/info", {}, { headers: this.headers });
    }

    // PROFILE
    public saveProfileInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/profile/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // DASHBOARD
    public getProductList(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/product/list", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // DASHBOARD
    public getProductInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/product/info", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // VEHICLE DETAILS QUOTATION
    public checkDuplicateVehicleDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/vehicle/check-duplicate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // VEHICLE DETAILS QUOTATION
    public saveVehicleDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public chkBlockedVehicle(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/check-blocked-vehicle", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PROPERTY DETAILS
    public getAddress():any {
        this.baseRequest();
        return this.httpClient.post(this.apiQuotationUrl + "/property", {}, { headers: this.headers });
    }

    // PROPERTY DETAILS QUOTATION
    public checkDuplicatePropertyDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/property/check-duplicate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePropertyDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/property/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // COVERAGE/PERILS QUOTATION
    // WALA PA VALIDATION NG AUTHORIZED USER
    // WALA PA VALIDATION NG RECORD ID
    public computeCoveragePerils(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/coverage/compute", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // LOGIN
    // REGISTRATION
    // COVERAGE/PERILS QUOTATION
    public saveCoverage(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/coverage/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // COVERAGE DELETE DEPENDENTS WHEN 0
    public deleteDependents(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/coverage/delete-dependents", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // REVIEW PREMIUM AND CHARGES QUOTATION
    public cancelQuotation(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/cancel", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // EMAIL/PRINT QUOTATION
    public generateQuoteNo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/generate-quote-no", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // EMAIL/PRINT QUOTATION
    // EMAIL/PRINT POLICY
    public getPolicyHolder(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/policy-holder", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }



    // EMAIL/PRINT QUOTATION
    // EMAIL/PRINT POLICY
    // WALA PA VALIDATION NG MGA PARAMETER
    public savePolicyHolder(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/policy-holder/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CUSTOMER INFORMATION POLICY
    // EMAIL/PRINT POLICY
    public getCustInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public validateCoverage(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/validate-coverage", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CUSTOMER INFORMATION POLICY
    // WALA PA VALIDATION NG MGA PARAMETER
    // WALA PA VALIDATION NG AUTHORIZED USER
    // WALA PA VALIDATION NG RECORD ID
    public getPresignedUrlUploadId(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information/upload-id", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CUSTOMER INFORMATION POLICY
    // WALA PA VALIDATION NG MGA PARAMETER
    // WALA PA VALIDATION NG AUTHORIZED USER
    // WALA PA VALIDATION NG RECORD ID
    public ocr(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information/upload-id", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPresignedUrlSupDocs(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information/upload-supporting-docs", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CUSTOMER INFORMATION POLICY
    // WALA PA VALIDATION NG MGA PARAMETER
    public saveCustInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // ACCEPTANCE POLICY
    public updateAcceptance(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/update-acceptance", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // ACCEPTANCE POLICY
    // WALA PA VALIDATION NG PERIOD
    public generatePolicy(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/generate-policy", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public sendPaymentLinkToCustomer(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/payment/send-link", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public createPaymentRequest(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/payment/create-request", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public processPayrollDeductionPayment(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/payment/payroll-deduction", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public getBpiPaymentAccounts(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/bpi-payment/accounts", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public initBPIPayment(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/bpi-payment/initiate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public otpBPIPayment(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/bpi-payment/otp", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    public processBPIPayment(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/bpi-payment/process", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // PAYMENT
    // NOT YET USED. FOR REFERENCE ONLY IN CASE OF FUTURE USE.
    // statusBPIPayment(params:any):any {
    //     this.baseRequest(params);
    //     return this.httpClient.post(this.apiUrl + "/bpi-payment/status", {
    //         params: this.encryptedParams
    //     });
    // }

    // PAYMENT
    public redirectToBPIPayment():any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/bpi-payment/redirect", {}, { headers:this.headers });
    }

    // EMAIL/PRINT QUOTATION
    // EMAIL/PRINT POLICY
    // NAKA-JAVA
    public printPDF(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/print", this.encryptedParams, { headers:this.headers });
    }

    // EMAIL/PRINT QUOTATION
    // EMAIL/PRINT POLICY
    // MERGE WITH GENERATE REPORT NODEJS VERSION
    getReportUrl(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/report", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // POLICY SUMMARY
    // FOR RENEWAL PROCESS
    saveRenwalCustomerInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/renewal", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // DASHBOARD-RENEWAL
    public getRenewalList(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/renewal/list", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // DASHBOARD-RENEWAL
    public getRenewalInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/renewal/info", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    // CUSTOMER INFORMATION POLICY
    // EMAIL/PRINT POLICY
    public getRenewalCustInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/renewal-customer-information", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // RENEWAL CUSTOMER INFORMATION POLICY
    // WALA PA VALIDATION NG MGA PARAMETER
    public saveRenewalCustInfo(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/renewal-customer-information/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    
    // POLICY STATUS UPDATE
    public updatePolicyStatus(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/update-policy-status", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public checkRenewalCocaf(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/check-cocaf", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public validateReferralCode(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/choose-plan/validate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public valProdVouchCd(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/choose-plan/validate-prod-vouch-code", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    // PERSONAL DETAILS QUOTATION
    public getPrincipalDetails(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/principal", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPrincipalDetailsNonPublic(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/principal/non-public", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public checkExistingQuotationPublic(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/principal/check-duplicate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePrincipalDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/principal/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // COMMON
    public getPresignedUrl(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/get-presigned-url", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // COMMON
    public uploadPresignedUrl(presignedUrl: string, file: File): any {
        const headers: HttpHeaders = new HttpHeaders({
            "Content-Type": "multipart/form-data"
        });

        return this.httpClient.put(presignedUrl, file, { headers: headers });
    }

    // SEND SMS
    public sendSms(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/sms", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    //UPDATE VEHICLE DETAILS(E-POLICY SCREEN)
    public updateVehicleDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/update-vehicle-details", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // GET EFF DATE PARAMS FOR NEW POLICY IC REGULATION
    public getEffDateParams(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiQuotationUrl + "/coverage/eff-date-params", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
        
    //VALIDATE CANCELLATION(E-POLICY SUMMARY)
    public validateCancellationText(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/check-cancellation", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    //CANCELLATION(E-POLICY SUMMARY)
    public cancelPolicy(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/cancel", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // GET POLICY PAYMENT INFO (E-POLICY SUMMARY)
    public getPolicyPaytInfo(params:any){
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/payment-info", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // CUSTOMER INFORMATION POLICY
    // EMAIL/PRINT POLICY
    public checkHighRisk(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyUrl + "/customer-information/check-high-risk", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public generateId(){
        var length = 20;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public validateRenewalDetails(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/renewal/validate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public sendRenewOtp(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/renewal/validate/otp", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public verifyRenewOtp(params:any):any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiDashboardUrl + "/renewal/validate/otp/verify", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    // CUSTOMER INFORMATION POLICY
    public redirectToBPIRM():any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/bpi-rm/redirect", {}, { headers:this.headers });
    }

    public saveBPIRM(params:any):any{
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/bpi-rm/save", {
            params: this.encryptedParams
        }, { headers: this.headers });

    }
}
