import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service'; 
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProductService } from '../../services/product.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
  templateUrl: './contact-us.html'
})
export class ContactUsComponent {

    customErrorMsg = {
        mobile: "Not a valid mobile number.",
        fileType: "",
        fileSize: ""
    }

    form:FormGroup = this.formBuilder.group({
        clientName: ["", [Validators.required]],
        mobile: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        message: [""],
        brand: [""],
        yearModel: [""],
        model: [""],
        srp: [""],
        invoiceImg: [""],
        compAddress: ["", [Validators.required]],
        province: ["", [Validators.required]],
        city: ["", [Validators.required]],
        subdivision: ["", [Validators.required]],
    });

    emailParams: any = {
        recipient: "",
        subject: "",
        emailContent: "",
        attach: []
    }

    allowedFileExt:any = [];
    allowedTotalSize:number = 0;

    custDesc:string = "";
    custNumber:string = "";
    custFaxNo:string = "";
    custEmail:string = "";

    lineCd:string = "";

    filteredProvinceOptions: Observable<any> = new Observable;
    filteredCityOptions: Observable<any> = new Observable;
    productInfo: any = {};
    
    attachCaveat: string = "";
    
    constructor (
        // PUBLIC
        public formService: FormService,
        public autocompleteService: AutocompleteService,

        // PRIVATE
        private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private userDetailsService: UserDetailsService,
        private parameterService: ParameterService,
        private securityService: SecurityService,
        private route : ActivatedRoute,
        private router: Router,
        private productService: ProductService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.getDataJson();
        this.lineCd = this.productService.productInfo.lineCd;
        this.productInfo = this.productService.productInfo;

        if (this.userDetailsService.userId && this.userDetailsService.accountType == 'P') {
            this.form.controls["clientName"].setValue(this.userDetailsService.displayName || this.userDetailsService.name);
            this.form.controls['mobile'].setValue(this.userDetailsService.mobileNo);
            this.form.controls["email"].setValue(this.userDetailsService.email);
        }

        if (this.lineCd === 'MC') {
            route.queryParams.subscribe(params=>{
                this.form.patchValue(params);
            })
        }

        this.filteredProvinceOptions = this.autocompleteService.set(this.form, "province", this.getProvinces());
        this.setValidators();
    }

    getDataJson():void {
        let risk = this.productService?.productInfo?.customerInfo?.riskProfilingDetail?.value || 0;
        this.emailParams.recipient = risk = 1 
            ? this.jsonDataService.data.users.filter((bpims: any) => 
                bpims.bmaCd == this.productService.productInfo?.policyHeaderDetails?.bmaDetail?.value)[0]?.emailAddress || 
                this.parameterService.paramV("NON_STD_EMAIL_ADDR")
            : this.parameterService.paramV("NON_STD_EMAIL_ADDR");
        this.emailParams.subject = this.parameterService.paramV("NON_STD_SUBJECT");
        this.allowedFileExt = this.parameterService.paramV("CONTACT_US_FILE_EXT").split(",");
        this.allowedTotalSize = this.parameterService.paramN("CONTACT_US_FILE_LIMIT");
        this.custDesc = this.parameterService.paramV("CUST_DESC");
        this.custNumber = this.parameterService.paramV("CUST_NUMBER");
        this.custFaxNo = this.parameterService.paramV("CUST_FAX_NO");
        this.custEmail = this.parameterService.paramV("CUST_EMAIL");
        this.attachCaveat = this.jsonDataService.data.lines.find((line: any) => line.lineCd == this.productService.productInfo?.lineCd)?.attachCaveat || "";
    }

    processContactForm():void {
        if (this.form.valid) {
            const form = this.form.value;
            if (this.lineCd === 'MC') {
                this.emailParams.emailContent =
                this.parameterService.paramV("NON_STD_OPEN_TEXT") + "\n\n" +
                "CUSTOMER INFORMATION\n" +
                "Name: " + form.clientName + "\n" +
                "Mobile Number: " + form.mobile + "\n" +
                "Email: " + form.email + "\n\n" +
                "VEHICLE INFORMATION\n" +
                "Brand: " + (form.brand ? form.brand : "-") + "\n" +
                "Year Model: " + (form.yearModel ? form.yearModel : "-") + "\n" +
                "Model: " + (form.model ? form.model : "-") + "\n" +
                "Car Value / SRP: " + (form.srp ? this.formService.formatAmount(form.srp) : "-") + "\n\n" +
                "MESSAGE FROM CUSTOMER:\n " + (form.message ? form.message : "-") + "\n\n\n" +
                this.parameterService.paramV("NON_STD_CLOSE_TEXT");
            } 
            else if(this.lineCd === 'HA'){
                this.emailParams.emailContent =
                this.parameterService.paramV("NON_STD_OPEN_TEXT") + "\n\n" +
                "CUSTOMER INFORMATION\n" +
                "Name: " + form.clientName + "\n" +
                "Mobile Number: " + form.mobile + "\n" +
                "Email: " + form.email + "\n\n" +
                "PROPERTY DETAILS\n" +
                "Complete Address: " + (form.compAddress ? form.compAddress : "-") + "\n" +
                "Province: " + (form.province.text ? form.province.text : "-") + "\n" +
                "City: " + (form.city.text ? form.city.text : "-") + "\n" +
                "Subdivision: " + (form.subdivision ? form.subdivision : "-") + "\n\n" +
                "MESSAGE FROM CUSTOMER:\n " + (form.message ? form.message : "-") + "\n\n\n" +
                this.parameterService.paramV("NON_STD_CLOSE_TEXT");
            }
            else {
                this.emailParams.emailContent =
                this.parameterService.paramV("NON_STD_OPEN_TEXT") + "\n\n" +
                "CUSTOMER INFORMATION\n" +
                "Name: " + form.clientName + "\n" +
                "Mobile Number: " + form.mobile + "\n" +
                "Email: " + form.email + "\n\n" +
                "MESSAGE FROM CUSTOMER:\n " + (form.message ? form.message : "-") + "\n\n\n" +
                this.parameterService.paramV("NON_STD_CLOSE_TEXT");
            }
            
            this.sendMail(this.emailParams);
        } else {
            this.appMessageService.showAppMessage("Please fill all the required fields.", "info");
        }
    }

    sendMail(params:any):void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendMail(params).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    // this.fbAnalyticsService.trackEvent('Contact');
                    this.resetForm();
                    this.appMessageService.showAppMessage("Your request has been sent. One of our representatives will contact you as soon as possible.", "success");
                    this.router.navigateByUrl(this.router.serializeUrl(this.router.createUrlTree(["/"])));
                } else {
                    this.appMessageService.showAppMessage(response.message, "error");
                }
            });
        });
    }

    resetForm():void {
        this.form.controls["clientName"].setValue("");
        this.form.controls["clientName"].setErrors(null);
        this.form.controls["mobile"].setValue("");
        this.form.controls["mobile"].setErrors(null);
        this.form.controls["email"].setValue("");
        this.form.controls["email"].setErrors(null);
        this.form.controls["message"].setValue("");
        this.form.controls["brand"].setValue("");
        this.form.controls["yearModel"].setValue("");
        this.form.controls["model"].setValue("");
        this.form.controls["srp"].setValue("");
        this.form.controls["invoiceImg"].setValue("");
        this.form.controls["compAddress"].setValue("");
        this.form.controls["compAddress"].setErrors(null);
        this.form.controls["province"].setValue("");
        this.form.controls["province"].setErrors(null);
        this.form.controls["city"].setValue("");
        this.form.controls["city"].setErrors(null);
        this.form.controls["subdivision"].setValue("");
        this.form.controls["subdivision"].setErrors(null);
        this.emailParams = {
            recipient: "",
            subject: "",
            emailContent: "",
            attach: []
        };
    }

    onDrop(event:any):void {
        event.preventDefault();
        this.onFileChange({
            target: {
                files: event.dataTransfer.files
            }
        });
    }

    onDragOver(event:any):any {
        event.stopPropagation();
        event.preventDefault();
    }

    onFileChange(event:any):void {
        this.form.controls["invoiceImg"].reset();
        var invalidAttachment = false;
        var totalSize = 0;

        this.emailParams.attach.forEach((el:any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (event.target.files && event.target.files.length) {
            var files = event.target.files;

            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                const element = files[index];

                reader.readAsDataURL(element);
                reader.onload = () => {
                    var type = element.type.split("/")[1];
                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        invalidAttachment = true;
                    } else {
                        totalSize += (element.size / 1024 / 1024);
                        this.emailParams.attach.push({
                            filename: element.name,
                            path: reader.result,
                            size: element.size,
                            type: element.type
                        });

                        this.form.patchValue({
                            invoiceImg: this.emailParams.attach.map((a:any) => { return a.filename }).join(", ")
                        });
                    }

                    if (index === files.length - 1) {
                        if (invalidAttachment) {
                            this.customErrorMsg.fileType = "Unable to attach file/s. Accepted file types: " + this.allowedFileExt.join(", ");
                        } else {
                            this.customErrorMsg.fileType = "";
                        }

                        if (totalSize > this.allowedTotalSize) {
                            this.customErrorMsg.fileSize = "You have reached the maximum file size for the attachment. (" + this.allowedTotalSize + "MB)";
                        } else {
                            this.customErrorMsg.fileSize = "";
                        }

                        if (invalidAttachment || totalSize > this.allowedTotalSize) {
                            this.form.controls["invoiceImg"].setErrors({ "incorrect": true });
                            this.form.controls["invoiceImg"].markAsTouched();
                        } else {
                            this.form.controls["invoiceImg"].setErrors(null);
                        }

                        if (invalidAttachment) {
                            setTimeout(() => {
                                this.form.controls["invoiceImg"].setErrors(null);
                            }, 3000);
                        }
                    }
                }
            }
        }
    }

    onClickDelete(ind:number):void {
        this.emailParams.attach = this.emailParams.attach.filter((a:any, i:number) => { return i !== ind });

        let totalSize = 0;
        this.emailParams.attach.forEach((el:any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (totalSize > this.allowedTotalSize) {
            this.customErrorMsg.fileSize = "You have reached the maximum file size for the attachment. (" + this.allowedTotalSize + "MB)";
            this.form.controls["invoiceImg"].setErrors({ "incorrect": true });
            this.form.controls["invoiceImg"].markAsTouched();
        } else {
            this.customErrorMsg.fileSize = "";
            this.form.controls["invoiceImg"].setErrors(null);
        }
    }

    
    /* PROVINCE AUTO-COMPLETE FUNCTIONS */

    getProvinces(): any[] {
        let provinceOptions = [];
        for (let p of this.jsonDataService.data.province) {
            provinceOptions.push({
                value: p.provinceCd,
                text: p.provinceName
            });
        }
        provinceOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return provinceOptions;
    }
    setProvinceViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "province", true, event);
        for (const p of this.getProvinces()) {
            if (p.text === this.form.controls["province"].value.text) {
                this.form.controls["province"].setValue(p);
            }
        }
        
    }
    setProvinceViaOption(option: any): void {
        this.form.patchValue({
            city: "",
            postCode: "",
        });
        this.productInfo.propertyAssessment = this.form.getRawValue();
        this.filteredCityOptions = this.autocompleteService.set(this.form, "city", this.getCities(option.value.value));
    }

    /* CITY AUTO-COMPLETE FUNCTIONS */

    getCities(provinceCd: any): any[] {
        let cityOptions = [];
        for (let c of this.jsonDataService.data.city.filter((c: any) => c.provinceCd == provinceCd)) {
            cityOptions.push({
                value: c.cityCd,
                text: c.cityName,
                provinceCd: c.provinceCd
            });
        }
        cityOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return cityOptions;
    }
    setCityViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "city", true, event);
        for (const p of this.getCities(event.option)) {
            if (p.text === this.form.controls["city"].value.text) {
                this.form.controls["city"].setValue(p);
            }
        }
    }
    setCityViaOption(option: any): void {
        this.form.patchValue({
            postCode: ""
        });
    }

    setValidators() : void{
        if (this.lineCd=="HA") {
            this.form.controls["compAddress"].setValidators([Validators.required]);
            this.form.controls["province"].setValidators([Validators.required]);
            this.form.controls["city"].setValidators([Validators.required]);
            this.form.controls["subdivision"].setValidators([Validators.required]);
        } else {
            this.form.controls["compAddress"].clearValidators();
            this.form.controls["province"].clearValidators();
            this.form.controls["city"].clearValidators();
            this.form.controls["subdivision"].clearValidators();
        }
        this.form.controls["compAddress"].updateValueAndValidity();
        this.form.controls["province"].updateValueAndValidity();
        this.form.controls["city"].updateValueAndValidity();
        this.form.controls["subdivision"].updateValueAndValidity();
    }

}