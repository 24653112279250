import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FAQService } from 'src/app/services/faq.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';

import { UserDetailsService } from 'src/app/services/user-details.service';
import { MessageBoxService } from 'src/app/services/message-box.service';

@Component({
    templateUrl: './faq.html',
  })
  export class FAQComponent implements OnInit {
    
    introText: string = '';
    disclaimer: string = '';
    faqTitle: string = '';
    faqTitle2: string = '';
    faqContent: any = [];
    faqDefaultContent: any = [];
    lineCd: string = '';

    constructor(
        private faqService: FAQService,
        private jsonDataService: JsonDataService,
        private router: Router,
        private productService: ProductService,
        private userDetailsService: UserDetailsService,
        private msgBox: MessageBoxService
    ){
        for (let param of this.jsonDataService.data.params) {
            if (param.paramName === "FAQ_INTRO_TEXT") {
                this.introText = param.paramValueV;
            }
            else if (param.paramName === "FAQ_DISCLAIMER") {
                this.disclaimer = param.paramValueV;
            }
        }
        
    }
    ngOnInit(): void {
        this.lineCd = this.faqService.faqInfo.lineCd;
        if(this.faqService.faqInfo.lineCd=="" || this.faqService.faqInfo.sublineCd == ""){
            this.router.navigate(['/']);
        }
        this.faqTitle = this.faqService.faqInfo.title;
        this.faqTitle2 = "Frequently Asked Questions";
        this.faqContent = this.faqService.faqInfo.content;
    }
    panelOpenState = false;
  

    goBack(){
        this.router.navigate(['/']);
    }
    gotoPlan(){
        this.productService.setProductInfo("lineCd", this.faqService.faqInfo.lineCd);
        this.productService.setProductInfo("sublineCd", this.faqService.faqInfo.sublineCd);
        this.productService.setProductInfo("creationProcess", "quotation");
        this.router.navigateByUrl("/quotation");
    }
    gotoPay() {
        if(this.userDetailsService.userId !== '') {
            var acctType = this.jsonDataService.data.refCds.filter((a: any) => a.rvDomain === "BMM013.USER_TYPE" && a.rvLowValue === this.userDetailsService.accountType)[0]['rvMeaning'];
            var msg = 'You are currently logged-in to your ' + (acctType || 'Personal') + ' account. Please logout first in order to proceed.';

            this.msgBox.show1('E', msg, 'Ok', '');
        } else {
            this.productService.setProductInfo("lineCd", 'paytRenewal');
            this.productService.setProductInfo("sublineCd", 'paytRenewal');
            this.productService.setProductInfo("creationProcess", 'paytRenewal');
            this.router.navigateByUrl("/renewal-payment");
        }
    }
  }