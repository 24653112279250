import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { DeviceDetectorService } from 'ngx-device-detector';

import { JsonDataService } from 'src/app/services/json-data.service';
import { APICallService } from 'src/app/services/api-call.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
    selector: 'login',
    templateUrl: './login.html'
})
export class LoginComponent {

    subform: string = "login";

    varChildCommon: any = {
        accountType: "",
        userName: "",
        requestId: "",
        isExpired: false,
        loginResponse: {},
        loginFunction: (password: string) => {
            return this.login(password);
        },
        proceedLogin: (content: any) => {
            return this.proceedLogin(content);
        },
        goToForm: (formName: string) => {
            return this.goToForm(formName);
        },
        sendRecoveryEmail: (params: any) => {
            return this.sendRecoveryEmail(params);
        }
    }

    constructor(
        private router: Router,
        private deviceDetectorService: DeviceDetectorService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        private activatedRoute: ActivatedRoute,
        private formService: FormService,
        private productService: ProductService,
        private securityService: SecurityService,
        private parameterService: ParameterService
    ) {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params?.params) {
                let params: any = jsonDataService.parseBase64(p.params.params);
                params = JSON.parse(jsonDataService.decrypt(params));

                if (params.status === "RESET_PASS") {
                    this.initResetForm(params.requestId, null);
                } else if (params.status === "VERIFIED") {
                    this.subform = 'verified';
                    if (params.userId === this.userDetailsService.userId) {
                        this.userDetailsService.verificationTag = "Y";
                    }
                } else if (params.status === "EXPIRED") {
                    this.varChildCommon.isExpired = true;
                    this.subform = "reset";
                    this.varChildCommon.requestId = params.requestId;
                } else {
                    this.appMessageService.showAppMessage("You are not allowed to perform this operation.", "error");
                    this.router.navigate(["/"]);
                }
            }
        });
    }

    login(password: string): any {
        let subject = new Subject<string>();
        this.apiCallService.login({
            at: this.varChildCommon.accountType,
            un: this.varChildCommon.userName,
            pw: password,
            localStorage: localStorage
        }).subscribe((response: any) => {
            if (response.status === "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, () => {
                    const content = JSON.parse(this.jsonDataService.decrypt(response.response));
                    sessionStorage.setItem("rkc", "1");

                    if (content.status === "SUCCESS") {
                        if (content.userAuth.withOTP === "Y") {
                            this.jsonDataService.contorlLoading(false);
                            if (content.userAuth.mobileNo || content.userAuth.email) {
                                this.varChildCommon.loginResponse = content;
                                this.subform = "otp";
                            } else {
                                subject.next("OTP is required for you to continue signing in, but you have no maintained email or mobile number on our record. Please contact BPI MS for more information.");
                            }
                        } else {
                            this.proceedLogin(content);
                        }
                    } else if (content.status === "INITIAL") {
                        this.jsonDataService.contorlLoading(false);
                        this.subform = "initial";
                    } else {
                        subject.next(content.message);
                    }
                });
            } else {
                subject.next(response.message);
            }
        });

        return subject.asObservable();
    }

    private willBypassOtp(content: any): boolean {
        let bypass = false;
        let keys = Object.keys(localStorage);
        let i = keys.length;

        while (i--) {
            if (this.isValidToken(keys[i], localStorage.getItem(keys[i]) || "")) {
                try {
                    const otpTokenValue = JSON.parse(this.jsonDataService.decrypt(localStorage.getItem(keys[i]) || ""));

                    const date: any = new Date();
                    const savedDate: any = new Date((otpTokenValue?.datetime || "").replace(/-/g, "/"));
                    const secondsPast = Math.floor((date - savedDate) / 1000);
                    const secondsInDays = (this.parameterService.paramN("DAYS_TO_BYPASS_OTP") || 0) * 86400;

                    if (otpTokenValue?.userId === content.userId
                        && otpTokenValue?.browser === this.deviceDetectorService.browser
                        && secondsInDays >= secondsPast
                    ) {
                        bypass = true;
                    }
                } catch (e) {
                    continue;
                }
            }
        }

        return bypass;
    }

    private isValidToken(token1: string, token2: string): boolean {
        const decryptedToken = this.jsonDataService.decrypt(token1);

        if (decryptedToken.substr(1, 1) !== token2.substr(30, 1)
            || decryptedToken.substr(2, 1) !== token2.substr(10, 1)
            || decryptedToken.substr(3, 1) !== token2.substr(40, 1)
            || decryptedToken.substr(4, 1) !== token2.substr(15, 1)
            || decryptedToken.substr(5, 1) !== token2.substr(35, 1)
            || decryptedToken.substr(6, 1) !== token2.substr(5, 1)
            || decryptedToken.substr(7, 1) !== token2.substr(25, 1)
            || decryptedToken.substr(8, 1) !== token2.substr(20, 1)
        ) {
            return false;
        }

        return true;
    }

    proceedLogin(content: any): void {
        sessionStorage.setItem("bssid", content.userAuth.bssid);

        this.userDetailsService.setUserDetails({
            ...content,
            ...{ unchangedPw: "N" }
        });

        if (["/register", "/login"].indexOf(this.router.url) > 0 || this.router.url.includes("login")) {
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage("Logged In Successfully.", "success");
            this.router.navigate(["/dashboard"]);
        } else {
            if (this.productService.productInfo.lineCd === "MC" || this.productService.productInfo.lineCd === "HA") {
                this.saveQuotationDetails();
            } else if (["PA", "CI"].indexOf(this.productService.productInfo.lineCd) >= 0) {
                this.saveCoverageDetails();
            }
        }
    }

    saveQuotationDetails(): void {
        const productInfo = this.productService.productInfo;
        if (productInfo.lineCd === 'MC') {
            const vehicleInfo = productInfo.vehicleInfo;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.saveVehicleDetails({
                quoteId: null,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                pvCd: productInfo.prodVouchInfo?.prodVouchCd,
                mortAccTag: vehicleInfo.mortgageType,
                bankCd: vehicleInfo.bankDetail.value,
                lanNo: vehicleInfo.loanAccountNo,
                alfesNo: vehicleInfo.alfesNo,
                veTypeTag: vehicleInfo.vehicleType,
                plateNo: vehicleInfo.plateNo,
                modelYear: vehicleInfo.yearModelDetail.value,
                carCompanyCd: vehicleInfo.makeDetail.value,
                makeCd: vehicleInfo.modelDetail.value,
                seriesCd: vehicleInfo.subModelDetail.value,
                purchasePrice: vehicleInfo.purchasePrice,
                color: vehicleInfo.color,
                seatsCovered: vehicleInfo.seatsCovered,
                engineNo: vehicleInfo.engineNo,
                chassisNo: vehicleInfo.chassisNo,
                mvFileNo: vehicleInfo.mvFileNo,
                mvType: vehicleInfo.mvTypeDetail.value,
                mvPremType: vehicleInfo.mvPremTypeDetail.value,
                veUsageCd: vehicleInfo.beingUsedDetail.value,
                remarks: vehicleInfo.remarks,
                creationProcess: productInfo.creationProcess,
                supportingDocs: vehicleInfo.supportingDocs,
                genPageWord: vehicleInfo.genPageWord
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            sessionStorage.setItem("rid", res.recordId);
                            this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                            this.productService.setProductInfo("quoteId", res.quoteId);
                            this.saveCoverageDetails();
                        });
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage("Error saving your vehicle details.", "error");
                    }
                });
            });
        } else if (productInfo.lineCd === 'HA') {
            const propertyInfo = productInfo.propertyInfo;
            const propertyAssessment = productInfo.propertyAssessment;
            const bldg = productInfo.bldgForm;
            const cont = productInfo.contForm;
            
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.savePropertyDetails({
                quoteId: null,
                pvCd: productInfo.prodVouchInfo?.prodVouchCd,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                addType: null,
                compAdd: propertyInfo.compAdd || null,
                houseNo: propertyInfo.houseNo,
                street: propertyInfo.street,
                brgy: propertyInfo.brgy,
                subdivName: propertyInfo.subdivName,
                country: propertyInfo.country.value,
                provOrCity: propertyInfo.provOrCity.provinceCd,
                cityCd: propertyInfo.provOrCity.value,
                postCode: propertyInfo.postCode.value,
                subdivCd: propertyAssessment.subdivision.value || 0,
                zoneCd: propertyAssessment.subdivision.zoneCd || 1,
                latitude: propertyInfo.latitude || "",
                longitude: propertyInfo.longitude || "",
                currency: propertyInfo.currency,
                buildings: bldg.bldgItms,
                contents: cont.contItms,
                constYear: propertyInfo.constYear || "",
                yrBuiltCd: propertyInfo.yrBuiltCd.value || 0,
                ageBldg: propertyInfo.ageBldg || "",
                noOfFloor: propertyInfo.noOfFloor || "",
                nearLand: propertyInfo.nearLand || "",
                roofPropDtl: Number(propertyInfo.roofPropDtl),
                roofOthers: propertyInfo.roofOthers || "",
                wallPropDtl: Number(propertyInfo.wallPropDtl),
                wallOthers: propertyInfo.wallOthers || "",
                mortAccTag: propertyInfo.mortAccTag || "",
                bank: propertyInfo.bank.value || "",
                loanAcctNo: propertyInfo.loanAcctNo || "",
                bankPolDoc: propertyInfo.bankPolDoc || "",
                existInsTag: propertyInfo.existInsTag || "",
                existInsComp: propertyInfo.existInsComp || "",
                existInsBldgTag: propertyInfo.existInsBldgTag || "",
                existInsContTag: propertyInfo.existInsContTag || "",
                occupancyF: propertyInfo.occupancyF || "",
                occupancyR: propertyInfo.occupancyR || "",
                occupancyL: propertyInfo.occupancyL || "",
                occupancyB: propertyInfo.occupancyB || "",
                storeyF: propertyInfo.storeyF || "",
                storeyR: propertyInfo.storeyR || "",
                storeyL: propertyInfo.storeyL || "",
                storeyB: propertyInfo.storeyB || "",
                roofF: propertyInfo.roofF || "",
                roofR: propertyInfo.roofR || "",
                roofL: propertyInfo.roofL || "",
                roofB: propertyInfo.roofB || "",
                exWallF: propertyInfo.exWallF || "",
                exWallR: propertyInfo.exWallR || "",
                exWallL: propertyInfo.exWallL || "",
                exWallB: propertyInfo.exWallB || "",
                surroundF: propertyInfo.surroundF || "",
                surroundR: propertyInfo.surroundR || "",
                surroundL: propertyInfo.surroundL || "",
                surroundB: propertyInfo.surroundB || "",
                remarks: propertyInfo.remarks || "",
                roofClassCd: propertyAssessment.roof.classCd,
                wallClassCd: propertyAssessment.wall.classCd,
                creationProcess: productInfo.creationProcess,
                supportingDocs: propertyInfo.supportingDocs,
                genPageWord: propertyInfo.genPageWord,
                saveContent: true
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (propertyAssessment.suppDocs && propertyAssessment.suppDocs.length > 0) {
                                this.uploadSupportingDocs(0, response, propertyAssessment);
                            } else {
                                sessionStorage.setItem("rid", res.recordId);
                                this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                                this.productService.setProductInfo("quoteId", res.quoteId);
                                this.saveCoverageDetails();
                            }
                        });
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage("Error saving your property details.", "error");
                    }
                });
            });
        }
    }

    async uploadSupportingDocs(index: number, response: any, propertyAssessment: any): Promise<void> {
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        this.jsonDataService.contorlLoading(true);
        const base64String = await this.formService.getFileAsBase64(propertyAssessment.suppDocs[index]);
        this.apiCallService.getPresignedUrl({
            action: "putObject",
            directory: `supporting-docs/property-details/${res.quoteId}/${res.itemNo}`,
            filename: propertyAssessment.suppDocs[index].name,
            validation: "VehicleSuppDoc",
            base64FileString: base64String
        }).subscribe((getPresignedUrlResponse: any) => {
            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                this.jsonDataService.contorlLoading(false);
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.apiCallService.uploadPresignedUrl(
                                res.presignedUrl, propertyAssessment.suppDocs[index]
                            ).subscribe((uploadPresignedUrlResponse: any) => {
                                if (index === (propertyAssessment.suppDocs.length-1)) {
                                    sessionStorage.setItem("rid", res.recordId);
                                    this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                                    this.productService.setProductInfo("quoteId", res.quoteId);
                                    this.saveCoverageDetails();
                                } else {
                                    this.uploadSupportingDocs(index+1, response, propertyAssessment);
                                }
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            //this.supportingDocsFiles = [];
                            //this.form.controls["supportingDocs"].setValue([]);
                            this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your property details. Please try again later.", "error");
                }
            });
        });
    }

    saveCoverageDetails(): void {
        const productInfo = this.productService.productInfo;
        if (['MC', 'HA'].includes(productInfo.lineCd)) {
            this.apiCallService.saveCoverage({
                quoteId: productInfo.quoteId,
                tempId: productInfo.coverageInfo.tempId,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd
            }).subscribe((response: any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.appMessageService.showAppMessage("Logged In successfully and your quotation details have been saved.", "success");
                        if (this.userDetailsService.accountType !== "P") {
                            this.router.navigate(["/quotation/coverage-perils"]);
                        } else {
                            this.router.navigate(["/quotation/email-print"]);
                        }
                    } else {
                        this.appMessageService.showAppMessage("Error saving your coverage details.", "error");
                    }
                });
            });
        } else if (['PA', 'CI'].includes(productInfo.lineCd)) {
            this.apiCallService.saveCoverage({
                quoteId: productInfo.quoteId,
                tempId: productInfo.coverageInfo.tempId,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                promoCode: productInfo.coverageInfo.promoCode,
                userId: this.userDetailsService.userId,
                policyHeaderInfo: productInfo.policyHeaderDetails
            }).subscribe((response: any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        sessionStorage.setItem("rid", res.recordId);
                        this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                        this.productService.setProductInfo("quoteId", res.quoteId);
                        if (this.userDetailsService.accountType !== "P") {
                            this.router.navigate(["/quotation/coverage-perils"]);
                        } else {
                            this.router.navigate(["/quotation/personal-details"]);
                        }
                        this.appMessageService.showAppMessage("Logged In successfully and your coverage details have been saved.", "success");
                    } else {
                        this.appMessageService.showAppMessage("Error saving your coverage details.", "error");
                    }
                });
            });
        }
    }

    initResetForm(requestId: any, expDate?: any): void {
        if (requestId) {
            if (expDate && new Date().getTime() > expDate) {
                this.varChildCommon.isExpired = true;
            }
            this.varChildCommon.requestId = requestId;
            this.subform = "reset"
        }
    }

    sendRecoveryEmail(params: any): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendRecoveryEmail("forgot", params).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);

            const loginFormBtns = document.querySelectorAll("#sub-forgot .hwl");
            loginFormBtns.forEach((field) => {
                field.classList.remove("hide");
            });

            this.securityService.hasValidCsrfToken(response, () => {
                if (response.status === "SUCCESS") {
                    this.appMessageService.showAppMessage("Email has been sent.", "success");
                    this.goToForm('login');
                } else {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    this.formService.showFormMsg("forgotFormMsg", res.message, "E");
                }
            });
        });
    }

    goToForm(formName: string): void {
        this.subform = formName;
    }
}