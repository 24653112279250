import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmLeaveComponent } from 'src/app/components/common/confirm-leave/confirm-leave.component';
import { UtilityService } from 'src/app/services/utility.service';

export interface CanComponentDeactivate {
    canDeactivate: (modalService: MatDialog) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ConfirmLeaveGuard implements CanDeactivate<CanComponentDeactivate> {

    constructor (
        private modalService:MatDialog,
        private utilService:UtilityService
    ) { }

    canDeactivate() {
        if (this.utilService.getFormGroup().dirty) {
            const modal = this.modalService.open(ConfirmLeaveComponent);
            return modal.afterClosed().pipe(tap(e => {
                if (e) {
                    this.utilService.getFormGroup().markAsPristine();
                }
            }));
        } else {
            return true;
        }
    }
}