import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[emailAdd]'
})
export class EmailAddDirective implements AfterViewInit {

    constructor(
        private control: NgControl,
        private el: ElementRef
    ) {}

    ngAfterViewInit(): void {
        this.control.valueChanges?.subscribe(data=>{
            let start=this.el.nativeElement.selectionStart;
            let end = this.el.nativeElement.selectionEnd;
            this.control.control?.setValue(String(data || ''),{emitEvent:false});
            this.el.nativeElement.setSelectionRange(start,end);
        })
    }

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        if (
            this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
            || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
            || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
            || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
            || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
            || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
            || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
            || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
            || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }
        
        if (e.key.match(/ /g)) {
            e.preventDefault();
        }
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        let pastedInput = '';
        pastedInput = String(event.clipboardData?.getData("text/plain")).replace(/ /g, "");
        
        pastedInput && document.execCommand("insertText", false, pastedInput);
    }
}
