<section id="footer">
    <div class="container">
        <img
            src="./assets/images/common/company-logo/bpims-logo2.png"
            alt="BPI/MS Insurance"
        />
        <div>
            <a (click)="setFAQ('faq', 'faq')">Frequently Asked Questions</a>
            <a href="{{onlineTerms}}" target="_blank">Terms and Conditions</a>
            <a href="{{privacyLink}}" target="_blank">Privacy Statement</a>
            <a href="{{customerCharter}}" target="_blank">Customer Charter</a>
        </div>
    </div>
    <div class="container block">
        <p>
            For concerns, you may call BPI MS at (02) 8840-9000 or send an
            e-mail to helpline@bpims.com. Our representatives will be glad to
            assist you.
        </p>
        <br />
        <p>
            BPI/MS Insurance Corporation is regulated by the Insurance
            Commission (IC). For more details, visit
            https://www.insurance.gov.ph.
        </p>
        <br />
        <p>
            IC Public Assistance Office contact details: Landline: (02)
            8523-8461 loc. 103/127 SMS: 09171160007 or 09999930637 E-mail:
            publicassistance@insurance.gov.ph Offices:
            https://www.insurance.gov.ph/contact-us/
        </p>
    </div>
</section>
