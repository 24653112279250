import { Injectable } from '@angular/core';

declare const fbq: (arg0: string, arg1: String, arg2?: Object) => void;

@Injectable({
    providedIn: 'root'
})
export class FacebookEventAnalyticsService {
    constructor() {}

    public trackEvent(category: String) {
        if (typeof fbq === 'undefined') {
            return;
        }
        fbq('trackCustom', category);
    }

    public trackEventWithData(category: String, properties: Object) {
        if (typeof fbq === 'undefined') {
            return;
        }
        fbq('track', category, properties);
    }
}
