import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FAQService } from 'src/app/services/faq.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.html'
})
export class FooterComponent { 
    privacyLink:string = "";
    faq:any [];
    faqProduct:any[];
    paramSublines:any[];
    onlineTerms:string;
    customerCharter:string;
    faqGroupCd: number = 0;

    constructor (
        private router: Router,
        private jsonDataService: JsonDataService,
        private parameterService: ParameterService,
        private faqService: FAQService
    ) {
        this.faqGroupCd = this.jsonDataService.data.refCds.filter((a: any) => a.rvDomain === "BMM074.GROUP_CD" && a.rvMeaning === 'Main')[0]['rvLowValue']*1;

        this.privacyLink = this.parameterService.paramV("PRIVACY_LINK");
        this.faq = this.jsonDataService.data.faq.filter((a: any) => a.groupCd === this.faqGroupCd);
        this.faqProduct = this.jsonDataService.data.faqProduct;
        this.paramSublines = this.jsonDataService.data.sublines;
        this.onlineTerms = this.parameterService.paramV("ACCEPT_ONLINE_TERMS");
        this.customerCharter = this.parameterService.paramV("CUST_CHARTER");
    }

    setFAQ(lineCd:string, sublineCd:string):void {
        this.faqService.faqInfo.lineCd = lineCd;
        this.faqService.faqInfo.sublineCd = sublineCd;
        this.faqService.faqInfo.content = [];

        if (this.faqService.faqInfo.sublineCd === "faq") {
            this.faqService.faqInfo.title = "";
            this.faqService.faqInfo.content = this.faq;
            this.router.navigate(["/faq"]);
        } else {
            this.paramSublines.forEach((a:any) => {
                if (a.lineCd == lineCd && a.sublineCd == sublineCd) {
                    this.faqService.faqInfo.title = a.sublineName;
                }
            });

            this.faqProduct.forEach((a:any) => {
                if (a.lineCd === this.faqService.faqInfo.lineCd && a.subLineCd === this.faqService.faqInfo.sublineCd) {
                    this.faqService.faqInfo.content.push(a);
                }
            });

            this.router.navigate(["/faq"]);
        }
    }
}