import { Injectable } from '@angular/core';
import { PopupComponent } from '../components/common/popup/popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  popup!:PopupComponent;

  constructor() { }

  setPopUpComponent(popup: PopupComponent){
    this.popup = popup;
  }
}
