<section id="property-details">
    <div class="container">
        <form [formGroup]="form">
            <div id="property-details-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div class="form">
                        <div class="property-details-form-msg top"></div>
                        <div class="col title">PROPERTY</div>
                        <!-- <div class="col-case">
                            <div class="field required">
                                <label class="rdo-label">Address of Property to be Insured:</label>
                                <mat-radio-group formControlName="addType" class="address-type-radio-group">
                                    <mat-radio-button class="address-type-radio-button" value=1>Same as Present</mat-radio-button>
                                    <mat-radio-button class="address-type-radio-button" value=2>Same as Permanent</mat-radio-button>
                                    <mat-radio-button class="address-type-radio-button" value=3>Different Address</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div> -->
                        <div class="col-case col-2">
                            <!-- Address Field -->
                            <!-- <div class="field required">
                                <label>Complete Address</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Complete Address" formControlName="compAdd" maxlength="500" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'compAdd')">
                                    {{formService.getFieldErrorMsg(form, "compAdd")}}
                                </p>
                            </div> -->
                            <div class="field" [class.required]="reqHouseNo">
                                <label>House No./Lot/Block</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="House No./Lot/Block" formControlName="houseNo" maxlength="50" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'houseNo')">
                                    {{formService.getFieldErrorMsg(form, "houseNo")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="reqStreet">
                                <label>Street</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Street" formControlName="street" maxlength="250" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'street')">
                                    {{formService.getFieldErrorMsg(form, "street")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case col-2">
                            <div class="field">
                                <label>Subdivision</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Subdivision" formControlName="subdivName" maxlength="250" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'subdivName')">
                                    {{formService.getFieldErrorMsg(form, "subdivName")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="reqBrgy">
                                <label>Barangay</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Barangay" formControlName="brgy" maxlength="250" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'brgy')">
                                    {{formService.getFieldErrorMsg(form, "brgy")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case col-2">
                            <!-- Country Field -->
                            <!-- <div class="field required">
                                <label>Country</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setCountryPlaceholder('country')" formControlName="country" [matAutocomplete]="autoCountry" (input)="setCountryViaInput('country')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                    <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryViaOption($event.option, 'country')">
                                        <mat-option *ngFor="let option of filteredCountry | async" [value]="option">{{option.text}}</mat-option>
                                    </mat-autocomplete>    
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'country')">
                                    {{formService.getFieldErrorMsg(form, "country")}}
                                </p>
                            </div> -->
                            <!-- Province or City Field -->
                            <div class="field required">
                                <label>Province or City</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setProvPlaceholder('provOrCity')" formControlName="provOrCity" [matAutocomplete]="autoProv" (input)="setProvViaInput('provOrCity', $event)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                    <mat-autocomplete #autoProv="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvViaOption($event.option, 'postCode')">
                                        <mat-option *ngFor="let option of filteredProvince | async" [value]="option" [attr.provinceCd]="option.provCd">{{option.text}}</mat-option>
                                    </mat-autocomplete>    
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'provOrCity')">
                                    {{formService.getFieldErrorMsg(form, "provOrCity")}}
                                </p>
                                
                            </div>
                            <!-- Postal Code Field -->
                            <div class="field required">
                                <label>Postal Code</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setPostalPlaceholder('postCode')" formControlName="postCode" [matAutocomplete]="autoPostal" (input)="setPostalViaInput('postCode')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                    <mat-autocomplete #autoPostal="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPostalViaOption($event.option, 'provOrCity')">
                                        <mat-option *ngFor="let option of filteredPostal | async" [value]="option" [attr.provinceCd]="option.provCd" [attr.cityCd]="option.cityCd">{{option.text}}</mat-option>
                                    </mat-autocomplete>    
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'postCode')">
                                    {{formService.getFieldErrorMsg(form, "postCode")}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case col-3">
                            <!-- Google Map button -->
                            <div class="field map-button">
                                <label>&nbsp;</label>
                                <button class="btn btn5 proxima maps" (click)="toggleMap()">{{showMap ? "Close Google Map" : "Open Google Map"}}</button>
                            </div>
                            <!-- LATITUDE FIELD -->
                            <div class="field" [class.required]="reqLat">
                                <label>Latitude</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Latitude" formControlName="latitude" maxlength="10" latLong>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'latitude')">
                                    {{formService.getFieldErrorMsg(form, "latitude")}}
                                </p>
                            </div>
                            <!-- LONGITUDE FIELD -->
                            <div class="field" [class.required]="reqLong">
                                <label>Longitude</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Longitude" formControlName="longitude" maxlength="10" latLong>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'longitude')">
                                    {{formService.getFieldErrorMsg(form, "longitude")}}
                                </p>
                            </div>
                        </div>
                        <div class="caveat" *ngIf="latLongCaveat">
                            <p class="font">{{latLongCaveat}}&nbsp; <a class="link" *ngIf="latLongCaveatLink" (click)="showMapsInsLink()">Click Here.</a>
                        </div>
                        <div class="col-case" [hidden]="!showMap">
                            <div class="field gMap-field">
                                <div appearance="fill" class="gMap">
                                    <input formControlName="gMap" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location In Google Maps" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                </div> 
                            </div> 
                            <div class="field">
                                <agm-map [latitude]="lat" [longitude]="long" (mapClick)="onChoseLocation($event)" [zoom]="zoom">
                                    <agm-marker *ngIf="locationChosen" [latitude]="lat" [longitude]="long" [markerDraggable]="true" (dragEnd)="onChoseLocation($event)"></agm-marker>
                                </agm-map>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case label">
                            <div class="field required">
                                <label>Properties to be Insured</label>
                            </div>
                        </div>
                        <div class="col-case col-2 bldg-cont-case">
                            <div class="field">
                                <label>Amount of Insurance Applied for</label>
                                <ng-container class="field">
                                <form [formGroup]="bldgForm">
                                    <div formArrayName="bldgItms">
                                        <ng-container *ngFor="let b of bldgItms.controls; let i=index">
                                            <ng-container [formGroupName]="i">
                                                <div class="col-case col-2">
                                                    <div class="field">
                                                        <div class="checkbox bldg">
                                                            <mat-checkbox [ngClass]="{'invalid-checkbox' : showChkBoxError }" (change)="chkBox($event.checked, 'bldgInsuredAmt', i)" [checked]="bldg[i]" [disabled]="bldgChkBoxDisabled[i]">{{bldgLabel[i]}}
                                                                <span *ngIf="bldgToolTip[i] != ''">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" [matTooltip]="bldgToolTip[i]" matTooltipPosition="right" class="tooltip" *ngIf="bldgToolTip[i] != ''">help_outline</mat-icon>
                                                                </span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="col-case col-2">
                                                            <div class="field currency">
                                                                <mat-form-field appearance="fill">
                                                                    <input matInput formControlName="bldgCurrency" class="currency-input">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="field currency-field">
                                                                <mat-form-field appearance="fill">
                                                                    <input matInput class="amount-field" [placeholder]="setAmtPlaceholder()" (input)="chkAmt('bldg', i)" (blur)="chkSILimit()" maxlength="12" formControlName="bldgAmt" amount>
                                                                </mat-form-field>
                                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'bldgAmt')">
                                                                    {{formService.getFieldErrorMsg(form, "bldgAmt")}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </form>
                                </ng-container>
                            </div>
                            <div class="field">
                                <label class="label-hide-cont">&nbsp;</label>
                                <ng-container class="field">
                                <form [formGroup]="contForm">
                                    <div formArrayName="contItms">
                                        <ng-container *ngFor="let c of contItms.controls; let i=index">
                                            <ng-container [formGroupName]="i">
                                                <div class="col-case col-2">
                                                    <div class="field">
                                                        <div class="checkbox cont">
                                                            <mat-checkbox [ngClass]="{'invalid-checkbox' : showChkBoxError }" (change)="chkBox($event.checked, 'contInsuredAmt', i)" [checked]="cont[i]" [disabled]="contChkBoxDisabled[i]">{{contLabel[i]}}
                                                                <span *ngIf="contToolTip[i] != ''">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" [matTooltip]="contToolTip[i]" matTooltipPosition="right" class="tooltip" *ngIf="contToolTip[i] != ''">help_outline</mat-icon>
                                                                </span>
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="col-case col-2">
                                                            <div class="field currency">
                                                                <mat-form-field appearance="fill">
                                                                    <input matInput formControlName="contCurrency" class="currency-input">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="field currency-field">
                                                                <mat-form-field appearance="fill">
                                                                    <input matInput class="amount-field" [placeholder]="setAmtPlaceholder()" (input)="chkAmt('cont', i)" (blur)="chkSILimit()" maxlength="12" formControlName="contAmt" amount>
                                                                </mat-form-field>
                                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'contInsuredAmt')">
                                                                    {{formService.getFieldErrorMsg(form, "contInsuredAmt")}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </form>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-case" >
                            <div class="field">
                                <label>&nbsp;</label>
                                <div class="col-case col-2">
                                    <div class="field total-label-case">
                                        <div class="">
                                            <p class="label-total">Total Sum Insured</p>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="" *ngIf="total">
                                            <p class="label-total">{{ displayedCurrency }}&nbsp;&nbsp;&nbsp;&nbsp;{{total | number:'1.2-2':'en-US'}}</p>
                                            <p class="si-field-error" *ngIf="siError != ''">
                                                {{siError}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="insText != ''">
                            <p class="font">{{insText}}&nbsp; <a class="link" (click)="showLink()">BPI MS Average Clause Condition.</a></p> 
                        </div>
                        <ng-container *ngIf="productInfo.creationProcess === 'quotation' else polBlock">
                            <!-- <div class="form-note">
                                <p>Can't find the brand of your vehicle? <a (click)="contactBPI()">Contact BPI MS</a></p>
                            </div> -->
                        </ng-container>
                        <ng-template #polBlock>
                            <div class="form-note">
                                <p class="font">Do you want to change properties to be insured? <a (click)="backToQuotationProcessing()">Click here to go back to quotation.</a>&nbsp;<a (click)="reprintQuote()">Reprint quote.</a></p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="form">
                        <div class="col-case col-2">
                            <div class="field">
                                <!-- CONSTRUCTION YEAR FIELD -->
                                <div class="field const-year" [class.required]="reqConstYear" *ngIf="!hideConstYear" >
                                    <label class="const-label">Construction Year</label>
                                    <div class="col-case">
                                        <div class="field const-yr-opt">
                                            <mat-radio-group formControlName="constYrOpt" (change)="setConstYrField($event.value)" class="const-year-option-radio-group">
                                                <mat-radio-button class="const-year-option-radio-button exact" value='exact'>Exact Year</mat-radio-button>
                                                <mat-radio-button class="const-year-option-radio-button" value='range'>Range</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <ng-container *ngIf="yrExact else showDropDown">
                                            <div class="field const-field exact">
                                                <mat-form-field appearance="fill">
                                                    <input matInput constYear placeholder="YYYY" formControlName="constYear" maxlength="4" (input)="chkConstYear()" (blur)="setYrBuiltCd()" (keypress)="allowNumericDigitsOnly($event)" class="numbers">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'constYear')">
                                                    {{formService.getFieldErrorMsg(form, "constYear")}}
                                                </p>
                                            </div>
                                        </ng-container>
                                        <ng-template #showDropDown>
                                            <!-- DROPDOWN FIELD -->
                                            <div class="field const-field">
                                                <mat-form-field class="select" appearance="fill">
                                                    <input matInput placeholder="Select" formControlName="yrBuiltCd" [matAutocomplete]="autoYrBuiltCd" (input)="setYrBuiltCdViaInput($event)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                                    <mat-autocomplete #autoYrBuiltCd="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setYrBuiltCdViaOption($event.option)">
                                                        <mat-option *ngFor="let option of filteredYrBuiltCd | async" [value]="option" >{{option.text}}</mat-option>
                                                    </mat-autocomplete>    
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'yrBuiltCd')">
                                                    {{formService.getFieldErrorMsg(form, "yrBuiltCd")}}
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <!-- AGE OF BUILDING FIELD -->
                            <div class="field age" *ngIf="!hideAgeBldg">
                                <label class="age-label" >Age of Building</label>
                                <mat-form-field appearance="fill" class="age-of-bldg">
                                    <input matInput placeholder="Age of Building" formControlName="ageBldg">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'ageBldg')">
                                    {{formService.getFieldErrorMsg(form, "ageBldg")}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case">
                            <!-- NO OF FLOOR FIELD -->
                            <div class="field no-of-floor" [class.required]="reqNoOfFloor">
                                <label>No. of storeys/floors</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="No. of storeys/floors" formControlName="noOfFloor" class="numbers">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'noOfFloor')">
                                    {{formService.getFieldErrorMsg(form, "noOfFloor")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case col-2">
                            <div class="field" [class.required]="reqRoofPropDtl">
                                <label class="rdo-label">The roof is made of:</label>
                                <mat-radio-group formControlName="roofPropDtl" class="roofPropDtl-type-radio-group">
                                    <mat-radio-button class="roofPropDtl-type-radio-button" *ngFor="let roof of roofPropertyDtl" [value]="roof.propDtlId" >{{roof.propDtlDesc}}</mat-radio-button>
                                </mat-radio-group>
                                <div class="field roof-others" [class.required]="reqRoofOthers" *ngIf="!hideRoofOthers">
                                    <label>Please specify</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="Others" formControlName="roofOthers" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'roofOthers')">
                                        {{formService.getFieldErrorMsg(form, "roofOthers")}}
                                    </p>
                                </div>
                            </div>
                            <div class="field" [class.required]="reqWallPropDtl">
                                <label class="rdo-label">The exterior walls are made of:</label>
                                <mat-radio-group formControlName="wallPropDtl" class="wallPropDtl-type-radio-group">
                                        <mat-radio-button class="wallPropDtl-type-radio-button" *ngFor="let wall of wallPropertyDtl" [value]="wall.propDtlId">{{wall.propDtlDesc}}</mat-radio-button>
                                </mat-radio-group>
                                <div class="field wall-others" [class.required]="reqWallOthers" *ngIf="!hideWallOthers">
                                    <label>Please specify</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput placeholder="Others" formControlName="wallOthers" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'wallOthers')">
                                        {{formService.getFieldErrorMsg(form, "wallOthers")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field" [class.required]="reqNearLand" *ngIf="productInfo.creationProcess === 'policy'">
                                <label>Nearest Landmark (place comma for multiple entry)</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Nearest Landmark" formControlName="nearLand" maxlength="500" uppercase [withSpace]="true" [withSpecialCharacters]="true">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'nearLand')">
                                    {{formService.getFieldErrorMsg(form, "nearLand")}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form" *ngIf="!hideMortTag">
                        <div class="col-case">
                            <div class="field mortTag" [class.required]="reqMortTag" *ngIf="!hideMortTag">
                                <label class="rdo-label">Is property mortgaged, or will be mortgaged with?</label>
                                <mat-radio-group formControlName="mortAccTag" class="mort-type-radio-group" (change)="setBankFields($event.value)">
                                    <mat-radio-button class="mort-type-radio-button" value="Y">Yes</mat-radio-button>
                                    <mat-radio-button class="mort-type-radio-button" value="N">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-case col-2">
                            <div class="field bank">
                                <label>Bank or Lender Name</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Bank Name" formControlName="bank" [matAutocomplete]="autoBankDetail" (input)="setBankViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoBankDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setBankViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredBankDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'bank')">
                                    {{formService.getFieldErrorMsg(form, "bank")}}
                                </p>
                            </div>
                            <div class="field loan" [class.required]="reqLAN">
                                <label>Loan Account No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Loan Account No." formControlName="loanAcctNo" maxlength="20">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'loanAcctNo')">
                                    {{formService.getFieldErrorMsg(form, "loanAcctNo")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case mort-set" *ngIf="showCaveat && showMortAmt">
                            <div class="field required bank-policy-doc">
                                <label>Name of Bank to appear in Policy Document</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Name of Bank" formControlName="bankPolDoc" maxlength="500">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'bankPolDoc')">
                                    {{formService.getFieldErrorMsg(form, "bankPolDoc")}}
                                </p>
                            </div>
                        </div>
                        <div class="form-note montserrat caveat" *ngIf="showCaveat">
                            <div [innerHTML]="mortCaveat"></div>
                            <p><a class="link" (click)="showMortLink()">For mortgaged properties, click/tap here for further reminders.</a></p>
                        </div>
                    </div>
                    <div class="form" *ngIf="!hideInsTag">
                        <div class="col-case">
                            <div class="field existIns" [class.required]="reqInsTag">
                                <label class="rdo-label">Do you have an existing insurance for this property?</label>
                                <mat-radio-group formControlName="existInsTag" class="existIns-type-radio-group" (change)="setInsFields($event.value)">
                                    <mat-radio-button class="existIns-type-radio-button" value="N">None</mat-radio-button>
                                    <mat-radio-button class="existIns-type-radio-button" value="Y">Yes</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-case col-2">
                            <div class="field existComp" [class.required]="reqInsFields">
                                <label>Insurance Company</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Insurance Company" formControlName="existInsComp">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'existInsComp')">
                                    {{formService.getFieldErrorMsg(form, "existInsComp")}}
                                </p>
                            </div>
                            <div class="field existchkbox" [ngClass]="reqInsFields ? 'req' : ''">
                                <label class="prop-opt">Property Covered</label>
                                <div class="col-case col-2">
                                    <div class="field">
                                        <div class="checkbox bldg">
                                            <mat-checkbox (change)="chkBox($event.checked, 'existBldg', 0)" [checked]="existBldg" [disabled]="chkBoxInsDisabled">Building</mat-checkbox>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="checkbox cont">
                                            <mat-checkbox (change)="chkBox($event.checked, 'existCont', 0)" [checked]="existCont" [disabled]="chkBoxInsDisabled">Contents</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form" *ngIf="!hideSurrounding">
                        <div class="col-case label">
                            <div class="field" [class.required]="reqSurrounding">
                                <label>Houses, Buildings or Streets surrounding the Property</label>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field surround-field">
                                <div class="surround-label">
                                    <label>Front</label>
                                </div>
                                <div class="field span">
                                    <mat-form-field appearance="fill">
                                        <textarea matInput placeholder="" rows="1" formControlName="surroundF" maxlength="250"></textarea>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'surroundF')">
                                        {{formService.getFieldErrorMsg(form, "surroundF")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field surround-field">
                                <div class="surround-label">
                                    <label>Right</label>
                                </div>
                                <div class="field span">
                                    <mat-form-field appearance="fill">
                                        <textarea matInput placeholder="" rows="1" formControlName="surroundR" maxlength="250"></textarea>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'surroundR')">
                                        {{formService.getFieldErrorMsg(form, "surroundR")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field surround-field">
                                <div class="surround-label">
                                    <label>Left</label>
                                </div>
                                <div class="field span">
                                    <mat-form-field appearance="fill">
                                        <textarea matInput placeholder="" rows="1" formControlName="surroundL" maxlength="250"></textarea>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'surroundL')">
                                        {{formService.getFieldErrorMsg(form, "surroundL")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-case">
                            <div class="field surround-field">
                                <div class="surround-label">
                                    <label>Back</label>
                                </div>
                                <div class="field span">
                                    <mat-form-field appearance="fill">
                                        <textarea matInput placeholder="" rows="1" formControlName="surroundB" maxlength="250"></textarea>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'surroundB')">
                                        {{formService.getFieldErrorMsg(form, "surroundB")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-case col-2">
                            <div class="field first-half">
                                <div class="col-case col-2">
                                    <div class="field">
                                        <div class="col-case col-2">
                                            <div class="field label-front">
                                                <label>Front</label>
                                            </div>
                                            <div class="field text-occupancy occupancy">
                                                <label>Occupancy</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="occupancyF" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'occupancyF')">
                                                    {{formService.getFieldErrorMsg(form, "occupancyF")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical">
                                                <label>Right</label>
                                            </div>
                                            <div class="field text-occupancy occupancy">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="occupancyR" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'occupancyR')">
                                                    {{formService.getFieldErrorMsg(form, "occupancyR")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical">
                                                <label>Left</label>
                                            </div>
                                            <div class="field text-occupancy occupancy">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="occupancyL" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'occupancyL')">
                                                    {{formService.getFieldErrorMsg(form, "occupancyL")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical">
                                                <label>Back</label>
                                            </div>
                                            <div class="field text-occupancy occupancy">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="occupancyB" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'occupancyB')">
                                                    {{formService.getFieldErrorMsg(form, "occupancyB")}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="col-case col-2">
                                            <div class="field label-front label-hide">
                                                <label>Front</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-storey">
                                                <label>No. of Storeys</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="storeyF" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'storeyF')">
                                                    {{formService.getFieldErrorMsg(form, "storeyF")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Right</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-storey">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="storeyR" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'storeyR')">
                                                    {{formService.getFieldErrorMsg(form, "storeyR")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Left</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-storey">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="storeyL" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'storeyL')">
                                                    {{formService.getFieldErrorMsg(form, "storeyL")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Back</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-storey">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="storeyB" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'storeyB')">
                                                    {{formService.getFieldErrorMsg(form, "storeyB")}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <div class="col-case col-2">
                                    <div class="field">
                                        <div class="col-case col-2">
                                            <div class="field label-front label-hide">
                                                <label>Front</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-roof">
                                                <label>Roof</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="roofF" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'roofF')">
                                                    {{formService.getFieldErrorMsg(form, "roofF")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Right</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-roof">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="roofR" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'roofR')">
                                                    {{formService.getFieldErrorMsg(form, "roofR")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Left</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-roof">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="roofL" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'roofL')">
                                                    {{formService.getFieldErrorMsg(form, "roofL")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Back</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-roof">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="roofB" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'roofB')">
                                                    {{formService.getFieldErrorMsg(form, "roofB")}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="col-case col-2">
                                            <div class="field label-front label-hide">
                                                <label>Front</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-ext">
                                                <label>Exterior Walls</label>
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="exWallF" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'exWallF')">
                                                    {{formService.getFieldErrorMsg(form, "exWallF")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Right</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-ext">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="exWallR" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'exWallR')">
                                                    {{formService.getFieldErrorMsg(form, "exWallR")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Left</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-ext">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="exWallL" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'exWallL')">
                                                    {{formService.getFieldErrorMsg(form, "exWallL")}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-case col-2">
                                            <div class="field label-vertical label-hide">
                                                <label>Back</label>
                                            </div>
                                            <div class="field text-occupancy adjust-fields-ext">
                                                <mat-form-field appearance="fill">
                                                    <input matInput placeholder="" formControlName="exWallB" maxlength="500">
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(form, 'exWallB')">
                                                    {{formService.getFieldErrorMsg(form, "exWallB")}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="form" *ngIf="!hideSupportingDoc && userDetailsService.userId">
                        <div class="col-case col-3">
                            <div class="field supporting-docs">
                                <label>Supporting Documents</label>
                                <button class="btn btn6 proxima" (click)="fileInput.click()">Upload File (Total file size up to 10MB)</button>
                                <input type="file" #fileInput (change)="onFileChange($event)" multiple hidden>
                                <p class="error-msg" *ngIf="supportDocErrorMsg">{{supportDocErrorMsg}}</p>
                                <div class="files" *ngFor="let a of form.controls['supportingDocs'].value; index as i">
                                    <p>{{a.filename}}</p>
                                    <div class="buttons">
                                        <p class="view" (click)="onClickView(i)">View</p>
                                        <p class="remove" (click)="onClickDelete(i)">Remove</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="docText != ''">
                            <p>{{docText}}</p>
                        </div>
                    </div>
                    <div class="form" *ngIf="userDetailsService.userId && userDetailsService.accountType !== 'P'">
                        <!-- <div class="col-case">
                            <div class="field">
                                <label>Remarks (Internal use only)</label>
                                <mat-form-field appearance="fill">
                                    <textarea matInput placeholder="Remarks" rows="3" formControlName="remarks" maxlength="1000"></textarea>
                                </mat-form-field>
                            </div>
                        </div> -->
                        <div class="col-case">
                            <div class="field">
                                <label>Internal Notes</label>
                                <mat-form-field appearance="fill">
                                    <textarea matInput placeholder="Internal Notes" rows="3" formControlName="intNotes" maxlength="1000" appLineLimit [maxLines]="2" [maxCharactersPerLine]="60"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-case" *ngIf="userDetailsService.userId && genPageUser.includes(userDetailsService.accountType)">
                        <div class="field">
                            <label>General Page Wordings (Will appear in Policy Document)</label>
                            <mat-form-field appearance="fill">
                                <textarea matInput placeholder="General Page Wordings" rows="3" formControlName="genPageWord" maxlength="3750" appLineLimit></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-btns">
                <button class="btn btn4" (click)="move('back')">Back</button>
                <button class="btn btn5" (click)="checkCoverageDependent('')" *ngIf="userDetailsService.userId">Save</button>
                <button class="btn btn2" (click)="move('next')">{{nextLabel}}</button>
            </div>
        </form>
    </div>
</section>

<overlay #duplicatePropertyOverlay [title]="overlay.duplicateMessageProceed ? 'Confirmation' : 'Information'">
    <div content>{{ overlay.confirmDuplicateMessage }}</div>
    <button mat-button buttons #focusBtn class="btn btn-4" *ngIf="overlay.duplicateMessageProceed" (click)="savePropertyDetails(); duplicatePropertyOverlay.close()">Save</button>
    <button mat-button buttons class="btn" (click)="contactBPI(); duplicatePropertyOverlay.close()" tabindex="0">Contact BPI MS</button>
</overlay>