<section id="sub-verify">
    <form>
        <div id="registration-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <h2>Verify your Account</h2>
                <div class="form">
                    <p>We have sent an email to <b>{{ email }}</b></p>
                    <br><br>
                    <p>You need to verify your email to continue. If you have not received the 
                        verification email, please check your "Spam folder". You can also click 
                        the resend button below to have another email sent to you.</p>
                    <br><br>
                    <a [routerLink]="" (click)="resendEmail()">Resend Verification Email</a>
                </div>
            </div>
            <div class="registration-form-msg bottom"></div>
        </div>
        <!-- <div class="form-btns">
            Already have an account?, Click the Login button &nbsp;
            <button class="btn btn3" tabindex="9" [routerLink]="['/login']">Login</button>
        </div> -->
    </form>
</section>