import { Component, OnInit, ElementRef, ViewChild, NgZone, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { mimeTypes } from 'mime-wrapper';
import { MapsAPILoader } from '@agm/core'
import { getCurrencySymbol, formatNumber } from '@angular/common';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormService } from 'src/app/services/form.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { APICallService } from 'src/app/services/api-call.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { SecurityService } from 'src/app/services/security.service';
import { DownloadService } from 'src/app/services/download.service';
import { OverlayComponent } from 'src/app/components/common/overlay/overlay';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

declare function setInputFormatter(): any;

@Component({
    templateUrl: './property-details.html'
})

export class PropertyDetailsComponent implements OnInit {

    form: FormGroup = this.formBuilder.group({
        addType: [""],
        compAdd: [""],
        houseNo: [""],
        street: [""],
        brgy: [""],
        subdivName: [{ value: "", disabled: true }],
        country: [{ value: "", disabled: true }],
        provOrCity: [{ value: "", disabled: true }],
        postCode: [""],
        latitude: [""],
        longitude: [""],
        gMap: [""],
        currency: [{ value: "", disabled: true }],
        constYear: [{ value: "", disabled: true }],
        constYrOpt: [""],
        yrBuiltCd: ["", [this.formService.autocomplete(), Validators.required]],
        ageBldg: [{ value: "", disabled: true }],
        noOfFloor: [{ value: "", disabled: true }],
        nearLand: [""],
        roofPropDtl: [{ value: "", disabled: true }],
        roofOthers: [{ value: "", disabled: true }],
        wallPropDtl: [{ value: "", disabled: true }],
        wallOthers: [{ value: "", disabled: true }],
        mortAccTag: [{ value: "", disabled: true }],
        bank: [{ value: "", disabled: true }],
        loanAcctNo: [{ value: "", disabled: true }],
        bankPolDoc: [""],
        existInsTag: [{ value: "", disabled: true }],
        existInsComp: [{ value: "", disabled: true }],
        existInsBldgTag: [""],
        existInsContTag: [""],
        // occupancyF: [""],
        // occupancyR: [""],
        // occupancyL: [""],
        // occupancyB: [""],
        // storeyF: [""],
        // storeyR: [""],
        // storeyL: [""],
        // storeyB: [""],
        // roofF: [""],
        // roofR: [""],
        // roofL: [""],
        // roofB: [""],
        // exWallF: [""],
        // exWallR: [""],
        // exWallL: [""],
        // exWallB: [""],
        surroundF: [""],
        surroundR: [""],
        surroundL: [""],
        surroundB: [""],
        remarks: [""],
        supportingDocs: [[]],
        genPageWord: [""],
        intNotes:[""]
    });

    bldgForm: FormGroup;
    contForm: FormGroup;
    bldgLabel: any[] = [];
    contLabel: any[] = [];
    bldgToolTip: any[] = [];
    contToolTip: any[] = [];

    lat: number = 14.6023276;
    long: number = 121.045262;
    locationChosen: boolean = false;
    showMap: boolean = false;
    zoom: number = 8;
    address: string = "";
    siError: string = "";
    total: string = "";

    private geoCoder: any;

    @ViewChild('search', { static: true })
    public searchElemRef!: ElementRef;

    productInfo: any = {};


    nextLabel: string = "Next: ";
    supportDocErrorMsg: string = "";
    userType: String = "";
    mortCaveat: string = "";
    docText: string = "";
    insText: string = "";
    insLink: string = "";
    mortLink: string = "";
    latLongCaveat: string = this.parameterService.paramV('GOOGLE_MAP_TEXT');
    latLongCaveatLink: string = this.parameterService.paramV('GOOGLE_MAP_INS_LINK');

    defaultCountry: number = 23; //PHILIPPINES
    minNoFloors: number = 0;
    maxNoFloors: number = 0;
    allowedFileSize: number = 0;

    roofPropertyDtl: any = [];
    wallPropertyDtl: any = [];
    allowedFileExt: any[] = [];
    supportingDocsFiles: any[] = [];
    surroundingPerType: any[] = [];
    bldg: any[] = [];
    cont: any[] = [];
    bldgChkBoxDisabled: any[] = [];
    contChkBoxDisabled: any[] = [];
    showChkBoxError: boolean = false;
    constructionYearMin: number = 1;

    resetCoverage: boolean = false;
    acceptedToDraft: boolean = false;
    existBldg: boolean = false;
    existCont: boolean = false;
    withSupportingDocChange: boolean = false;
    showCaveat: boolean = false;
    yrExact: boolean = true;
    chkBoxDisabled: boolean = false;
    chkBoxInsDisabled: boolean = true;
    chkBoxInsError: boolean = false;

    // AUTO-COMPLETE OPTIONS
    filteredCountry: Observable<any> = new Observable;
    filteredProvince: Observable<any> = new Observable;
    filteredPostal: Observable<any> = new Observable;
    filteredBankDetailOptions: Observable<any>;
    filteredYrBuiltCd: Observable<any>;

    // PARAMETERS FOR HIDDEN FIELD
    hideConstYear: boolean = false;
    hideAgeBldg: boolean = false;
    hideNearLand: boolean = true;
    hideRoofOthers: boolean = true;
    hideWallOthers: boolean = true;
    hideSupportingDoc: boolean = false;
    hideMortTag: boolean = true;
    hideInsTag: boolean = true;
    hideSurrounding: boolean = true;
    showMortAmt: boolean = false;

    // PARAMETERS TO DETERMINE IF SPECIFIC FIELD IS REQUIRED
    requiredHouseNo: string = "";
    requiredStreet: string = "";
    requiredBrgy: string = "";
    requiredLat: string = "";
    requiredLong: string = "";
    requiredConstYear: string = "";
    requiredNoOfFloor: string = "";
    requiredNearLand: string = "";
    requiredRoofPropDtl: string = "";
    requiredWallPropDtl: string = "";
    requiredMortTag: string = "";
    requiredInsTag: string = "";
    requiredSurrounding: string = "";
    requiredLAN: string = "";

    // PARAMETER BASED REQUIRED FIELDS
    reqHouseNo: boolean = false;
    reqStreet: boolean = false;
    reqBrgy: boolean = false;
    reqLat: boolean = false;
    reqLong: boolean = false;
    reqConstYear: boolean = false;
    reqNoOfFloor: boolean = false;
    reqNearLand: boolean = false;
    reqRoofPropDtl: boolean = false;
    reqWallPropDtl: boolean = false;
    reqRoofOthers: boolean = false;
    reqWallOthers: boolean = false;
    reqMortTag: boolean = false;
    reqInsTag: boolean = false;
    reqSurrounding: boolean = false;
    reqLAN: boolean = false;
    reqInsFields: boolean = false;

    genPageUser: any[] = this.parameterService.paramV('DISPLAY_GENERAL_PAGE')?.split(',') || [];
    displayedCurrency: string = "";
    initFormValid: Boolean = true;

    // ? For Duplication Overlay
    @ViewChild("duplicatePropertyOverlay") duplicatePropertyOverlay !: OverlayComponent;
    public overlay = {
        duplicateMessageProceed: false,
        confirmDuplicateMessage: "",
    };

    constructor(
        // PUBLIC
        public formService: FormService,
        public userDetailsService: UserDetailsService,
        public autocompleteService: AutocompleteService,
        public parameterService: ParameterService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private stepperDtlService: StepperDtlService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private dialogBoxService: DialogBoxService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private downloadService: DownloadService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.buildPropertyInfoInProductInfo();
        this.initializeStepperDetail();

        this.defaultCountry = this.parameterService.paramN("PH_COUNTRY_CD");
        this.mortCaveat = this.parameterService.paramV("MORT_ACCT_TEXT") || "";
        this.docText = this.parameterService.paramV("HA_DOCUMENT_TEXT") || "";
        this.insText = this.parameterService.paramV("HA_INSURED_TEXT") || "";
        this.insLink = this.parameterService.paramV("HA_INSURED_LINK") || "";
        this.mortLink = this.parameterService.paramV("MORT_ACCT_LINK") || "";

        this.filteredCountry = this.autocompleteService.set(this.form, "country", this.getCountry());
        this.requiredHouseNo = this.parameterService.paramV("REQ_HA_HOUSE_NO");
        this.requiredStreet = this.parameterService.paramV("REQ_HA_STREET");
        this.requiredBrgy = this.parameterService.paramV("REQ_HA_BRGY");
        this.requiredLat = this.parameterService.paramV("REQ_HA_LAT");
        this.requiredLong = this.parameterService.paramV("REQ_HA_LONG");
        this.requiredConstYear = this.parameterService.paramV("REQ_HA_CONST_YR");
        this.minNoFloors = this.parameterService.paramN("NO_OF_FLOOR_MIN");
        this.maxNoFloors = this.parameterService.paramN("NO_OF_FLOOR_MAX");
        this.requiredNoOfFloor = this.parameterService.paramV("REQ_HA_NO_OF_STOREYS");
        this.requiredNearLand = this.parameterService.paramV("REQ_HA_NEAR_LANDMARK");
        this.requiredRoofPropDtl = this.parameterService.paramV('REQ_HA_ROOF');
        this.requiredWallPropDtl = this.parameterService.paramV('REQ_HA_WALL');
        this.allowedFileExt = this.parameterService.paramV("RISK_DTL_FILE_EXT").split(",");
        this.allowedFileSize = this.parameterService.paramN("RISK_DTL_FILE_SIZE");
        this.requiredMortTag = this.parameterService.paramV("REQ_HA_MORTGAGE");
        this.requiredInsTag = this.parameterService.paramV("REQ_HA_OTH_INSURANCE");
        this.requiredSurrounding = this.parameterService.paramV("REQ_HA_SURROUNDING");
        this.surroundingPerType = this.parameterService.paramV("HA_SURROUNDING").split(",") || [];
        this.requiredLAN = this.parameterService.paramV("REQ_HA_LAN");

        this.bldgForm = this.formBuilder.group({
            bldgItms: this.formBuilder.array([])
        });

        this.contForm = this.formBuilder.group({
            contItms: this.formBuilder.array([])
        });

        for (const bldg of this.jsonDataService.data.firePlanItemCd.filter((a: any) => a.lineCd === this.productInfo.lineCd
            && a.sublineCd === this.productInfo.sublineCd
            && a.planCd === this.productInfo.planInfo.planCd
            && a.colGrp === 1)) {
            var label = bldg.itemLabel || "";
            var toolTip = bldg.itemCdDtl || "";
            if (label === "") {
                for (const b of this.jsonDataService.data.fireItemCd.filter((a: any) => a.itemCd === bldg.itemCd)) {
                    label = b.itemLabel || "";

                }
            }
            if (toolTip === "") {
                for (const b of this.jsonDataService.data.fireItemCd.filter((a: any) => a.itemCd === bldg.itemCd)) {
                    toolTip = b.itemCdDtl || "";

                }
            }
            this.addForm('bldg', label, bldg.itemCd, toolTip, bldg.seqNo);
        }
        for (const cont of this.jsonDataService.data.firePlanItemCd.filter((a: any) => a.lineCd === this.productInfo.lineCd
            && a.sublineCd === this.productInfo.sublineCd
            && a.planCd === this.productInfo.planInfo.planCd
            && a.colGrp === 2)) {
            var label = cont.itemLabel || "";
            var toolTip = cont.itemCdDtl || "";
            if (label === "") {
                for (const b of this.jsonDataService.data.fireItemCd.filter((a: any) => a.itemCd === cont.itemCd)) {
                    label = b.itemLabel || "";
                }
            }
            if (toolTip === "") {
                for (const b of this.jsonDataService.data.fireItemCd.filter((a: any) => a.itemCd === cont.itemCd)) {
                    toolTip = b.itemCdDtl || "";
                }
            }
            this.addForm('cont', label, cont.itemCd, toolTip, cont.seqNo);
        }

        if (!this.productInfo.propertyInfo.addType || this.productInfo.propertyInfo.addType === "") {
            this.productInfo.propertyInfo.addType = "1";
        }
        this.bldgForm.markAsPristine();
        this.contForm.markAsPristine();

        if (!this.productInfo.propertyInfo.constYrOpt || this.productInfo.propertyInfo.constYrOpt === "") {
            if (this.productInfo.quoteId) {
                let constYr = this.productInfo.propertyInfo?.constYear || "";
                if (constYr === '') {
                    this.productInfo.propertyInfo.constYrOpt = "range";
                    this.yrExact = false;
                    this.setConstYrField('range');
                } else {
                    this.productInfo.propertyInfo.constYrOpt = "exact";
                    this.yrExact = true;
                    this.setConstYrField('exact');
                }
            } else {
                this.productInfo.propertyInfo.constYrOpt = "exact";
                this.yrExact = true;
                this.setConstYrField('exact');
            }
        } else {
            let constYr = this.productInfo.propertyInfo?.constYear || "";
            let constYrOpt = this.productInfo.propertyInfo.constYrOpt;
            if (constYr === '' && constYrOpt === 'range') {
                this.productInfo.propertyInfo.constYrOpt = "range";
                this.setConstYrField('range');
            } else {
                this.productInfo.propertyInfo.constYrOpt = "exact";
                this.setConstYrField('exact');
            }
        }

        for (let roof of this.jsonDataService.data.propertyDetails.filter((a: any) => a.propDtlType === 'R')) {
            this.roofPropertyDtl.push(roof);
        }
        for (let wall of this.jsonDataService.data.propertyDetails.filter((a: any) => a.propDtlType === 'W')) {
            this.wallPropertyDtl.push(wall);
        }

        this.productInfo.propertyInfo.currency = this.productInfo.planInfo.currencyCd;
        this.productInfo.propertyInfo.noOfFloor = this.productInfo.propertyAssessment.numFLR;
        this.productInfo.propertyInfo.roofPropDtl = this.productInfo.propertyAssessment.numROOF;
        this.productInfo.propertyInfo.wallPropDtl = this.productInfo.propertyAssessment.numWALL;
        if (this.productInfo.propertyAssessment.isMORT === "Y") {
            this.productInfo.propertyInfo.mortAccTag = this.productInfo.propertyAssessment.isMORT;
            this.productInfo.propertyInfo.bank = this.productInfo.propertyAssessment.bankDetail;
            this.productInfo.propertyInfo.loanAcctNo = this.productInfo.propertyAssessment.loanAccountNo === "" ? this.productInfo.propertyInfo.loanAcctNo : this.productInfo.propertyAssessment.loanAccountNo;
            this.hideMortTag = false;
            this.form.controls["loanAcctNo"].enable();
            if (this.willRequireTheField(this.requiredLAN)) {
                this.reqLAN = true;
                this.form.controls["loanAcctNo"].setValidators([Validators.required]);
            }
            this.form.controls["loanAcctNo"].updateValueAndValidity();
            this.showCaveat = true;
        } else {
            this.productInfo.propertyInfo.mortAccTag = this.productInfo.propertyAssessment.isMORT;
            this.showCaveat = false;
        }

        if (!this.productInfo.propertyInfo.houseNo) {
            this.productInfo.propertyInfo.houseNo = this.productInfo.propertyAssessment.houseNo;
        }

        if (!this.productInfo.propertyInfo.street) {
            this.productInfo.propertyInfo.street = this.productInfo.propertyAssessment.street;
        }

        if (!this.productInfo.propertyInfo.brgy) {
            this.productInfo.propertyInfo.brgy = this.productInfo.propertyAssessment.brgy;
        }

        this.setSubdivName();

        if (this.productInfo.propertyInfo.compAdd === "") {
            this.productInfo.propertyInfo.compAdd = this.productInfo.propertyAssessment.subdivAddress ?? [this.productInfo.propertyAssessment.houseNo, this.productInfo.propertyAssessment.street, this.productInfo.propertyInfo.subdivName, this.productInfo.propertyAssessment.brgy].filter(Boolean).join(' ');
        }

        if (this.productInfo.propertyInfo.existInsTag === "Y") {
            this.setInsFields('Y');
        } else {
            this.productInfo.propertyInfo.existInsTag = "N";
            this.setInsFields('N');
        }
        if (this.productInfo.propertyInfo.existInsBldgTag === "") {
            this.productInfo.propertyInfo.existInsBldgTag = "N";
            this.existBldg = false;
        } else if (this.productInfo.propertyInfo.existInsBldgTag === "Y") {
            this.existBldg = true;
        }
        if (this.productInfo.propertyInfo.existInsContTag === "") {
            this.productInfo.propertyInfo.existInsContTag = "N";
            this.existCont = false;
        } else if (this.productInfo.propertyInfo.existInsContTag === "Y") {
            this.existCont = true;
        }

        this.productService.setProductInfo2(this.productInfo);
        this.form.patchValue(this.productInfo.propertyInfo);

        this.displayedCurrency = getCurrencySymbol(this.form.get("currency")?.value, 'narrow');

        this.chkAmt('', 0);

        this.filteredBankDetailOptions = this.autocompleteService.set(this.form, "bank", this.getBanks());
        this.filteredYrBuiltCd = this.autocompleteService.set(this.form, "yrBuiltCd", this.getYearBuiltCd());

        this.setReqFields();
        this.setHiddenFields();
        this.setConstYearMin();

        this.setDefaultCountry();
        this.setProvViaInput("propertyAssessment", "");
        this.setSupportingDocs();
        this.chkSILimit();
        this.initFormValid = this.form.valid;
    }

    ngOnInit(): void {
        setInputFormatter();
        if (this.productInfo.creationProcess === 'quotation') {
            if (this.productService.productInfo.sublineCd === 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_Quotation_Property_Details')
            }
        } else if (this.productInfo.creationProcess === 'policy') {
            if (this.productService.productInfo.sublineCd === 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_ EPolicy_Property_Details')
            }
        }
        
    }

    get bldgItms(): FormArray {
        return this.bldgForm.get("bldgItms") as FormArray;
    }

    getBldgNewIndex() {
        return this.bldgItms.length - 1;
    }

    get contItms(): FormArray {
        return this.contForm.get("contItms") as FormArray;
    }

    getContNewIndex() {
        return this.contItms.length - 1;
    }

    newForm(form: string, label: string, itemCd: number, toolTip: string, seqNo: number): any {
        if (form === 'bldg') {
            var bldgItmCd = "";
            var bldgCurrency = "";
            var bldgAmt = "";

            var i = this.getBldgNewIndex() + 1;

            bldgItmCd = bldgItmCd.concat("bldgItmCd", i.toString());
            bldgCurrency = bldgCurrency.concat("bldgCurrency", i.toString());
            bldgAmt = bldgAmt.concat("bldgAmt", i.toString());

            this.bldgLabel[i] = label;
            this.bldgToolTip[i] = toolTip;

            if (this.productInfo?.bldgCont.length === 0) {
                return this.formBuilder.group({
                    bldgItmCd: [itemCd],
                    bldgCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                    bldgAmt: [{ value: this.productInfo?.bldgForm?.bldgItms[i]?.bldgAmt || "", disabled: true }],
                    seqNo: [seqNo]
                });
            } else {
                if (this.productInfo?.bldgCont.map(function (a: any) { return a.bldgItmCd; }).indexOf(itemCd) == -1) {
                    return this.formBuilder.group({
                        bldgItmCd: [itemCd],
                        bldgCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                        bldgAmt: [{ value: "", disabled: true }],
                        seqNo: [seqNo]
                    });
                } else {
                    for (const b of this.productInfo.bldgCont.filter((a: any) => a.bldgItmCd === itemCd)) {
                        return this.formBuilder.group({
                            bldgItmCd: [itemCd],
                            bldgCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                            bldgAmt: [{ value: b.bldgAmt, disabled: true }],
                            seqNo: [seqNo]
                        });
                    }
                }
            }
        } else if (form === 'cont') {
            var contItmCd = "";
            var contCurrency = "";
            var contAmt = "";

            var i = this.getContNewIndex() + 1;

            contItmCd = contItmCd.concat("contItmCd", i.toString());
            contCurrency = contCurrency.concat("contCurrency", i.toString());
            contAmt = contAmt.concat("contAmt", i.toString());

            this.contLabel[i] = label;
            this.contToolTip[i] = toolTip;
            if (this.productInfo?.bldgCont.length === 0) {
                return this.formBuilder.group({
                    contItmCd: [itemCd],
                    contCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                    contAmt: [{ value: this.productInfo?.contForm?.contItms[i]?.contAmt || "", disabled: true }],
                    seqNo: [seqNo]
                });
            } else {
                if (this.productInfo?.bldgCont.map(function (a: any) { return a.bldgItmCd; }).indexOf(itemCd) == -1) {
                    return this.formBuilder.group({
                        contItmCd: [itemCd],
                        contCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                        contAmt: [{ value: "", disabled: true }],
                        seqNo: [seqNo]
                    });
                } else {
                    for (const b of this.productInfo.bldgCont.filter((a: any) => a.bldgItmCd === itemCd)) {
                        return this.formBuilder.group({
                            contItmCd: [itemCd],
                            contCurrency: [{ value: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow'), disabled: true }],
                            contAmt: [{ value: b.bldgAmt, disabled: true }],
                            seqNo: [seqNo]
                        });
                    }
                }
            }
        }
    }

    addForm(form: string, label: string, itemCd: number, toolTip: string, seqNo: number) {
        if (form === 'bldg') {
            this.bldgItms.push(this.newForm(form, label, itemCd, toolTip, seqNo));
            this.initialChkBox('bldg');
        } else if (form === 'cont') {
            this.contItms.push(this.newForm(form, label, itemCd, toolTip, seqNo));
            this.initialChkBox('cont');
        }
    }

    initialChkBox(form: string) {
        if (form === 'bldg') {
            var i = this.getBldgNewIndex();
            var arrayControl = this.bldgForm.get('bldgItms') as FormArray;
            var bldgItms = arrayControl.at(i);

            let amt = bldgItms.get("bldgAmt")?.value || "";

            if (amt != "") {
                this.bldg[i] = true;
                bldgItms.get('bldgAmt')?.enable();
                bldgItms.get('bldgAmt')?.setValidators([Validators.required]);
            }

            if (this.productInfo.creationProcess === 'quotation') {
                this.bldgChkBoxDisabled[i] = false;
            } else if (this.productInfo.creationProcess === 'policy') {
                this.bldgChkBoxDisabled[i] = true;
                bldgItms.get('bldgAmt')?.disable();
            }
        } else if (form === 'cont') {
            var i = this.getContNewIndex();
            var arrayControl = this.contForm.get('contItms') as FormArray;
            var contItms = arrayControl.at(i);

            let amt = contItms.get("contAmt")?.value || "";

            if (amt != "") {
                this.cont[i] = true;
                contItms.get('contAmt')?.enable();
                contItms.get('contAmt')?.setValidators([Validators.required]);
            }

            if (this.productInfo.creationProcess === 'quotation') {
                this.contChkBoxDisabled[i] = false;
            } else if (this.productInfo.creationProcess === 'policy') {
                this.contChkBoxDisabled[i] = true;
                contItms.get('contAmt')?.disable();
            }
        }
    }

    buildPropertyInfoInProductInfo(): void {
        this.productService.productInfo.quoteStatCd ? "" : this.productService.setQuoteStat(1);

        this.productInfo = this.productService.productInfo;

        const propertyInfo = this.productInfo.propertyInfo ? this.productInfo.propertyInfo : {
            addType: "",
            compAdd: "",
            house: "",
            street: "",
            brgy: "",
            presentAdd: "",
            permanentAdd: "",
            country: "",
            presentProv: "",
            presentPostal: "",
            permanentProv: "",
            permanentPostal: "",
            provOrCity: "",
            postCode: "",
            latitude: "",
            longitude: "",
            currency: "",
            constYear: "",
            constYrOpt: "",
            yrBuiltCd: "",
            ageBldg: "",
            noOfFloor: "",
            nearLand: "",
            roofPropDtl: "",
            roofOthers: "",
            wallPropDtl: "",
            wallOthers: "",
            mortAccTag: "",
            bank: "",
            loanAcctNo: "",
            bankPolDoc: "",
            existInsTag: "",
            existInsComp: "",
            existInsBldgTag: "",
            existInsContTag: "",
            // occupancyF: "",
            // occupancyR: "",
            // occupancyL: "",
            // occupancyB: "",
            // storeyF: "",
            // storeyR: "",
            // storeyL: "",
            // storeyB: "",
            // roofF: "",
            // roofR: "",
            // roofL: "",
            // roofB: "",
            // exWallF: "",
            // exWallR: "",
            // exWallL: "",
            // exWallB: "",
            surroundF: "",
            surroundR: "",
            surroundL: "",
            surroundB: "",
            remarks: "",
            genPageWord: "",
            intNotes:""
        };

        this.productInfo.bldgCont = this.productInfo.bldgCont ?? [];

        this.productInfo.propertyInfo = propertyInfo;
        this.productService.setProductInfo2(this.productInfo);
    }

    initializeStepperDetail(): void {
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.titleStepperDtl = "Property Details";

        if (this.productInfo.creationProcess === "quotation") {
            this.stepperDtlService.stepperDtlStepNo = 2;
            this.nextLabel += "Coverage";
        } else if (this.productInfo.creationProcess === "policy") {
            this.stepperDtlService.stepperDtlStepNo = 1;
            this.nextLabel += "Customer Info";
        }

        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: "move", actionParam: "back" },
            { class: "btn5", label: "Save", action: "checkCoverageDependent", actionParam: "", isShow: this.userDetailsService.userId ? "true" : "false" },
            { class: "btn2", label: this.nextLabel, action: "move", actionParam: "next" }
        ];
    }

    public onChoseLocation(event: any): void {
        this.lat = event.coords.lat;
        this.long = event.coords.lng;
        this.locationChosen = true;
        this.form.patchValue({
            latitude: String(this.lat).substring(0, 10),
            longitude: String(this.long).substring(0, 10)
        });
        this.form.patchValue({ gMap: "" });
        this.form.markAsDirty();
        this.form.markAsTouched();
    }

    public toggleMap(): void {
        if (this.showMap) {
            this.showMap = false;
            this.locationChosen = false;
        } else {
            this.showMap = true;
            this.mapsAPILoader.load().then(() => {
                this.geoCoder = new google.maps.Geocoder;
                let autocomplete = new google.maps.places.Autocomplete(this.searchElemRef.nativeElement);
                autocomplete.addListener("place_changed", () => {
                    this.ngZone.run(() => {
                        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }
                        this.lat = place.geometry.location.lat();
                        this.long = place.geometry.location.lng();
                        this.zoom = 18;
                        this.locationChosen = true;
                        this.form.patchValue({
                            latitude: String(this.lat).substring(0, 10),
                            longitude: String(this.long).substring(0, 10)
                        });
                    });
                });
            });
        }
    }

    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.long = position.coords.longitude;
                this.zoom = 8;
            });
        }
    }

    private setConstYearMin(): void {
        this.constructionYearMin = Math.min(...this.jsonDataService.data.fireConstYr.map((o: any) => o.yrBuiltFrom));
    }

    getMapAddress(lat: any, long: any) {
        this.geoCoder.geocode({ 'location': { lat: lat, lng: long } }, (results: { formatted_address: string; }[], status: string) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    }

    // ! Obselete method
    getAddress(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getAddress().subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status == "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        this.productInfo.propertyInfo.presentAdd = res.presentAddr;
                        this.productInfo.propertyInfo.permanentAdd = res.permanentAddr;
                        this.productInfo.propertyInfo.country = res.permanentCountryDetail;
                        this.productInfo.propertyInfo.presentProv = res.presentProvinceDetail;
                        this.productInfo.propertyInfo.presentPostal = res.presentPostalCodeDetail;
                        this.productInfo.propertyInfo.permanentProv = res.permanentProvinceDetail;
                        this.productInfo.propertyInfo.permanentPostal = res.permanentPostalCodeDetail;

                        this.productService.setProductInfo2(this.productInfo);

                        if (this.productInfo.propertyInfo.compAdd === "") {

                            if (this.productInfo.propertyInfo.addType === "1") {
                                this.form.patchValue({
                                    compAdd: this.productInfo.propertyInfo.presentAdd,
                                    country: this.productInfo.propertyInfo.country,
                                    provOrCity: this.productInfo.propertyInfo.presentProv,
                                    postCode: this.productInfo.propertyInfo.presentPostal
                                });
                            } else if (this.productInfo.propertyInfo.addType === "2") {
                                this.form.patchValue({
                                    compAdd: this.productInfo.propertyInfo.permanentAdd,
                                    country: this.productInfo.propertyInfo.country,
                                    provOrCity: this.productInfo.propertyInfo.permanentProv,
                                    postCode: this.productInfo.propertyInfo.permanentPostal
                                });
                            }
                        }
                        this.setDefaultCountry();
                    });
                } else {
                    this.setDefaultCountry();
                    this.appMessageService.showAppMessage("Error getting your address. Please fill up the form manually.", "error");
                }
            });
        });
    }

    setSupportingDocs(): void {
        if (this.productInfo.propertyAssessment.suppDocs) {
            if (this.form.controls["supportingDocs"].value.length === 0) {
                if (this.productInfo.creationProcess === "quotation" && this.productInfo.propertyAssessment.suppDocs.length > 0 && this.supportingDocsFiles.length === 0) {
                    this.withSupportingDocChange = true;
                    let supportingDocs = this.form.controls["supportingDocs"].value;
                    for (const supp of this.productInfo.propertyAssessment.supportingDocs) {
                        supportingDocs.push(supp);
                    }
                    this.supportingDocsFiles = this.productInfo.propertyAssessment.suppDocs;
                    this.form.controls["supportingDocs"].setValue(supportingDocs);
                }
            }
        }
    }

    setBankFields(param: string): void {
        if (param === "Y") {
            this.form.controls["bank"].enable();
            this.form.controls["loanAcctNo"].enable();
            this.form.controls["bank"].setValidators([Validators.required]);
            this.form.controls["loanAcctNo"].setValidators([Validators.required]);
        } else {
            this.form.patchValue({ bank: "", loanAcctNo: "" });
            this.form.controls["bank"].clearValidators();
            this.form.controls["loanAcctNo"].clearValidators();
            this.form.controls["bank"].disable();
            this.form.controls["loanAcctNo"].disable();

        }
        this.form.controls["bank"].updateValueAndValidity();
        this.form.controls["loanAcctNo"].updateValueAndValidity();
    }

    setInsFields(param: string): void {
        if (param === "Y") {
            this.form.controls["existInsComp"].enable();
            this.form.controls["existInsComp"].setValidators([Validators.required]);
            this.chkBoxInsDisabled = false;
        } else {
            this.chkBoxInsDisabled = true;
            this.chkBoxInsError = false;
            this.reqInsFields = false;
            this.form.patchValue({ existInsComp: "", existInsBldgTag: "N", existInsContTag: "N" });

            this.existBldg = false;
            this.existCont = false;
            this.form.controls["existInsComp"].clearValidators();
            this.form.controls["existInsComp"].disable();
        }
        this.form.controls["existInsComp"].updateValueAndValidity();
    }

    showLink() {
        window.open(this.insLink);
    }

    showMortLink() {
        window.open(this.mortLink);
    }

    showMapsInsLink(){
        window.open(this.latLongCaveatLink);
    }

    // ! Obselete Method
    setAddress(param: string): void {
        if (param === "1") {
            this.form.patchValue({
                compAdd: this.productInfo.propertyInfo.presentAdd,
                country: this.productInfo.propertyInfo.country,
                provOrCity: this.productInfo.propertyInfo.presentProv,
                postCode: this.productInfo.propertyInfo.presentPostal
            });
        } else if (param === "2") {
            this.form.patchValue({
                compAdd: this.productInfo.propertyInfo.permanentAdd,
                country: this.productInfo.propertyInfo.country,
                provOrCity: this.productInfo.propertyInfo.permanentProv,
                postCode: this.productInfo.propertyInfo.permanentPostal
            });
        } else {
            this.form.patchValue({
                compAdd: "",
                country: this.productInfo.propertyInfo.country,
                provOrCity: "",
                postCode: ""
            });
        }

        if (this.form.controls["country"].value === "") {
            this.setDefaultCountry();
        }
    }

    allowNumericDigitsOnly(event: any) {
        try {
            if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
                return true;
            } else {
                event.preventDefault();
                return false;
            }
        } catch (e) {
            return undefined;
        }
    }

    chkConstYear(): void {
        if (!this.hideAgeBldg && this.yrExact) {
            let year = Number(this.form.controls["constYear"].value);
            let currentYear = Number(new Date().getFullYear());
            let age = (currentYear - year) || 1;

            if (year === 0) {
                this.form.patchValue({ ageBldg: "" });
            } else {
                if (age < 0 || year < this.constructionYearMin) {
                    this.form.patchValue({ ageBldg: "" });
                } else {
                    this.form.patchValue({ ageBldg: age });
                }
            }
        }
    }

    setYrBuiltCd(): void {
        let yr = this.form.controls['constYear']?.value ? parseInt(this.form.controls['constYear']?.value) : '';
        if (yr != '') {
            let range = this.jsonDataService.data.fireConstYr.filter((a: any) => a.yrBuiltFrom <= yr && a.yrBuiltTo >= yr)[0];
            this.form.patchValue({ yrBuiltCd: { value: range.yrBuiltCd, text: range.yrBuiltDesc } });
        }
    }

    setConstYrField(param: any) {
        if (param === 'exact') {
            this.form.controls['constYear'].enable();
            this.form.controls['constYear'].setValidators([this.formService.constYear(this.constructionYearMin), Validators.required]);
            this.form.patchValue({ constYrOpt: "exact" });

            this.hideAgeBldg = false;
            this.yrExact = true;
            this.chkConstYear();

        } else if (param === 'range') {
            this.form.controls['constYear'].clearValidators();
            this.form.controls['constYear'].disable();
            this.form.patchValue({ constYrOpt: "range" });
            this.hideAgeBldg = false;
            this.yrExact = false;
            this.form.patchValue({ constYear: "", ageBldg: "" });
        }
        this.form.controls['constYear'].updateValueAndValidity();
    }

    chkSILimit(): void {
        let siLimit = this.getSILimit();

        if (parseFloat(this.total) <= siLimit?.siAmtMax && parseFloat(this.total) >= siLimit?.siAmtMin) {
            this.siError = "";
        } else {
            this.siError = `Total amount to be insured is not within the allowable range of ${this.displayedCurrency} ${formatNumber(siLimit?.siAmtMax, 'en-us', '1.2-2')}. For assistance, contact BPI MS.`;
        }
    }

    chkAmt(param: string, i: number): void {
        if (param === 'bldg') {
            var arrayControl = this.bldgForm.get('bldgItms') as FormArray;
            var bldgItms = arrayControl.at(i);

            if (Number(bldgItms.get("bldgAmt")?.value) === 0) {
                bldgItms.get('bldgAmt')?.setValue("");
            }
        } else if (param === 'cont') {
            var arrayControl = this.contForm.get('contItms') as FormArray;
            var contItms = arrayControl.at(i);
            if (Number(contItms.get("contAmt")?.value) === 0) {
                contItms.get('contAmt')?.setValue("");
            }
        }
        let bldg = 0;
        Object.keys(this.bldgItms.controls).forEach(key => {
            bldg = Number(bldg) + Number(this.bldgItms.get(key)?.get('bldgAmt')?.value || 0)

        });
        let cont = 0;
        Object.keys(this.contItms.controls).forEach(key => {
            cont = Number(cont) + Number(this.contItms.get(key)?.get('contAmt')?.value || 0)

        });

        this.total = String(bldg + cont);
    }

    private processSave(): void {
        if (this.form.dirty || this.bldgForm.dirty || this.contForm.dirty) {
            if (this.form.valid && this.bldgForm.valid && this.contForm.valid) {
                if (this.bldg.includes(true) || this.cont.includes(true)) {
                    if (this.siError === '') {
                        this.checkDuplicatePropertyExists();
                    } else {
                        this.appMessageService.showAppMessage(this.siError, "info");
                    }
                } else {
                    this.showChkBoxError = true;
                    this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                }
            } else {
                this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
            }
        } else {
            this.appMessageService.showAppMessage("You don't have any changes to save.", "info");
        }
    }

    private checkDuplicatePropertyExists(): void {
        const form = this.form.getRawValue();
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.checkDuplicatePropertyDetails({
            quoteId: this.productInfo.quoteId,
            lineCd: this.productInfo.lineCd,
            sublineCd: this.productInfo.sublineCd,
            houseNo: form.houseNo,
            street: form.street,
            brgy: form.brgy,
            country: form.country.value,
            provOrCity: form.provOrCity.provinceCd,
            cityCd: form.provOrCity.value,
            postCode: form.postCode.value,
            subdivCd: this.productInfo.propertyAssessment.subdivision.value || 0,
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        const { policyPropertyExists, quotePropertyExists } = res;

                        this.overlay.duplicateMessageProceed = true;
                        this.overlay.confirmDuplicateMessage = '';

                        if (policyPropertyExists) {
                            this.overlay.duplicateMessageProceed = false;
                            this.overlay.confirmDuplicateMessage = `Unable to save your property details. This property has already been processed to policy. Please contact BPI MS for more information.`;
                        }

                        if (quotePropertyExists) {
                            this.overlay.duplicateMessageProceed = true;
                            this.overlay.confirmDuplicateMessage = `A quotation has been requested for this property. You may contact BPI MS for more information or click Save to proceed`;
                        }

                        if (this.overlay.confirmDuplicateMessage) {
                            this.jsonDataService.contorlLoading(false);
                            this.duplicatePropertyOverlay.open();
                        }
                        else {
                            this.savePropertyDetails();
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Unable to save your property details. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    savePropertyDetails(): void {
        this.form.patchValue({
            compAdd: [this.form.get('houseNo')?.value, this.form.get('street')?.value, this.form.get('subdivName')?.value, this.form.get('brgy')?.value].filter(Boolean).join(' '),
        });
        const form = this.form.getRawValue();
        const bForm = this.bldgForm.getRawValue();
        const cForm = this.contForm.getRawValue();

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.savePropertyDetails({
            quoteId: this.productInfo.quoteId,
            pvCd: this.productInfo.prodVouchInfo?.prodVouchCd,
            lineCd: this.productInfo.lineCd,
            sublineCd: this.productInfo.sublineCd,
            planCd: this.productInfo.planInfo.planCd,
            addType: null, // Number(form.addType),
            compAdd: form.compAdd,
            houseNo: form.houseNo,
            street: form.street,
            brgy: form.brgy,
            subdivName: form.subdivName,
            country: form.country.value,
            provOrCity: form.provOrCity.provinceCd,
            cityCd: form.provOrCity.value,
            postCode: form.postCode.value,
            subdivCd: this.productInfo.propertyAssessment.subdivision.value || 0,
            zoneCd: this.productInfo.propertyAssessment.subdivision.zoneCd || 1,
            latitude: form.latitude || "",
            longitude: form.longitude || "",
            currency: form.currency,
            buildings: bForm.bldgItms,
            contents: cForm.contItms,
            constYear: form.constYear || "",
            yrBuiltCd: form.yrBuiltCd.value || this.productInfo.propertyInfo.yrBuiltCd.value,
            ageBldg: form.ageBldg || "",
            noOfFloor: form.noOfFloor || "",
            nearLand: form.nearLand || "",
            roofPropDtl: Number(form.roofPropDtl),
            roofOthers: form.roofOthers || "",
            wallPropDtl: Number(form.wallPropDtl),
            wallOthers: form.wallOthers || "",
            mortAccTag: form.mortAccTag || "",
            bank: form.bank.value || "",
            loanAcctNo: form.loanAcctNo || "",
            bankPolDoc: form.bankPolDoc || "",
            existInsTag: form.existInsTag || "",
            existInsComp: form.existInsComp || "",
            existInsBldgTag: form.existInsBldgTag || "",
            existInsContTag: form.existInsContTag || "",
            occupancyF: form.occupancyF || "",
            occupancyR: form.occupancyR || "",
            occupancyL: form.occupancyL || "",
            occupancyB: form.occupancyB || "",
            storeyF: form.storeyF || "",
            storeyR: form.storeyR || "",
            storeyL: form.storeyL || "",
            storeyB: form.storeyB || "",
            roofF: form.roofF || "",
            roofR: form.roofR || "",
            roofL: form.roofL || "",
            roofB: form.roofB || "",
            exWallF: form.exWallF || "",
            exWallR: form.exWallR || "",
            exWallL: form.exWallL || "",
            exWallB: form.exWallB || "",
            surroundF: form.surroundF || "",
            surroundR: form.surroundR || "",
            surroundL: form.surroundL || "",
            surroundB: form.surroundB || "",
            remarks: form.remarks,
            intNotes: form.intNotes,
            roofClassCd: this.productInfo.propertyAssessment.roof.classCd,
            wallClassCd: this.productInfo.propertyAssessment.wall.classCd,
            subdWallTag: this.productInfo.propertyAssessment.isNSUBD || "N",
            acceptedToDraft: this.acceptedToDraft,
            creationProcess: this.productService.productInfo.creationProcess,
            supportingDocs: form.supportingDocs,
            genPageWord: form.genPageWord,
            saveContent: this.bldgForm.dirty || this.contForm.dirty
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (this.supportingDocsFiles.length > 0 && this.withSupportingDocChange) {
                            this.uploadSupportingDocs(0, response);
                        } else {
                            this.proceedAfterSave(response);
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your property details. Please try again later.", "error");
                }
            });
        });

    }

    move(action: string): void {
        if (this.userDetailsService.userId) {
            const savedPropertyInfo = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).propertyInfo;
            if (!savedPropertyInfo.postCode) {
                if (action === "back") {
                    if (this.form.dirty) {
                        this.showConfirm(action);
                    } else {
                        this.proceedMove(action);
                    }
                } else {
                    this.appMessageService.showAppMessage("Please fill and save the property details form first.", "info");
                }
            } else {
                if (action === "next") {
                    if (this.form.valid) {
                        if (!this.initFormValid && this.form.dirty) {
                            this.appMessageService.showAppMessage("Please fill and save the property details form first.", "error")
                        } else if (this.form.dirty || this.bldgForm.dirty || this.contForm.dirty) {
                            this.showConfirm(action);
                        } else {
                            this.proceedMove(action);
                        }
                    } else {
                        this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                    }
                } else {
                    this.proceedMove(action);
                }
            }
        } else {
            if (action === "next") {
                if (this.form.valid && this.bldgForm.valid && this.contForm.valid) {
                    if (this.bldg.includes(true) || this.cont.includes(true)) {
                        if (this.siError === "") {
                            this.checkCoverageDependent(action);
                        } else {
                            this.appMessageService.showAppMessage(this.siError, "info");
                        }
                    } else {
                        this.showChkBoxError = true;
                        this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                    }
                } else {
                    this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                }
            } else {
                this.checkCoverageDependent(action);
            }
        }
    }

    proceedMove(action: string): void {
        if (this.productInfo.creationProcess === "quotation") {
            if (action === "next") {
                this.router.navigate(["/quotation/coverage-perils"]);
            } else {
                this.router.navigate(["/quotation/choose-plan"]);
            }
        } else if (this.productInfo.creationProcess === "policy") {
            if (action === "next") {
                this.router.navigate(["/policy/customer-information"]);
            } else {
                this.router.navigate(["/dashboard"]);
            }
        }
        this.form.patchValue({ gMap: "" });
    }

    showConfirm(action: string): void {
        this.dialogBoxService.open({
            messageType: "C",
            content: "You have unsaved changes. Do you want to continue?",
            buttons: [
                { text: "No" },
                {
                    text: "Yes", action: () => {
                        this.proceedMove(action);
                    }
                }
            ]
        });
    }

    checkIfThereAreChanges(): boolean {
        const savedPropertyInfo = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).propertyInfo;

        let changeTag = false;
        Object.keys(this.form.controls).forEach(field => {
            if (typeof this.form.controls[field].value === "object") {
                Object.keys(this.form.controls[field].value).forEach(fieldValue => {
                    const value = this.form.controls[field].value[fieldValue];
                    if (typeof value === "object" && value) {
                        Object.keys(this.form.controls[field].value[fieldValue]).forEach(fieldValue2 => {
                            if (this.form.controls[field].value[fieldValue][fieldValue2] !== savedPropertyInfo[field][fieldValue][fieldValue2]) {
                                changeTag = true;
                            }
                        });
                    } else if (value !== savedPropertyInfo[field][fieldValue]) {
                        changeTag = true;

                    }
                });
            } else if (this.form.controls[field].value !== savedPropertyInfo[field]) {
                changeTag = true;
            }
        });

        return changeTag;
    }

    checkCoverageDependent(action: string): void {
        const coverage = this.productInfo.coverageInfo;
        if (coverage === undefined || Object.keys(coverage).length === 0 || coverage.tempId === null) {
            this.resetCoverage = false;
            action ? this.processMove(action) : this.processSave();
        } else {
            if (this.willResetCoverage()) {
                let content = this.productService.productInfo.quoteStatCd == 3 ? 'There are changes made which will require re-computing your coverage and change the status of your quotation back to draft. Do you want to proceed?' :
                    'There are changes made which will require re-computing your coverage. Do you want to proceed?';

                this.dialogBoxService.open({
                    messageType: "C",
                    content: content,
                    buttons: [
                        { text: "No" },
                        {
                            text: "Yes", action: () => {
                                this.resetCoverage = true;
                                this.acceptedToDraft = this.productService.productInfo.quoteStatCd == 3;
                                if (action) {
                                    this.processMove(action);
                                } else {
                                    this.processSave();
                                }
                            }
                        }
                    ]
                });
            } else {
                this.resetCoverage = false;
                action ? this.processMove(action) : this.processSave();
            }
        }
    }

    willResetCoverage(): boolean {
        const currentPropertyDetail = this.form.getRawValue();
        const lastPropertyDetail = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).propertyInfo;

        if (this.contForm.dirty || this.bldgForm.dirty
        ) {
            return true;
        } else {
            return false;
        }
    }

    processMove(action: string): void {
        this.form.patchValue({
            compAdd: [this.form.get('houseNo')?.value, this.form.get('street')?.value, this.form.get('subdivName')?.value, this.form.get('brgy')?.value].filter(Boolean).join(' '),
        });
        this.productInfo.propertyInfo = this.form.getRawValue();
        this.productInfo.bldgForm = this.bldgForm.getRawValue();
        this.productInfo.contForm = this.contForm.getRawValue();
        this.productService.setProductInfo2(this.productInfo);
        this.resetCoverage ? this.resetCoverageFunc() : "";
        this.proceedMove(action);
    }

    resetCoverageFunc(): void {
        this.productInfo.perilInfo = [];
        this.productInfo.coverages = [];
        this.productInfo.ara = [];
        this.productInfo.peril = [];
        this.productInfo.taxes = [];
        this.productInfo.coverageInfo = {};
        this.productInfo.deductibles = [];
        this.productInfo.recomputeFlag = true;
        this.productService.setProductInfo2(this.productInfo);
    }

    getSILimit(): any {
        const accountType = this.userDetailsService.accountType;
        const userType = accountType ? accountType : "P";
        return this.jsonDataService.data.minPremLimit.filter((a: any) =>
            a.lineCd === this.productInfo?.lineCd
            && a.sublineCd === this.productInfo?.sublineCd
            && a.planCd === this.productInfo?.planInfo?.planCd
            && a.siAmtMax !== null
        )[0] || this.jsonDataService.data.fireRiskSiLimit.filter((a: any) =>
            a.userType === userType
            && a.userLevel === (this.userDetailsService.userLevel || '0')
        )[0];
    }

    setReqFields() {
        if (this.willRequireTheField(this.requiredHouseNo)) {
            this.reqHouseNo = true;
            this.form.controls['houseNo'].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredStreet)) {
            this.reqStreet = true;
            this.form.controls['street'].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredBrgy)) {
            this.reqBrgy = true;
            this.form.controls['brgy'].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredLat)) {
            this.reqLat = true;
            this.form.controls['latitude'].setValidators([Validators.required, this.formService.latLongValidator()]);
        }
        if (this.willRequireTheField(this.requiredLong)) {
            this.reqLong = true;
            this.form.controls['longitude'].setValidators([Validators.required, this.formService.latLongValidator()]);
        }
        if (this.willRequireTheField(this.requiredConstYear)) {
            this.reqConstYear = true;
            this.hideConstYear = false;
        }
        if (this.willRequireTheField(this.requiredNoOfFloor)) {
            this.reqNoOfFloor = true;
            this.form.controls['noOfFloor'].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredNearLand)) {
            this.reqNearLand = true;
            this.hideNearLand = false;
            this.form.controls["nearLand"].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredRoofPropDtl)) {
            this.reqRoofPropDtl = true;
            this.form.controls["roofPropDtl"].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredWallPropDtl)) {
            this.reqWallPropDtl = true;
            this.form.controls["wallPropDtl"].setValidators([Validators.required]);
        }
        if (this.willRequireTheField(this.requiredMortTag)) {
            this.reqMortTag = true;
            this.form.controls["mortAccTag"].setValidators([Validators.required]);
            this.hideMortTag = false;
        }
        if (this.willRequireTheField(this.requiredInsTag)) {
            this.reqInsTag = true;
            this.form.controls["existInsTag"].enable();
            this.form.controls["existInsTag"].setValidators([Validators.required]);
            this.hideInsTag = false;
        } else if (this.requiredInsTag === 'N' && this.productInfo.creationProcess === 'policy') {
            this.form.controls["existInsTag"].enable();
            this.hideInsTag = false;
        }
        let user = this.userDetailsService.accountType || "P";

        if (this.surroundingPerType.includes(user.toUpperCase()) && this.productInfo.creationProcess == 'policy') {
            this.hideSurrounding = false;
            if (this.willRequireTheField(this.requiredSurrounding)) {
                this.reqSurrounding = true;
                // this.form.controls["occupancyF"].setValidators([Validators.required]);
                // this.form.controls["occupancyR"].setValidators([Validators.required]);
                // this.form.controls["occupancyL"].setValidators([Validators.required]);
                // this.form.controls["occupancyB"].setValidators([Validators.required]);

                // this.form.controls["storeyF"].setValidators([Validators.required]);
                // this.form.controls["storeyR"].setValidators([Validators.required]);
                // this.form.controls["storeyL"].setValidators([Validators.required]);
                // this.form.controls["storeyB"].setValidators([Validators.required]);

                // this.form.controls["roofF"].setValidators([Validators.required]);
                // this.form.controls["roofR"].setValidators([Validators.required]);
                // this.form.controls["roofL"].setValidators([Validators.required]);
                // this.form.controls["roofB"].setValidators([Validators.required]);

                // this.form.controls["exWallF"].setValidators([Validators.required]);
                // this.form.controls["exWallR"].setValidators([Validators.required]);
                // this.form.controls["exWallL"].setValidators([Validators.required]);
                // this.form.controls["exWallB"].setValidators([Validators.required]);

                this.form.controls["surroundF"].setValidators([Validators.required]);
                this.form.controls["surroundR"].setValidators([Validators.required]);
                this.form.controls["surroundL"].setValidators([Validators.required]);
                this.form.controls["surroundB"].setValidators([Validators.required]);
            }
        }

        this.form.controls["country"].setValidators([this.formService.autocomplete(), Validators.required]);
        this.form.controls["provOrCity"].setValidators([this.formService.autocomplete(), Validators.required]);
        this.form.controls["postCode"].setValidators([this.formService.autocomplete(), Validators.required]);

        if (this.productInfo.creationProcess === 'quotation') {
            this.chkBoxDisabled = false;

            this.showMortAmt = false;
        } else if (this.productInfo.creationProcess === 'policy') {
            this.chkBoxDisabled = true;

            if (this.productInfo.propertyInfo.mortAccTag === 'Y') {
                this.showMortAmt = true;
                this.form.controls["bankPolDoc"].setValidators([Validators.required]);
                this.form.patchValue({ bankPolDoc: this.form.controls["bank"]?.value.text });
            } else {
                this.form.controls["bankPolDoc"].clearValidators();
                this.showMortAmt = false;
            }
        }

        this.form.controls["country"].updateValueAndValidity();
        this.form.controls["provOrCity"].updateValueAndValidity();
        this.form.controls["postCode"].updateValueAndValidity();
        this.form.controls["houseNo"].updateValueAndValidity();
        this.form.controls["street"].updateValueAndValidity();
        this.form.controls["brgy"].updateValueAndValidity();
        this.form.controls["latitude"].updateValueAndValidity();
        this.form.controls["longitude"].updateValueAndValidity();
        this.form.controls["noOfFloor"].updateValueAndValidity();
        this.form.controls["nearLand"].updateValueAndValidity();
        this.form.controls["roofPropDtl"].updateValueAndValidity();
        this.form.controls["wallPropDtl"].updateValueAndValidity();
        this.form.controls["mortAccTag"].updateValueAndValidity();
        this.form.controls["bankPolDoc"].updateValueAndValidity();
        this.form.controls["existInsTag"].updateValueAndValidity();

        // this.form.controls["occupancyF"].updateValueAndValidity();
        // this.form.controls["occupancyR"].updateValueAndValidity();
        // this.form.controls["occupancyL"].updateValueAndValidity();
        // this.form.controls["occupancyB"].updateValueAndValidity();

        // this.form.controls["storeyF"].updateValueAndValidity();
        // this.form.controls["storeyR"].updateValueAndValidity();
        // this.form.controls["storeyL"].updateValueAndValidity();
        // this.form.controls["storeyB"].updateValueAndValidity();

        // this.form.controls["roofF"].updateValueAndValidity();
        // this.form.controls["roofR"].updateValueAndValidity();
        // this.form.controls["roofL"].updateValueAndValidity();
        // this.form.controls["roofB"].updateValueAndValidity();

        // this.form.controls["exWallF"].updateValueAndValidity();
        // this.form.controls["exWallR"].updateValueAndValidity();
        // this.form.controls["exWallL"].updateValueAndValidity();
        // this.form.controls["exWallB"].updateValueAndValidity();

        this.form.controls["surroundF"].updateValueAndValidity();
        this.form.controls["surroundR"].updateValueAndValidity();
        this.form.controls["surroundL"].updateValueAndValidity();
        this.form.controls["surroundB"].updateValueAndValidity();
    }

    setHiddenFields() {
        this.supportingDocCheck();
    }

    setOthersField(field: string, param: string) {
        if (field === 'roof') {
            if (param === 'Y') {
                this.hideRoofOthers = false;
                this.form.controls["roofOthers"].setValidators([Validators.required]);
            } else {
                this.hideRoofOthers = true;
                this.form.controls["roofOthers"].clearValidators();
            }
        } else if (field === 'wall') {
            if (param === 'Y') {
                this.hideWallOthers = false;
                this.form.controls["wallOthers"].setValidators([Validators.required]);
            } else {
                this.hideWallOthers = true;
                this.form.controls["wallOthers"].clearValidators();
            }
        }
        this.form.controls["roofOthers"].updateValueAndValidity();
        this.form.controls["wallOthers"].updateValueAndValidity();
    }

    setDefaultCountry() {
        if (this.productInfo.propertyInfo.country === "") {
            for (let count of this.getCountry().filter((a: any) => a.value === this.defaultCountry)) {
                this.form.controls["country"].setValue(count);
            }
        }
        this.resetPostal('postCode');
    }

    willRequireTheField(param: string): boolean {
        return param === "Q" || (param === "P" && this.productInfo.creationProcess === "policy");
    }



    chkBox(event: any, param: string, i: number) {
        this.showChkBoxError = false;
        if (param === 'bldgInsuredAmt') {
            var arrayControl = this.bldgForm.get('bldgItms') as FormArray;
            var bldgItms = arrayControl.at(i);
            if (event) {
                this.bldg[i] = true;
                bldgItms.get('bldgAmt')?.enable();
                bldgItms.get('bldgAmt')?.setValidators([Validators.required]);
            } else {
                this.bldg[i] = false;
                bldgItms.get('bldgAmt')?.clearValidators();
                bldgItms.get('bldgAmt')?.setValue("");
                bldgItms.get('bldgAmt')?.disable();
                this.chkAmt('bldg', i);
                this.chkSILimit();
            }
            bldgItms.get('bldgAmt')?.markAsDirty();
            bldgItms.get('bldgAmt')?.updateValueAndValidity()
        } else if (param === 'contInsuredAmt') {
            var arrayControl = this.contForm.get('contItms') as FormArray;
            var contItms = arrayControl.at(i);
            if (event) {
                this.cont[i] = true;
                contItms.get('contAmt')?.enable();
                contItms.get('contAmt')?.setValidators([Validators.required]);
            } else {
                this.cont[i] = false;
                contItms.get('contAmt')?.clearValidators();
                contItms.get('contAmt')?.setValue("");
                contItms.get('contAmt')?.disable();
                this.chkAmt('cont', i);
                this.chkSILimit();
            }
            contItms.get('contAmt')?.markAsDirty();
            contItms.get('contAmt')?.updateValueAndValidity();
        } else if (param === 'existBldg') {
            if (event) {
                this.existBldg = true;
                this.form.patchValue({ existInsBldgTag: "Y" });
                this.chkBoxInsError = false;
            } else {
                this.existBldg = false;
                this.form.patchValue({ existInsBldgTag: "N" });
            }
            this.form.controls["existInsBldgTag"].markAsDirty();
        } else if (param === 'existCont') {
            if (event) {
                this.existCont = true;
                this.form.patchValue({ existInsContTag: "Y" });
                this.chkBoxInsError = false;
            } else {
                this.existCont = false;
                this.form.patchValue({ existInsContTag: "N" });
            }
            this.form.controls["existInsContTag"].markAsDirty();
        }
    }

    setAmtPlaceholder() {
        return '000,000,000.00';
    }

    proceedAfterSave(response: any): void {
        this.jsonDataService.contorlLoading(false);
        this.withSupportingDocChange = false;
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        sessionStorage.setItem("rid", res.recordId);

        this.productInfo.quoteId = res.quoteId;
        this.productInfo.propertyInfo = this.form.getRawValue();
        this.productInfo.bldgForm = this.bldgForm.getRawValue();
        this.productInfo.contForm = this.contForm.getRawValue();
        this.productInfo.policyHeaderDetails = res.headerDetails;

        if (this.acceptedToDraft) {
            this.productInfo.quoteStatCd = 1;
            this.acceptedToDraft = false;
        }

        if (this.bldgForm.dirty || this.contForm.dirty) {
            this.productInfo.bldgCont = [];
        }
        this.productService.setProductInfo2(this.productInfo);
        this.form.markAsPristine();
        this.bldgForm.markAsPristine();
        this.contForm.markAsPristine();
        this.resetCoverage ? this.resetCoverageFunc() : "";
        this.appMessageService.showAppMessage("Successfully saved.", "success");
    }

    // SUPPORTING DOCUMENTS

    onFileChange(event: any): void {
        this.withSupportingDocChange = true;

        let totalSize = 0;
        this.form.controls["supportingDocs"].value.forEach((el: any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (event.target.files && event.target.files.length) {
            const files = event.target.files;

            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                const element = files[index];

                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.supportDocErrorMsg = "";
                    const type = mimeTypes.getExtension(element.type) || "";

                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        this.supportDocErrorMsg = "Some files were not attached. Accepted file types: " + this.allowedFileExt.join(", ");
                    } else {
                        totalSize += (element.size / 1024 / 1024);

                        if (totalSize > this.allowedFileSize) {
                            this.supportDocErrorMsg = "You have reached the maximum file size for the attachment. Some files were not uploaded.";
                        } else {
                            const supportingDocs = this.form.controls["supportingDocs"].value;
                            supportingDocs.push({
                                filename: element.name,
                                size: element.size
                            });

                            this.supportingDocsFiles.push(element);
                            this.form.controls["supportingDocs"].setValue(supportingDocs);
                            this.form.markAsDirty();
                        }
                    }

                    if (this.supportDocErrorMsg) {
                        setTimeout(() => {
                            this.supportDocErrorMsg = "";
                        }, 5000);
                    }
                };
            }
        }
    }

    onClickView(index: number): void {
        const filename = this.form.controls["supportingDocs"].value[index].filename;

        let newFile = false;
        for (const supportingDocsFile of this.supportingDocsFiles) {
            if (supportingDocsFile.name === filename) {
                newFile = true;
                this.downloadService.downloadFile(supportingDocsFile);
            }
        }

        if (!newFile) {
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getPresignedUrl({
                action: "getObject",
                directory: `supporting-docs/property-details/${this.productInfo.quoteId}/1`,
                filename: filename
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.downloadService.downloadFileUsingPresignedUrl(res.presignedUrl, filename);
                        });
                    }
                });
            });
        }
    }

    onClickDelete(index: number): void {
        this.form.controls["supportingDocs"].setValue(
            this.form.controls["supportingDocs"].value.filter((a: any, i: number) => { return i !== index })
        );
        this.supportingDocsFiles.splice(index, 1);
        this.form.markAsDirty();
        this.withSupportingDocChange = true;
    }

    async uploadSupportingDocs(index: number, response: any): Promise<void> {
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        this.jsonDataService.contorlLoading(true);
        const base64String = await this.formService.getFileAsBase64(this.supportingDocsFiles[index]);
        this.apiCallService.getPresignedUrl({
            action: "putObject",
            directory: `supporting-docs/property-details/${res.quoteId}/${res.itemNo}`,
            filename: this.supportingDocsFiles[index].name,
            validation: "VehicleSuppDoc",
            base64FileString: base64String
        }).subscribe((getPresignedUrlResponse: any) => {
            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                this.jsonDataService.contorlLoading(false);
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.apiCallService.uploadPresignedUrl(
                                res.presignedUrl, this.supportingDocsFiles[index]
                            ).subscribe((uploadPresignedUrlResponse: any) => {
                                if (index === (this.supportingDocsFiles.length - 1)) {
                                    this.proceedAfterSave(response);
                                } else {
                                    this.uploadSupportingDocs(index + 1, response);
                                }
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.supportingDocsFiles = [];
                            this.form.controls["supportingDocs"].setValue([]);
                            this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your property details. Please try again later.", "error");
                }
            });
        });
    }

    supportingDocCheck() {
        const lineCd = this.productInfo.lineCd || "";
        const sublineCd = this.productInfo.sublineCd || "";
        const planCd = this.productInfo.planInfo.planCd || "";
        const plans = this.jsonDataService.data.plans.filter((plans: any) => {
            return plans.supportDocTag && plans.lineCd === lineCd && plans.sublineCd === sublineCd && plans.planCd === planCd
        }).map((supportDocTag: any) => {
            return supportDocTag.supportDocTag
        })[0] || "";
        this.hideSupportingDoc = plans === "Y" ? false : true;
    }

    backToQuotationProcessing(): void {
        this.productInfo.creationProcess = "quotation";
        this.productInfo.previousProcess = "quotation";
        this.productService.setProductInfo2(this.productInfo);
        this.router.navigate(["/quotation/choose-plan"]);
    }

    reprintQuote(): void {
        this.productInfo.creationProcess = "quotation";
        this.productInfo.previousProcess = "policy";
        this.productService.setProductInfo2(this.productInfo);
        this.router.navigate(["/quotation/email-print"]);
    }


    //AUTOCOMPLETE FUNCTIONS
    //--COUNTRY FIELD
    setCountryPlaceholder(params: String): string {
        let placeholder = "";
        if (params === "country") {
            if (typeof this.form.controls["country"].value !== "object"
                || this.formService.isInvalid(this.form, "country")) {
                placeholder = "Please select Country";
            } else {
                placeholder = "Country";
            }
        }
        return placeholder;
    }
    getCountry(): any[] {
        let country: any[] = [];
        for (let count of this.jsonDataService.data.country) {
            country.push({
                value: count.countryCd,
                text: count.countryName
            });
        }
        return country;
    }
    setCountryViaInput(params: String): void {
        for (const count of this.getCountry()) {
            if (params === "country") {
                if (count.text === this.form.controls["country"].value
                    || count.value === this.form.controls["country"].value.value) {
                    this.form.controls["country"].setValue(count);
                    this.resetProv('provOrCity');
                    this.resetPostal('postCode');
                }
                if (this.form.controls["country"].value === "") {
                    this.form.patchValue({ provOrCity: { value: "", text: "", provinceCd: "" } });
                    this.form.patchValue({ postCode: { value: "", text: "", provinceCd: "", cityCd: "" } });
                    this.resetProv('provOrCity');
                    this.resetPostal('postCode');
                }
            }
        }
    }
    setCountryViaOption(option: any, params: string): void {
        const optionEl = option._element.nativeElement;
        if (params === 'country') {
            this.resetProv('provOrCity');
            this.resetPostal('postCode');
        }
    }

    //--PROVINCE OR CITY FIELD
    setProvPlaceholder(params: String): string {
        let placeholder = "";
        if (params === "provOrCity") {
            if (typeof this.form.controls["provOrCity"].value !== "object"
                || this.formService.isInvalid(this.form, "provOrCity")) {
                placeholder = "Please select Province or City";
            } else {
                placeholder = "Province or City";
            }
        }
        return placeholder;
    }
    getProvince(params: string): any[] {
        let provCity: any[] = [];
        let name = "";
        let country = "";
        let prov = "";
        let cityCd = "";

        if (params === 'provOrCity') {
            country = this.form.controls["country"].value.value || this.defaultCountry;
            prov = this.form.controls["postCode"].value.provinceCd || "";
            cityCd = this.form.controls["postCode"].value.cityCd || "";
        } else if (params === 'propertyAssessment') {
            country = this.form.controls["country"].value.value || this.defaultCountry;
            prov = this.productInfo.propertyAssessment.province.value || "";
            cityCd = this.productInfo.propertyAssessment.city.value || "";
        }

        if ((prov === "") && (cityCd === "")) {
            for (let prov of this.jsonDataService.data.province) {
                for (let city of this.jsonDataService.data.city.filter((a: any) => a.provinceCd === prov.provinceCd && a.countryCd === prov.countryCd &&
                    a.countryCd === Number(country))) {
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", prov.provinceName),
                        provinceCd: city.provinceCd
                    });
                }
            }
        } else {
            for (let province of this.jsonDataService.data.province) {
                for (let city of this.jsonDataService.data.city.filter((a: any) => a.provinceCd === prov && a.countryCd === Number(country) &&
                    a.cityCd === Number(cityCd) && a.provinceCd === province.provinceCd && a.countryCd === province.countryCd)) {
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", province.provinceName),
                        provinceCd: city.provinceCd
                    });
                }
            }
        }

        provCity.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return provCity;
    }
    setProvViaInput(params: string, event: any): void {
        for (const prov of this.getProvince(params)) {
            if (params === "provOrCity") {
                if (prov.text === this.form.controls["provOrCity"].value) {
                    this.form.controls["provOrCity"].setValue(prov);
                }
                if (this.form.controls["provOrCity"].value === "") {
                    this.form.patchValue({ postCode: { value: "", text: "", provinceCd: "", cityCd: "" } });
                    this.resetProv(params);
                }

            } else if (params === "propertyAssessment") {
                this.form.patchValue({ provOrCity: prov });
            }
        }
        if (params === "provOrCity" || params === "propertyAssessment") {
            this.resetPostal("postCode");
        }
    }
    setProvViaOption(option: any, params: string): void {
        const optionEl = option._element.nativeElement;
        this.resetPostal(params);
    }
    resetProv(params: string): void {
        if (params === 'provOrCity') {
            this.filteredProvince = this.autocompleteService.set(this.form, params, this.getProvince(params));
        }
        if (this.getProvince(params).length === 1) {
            this.form.controls[params].setValue(this.getProvince(params)[0]);
        }
    }

    //--POSTAL CODE FIELD
    setPostalPlaceholder(params: String): string {
        let placeholder = "";
        if (params === "postCode") {
            if (typeof this.form.controls["postCode"].value !== "object"
                || this.formService.isInvalid(this.form, "postCode")) {
                placeholder = "Please select Postal Code";
            } else {
                placeholder = "Postal Code";
            }
        }
        return placeholder;
    }
    getPostal(params: string): any[] {
        let postal: any[] = [];
        let postName = "";
        let country = "";
        let prov = "";
        let city = "";
        if (params === 'postCode') {
            country = this.form.controls["country"].value.value || this.defaultCountry;
            prov = this.form.controls["provOrCity"].value.provinceCd || "";
            city = this.form.controls["provOrCity"].value.value || "";
        }

        if (prov === "" && city === "") {
            for (let post of this.jsonDataService.data.postalCds.filter((a: any) => a.countryCd === Number(country))) {
                postal.push({
                    value: post.postalCd,
                    text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                    provinceCd: post.provinceCd,
                    cityCd: post.cityCd
                });
            }
        } else {
            for (let post of this.jsonDataService.data.postalCds.filter((a: any) => a.countryCd === Number(country)
                && a.provinceCd === Number(prov) && a.cityCd === Number(city))) {
                postal.push({
                    value: post.postalCd,
                    text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                    provinceCd: post.provinceCd,
                    cityCd: post.cityCd
                });
            }
        }

        postal.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return postal;
    }

    setPostalViaInput(params: string): void {
        for (const post of this.getPostal(params)) {
            if (params === "postCode") {
                if (post.text === this.form.controls["postCode"].value
                    || post.value === this.form.controls["postCode"].value.value) {
                    this.form.controls["postCode"].setValue(post);
                }
            }
        }
    }

    setPostalViaOption(option: any, params: string): void {
        const optionEl = option._element.nativeElement;
    }

    resetPostal(params: string): void {
        if (params === 'postCode') {
            this.filteredPostal = this.autocompleteService.set(this.form, params, this.getPostal(params));
        }
        if (this.getPostal(params).length === 1) {
            this.form.controls[params].setValue(this.getPostal(params)[0]);
        }
    }

    //--BANK FIELD
    getBanks(): any[] {
        let bankOptions = [];
        for (let bank of this.jsonDataService.data.banks) {
            bankOptions.push({
                value: bank.bankCd,
                text: bank.bankName,
            });
        }
        bankOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return bankOptions;
    }
    setBankViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "bank", true, event);
        for (const bank of this.getBanks()) {
            if (bank.text === this.form.controls["bankDetail"].value.text) {
                this.form.controls["bankDetail"].setValue(bank);
            }
        }
    }
    setBankViaOption(option: any): void {
        //this.resetBankDependent();
        //this.validatedBank();
    }

    //--YEAR BUILT CODE FIELD
    getYearBuiltCd(): any[] {
        let yrBuiltCd = [];
        for (let yrBuilt of this.jsonDataService.data.fireConstYr) {
            yrBuiltCd.push({
                value: yrBuilt.yrBuiltCd,
                text: yrBuilt.yrBuiltDesc,
            });
        }
        return yrBuiltCd;
    }
    setYrBuiltCdViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "yrBuiltCd", true, event);
        for (const yrBuilt of this.getYearBuiltCd()) {
            if (yrBuilt.text === this.form.controls["yrBuiltCd"].value.text) {
                this.form.controls["yrBuiltCd"].setValue(yrBuilt);
            }
        }
    }
    setYrBuiltCdViaOption(option: any): void {
        //this.resetBankDependent();
        //this.validatedBank();
    }

    contactBPI(): void {
        let form = this.form.value;
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/contact-us`], {})
        );
        this.router.navigateByUrl(url);
    }

    private setSubdivName() {
        if (!this.productInfo.propertyAssessment.subdivision.value) {
            this.productInfo.propertyInfo.subdivName = this.productInfo.propertyAssessment.subdivName ?? "";
        }
        else {
            this.productInfo.propertyInfo.subdivName = this.productInfo.propertyAssessment.subdivision.text?.toUpperCase() ?? "";
        }
    }
}