import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiTableDataSource } from 'src/app/common/api-table.datasource';
import { OverlayComponent } from 'src/app/components/common/overlay/overlay';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge } from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'lov',
  templateUrl: './lov.component.html'
})

export class LovComponent {

  LOV_TYPES:any = {
    clients :  {
        displayedColumns: ["displayName", "addressPt1"],
        apiCall : 'getClients',
        colDefs: [
          {key:'displayName', header:'Name'},
          {key:'addressPt1', header:'Address'},
        ],
        title:'Customer'
    },
    province : {
        displayedComlumns: ["provinceCd", "provinceName"],
        apiCall : 'getProvince',
        colDefs : [
           {key:'provinceCd', header:'Province Code'},
           {key:'provinceName', header:'Province'},
        ] 
    },
    personalClients : {
      displayedColumns: ["displayName", "birthDate"],
        apiCall : 'getPersonalClients',
        colDefs: [
          {key:'displayName', header:'Name'},
          {key:'birthDate', header:'Birth Date'},
        ],
        title:'Customer'
    },
    corporateClients : {
      displayedColumns: ["companyName", "registrationDate", "registrationNo"],
        apiCall : 'getCorporateClients',
        colDefs: [
          {key:'companyName', header:'Company Name'},
          {key:'registrationDate', header:'Registration Date'},
          {key:'registrationNo', header:'Registration No'},
        ],
        title:'Customer'
    }
  };

  @Input() type:string='';

  @ViewChild(OverlayComponent) overlay!: OverlayComponent;
  overlayRef!:MatDialogRef<any>;
  selectedRow:any = null;

  open(){
    this.dataSource =  new ApiTableDataSource();
    this.dataSource.service = this.mtnService;
    this.dataSource.apiCall = this.LOV_TYPES[this.type].apiCall;
    this.dataSource.type = this.type;
    this.dataSource.loadData(this.params);
    this.overlayRef = this.overlay.open();
    this.initEvents();
  }

  close(){
    this.overlayRef.close();
  }

  onClick(el:any,row:any){
    this.selectedRow = this.selectedRow == row ? null : row;
  }

  onClickOk(){
    this.selected.emit(this.selectedRow);
    this.overlayRef.close();
  }
  // -------------------------------------------------------
  
  dataSource!:ApiTableDataSource;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('input') input!: ElementRef;
  @Output() selected:EventEmitter<any> = new EventEmitter();

  //TO PARAM
  @Input() params:any = {brTag:'R'};
  // END PARAMS -------------------

  @Input() lovType:String = '';

  constructor(
    private mtnService: APICallService, 
    private productService: ProductService) { 
    
  }

  initEvents() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement,'keyup')
        .pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;

                this.filterData(); // SEARCH FILTER
            })
        )
        .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.navigateData()) // CHANGE PAGE
    )
    .subscribe();

  }

  // loadData() { currently not in use
  //   this.params.start = this.paginator.pageIndex * this.paginator.pageSize;
  //   this.params.length = this.paginator.pageSize;
  //   this.params.columnName = this.sort.active;
  //   this.params.columnDir = this.sort.direction;
  //   this.params.search = this.input.nativeElement.value.toString().toUpperCase();
  //   this.dataSource.loadData(this.params);
  // }
  
  navigateData() {
    this.params.start = this.paginator.pageIndex * this.paginator.pageSize;
    this.params.length = this.paginator.pageSize;
    this.params.columnName = this.sort.active;
    this.params.columnDir = this.sort.direction;
    this.params.search = this.input.nativeElement.value.toString().toUpperCase();
    this.dataSource.navigateData(this.params);
  }

  filterData() {
    this.params.start = this.paginator.pageIndex * this.paginator.pageSize;
    this.params.length = this.paginator.pageSize;
    this.params.columnName = this.sort.active;
    this.params.columnDir = this.sort.direction;
    this.params.search = this.input.nativeElement.value.toString().toUpperCase();
    this.dataSource.filterData(this.params);
  }
}
