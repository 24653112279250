import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DecimalPipe, formatNumber, getCurrencySymbol } from '@angular/common'

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service'; 
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProductService } from '../../services/product.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';

@Component({
  selector: 'request-quotation',
  templateUrl: './request-quotation.html',
  styleUrls: ['./request-quotation.css'],
  encapsulation: ViewEncapsulation.None
})
export class RequestQuotationComponent {

    customErrorMsg = {
        mobile: "Not a valid mobile number.",
        fileType: "",
        fileSize: ""
    }

    form:FormGroup = this.formBuilder.group({
        clientName: ["", [Validators.required]],
        mobile: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        message: [""],
        brand: [""],
        yearModel: [""],
        model: [""],
        srp: [""],
        invoiceImg: [""],
        compAddress: ["", [Validators.required]],
        country: [{ value: "", disabled: true }, [Validators.required]],
        province: ["", [Validators.required]],
        city: ["", [Validators.required]],
        postCode: ["", [Validators.required]],
        bldgInsuredAmt: [{ value: "", disabled: true }],
        bldgTag: ["N"],
        contTag: ["N"],
        contInsuredAmt: [{ value: "", disabled: true }],
        existInsBldgTag: [{ value: "", disabled: true }],
        existInsContTag: [{ value: "", disabled: true }],
        roofPropDtl: [{ value: "", disabled: true }],
        roofOthers: [{ value: "", disabled: true }],
        wallPropDtl: [{ value: "", disabled: true }],
        wallOthers: [{ value: "", disabled: true }],
        numFLR: ["", [Validators.required]],
        numROOF: ["", [Validators.required]],
        numWALL: ["", [Validators.required]],
        roof: [""],
        wall: [""],
        currency: [{ value: "", disabled: true }],
    });

    emailParams: any = {
        recipient: "",
        cc: "",
        subject: "",
        emailContent: "",
        attach: []
    }

    allowedFileExt:any = [];
    allowedTotalSize:number = 0;

    custDesc:string = "";
    custNumber:string = "";
    custFaxNo:string = "";
    custEmail:string = "";

    lineCd:string = "";
    siError: string = "";
    total: string = "";

    filteredCountryOptions: Observable<any> = new Observable;
    filteredProvinceOptions: Observable<any> = new Observable;
    filteredCityOptions: Observable<any> = new Observable;
    filteredPostal: Observable<any> = new Observable;
    productInfo: any = {};
    defaultCountry: number = 23; //PHILIPPINES
    existCont: boolean = false;
    existBldg: boolean = false;
    bldg: boolean = false;
    cont: boolean = false;
    reqRoofPropDtl : boolean = false;
    reqWallPropDtl : boolean = false;
    reqRoofOthers: boolean = false;
    hideRoofOthers: boolean = true;
    reqWallOthers: boolean = false;
    hideWallOthers: boolean = true;

    displayedCurrency: string = "";
    
    roofOptions: any = [];
    wallOptions: any = [];

    attachCaveat: string = "";

    constructor (
        // PUBLIC
        public formService: FormService,
        public autocompleteService: AutocompleteService,
        public decimalPipe: DecimalPipe,

        // PRIVATE
        private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private userDetailsService: UserDetailsService,
        private parameterService: ParameterService,
        private securityService: SecurityService,
        private route : ActivatedRoute,
        private router: Router,
        private productService: ProductService
    ) {
        this.getDataJson();
        this.lineCd = this.productService.productInfo.lineCd;
        this.productInfo = this.productService.productInfo;
        this.defaultCountry = this.parameterService.paramN("PH_COUNTRY_CD");

        if (this.userDetailsService.userId && this.userDetailsService.accountType == 'P') {
            this.form.controls["clientName"].setValue(this.userDetailsService.displayName || this.userDetailsService.name);
            this.form.controls['mobile'].setValue(this.userDetailsService.mobileNo);
            this.form.controls["email"].setValue(this.userDetailsService.email);
        }
        this.filteredCountryOptions = this.autocompleteService.set(this.form, "country", this.getCountry());
        this.filteredProvinceOptions = this.autocompleteService.set(this.form, "province", this.getProvinces());
        this.initializeRoofWallValues();
        this.setDefaultCountry();
        this.setDefaultCurrency();
        this.setDefaultPropertyAssessment();

        this.displayedCurrency = getCurrencySymbol(this.form.get("currency")?.value, 'narrow');
    }

    getDataJson():void {
        let cc = this.jsonDataService.data.users.filter((bpims: any) => bpims.bmaCd == this.productService.productInfo?.policyHeaderDetails?.bmaDetail?.value)[0]?.emailAddress || '';
        this.emailParams.recipient = this.parameterService.paramV("REQUEST_FOR_QU_EMAIL");
        if (cc !== '') {
            this.emailParams.cc = this.jsonDataService.data.users.filter((bpims: any) => bpims.bmaCd == this.productService.productInfo?.policyHeaderDetails?.bmaDetail?.value)[0]?.emailAddress;
        }
        this.emailParams.subject = "BPI MS Request for Quotation"; //'BPI MS Request For Quotation';
        this.allowedFileExt = this.parameterService.paramV("CONTACT_US_FILE_EXT").split(",");
        this.allowedTotalSize = this.parameterService.paramN("CONTACT_US_FILE_LIMIT");
        this.custDesc = this.parameterService.paramV("CUST_DESC");
        this.custNumber = this.parameterService.paramV("CUST_NUMBER");
        this.custFaxNo = this.parameterService.paramV("CUST_FAX_NO");
        this.custEmail = this.parameterService.paramV("CUST_EMAIL");
        this.attachCaveat = this.jsonDataService.data.lines.find((line: any) => line.lineCd == this.productService.productInfo?.lineCd)?.attachCaveat || "";
    }

    processContactForm():void {
        if (this.form.valid&&(this.form.controls["bldgTag"].value=="Y"||this.form.controls["contTag"].value=="Y")) {
            if (this.siError === "" ) {
                const form = this.form.value;
                if (this.lineCd === 'HA') {
                    this.emailParams.recipient = this.parameterService.paramV("REQUEST_FOR_QU_EMAIL");
                    this.emailParams.cc = '';
                    this.emailParams.subject = "BPI MS Request for Quotation"; //'BPI MS Request For Quotation';
                    this.emailParams.emailContent =
                    this.parameterService.paramV("NON_STD_OPEN_TEXT") + "\n\n" +
                    "CUSTOMER INFORMATION\n" +
                    "Name: " + form.clientName + "\n" +
                    "Mobile Number: " + form.mobile + "\n" +
                    "Email: " + form.email + "\n\n" +
                    "PROPERTY DETAILS\n" +
                    "Complete Address: " + (form.compAddress ? form.compAddress : "-") + "\n" +
                    "Country: " + (this.form.controls["country"].value ? this.form.controls["country"].value.text : "-") + "\n" +
                    "Province: " + (form.province ? form.province.text : "-") + "\n" +
                    "Postal Code: " + (form.postCode ? form.postCode.text : "-") + "\n" +
                    "Building Amount: " + (form.bldgInsuredAmt ? this.decimalPipe.transform(form.bldgInsuredAmt,'1.2-2') : "-") + "\n" +
                    "Content Amount: " + (form.contInsuredAmt ? this.decimalPipe.transform(form.contInsuredAmt,'1.2-2') : "-") + "\n" +
                    "No. of Storey/Floors: " + (form.numFLR ? form.numFLR : "-") + "\n" +
                    "The roof is made of: " + (form.wall.text ? form.wall.text : "-") + "\n" +
                    "The exterior walls are made of: " + (form.roof.text ? form.roof.text : "-") + "\n" +
                    "Bank or Lender Name: "+ (this.productInfo.propertyAssessment?.bankDetail?.text || '-')+"\n\n" +
                    "MESSAGE FROM CUSTOMER:\n " + (form.message ? form.message : "-") + "\n\n\n" +
                    this.parameterService.paramV("NON_STD_CLOSE_TEXT");

                    this.sendMail(this.emailParams);
                } 
            } else {
                this.appMessageService.showAppMessage(this.siError, "info");
            }
        } else {
            this.appMessageService.showAppMessage("Please fill all the required fields.", "info");
        }
    }

    sendMail(params:any):void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendMail(params).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.resetForm();
                    this.appMessageService.showAppMessage("Your request has been sent. One of our representatives will contact you as soon as possible.", "success");
                    this.router.navigateByUrl(this.router.serializeUrl(this.router.createUrlTree(["/"])));
                } else {
                    this.appMessageService.showAppMessage(response.message, "error");
                }
            });
        });
    }

    resetForm():void {
        this.form.controls["clientName"].setValue("");
        this.form.controls["clientName"].setErrors(null);
        this.form.controls["mobile"].setValue("");
        this.form.controls["mobile"].setErrors(null);
        this.form.controls["email"].setValue("");
        this.form.controls["email"].setErrors(null);
        this.form.controls["message"].setValue("");
        this.form.controls["brand"].setValue("");
        this.form.controls["yearModel"].setValue("");
        this.form.controls["model"].setValue("");
        this.form.controls["srp"].setValue("");
        this.form.controls["invoiceImg"].setValue("");
        this.form.controls["compAddress"].setValue("");
        this.form.controls["compAddress"].setErrors(null);
        this.form.controls["province"].setValue("");
        this.form.controls["province"].setErrors(null);
        this.form.controls["city"].setValue("");
        this.form.controls["city"].setErrors(null);
        this.form.controls["postCode"].setValue("");
        this.form.controls["postCode"].setErrors(null);
        this.form.controls["bldgInsuredAmt"].setValue("");
        this.form.controls["bldgInsuredAmt"].setErrors(null);
        this.form.controls["contInsuredAmt"].setValue("");
        this.form.controls["contInsuredAmt"].setErrors(null);
        this.form.controls["bldgTag"].setValue("N");
        this.form.controls["contTag"].setValue("N");
        this.bldg = false;
        this.cont = false;
        this.form.controls["numFLR"].setValue("");
        this.form.controls["numFLR"].setErrors(null);
        this.form.controls["currency"].setValue("PHP");

        this.emailParams = {
            recipient: "",
            cc: "",
            subject: "",
            emailContent: "",
            attach: []
        };

        this.setDefaultCountry();
    }

    onDrop(event:any):void {
        event.preventDefault();
        this.onFileChange({
            target: {
                files: event.dataTransfer.files
            }
        });
    }

    onDragOver(event:any):any {
        event.stopPropagation();
        event.preventDefault();
    }

    onFileChange(event:any):void {
        this.form.controls["invoiceImg"].reset();
        var invalidAttachment = false;
        var totalSize = 0;

        this.emailParams.attach.forEach((el:any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (event.target.files && event.target.files.length) {
            var files = event.target.files;

            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                const element = files[index];

                reader.readAsDataURL(element);
                reader.onload = () => {
                    var type = element.type.split("/")[1];
                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        invalidAttachment = true;
                    } else {
                        totalSize += (element.size / 1024 / 1024);
                        this.emailParams.attach.push({
                            filename: element.name,
                            path: reader.result,
                            size: element.size,
                            type: element.type
                        });

                        this.form.patchValue({
                            invoiceImg: this.emailParams.attach.map((a:any) => { return a.filename }).join(", ")
                        });
                    }

                    if (index === files.length - 1) {
                        if (invalidAttachment) {
                            this.customErrorMsg.fileType = "Unable to attach file/s. Accepted file types: " + this.allowedFileExt.join(", ");
                        } else {
                            this.customErrorMsg.fileType = "";
                        }

                        if (totalSize > this.allowedTotalSize) {
                            this.customErrorMsg.fileSize = "You have reached the maximum file size for the attachment. (" + this.allowedTotalSize + "MB)";
                        } else {
                            this.customErrorMsg.fileSize = "";
                        }

                        if (invalidAttachment || totalSize > this.allowedTotalSize) {
                            this.form.controls["invoiceImg"].setErrors({ "incorrect": true });
                            this.form.controls["invoiceImg"].markAsTouched();
                        } else {
                            this.form.controls["invoiceImg"].setErrors(null);
                        }

                        if (invalidAttachment) {
                            setTimeout(() => {
                                this.form.controls["invoiceImg"].setErrors(null);
                            }, 3000);
                        }
                    }
                }
            }
        }
    }

    onClickDelete(ind:number):void {
        this.emailParams.attach = this.emailParams.attach.filter((a:any, i:number) => { return i !== ind });

        let totalSize = 0;
        this.emailParams.attach.forEach((el:any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (totalSize > this.allowedTotalSize) {
            this.customErrorMsg.fileSize = "You have reached the maximum file size for the attachment. (" + this.allowedTotalSize + "MB)";
            this.form.controls["invoiceImg"].setErrors({ "incorrect": true });
            this.form.controls["invoiceImg"].markAsTouched();
        } else {
            this.customErrorMsg.fileSize = "";
            this.form.controls["invoiceImg"].setErrors(null);
        }
    }

    getSILimit(): any {
        const accountType = this.userDetailsService.accountType;
        const userType = accountType ? accountType : "P";
        return this.jsonDataService.data.fireRiskSiLimit.filter((a: any) =>
            a.userType === userType
            && a.userLevel === (this.userDetailsService.userLevel || '0')
        )[0];
    }

    chkSILimit (): void {
        let siLimit = this.getSILimit();

        if (parseFloat(this.total) <= siLimit?.siAmtMax && parseFloat(this.total) >= siLimit?.siAmtMin) {
            this.siError = "";
        } else {
            this.siError = `Total amount to be insured is not within the allowable range of ${this.displayedCurrency} ${formatNumber(siLimit?.siAmtMax, 'en-us', '1.2-2')}. For assistance, contact BPI MS.`;
        }
    }

    
    getCountry():any[] {
        let country: any[] = [];
        for (let count of this.jsonDataService.data.country) {
            country.push({
                value: count.countryCd,
                text: count.countryName
            });
        }
        return country;
    }
    setCountryViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "country", true, event);
        for (const p of this.getCountry()) {
            if (p.text === this.form.controls["country"].value.text) {
                this.form.controls["country"].setValue(p);
            }
        }
        
    }
    setCountryViaOption(option: any): void {
        this.form.patchValue({
            province: "",
            city: "",
            postCode: "",
        });
    }

    /* PROVINCE AUTO-COMPLETE FUNCTIONS */

    getProvinces(): any[] {
        let provinceOptions = [];
        for (let p of this.jsonDataService.data.province) {
            provinceOptions.push({
                value: p.provinceCd,
                text: p.provinceName
            });
        }
        provinceOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return provinceOptions;
    }
    setProvinceViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "province", true, event);
        for (const p of this.getProvinces()) {
            if (p.text === this.form.controls["province"].value.text) {
                this.form.controls["province"].setValue(p);
            }
        }
        
    }
    setProvinceViaOption(option: any): void {
        this.form.patchValue({
            city: "",
            postCode: "",
        });
        this.filteredCityOptions = this.autocompleteService.set(this.form, "city", this.getCities(option.value.value));
    }

    /* CITY AUTO-COMPLETE FUNCTIONS */

    getCities(provinceCd: any): any[] {
        let cityOptions = [];
        for (let c of this.jsonDataService.data.city.filter((c: any) => c.provinceCd == provinceCd)) {
            cityOptions.push({
                value: c.cityCd,
                text: c.cityName,
                provinceCd: c.provinceCd
            });
        }
        cityOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return cityOptions;
    }
    setCityViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "city", true, event);
        for (const p of this.getCities(event.option)) {
            if (p.text === this.form.controls["city"].value.text) {
                this.form.controls["city"].setValue(p);
            }
        }
    }
    setCityViaOption(option: any): void {
        this.form.patchValue({
            postCode: ""
        });
        this.filteredPostal = this.autocompleteService.set(this.form, "postCode", this.getPostal(option.value.value));
    }

    
    //Postal Code
    setPostalPlaceholder(params: String):string {
        let placeholder = "";
        if (params === "postCode") {
            if (   typeof this.form.controls["postCode"].value !== "object"
                || this.formService.isInvalid(this.form, "postCode")) {
                placeholder = "Postal Code";
            } else {
                placeholder = "Postal Code";
            }
        } else if (params === "presPostCode") {
            if (   typeof this.form.controls["presPostCode"].value !== "object"
                || this.formService.isInvalid(this.form, "presPostCode")) {
                placeholder = "Postal Code";
            } else {
                placeholder = "Postal Code";
            }
        }
        return placeholder;
    }
    getPostal(params: string):any[] {
        let postal: any[] = [];
        let postName = "";
        let country = this.form.controls["country"].value.value || this.defaultCountry;
        let prov = this.form.controls["city"].value.provinceCd || "";
        let city = this.form.controls["city"].value.value || "";
        if (params === 'postCode') {
            country = this.form.controls["country"].value.value;
            prov = this.form.controls["city"].value.provinceCd || "";
            city = this.form.controls["city"].value.value || "";
        }
        
        if (prov === "" && city === "") {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        } else {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country)
                 && a.provinceCd === Number(prov) && a.cityCd === Number(city))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        }

        postal.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return postal;
    }
    setPostalViaInput(params: string):void {
        for (const post of this.getPostal(params)) {
            if (params === "postCode") {
                if (   post.text === this.form.controls["postCode"].value
                    || post.value === this.form.controls["postCode"].value.value) {
                        this.form.controls["postCode"].setValue(post);
                }
            } else if (params === "presPostCode") {
                if (   post.text === this.form.controls["presPostCode"].value
                    || post.value === this.form.controls["presPostCode"].value.value) {
                        this.form.controls["presPostCode"].setValue(post);
                }
            }
        }
        if (params === "postCode") {
            this.resetProv("provOrCity");
        } else if (params === "presPostCode") {
            this.resetProv("presProv");
        }
    }
    setPostalViaOption(option:any, params:string):void {
        const optionEl = option._element.nativeElement;
        this.resetProv(params);

    }
    resetPostal(params: string):void{
        if (params === 'postCode') {
            this.filteredPostal = this.autocompleteService.set(this.form, params, this.getPostal(params));
        }
        if (this.getPostal(params).length === 1) {
            this.form.controls[params].setValue(this.getPostal(params)[0]);
        }
    }
    
    resetProv(params: string): void {
        if (params === 'province') {
            this.filteredProvinceOptions = this.autocompleteService.set(this.form, params, this.getProvinces());
        } 
        if (this.getProvinces().length === 1) {
            this.form.controls[params].setValue(this.getProvinces()[0]);
        }
    }

    chkBox(event:any, param: string) {
        if (param === 'bldgInsuredAmt') {
            if (event.checked) {
                this.bldg = true;
                this.form.patchValue({bldgTag: "Y"});
                this.form.controls["bldgInsuredAmt"].enable();
                this.form.controls["bldgInsuredAmt"].setValidators([Validators.required]);
            } else {
                this.bldg = false;
                this.form.patchValue({bldgTag: "N"});
                this.form.controls["bldgInsuredAmt"].clearValidators();
                this.form.patchValue({bldgInsuredAmt: ""});
                this.form.controls["bldgInsuredAmt"].disable();
                this.chkAmt('bldg');
                this.chkSILimit();
            }
        } else if (param === 'contInsuredAmt') {
            if (event.checked) {
                this.cont = true;
                this.form.patchValue({contTag: "Y"});
                this.form.controls["contInsuredAmt"].enable();
                this.form.controls["contInsuredAmt"].setValidators([Validators.required]);
            } else {
                this.cont = false;
                this.form.patchValue({contTag: "N"});
                this.form.controls["contInsuredAmt"].clearValidators();
                this.form.patchValue({contInsuredAmt: ""});
                this.form.controls["contInsuredAmt"].disable();
                this.chkAmt('cont');
                this.chkSILimit();
            }
        } else if (param === 'existBldg') {
            if (event.checked) {
                this.existBldg = true;
                this.form.patchValue({existInsBldgTag: "Y"});
            } else {
                this.existBldg = false;
                this.form.patchValue({existInsBldgTag: "N"});
            }
        } else if (param === 'existCont') {
            if (event.checked) {
                this.existCont = true;
                this.form.patchValue({existInsContTag: "Y"});
            } else {
                this.existCont = false;
                this.form.patchValue({existInsContTag: "N"});
            }
        }

        this.form.controls["bldgInsuredAmt"].updateValueAndValidity();
        this.form.controls["contInsuredAmt"].updateValueAndValidity();
    }

    chkAmt(param: string): void {
        if (param === 'bldg') {
            if (Number(this.form.controls["bldgInsuredAmt"].value) === 0) {
                this.form.patchValue({bldgInsuredAmt: ""});
            }
        } else if(param ==='cont') {
            if (Number(this.form.controls["contInsuredAmt"].value) === 0) {
                this.form.patchValue({contInsuredAmt: ""});
            }
        }
        let bldg = this.form.controls["bldgInsuredAmt"].value || 0;
        let cont = this.form.controls["contInsuredAmt"].value || 0;
        
        this.total = String(parseFloat(bldg) + parseFloat(cont));
    }
    
    setRoofValue(roof: any) {
        this.form.patchValue({
            numROOF: roof
        });
        for (const r of this.roofOptions.filter((c: any) => c.value == roof)) {
            this.form.controls["roof"].setValue(r);
        }
    }

    setWallValue(wall: any) {
        this.form.patchValue({
            numWALL: wall
        });
        for (const w of this.wallOptions.filter((w: any) => w.value == wall)) {
            this.form.controls["wall"].setValue(w);
        }
    }

    private initializeRoofWallValues() {
        if (this.roofOptions.length === 0) {
            for (const q of this.jsonDataService.data.propertyDetails.filter((a:any)=> a.propDtlType === 'R')) {
                if (this.productInfo.language === 'E') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'F') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.descFil,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'T') {
                    this.roofOptions.push({
                        value: q.propDtlId,
                        text: q.descTaglish,
                        classCd: q.classCd
                    });
                }
            }
            /*this.form.patchValue({
                numROOF: 1
            });*/
        }

        if (this.wallOptions.length === 0) {
            for (const q of this.jsonDataService.data.propertyDetails.filter((a:any)=> a.propDtlType === 'W')) {
                if (this.productInfo.language === 'E') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'F') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                } else if (this.productInfo.language === 'T') {
                    this.wallOptions.push({
                        value: q.propDtlId,
                        text: q.propDtlDesc,
                        classCd: q.classCd
                    });
                }
            }
            /*this.form.patchValue({
                numWALL: (this.roofOptions.length + 1)
            });*/
        } 
        for (const r of this.roofOptions.filter((c: any) => c.value == this.form.get("numROOF")?.value)) {
            this.form.controls["roof"].setValue(r);
        }
        for (const r of this.wallOptions.filter((c: any) => c.value == this.form.get("numWALL")?.value)) {
            this.form.controls["wall"].setValue(r);
        }
    }

    setAmtPlaceholder() {
        return '000,000,000.00';
    }
    
    floorValidation():boolean {
        let error = false;
        if (this.form.controls["numFLR"].value) {
            const numFLR = this.form.controls["numFLR"].value;
            const min = this.parameterService.paramN("NO_OF_FLOOR_MIN");
            const max = this.parameterService.paramN("NO_OF_FLOOR_MAX");
            if (numFLR === 0 || numFLR < min || numFLR > max) {
                error = true;
            }

            if (error) {
                this.form.controls["numFLR"].setErrors({ "incorrect": true });
                this.form.controls["numFLR"].markAsTouched();
            }
        }
        return error;
    }

    setDefaultCountry() {
        if (this.form.controls["country"].value == "") {
            for (let count of this.getCountry().filter((a:any) => a.value === this.defaultCountry)) {
                this.form.controls["country"].setValue(count);
            }
        }
        this.resetPostal('postCode');
    }
    setDefaultCurrency() {
        if (this.form.controls["currency"].value == "") {
            this.form.patchValue({
                currency: "PHP"
            });
        }
    }

    setDefaultPropertyAssessment() {
        if ([1, 3, 9, 10, 11].includes(this.productInfo.planEvaluated)) {
            this.form.patchValue({
                compAddress: this.productInfo.propertyAssessment.subdivAddress,
                numROOF: this.productInfo.propertyAssessment.numROOF,
                numWALL: this.productInfo.propertyAssessment.numWALL,
                numFLR: this.productInfo.propertyAssessment.numFLR
            });
            for (const p of this.getProvinces()) {
                if (p.text === this.productInfo.propertyAssessment.province.text) {
                    this.form.controls["province"].setValue(p);
                    this.filteredCityOptions = this.autocompleteService.set(this.form, "city", this.getCities(p.value));
                }
            }
            for (const p of this.getCities(this.productInfo.propertyAssessment.province.value)) {
                if (p.text === this.productInfo.propertyAssessment.city.text) {
                    this.form.controls["city"].setValue(p);
                    this.filteredPostal = this.autocompleteService.set(this.form, "postCode", this.getPostal(p.value));
                }
            }
            for (const r of this.roofOptions.filter((c: any) => c.value == this.productInfo.propertyAssessment.numROOF)) {
                this.form.controls["roof"].setValue(r);
            }
            for (const w of this.wallOptions.filter((w: any) => w.value == this.productInfo.propertyAssessment.numWALL)) {
                this.form.controls["wall"].setValue(w);
            }

            if (this.productInfo.propertyAssessment.emailAttachment) { 
                this.emailParams.attach = this.productInfo.propertyAssessment.emailAttachment;
            }
        }
    }

    allowNumericDigitsOnlyWODecimal(event: any) {
        try {
          if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
            return true;
          }
          else {
            event.preventDefault();
            return false;
          }
        }
        catch (e) {
          return undefined; 
        }
    }
}