import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { throwError } from 'rxjs';

@Directive({
    selector: '[tin]'
})
export class TinDirective implements AfterViewInit, Validator {
    //formControl?: AbstractControl;
    constructor(
        private el: ElementRef,
        private control: NgControl
    ) { 
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const allowed = (/^([0-9]{3}\-){3}[0-9]{3,5}?$/g.test(control.value) && control.value.split('').some((a:any)=>a!=control.value[0] && a!='-')) || control.value == '';
        return !allowed ? { 'tin': { value: true } } : null;
    }

    ngAfterViewInit(): void {
        this.el.nativeElement.placeholder = 'XXX-XXX-XXX-XXX';
        /*setTimeout(() => {
            this.control.control?.setValidators(this.control && this.control?.control?.validator ? [this.control.control?.validator, tinValidator()] : [tinValidator()]);
            this.control.control?.updateValueAndValidity();
        }, 0);*/
    }

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        if (
            this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
            || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
            || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
            || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
            || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
            || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
            || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
            || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
            || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if (e.shiftKey || !e.key.match(/^[0-9\-]$/g)) {
            e.preventDefault();
        }
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput = event.clipboardData ? this.formatTin(event.clipboardData.getData("text/plain")) : '';
        pastedInput && document.execCommand("insertText", false, pastedInput);
    }

    @HostListener("blur", ["$event"])
    onBlur() {
        if (this.control.value) {
            this.control.control?.setValue((this.formatTin(this.control.value) + ""));
        }
    }

    private formatTin(str: string): string {
        let n = 0, N = 3;
        return str.replace(/[^0-9\.]/g, "").replace(/[0-9]{3}/g, (match: any) => n++ < N ? `${match}-` : match).substring(0,17);
    }

}

export function tinValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const allowed = (/^([0-9]{3}\-){3}[0-9]{3,5}?$/g.test(control.value) && control.value.split('').some((a:any)=>a!=control.value[0] && a!='-')) || control.value == '';
        return !allowed ? { 'tin': { value: true } } : null;
    };
}