import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appLineLimit]'
})
export class LineLimitDirective {

  @Input() maxLines: number = 14;
  @Input() maxCharactersPerLine: number = 60;

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input') onInput() {
    this.limitLines(this.el.nativeElement, this.maxCharactersPerLine, this.maxLines);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    // Handle paste event
    this.handlePaste(event);
  }

  private limitLines(element: HTMLTextAreaElement, maxLength: number, maxLines: number): void {
    let lines = element.value.split('\n');

    // Limit the number of lines
    if (lines.length > maxLines) {
      lines = lines.slice(0, maxLines);
    }

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > maxLength) {
        lines[i] = lines[i].substring(0, maxLength);
      }
    }

    element.value = lines.join('\n');

    this.ngControl.control?.setValue(element.value);
    this.ngControl.control?.markAsDirty();
  }

  private handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text/plain');
    const currentCursorPosition = this.el.nativeElement.selectionStart;

    const currentValue = this.el.nativeElement.value;
    const newValue =
      currentValue.substring(0, currentCursorPosition) +
      pastedText +
      currentValue.substring(this.el.nativeElement.selectionEnd);

    this.el.nativeElement.value = newValue;

    this.limitLines(this.el.nativeElement, this.maxCharactersPerLine, this.maxLines);
  }
}
