import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
    selector: 'header',
    templateUrl: './header.html'
})
export class HeaderComponent {

    varCompanyLogo1:string = "";
    varCompanyLogo2:string = "";
    aboutUsLink: string = "";
    contactUsLink:string = "";
    ncrBranchLink:string = "";
    luzonBranchLink:string = '';
    visayasBranchLink:string = '';
    mindanaoBranchLink:string = '';

    chkSpTrigger:boolean = false;
    chkSubBranchTrigger:boolean = false;
    chkSubUserTrigger:boolean = false;
    isMenuOpen:boolean = false;

    screenWidth:number = 0;

    constructor (
        // PUBLIC
        public userDetailsService: UserDetailsService,

        // PRIVATE
        private router: Router,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private parameterService: ParameterService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.getScreenWidth();
        this.varCompanyLogo1 = this.parameterService.paramV("COMPANY_LOGO_WHITE");
        this.varCompanyLogo2 = this.parameterService.paramV("COMPANY_LOGO");
        this.aboutUsLink = this.parameterService.paramV("ABOUTUS_LINK");
        this.contactUsLink = this.parameterService.paramV("CONTACT_US_LINK");
        this.ncrBranchLink = this.parameterService.paramV("BRANCH_NCR");
        this.luzonBranchLink = this.parameterService.paramV("BRANCH_LUZON");
        this.visayasBranchLink = this.parameterService.paramV("BRANCH_VISAYAS");
        this.mindanaoBranchLink = this.parameterService.paramV("BRANCH_MINDANAO");
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll() {
        this.isMenuOpen = false;
    }

    @HostListener("window:resize", ["$event"])
    getScreenWidth() {
          this.screenWidth = window.innerWidth;
    }

    toggleHtmlScroll():void {
        const html = document.querySelector("html");

        if (html !== null) {
            if (!this.chkSpTrigger) {
                html.className = "no-scroll";
            } else {
                html.className = "";
                this.chkSubBranchTrigger = false;
                this.chkSubUserTrigger = false;
            }
        }
    }

    navigateTo(path:string):void {
        this.chkSpTrigger = false;
        this.chkSubBranchTrigger = false;
        this.chkSubUserTrigger = false;

        const html = document.querySelector("html");
        if (html !== null) {
            html.className = "";
        }

        this.router.navigateByUrl("/quotation", { skipLocationChange: true }).then(() => {
            this.router.navigate([path]);
        });
    }

    trackContactService():void {
        // this.fbAnalyticsService.trackEvent('Contact');
    }

    getHeaderStyle():string {
        let style = "";
        if (["/", "/login", "/register"].indexOf(this.router.url) >= 0 || this.router.url.indexOf("/?p=") === 0 || this.router.url.indexOf("/renewal-and-payments") === 0) {
            style = "style1";
        } else {
            style = "style2";
        }
        return style;
    }

    getUserName():string {
        let un = this.userDetailsService.name.toUpperCase().trim();

        if (this.screenWidth <= 360 && un.length > 11) {
            un = un.substr(0, 7).trim() + "...";
        } else if (this.screenWidth <= 375 && un.length > 12) {
            un = un.substr(0, 8).trim() + "...";
        } else if (this.screenWidth <= 390 && un.length > 13) {
            un = un.substr(0, 9).trim() + "...";
        } else if (this.screenWidth <= 405 && un.length > 14) {
            un = un.substr(0, 10).trim() + "...";
        } else if (this.screenWidth <= 420 && un.length > 15) {
            un = un.substr(0, 11).trim() + "...";
        } else if (this.screenWidth <= 435 && un.length > 16) {
            un = un.substr(0, 12).trim() + "...";
        } else if (this.screenWidth <= 450 && un.length > 17) {
            un = un.substr(0, 13).trim() + "...";
        } else if (this.screenWidth <= 465 && un.length > 18) {
            un = un.substr(0, 14).trim() + "...";
        } else if (this.screenWidth <= 480 && un.length > 19) {
            un = un.substr(0, 15).trim() + "...";
        } else if (this.screenWidth <= 495 && un.length > 20) {
            un = un.substr(0, 16).trim() + "...";
        } else if (this.screenWidth <= 510 && un.length > 21) {
            un = un.substr(0, 17).trim() + "...";
        } else if (this.screenWidth <= 525 && un.length > 22) {
            un = un.substr(0, 18).trim() + "...";
        } else if (this.screenWidth <= 540 && un.length > 23) {
            un = un.substr(0, 19).trim() + "...";
        } else if (this.screenWidth <= 555 && un.length > 24) {
            un = un.substr(0, 20).trim() + "...";
        } else if (this.screenWidth <= 570 && un.length > 25) {
            un = un.substr(0, 21).trim() + "...";
        } else if (this.screenWidth <= 585 && un.length > 26) {
            un = un.substr(0, 22).trim() + "...";
        } else if (this.screenWidth <= 600 && un.length > 27) {
            un = un.substr(0, 23).trim() + "...";
        } else if (this.screenWidth <= 615 && un.length > 28) {
            un = un.substr(0, 24).trim() + "...";
        } else if (this.screenWidth <= 630 && un.length > 29) {
            un = un.substr(0, 25).trim() + "...";
        } else if (this.screenWidth <= 950 && un.length > 30) {
            un = un.substr(0, 26).trim() + "...";
        } else if (this.screenWidth <= 960 && un.length > 21) {
            un = un.substr(0, 17).trim() + "...";
        } else if (this.screenWidth <= 975 && un.length > 22) {
            un = un.substr(0, 18).trim() + "...";
        } else if (this.screenWidth <= 990 && un.length > 23) {
            un = un.substr(0, 19).trim() + "...";
        } else if (this.screenWidth <= 1005 && un.length > 24) {
            un = un.substr(0, 20).trim() + "...";
        } else if (un.length > 25) {
            un = un.substr(0, 21).trim() + "...";
        }

        return un;
    }

    logout():void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.logout().subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.jsonDataService.contorlLoading(false);
                sessionStorage.setItem("bssid", "1");
                sessionStorage.removeItem("rkc");
                this.navigateTo("/");
                this.userDetailsService.resetUserDetails();
                this.appMessageService.showAppMessage("Logged Out Successfully.", "success");

                // setTimeout(() => {
                //     window.location.reload();
                //     this.userDetailsService.resetUserDetails();
                //     this.appMessageService.showAppMessage("Logged Out Successfully.", "success");
                // });
            });
        });
    }

    gotoLink(value:string):void {
        if (value === "ncr") {
            window.open(this.ncrBranchLink);
        } else if (value === "luzon") {
            window.open(this.luzonBranchLink);
        } else if (value === "visayas") {
            window.open(this.visayasBranchLink);
        } else if (value === "mindanao") {
            window.open(this.mindanaoBranchLink);
        } else {
            this.navigateTo("/");
        }
    }
}