import { Component, OnInit,  ViewChild, AfterViewInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { mimeTypes } from 'mime-wrapper';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from '../../../../services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpClient } from '@angular/common/http';
import { APICallService } from 'src/app/services/api-call.service';
import { DatePipe, formatDate } from '@angular/common';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FormService } from 'src/app/services/form.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayComponent } from 'src/app/components/common/overlay/overlay';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { SecurityService } from 'src/app/services/security.service';
import { DownloadService } from 'src/app/services/download.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

class ImageSnippet {
    pending: boolean = false;
    status: string = 'init';
    constructor(public src: string, public file: File) {}
}

export interface IResizeImageOptions {
    maxSize: number;
    file: File;
}

export interface Id {
    text: '';
}

@Component({
    templateUrl: './customer-information.html',
})

export class PolCustomerInformationComponent implements OnInit, AfterViewInit {
    @ViewChild('validIdList') validIdList!: OverlayComponent;
    @ViewChild('rmConsent') rmConsent!: OverlayComponent;
    /* section for photo/video 
    @ViewChild("video")
    public video!: ElementRef;

    @ViewChild("canvas")
    public canvas!: ElementRef;

    public captures: Array<any>; */

    productInfo:any = {};
    quoteId: String =  "";
    instructions: String = "";
    instructions2: String = "";
    sameAsPermanent: boolean = false;
    noTin : boolean = false;
    resizedImage: string = '';

    reqAssdEmail: String = "";
    reqMobileNo: String = "";
    reqTelNo: String = "";
    reqBirthDate: String = "";
    reqRegNo: String = "";
    reqGender: String = "";
    reqMarital: String = "";
    reqCountryOfBirth: String = "";
    reqCountry: String = "";
    reqPresCountry: String = "";
    reqProvOfBirth: String = "";
    reqProvince: String = "";
    reqPresProv: String = "";
    reqAddress: String = "";
    reqPresAdd: String = "";
    reqPostal: String = "";
    reqPresPostCode: String = "";
    reqNationality: String = "";
    reqFunds: String = "";
    reqNameEmployer: String = "";
    reqNatureEmployment: String = "";
    reqCategory: String = "";
    reqOccupation: String = "";
    reqRisk: String = "";
    reqTin: String = "";
    reqExpDate: String = "";
    minAssdId: Number = 1;
    reqMiddleName: String = "";
    maxDate:Date = new Date();
    minDate:Date = new Date();
    minExpDate:Date = new Date();
    userType: String = "";
    addId1: Number = 1;
    addId2: Number = 0;
    addId3: Number = 3;
    preview1: Number = 0;
    preview2: Number = 0;
    preview3: Number = 0;
    attachmentNo: String = "";
    primaryIdCount: Number = 0;
    tinMax: Number = 17;
    birthMax: Number = 10;
    defaultCountry:Number = 0;
    counter:number = 0;
    supDocsCounter:number = 0;
    occOther:Number = 0;
    withNoTin: any [] = [];
    allowedFileSize: number = 0; 
    setNoTinBox: boolean = false;
    withSupportingDocChange: boolean = false;
    supportDocErrorMsg: string = "";
    allowedFileExt: any[] = [];
    reqRmNo: String = "";
    rmLength: number = 0;
    idReq: boolean = false;
    idMax: number = 0;
    tinErrorMessage: string = "";
    
    chkExp: Number = 0;
    file!: File;

    listId: any [] = [];
    idType: any [] = [];
    supportingDocsFiles: any[] = [];
    
    //for pics display
    ocrStat: any [] = [];
    containers: any [] = [];
    //for uploading file
    fileUpload: any [] = [];

    /*showLov:boolean = true;

    lovParams: any = {
        provOrCityOfBirth: ""
    }*/
    
    // AUTO-COMPLETE OPTIONS
    filteredGender:Observable<any> = new Observable;
    filteredMaritalStat:Observable<any> = new Observable;
    filteredProvOfBirth:Observable<any> = new Observable;
    filteredProvince:Observable<any> = new Observable;
    filteredPresProv:Observable<any> = new Observable;
    filteredPostal:Observable<any> = new Observable;
    filteredPresPostCode:Observable<any> = new Observable;
    filteredCountryOfBirth:Observable<any> = new Observable;
    filteredCountry:Observable<any> = new Observable;
    filteredPresCountry:Observable<any> = new Observable;
    filteredNationality:Observable<any> = new Observable;
    filteredFunds:Observable<any> = new Observable;
    filteredCategory:Observable<any> = new Observable;
    filteredOccupation:Observable<any> = new Observable;
    filteredRisk:Observable<any> = new Observable;

    filteredId1:Observable<Id[]>[] = [];
    
    idForm: FormGroup;

    profileForm: FormGroup = this.fb.group ({
        quoteId: [""],
        assdNo: [""],
        brTag: ["",[Validators.required]],
        lastName: [{value:"", disabled: true}],
        firstName: [{value:"", disabled: true}],
        middleName: [{value:"", disabled: true}],
        compName: [{value:"", disabled: true}],
        dispName: [{value:"", disabled: true}],
        emailAdd: [""],
        mobileNo: [""],
        telNo: [""],
        birthDate: [{value:"", disabled: true}],
        regNo: [""],
        gender: [""],
        maritalStat: [{value:"", disabled: true}],
        countryOfBirth: [""],
        provOrCityOfBirth: [""],
        nationality: [""],
        compAdd: [""],
        countryBirth: [""],
        provOrCity: [""],
        postCode: [""],
        presAdd: [""],
        presAddChkbox:["N"],
        presCountry: [""],
        presProv: [""],
        presPostCode:[""],
        sourceFunds:[""],
        nameEmployer:[""],
        natureEmployment:[""],
        category: [""],
        occupation: [{value:"", disabled: true}],
        occOther: [{value:"", disabled: true}],
        riskPro: [""],
        tinNo: [{value:"", disabled: true}],
        noTin: [""],
        suffix: [""],
        provOrCityOfBirthDesc: [""],
        provOrCityDesc: [""],
        postCodeDesc: [""],
        presProvDesc: [""],
        presPostCodeDesc:[""],
        supportingDocs: [[]],
        rmNo: [""]
    });

    varClientTypes:any = [];
    
    renewalKYCBackPrompt:string = "";
    bpiRmConsentText: string = this.parameterService.paramV("BPI_RM_CONSENT_TEXT");
    fromRm:boolean = false;

    constructor (
        private router: Router,
        private fb: FormBuilder,
        private dp : DatePipe,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private productService: ProductService,
        private jsonService: JsonDataService,
        private utilityService: UtilityService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private parameterService: ParameterService,
        private dialogBoxService: DialogBoxService,
        private securityService: SecurityService,
        private downloadService: DownloadService,
        
        public userDetailsService: UserDetailsService,
        public autocompleteService: AutocompleteService,
        public formService: FormService,
        private activatedRoute: ActivatedRoute,

        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.buildCustomerInfoInProductInfo();
        this.renewalKYCBackPrompt = this.parameterService.paramV("RENEWAL_KYC_BACK_PROMPT");
        // STEPPER DETAIL
        if(this.productInfo.creationProcess==='renewal'){
            this.profileForm.get("maritalStat")?.enable();
            this.profileForm.get("occupation")?.enable();
            this.profileForm.get("birthDate")?.enable();
            this.profileForm.get("firstName")?.disable();
            this.profileForm.get("middleName")?.disable();
            this.profileForm.get("lastName")?.disable();
            this.profileForm.get("suffix")?.disable();
            this.profileForm.get("compName")?.disable();
            this.profileForm.get("dispName")?.disable();
            this.stepperDtlService.showStepperDtl = true;
            this.stepperDtlService.stepperDtlStepNo = 2;
            this.stepperDtlService.titleStepperDtl = "Customer Information";
            this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action:'move', actionParam:'back' },
            { class: "btn5", label: "Save", action:'onSubmit' }
            ];
            this.stepperDtlService.childInstance = this;
        } else{
            this.stepperDtlService.showStepperDtl = true;
            this.stepperDtlService.stepperDtlStepNo = 2;
            this.stepperDtlService.titleStepperDtl = "Customer Information";
            this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action:'move', actionParam:'back' },
            { class: "btn5", label: "Save", action:'onSubmit' },
            { class: "btn2", label: "Proceed", action:'move', actionParam:'forward' }
            ];
            this.stepperDtlService.childInstance = this;
        }

        this.userType = userDetailsService.accountType;

        for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM015.BR_TAG")) {
            this.varClientTypes.push({
                value: refCd.rvLowValue,
                text: refCd.rvMeaning
            });
        }
        
        this.filteredGender = this.autocompleteService.set(this.profileForm, "gender", this.getGender());
        this.filteredMaritalStat = this.autocompleteService.set(this.profileForm, "maritalStat", this.getMaritalStat());
        
        
        this.filteredCountryOfBirth = this.autocompleteService.set(this.profileForm, "countryOfBirth", this.getCountry());
        this.filteredCountry = this.autocompleteService.set(this.profileForm, "countryBirth", this.getCountry());
        this.filteredPresCountry = this.autocompleteService.set(this.profileForm, "presCountry", this.getCountry());
        this.filteredNationality = this.autocompleteService.set(this.profileForm, "nationality", this.getNationality());
        this.filteredCategory = this.autocompleteService.set(this.profileForm, "category", this.getCategory());
        this.filteredOccupation = this.autocompleteService.set(this.profileForm, "occupation", this.getOccupation());
        this.filteredRisk = this.autocompleteService.set(this.profileForm, "riskPro", this.getRisk());
        this.filteredFunds = this.autocompleteService.set(this.profileForm, "sourceFunds", this.getFunds());
        
        this.idForm = this.fb.group({
            ids: this.fb.array([])
        });
        
        this.profileForm.patchValue({quoteId: this.productService.productInfo.quoteId});
        this.idType = this.jsonService.data.idList;
        
        this.instructions = this.parameterService.paramV("INSTRUCTIONS_CUSTOMER");
        this.instructions2 = this.parameterService.paramV("INSTRUCTIONS_CUSTOMER_NOTE");
        this.reqAssdEmail = this.parameterService.paramV("REQ_ASSD_EMAIL");
        this.reqMobileNo = this.parameterService.paramV("REQ_ASSD_MOBILE");
        this.reqTelNo = this.parameterService.paramV("REQ_ASSD_TEL_NO");
        this.reqBirthDate = this.parameterService.paramV("REQ_ASSD_BIRTH_DATE");
        this.reqRegNo = this.parameterService.paramV("REQ_ASSD_REGISTRATION");
        this.reqGender = this.parameterService.paramV("REQ_ASSD_GENDER");
        this.reqMarital = this.parameterService.paramV("REQ_ASSD_MARITAL");
        this.reqCountryOfBirth = this.parameterService.paramV("REQ_ASSD_BIRTH_COUNTRY");
        this.reqProvOfBirth = this.parameterService.paramV("REQ_ASSD_BIRTH_PROVINCE");
        this.reqAddress = this.parameterService.paramV("REQ_ASSD_ADDRESS");
        this.reqProvince = this.parameterService.paramV("REQ_ASSD_PROVINCE");
        this.reqPostal = this.parameterService.paramV("REQ_ASSD_POSTAL");
        this.reqCountry = this.parameterService.paramV("REQ_ASSD_COUNTRY");
        this.reqNationality = this.parameterService.paramV("REQ_ASSD_NATIONALITY");
        this.reqCategory = this.parameterService.paramV("REQ_ASSD_CATEGORY");
        this.reqOccupation = this.parameterService.paramV("REQ_ASSD_OCCUPATION");
        this.reqRisk = this.parameterService.paramV("REQ_ASSD_RISK_PROF");
        this.reqTin = this.parameterService.paramV("REQ_ASSD_TIN");
        this.reqPresAdd = this.parameterService.paramV("REQ_ASSD_PRE_ADDRESS");
        this.reqPresCountry = this.parameterService.paramV("REQ_ASSD_PRE_COUNTRY");
        this.reqPresProv = this.parameterService.paramV("REQ_ASSD_PRE_PROVINCE");
        this.reqPresPostCode = this.parameterService.paramV("REQ_ASSD_PRE_POSTAL");
        this.reqFunds = this.parameterService.paramV("REQ_ASSD_SOURCE_FUND");
        this.reqNameEmployer = this.parameterService.paramV("REQ_ASSD_EMP_BUS_NAME");
        this.reqNatureEmployment = this.parameterService.paramV("REQ_ASSD_EMP_BUS_NATURE");
        this.reqExpDate = this.parameterService.paramV('REQ_ASSD_ID_EXPIRY');
        this.minAssdId = this.parameterService.paramN("MINIMUM_ASSD_ID");
        this.reqMiddleName = this.parameterService.paramV('REQ_ASSD_MIDDLE_NAME');
        this.occOther = this.parameterService.paramN('OCCUPATION_OTHERS');
        //this.withNoTin = this.parameterService.paramV('NO_TIN_SRC_FUND').split(',');
        this.allowedFileExt = this.parameterService.paramV("CUST_DTL_FILE_EXT").split(",");
        this.allowedFileSize = this.parameterService.paramN('CUST_DTL_FILE_SIZE');
        this.reqRmNo = this.parameterService.paramV('REQ_ASSD_RM_NO');
        this.rmLength = this.parameterService.paramN('RM_NO_LENGTH');
        this.idMax = this.parameterService.paramN('ID_NO_LENGTH');

        for (let src of this.jsonDataService.data.sourceFund.filter((a:any) => a.noTinTag === "Y")) {
            this.withNoTin.push(src.sourceFundCd);
        }
        this.idReq = false;
        this.defaultCountry = this.parameterService.paramN("DEFAULT_ASSD_COUNTRY");
        this.checkAccType();
        this.setReqFields();
        //this.captures = []; 
        this.utilityService.setFormGroup(this.profileForm);
    }

    private buildCustomerInfoInProductInfo(): void {
        this.productInfo = this.productService.productInfo;
        this.productInfo.customerInfo = this.productInfo.customerInfo ?? {};
        this.productInfo.customerIds = this.productInfo.customerIds ?? [];
        this.productService.setProductInfo2(this.productInfo);
    }

    private setPolicyHolderDetails(customerInfo: any): void {
        this.productInfo.policyHolderDetails = this.productInfo.policyHolderDetails ? this.productInfo.policyHolderDetails : {};
        this.productInfo.policyHolderDetails.policy = this.productInfo.policyHolderDetails.policy || {};
        this.productInfo.policyHolderDetails.policy.displayName = customerInfo.dispName;
        this.productInfo.policyHolderDetails.policy.address = customerInfo.compAdd;
        this.productService.setProductInfo2(this.productInfo);
    }

    private setCustomerInfo(customerInfo: any): void {
        this.productInfo.customerInfo.clientTypeDetail = this.varClientTypes.filter((a: any) => a.value === customerInfo.brTag)[0];
        this.productInfo.customerInfo.lastName = customerInfo.lastName;
        this.productInfo.customerInfo.firstName = customerInfo.firstName;
        this.productInfo.customerInfo.middleName = customerInfo.middleName;
        this.productInfo.customerInfo.suffix = customerInfo.suffix;
        this.productInfo.customerInfo.companyName = customerInfo.companyName;
        this.productInfo.customerInfo.emailAddress = customerInfo.emailAdd;
        this.productInfo.customerInfo.mobileNo = customerInfo.mobileNo;
        this.productInfo.customerInfo.telNo = customerInfo.telNo;
        this.productInfo.customerInfo.birthDate = customerInfo.birthDate;
        this.productInfo.customerInfo.genderDetail = customerInfo.gender;
        this.productInfo.customerInfo.maritalDetail = customerInfo.maritalStat;
        this.productInfo.customerInfo.countryOfBirthOriginDetail = customerInfo.countryOfBirth;
        this.productInfo.customerInfo.nationalityDetail = customerInfo.nationality;
        this.productInfo.customerInfo.registrationNo = customerInfo.regNo;
        this.productInfo.customerInfo.permanentAddress = customerInfo.compAdd;
        this.productInfo.customerInfo.permanentCityDetail = customerInfo.provOrCity.text === "" ? "" : customerInfo.provOrCity;
        this.productInfo.customerInfo.permanentPostalDetail = customerInfo.postCode.text === "" ? "" : customerInfo.postCode;
        this.productInfo.customerInfo.tinNo = customerInfo.tinNo;
        this.productInfo.customerInfo.jobCategoryDetail = customerInfo.category;
        this.productInfo.customerInfo.riskProfilingDetail = customerInfo.riskPro;
        this.productInfo.customerInfo.cityDesc = customerInfo.provOrCityDesc;
        this.productInfo.customerInfo.postalDesc = customerInfo.postCodeDesc;
        this.productService.setProductInfo2(this.productInfo);
    }

    private proceedAfterGettingTheCustomerInformation(response: any): void {
        this.securityService.checkRequestKeyResponse(response, () => {
            if (response.status === "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, async () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                    this.primaryIdCount = res.idCount[0].validIdCount;
                    res.custInfo.birthDate = res.custInfo.birthDate ? formatDate(res.custInfo.birthDate, "yyyy-MM-dd", "en-US") : "";

                    this.profileForm.patchValue({
                        assdNo: String(res.custInfo.assdNo),
                        brTag: res.custInfo.brTag,
                        lastName: res.custInfo.lastName,
                        firstName: res.custInfo.firstName,
                        middleName: res.custInfo.middleName,
                        compName: res.custInfo.compName,
                        dispName: res.custInfo.dispName,
                        emailAdd: res.custInfo.emailAdd,
                        mobileNo: res.custInfo.mobileNo,
                        telNo: res.custInfo.telNo,
                        birthDate: res.custInfo.birthDate,
                        regNo: res.custInfo.regNo,
                        compAdd: res.custInfo.compAdd,
                        presAdd: res.custInfo.presAdd,
                        presAddChkbox: res.custInfo.presAddChkbox,
                        tinNo: res.custInfo.tinNo,
                        noTin: res.custInfo.noTin,
                        gender: res.custInfo.gender,
                        provOrCityOfBirth: res.custInfo.provOrCityOfBirth,
                        provOrCity: res.custInfo.provOrCity,
                        presProv: res.custInfo.presProv,
                        maritalStat: res.custInfo.maritalStat,
                        postCode: res.custInfo.postCode,
                        presPostCode: res.custInfo.presPostCode,
                        countryOfBirth: res.custInfo.countryOfBirth,
                        countryBirth: res.custInfo.countryBirth,
                        presCountry: res.custInfo.presCountry,
                        nationality: res.custInfo.nationality,
                        sourceFunds: res.custInfo.sourceFunds,
                        nameEmployer: res.custInfo.nameEmployer,
                        natureEmployment: res.custInfo.natureEmployment,
                        category: res.custInfo.category,
                        occupation: res.custInfo.occupation,
                        occOther: res.custInfo.occOther,
                        riskPro: res.custInfo.riskPro,
                        suffix: res.custInfo.suffix,
                        provOrCityOfBirthDesc: res.custInfo.provOrCityOfBirthDesc,
                        provOrCityDesc: res.custInfo.provOrCityDesc,
                        postCodeDesc: res.custInfo.postCodeDesc,
                        presProvDesc: res.custInfo.presProvDesc,
                        presPostCodeDesc: res.custInfo.presPostCodeDesc,
                        supportingDocs: res.custInfo.supportingDocs || [],
                        rmNo: res.custInfo.rmNo
                    });

                    res.custInfo.companyName = res.custInfo.compName;
                    this.setPolicyHolderDetails(res.custInfo);
                    this.setCustomerInfo(res.custInfo);

                    for (const e of res.ids) {
                        var url = "";
                        for (const id of this.jsonService.data.idList.filter((a:any) => a.idTypeDesc === e.idName)) {
                            if (id.withExpDate === "Y") {
                                this.ids.push(this.fb.group({
                                    typeId: [e.typeId, [this.formService.autocomplete(), Validators.required]],
                                    idNo: [e.idNo, [Validators.required]],
                                    fileName: [e.fileName, [Validators.required]],
                                    expDate: [e.expDate, [Validators.required]]
                                }));
                                this.chkExp = 1;
                            } else {
                                this.ids.push(this.fb.group({
                                    typeId: [e.typeId, [this.formService.autocomplete(), Validators.required]],
                                    idNo: [e.idNo, [Validators.required]],
                                    fileName: [e.fileName, [Validators.required]],
                                    expDate: [e.expDate]
                                }));
                            }

                            this.productInfo.customerIds.push({
                                idTypeDetail: e.typeId,
                                idNo: e.idNo,
                                expiryDate: e.expDate
                            });
                        }

                        // if (e.fileName != null) {
                        //     // this.containers.push(url.concat(window.location.origin, "/attachments/", res.custInfo.assdNo.toString(), "/", e.fileName));
                        //     this.getIdUrl(e.fileName);
                            
                        // }
                    }
                    await this.getIdUrl(res.ids.filter((e:any)=>e.fileName).map((e:any)=>e.fileName));

                    this.jsonDataService.contorlLoading(false);
                    for (const listId of this.jsonService.data.idList.filter((a:any) => a.brTag === res.custInfo.brTag)) {
                        this.listId.push(listId);
                    }

                    this.checkAccType();
                    this.initializeValues();
                    this.setOtherValidators();
                    this.checkCountry();
                    this.validateTinNo();
                    this.filteredProvOfBirth = this.autocompleteService.set(this.profileForm, "provOrCityOfBirth", this.getProvince('provOrCityOfBirth'));
                    this.filteredProvince = this.autocompleteService.set(this.profileForm, "provOrCity", this.getProvince('provOrCity'));
                    this.filteredPresProv = this.autocompleteService.set(this.profileForm, "presProv", this.getProvince('presProv'));
                    this.filteredPostal = this.autocompleteService.set(this.profileForm, "postCode", this.getPostal('postCode'));
                    this.filteredPresPostCode = this.autocompleteService.set(this.profileForm, "presPostCode", this.getPostal('presPostCode'));

                    this.showRmConsent(res.custInfo);
                });
            } else {
                this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
            }
        });
    }

    async getIdUrl(fileNames:string[]){
        for(let file of fileNames){
            
            const getPresignedUrlResponse = await this.apiCallService.getPresignedUrl({
                directory: `attachments/${this.profileForm.get("assdNo")?.value}`,
                filename: file
            }).toPromise();

            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.containers.push(res.presignedUrl)
                            this.ocrStat.push("SUCCESS");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your id. Please try again later.", "error");
                }
            });
        }
    }

    showRmConsent(custInfo: any): void{
        let showRm = this.parameterService.paramV("BPI_RM_CONSENT") == 'Y';
        if((!custInfo.lastName || !custInfo.firstName || !custInfo.emailAdd || !custInfo.tinNo || !custInfo.mobileNo || !custInfo.sourceFunds.value) && showRm && this.userDetailsService.accountType == 'P' && !this.fromRm){
            this.rmConsent.open('Skip to Application Form');
        }
    }

    toBpiRMLogin():void {
        this.jsonDataService.contorlLoading(true);
        
        this.apiCallService.redirectToBPIRM().subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        window.location.href = JSON.parse(this.jsonDataService.decrypt(response.response));
                    });
                } else {
                    this.appMessageService.showAppMessage(response.message, "error");
                }
            });
        });
    }

    ngOnInit(): void {
        this.jsonDataService.contorlLoading(true);
        
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params.params) {
                if (p.params.from === "bpi") {
                    let params: any = this.jsonDataService.parseBase64(p.params?.params);
                    params = JSON.parse(JSON.parse(this.jsonDataService.decrypt(params)));
                    this.saveRM(params);
                }else{
                    this.continueOnInit();
                }
            }else {
                this.continueOnInit();
            }
        });

        if (this.productInfo.creationProcess === "quotation") {
            if (this.productService.productInfo.sublineCd == 'COM') {
                // this.fbAnalyticsService.trackEvent('Motor_Quotation_Vehicle_Details')
            } else if (this.productService.productInfo.sublineCd == 'IND') {
                this.fbAnalyticsService.trackEvent('Personal_EPolicy_Customer_Info')
            }  
        } else if (this.productInfo.creationProcess === "policy") {
            if (this.productService.productInfo.sublineCd == 'COM') {
                this.fbAnalyticsService.trackEvent('Motor_Epolicy_ Customer_Info')
            } else if (this.productService.productInfo.sublineCd == 'CTPL') {
                this.fbAnalyticsService.trackEvent('Liability_EPolicy_Customer_Info')
            } else if (this.productService.productInfo.sublineCd == 'FPA') {
                this.fbAnalyticsService.trackEvent('Family_EPolicy_Customer_Info')
            } else if (this.productService.productInfo.sublineCd == 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_ EPolicy_ Customer_Details')
            }
        }
    }

    saveRM(params:any):void {
        this.jsonDataService.contorlLoading(true);
        
        this.apiCallService.saveBPIRM({
            token: params.access_token,
            quoteId: this.productService.productInfo.quoteId.toString()
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.fromRm = true;
                    this.continueOnInit();
                } else {
                    this.appMessageService.showAppMessage("There's something wrong in retrieving BPI data. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    continueOnInit():void{
        if (this.productInfo.creationProcess === "renewal") {
            this.profileForm.get("maritalStat")?.enable();
            this.profileForm.get("occupation")?.enable();
            this.profileForm.get("birthDate")?.enable();
            this.profileForm.get("firstName")?.disable();
            this.profileForm.get("middleName")?.disable();
            this.profileForm.get("lastName")?.disable();
            this.profileForm.get("suffix")?.disable();
            this.profileForm.get("compName")?.disable();
            this.profileForm.get("dispName")?.disable();

            
            this.apiCallService.getRenewalCustInfo({
                policyId: this.productService.productInfo.policyId.toString()
            }).subscribe((response: any) => {
                this.proceedAfterGettingTheCustomerInformation(response);
            });
        } else {
            
            this.apiCallService.getCustInfo({
                quoteId: this.productService.productInfo.quoteId.toString()
            }).subscribe((response: any) => {
                this.proceedAfterGettingTheCustomerInformation(response);
            });
        }   
    }

    ngAfterViewInit(): void {
        // codes here
    }

    get ids() : FormArray {
        return this.idForm.get("ids") as FormArray;
    }

    getNewIndex() {
        return this.ids.length - 1;
    }

    newId(): any {
        var typeId = "";
        var idNo = "";
        var fileName = "";
        var expDate = "";

        var i = this.getNewIndex() + 1;
        
        typeId = typeId.concat("typeId", i.toString());
        idNo = idNo.concat("idNo", i.toString());
        fileName = fileName.concat("fileName", i.toString());
        expDate = expDate.concat("expDate", i.toString());
        
        return this.fb.group({
            typeId: ['', [this.formService.autocomplete(),Validators.required]],
            idNo: ['', [Validators.required]],
            fileName: ['', [Validators.required]],
            expDate: ['']
        });
    }
 
    addId() {
        this.ids.push(this.newId());
        var i = this.getNewIndex();
        
        this.filteredId1[i] = this.autocompleteService.setArray(this.idForm, "typeId", this.getIdList1(i), "ids", i);
    }
 
    removeId(i:number) {
        this.ids.removeAt(i);
        if (this.ids.length === 0) {
            this.chkExp = 0;
        }
        this.idReq = false;
    }
//id section end

    validForOcr(i: number) {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        let validOcr = false;

        for (const id of this.jsonService.data.idList.filter((a:any) => a.idTypeDesc === typeId.get("typeId")?.value.text)) {
            if (id.ocrTag === 'Y') {
                validOcr =  true;
            } else {
                validOcr = false;
            }
        }
        return validOcr;
    }
    
    getBase64(dataURI: any) {
        var byteString = unescape(dataURI.split(',')[1]);
        return byteString;
    }

    checkType(i: number){
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        if(typeId.get("typeId")?.value.text == undefined || typeId.get("typeId")?.value.text == ""){
            this.dialogBoxService.open({
                messageType: "E",
                content: "Please provide the type of ID before uploading an image.",
                buttons: [
                    { text: "Ok" }
                ]
            });
        }
    }

    checkField(i: number){
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);

        if(typeId.get("typeId")?.value.text == undefined) {
            return false;
        } else {
            return true;
        }
    }

    resizeImage(settings: IResizeImageOptions) {
        this.resizedImage = '';
        const file = settings.file;
        const maxSize = settings.maxSize;
        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement('canvas');
        
        const resize = () => {
            let width = image.width;
            let height = image.height;
            
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
           
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
        
            this.resizedImage = canvas.toDataURL('image/jpeg');
            return this.resizedImage;
        };
        return new Promise((ok, no) => {
            reader.onload = (readerEvent: any) => {
                image.onload = () => ok(resize());
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    }

    processFile(imageInput: any, i: number, params: String) {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        if (file.type === "image/jpeg" || file.type === "image/png") {
            if (file.size >= 10000000) {
                this.dialogBoxService.open({
                    messageType: "E",
                    content: "File size is over the size limit. Please choose a file with size lower than 10 MB.",
                    buttons: [
                        { text: "Ok"}
                    ]
                });
            } else {
                reader.addEventListener('load', (event: any) => {
                    var img = new ImageSnippet(event.target.result, file);
                    typeId.patchValue({fileName: file.name});

                    if (file.size > 3000000) {
                        this.resizeImage({
                            file: file,
                            maxSize: 2000
                        }).then();
                    }
                    
                    if (params === 'replace') {
                        this.containers[i] = img.src;
                        this.ocrStat[i] = "";
                    } else {
                        this.containers[i] = img.src;
                        if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                            this.fileUpload.push(img);
                        }
                    }
                        
                    if (this.validForOcr(i)) {
                        this.dialogBoxService.open({
                            messageType: "C",
                            content: "Some of the fields may be auto-populated based on the information available on the uploaded ID.",
                            buttons: [
                                /*{ text: "No", action: () => {
                                    if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                        this.fileUpload.push(img);
                                    }
                                    //this.processUpload(file);
                                }},*/
                                { text: "Ok", action: () => {
                                    if (this.resizedImage === "") {
                                        this.processOcr(event.target.result, i, img);
                                    } else {
                                        this.processOcr(this.resizedImage, i, img);    
                                    }
                                }}
                            ]
                        });
                    } else {
                        this.ocrStat[i] = "SUCCESS";
                        if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                            this.fileUpload.push(img);
                        }
                        //this.processUpload(file);
                    }
                });
                reader.readAsDataURL(file);
            }
        } else {
            this.dialogBoxService.open({
                messageType: "E",
                content: "The format of the file you've submitted is not supported. The supported formats include JPEG and PNG.",
                buttons: [
                    { text: "Ok"}
                ]
            });
        }
    }

    processOcr(fileUrl:any, i:number, img:any) {
        
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.ocr({
            action: "ocr",
            fileName: this.getBase64(fileUrl),
            assdNo: this.profileForm.get("assdNo")?.value
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        this.ocrStat[i] = "SUCCESS";
                        this.resizedImage = "";
                        this.populateFields(res.OCR, i, img);
                    });
                    
                } else {
                    this.ocrStat[i] = "SUCCESS";
                    if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                        this.fileUpload.push(img);
                    }
                    this.appMessageService.showAppMessage("There's something wrong with the OCR. Kindly fill up the form manually.", "info");
                }
            });
        }, (err:any) => {
            this.resizedImage = "";
        });
    }

    populateFields(ocr: any, i: number, img: any) {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        var date;
        var expDate;
        var name;
        var firstName;
        var middleName;
        var lastName;
        var address = "";
        var iAdd;
        var gender;
        var iGen;
        var nationality;
        
        if (typeId.get("typeId")?.value.text === 'TIN'){
            if (ocr.form.TIN == undefined || ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('BUREAU OF INTERNAL REVENUE') == -1) {
                this.dialogBoxService.open({
                    messageType: "C",
                    content: "The image submitted cannot be recognized by OCR. You may try to reupload or proceed with this image.",
                    buttons: [
                        { text: "Reupload", action: () => {
                            this.containers.splice(i, 1);
                            this.ocrStat.splice(i, 1);
                            typeId.patchValue({fileName: ""});
                        }},
                        { text: "Keep uploaded image", action: () => {
                            if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                this.fileUpload.push(img);
                            }
                        }}
                    ]
                });
            } else {
                date = this.dp.transform(ocr.form.BIRTHDATE, 'YYYY-MM-dd');
                name = ocr.ocr.Blocks[4].Text;
                lastName = name.split(',')[0];
                middleName = name.split(' ')[name.split(' ').length - 1];
                firstName = name.substring(name.indexOf(',') + 2, name.lastIndexOf(' '));
                address = address.concat(ocr.ocr.Blocks[6].Text, ' ', ocr.ocr.Blocks[7].Text, ' ', ocr.ocr.Blocks[8].Text)  
                
                typeId.patchValue({idNo: ocr.form.TIN});
                if(this.noTin) {
                    this.profileForm.get("tinNo")?.enable();
                    this.noTin = false;
                    this.profileForm.controls["tinNo"].setValidators([this.formService.tinValidator(), Validators.required]);
                    //this.profileForm.controls["tinNo"].setValidators(this.profileForm.controls["tinNo"].validator ? [this.profileForm.controls["tinNo"].validator ,Validators.required] : [Validators.required] );
                    this.profileForm.patchValue({noTin: "N"});
                }
                this.profileForm.patchValue({tinNo: ocr.form.TIN});
                if(this.profileForm.get("lastName")?.value === ""){
                    this.profileForm.patchValue({lastName: lastName});
                }
                if(this.profileForm.get("firstName")?.value === ""){
                    this.profileForm.patchValue({firstName: firstName});
                }
                if(this.profileForm.get("middleName")?.value === ""){
                    this.profileForm.patchValue({middleName: middleName});
                }
                if(this.profileForm.get("compAdd")?.value === ""){
                    this.profileForm.patchValue({compAdd: address});
                }
                if(this.profileForm.get("birthDate")?.value === null){
                    this.profileForm.patchValue({birthDate: date});
                }

                if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                    this.fileUpload.push(img);
                }
                //this.processUpload(img);
            }
            
        } else if (typeId.get("typeId")?.value.text === 'GSIS E-CARD/UMID') {
            if(ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('Unified Multi-Purpose ID') == -1){
                this.dialogBoxService.open({
                    messageType: "C",
                    content: "The image submitted cannot be recognized by OCR. You may try to reupload or proceed with this image.",
                    buttons: [
                        { text: "Reupload", action: () => {
                            this.containers.splice(i, 1);
                            this.ocrStat.splice(i, 1);
                            typeId.patchValue({fileName: ""});
                        }},
                        { text: "Keep uploaded image", action: () => {
                            if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                this.fileUpload.push(img);
                            }
                        }}
                    ]
                });
            } else {
                date = this.dp.transform(ocr.form.DATEOFBIRTH, 'YYYY-MM-dd');
                lastName = ocr.form.SURNAME;
                firstName = ocr.form.GIVENNAME;
                middleName = ocr.form.MIDDLENAME;
                iAdd = ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('ADDRESS');
                iGen = ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('SEX');
                address = address.concat(ocr.ocr.Blocks[iAdd+1].Text, ' ', ocr.ocr.Blocks[iAdd+2].Text, ' ', ocr.ocr.Blocks[iAdd+3].Text);
                gender = ocr.ocr.Blocks[iGen+1].Text;
                
                if(this.profileForm.get('gender')?.value.text === undefined) {
                    for (const gen of this.getGender()) {
                        if (gen.text === gender) {
                                this.profileForm.controls["gender"].setValue(gen);
                        }
                    }
                }
                typeId.patchValue({idNo: ocr.form.CRN});

                if(this.profileForm.get("lastName")?.value === ""){
                    this.profileForm.patchValue({lastName: lastName});
                }
                if(this.profileForm.get("firstName")?.value === ""){
                    this.profileForm.patchValue({firstName: firstName});
                }
                if(this.profileForm.get("middleName")?.value === ""){
                    this.profileForm.patchValue({middleName: middleName});
                }
                if(this.profileForm.get("compAdd")?.value === ""){
                    this.profileForm.patchValue({compAdd: address});
                }
                if(this.profileForm.get("birthDate")?.value === null){
                    this.profileForm.patchValue({birthDate: date});
                }
                
                if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                    this.fileUpload.push(img);
                }
                //this.processUpload(file);
            }
        } else if (typeId.get("typeId")?.value.text === "DRIVER'S LICENSE") {
            if(ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('LAND TRANSPORTATION OFFICE') == -1 || ocr.form.AGY == undefined ||
               ocr.form.RESTRICTIONS == undefined) {
                this.dialogBoxService.open({
                    messageType: "C",
                    content: "The image submitted cannot be recognized by OCR. You may try to reupload or proceed with this image.",
                    buttons: [
                        { text: "Reupload", action: () => {
                            this.containers.splice(i, 1);
                            this.ocrStat.splice(i, 1);
                            typeId.patchValue({fileName: ""});
                        }},
                        { text: "Keep uploaded image", action: () => {
                            if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                this.fileUpload.push(img);
                            }
                        }}
                    ]
                });
            } else {
                var chkDate = new Date(ocr.form.EXPIRES);
                if (chkDate < this.minExpDate){
                    this.dialogBoxService.open({
                        messageType: "E",
                        content: "ID submitted is already expired. Please provide an ID with a valid Expiry Date.",
                        buttons: [
                            { text: "Ok", action: () => {
                                this.containers.splice(i, 1);
                                this.ocrStat.splice(i, 1);
                                typeId.patchValue({fileName: "",
                                                   expDate: ""
                                                 });
                            }}
                        ]
                    });
                } else {
                    date = this.dp.transform(ocr.form.BIRTHDATE, 'YYYY-MM-dd');
                    expDate = this.dp.transform(ocr.form.EXPIRES, 'YYYY-MM-dd');
                    var sex = ocr.form.SEX;
                    var nat = ocr.form.NATIONALITY;
                    nationality = "";
                    iAdd = ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('ADDRESS (NO. STREET, CITY MUN., PROVINCE)');
                    address = ocr.ocr.Blocks[iAdd+1].Text;
                    name = ocr.ocr.Blocks[iAdd-1].Text;
                    lastName = name.split(',')[0];
                    middleName = middleName = name.split(' ')[name.split(' ').length - 1];
                    firstName = name.substring(name.indexOf(',') + 2, name.lastIndexOf(' '));
                    if (sex === "M") {
                        gender = "MALE";
                    } else if (sex === "F") {
                        gender = "FEMALE";
                    } else {
                        gender = "UNKNOWN";
                    }

                    if (nat === "FIL") {
                        nationality = "FILIPINO";
                    } else {
                        nationality = ocr.form.NATIONALITY;
                    }

                    if(this.profileForm.get('nationality')?.value.text === undefined) {
                        for (const nation of this.getNationality()) {
                            if (   nation.text === nationality) {
                                    this.profileForm.controls["nationality"].setValue(nation);
                            }
                        }
                    }
                    if(this.profileForm.get('gender')?.value.text === undefined) {
                        for (const gen of this.getGender()) {
                            if (gen.text === gender) {
                                    this.profileForm.controls["gender"].setValue(gen);
                            }
                        }
                    }
                    typeId.patchValue({idNo: ocr.form.LICENSENO,
                                       expDate: expDate
                                      });
                    if(this.profileForm.get("lastName")?.value === ""){
                        this.profileForm.patchValue({lastName: lastName});
                    }
                    if(this.profileForm.get("firstName")?.value === ""){
                        this.profileForm.patchValue({firstName: firstName});
                    }
                    if(this.profileForm.get("middleName")?.value === ""){
                        this.profileForm.patchValue({middleName: middleName});
                    }
                    if(this.profileForm.get("compAdd")?.value === ""){
                        this.profileForm.patchValue({compAdd: address});
                    }
                    if(this.profileForm.get("birthDate")?.value === null){
                        this.profileForm.patchValue({birthDate: date});
                    }
                    if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                        this.fileUpload.push(img);
                    }
                }
            }
        } else if (typeId.get("typeId")?.value.text === "PRC ID") {
            if (ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('PROFESSIONAL REGULATION COMMISSION') > -1 || 
                ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('PROFESSIONAL REGULATION commission') > -1 ||
                ocr.ocr.Blocks.map(function (img:any) { return img.Text; }).indexOf('PROFESSIONAL IDENTIFICATION CARD') > -1 ||
                ocr.form.REGISTRATIONNO != undefined) {
                    
                    var chkDate = new Date(ocr.form.VALIDUNTIL);
                    if (chkDate < this.minExpDate){
                        this.dialogBoxService.open({
                            messageType: "E",
                            content: "ID submitted is already expired. Please provide an ID with a valid Expiry Date.",
                            buttons: [
                                { text: "Ok", action: () => {
                                    this.containers.splice(i, 1);
                                    this.ocrStat.splice(i, 1);
                                    typeId.patchValue({fileName: "",
                                                       expDate: ""
                                                     });
                                }}
                            ]
                        });
                    } else {
                        expDate = this.dp.transform(ocr.form.VALIDUNTIL, 'YYYY-MM-dd');
                        lastName = ocr.form.LASTNAME;
                        firstName = ocr.form.FIRSTNAME;

                        if(ocr.form.MIDDLENAME == undefined){
                            middleName = ocr.form.MIDDLEINITIALNAME;
                        } else {
                            middleName = ocr.form.MIDDLENAME;
                        }
                        
                        typeId.patchValue({idNo: ocr.form.REGISTRATIONNO,
                                           expDate: expDate
                                         });

                        if(this.profileForm.get("lastName")?.value === ""){
                            this.profileForm.patchValue({lastName: lastName});
                        }
                        if(this.profileForm.get("firstName")?.value === ""){
                            this.profileForm.patchValue({firstName: firstName});
                        }
                        if(this.profileForm.get("middleName")?.value === ""){
                            this.profileForm.patchValue({middleName: middleName});
                        }

                        if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                            this.fileUpload.push(img);
                        }
                        //this.processUpload(file);
                    }
            } else {
                this.dialogBoxService.open({
                    messageType: "C",
                    content: "The image submitted cannot be recognized by OCR. You may try to reupload or proceed with this image.",
                    buttons: [
                        { text: "Reupload", action: () => {
                            this.containers.splice(i, 1);
                            this.ocrStat.splice(i, 1);
                            typeId.patchValue({fileName: ""});
                        }},
                        { text: "Keep uploaded image", action: () => {
                            if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                this.fileUpload.push(img);
                            }
                        }}
                    ]
                });
            }
        } else if (typeId.get("typeId")?.value.text === "PASSPORT") {
            let passportNo = ocr.form.PasaportablgPassportno != undefined ? ocr.form.PasaportablgPassportno : ocr.form.PasaportebigPossportno;
            if (passportNo == undefined){
                this.dialogBoxService.open({
                    messageType: "C",
                    content: "The image submitted cannot be recognized by OCR. You may try to reupload or proceed with this image.",
                    buttons: [
                        { text: "Reupload", action: () => {
                            this.containers.splice(i, 1);
                            this.ocrStat.splice(i, 1);
                            typeId.patchValue({fileName: ""});
                        }},
                        { text: "Keep uploaded image", action: () => {
                            if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                                this.fileUpload.push(img);
                            }
                        }}
                    ]
                });
            } else {
                var chkDate = new Date(ocr.form.ArawngpagkawalangbisaValiduntil);
                if (chkDate < this.minExpDate){
                    this.dialogBoxService.open({
                        messageType: "E",
                        content: "ID submitted is already expired. Please provide an ID with a valid Expiry Date.",
                        buttons: [
                            { text: "Ok", action: () => {
                                this.containers.splice(i, 1);
                                this.ocrStat.splice(i, 1);
                                typeId.patchValue({fileName: "",
                                                   expDate: ""
                                                 });
                            }}
                        ]
                    });
                } else {
                    lastName = ocr.form.ApelyidoSurname;
                    firstName = ocr.form.PangalanGivennames;
                    middleName = ocr.form.PanggitnangapelyidoMiddlename;
                    date = this.dp.transform(ocr.form.ArawngkapanganakanDateofbirth, 'YYYY-MM-dd') || this.dp.transform(ocr.form.PetsangkapanganakonDateofbith, 'YYYY-MM-dd');
                    expDate = this.dp.transform(ocr.form.ArawngpagkawalangbisaValiduntil, 'YYYY-MM-dd') || this.dp.transform(ocr.form.Petsangpagkawelanguntil, 'YYYY-MM-dd');
                    var sex = ocr.form.KasarianSex;

                    if (sex === "M") {
                        gender = "MALE";
                    } else if (sex === "F") {
                        gender = "FEMALE";
                    } else {
                        gender = "UNKNOWN";
                    }

                    if(this.profileForm.get('gender')?.value.text === undefined) {
                        for (const gen of this.getGender()) {
                            if (gen.text === gender) {
                                    this.profileForm.controls["gender"].setValue(gen);
                            }
                        }
                    }
                    typeId.patchValue({idNo: passportNo,
                                       expDate: expDate
                                      });
                    
                    if(this.profileForm.get("lastName")?.value === ""){
                        this.profileForm.patchValue({lastName: lastName});
                    }
                    if(this.profileForm.get("firstName")?.value === ""){
                        this.profileForm.patchValue({firstName: firstName});
                    }
                    if(this.profileForm.get("middleName")?.value === ""){
                        this.profileForm.patchValue({middleName: middleName});
                    }
                    if(this.profileForm.get("birthDate")?.value === null){
                        this.profileForm.patchValue({birthDate: date});
                    }
                    
                    if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                        this.fileUpload.push(img);
                    }
                }
            }
        }
    }

    /*public ngAfterViewInit() {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
                this.video.nativeElement.src = window.URL.createObjectURL(stream);
                this.video.nativeElement.play();
            });
        }
    }

    public capture() {
        var context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 640, 480);
        this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    } */

    deleteImage(i: number){
        var file = this.containers[i];
        this.containers.splice(i, 1);
        this.ocrStat.splice(i, 1);
        this.ids.removeAt(i);

        var del = this.fileUpload.map(function (img:any) { return img.src; }).indexOf(file);
        if (del != -1) {
            this.fileUpload.splice(del,1);
        }
        if (this.ids.length === 0) {
            this.chkExp = 0;
        } 
        this.idReq = false;
    }

    //Supporting Docs
    addSupportDocs(event: any): void {
        this.withSupportingDocChange = true;
        
        let totalFileSize = 0;
        this.profileForm.controls["supportingDocs"].value.forEach((el: any) => {
            totalFileSize += (el.size / 1024 / 1024);
        });
        
        if (event.target.files && event.target.files.length) {
            const files = event.target.files;
    
            for (let index = 0; index < files.length; index++) {
                 const reader = new FileReader();
                 const element = files[index];
    
                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.supportDocErrorMsg = "";
                    const type = mimeTypes.getExtension(element.type) || "";
                    //let type = element.type.split("/")[1];
                    
                    /*if (type === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        type = 'DOCX';
                    }*/
                    //if (!this.allowedFileExt.includes(type)) {
                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        this.supportDocErrorMsg = "Some files were not attached. Accepted file types: " + this.allowedFileExt.join(", ");
                    } else {
                        totalFileSize += (element.size / 1024 / 1024);
    
                        if (totalFileSize > this.allowedFileSize) {
                            this.supportDocErrorMsg = "You have reached the maximum file size for the attachment. Some files were not uploaded.";
                        } else {
                            const supportingDocs = this.profileForm.controls["supportingDocs"].value;
                            supportingDocs.push({
                                filename: element.name,
                                size: element.size
                            });
    
                            this.supportingDocsFiles.push(element);
                            this.profileForm.controls["supportingDocs"].setValue(supportingDocs);
                            this.profileForm.markAsDirty();
                        }
                    }
    
                    if (this.supportDocErrorMsg) {
                        setTimeout(() => {
                            this.supportDocErrorMsg = "";
                        }, 5000);
                    }
                };
            }
        }
        


        /*const file: File = imageInput.files[0];
        const reader = new FileReader();
        
        //this.totalFileSize = this.totalFileSize + ((file.size/1024)/1024);
        
        if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            if (this.fileSize < this.totalFileSize) {
                this.dialogBoxService.open({
                    messageType: "E",
                    content: "Total file size is over the size limit. Total file size should be lower than " + this.fileSize,
                    buttons: [
                        { text: "Ok"}
                    ]
                });
            } else {
                reader.addEventListener('load', (event: any) => {
                    var img = new ImageSnippet(event.target.result, file);
                    
                    if (this.queue.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                        this.queue.push(img);
                    } else {
                        this.appMessageService.showAppMessage("File is already on the list.", "error");
                    }
                    
                        
                    /*if (this.validForOcr(i)) {
                        this.dialogBoxService.open({
                            messageType: "C",
                            content: "Some of the fields may be auto-populated based on the information available on the uploaded ID.",
                            buttons: [
                                { text: "Ok", action: () => {
                                    this.processOcr(event.target.result, i, img);
                                }}
                            ]
                        });
                    } else {
                        this.ocrStat[i] = "SUCCESS";
                        if (this.fileUpload.map(function (pic:any) { return pic.src; }).indexOf(img.src) == -1){
                            this.fileUpload.push(img);
                        }
                        //this.processUpload(file);
                    }
                });
                reader.readAsDataURL(file);
            }
        } else {
            this.dialogBoxService.open({
                messageType: "E",
                content: "The format of the file you've submitted is not supported. The supported formats include " + this.fileExt,
                buttons: [
                    { text: "Ok"}
                ]
            });
        }*/
    }

    viewFile(index: number): void{
        const filename = this.profileForm.controls["supportingDocs"].value[index].filename;

        let newFile = false;
        for (const supportingDocsFile of this.supportingDocsFiles) {
            if (supportingDocsFile.name === filename) {
                newFile = true;
                this.downloadService.downloadFile(supportingDocsFile);
            }
        }

        if (!newFile) {
            this.jsonDataService.contorlLoading(true);
            
            this.apiCallService.getPresignedUrl({
                action: "getObject",
                directory: `supporting-docs/customer-information/${this.profileForm.get("assdNo")?.value}`,
                filename: filename
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.downloadService.downloadFileUsingPresignedUrl(res.presignedUrl, filename);
                        });
                    }
                });
            });
        }
    }

    removeFile(index: number): void{
        this.profileForm.controls["supportingDocs"].setValue(
            this.profileForm.controls["supportingDocs"].value.filter((a:any, i:number) => { return i !== index })
        );
        this.supportingDocsFiles.splice(index, 1);
        this.profileForm.markAsDirty();
        this.withSupportingDocChange = true;
    }

    initializeValues():void {
        if (this.profileForm.get('presAddChkbox')?.value === "Y") {
            this.sameAsPermanent = true;
        }
        if (this.profileForm.get('noTin')?.value === "Y") {
            this.noTin = true;
            this.profileForm.patchValue({tinNo: "No TIN"});
            this.profileForm.controls["tinNo"].clearValidators();
            this.profileForm.get("tinNo")?.disable();
        } else {
            this.profileForm.get("tinNo")?.enable();
            this.noTin = false;
            this.profileForm.controls["tinNo"].setValidators([this.formService.tinValidator(), Validators.required]);
            //this.profileForm.controls["tinNo"].setValidators(this.profileForm.controls["tinNo"].validator ? [this.profileForm.controls["tinNo"].validator ,Validators.required] : [Validators.required] );
            this.profileForm.patchValue({noTin: "N"});
        }
        this.profileForm.controls["tinNo"].updateValueAndValidity();
        if (this.profileForm.get('countryBirth')?.value.value === "") {
            for (let count of this.jsonDataService.data.country.filter((b:any) => b.countryCd === this.parameterService.paramN("DEFAULT_ASSD_COUNTRY"))) {
                this.profileForm.controls["countryBirth"].patchValue({
                    value: count.countryCd,
                    text: count.countryName
                });
            }
        }
        if (this.profileForm.get('countryOfBirth')?.value.value === "") {
            for (let count of this.jsonDataService.data.country.filter((b:any) => b.countryCd === this.parameterService.paramN("DEFAULT_ASSD_COUNTRY"))) {
                this.profileForm.controls["countryOfBirth"].patchValue({
                    value: count.countryCd,
                    text: count.countryName
                });
            }
        }
        if (this.profileForm.get('presCountry')?.value.value === "") {
            for (let count of this.jsonDataService.data.country.filter((b:any) => b.countryCd === this.parameterService.paramN("DEFAULT_ASSD_PRE_COUNTRY"))) {
                this.profileForm.controls["presCountry"].patchValue({
                    value: count.countryCd,
                    text: count.countryName
                });
            }
        }
        if (this.profileForm.get('nationality')?.value.value === "") {
            for (const nation of this.jsonService.data.nationality.filter((a:any) => a.nationCd === this.parameterService.paramN("DEFAULT_ASSD_NATIONALITY"))) {
                this.profileForm.controls["nationality"].patchValue({
                    value: nation.nationCd,
                    text: nation.nationDesc.toUpperCase()
                });
            }
        }        
        /*if (this.profileForm.get('category')?.value.value === "") {
            if(!(this.jsonService.data.category.filter((a:any) => Number(a.catCd) === Number(this.parameterService.paramV("PU_CATEGORY_CD")))=='')){
                for (const cat of this.jsonService.data.category.filter((a:any) => Number(a.catCd) === Number(this.parameterService.paramV("PU_CATEGORY_CD")))) {
                    this.profileForm.controls["category"].patchValue({
                        value: cat.catCd,
                        text: cat.catDesc
                    });
                }
            }
            else{
                this.profileForm.controls["category"].patchValue({
                    value: null,
                    text: ""
                });
            }
        }*/
        if (this.profileForm.get('riskPro')?.value.value === "") {
            for(const rp of this.jsonService.data.userType.filter((b:any) => b.userType === this.userType)){
                for (const risk of this.jsonService.data.riskProfCd.filter((a:any) => Number(a.rpCd) === Number(rp.riskProfCd))) {
                    this.profileForm.controls["riskPro"].patchValue({
                        value: risk.rpCd,
                        text: risk.bmDesc
                    });
                }
            }
        }
        if ((this.productInfo.lineCd === 'PA' || this.productInfo.lineCd === 'CI') && this.productInfo.creationProcess != 'renewal') {
            this.profileForm.get("maritalStat")?.disable();
            this.profileForm.get("occupation")?.disable();
            this.profileForm.get("birthDate")?.disable();
        } else {
            this.profileForm.get("maritalStat")?.enable();
            this.profileForm.get("occupation")?.enable();
            this.profileForm.get("birthDate")?.enable();
        }
        
        if (this.productInfo.creationProcess === 'renewal') {
            this.profileForm.get("firstName")?.disable();
            this.profileForm.get("middleName")?.disable();
            this.profileForm.get("lastName")?.disable();
            this.profileForm.get("suffix")?.disable();
            this.profileForm.get("compName")?.disable();
            this.profileForm.get("dispName")?.disable();
        } else {
            this.profileForm.get("firstName")?.enable();
            this.profileForm.get("middleName")?.enable();
            this.profileForm.get("lastName")?.enable();
            this.profileForm.get("suffix")?.enable();
            this.profileForm.get("compName")?.enable();
            this.profileForm.get("dispName")?.enable();


        }

        this.profileForm.controls["tinNo"].updateValueAndValidity();
    }

    showListId(): void{
        this.validIdList.open();
    }

    reqExpiryDate(i: number){
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        var req = false;
        
        for(const id of this.jsonService.data.idList.filter((a:any) => a.idTypeDesc === typeId.get("typeId")?.value.text)){
            if(id.withExpDate === 'Y'){
                req = true;
                this.chkExp = 1;
            } else {
                req = false;
            }
            this.setExpDateValidators(i, id.withExpDate);
        }
        return req;
    }

    setExpDateValidators(i: number, wExpDate: string):void {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        typeId.get('expDate')?.clearValidators();
        if (wExpDate === 'Y') {
            typeId.get('expDate')?.setValidators([Validators.required]);
        } else {
            typeId.get('expDate')?.clearValidators();
        }
        typeId.get('expDate')?.updateValueAndValidity();
    }

    setReqFields():void{
        if(this.reqAssdEmail === 'P'){
            this.profileForm.controls["emailAdd"].setValidators([Validators.required, Validators.email]);
        } else {
            this.profileForm.controls["emailAdd"].setValidators([Validators.email]);
        }
        if(this.reqMobileNo === 'P'){
            this.profileForm.controls["mobileNo"].setValidators(this.profileForm.controls["mobileNo"].validator ? [this.profileForm.controls["mobileNo"].validator ,Validators.required] : [Validators.required] );
        }
        if(this.reqTelNo === 'P'){
            this.profileForm.controls["telNo"].setValidators([Validators.required]);
        }
        if(this.reqBirthDate === 'P'){
            this.profileForm.controls["birthDate"].setValidators([Validators.required]);
        }
        if(this.reqRegNo === 'P'){
            this.profileForm.controls["regNo"].setValidators([Validators.required]);
        }
        if(this.reqAddress === 'P'){
            this.profileForm.controls["compAdd"].setValidators([Validators.required]);
        }
        if(this.reqPresAdd === 'P'){
            this.profileForm.controls["presAdd"].setValidators([Validators.required]);
        }
        if(this.reqNameEmployer === 'P'){
            this.profileForm.controls["nameEmployer"].setValidators([Validators.required]);
        }
        if(this.reqNatureEmployment === 'P'){
            this.profileForm.controls["natureEmployment"].setValidators([Validators.required]);
        }
        if(this.reqProvince === 'P'){
            this.profileForm.controls["provOrCity"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["provOrCity"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqProvOfBirth === 'P'){
            this.profileForm.controls["provOrCityOfBirth"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["provOrCityOfBirth"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqPresProv === 'P'){
            this.profileForm.controls["presProv"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["presProv"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqPostal === 'P'){
            this.profileForm.controls["postCode"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["postCode"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqPresPostCode=== 'P'){
            this.profileForm.controls["presPostCode"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["presPostCode"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqCountry === 'P'){
            this.profileForm.controls["countryBirth"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["countryBirth"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqCountryOfBirth === 'P'){
            this.profileForm.controls["countryOfBirth"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["countryOfBirth"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqPresCountry === 'P'){
            this.profileForm.controls["presCountry"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["presCountry"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqNationality === 'P'){
            this.profileForm.controls["nationality"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["nationality"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqFunds=== 'P'){
            this.profileForm.controls["sourceFunds"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["sourceFunds"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqCategory === 'P'&&(this.userDetailsService.accountType=='P'||!this.userDetailsService.userId)){
            this.profileForm.controls["category"].clearValidators();
        }
        else if(this.reqCategory === 'P'&&(this.userDetailsService.accountType!='P'&&this.userDetailsService.userId)){
            this.profileForm.controls["category"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["category"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqOccupation === 'P'){
            this.profileForm.controls["occupation"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["occupation"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqRisk === 'P'){
            this.profileForm.controls["riskPro"].setValidators([this.formService.autocomplete(),Validators.required]);
        } else {
            this.profileForm.controls["riskPro"].setValidators([this.formService.autocomplete()]);
        }
        if(this.reqTin === 'P'){
            this.profileForm.controls["tinNo"].setValidators([this.formService.tinValidator(), Validators.required]);
            //this.profileForm.controls["tinNo"].setValidators(this.profileForm.controls["tinNo"].validator ? [this.profileForm.controls["tinNo"].validator ,Validators.required] : [Validators.required] );
        }
        if(this.reqRmNo === 'P' && (this.userDetailsService.accountType == 'E' || this.userDetailsService.accountType == 'B')) {
            this.profileForm.controls["rmNo"].setValidators([Validators.required]);
        } else {
            this.profileForm.controls["rmNo"].clearValidators();
        }
        
        this.profileForm.controls["emailAdd"].updateValueAndValidity();
        this.profileForm.controls["mobileNo"].updateValueAndValidity();
        this.profileForm.controls["telNo"].updateValueAndValidity();
        this.profileForm.controls["birthDate"].updateValueAndValidity();
        this.profileForm.controls["regNo"].updateValueAndValidity();
        this.profileForm.controls["compAdd"].updateValueAndValidity();
        this.profileForm.controls["presAdd"].updateValueAndValidity();
        this.profileForm.controls["nameEmployer"].updateValueAndValidity();
        this.profileForm.controls["natureEmployment"].updateValueAndValidity();
        this.profileForm.controls["provOrCity"].updateValueAndValidity();
        this.profileForm.controls["provOrCityOfBirth"].updateValueAndValidity();
        this.profileForm.controls["presProv"].updateValueAndValidity();
        this.profileForm.controls["postCode"].updateValueAndValidity();
        this.profileForm.controls["presPostCode"].updateValueAndValidity();
        this.profileForm.controls["countryBirth"].updateValueAndValidity();
        this.profileForm.controls["countryOfBirth"].updateValueAndValidity();
        this.profileForm.controls["presCountry"].updateValueAndValidity();
        this.profileForm.controls["nationality"].updateValueAndValidity();
        this.profileForm.controls["sourceFunds"].updateValueAndValidity();
        this.profileForm.controls["category"].updateValueAndValidity();
        this.profileForm.controls["occupation"].updateValueAndValidity();
        this.profileForm.controls["riskPro"].updateValueAndValidity();
        this.profileForm.controls["tinNo"].updateValueAndValidity();
        this.profileForm.controls["rmNo"].updateValueAndValidity();
    }

    resetValue(value: any){
        if(value=="brTag"){
            this.checkAccType();
            this.listId = [];
            for (const listId of this.jsonService.data.idList.filter((a:any) => a.brTag == this.profileForm.controls['brTag'].value)) {
                this.listId.push(listId);
            }
            this.profileForm.patchValue({firstName:'', middleName:'', lastName:'', compName: '', gender: '', maritalStat: ''});
            this.chkValidDate();
        }
    }

    chkNoTin(event: any){
        if (event.checked) {
            this.profileForm.controls["tinNo"].clearValidators();
            this.profileForm.patchValue({noTin: "Y"});
            this.profileForm.patchValue({tinNo: "No TIN"});
            this.noTin = event.checked;
            this.profileForm.get("tinNo")?.disable();
        } else {
            this.profileForm.get("tinNo")?.enable();
            this.profileForm.controls["tinNo"].setValidators([this.formService.tinValidator(), Validators.required]);
            //this.profileForm.controls["tinNo"].setValidators(this.profileForm.controls["tinNo"].validator ? [this.profileForm.controls["tinNo"].validator ,Validators.required] : [Validators.required] );
            this.profileForm.patchValue({tinNo: ""});
            this.profileForm.patchValue({noTin: "N"});
            this.noTin = event.checked;

        }
        this.profileForm.controls["tinNo"].updateValueAndValidity();
    }

    checked(event: any){
        if(event.checked) {
            this.profileForm.patchValue({presAdd: this.profileForm.get('compAdd')?.value});
            if (this.profileForm.get('countryBirth')?.value.text === undefined) {
                this.profileForm.patchValue({presCountry: {value: "", text: ""}});
            } else {
                this.profileForm.patchValue({presCountry: this.profileForm.get('countryBirth')?.value});
            }
            if (this.profileForm.get('provOrCity')?.value.text === undefined) {
                this.profileForm.patchValue({presProv: {value: "", text: "", provCd: "", countryCd: ''}});
                this.resetPostal("presPostCode");
            } else {
                this.profileForm.patchValue({presProv: this.profileForm.get('provOrCity')?.value});
                this.resetPostal("presPostCode");
            }
            if (this.profileForm.get('postCode')?.value.text === undefined) {
                this.profileForm.patchValue({presPostCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                this.resetProv("presProv");
            } else {
                this.profileForm.patchValue({presPostCode: this.profileForm.get('postCode')?.value});
                this.resetProv("presProv");
            }
            if (this.profileForm.get('provOrCityDesc')?.value === undefined) {
                this.profileForm.patchValue({presProvDesc: {value: ""}});
                this.resetPostal("presPostCode");
            } else {
                this.profileForm.patchValue({presProvDesc: this.profileForm.get('provOrCityDesc')?.value});
            }
            if (this.profileForm.get('postCodeDesc')?.value === undefined) {
                this.profileForm.patchValue({presPostCodeDesc: {value: ""}});
            } else {
                this.profileForm.patchValue({presPostCodeDesc: this.profileForm.get('postCodeDesc')?.value});
            }
            this.profileForm.patchValue({presAddChkbox: "Y"});
            this.sameAsPermanent = event.checked;
        } else {
            this.profileForm.patchValue({presAddChkbox: "N"});
            this.sameAsPermanent = event.checked;
            this.profileForm.patchValue({presCountry: {value: "", text: ""}});
            this.profileForm.patchValue({presProvDesc:""});
            this.profileForm.patchValue({presPostCodeDesc:""});
            this.resetProv("presProv");
            this.resetPostal("presPostCode");
        }
    }

    setPresent(param: string) {
        if(param === "compAdd" && this.sameAsPermanent){
            this.profileForm.patchValue({presAdd: this.profileForm.get('compAdd')?.value});
        }
        if(param === "countryBirth" && this.sameAsPermanent) {
            if (this.profileForm.get('countryBirth')?.value.text === undefined) {
                this.profileForm.patchValue({presCountry: {value: "", text: ""}});
            } else {
                this.profileForm.patchValue({presCountry: this.profileForm.get('countryBirth')?.value});
            }
            if(this.sameAsPermanent){
                this.profileForm.patchValue({
                    presProvDesc: this.profileForm.get('provOrCityDesc')?.value,
                    presPostCodeDesc: this.profileForm.get('postCodeDesc')?.value
                });
            }
        }
        if(param === "postCode" && this.sameAsPermanent) {
            if (this.profileForm.get('postCode')?.value.text === undefined) {
                this.profileForm.patchValue({presPostCode: {value: "", text: ""}});
                this.resetProv("presProv");
            } else {
                this.profileForm.patchValue({presPostCode: this.profileForm.get('postCode')?.value});
                this.resetProv("presProv");
            }
        }
        if(param === "provOrCity" && this.sameAsPermanent) {
            if (this.profileForm.get('provOrCity')?.value.text === undefined) {
                this.profileForm.patchValue({presProv: {value: "", text: "", provCd: "", countryCd: ""}});
                this.resetPostal("presPostCode");
            } else {
                this.profileForm.patchValue({presProv: this.profileForm.get('provOrCity')?.value});
                this.resetPostal("presPostCode");
            }
        }
    }

    chkValidDate(){
        this.maxDate = new Date();
        this.minDate = new Date();
        if (this.profileForm.controls['brTag'].value == 'R') {
            this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
            this.minDate.setFullYear(this.minDate.getFullYear() - 100);
            if (this.profileForm.controls['birthDate'].value > this.maxDate || this.profileForm.controls['birthDate'].value < this.minDate){
                this.appMessageService.showAppMessage("Age must be 18 to 100 years old.", "error");
                this.profileForm.patchValue({birthDate: ""});
            }
        }
    }
    chkExpDate(i:number){
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        this.minExpDate = new Date();
        if (typeId.get("expDate")?.value < this.minExpDate){
            this.appMessageService.showAppMessage("ID submitted is already expired. Please provide an ID with a valid Expiry Date.", "error");
            typeId.patchValue({expDate: ""});
        }
    }
    setTinMax() {
        this.tinErrorMessage = "";
        if (this.profileForm.controls['tinNo'].value.includes('-') || this.profileForm.controls['tinNo'].value === "") {
            this.tinMax = 17;
        } else {
            this.tinMax = 14;
        }
    }
    setBirthMax() {
        if (this.profileForm.controls['birthDate'].value === null) {
            this.birthMax = 8;
        } else {
            if (this.profileForm.controls['birthDate'].value._i.includes('/') || this.profileForm.controls['birthDate'].value._i.includes('-')) {
                this.birthMax = 10;
            } else {
                this.birthMax = 8;
            }
        }
    }

    checkIdCount(){
        if (this.primaryIdCount >= this.minAssdId) {
            if(this.productInfo.creationProcess !== "renewal"){
                this.jsonDataService.contorlLoading(true);
                
                this.apiCallService.validateCoverage({
                    quoteId: this.productService.productInfo.quoteId
                }).subscribe((response:any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.securityService.hasValidCsrfToken(response, () => {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                if (res.rec === 'INVALID') {
                                    this.dialogBoxService.open({
                                        messageType: "E",
                                        content: "There are changes in Coverage Screen that requires saving. Please click OK to return.",
                                        buttons: [{
                                                    text: "OK", action: () => {
                                                        this.router.navigate(["/quotation/coverage-perils"]);
                                                    }
                                                 }]
                                    });
                                } else {
                                    this.checkHighRisk();
                                }
                            });
                        } else {
                            this.appMessageService.showAppMessage("Error in validating your information..", "error");
                        }
                    });
                });
            } else {
                this.router.navigate(["/policy/summary"]);
            }            
        } else {
            var content = "";
            var valid = "";
            if (this.minAssdId > 1) {
                valid = " valid IDs."
            } else if (this.minAssdId === 1) {
                valid = " valid ID."
            }

            this.dialogBoxService.open({
                messageType: "E",
                content: content.concat("To proceed with your application, please provide at least ", this.minAssdId.toString(),
                            valid),
                buttons: [{ text: "Ok" }]
            });
        }
    }

    checkHighRisk(){
        this.jsonDataService.contorlLoading(true);
        
        this.apiCallService.checkHighRisk({
            quoteId: this.productInfo.quoteId,
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.router.navigate(["/policy/acceptance"]);
                } else if (response.status === "HIGH_RISK") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if (this.productInfo.lineCd === "MC") {
                            const vehicleInfo = this.productService.productInfo.vehicleInfo;

                            this.dialogBoxService.open({
                                messageType: "I",
                                content: res.errorMessage,
                                buttons: [{
                                    text: "Contact BPI MS",
                                    action: () => {
                                        const url = this.router.serializeUrl(
                                            this.router.createUrlTree(["/contact-us"], {
                                                queryParams: {
                                                    brand: vehicleInfo.makeDetail.text,
                                                    yearModel: vehicleInfo.yearModelDetail.value,
                                                    model: vehicleInfo.modelDetail.text + " " + vehicleInfo.subModelDetail.text,
                                                    srp: vehicleInfo.purchasePrice
                                                }
                                            })
                                        );
                                        this.router.navigateByUrl(url);
                                    }
                                }, { text: "Close" }]
                            });
                        } else {
                            this.dialogBoxService.open({
                                messageType: "I",
                                content: res.errorMessage,
                                buttons: [{
                                    text: "Contact BPI MS",
                                    action: () => {
                                        const url = this.router.serializeUrl(this.router.createUrlTree(["/contact-us"], {}));
                                        this.router.navigateByUrl(url);
                                    }
                                }, { text: "Close" }]
                            });
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("Unable to check High Risk at this time. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }
        
    move(action: String){
        if (action === "back") {
            if (this.profileForm.dirty || this.idForm.dirty) {
                if(this.productService.productInfo.creationProcess==='renewal'){
                    this.dialogBoxService.open({
                        messageType: "C",
                        content: this.renewalKYCBackPrompt,
                        buttons: [
                            { text: "No" },
                            {
                                text: "Yes", action: () => {
                                        this.router.navigate(["/policy/summary"]);
                                }
                            }
                        ]
                    });
                } else{
                    this.dialogBoxService.open({
                        messageType: "C",
                        content: "You have unsaved changes. Do you want to continue?",
                        buttons: [
                            { text: "No" },
                            {
                                text: "Yes", action: () => {
                                    if (this.productService.productInfo.lineCd === "PA" || this.productService.productInfo.lineCd === "CI") {
                                        this.router.navigate(["/policy/personal-details"]);
                                    } else if (this.productService.productInfo.lineCd === "MC") {
                                        this.router.navigate(["/policy/vehicle-details"]);
                                    } else {
                                        this.router.navigate(["/policy/property-details"]);
                                    }
                                }
                            }
                        ]
                    });
                }
            } else {
                if(this.productService.productInfo.creationProcess==='renewal'){
                    this.router.navigate(["/policy/summary"]);
                } else{
                    if (this.productService.productInfo.lineCd === "PA" || this.productService.productInfo.lineCd === "CI") {
                        this.router.navigate(["/policy/personal-details"]);
                    } else if (this.productService.productInfo.lineCd === "MC") {
                        this.router.navigate(["/policy/vehicle-details"]);
                    } else {
                        this.router.navigate(["/policy/property-details"]);
                    }
                }
            }
        } else {
            if (this.profileForm.valid && this.idForm?.valid) {
                if (this.idForm.dirty) {
                    this.dialogBoxService.open({
                        messageType: "I",
                        content: "Please save your changes before you proceed.",
                        buttons: [
                            { text: "Ok" }
                        ]
                    });
                } else if (this.profileForm.dirty) {
                    this.dialogBoxService.open({
                        messageType: "C",
                        content: "You have unsaved changes. Do you want to continue?",
                        buttons: [
                            { text: "No" },
                            {
                                text: "Yes", action: () => {
                                    this.checkIdCount();
                                }
                            }
                        ]
                    });
                } else {
                    this.checkIdCount();
                }
            } else {
                this.dialogBoxService.open({
                    messageType: "E",
                    content: "There are missing information. Please complete the required fields.",
                    buttons: [{ text: "Ok" }]
                });
            }
        }
    }
    
    checkAccType():void {
        /*this.profileForm.controls["lastName"].reset();
        this.profileForm.controls["firstName"].reset();
        this.profileForm.controls["middleName"].reset();
        this.profileForm.controls["compName"].reset();
        this.profileForm.controls["gender"].reset();
        this.profileForm.controls["maritalStat"].reset();*/
        this.listId = [];
        for (const listId of this.jsonService.data.idList.filter((a:any) => a.brTag == this.profileForm.controls['brTag'].value)) {
            this.listId.push(listId);
        }

        //ndi pa sure kng lalagyan
        //this.filteredId1 = this.autocompleteService.set(this.profileForm, "typeId1", this.getIdList1());
        
        if (this.profileForm.controls['brTag'].value === "R") {
            this.profileForm.controls["lastName"].setValidators([Validators.required]);
            this.profileForm.controls["firstName"].setValidators([Validators.required]);
            this.profileForm.controls["dispName"].setValidators([Validators.required]);
            this.profileForm.controls["compName"].clearValidators();
            this.profileForm.controls["regNo"].clearValidators();
            
            if(this.reqMiddleName === 'P') {
                this.profileForm.controls["middleName"].setValidators([Validators.required]);
            }
            if (this.reqGender === 'P'){
                this.profileForm.controls["gender"].setValidators([this.formService.autocomplete(), Validators.required]);   
            } else {
                this.profileForm.controls["gender"].setValidators([this.formService.autocomplete()]);
            }
            if (this.reqMarital === 'P'){
                this.profileForm.controls["maritalStat"].setValidators([this.formService.autocomplete(), Validators.required]);
            } else {
                this.profileForm.controls["maritalStat"].setValidators([this.formService.autocomplete()]);
            }

        } else {
            this.profileForm.controls["lastName"].clearValidators();
            this.profileForm.controls["firstName"].clearValidators();
            this.profileForm.controls["dispName"].setValidators([Validators.required]);
            this.profileForm.controls["middleName"].clearValidators();
            this.profileForm.controls["gender"].clearValidators();
            this.profileForm.controls["maritalStat"].clearValidators();
            this.profileForm.controls["compName"].setValidators([Validators.required]);
            this.profileForm.controls["regNo"].setValidators([Validators.required]);
        }

        this.profileForm.controls["lastName"].updateValueAndValidity();
        this.profileForm.controls["firstName"].updateValueAndValidity();
        this.profileForm.controls["dispName"].updateValueAndValidity();
        this.profileForm.controls["middleName"].updateValueAndValidity();
        this.profileForm.controls["compName"].updateValueAndValidity();
        this.profileForm.controls["regNo"].updateValueAndValidity();
        this.profileForm.controls["gender"].updateValueAndValidity();
        this.profileForm.controls["maritalStat"].updateValueAndValidity();
    }

    async uploadSupDocs(index: number, response: any, form: any): Promise<void> {
        //const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        this.jsonDataService.contorlLoading(true);
        const base64String = await this.formService.getFileAsBase64(this.supportingDocsFiles[index]);
        this.apiCallService.getPresignedUrl({
            action: "putObject",
            directory: `supporting-docs/customer-information/${this.profileForm.get("assdNo")?.value}`,
            filename: this.supportingDocsFiles[index].name,
            validation: "CustomerSuppDoc",
            base64FileString: base64String
        }).subscribe((getPresignedUrlResponse: any) => {
            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                this.jsonDataService.contorlLoading(false);
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.jsonDataService.contorlLoading(true);
                            this.apiCallService.uploadPresignedUrl(
                                res.presignedUrl, this.supportingDocsFiles[index]
                            ).subscribe((uploadPresignedUrlResponse: any) => {
                                this.jsonDataService.contorlLoading(false);
                                if (index === (this.supportingDocsFiles.length-1)) {
                                    this.proceedAfterSave(response, form);
                                } else {
                                    this.uploadSupDocs(index+1, response, form);
                                }
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.supportingDocsFiles = [];
                            this.profileForm.controls["supportingDocs"].setValue([]);
                            this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your personal information. Please try again later.", "error");
                }
            });
        });
    }

    saveCustInfo(form:any, id:any) {
        if(this.productInfo.creationProcess==='renewal'){
            if(this.ids.length == 0){
                var content = "";
                var valid = "";
                if (this.minAssdId > 1) {
                    valid = " valid IDs."
                } else if (this.minAssdId === 1) {
                    valid = " valid ID."
                }

                this.dialogBoxService.open({
                    messageType: "E",
                    content: content.concat("To proceed with your application, please provide at least ", this.minAssdId.toString(),
                                valid),
                    buttons: [{ text: "Ok" }]
                });
            }
            else{
                this.jsonDataService.contorlLoading(true);
                this.apiCallService.saveRenewalCustInfo({
                    policyId: this.productService.productInfo.policyId,
                    assdNo: form.assdNo,
                    brTag: form.brTag,
                    lastName: form.lastName,
                    firstName: form.firstName,
                    middleName: form.middleName,
                    compName: form.compName,
                    displayName: form.dispName,
                    emailAdd: form.emailAdd,
                    mobileNo: form.mobileNo,
                    telNo: form.telNo,
                    birthDate: form.birthDate,
                    regNo: form.regNo,
                    gender: form.gender.value,
                    maritalStat: form.maritalStat.value,
                    compAdd: form.compAdd,
                    presAdd: form.presAdd,
                    presAddChkbox: form.presAddChkbox,
                    provOrCityOfBirth: form.provOrCityOfBirth.provCd,
                    cityOfBirth: form.provOrCityOfBirth.value,
                    provOrCity: form.provOrCity.provCd,
                    city: form.provOrCity.value,
                    presProv: form.presProv.provCd,
                    presCity: form.presProv.value,
                    postCode: form.postCode.value,
                    presPostCode: form.presPostCode.value,
                    countryOfBirth: form.countryOfBirth.value,
                    countryBirth: form.countryBirth.value,
                    presCountry: form.presCountry.value,
                    nationality: form.nationality.value,
                    sourceFunds: form.sourceFunds.value,
                    nameEmployer: form.nameEmployer,
                    natureEmployment: form.natureEmployment,
                    category: form.category.value,
                    occupation: form.occupation.value,
                    occOther: form.occOther,
                    riskPro: form.riskPro.value,
                    tinNo: form.tinNo,
                    noTin: form.noTin,
                    ids: id.ids,
                    suffix: form.suffix,
                    provOrCityOfBirthDesc: form.provOrCityOfBirthDesc,
                    provOrCityDesc: form.provOrCityDesc,
                    postCodeDesc: form.postCodeDesc,
                    presProvDesc: form.presProvDesc,
                    presPostCodeDesc: form.presPostCodeDesc,
                    supportingDocs: form.supportingDocs || [],
                    rmNo: form.rmNo
                }).subscribe((response:any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.securityService.hasValidCsrfToken(response, () => {
                                if (this.supportingDocsFiles.length > 0 && this.withSupportingDocChange) {
                                    this.uploadSupDocs(0, response, form);
                                } else {
                                    this.proceedAfterSave(response, form);
                                }
                            });
                        } else {
                            this.appMessageService.showAppMessage("Error saving your personal information.", "error");
                        }
                    });
                });
            }
        } else {
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.saveCustInfo({
                quoteId: this.productService.productInfo.quoteId,
                assdNo: form.assdNo,
                brTag: form.brTag,
                lastName: form.lastName,
                firstName: form.firstName,
                middleName: form.middleName,
                compName: form.compName,
                displayName: form.dispName,
                emailAdd: form.emailAdd,
                mobileNo: form.mobileNo,
                telNo: form.telNo,
                birthDate: form.birthDate,
                regNo: form.regNo,
                gender: form.gender.value,
                maritalStat: form.maritalStat.value,
                compAdd: form.compAdd,
                presAdd: form.presAdd,
                presAddChkbox: form.presAddChkbox,
                provOrCityOfBirth: form.provOrCityOfBirth.provCd,
                cityOfBirth: form.provOrCityOfBirth.value,
                provOrCity: form.provOrCity.provCd,
                city: form.provOrCity.value,
                presProv: form.presProv.provCd,
                presCity: form.presProv.value,
                postCode: form.postCode.value,
                presPostCode: form.presPostCode.value,
                countryOfBirth: form.countryOfBirth.value,
                countryBirth: form.countryBirth.value,
                presCountry: form.presCountry.value,
                nationality: form.nationality.value,
                sourceFunds: form.sourceFunds.value,
                nameEmployer: form.nameEmployer,
                natureEmployment: form.natureEmployment,
                category: form.category.value,
                occupation: form.occupation.value,
                occOther: form.occOther,
                riskPro: form.riskPro.value,
                tinNo: form.tinNo,
                noTin: form.noTin,
                ids: id.ids,
                suffix: form.suffix,
                provOrCityOfBirthDesc: form.provOrCityOfBirthDesc,
                provOrCityDesc: form.provOrCityDesc,
                postCodeDesc: form.postCodeDesc,
                presProvDesc: form.presProvDesc,
                presPostCodeDesc: form.presPostCodeDesc,
                supportingDocs: form.supportingDocs || [],
                rmNo: form.rmNo
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (this.supportingDocsFiles.length > 0 && this.withSupportingDocChange) {
                                this.uploadSupDocs(0, response, form);
                            } else {
                                this.proceedAfterSave(response, form);
                            }
                        });
                    } else {
                        this.appMessageService.showAppMessage("Error saving your personal information.", "error");
                    }
                });
            });
        }
    }

    proceedAfterSave(response: any, form: any): void {
        this.jsonDataService.contorlLoading(false);
        this.withSupportingDocChange = false;
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        //sessionStorage.setItem("rid", res.recordId);
        if(res.risk == "HIGH_RISK"&&this.productInfo.creationProcess=='renewal'){
            this.productInfo.customerInfo.riskProfilingDetail.value = 1;
            this.productInfo.customerInfo.riskProfilingDetail.text = 'HIGH_RISK';
        }
        else{
            if(this.productInfo.creationProcess=='renewal'){
                this.productInfo.customerInfo.riskProfilingDetail.value = 2;
            }
        }
        
        this.productService.productInfo.policyHolderDetails.policy.policyAddress = form.compAdd;

        if(this.productInfo.creationProcess=='renewal'&&this.productInfo.lineCd=='PA'){
            const effDate = new Date(this.productInfo.coverageInfo.effDate);
            const birthDate = new Date(form.birthDate);
            const timeDiff = Math.abs(effDate.getTime() - birthDate.getTime());
            const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
            this.productInfo.paPropertyInfo.age = age;
        }

        this.setPolicyHolderDetails(form);
        this.setCustomerInfo(form);

        this.profileForm.markAsPristine();
        this.idForm.markAsPristine();
        if(this.productInfo.creationProcess=='renewal'){
            this.appMessageService.showAppMessage("Successfully saved.", "success");
            this.router.navigate(["/policy/summary"]);
        }
        else{
            this.appMessageService.showAppMessage("Successfully saved.", "success");
        }
        this.primaryIdCount = res.idCount.validIdCount;
    }


    onSubmit() {
        this.idReq = true;
        this.checkCountry();
        this.jsonDataService.contorlLoading(true);
        var display = "";
        if(this.sameAsPermanent){
            this.profileForm.patchValue({
                presProvDesc: this.profileForm.get('provOrCityDesc')?.value,
                presPostCodeDesc: this.profileForm.get('postCodeDesc')?.value
            });
        }
        if(this.userType === "P"){
            if (this.profileForm.controls['brTag'].value === "R") {
                this.profileForm.patchValue({dispName: display.concat(this.profileForm.controls['firstName'].value, " ", this.profileForm.controls['middleName'].value, " ", this.profileForm.controls['lastName'].value, " ", this.profileForm.controls['suffix'].value)});
                //form.displayName = display.concat(this.profileForm.controls['firstName'].value, " ", this.profileForm.controls['middleName'].value, " ", this.profileForm.controls['lastName'].value, " ", this.profileForm.controls['suffix'].value);
            } else {
                this.profileForm.patchValue({dispName: this.profileForm.controls['compName'].value});
                //form.displayName = form.compName;
            }
        }

        this.counter = this.fileUpload.length;
        if(this.productInfo.creationProcess==='renewal'){
            if(this.profileForm.valid) {
                this.tinErrorMessage = "";
                if (this.idForm?.valid) {
                    const id = this.idForm.getRawValue();
                    const form = this.profileForm.getRawValue();
                    
                    if(form.birthDate && typeof form.birthDate != 'string'){
                        form.birthDate = form.birthDate.format('YYYY-MM-DD');
                    }
        
                    for (const ids of id.ids){
                        if(ids.expDate && typeof ids.expDate != 'string'){
                            ids.expDate = ids.expDate.format('YYYY-MM-DD');
                        }
                    }
        
                    if(form.suffix == ''){
                        form.suffix = null;
                    }
                    
                    if (this.fileUpload.length === 0) {
                        if(this.productInfo.creationProcess==='renewal'&& this.ids.length == 0){
                            this.jsonDataService.contorlLoading(false);
                            var content = "";
                            var valid = "";
                            if (this.minAssdId > 1) {
                                valid = " valid IDs."
                            } else if (this.minAssdId === 1) {
                                valid = " valid ID."
                            }
        
                            this.dialogBoxService.open({
                                messageType: "E",
                                content: content.concat("To proceed with your application, please provide at least ", this.minAssdId.toString(),
                                            valid),
                                buttons: [{ text: "Ok" }]
                            });
                        }
                        else{
                            this.saveCustInfo(form, id);
                        }
                    } else {
                        this.urlFileUpload(0, form, id);
                    }
                } else {
                    if (this.containers.length === 0 || this.containers.length < this.minAssdId) {
                        this.jsonDataService.contorlLoading(false);
                        var content = "";
                        var valid = "";
                        if (this.minAssdId > 1) {
                            valid = " valid IDs."
                        } else if (this.minAssdId === 1) {
                            valid = " valid ID."
                        }
    
                        this.dialogBoxService.open({
                            messageType: "E",
                            content: content.concat("To proceed with your application, please provide at least ", this.minAssdId.toString(),
                                        valid),
                            buttons: [{ text: "Ok" }]
                        });
                    } else {
                        if (this.containers.length != this.ids.length) {
                            this.jsonDataService.contorlLoading(false);
                            this.idForm.markAllAsTouched();
                            this.dialogBoxService.open({
                                messageType: "E",
                                content: "A copy of your ID is required. Please upload your ID.",
                                buttons: [{ text: "Ok" }]
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.idForm.markAllAsTouched();
                            this.dialogBoxService.open({
                                messageType: "E",
                                content: "There are missing information. Please complete the required fields.",
                                buttons: [{ text: "Ok" }]
                            });
                        }
                    }
                }

            } else {
                this.jsonDataService.contorlLoading(false);
                this.idForm.markAllAsTouched();
                if (this.profileForm.get("tinNo")?.hasError("tin")) {
                    this.tinErrorMessage = "TIN format must be 12-15 digit numbers separated by dash (-). Example 999-999-999-000 or 999-999-999-00000";
                }
                this.dialogBoxService.open({
                    messageType: "E",
                    content: "There are missing information. Please complete the required fields.",
                    buttons: [{ text: "Ok" }]
                });
            }
        } else {
            if(this.profileForm.valid && this.idForm?.valid) {
                const id = this.idForm.getRawValue();
                const form = this.profileForm.getRawValue();
                
                if(form.birthDate && typeof form.birthDate != 'string'){
                    form.birthDate = form.birthDate.format('YYYY-MM-DD');
                }
    
                for (const ids of id.ids){
                    if(ids.expDate && typeof ids.expDate != 'string'){
                        ids.expDate = ids.expDate.format('YYYY-MM-DD');
                    }
                }
    
                if(form.suffix == ''){
                    form.suffix = null;
                }
                
                if (this.fileUpload.length === 0) {
                    if(this.productInfo.creationProcess==='renewal'&& this.ids.length == 0){
                        this.jsonDataService.contorlLoading(false);
                        var content = "";
                        var valid = "";
                        if (this.minAssdId > 1) {
                            valid = " valid IDs."
                        } else if (this.minAssdId === 1) {
                            valid = " valid ID."
                        }
    
                        this.dialogBoxService.open({
                            messageType: "E",
                            content: content.concat("To proceed with your application, please provide at least ", this.minAssdId.toString(),
                                        valid),
                            buttons: [{ text: "Ok" }]
                        });
                    } else{
                        this.saveCustInfo(form, id);
                    }
                } else {
                    this.urlFileUpload(0, form, id);
                }
            } else {
                this.jsonDataService.contorlLoading(false);
                this.idForm.markAllAsTouched();
                this.dialogBoxService.open({
                    messageType: "E",
                    content: "There are missing information. Please complete the required fields.",
                    buttons: [{ text: "Ok" }]
                });
            }
        }
    }

    urlFileUpload(i: number, form: any, id: any) {
        try { 
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getPresignedUrlUploadId({
                action: "putObject",
                fileName: this.fileUpload[i].file.name,
                assdNo: this.profileForm.get("assdNo")?.value
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            
                            this.jsonDataService.contorlLoading(true);
                            this.apiCallService.uploadPresignedUrl(
                                res.URL, this.fileUpload[i].file
                            ).subscribe((a:any) => {
                                this.jsonDataService.contorlLoading(false);
                                this.counter = this.counter - 1;
                                if (this.counter === 0) { 
                                    this.saveCustInfo(form, id);
                                } else {
                                    this.urlFileUpload(i+1, form, id);
                                }
                            });
                        });
                    } else {
                        this.appMessageService.showAppMessage("Error saving your personal information.", "error");
                    }
                });
            }, (error:any) => {
                
            });
        } catch(e) {
            console.error(e);
        }
    }
    
    //AUTO COMPLETE//
//Gender
    setGenderPlaceholder():string {
        if (   typeof this.profileForm.controls["gender"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "gender")) {
            return "Please select Gender";
        } else {
            return "Gender";
        }
    }
    getGender():any[] {
        let gender = [];
        for (let gen of this.jsonDataService.data.gender) {
            gender.push({
                value: gen.genderCd,
                text: gen.genderDesc.toUpperCase()
            });
        }
        gender.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return gender;
    }
    setGenderViaInput():void {
        for (const gen of this.getGender()) {
            if (   gen.text === this.profileForm.controls["gender"].value
                || gen.value === this.profileForm.controls["gender"].value.value) {
                    this.profileForm.controls["gender"].setValue(gen);
                    //this.validatedGender();
            }
        }
    }
    setGenderViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
        //this.validatedGender();
    }
    /*validatedGender():void {
        this.filteredGender = this.autocompleteService.set(this.profileForm, "gender", this.getGender());
    }*/
//MaritalStat
    setMaritalPlaceholder():string {
        if (   typeof this.profileForm.controls["maritalStat"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "maritalStat")) {
            return "Please select Marital Status";
        } else {
            return "Marital Status";
        }
    }
    getMaritalStat():any[] {
        let marStat: any[] = [];
        for (let marital of this.jsonDataService.data.maritalStat) {
            marStat.push({
                value: marital.maritalStatCd,
                text: marital.maritalDesc.toUpperCase()
            });
        }
        marStat.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return marStat;
    }
    setMaritalViaInput():void {
        for (const marital of this.getMaritalStat()) {
            if (   marital.text === this.profileForm.controls["maritalStat"].value
                || marital.value === this.profileForm.controls["maritalStat"].value.value) {
                    this.profileForm.controls["maritalStat"].setValue(marital);
            }
        }
    }
    setMaritalViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
    }
//Province
    setProvPlaceholder(params:String):string {
        let placeholder = "";
        if (params === "provOrCityOfBirth") {
            if (   typeof this.profileForm.controls["provOrCityOfBirth"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "provOrCityOfBirth")) {
                placeholder = "Please select Province or City of Birth";
            } else {
                placeholder = "Province or City of Birth";
            } 
        } else if (params === "provOrCity"){
            if (   typeof this.profileForm.controls["provOrCity"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "provOrCity")) {
                placeholder = "Please select Province or City";
            } else {
                placeholder = "Province or City";
            }
        } else if (params === "presProv") {
            if (   typeof this.profileForm.controls["presProv"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "presProv")) {
                placeholder = "Please select Province or City";
            } else {
                placeholder = "Province or City";
            }
        }
        return placeholder;
    }
    getProvince(params: string):any[] {
        let provCity: any[] = [];
        let name = "";
        let country = "";
        let prov = "";
        let cityCd = "";
        /*for (let prov of this.jsonDataService.data.city) {
            provCity.push({
                value: prov.cityCd,
                text: prov.cityName
            });
        }*/
        
        if (params === 'provOrCity') {
            country = this.profileForm.controls["countryBirth"].value.value;
            prov = this.profileForm.controls["postCode"].value.provCd || "";
            cityCd = this.profileForm.controls["postCode"].value.cityCd || "";
        } else if (params === 'presProv') {
            country = this.profileForm.controls["presCountry"].value.value;
            prov = this.profileForm.controls["presPostCode"].value.provCd || "";
            cityCd = this.profileForm.controls["presPostCode"].value.cityCd || "";
        } else if (params === 'provOrCityOfBirth') {
            country = this.profileForm.controls["countryOfBirth"].value.value || "";
        }

        if ((prov === "") && (cityCd === "")) {
            for (let prov of this.jsonDataService.data.province) {
                for(let city of this.jsonDataService.data.city.filter((a:any) => a.provinceCd === prov.provinceCd && a.countryCd === prov.countryCd &&
                a.countryCd === Number(country))){
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", prov.provinceName),
                        provCd: city.provinceCd,
                        countryCd: city.countryCd
                    });
                }
            }
        } else {
            for (let province of this.jsonDataService.data.province) {
                for(let city of this.jsonDataService.data.city.filter((a:any) => a.provinceCd === prov && a.countryCd === Number(country) && 
                    a.cityCd === Number(cityCd) && a.provinceCd === province.provinceCd && a.countryCd === province.countryCd)){
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", province.provinceName),
                        provCd: city.provinceCd,
                        countryCd: city.countryCd
                    });
                }
            }
        }
        
        provCity.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return provCity;
    }
    setProvViaInput(params: string, event: any):void {
        for (const prov of this.getProvince(params)) {
            if(params === "provOrCityOfBirth"){
                if (   prov.text === this.profileForm.controls["provOrCityOfBirth"].value
                    /*|| prov.value === this.profileForm.controls["provOrCityOfBirth"].value.value*/) {
                        this.profileForm.controls["provOrCityOfBirth"].setValue(prov);
                        this.profileForm.patchValue({provOrCityOfBirthDesc:""});
                }
            } else if (params === "provOrCity"){
                if (   prov.text === this.profileForm.controls["provOrCity"].value
                    /*|| prov.value === this.profileForm.controls["provOrCity"].value.value*/) {
                        this.profileForm.controls["provOrCity"].setValue(prov);
                }
                if (this.profileForm.controls["provOrCity"].value === "") {
                    this.profileForm.patchValue({postCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                    this.resetProv(params);
                    if(this.sameAsPermanent){
                        this.profileForm.patchValue({presPostCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                    }
                }
            } else if (params === "presProv"){
                if (   prov.text === this.profileForm.controls["presProv"].value
                    /*|| prov.value === this.profileForm.controls["presProv"].value.value*/) {
                        this.profileForm.controls["presProv"].setValue(prov);
                }
                if (this.profileForm.controls["presProv"].value === "") {
                    this.profileForm.patchValue({presPostCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                    this.resetProv(params);
                }
            }
        }
        if (params === "provOrCity") {
            this.resetPostal("postCode");
        } else if (params === "presProv") {
            this.resetPostal("presPostCode");
        }
    }
    setProvViaOption(option:any, params:string):void {
        const optionEl = option._element.nativeElement;
        this.resetPostal(params);
    }
    resetProv(params: string): void {
        if (params === 'provOrCity') {
            this.filteredProvince = this.autocompleteService.set(this.profileForm, params, this.getProvince(params));
        } else if (params === 'presProv') {
            this.filteredPresProv = this.autocompleteService.set(this.profileForm, params, this.getProvince(params));
        } else if (params === 'provOrCityOfBirth') {
            this.filteredProvOfBirth = this.autocompleteService.set(this.profileForm, params, this.getProvince(params));
        }
        if (this.getProvince(params).length === 1) {
            this.profileForm.controls[params].setValue(this.getProvince(params)[0]);
            if (params === "provOrCity" && this.sameAsPermanent) {
                this.profileForm.controls["presProv"].setValue(this.getProvince(params)[0]);
                this.resetPostal("presPostCode");
            }
        }
    }
//Postal Code
    setPostalPlaceholder(params: String):string {
        let placeholder = "";
        if (params === "postCode") {
            if (   typeof this.profileForm.controls["postCode"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "postCode")) {
                placeholder = "Please select Postal Code";
            } else {
                placeholder = "Postal Code";
            }
        } else if (params === "presPostCode") {
            if (   typeof this.profileForm.controls["presPostCode"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "presPostCode")) {
                placeholder = "Please select Postal Code";
            } else {
                placeholder = "Postal Code";
            }
        }
        return placeholder;
    }
    getPostal(params: string):any[] {
        let postal: any[] = [];
        let postName = "";
        let country = "";
        let prov = "";
        let city = "";
        if (params === 'postCode') {
            country = this.profileForm.controls["countryBirth"].value.value;
            prov = this.profileForm.controls["provOrCity"].value.provCd || "";
            city = this.profileForm.controls["provOrCity"].value.value || "";
        } else if (params === 'presPostCode') {
            country = this.profileForm.controls["presCountry"].value.value;
            prov = this.profileForm.controls["presProv"].value.provCd || "";
            city = this.profileForm.controls["presProv"].value.value || "";
        }
        
        if (prov === "" && city === "") {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        } else {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country)
                 && a.provinceCd === Number(prov) && a.cityCd === Number(city))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        }

        postal.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return postal;
    }
    setPostalViaInput(params: string):void {
        for (const post of this.getPostal(params)) {
            if (params === "postCode") {
                if (   post.text === this.profileForm.controls["postCode"].value
                    || post.value === this.profileForm.controls["postCode"].value.value) {
                        this.profileForm.controls["postCode"].setValue(post);
                }
            } else if (params === "presPostCode") {
                if (   post.text === this.profileForm.controls["presPostCode"].value
                    || post.value === this.profileForm.controls["presPostCode"].value.value) {
                        this.profileForm.controls["presPostCode"].setValue(post);
                }
            }
        }
        if (params === "postCode") {
            this.resetProv("provOrCity");
        } else if (params === "presPostCode") {
            this.resetProv("presProv");
        }
    }
    setPostalViaOption(option:any, params:string):void {
        const optionEl = option._element.nativeElement;
        this.resetProv(params);

    }
    resetPostal(params: string):void{
        if (params === 'postCode') {
            this.filteredPostal = this.autocompleteService.set(this.profileForm, params, this.getPostal(params));
        } else if (params === 'presPostCode') {
            this.filteredPresPostCode = this.autocompleteService.set(this.profileForm, params, this.getPostal(params));
        }
        if (this.getPostal(params).length === 1) {
            this.profileForm.controls[params].setValue(this.getPostal(params)[0]);
            if (params === "postCode" && this.sameAsPermanent) {
                this.profileForm.controls["presPostCode"].setValue(this.getPostal(params)[0]);
                this.resetProv("presProv");
            }
        }
    }
//Country
    setCountryPlaceholder(params: String):string {
        let placeholder = "";
        if (params === "countryOfBirth") {
            if (   typeof this.profileForm.controls["countryOfBirth"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "countryOfBirth")) {
                placeholder = "Please select Country of birth";
            } else {
                placeholder = "Country of birth";
            }
        } else if (params === "countryBirth") {
            if (   typeof this.profileForm.controls["countryBirth"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "countryBirth")) {
                placeholder = "Please select Country";
            } else {
                placeholder = "Country";
            }
        } else if (params === "presCountry") {
            if (   typeof this.profileForm.controls["presCountry"].value !== "object"
                || this.formService.isInvalid(this.profileForm, "presCountry")) {
                placeholder = "Please select Country";
            } else {
                placeholder = "Country";
            }
        }
        return placeholder;
    }
    getCountry():any[] {
        let country: any[] = [];
        for (let count of this.jsonDataService.data.country) {
            country.push({
                value: count.countryCd,
                text: count.countryName
            });
        }
        //country.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return country;
    }
    setCountryViaInput(params: String):void {
        for (const count of this.getCountry()) {
            if(params === "countryBirth"){
                if (   count.text === this.profileForm.controls["countryBirth"].value
                    || count.value === this.profileForm.controls["countryBirth"].value.value) {
                        this.profileForm.controls["countryBirth"].setValue(count);
                        this.resetProv('provOrCity');
                        this.resetPostal('postCode');
                }
                if (this.profileForm.controls["countryBirth"].value === "") {
                    this.profileForm.patchValue({provOrCity: {value: "", text: "", provCd: "", countryCd: ''}});
                    this.profileForm.patchValue({postCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                    this.resetProv('provOrCity');
                    this.resetPostal('postCode');
                    if(this.sameAsPermanent){
                        this.profileForm.patchValue({presProv: {value: "", text: "", provCd: "", countryCd: ''}});
                        this.profileForm.patchValue({presPostCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                        this.resetProv('presProv');
                        this.resetPostal('presPostCode');
                    }
                }
            } else if(params === "countryOfBirth"){
                if (   count.text === this.profileForm.controls["countryOfBirth"].value
                    || count.value === this.profileForm.controls["countryOfBirth"].value.value) {
                        this.profileForm.controls["countryOfBirth"].setValue(count);
                        this.resetProv('provOrCityOfBirth');
                }
                if (this.profileForm.controls["countryOfBirth"].value === "") {
                    this.profileForm.patchValue({provOrCityOfBirth: {value: "", text: "", provCd: "", countryCd: ''}});
                    this.resetProv('provOrCityOfBirth');
                }
            } else if(params === "presCountry") {
                if (   count.text === this.profileForm.controls["presCountry"].value
                    || count.value === this.profileForm.controls["presCountry"].value.value) {
                        this.profileForm.controls["presCountry"].setValue(count);
                        this.resetProv('presProv');
                        this.resetPostal('presPostCode');
                }
                if (this.profileForm.controls["presCountry"].value === "") {
                    this.profileForm.patchValue({presProv: {value: "", text: "", provCd: "", countryCd: ''}});
                    this.profileForm.patchValue({presPostCode: {value: "", text: "", provCd: "", countryCd: "", cityCd: ""}});
                    this.resetProv('presProv');
                    this.resetPostal('presPostCode');
                }
            }
        }
    }
    setCountryViaOption(option:any, params: string):void {
        const optionEl = option._element.nativeElement;
        if (params === 'countryOfBirth') {
            this.resetProv('provOrCityOfBirth');    
        } else if (params === 'countryBirth') {
            this.resetProv('provOrCity');
            this.resetPostal('postCode');
        } else if (params === 'presCountry') {
            this.resetProv('presProv');
            this.resetPostal('presPostCode');
        }
        
    }
//Nationality
    setNationalityPlaceholder():string {
        if (   typeof this.profileForm.controls["nationality"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "nationality")) {
            return "Please select Nationality";
        } else {
            return "Nationality";
        }
    }
    getNationality():any[] {
        let nationality: any[] = [];
        for (let nation of this.jsonDataService.data.nationality) {
            nationality.push({
                value: nation.nationCd, //galing extract
                text: nation.nationDesc
            });
        }
        nationality.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return nationality;
    }
    setNationalityViaInput():void {
        for (const nation of this.getNationality()) {
            if (   nation.text === this.profileForm.controls["nationality"].value
                || nation.value === this.profileForm.controls["nationality"].value.value) {
                    this.profileForm.controls["nationality"].setValue(nation);
            }
        }
    }
    setNationalityViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
    }
//Source of Funds    
    setFundsPlaceholder():string {
        if (   typeof this.profileForm.controls["sourceFunds"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "sourceFunds")) {
            return "Please select a Source of Funds";
        } else {
            return "Source of Funds";
        }
    }
    getFunds():any[] {
        let funds: any[] = [];
        for (let source of this.jsonDataService.data.sourceFund) {
            funds.push({
                value: source.sourceFundCd,
                text: source.sourceFundDesc
            });
        }
        funds.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return funds;
    }
    setFundsViaInput():void {
        for (const source of this.getFunds()) {
            if (   source.text === this.profileForm.controls["sourceFunds"].value
                || source.value === this.profileForm.controls["sourceFunds"].value.value) {
                    this.profileForm.controls["sourceFunds"].setValue(source);
                    this.validateTinNo();
            }
        }
    }
    setFundsViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
        this.validateTinNo();
    }
    validateTinNo(){
        if(!this.withNoTin.includes(Number(this.profileForm.controls["sourceFunds"].value.value))) {
            this.profileForm.controls["tinNo"].clearValidators();
            this.profileForm.get("tinNo")?.enable();
            this.profileForm.controls["tinNo"].setValidators([this.formService.tinValidator(), Validators.required]);
            //this.profileForm.controls["tinNo"].setValidators(this.profileForm.controls["tinNo"].validator ? [this.profileForm.controls["tinNo"].validator ,Validators.required] : [Validators.required] );
            if (this.profileForm.controls['tinNo'].value === "No TIN") {
                this.profileForm.patchValue({tinNo: ""});
            }
            this.profileForm.patchValue({noTin: "N"});
            this.noTin = false;
            this.setNoTinBox = false;
            this.profileForm.controls["tinNo"].updateValueAndValidity();
            if(this.profileForm.get('tinNo')?.invalid){
                this.profileForm.get('tinNo')?.value && this.profileForm.get('tinNo')?.markAsTouched();
            }
        } else {
            this.setNoTinBox = true;
        }
    }
//Category
    setCategoryPlaceholder():string {
        if (   typeof this.profileForm.controls["category"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "category")) {
            return "Please select Category";
        } else {
            return "Category";
        }
    }
    getCategory():any[] {
        let category: any[] = [];
        for (let cat of this.jsonDataService.data.category) {
            category.push({
                value: cat.catCd,
                text: cat.catDesc
            });
        }
        category.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return category;
    }
    setCategoryViaInput():void {
        for (const cat of this.getCategory()) {
            if (   cat.text === this.profileForm.controls["category"].value
                || cat.value === this.profileForm.controls["category"].value.value) {
                    this.profileForm.controls["category"].setValue(cat);
            }
        }
    }
    setCategoryViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
    }
    setCategory() {
        for (let cat of this.jsonDataService.data.category.filter((a:any) => a.catCd == this.profileForm.get('occupation')?.value.catCd)) {
            this.profileForm.controls['category'].patchValue({value: cat.catCd, text: cat.catDesc});
        }
    };
//Occupation
    setOccupationPlaceholder():string {
        if (   typeof this.profileForm.controls["occupation"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "occupation")) {
            return "Please select Occupation";
        } else {
            return "Occupation";
        }
    }
    getOccupation():any[] {
        let occupation: any[] = [];
        for (let occ of this.jsonDataService.data.occupation) {
            occupation.push({
                value: occ.occCd,
                text: occ.occDesc,
                catCd: occ.catCd
            });
        }
        occupation.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return occupation;
    }
    setOccupationViaInput():void {
        for (const occ of this.getOccupation()) {
            if (   occ.text === this.profileForm.controls["occupation"].value
                || occ.value === this.profileForm.controls["occupation"].value.value) {
                    this.profileForm.controls["occupation"].setValue(occ);
                    this.setCategory();
            }
        }
        this.setOtherValidators();
    }
    setOtherValidators(){
        if (this.profileForm.get("occupation")?.value.value == this.occOther) {
            this.profileForm.get('occOther')?.enable();
            this.profileForm.controls["occOther"].setValidators([Validators.required]);
        } else {
            this.profileForm.patchValue({occOther:""});
            this.profileForm.controls["occOther"].clearValidators();
            this.profileForm.get('occOther')?.disable();
        }
        this.profileForm.controls["occOther"].updateValueAndValidity();
    }
    setOccupationViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
        this.setCategory();
        this.setOtherValidators();
    }
//Risk Profile
    setRiskPlaceholder():string {
        if (   typeof this.profileForm.controls["riskPro"].value !== "object"
            || this.formService.isInvalid(this.profileForm, "riskPro")) {
            return "Please select Risk profiling";
        } else {
            return "Risk profiling";
        }
    }
    getRisk():any[] {
        let risk: any[] = [];
        for (let r of this.jsonDataService.data.riskProfCd) {
            risk.push({
                value: r.rpCd,
                text: r.bmDesc
            });
        }
        risk.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return risk;
    }
    setRiskViaInput():void {
        for (const r of this.getRisk()) {
            if (   r.text === this.profileForm.controls["riskPro"].value
                || r.value === this.profileForm.controls["riskPro"].value.value) {
                    this.profileForm.controls["riskPro"].setValue(r);
            }
        }
    }
    setRiskViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
    }
//Id Type 1
    setIdPlaceholder1(i: number):string {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        if (   typeof typeId.value !== "object"
            || this.formService.isInvalid(this.idForm, "typeId")) {
            return "Please select a Valid ID";
        } else {
            return "Valid ID";
        }
    }
    getIdList1(i:number):any[] {
        let idType: any[] = [];
        for (let id of this.jsonDataService.data.idList.filter((a:any) => a.brTag == this.profileForm.get('brTag')?.value &&
             this.idForm.get('ids')?.value.map(function (type:any) { return type.typeId.text; }).indexOf(a.idTypeDesc) === -1)) {
            idType.push({
                value: id.idTypeCd,
                text: id.idTypeDesc
            });
        }
        idType.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return idType;
    }
    /*getIdList1():any[] {
        let idType: any[] = [];
        for (let id of this.jsonDataService.data.idList.filter((a:any) => a.brTag == this.profileForm.get('brTag')?.value && 
            a.idTypeCd != this.profileForm.get('typeId2')?.value.value && a.idTypeCd != this.profileForm.get('typeId3')?.value.value)) {
            idType.push({
                value: id.idTypeCd,
                text: id.idTypeDesc
            });
        }
        idType.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return idType;
    }*/
    setIdViaInput1(i: number):void {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        for (const id of this.getIdList1(i)) {
            if (   id.text === typeId.value.typeId  ) {
                typeId.patchValue({typeId: id});
            } else if (this.idForm.value.ids[i].typeId === "") { 
                typeId.patchValue({typeId: {
                                        value: "",
                                        text: ""
                }});
            }
        }
        if (typeId.get("typeId")?.value.text === "") {
            this.resetIdList(i);
        }
    }
    setIdViaOption1(option:any, i:number):void {
        var arrayControl = this.idForm.get('ids') as FormArray;
        var typeId = arrayControl.at(i);
        const optionEl = option._element.nativeElement;
        this.resetIdList(i);
    }
    resetIdList(i: number){
        this.filteredId1[i] = this.autocompleteService.setArray(this.idForm, "typeId", this.getIdList1(i), "ids", i);
    }

    checkCountry(){
        
        if(this.profileForm.controls['countryOfBirth'].value.value == this.defaultCountry.toString() && !this.profileForm.controls['provOrCityOfBirthDesc'].value){
            // this.profileForm.patchValue({provOrCityOfBirthDesc:""});
            if(this.reqProvOfBirth === 'P'){
                this.profileForm.controls["provOrCityOfBirth"].setValidators([this.formService.autocomplete(),Validators.required]);
            }
            this.profileForm.controls["provOrCityOfBirth"].updateValueAndValidity();
            this.profileForm.controls["provOrCityOfBirthDesc"].clearValidators();
            this.profileForm.controls["provOrCityOfBirthDesc"].updateValueAndValidity();
        }
        else if(this.profileForm.controls['countryOfBirth'].value.value != this.defaultCountry.toString() || this.profileForm.controls['provOrCityOfBirthDesc'].value){
            this.profileForm.patchValue({provOrCityOfBirth: {value: null, text: "", provCd: null, countryCd: this.profileForm.controls['countryBirth'].value.value}});
            this.profileForm.controls["provOrCityOfBirth"].clearValidators();
            this.profileForm.controls["provOrCityOfBirthDesc"].setValidators([Validators.required]);
            this.profileForm.controls["provOrCityOfBirth"].updateValueAndValidity();
            this.profileForm.controls["provOrCityOfBirthDesc"].updateValueAndValidity();
        }

        if(this.profileForm.controls['countryBirth'].value.value == this.defaultCountry.toString()){
            this.profileForm.patchValue({provOrCityDesc:""});
            this.profileForm.patchValue({postCodeDesc:""});
            if(this.reqProvince === 'P'){
                this.profileForm.controls["provOrCity"].setValidators([this.formService.autocomplete(),Validators.required]);
            }
            if(this.reqPostal === 'P'){
                this.profileForm.controls["postCode"].setValidators([this.formService.autocomplete(),Validators.required]);
            }
            this.profileForm.controls["provOrCityDesc"].setErrors(null);
            this.profileForm.controls["postCodeDesc"].setErrors(null);
            this.profileForm.controls["provOrCityDesc"].clearValidators();
            this.profileForm.controls["postCodeDesc"].clearValidators();
            this.profileForm.controls["provOrCityDesc"].updateValueAndValidity();
            this.profileForm.controls["postCodeDesc"].updateValueAndValidity();
            this.profileForm.controls["provOrCity"].updateValueAndValidity();
            this.profileForm.controls["postCode"].updateValueAndValidity();
        }
        else if(this.profileForm.controls['countryBirth'].value.value != this.defaultCountry.toString()){
            this.profileForm.patchValue({provOrCity: {value: null, text: "", provCd: null, countryCd: this.profileForm.controls['countryBirth'].value.value}});
            this.profileForm.patchValue({postCode: {value: null, text: "", provCd: null, countryCd: this.profileForm.controls['countryBirth'].value.value, cityCd: null}});
            this.profileForm.controls["provOrCity"].clearValidators();
            this.profileForm.controls["postCode"].clearValidators();
            this.profileForm.controls["provOrCityDesc"].setValidators([Validators.required]);
            this.profileForm.controls["postCodeDesc"].setValidators([Validators.required]);
            this.profileForm.controls["provOrCity"].updateValueAndValidity();
            this.profileForm.controls["postCode"].updateValueAndValidity();
            this.profileForm.controls["provOrCityDesc"].updateValueAndValidity();
            this.profileForm.controls["postCodeDesc"].updateValueAndValidity();
        }
        if(this.profileForm.controls['presCountry'].value.value == this.defaultCountry.toString()){
            this.profileForm.patchValue({presProvDesc:""});
            this.profileForm.patchValue({presPostCodeDesc:""});
            if(this.reqPresProv === 'P'){
                this.profileForm.controls["presProv"].setValidators([this.formService.autocomplete(),Validators.required]);
            }
            if(this.reqPresPostCode === 'P'){
                this.profileForm.controls["presPostCode"].setValidators([this.formService.autocomplete(),Validators.required]);
            }
            this.profileForm.controls["presProvDesc"].setErrors(null);
            this.profileForm.controls["presPostCodeDesc"].setErrors(null);
            this.profileForm.controls["presProvDesc"].clearValidators();
            this.profileForm.controls["presPostCodeDesc"].clearValidators();
            this.profileForm.controls["presProvDesc"].updateValueAndValidity();
            this.profileForm.controls["presPostCodeDesc"].updateValueAndValidity();
            this.profileForm.controls["presProv"].updateValueAndValidity();
            this.profileForm.controls["presPostCode"].updateValueAndValidity();
        }
        else if(this.profileForm.controls['presCountry'].value.value != this.defaultCountry.toString()){
            this.profileForm.patchValue({presProv: {value: null, text: "", provCd: null, countryCd: this.profileForm.controls['presCountry'].value.value}});
            this.profileForm.patchValue({presPostCode: {value: null, text: "", provCd: null, countryCd: this.profileForm.controls['presCountry'].value.value, cityCd: null}});
            this.profileForm.controls["presProv"].clearValidators();
            this.profileForm.controls["presProv"].updateValueAndValidity();
            this.profileForm.controls["presPostCode"].clearValidators();
            this.profileForm.controls["presPostCode"].updateValueAndValidity();
            this.profileForm.controls["presProvDesc"].setValidators([Validators.required]);
            this.profileForm.controls["presProvDesc"].updateValueAndValidity();
            this.profileForm.controls["presPostCodeDesc"].setValidators([Validators.required]);
            this.profileForm.controls["presPostCodeDesc"].updateValueAndValidity();
        }
    }
}
