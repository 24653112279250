<section id="sub-otp">
    <form [formGroup]="otpForm" ng-submit="verifyOtp()">
        <div id="sub-otp-form">
            <div class="form-fields">
                <ng-container *ngIf="!successOtp">
                    <ng-container *ngIf="!withOtpMethod">
                        <h2>Verify your identity</h2>
                        <p>Please choose where you want to get your One-Time Pin (OTP).</p>
                        <div class="otp-options">
                            <div class="option" *ngIf="parentChildCommon.loginResponse.userAuth.mobileNo" (click)="sendOtp('mobile', 'N')">
                                <p>Text</p><span>{{parentChildCommon.loginResponse.userAuth.mobileNo}}</span>
                            </div>
                            <div class="option" *ngIf="parentChildCommon.loginResponse.userAuth.email" (click)="sendOtp('email', 'N')">
                                <p>Email</p><span>{{parentChildCommon.loginResponse.userAuth.email}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="withOtpMethod">
                        <h2>Enter OTP</h2>
                        <p>Your One-Time PIN has been sent to {{otpReceiver}}. Please enter the PIN to sign in.</p>
                        <div class="form">
                            <div class="col-case">
                                <div class="field">
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="otp" type="tel" maxlength="6">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <p class="trouble" *ngIf="parentChildCommon.loginResponse.userAuth.mobileNo && parentChildCommon.loginResponse.userAuth.email">
                            Having trouble? <a (click)="signInAnotherWay()">Send OTP in another way.</a>
                        </p>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="successOtp">
                    <h2 class="trustBrowserQ">Do you trust this device and this browser?</h2>
                    <p class="trustBrowserM">OTP will not be needed next time you login to this device and this browser. This will take effect for {{noOfDays}}.</p>
                </ng-container>
            </div>
            <div class="otpFormMsg">{{otpErrorMsg}}</div>
        </div>
        <ng-container *ngIf="!successOtp">
            <div class="form-btns" *ngIf="!withOtpMethod">
                <button class="btn btn2" (click)="cancelOTP()">Cancel</button>
            </div>
            <div class="form-btns verify-btns" *ngIf="withOtpMethod">
                <button class="btn btn2" (click)="sendOtp(otpMethod, 'Y')" [disabled]="sendOtpInterval > 0">{{sendOtpInterval > 0 ? "(" + sendOtpInterval + ")" : ""}}<br *ngIf="sendOtpInterval > 0">Resend OTP</button>
                <button class="btn btn2" (click)="cancelOTP()" tabindex="-1">Cancel</button>
                <button class="btn btn2" (click)="verifyOTP()">Verify</button>
            </div>
        </ng-container>
        <ng-container *ngIf="successOtp">
            <div class="form-btns">
                <button class="btn btn2" (click)="saveOtpToken()">Yes</button>
                <button class="btn btn2" (click)="proceedLogin()">No</button>
            </div>
        </ng-container>
    </form>
</section>