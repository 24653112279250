<section id="acceptance" class="product-details">
    <div class="container">
        <form>
            <div id="acceptance-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div class="acceptance-form-msg top"></div>
                    <div class="quote-policy-details montserrat left-aligned">
                        <div class="detail">
                            <div class="label">Policy No.</div>
                            <div class="value">{{productInfo.policyNo || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Contract Type</div>
                            <div class="value">{{productInfo.contractTypeDesc || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Insurance Plan</div>
                            <div class="value">{{productInfo.planInfo.planName || '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Start Date</div>
                            <div class="value">{{productInfo.coverageInfo.effDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Policy End Date</div>
                            <div class="value">{{productInfo.coverageInfo.expDate | date:'MM/dd/yyyy hh:mm a' | pmToNoon}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Status</div>
                            <div class="value">{{productInfo.quoteStatDesc}}</div>
                        </div>
                    </div>
                    <div class="form-note" *ngIf="!productInfo.policyNo">
                        <p>Do you want to update your quotation? <a (click)="backToQuotationProcessing()">Click here to go back to quotation</a></p>
                    </div>
                    <div class="quote-policy-summary">
                        <div class="summary left-aligned">
                            <h2>Policy Holder Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="label">Name</div>
                                        <div class="value">{{productInfo.policyHolderDetails.policy.displayName || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Address</div>
                                        <div class="value">{{productInfo.policyHolderDetails.policy.address || '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'MC'">
                            <h2>Vehicle Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Year Model</div>
                                        <div class="value">{{productInfo.vehicleInfo.yearModelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Brand & Model</div>
                                        <div class="value">{{productInfo.vehicleInfo.makeDetail.text + ' ' + productInfo.vehicleInfo.modelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Variant</div>
                                        <div class="value">{{productInfo.vehicleInfo.subModelDetail.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Color</div>
                                        <div class="value">{{productInfo.vehicleInfo.color || '-'}}</div>
                                    </div>
                                </div>
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Plate No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.plateNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Engine No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.engineNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Chassis No.</div>
                                        <div class="value word-break">{{productInfo.vehicleInfo.chassisNo || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Seating Capacity</div>
                                        <div class="value">{{productInfo.vehicleInfo.seatsCovered || '-'}}</div>
                                    </div>
                                </div>
                                <div class="see-more" *ngIf="productInfo.sublineCd === 'CTPL' || productInfo.vehicleInfo.mortgageType === 'Y'">
                                    <ng-container *ngIf="seeMoreVehicleDetails">
                                        <div class="box-holder custom-style-2" *ngIf="productInfo.sublineCd === 'CTPL'">
                                            <div class="detail">
                                                <div class="label">MV File No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.mvFileNo || '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">MV Type</div>
                                                <div class="value">{{productInfo.vehicleInfo.mvTypeDetail?.text || '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">MV Prem Type</div>
                                                <div class="value">{{productInfo.vehicleInfo.mvPremTypeDetail?.text || '-'}}</div>
                                            </div>
                                        </div>
                                        <div class="box-holder custom-style-4" *ngIf="productInfo.sublineCd === 'CTPL'">
                                            <div class="detail">
                                                <div class="label">COC No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.cocNo || '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">COC Authentication No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.cocAuthNo || '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">COC Authentication Date</div>
                                                <div class="value">{{productInfo.vehicleInfo.cocAuthDate ? (productInfo.vehicleInfo.cocAuthDate | date : 'MM/dd/YYYY') : '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">IC Authentication No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.icAuthNo || '-'}}</div>
                                            </div>
                                        </div>
                                        <div class="box-holder custom-style-1" *ngIf="productInfo.vehicleInfo.mortgageType === 'Y'">
                                            <div class="detail">
                                                <div class="label">Mortgage Details</div>
                                                <div class="value">{{productInfo.vehicleInfo.bankDetail?.text || '-'}}</div>
                                            </div>
                                            <div class="detail">
                                                <div class="label">Loan Account No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.loanAccountNo || '-'}}</div>
                                            </div>
                                            <div class="detail" *ngIf="productInfo.vehicleInfo.bankDetail?.alfesTag === 'Y'">
                                                <div class="label">ALFES No.</div>
                                                <div class="value word-break">{{productInfo.vehicleInfo.alfesNo || '-'}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="button">
                                        <button class="btn btn2" *ngIf="!seeMoreVehicleDetails" (click)="seeMoreVehicleDetails = true">See More</button>
                                        <button class="btn btn2" *ngIf="seeMoreVehicleDetails" (click)="seeMoreVehicleDetails = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.menuLineCd === 'PA'">
                            <h2>Principal Information</h2>
                            <div class="box-1">
                                <div class="box-holder style-4">
                                    <div class="detail">
                                        <div class="label">Name</div>
                                        <div class="value">{{productInfo.personalInfo.principalFullName}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Birthdate</div>
                                        <div class="value">{{productInfo.personalInfo.principalBirthDateFormat}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Occupation</div>
                                        <div class="value">{{productInfo.personalInfo.principalOccupation.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Marital Status</div>
                                        <div class="value">{{productInfo.personalInfo.principalMaritalStatus.text}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary dependents" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'FPA' && showDepDetails">
                            <h2>Dependents</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p (click)="sortDependents('name', 'name')" [class.sort]="lastSortedColumn === 'name'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Name
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('relationship', 'relationship')" [class.sort]="lastSortedColumn === 'relationship'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Relationship
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('occupation', 'occupation')" [class.sort]="lastSortedColumn === 'occupation'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Occupation
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('maritalStatus', 'maritalStatus')" [class.sort]="lastSortedColumn === 'maritalStatus'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Marital Status
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('birthDate', 'birthDate')" [class.sort]="lastSortedColumn === 'birthDate'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Birthdate
                                            </p>
                                            <p class="centerAlign" (click)="sortDependents('age', 'age')" [class.sort]="lastSortedColumn === 'age'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Age
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let dependent of dependents">
                                            <div class="sp-1">
                                                <p>{{dependent.name}}</p>
                                                <div>
                                                    <p>Name</p>
                                                    <p>{{dependent.name}}</p>
                                                </div>
                                                <div>
                                                    <p>Relationship</p>
                                                    <p>{{dependent.relationship}}</p>
                                                </div>
                                                <div>
                                                    <p>Occupation</p>
                                                    <p>
                                                        {{dependent.occupation || '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Marital Status</p>
                                                    <p>
                                                        {{dependent.maritalStatus}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Birthdate</p>
                                                    <p>
                                                        {{dependent.birthDate}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Age</p>
                                                    <p>
                                                        {{dependent.age}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="centerAlign">{{dependent.relationship}}</p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.occupation || '-'}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.maritalStatus}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="centerAlign">
                                                    {{dependent.birthDate}}
                                                </p>
                                            </div>
                                            <div class="sp-6">
                                                <p class="centerAlign">{{dependent.age}}</p>
                                                <div>
                                                    <p>Name</p>
                                                    <p>{{dependent.name}}</p>
                                                </div>
                                                <div>
                                                    <p>Relationship</p>
                                                    <p>{{dependent.relationship}}</p>
                                                </div>
                                                <div>
                                                    <p>Occupation</p>
                                                    <p>
                                                        {{dependent.occupation || '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Marital Status</p>
                                                    <p>
                                                        {{dependent.maritalStatus}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Birthdate</p>
                                                    <p>
                                                        {{dependent.birthDate}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Age</p>
                                                    <p>
                                                        {{dependent.age}}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- Start of changes for FIRE -->
                        <div class="summary vehicle-information left-aligned" *ngIf="productInfo.lineCd === 'HA'">
                            <h2>Property Details</h2>
                            <div class="box-1">
                                <!-- ROW 1 -->
                                <div class="box-holder style-1">
                                    <div class="detail">
                                        <div class="label">Address</div>
                                        <div class="value">{{productInfo.propertyInfo.compAdd}}</div>
                                    </div>
                                </div>

                                <!-- ROW 2 -->
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Country</div>
                                        <div class="value">{{productInfo.propertyInfo.country.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Province or City</div>
                                        <div class="value">{{productInfo.propertyInfo.provOrCity.text}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Postal Code</div>
                                        <div class="value">{{productInfo.propertyInfo.postCode.text ? productInfo.propertyInfo.postCode.text.slice(0,4) : ''}}</div>
                                    </div>
                                </div>

                                <!-- ROW 3 -->
                                <div class="box-holder style-3">
                                    <div class="detail">
                                        <div class="label">Latitude</div>
                                        <div class="value">{{productInfo.propertyInfo.latitude}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Longitude</div>
                                        <div class="value">{{productInfo.propertyInfo.longitude}}</div>
                                    </div>
                                </div>

                                <!-- ROW 4 -->
                                <div class="box-holder style-1 insurance-amount">
                                    <div class="detail">
                                        <div class="label">Amount of Insurance Applied for</div>
                                    </div>
                                </div>
                                <div class="box-holder style-2 contents" *ngFor="let row of longerRow;index as idx" [class.insurance-amount]="idx+1 != longerRow.length">
                                    <div class="detail">
                                        <div class="value"  *ngIf="insuredCol1[idx]">
                                            <mat-checkbox 
                                                class="checkbox"
                                                [checked]="insuredCol1[idx].amt"
                                                [disableRipple]="true"
                                                (click)="$event.preventDefault()">
                                                {{insuredCol1[idx].label}}&nbsp;
                                            </mat-checkbox>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{insuredCol1[idx].curr}}
                                                </span>
                                                <span class="amount">
                                                    {{insuredCol1[idx].amt | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="detail">
                                        <div class="value" *ngIf="insuredCol2[idx]">
                                            <mat-checkbox 
                                                class="checkbox"
                                                [checked]="insuredCol2[idx].amt"
                                                [disableRipple]="true"
                                                (click)="$event.preventDefault()">
                                                {{insuredCol2[idx].label}}&nbsp;
                                            </mat-checkbox>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{insuredCol2[idx].curr}}
                                                </span>
                                                <span class="amount">
                                                    {{insuredCol2[idx].amt | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="value">
                                            <span>Total Sum Insured&nbsp;&nbsp;&nbsp;</span>
                                            <div class="currency-amount-container">
                                                <span class="currency">
                                                    {{ displayedCurrency }}
                                                </span>
                                                <span class="amount">
                                                    {{total | number:'1.2-2':'en-US'}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End of changes for FIRE -->
                        
                        <div class="summary coverage" *ngIf="summarizedPremTag == 'N'">
                            <h2>Coverage</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p (click)="setCoverages('coverage', 'perilName')" [class.sort]="lastSortedColumn === 'coverage'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                                Coverage
                                            </p>
                                            <p (click)="setCoverages('amt-covered', 'tsiAmt')" [class.sort]="lastSortedColumn === 'amt-covered'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                                Amount Covered
                                            </p>
                                            <p (click)="setCoverages('premium', 'premAmt')" [class.sort]="lastSortedColumn === 'premium'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                                Premium
                                            </p>
                                        </li>
                                        <li *ngFor="let coverage of coverages; last as l" [class.body]="!l" [class.footer]="l">
                                            <div>
                                                <p>{{coverage.perilName}}</p>
                                                <ng-container *ngIf="!l">
                                                    <div>
                                                        <p>Coverage</p>
                                                        <p>{{coverage.perilName}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Amount Covered</p>
                                                        <p>{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                    </div>
                                                    <div>
                                                        <p>Premium</p>
                                                        <p>
                                                            <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                                FREE
                                                            </ng-container>
                                                            <ng-template #paidPrem>
                                                                {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                            </ng-template>
                                                        </p>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <ng-container *ngIf="!l">
                                                    <p class="ralign">{{coverage.tsiAmt != null ? (coverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </ng-container>

                                                <!-- TOTAL PREMIUM BELOW AMOUNT COVERED -->
                                                <ng-container *ngIf="l">
                                                    <p class="ralign prem-total-1">
                                                        <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                            FREE
                                                        </ng-container>
                                                        <ng-template #paidPrem>
                                                            {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                        </ng-template>
                                                    </p>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    <ng-container *ngIf="coverage.premAmt === 0 else paidPrem">
                                                        FREE
                                                    </ng-container>
                                                    <ng-template #paidPrem>
                                                        {{coverage.premAmt !== null ? (coverage.premAmt | currency: 'PHP' : '₱ ') : '-'}}
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovFPA" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'FPA' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p></p>
                                            <p class="ralign">
                                                Principal
                                            </p>
                                            <p class="ralign">
                                                Spouse/Parents
                                            </p>
                                            <p class="ralign">
                                                Children
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesFPA">
                                            <div class="sp-1">
                                                <p>{{benefitCoverage.perilName}}</p>
                                                <div>
                                                    <p>{{benefitCoverage.perilName}}</p>
                                                    <p></p>
                                                </div>
                                                <div>
                                                    <p>Principal</p>
                                                    <p>{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                                <div>
                                                    <p>Spouse/Parents</p>
                                                    <p>
                                                        {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>Children</p>
                                                    <p>
                                                        {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="ralign">{{benefitCoverage.tsiPrincipal != null ? (benefitCoverage.tsiPrincipal | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    {{benefitCoverage.tsiSpouse != null ? (benefitCoverage.tsiSpouse | currency: 'PHP': '₱ ') : '-'}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="ralign">
                                                    {{benefitCoverage.tsiChildren != null ? (benefitCoverage.tsiChildren | currency: 'PHP': '₱ ') : '-'}}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovIND" *ngIf="productInfo.lineCd === 'PA' && productInfo.sublineCd === 'IND' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p>
                                                Principal
                                            </p>
                                        </li>
                                        <li class="body" *ngFor="let benefitCoverage of benefitsCoveragesIND">
                                            <div class="sp-1">
                                                <p>{{benefitCoverage.perilName}}</p>
                                                <div>
                                                    <p>{{benefitCoverage.perilName}}</p>
                                                    <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="ralign">{{benefitCoverage.tsiAmt != null ? (benefitCoverage.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary benCovCI" *ngIf="productInfo.lineCd === 'CI' && showBenCovDetails">
                            <h2>Benefits and Coverages</h2>
                            <div class="box-1">
                                <div class="custom-table">
                                    <ul>
                                        <li class="header">
                                            <p>
                                                Principal
                                            </p>
                                        </li>
                                        <li class="body">
                                            <div class="sp-1">
                                                <p>Daily Cash Assistance</p>
                                                <div>
                                                    <p>Daily Cash Assistance</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                                <div>
                                                    <p>Maximum No. of Confinement Days</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.noOfDays}}</p>
                                                </div>
                                                <div>
                                                    <p>Maximum Benefit</p>
                                                    <p class="ralign">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p>Maximum No. of Confinement Days</p>
                                            </div>
                                            <div>
                                                <p>Maximum Benefit</p>
                                            </div>
                                        </li>
                                        <li class="body2">
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.tsiAmt ? (benefitsCoveragesCI.tsiAmt | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.noOfDays}}</p>
                                            </div>
                                            <div>
                                                <p class="value">{{benefitsCoveragesCI.maxProfit ? (benefitsCoveragesCI.maxProfit | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="summary other-info" *ngIf="productInfo.lineCd === 'MC'">
                            <h2>Other Information</h2>
                            <div class="box-1">
                                <div class="box-holder" [class.style-2]="productInfo.sublineCd === 'CTPL'" [class.style-3]="productInfo.sublineCd !== 'CTPL'">
                                    <div class="detail">
                                        <div class="label">Deductible Amount<div class="help-icon"><mat-icon matTooltip="{{dedAmtComment}}" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.deductibleAmt ? (premiumCharges.deductibleAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                    <div class="detail" *ngIf="productInfo.sublineCd !== 'CTPL'">
                                        <div class="label">Towing Limit<div class="help-icon"><mat-icon matTooltip="{{towingAmtComment}}" #tooltip2="matTooltip" (click)="tooltip2.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.towingAmt ? (premiumCharges.towingAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Authorized Repair Limit<div class="help-icon"><mat-icon matTooltip="{{repairAmtComment}}" #tooltip3="matTooltip" (click)="tooltip3.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div>
                                        <div class="value">{{premiumCharges.repairLimitAmt ? (premiumCharges.repairLimitAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary other-info" *ngIf="productInfo.lineCd === 'HA'">
                            <h2>Deductibles</h2>
                            <div class="box-1">
                                <!-- ROW 1 -->
                                <div class="box-holder style-1">
                                    <div class="detail">
                                        <!-- <div class="label"></div> -->
                                        <div class="value deductible-text">
                                            <span *ngFor="let deductible of productInfo.deductibles">{{deductible.deductibleText}}
                                                <br>
                                                <br>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary premium-charges">
                            <h2>Premium Charges</h2>
                            <div class="box-2">
                                <div class="box-holder">
                                    <div class="detail">
                                        <div class="label">Premium</div>
                                        <div class="value">{{premiumCharges.premiumAmt | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                    <div class="details">
                                        <div class="detail" *ngFor="let tax of premiumCharges.taxes">
                                            <div class="label">{{tax.name}}</div>
                                            <div class="value">{{tax.value | currency: 'PHP': '₱ '}}</div>
                                        </div>
                                    </div>
                                    <div class="detail total-amt">
                                        <div class="label">Amount Due</div>
                                        <div class="value">{{premiumCharges.amountDue | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-1" *ngIf="(userDetails.userId && userDetails.accountType !== 'P') && showComm">
                                <div class="box-holder style-2">
                                    <div class="detail">
                                        <div class="label">Commission Amount</div>
                                        <div class="value">{{premiumCharges.commissionAmt | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                    <div class="detail total-amt">
                                        <div class="label">Net Due</div>
                                        <div class="value">{{premiumCharges.netDue | currency: 'PHP': '₱ '}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="policy-header" *ngIf="userDetails.userId && (userDetails.accountType !== 'P' && userDetails.accountType !== 'B')">
                        <div class="form-title">
                            <h2>Policy Header Details</h2>
                        </div>
                        <div class="quote-policy-details montserrat left-aligned policy-header-details">
                            <div class="detail">
                                <div class="label">Agent</div>
                                <div class="value">{{productInfo.policyHeaderDetails.agentDetail.text || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">BMA</div>
                                <div class="value">{{productInfo.policyHeaderDetails.bmaDetail.text || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Client Type Group</div>
                                <div class="value">{{productInfo.policyHeaderDetails.clientTypeGroupDetail.text || '-'}}</div>
                            </div>
                            <ng-container  *ngIf="userDetails.accountType !== 'A' || (userDetails.accountType === 'A' && agentType == 'D')">
                                <div class="detail">
                                    <div class="label">Source Code</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.sourceCodeDetail.text || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Source Extension</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.sourceExtensionDetail.text || '-'}}</div>
                                </div>
                                <div class="detail" *ngIf="!(userDetails.accountType === 'A' && agentType == 'D')">
                                    <div class="label">Referrer</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.referrorCodeDetail.text || '-'}}</div>
                                </div>
                                <div class="detail" *ngIf="!(userDetails.accountType === 'A' && agentType == 'D')">
                                    <div class="label">Project Code</div>
                                    <div class="value">{{productInfo.policyHeaderDetails.projectCodeDetail.text || '-'}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <form [formGroup]="policyHeaderDetailsForm">
                        <!--  REMOVE COMMENT TAGS IF POLICY HEADER DETAILS ARE EDITABLE 

                        <div class="form-title" *ngIf="userDetails.userId && (userDetails.accountType !== 'P' && userDetails.accountType !== 'B')">
                            <h2>Policy Header Details</h2>
                            <div class="form-title-button" *ngIf="showEditFlag && userDetails.accountType!== 'A'">
                                <button (click)="toggleEdit()" *ngIf="!editFlag">Edit Details ></button>
                                <button (click)="savePolicyHeaderDetails()" *ngIf="editFlag">Save Changes ></button>
                            </div>
                        </div>
                        <div id="policy-header-details-form">
                            <div class="form-loader"></div>
                            <div class="form-fields" *ngIf="userDetails.userId && (userDetails.accountType !== 'P' && userDetails.accountType !== 'B')">
                                <div class="form">
                                    <div class="policy-header-details-form-msg top"></div>
                                    <div class="with-form-msg">
                                        <div class="col-case col-3">
                                            <div class="field" [ngClass]="{'required': userDetails.accountType === 'A' || userDetails.accountType === 'E' }">
                                                <label>Agent</label>
                                                <mat-form-field class="select" appearance="fill">
                                                    <input matInput placeholder="Agent" formControlName="agentDetail" [matAutocomplete]="autoAgentDetail" (input)="setAgentViaInput()" (blur)="checkAgent()" autocomplete>
                                                    <mat-autocomplete #autoAgentDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                        <mat-option *ngFor="let option of filteredAgentDetailOptions | async" [value]="option">
                                                            {{option.text}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                                <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'agentDetail')">
                                                    {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "agentDetail")}}
                                                </p>
                                            </div>
                                            <ng-container *ngIf="userDetails.accountType !== 'A'">
                                                <div class="field">
                                                    <label>Source Code</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="Source Code" formControlName="sourceCodeDetail" [matAutocomplete]="autoSourceCodeDetail" (input)="setSourceCodeViaInput()" autocomplete>
                                                        <mat-autocomplete #autoSourceCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredSourceCodeDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'sourceCodeDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "sourceCodeDetail")}}
                                                    </p>
                                                </div>
                                                <div class="field">
                                                    <label>Source Extension</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="Source Extension" formControlName="sourceExtensionDetail" [matAutocomplete]="autoSourceExtensionDetail" (input)="setSourceExtensionViaInput()" autocomplete>
                                                        <mat-autocomplete #autoSourceExtensionDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredSourceExtensionDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'sourceExtensionDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "sourceExtensionDetail")}}
                                                    </p>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="userDetails.accountType === 'A'">
                                                <div class="field">
                                                    <label>BMA</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="BMA" formControlName="bmaDetail" [matAutocomplete]="autoBmaDetail" (input)="setBmaViaInput()" autocomplete>
                                                        <mat-autocomplete #autoBmaDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredBmaDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'bmaDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "bmaDetail")}}
                                                    </p>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-container *ngIf="userDetails.accountType !== 'A'">
                                            <div class="col-case col-3">
                                                <div class="field" [ngClass]="{'required': userDetails.accountType === 'E' }">
                                                    <label>BMA</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="BMA" formControlName="bmaDetail" [matAutocomplete]="autoBmaDetail" (input)="setBmaViaInput()" autocomplete>
                                                        <mat-autocomplete #autoBmaDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredBmaDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'bmaDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "bmaDetail")}}
                                                    </p>
                                                </div>
                                                <div class="field">
                                                    <label>Referror Code</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="Referror Code" formControlName="referrorCodeDetail" [matAutocomplete]="autoReferrorCodeDetail" (input)="setReferrorCodeViaInput()" (blur)="checkAgent()" autocomplete>
                                                        <mat-autocomplete #autoReferrorCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredReferrorCodeDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'referrorCodeDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "referrorCodeDetail")}}
                                                    </p>
                                                </div>
                                                <div class="field" [ngClass]="{'required': userDetails.accountType === 'E'}">
                                                    <label>Project Code</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="Project Code" formControlName="projectCodeDetail" [matAutocomplete]="autoProjectCodeDetail" (input)="setProjectCodeViaInput()" autocomplete>
                                                        <mat-autocomplete #autoProjectCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                            <mat-option *ngFor="let option of filteredProjectCodeDetailOptions | async" [value]="option">
                                                                {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'projectCodeDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "projectCodeDetail")}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-case col-3">
                                                <div class="field" [ngClass]="{'required': userDetails.accountType === 'E'}">
                                                    <label>Client Type Group</label>
                                                    <mat-form-field class="select" appearance="fill">
                                                        <input matInput placeholder="Client Type Group" formControlName="clientTypeGroupDetail" [matAutocomplete]="autoClientTypeGroupDetail" (input)="setClientTypeGroupViaInput()" autocomplete>
                                                        <mat-autocomplete #autoClientTypeGroupDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldValue">
                                                            <mat-option *ngFor="let option of filteredClientTypeGroupDetailOptions | async" [value]="option">
                                                                {{(option.value ? option.value + ' - ' : '') + option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'clientTypeGroupDetail')">
                                                        {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "clientTypeGroupDetail")}}
                                                    </p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="policy-header-details-form-msg bottom"></div>
                                </div>
                            </div>
                        </div>
                        -->
                        <div class="form terms" [formGroup]="termsForm">
                            <ng-container *ngIf="userDetails.accountType=='P' else showAgent">
                                <div class="col-case">
                                    <mat-checkbox formControlName='term1'></mat-checkbox>
                                    <div class='terms-text'>I have read, understood and agree to the  <a (click)="evaluateGetPdf()">Product's Insuring Terms & Conditions</a> and all its clauses. I certify that all the information and documents I provided in relation to this application are true, complete and accurate to the best of my knowledge and I understand that any falsification or misrepresentation of information may warrant BPI MS to cancel the policy or deny a claim related to this policy.</div>
                                </div>
                            </ng-container>
                            <ng-template #showAgent>
                                <div class="col-case">
                                    <mat-checkbox formControlName='term1'></mat-checkbox>
                                    <div class='terms-text' innerHTML="{{termsText}}"></div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="acceptance-form-msg bottom"></div>
                        <div class="form-btns">
                            <button class="btn btn4" (click)="move('back')" *ngIf="!productInfo.policyNo">Back</button>
                            <button class="btn btn2" (click)="accept()" *ngIf="!productInfo.policyNo" [disabled]="!enableAccept">Accept</button>
                            <button class="btn btn2" (click)="move('next')" *ngIf="productInfo.policyNo && productInfo.paytTag != 'Y'">Next: Payment</button>
                            <button class="btn btn2" (click)="onClickPrint('policy')" *ngIf="productInfo.policyNo && productInfo.paytTag == 'Y'">Print Policy</button>
                        </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<overlay #confirmPol [title]="'Confirmation'">
    <div content>
        Thank you for reviewing your quotation. Do you want to generate your E-Policy?
    </div>
    <button mat-button buttons class="btn-primary btn" (click)="generatePolicy();confirmPol.close()">Yes</button>
</overlay>

<overlay #changeEffDate [title]="'Confirmation'">
    <div content id="acceptance-change-eff-date">
        <p>
            Effective Date must be within {{effDateRangeDays}} days starting from {{sameDayTag ?'today' : 'the next day'}}.<br><br>Please enter a new Effective Date:<br><br>
        </p>
        <div [formGroup]="periodForm" class="periodContainer">
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker" formControlName="effDate" [min]="minEffDate" [max]="maxEffDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <p class="separator">-</p>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="expDate">
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <button mat-button buttons class="btn btn2" (click)="generatePolicy(true)" [disabled]="periodForm.invalid">Accept</button>
</overlay>