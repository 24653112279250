import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';

import { OverlayComponent } from 'src/app/components/common/overlay/overlay';

import { APICallService } from 'src/app/services/api-call.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { UtilityService } from 'src/app/services/utility.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { SecurityService } from 'src/app/services/security.service';
import { formatDate, getCurrencySymbol } from '@angular/common';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import * as moment from 'moment';
import { PopupService } from 'src/app/services/popup.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
    templateUrl: './acceptance.html'
})
export class AcceptanceComponent {

    @ViewChild("confirmPol") confirmPol!: OverlayComponent;
    @ViewChild("changeEffDate") changeEffDate!: OverlayComponent;

    policyHeaderDetailsForm: FormGroup = this.formBuilder.group({
        agentDetail: ["", [this.formService.autocomplete()]],
        sourceCodeDetail: ["", [this.formService.autocomplete()]],
        sourceExtensionDetail: ["", [this.formService.autocomplete()]],
        bmaDetail: ["", [this.formService.autocomplete()]],
        referrorCodeDetail: ["", [this.formService.autocomplete()]],
        projectCodeDetail: ["", [this.formService.autocomplete()]],
        clientTypeGroupDetail: ["", [this.formService.autocomplete()]]
    });

    periodForm: FormGroup = this.formBuilder.group({
        effDate: [new Date()],
        expDate: [{ value: '', disabled: true }]
    });


    termsForm: FormGroup = this.formBuilder.group({
        term1: [false],
        term2: [false],
        term3: [false],
        term4: [false],
        term5: [false]
    });

    // AUTO-COMPLETE OPTIONS
    filteredAgentDetailOptions: Observable<any>;
    filteredSourceCodeDetailOptions: Observable<any>;
    filteredSourceExtensionDetailOptions: Observable<any>;
    filteredBmaDetailOptions: Observable<any>;
    filteredReferrorCodeDetailOptions: Observable<any>;
    filteredProjectCodeDetailOptions: Observable<any>;
    filteredClientTypeGroupDetailOptions: Observable<any>;

    // COVERAGES TABLE
    lastSortedColumn: string = "";
    sorting: string = "ASC";

    productInfo: any = {};
    coverages: any[] = [];
    benefitsCoveragesCI:any = {};
    benefitsCoveragesFPA: any[] = [];
    benefitsCoveragesIND: any[] = [];
    showBenCovDetails:boolean = false;
    showComm: boolean = false;
    premiumCharges: any = {};
    editFlag: boolean = false;
    showEditFlag: boolean = true;
    enableAccept: boolean = false;

    securityPolicy: string = "";
    privacyPolicy: string = "";
    onlineTerms: string = "";
    ePolicy: string = "";
    mktgAgreement: string = "";
    insuringTerms: string = "";
    insuringTermsGrp: string = "";
    pdfUrl: string = "";
    termsText:string = "";
    termsLink:String = "";
    bpiAgentCd:String = "";
    total:number = 0;

    minEffDate: Date;
    maxEffDate: Date;
    sameDayTag: Boolean = false;

    sublineTime:any = '';
    effDateRangeDays = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.effDateRange ?? 30;

    helpParams:any = {
        tableName: "",
        dedAmt: "",
        towingAmt: "",
        repairAmt: ""
    }
    dedAmtComment:String = "";
    towingAmtComment:String = "";
    repairAmtComment:String = "";

    seeMoreVehicleDetails:boolean = false;

    //PA Parameters
    summarizedPremTag: String = "";
    dependents: any;
    showDepDetails:boolean = false;
    displayedColumns: string[] = ["name", "relationship", "occupation", "maritalStatus", "birthDate", "age"];
    rows: FormArray = this.formBuilder.array([]);
    tableForm = this.formBuilder.group({ 'dependents': this.rows });
    paValidations:any;
    agentType: String = '';

    displayedCurrency: string = "";

    @ViewChild(MatSort)
    sort!: MatSort;

    constructor (
        // PUBLIC
        public formService: FormService,
        public userDetails: UserDetailsService,
        public autocompleteService: AutocompleteService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private stepperDtlService: StepperDtlService,
        private jsonDataService: JsonDataService,
        private parameterService: ParameterService,
        private utilityService: UtilityService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private dialogBoxService: DialogBoxService,
        private popupService: PopupService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.productInfo = this.productService.productInfo;
        this.setCoverages("", "");
        this.commissionDisp();
        this.setPremiumCharges();

        if (this.productInfo.lineCd === 'HA') {
            this.buildAmountInsuredHA();
        }

        //* R2
        this.summarizedPremTag = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == productService.productInfo.lineCd && a.sublineCd == productService.productInfo.sublineCd && a.planCd == productService.productInfo.planInfo.planCd)[0]?.summarizedPremTag;

        // SET AUTO-COMPLETE INITIAL VALUES
        this.filteredAgentDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "agentDetail", this.getAgents());
        this.filteredSourceCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "sourceCodeDetail", this.getSourceCodes());
        this.filteredSourceExtensionDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "sourceExtensionDetail", this.getSourceExtensions());
        this.filteredBmaDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "bmaDetail", this.getBMAs());
        this.filteredReferrorCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "referrorCodeDetail", this.getReferrorCodes());
        this.filteredProjectCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "projectCodeDetail", this.getProjectCodes());
        this.filteredClientTypeGroupDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "clientTypeGroupDetail", this.getClientTypeGroups());

        this.securityPolicy = this.parameterService.paramV("ACCEPT_SECURITY_POL");
        this.privacyPolicy = this.parameterService.paramV("ACCEPT_PRIVACY_POL");
        this.onlineTerms = this.parameterService.paramV("ACCEPT_ONLINE_TERMS");
        this.mktgAgreement = this.parameterService.paramV("ACCEPT_MRKTG_AGREEMENT");
        this.ePolicy = this.parameterService.paramV("ACCEPT_EPOLICY");
        this.bpiAgentCd = this.parameterService.paramV("BPI_AGENT_CD");
        this.insuringTermsGrp = this.parameterService.paramV("GPA_TERMS_PATH");
        this.showEditFlag = !this.productInfo.policyNo;

        this.insuringTerms = this.jsonDataService.data.plans.filter((e: any) =>
            e.lineCd == this.productInfo.lineCd
            && e.sublineCd == this.productInfo.sublineCd
            && e.planCd == this.productInfo.planInfo.planCd
        )[0]?.termsPath;

        this.initializeStepperDetail();
        this.policyHeaderDetailsForm.disable();
        this.policyHeaderDetailsForm.patchValue(this.productInfo.policyHeaderDetails);
        this.utilityService.setFormGroup(this.policyHeaderDetailsForm);

        let {accountType, userLevel} = this.userDetails;
        let {sublineCd, planInfo:{planCd}, lineCd} = this.productService.productInfo;
        let { allowDateRange, sublineTimeTag} = this.jsonDataService.data.effDatePerUser.filter(
            (a:any)=> 
                   a.userType == accountType 
                && a.userLevel == userLevel
                && a.lineCd == lineCd
                && a.sublineCd == sublineCd
                && a.planCd == planCd)[0] || {};
        
        this.sublineTime = this.jsonDataService.data.sublines.filter((a: any) => a.lineCd == this.productInfo.lineCd && a.sublineCd == this.productInfo.sublineCd)[0].sublineTime;

        let sublineTimeArr = this.sublineTime.split(':');
        let cutOff = new Date();
        cutOff.setHours(sublineTimeArr[0], sublineTimeArr[1], sublineTimeArr[2]);
        let isPastCutOff = new Date() > cutOff;
        // IGNORE CUTOFF IF SUBLINE_TIME_TAG = "N" 
        let ignoreCutOff = sublineTimeTag === "N";
        allowDateRange = (typeof allowDateRange == 'number') ? allowDateRange : 1;

        //let sameDayUser: String = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.sameDayUser;
        let isSameDayUser: Boolean = allowDateRange <= 0;
        this.sameDayTag = isSameDayUser && (ignoreCutOff || !isPastCutOff);
        this.minEffDate = new Date();
        this.minEffDate.setHours(0, 0, 0);
        if(allowDateRange == 0 && !this.sameDayTag){
            allowDateRange = 1;
        }
        let defaultDate = new Date();
        defaultDate.setTime(this.minEffDate.getTime() + ( (!this.sameDayTag && allowDateRange > 0) ? 86400000 : 0) );
        this.minEffDate.setTime(this.minEffDate.getTime() + (allowDateRange *  86400000));
        
        this.maxEffDate = new Date();
        this.maxEffDate.setTime(defaultDate.getTime() + (86400000 * this.effDateRangeDays)); // default : 2592000000
        this.periodForm.get('effDate')?.valueChanges.subscribe(e => {
            this.getExpiry();
        });
        this.periodForm.get('effDate')?.setValue(defaultDate);
        if(!this.productInfo.policyNo&&this.userDetails.accountType=='P'){
            this.termsForm.patchValue({
                term1: false,
                term2: true,
                term3: true,
                term4: true,
                term5: true
            });
        }
        if(!this.productInfo.policyNo&&this.userDetails.accountType!='P'){
            this.termsForm.patchValue({
                term1: false,
                term2: true,
                term3: true,
                term4: true,
                term5: true
            });
            if(userDetails.accountType=='A'){
                this.termsText = this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_AGENT_TERMS')[0].paramValueV;
                const termsLinkConnector = "BPI MS Agent Undertaking";
                const termsLink = "<a href='" + this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_AGENT_UNDERTAKING')[0].paramValueV+"' target='_blank'\>"+termsLinkConnector+"</a>";
                this.termsText = this.termsText.replace(termsLinkConnector, termsLink);
            }
            else if(userDetails.accountType=='B'){
                this.termsText = this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_BFB_TERMS')[0].paramValueV;
                const termsLinkConnector = "BPI / BPI Family Bank Employee Undertaking";
                const termsLink = "<a href='" + this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_BFB_UNDERTAKING')[0].paramValueV+"' target='_blank'\>"+termsLinkConnector+"</a>";
                this.termsText = this.termsText.replace(termsLinkConnector, termsLink);
            }
            else if(userDetails.accountType=='E'){
                this.termsText = this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_MS_TERMS')[0].paramValueV;
                const termsLinkConnector = "BPI MS Employee Undertaking";
                const termsLink = "<a href='" + this.jsonDataService.data.params.filter((a:any) => a.paramName === 'ACCEPT_MS_UNDERTAKING')[0].paramValueV+"' target='_blank'\>"+termsLinkConnector+"</a>";
                this.termsText = this.termsText.replace(termsLinkConnector, termsLink);
            }
            
        }
        if (this.productInfo.policyNo) {
            this.termsForm.patchValue({
                term1: true,
                term2: true,
                term3: true,
                term4: true,
                term5: true
            });
            this.termsForm.disable();
        }

        this.termsForm.valueChanges.subscribe(() => {
            this.checkTerm();
        })
        this.initializePolicyHeaderDetail();
        this.setHelpDescription();
        this.assignDependentsAndBenefitsCoverages();
        if(userDetails.accountType=='E'){
            this.checkAgent();
        }

        this.displayedCurrency = getCurrencySymbol(this.productInfo.propertyInfo?.currency, 'narrow');

        this.agentType = this.jsonDataService.data.agent.find((a: any) => a.agentCd === this.userDetails.userId)?.agentType;

        setTimeout((e:any)=>
            popupService.popup.open('RA'), 0);
    }

    initializeStepperDetail(): void {
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = 3;
        this.stepperDtlService.titleStepperDtl = "Acceptance";

        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: "move", actionParam: "back", isShow: !this.productInfo.policyNo ? "true" : "false" },
            { class: "btn2", label: "Accept", action: "accept", disabled: !this.enableAccept, isShow: !this.productInfo.policyNo ? "true" : "false" },
            { class: "btn2", label: "Next: Payment", action: "move", actionParam: "next", isShow: (this.productInfo.policyNo &&  (this.productInfo.paytTag != 'Y')) ? "true" : "false" },
            { class: "btn2", label: "Print Policy", action: "onClickPrint", actionParam: "policy", isShow: (this.productInfo.policyNo && (this.productInfo.paytTag == 'Y')) ? "true" : "false" },
        ];

        if (!!this.productInfo.policyNo) {
            if (this.productService.productInfo.sublineCd == 'COM') {
                this.fbAnalyticsService.trackEvent('Motor_Epolicy_Acceptance')
            } else if (this.productService.productInfo.sublineCd == 'CTPL') {
                this.fbAnalyticsService.trackEvent('Liability_EPolicy_Acceptance’')
            } else if (this.productService.productInfo.sublineCd == 'FPA') {
                this.fbAnalyticsService.trackEvent('Family_EPolicy_Acceptance')
            } else if (this.productService.productInfo.sublineCd == 'IND') {
                this.fbAnalyticsService.trackEvent('Personal_EPolicy_ Acceptance')
            } else if (this.productService.productInfo.sublineCd == 'HCA') {
                this.fbAnalyticsService.trackEvent('Home_ EPolicy_ Acceptance')
            }
        }
    }

    initializePolicyHeaderDetail(){
        if(this.userDetails.accountType=='E'){
            this.policyHeaderDetailsForm.controls["agentDetail"].setValidators([this.formService.autocomplete(),Validators.required]);
            this.policyHeaderDetailsForm.controls["sourceCodeDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["bmaDetail"].setValidators([this.formService.autocomplete(),Validators.required]);
            this.policyHeaderDetailsForm.controls["projectCodeDetail"].setValidators([this.formService.autocomplete(),Validators.required]);
            this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].setValidators([this.formService.autocomplete(),Validators.required]);
            
            if(this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].value==""){
                const userClientCd = this.jsonDataService.data.userType.filter((a:any) => a.userType === this.userDetails.accountType)[0].clientCd;
                for (const client of this.getClientTypeGroups()) {
                    if(userClientCd === client.value){
                        this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].setValue(client);
                    }
                }
            }
        }
        if(this.userDetails.accountType=='A'){
            this.policyHeaderDetailsForm.controls["agentDetail"].setValidators([this.formService.autocomplete(),Validators.required]);
            this.policyHeaderDetailsForm.controls["sourceCodeDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["bmaDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["projectCodeDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].clearValidators();
        }
        if(this.userDetails.accountType=='P' || this.userDetails.accountType=='B'){
            this.policyHeaderDetailsForm.controls["agentDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["sourceCodeDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["bmaDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["projectCodeDetail"].clearValidators();
            this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].clearValidators();
        }

        this.policyHeaderDetailsForm.controls["agentDetail"].updateValueAndValidity();
        this.policyHeaderDetailsForm.controls["sourceCodeDetail"].updateValueAndValidity();
        this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].updateValueAndValidity();
        this.policyHeaderDetailsForm.controls["bmaDetail"].updateValueAndValidity();
        this.policyHeaderDetailsForm.controls["projectCodeDetail"].updateValueAndValidity();
        this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].updateValueAndValidity();
    }

    assignDependentsAndBenefitsCoverages():void {
        if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'FPA' && this.productInfo.dependents && this.productInfo.dependents.length) {
            this.dependents = this.productInfo.dependents;
            this.showDepDetails = true;
        }
        if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'FPA' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            for (let benCovFPA of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesFPA.push({
                    perilCd: benCovFPA.perilCd,
                    perilName: benCovFPA.perilLname,
                    tsiPrincipal: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'PR' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiSpouse: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'SP' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiChildren: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'CH' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'IND' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            for (let benCovIND of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesIND.push({
                    perilCd: benCovIND.perilCd,
                    perilName: benCovIND.perilLname,
                    tsiAmt: this.productInfo.benefitsCoverages.filter((a:any) => a.perilCd === benCovIND.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'CI' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length) {
            this.benefitsCoveragesCI.noOfDays = this.productInfo.benefitsCoverages[0].noOfDays;
            this.benefitsCoveragesCI.tsiAmt = this.productInfo.benefitsCoverages[0].tsiAmt;
            this.benefitsCoveragesCI.maxProfit = this.benefitsCoveragesCI.noOfDays*this.benefitsCoveragesCI.tsiAmt;
            this.showBenCovDetails = true;
        }
    }

    setHelpDescription(){
        this.dedAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'DED_AMT')[0]?.comment;
        this.towingAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'TOWING_AMT')[0]?.comment;
        this.repairAmtComment = this.jsonDataService.data.help.filter((a:any) => a.tableName === 'BMP015' && a.columnName === 'REPAIR_LIMIT_AMT')[0]?.comment;
    }

    setCoverages(sortColumn: string, key: string): void {
        this.coverages = this.productInfo.coverages;

        if (!this.coverages[this.coverages.length - 1].perilCd) {
            this.coverages.pop();
        }

        const perils = this.jsonDataService.data.peril.filter((a: any) => a.lineCd === this.productInfo.lineCd);
        this.coverages = this.productInfo.coverages.map((a: any) => {
            let groupName = "";
            if(this.productInfo.lineCd === "HA") {
                groupName = this.jsonDataService.data.fireItemCd.find((b:any)=>a.itemCd == b.itemCd)?.itemLabel + " - ";
            }
            return {
                ...a,
                perilName: groupName + perils.filter((b: any) => a.perilCd === b.perilCd)[0]?.perilLname.replaceAll(",", ", ").replaceAll("/", "/ ")
            }
        });

        this.sortCoverages(sortColumn, key);
        this.coverages.push({
            perilName: "Total Premium",
            tsiAmt: null,
            premRt: null,
            premAmt: this.productInfo.perilInfo.premAmt
        });
    }

    sortCoverages(sortColumn: string, key: string): void {
        if (sortColumn) {
            if (this.lastSortedColumn === sortColumn) {
                this.sorting = this.sorting === "ASC" ? "DESC" : "ASC";
            } else {
                this.lastSortedColumn = sortColumn;
                this.sorting = "ASC";
            }

            this.coverages.sort((a, b) => {
                if (this.sorting === "ASC") {
                    return this.compareStrings(a[key], b[key]);
                } else {
                    return this.compareStrings(b[key], a[key]);
                }
            });
        }
    }

    sortDependents(sortColumn: string, key: string): void {
        if (sortColumn) {
            if (this.lastSortedColumn === sortColumn) {
                this.sorting = this.sorting === "ASC" ? "DESC" : "ASC";
            } else {
                this.lastSortedColumn = sortColumn;
                this.sorting = "ASC";
            }

            this.dependents.sort((a: any, b: any) => {
                if (this.sorting === "ASC") {
                    return this.compareStrings(a[key], b[key]);
                } else {
                    return this.compareStrings(b[key], a[key]);
                }
            });
        }
    }

    compareStrings(a: any, b: any): number {
        if (typeof a === "string") {
            a = a.toLowerCase();
            b = b.toLowerCase();
        }

        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    private commissionDisp(): void {
        let m072Dtls = this.jsonDataService.data.agentGrps.find((a: any) => a.agentGrp == this.userDetails.agentGrp);
        let m093Dtls = this.jsonDataService.data.userType.filter((a: any) => a.userType == (this.userDetails.accountType || 'P'))[0];
        if (this.userDetails.accountType === 'A') {
            if ((m072Dtls && m072Dtls?.hideCommTag == "N") ||
                (!m072Dtls?.hideCommTag && m093Dtls?.hideCommTag == 'N')) {
                this.showComm = true;
            }
        }
        else {
            this.showComm = true;
        }
    }

    setPremiumCharges(): void {
        if (this.productService.productInfo.lineCd === 'MC') {
            const yearArr = this.productInfo.ara.map((b: any) => b.year);

            this.premiumCharges.premiumAmt = this.productInfo.ara.map((b: any) => b.premAmt).reduce((b: any, c: any) => b + c);
            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt;

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a: any) =>
                this.productInfo.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let total = 0;
                for (let key of yearArr) {
                    total += this.productInfo.taxes.filter((a: any) => a.year === key && a.taxCd === tax.taxCd)[0].taxAmt;
                }

                this.premiumCharges.amountDue += total;
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: total
                    });
                } else {
                    otherCharges += total;
                }
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            // TAXES - [END]

            this.premiumCharges.deductibleAmt = this.productInfo.ara[0]?.dedAmt;
            this.premiumCharges.towingAmt = this.productInfo.ara[0]?.towingAmt;
            this.premiumCharges.repairLimitAmt = this.productInfo.ara[0]?.repairLimitAmt;
            this.premiumCharges.commissionAmt = this.productInfo.ara.map((b: any) => b.commAmt).reduce((b: any, c: any) => b + c);;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        
        } else if (this.productService.productInfo.lineCd === 'PA') {
            this.premiumCharges.premiumAmt = 0;
            this.premiumCharges.premiumAmt = this.coverages[this.coverages.length - 1].premAmt;

            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];

            for (let tax of this.jsonDataService.data.tax.filter((a: any) => this.productService.productInfo.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
                let taxAmt = 0;

                taxAmt = this.productService.productInfo.taxes.filter((a: any) => a.taxCd === tax.taxCd)[0].taxAmt;

                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmt
                    });
                } else {
                    otherCharges += taxAmt;
                }

                this.premiumCharges.totalTax += taxAmt;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }

            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt + this.premiumCharges.totalTax;
            this.premiumCharges.commissionAmt = this.productService.productInfo.perilInfo.comAmount ?? this.productService.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        } else if (this.productService.productInfo.lineCd === 'CI') {
            this.premiumCharges.premiumAmt = 0;
            this.premiumCharges.premiumAmt = this.coverages[this.coverages.length - 1].premAmt;

            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];

            for (let tax of this.jsonDataService.data.tax.filter((a: any) => this.productService.productInfo.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
                let taxAmt = 0;

                taxAmt = this.productService.productInfo.taxes.filter((a: any) => a.taxCd === tax.taxCd)[0].taxAmt;

                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmt
                    });
                } else {
                    otherCharges += taxAmt;
                }

                this.premiumCharges.totalTax += taxAmt;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }

            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt + this.premiumCharges.totalTax;
            this.premiumCharges.commissionAmt = this.productService.productInfo.perilInfo.comAmount ?? this.productService.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        } else if (this.productService.productInfo.lineCd === 'HA') {
            this.premiumCharges.premiumAmt = 0;
            this.premiumCharges.premiumAmt = this.coverages[this.coverages.length - 1].premAmt;

            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];

            for (let tax of this.jsonDataService.data.tax.filter((a: any) => this.productService.productInfo.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
                let taxAmt = 0;

                this.productService.productInfo.taxes.filter((a: any) => a.taxCd === tax.taxCd).map((b: any) => {
                    taxAmt += b.taxAmt;
                });

                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmt
                    });
                } else {
                    otherCharges += taxAmt;
                }

                this.premiumCharges.totalTax += taxAmt;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }

            // COMMISSION - [START]
            this.premiumCharges.commissionAmt = 0;
            this.productInfo.coverages.map((a:any) => {
                if(a?.perilCd) {
                    this.premiumCharges.commissionAmt += a.commAmt;
                }
            });
            // COMMISSION - [END]

            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt + this.premiumCharges.totalTax;
            // this.premiumCharges.commissionAmt = this.productService.productInfo.perilInfo.comAmount ?? this.productService.productInfo.premiumCharges.commissionAmt;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        }
    }

    toggleEdit(): void {
        this.formService.hideFormMsg("acceptance-form-msg");
        this.formService.hideFormMsg("policy-header-details-form-msg");

        this.editFlag = !this.editFlag;
        if (this.editFlag) {
            this.policyHeaderDetailsForm.enable();
            if(this.userDetails.accountType==='A'){
                this.policyHeaderDetailsForm.controls['agentDetail'].disable();
                this.filteredAgentDetailOptions = EMPTY;
                this.policyHeaderDetailsForm.controls['bmaDetail'].disable();
                this.filteredBmaDetailOptions = EMPTY;
            }
            if(this.userDetails.accountType==='E'){
                this.policyHeaderDetailsForm.controls['agentDetail'].disable();
                this.filteredAgentDetailOptions = EMPTY;
            }
        } else {
            this.policyHeaderDetailsForm.disable();
        }
    }

    checkTerm(): void {
        if (!this.productInfo.policyNo) {
            this.enableAccept =
                this.termsForm.get("term1")?.value &&
                this.termsForm.get("term2")?.value &&
                this.termsForm.get("term3")?.value &&
                this.termsForm.get("term4")?.value &&
                this.termsForm.get("term5")?.value;
            this.initializeStepperDetail();
        }
    }

    checkAgent():void {
        if (this.userDetails.accountType === "E" && this.policyHeaderDetailsForm.get('agentDetail')?.value.value !== this.bpiAgentCd) {
            this.policyHeaderDetailsForm.controls["referrorCodeDetail"].setValue("");
        }
    }

    savePolicyHeaderDetails(): void {
        this.checkAgent();
        if (this.policyHeaderDetailsForm.valid) {
            this.toggleEdit();
            this.showEditFlag = false;
            this.proceedSavePolicyHeaderDetails();
        } else {
            this.appMessageService.showAppMessage("Please fill all the fields correctly.", "info");
        }
    }

    proceedSavePolicyHeaderDetails(): void {
        const form = this.policyHeaderDetailsForm.getRawValue();
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.updateAcceptance({
            quoteId: this.productInfo.quoteId,
            agentCd: form.agentDetail.value,
            srcCd: form.sourceCodeDetail.value,
            srcExtCd: form.sourceExtensionDetail.value,
            bmaCd: form.bmaDetail.value,
            referrorCd: form.referrorCodeDetail.value,
            projectCd: form.projectCodeDetail.value,
            clientCd: form.clientTypeGroupDetail.value
        }).subscribe((response:any) => {
            this.showEditFlag = true;
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.productInfo.policyHeaderDetails = form;
                    this.productService.setProductInfo2(this.productInfo);
                    this.policyHeaderDetailsForm.markAsPristine();
                    this.appMessageService.showAppMessage("Your policy header details has been saved successfully.", "success");
                } else {
                    this.appMessageService.showAppMessage("Unable to save policy header details this time. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    blockedVehicleMessage(res: any): void {
        let message = "";
        let blocked = "";
        if (res.plateNo && res.engineNo && res.chassisNo && res.mvFileNo) {
            blocked = "Plate No, Engine No, Chassis No and MV File No";
        } else {
            if (res.plateNo) {
                blocked = "Plate No";
            }
            if (res.engineNo) {
                blocked = "Engine No";
            }
            if (res.chassisNo) {
                blocked = "Chassis No";
            }
            if (res.mvFileNo) {
                blocked = "MV File No";
            }
            if (res.plateNo && res.engineNo) {
                blocked = "Plate No and Engine No";
            }
            if (res.plateNo && res.chassisNo) {
                blocked = "Plate No and Chassis No";
            }
            if (res.plateNo && res.mvFileNo) {
                blocked = "Plate No and MV File No";
            }
            if (res.engineNo && res.chassisNo) {
                blocked = "Engine No and Chassis No";
            }
            if (res.engineNo && res.mvFileNo) {
                blocked = "Engine No and MV File No";
            }
            if (res.chassisNo && res.mvFileNo) {
                blocked = "Chassis No and MV File No";
            }
            if (res.plateNo && res.engineNo && res.chassisNo) {
                blocked = "Plate No, Engine No and Chassis No"
            }
            if (res.plateNo && res.engineNo && res.mvFileNo) {
                blocked = "Plate No, Engine No and MV File No"
            }
            if (res.plateNo && res.chassisNo && res.mvFileNo) {
                blocked = "Plate No, Chassis No and MV File No"
            }
            if (res.engineNo && res.chassisNo && res.mvFileNo) {
                blocked = "Engine No, Chassis No and MV File No"
            }
        }
        this.dialogBoxService.open({
            messageType: "C",
            content: message.concat("The ", blocked,  " you have entered is subject for review and approval. You may contact BPI MS for more information."),
            buttons: [{
                text: "Contact BPI MS", action: () => {
                    const url = this.router.serializeUrl(
                        this.router.createUrlTree([`/contact-us`], {
                            queryParams: {
                                brand: this.productInfo.vehicleInfo.makeDetail.text,
                                yearModel: this.productInfo.vehicleInfo.yearModelDetail.value,
                                model: this.productInfo.vehicleInfo.modelDetail.text + " " + this.productInfo.vehicleInfo.subModelDetail.text,
                                srp: this.productInfo.vehicleInfo.purchasePrice
                            }
                        }
                        )
                    );
                    this.router.navigateByUrl(url);
                }
            }, {
                text: "Close"
            }]
        });
    }

    checkBlockedVehicle(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.chkBlockedVehicle({
            plateNo: this.productInfo.vehicleInfo.plateNo,
            engineNo: this.productInfo.vehicleInfo.engineNo,
            chassisNo: this.productInfo.vehicleInfo.chassisNo,
            mvFileNo: this.productInfo.vehicleInfo.mvFileNo
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.jsonDataService.contorlLoading(false);
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if (res.plateNo === 0 && res.engineNo === 0 && res.chassisNo === 0 && res.mvFileNo === 0){
                            this.continueAccept();
                        } else {
                            this.blockedVehicleMessage(res);        
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your vehicle details. Please try again later.", "error");
                }
            });
        });
    }

    accept(): void {
        if (this.productInfo.lineCd === 'MC') {
            this.checkBlockedVehicle();
        } else {
            this.continueAccept();
        }
    }

    continueAccept(): void {
        let isRetro = this.isRetro();

        if (isRetro && !(this.productService.productInfo.sublineCd == 'CTPL' && this.productService.productInfo.vehicleInfo.vehicleType == '2')) {
            this.formService.hideFormMsg("policy-header-details-form-msg");
            this.formService.hideFormMsg("acceptance-form-msg");
            this.changeEffDate.open("Cancel");
        }else if(isRetro){
            this.dialogBoxService.open({
                messageType: "I",
                content: this.parameterService.paramV('OSP_PLATE_NO_ERR_MSG'),
                buttons: [ {
                    text: "Close"
                }]
            });
        } else {
            this.formService.hideFormMsg("policy-header-details-form-msg");
            this.formService.hideFormMsg("acceptance-form-msg");
            this.confirmPol.open("No");
        }

        /* REMOVE COMMENT TAG IF POLICY HEADER DETAILS WILL BE EDITABLE
        this.policyHeaderDetailsForm.enable();
        if (this.policyHeaderDetailsForm.valid) {
            this.editFlag ? this.savePolicyHeaderDetails() : this.policyHeaderDetailsForm.disable();
            if (this.showEditFlag && this.editFlag) {
                this.appMessageService.showAppMessage("Please save your policy header details first.", "info");
            } else if (this.isRetro()) {
                this.formService.hideFormMsg("policy-header-details-form-msg");
                this.formService.hideFormMsg("acceptance-form-msg");
                this.changeEffDate.open("Cancel");
            } else {
                !this.editFlag ? this.policyHeaderDetailsForm.disable() : "";
                this.appMessageService.showAppMessage("Please fill up your policy header details first.", "error");
            }
        }
        */
    }

    isRetro(): boolean {
        let {accountType, userLevel} = this.userDetails;
        let {sublineCd, planInfo:{planCd}, lineCd} = this.productService.productInfo;
        let { allowDateRange, sublineTimeTag} = this.jsonDataService.data.effDatePerUser.filter(
            (a:any)=> 
                   a.userType == accountType 
                && a.userLevel == userLevel
                && a.lineCd == lineCd
                && a.sublineCd == sublineCd
                && a.planCd == planCd)[0] || {};

        this.sublineTime = this.jsonDataService.data.sublines.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd)[0]?.sublineTime;
        let sublineTimeArr = this.sublineTime.split(':');
        let cutOff = new Date();
        cutOff.setHours(sublineTimeArr[0],sublineTimeArr[1],sublineTimeArr[2],0);
        let isPastCutOff = new Date().getTime() > cutOff.getTime();
        let isSameDayUser:Boolean = allowDateRange <= 0;
        // IGNORE CUTOFF IF SUBLINE_TIME_TAG = "N" 
        let ignoreCutOff = sublineTimeTag === "N";
        if(!isSameDayUser){
            return new Date(new Date().setHours(24, 0, 0, 0)) > new Date(this.productInfo.coverageInfo.effDate);
        }else{
            let minDate = new Date();
            minDate.setHours(0, 0, 0);
            if(!ignoreCutOff){
                minDate.setHours(sublineTimeArr[0],sublineTimeArr[1],sublineTimeArr[2],0);
            }
            minDate.setTime(minDate.getTime() + (allowDateRange *  86400000));
            let currEffDate = new Date(this.productInfo.coverageInfo.effDate)
            return (currEffDate < minDate) || (isPastCutOff && allowDateRange==0 && !ignoreCutOff && this.isSameDay(currEffDate,new Date())); 
        }
    }

     isSameDay(d1:Date, d2:Date):boolean {
        return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
      }

    generatePolicy(isRetro?:Boolean): void {
        this.paValidations = this.jsonDataService.data.paValidations.filter((validators: any) => {
            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === this.productService.productInfo.planInfo.planCd
          }).length !== 0 ? this.jsonDataService.data.paValidations.filter((validators: any) => {
            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === this.productService.productInfo.planInfo.planCd
          })[0] : "";

        if(isRetro && ["CI","PA"].indexOf(this.productInfo.lineCd) > -1 && !this.validatePrincipalBirthDate()){
            const ageError = this.paValidations.PRIN_AGE_ERR_MSG;
            this.appMessageService.showAppMessage(ageError, "error");
            this.changeEffDate.close();
            return;
        }else if(isRetro && this.productInfo.lineCd === "PA" && this.productInfo.sublineCd == 'FPA' && !this.validateDependentsBirthDate()){
            let validityPeriodErrorMessage = this.parameterService.paramV("VALIDITY_PERIOD_ERR_MSG");
            const ageValidityErrorMessage = validityPeriodErrorMessage.replace("BMM136.MAX_PARENT_SPOUSE_AGE", this.paValidations.MAX_PARENT_SPOUSE_AGE).replace("BMM136.MAX_CHILD_SIBLING_AGE", this.paValidations.MAX_CHILD_SIBLING_AGE).replace("BMM011.DEP_AGE_VALIDITY_PERIOD", this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD").toString());
            this.appMessageService.showAppMessage(ageValidityErrorMessage, "error");
            this.changeEffDate.close();
            return;
        }
        this.proceedGeneratePolicy(isRetro);
    }

    validatePrincipalBirthDate():boolean {
        let validPrincipalBirthday = false;
        const ageValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
        const age = moment().diff(this.productService.productInfo.personalInfo.principalBirthDateFormat, 'years', false);
        const dob = moment(this.productService.productInfo.personalInfo.principalBirthDateFormat);
        const dobWithValidityPeriod = dob.subtract(ageValidityPeriod, 'days');
        const minAge = this.paValidations.MIN_PRIN_AGE;
        const maxAge = this.paValidations.MAX_PRIN_AGE;
        // const ageError = this.paValidations.PRIN_AGE_ERR_MSG;
        let period = this.periodForm.getRawValue();
        const effDate = moment(period.effDate).startOf('day');
        // const expDate = moment(period.expDate).startOf('day');
        // const lowLimitAge = moment().year() - moment(this.productService.productInfo.personalInfo.principalBirthDateFormat).year();
        const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
        // With age validity and eff date
        if(!isNaN(age) && !(this.productService.productInfo.personalInfo.principalBirthDateFormat > moment()) && !(maxLimitEffAge < minAge || maxLimitEffAge > maxAge)) {
            validPrincipalBirthday = true;
        }
        return validPrincipalBirthday;
    }

    validateDependentsBirthDate():boolean {
          let dependentAgeValid: boolean = true;
          this.productService.productInfo.dependents.map((dep: any) => { // check if age is still valid
            const maxDependentParentSpouseAge = this.paValidations.MAX_PARENT_SPOUSE_AGE;
            const minDependentSpouseAge = this.paValidations.MIN_SPOUSE_AGE;
            const minDependentChildSiblingAge = this.paValidations.MIN_CHILD_SIBLING_AGE;
            const maxDependentChildSiblingAge = this.paValidations.MAX_CHILD_SIBLING_AGE;
            const lowLimitDependentAge = moment().year() - moment(dep.birthDate).year();
            const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
            let dependentAge = moment().diff(moment(dep.birthDate).subtract(depAgeValidityPeriod, 'days'), 'years', false);
            let period = this.periodForm.getRawValue();
            const effDate = moment(period.effDate).startOf('day');
            const expDate = moment(period.expDate).startOf('day');
            
            const dobWithValidityPeriod = moment(moment(dep.birthDate).subtract(depAgeValidityPeriod, 'days'));
            let maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
            let ageWithAddedYear = moment(dobWithValidityPeriod);
            ageWithAddedYear.add(1, 'years');
            if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) { // checks next birthday against eff date
              if((dep.relationshipCd === 10 || dep.relationshipCd === 9) && maxLimitEffAge < 1) { // checks whether child or sibling less than 1 year old
                dep.age = 1;
                maxLimitEffAge = 1;
              } else {
                dep.age = maxLimitEffAge;
                dependentAge = maxLimitEffAge;
              }
            }
            dep.relationshipCd = this.jsonDataService.data.relationship.filter((data: any) => data.relationshipDesc === dep.relationship)[0]?.relationshipCd;
            if(dep.relationshipCd == 1 || dep.relationshipCd == 2 || dep.relationshipCd == 8) {
              if(maxLimitEffAge > maxDependentParentSpouseAge) {
                dependentAgeValid = false;
              }
            } else if (dep.relationshipCd == 7) {
              if(maxLimitEffAge > maxDependentParentSpouseAge || maxLimitEffAge < minDependentSpouseAge) {
                dependentAgeValid = false;
              }
            } else {
              if(maxLimitEffAge < minDependentChildSiblingAge || maxLimitEffAge > maxDependentChildSiblingAge) {
                dependentAgeValid = false;
              }
            }
          });
          return dependentAgeValid;
      }

    proceedGeneratePolicy(isRetro?:Boolean):void {
        let period:any = null;
        if (isRetro) {
            if (this.periodForm.invalid) { return; }
            period = this.periodForm.getRawValue();
            period.effDate =this.toOspDateString(new Date(period.effDate).toDateString() + " " + this.sublineTime);
            period.expDate =this.toOspDateString(new Date(period.expDate).toDateString() + " " + this.sublineTime);
            this.changeEffDate?.close();
        };

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.generatePolicy({
            quoteId: this.productInfo.quoteId,
            period: period
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    // this.fbAnalyticsService.trackEvent('SubmitApplication');
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if (res.errorMessage) {
                            this.appMessageService.showAppMessage(res.errorMessage, "error");
                        } else {
                            this.productInfo.policyId = res.policyId;
                            this.productInfo.policyNo = res.policyNo;

                            if (this.productInfo.lineCd === "MC") {
                                this.productInfo.vehicleInfo.cocNo = res.cocNo;
                                this.productInfo.vehicleInfo.cocAuthNo = res.cocAuthNo;
                                this.productInfo.vehicleInfo.icAuthNo = res.icAuthNo;
                                this.productInfo.vehicleInfo.cocAuthDate = res.cocAuthDate;
                            }

                            this.productInfo.paymentInfo ? "" : this.productInfo.paymentInfo = {};
                            this.productInfo.paymentInfo.paytRefNo = res.paytRefNo;
                            this.productInfo.paytTag = res.paytTag;

                            if (isRetro) {
                                this.productInfo.coverageInfo.effDate = period.effDate;
                                this.productInfo.coverageInfo.expDate = period.expDate;
                            }

                            this.productService.setQuoteStat(res.quoteStatCd);
                            this.productService.setProductInfo2(this.productInfo);

                            this.showEditFlag = false;
                            this.policyHeaderDetailsForm.disable();
                            this.initializeStepperDetail();

                            if (res.cocAuthNo) {
                                this.appMessageService.showAppMessage("You have successfully generated your E-Policy with Policy No. <b>" + res.policyNo + "</b> and COC Registration details with COC No. <b>" + res.cocNo + "</b> and COC Authentication No. <b>" + res.cocAuthNo + "</b>.", "success");
                            } else {
                                this.appMessageService.showAppMessage("You have successfully generated your E-Policy with Policy No. <b>" + res.policyNo + "</b>.", "success");
                            }
                        }
                    });
                } else if (response.status === "HIGH_RISK") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if (this.productInfo.lineCd === "MC") {
                            const vehicleInfo = this.productService.productInfo.vehicleInfo;

                            this.dialogBoxService.open({
                                messageType: "I",
                                content: res.errorMessage,
                                buttons: [{
                                    text: "Contact BPI MS",
                                    action: () => {
                                        const url = this.router.serializeUrl(
                                            this.router.createUrlTree(["/contact-us"], {
                                                queryParams: {
                                                    brand: vehicleInfo.makeDetail.text,
                                                    yearModel: vehicleInfo.yearModelDetail.value,
                                                    model: vehicleInfo.modelDetail.text + " " + vehicleInfo.subModelDetail.text,
                                                    srp: vehicleInfo.purchasePrice
                                                }
                                            })
                                        );
                                        this.router.navigateByUrl(url);
                                    }
                                }, { text: "Close" }]
                            });
                        } else {
                            this.dialogBoxService.open({
                                messageType: "I",
                                content: res.errorMessage,
                                buttons: [{
                                    text: "Contact BPI MS",
                                    action: () => {
                                        const url = this.router.serializeUrl(this.router.createUrlTree(["/contact-us"], {}));
                                        this.router.navigateByUrl(url);
                                    }
                                }, { text: "Close" }]
                            });
                        }
                    });
                } else if(response.status === "NONE_MAINTAINED") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        this.appMessageService.showAppMessage(res.errorMessage, "error");
                    });
                } else {
                    this.appMessageService.showAppMessage("Unable to generate Policy Number at this time. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    toOspDateString(date:Date | string ):string{
        let dt =new Date(date);
        return `${
            dt.getFullYear().toString().padStart(4, '0')}-${
            (dt.getMonth()+1).toString().padStart(2, '0')}-${
            dt.getDate().toString().padStart(2, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`
    }

    evaluateGetPdf(): void {
        if (this.productInfo.lineCd === 'PA' 
        && this.productInfo.sublineCd === 'IND'
        && this.productInfo.coverageInfo.grpTag === 'Y') {
            this.getPdf('insuringtermsgrp');
        }
        else {
            this.getPdf('insuringterms');
        }
    }

    getPdf(pdfName: string): void {
        if (pdfName === "epolicy") {
            window.open(this.ePolicy);
        } else if (pdfName === "privacypolicy") {
            window.open(this.privacyPolicy);
        } else if (pdfName === "securitypolicy") {
            window.open(this.securityPolicy);
        } else if (pdfName === "onlineterms") {``
            window.open(this.onlineTerms);
        } else if (pdfName === "marketingagreement") {
            window.open(this.mktgAgreement);
        } else if (pdfName === "insuringterms") {
            window.open(this.insuringTerms);
        } else if (pdfName === "insuringtermsgrp") {
            window.open(this.insuringTermsGrp);
        }
    }

    backToQuotationProcessing(): void {
        this.productInfo.creationProcess = "quotation";
        this.productInfo.previousProcess = "quotation";
        this.productService.setProductInfo2(this.productInfo);
        this.router.navigate(["/quotation/choose-plan"]);
    }

    move(action: string): void {
        if (action === "back") {
            this.router.navigate(["/policy/customer-information"]);
        } else {
            this.router.navigate(["/policy/payment"]);
        }
    }

    /*******************************************************************************/
    /*                                                                             */
    /*                           AUTO-COMPLETE FUNCTIONS                           */
    /*                                                                             */
    /*******************************************************************************/

    /* AGENT AUTO-COMPLETE FUNCTIONS */

    getAgents(): any[] {
        let agentOptions = [];
        for (let agent of this.jsonDataService.data.agent) {
            agentOptions.push({
                value: agent.agentCd,
                text: (agent.bmCode ? agent.bmCode + " - " : "") + agent.agentName
            });
        }
        agentOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return agentOptions;
    }
    setAgentViaInput(): void {
        for (const agent of this.getAgents()) {
            if (agent.text === this.policyHeaderDetailsForm.controls["agentDetail"].value) {
                this.policyHeaderDetailsForm.controls["agentDetail"].setValue(agent);
            }
        }
    }

    /* SOURCE CODE AUTO-COMPLETE FUNCTIONS */

    getSourceCodes(): any[] {
        let sourceCodeOptions = [];
        for (let sourceCode of this.jsonDataService.data.src) {
            sourceCodeOptions.push({
                value: sourceCode.srcCd,
                text: sourceCode.srcCdDesc
            });
        }
        sourceCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return sourceCodeOptions;
    }
    setSourceCodeViaInput(): void {
        for (const sourceCode of this.getSourceCodes()) {
            if (sourceCode.text === this.policyHeaderDetailsForm.controls["sourceCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["sourceCodeDetail"].setValue(sourceCode);
            }
        }
    }

    /* SOURCE EXTENSION AUTO-COMPLETE FUNCTIONS */

    getSourceExtensions(): any[] {
        let sourceExtensionOptions = [];
        for (let sourceExtension of this.jsonDataService.data.srcExt) {
            sourceExtensionOptions.push({
                value: sourceExtension.srcExtCd,
                text: sourceExtension.brCode + " " + sourceExtension.srcExtDesc
            });
        }
        sourceExtensionOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return sourceExtensionOptions;
    }
    setSourceExtensionViaInput(): void {
        for (const sourceExtension of this.getSourceExtensions()) {
            if (sourceExtension.text === this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].value) {
                this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].setValue(sourceExtension);
            }
        }
    }

    /* BMA AUTO-COMPLETE FUNCTIONS */

    getBMAs(): any[] {
        let bmaOptions = [];
        for (let bma of this.jsonDataService.data.bma) {
            bmaOptions.push({
                value: bma.bmaCd,
                text: bma.bmaDesc
            });
        }
        bmaOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return bmaOptions;
    }
    setBmaViaInput(): void {
        for (const bma of this.getBMAs()) {
            if (bma.text === this.policyHeaderDetailsForm.controls["bmaDetail"].value) {
                this.policyHeaderDetailsForm.controls["bmaDetail"].setValue(bma);
            }
        }
    }

    /* REFERROR CODE AUTO-COMPLETE FUNCTIONS */

    getReferrorCodes(): any[] {
        let referrorCodeOptions = [];
        for (let referrorCode of this.jsonDataService.data.referror) {
            referrorCodeOptions.push({
                value: referrorCode.referrorCd,
                text: referrorCode.referrorDesc
            });
        }
        referrorCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return referrorCodeOptions;
    }
    setReferrorCodeViaInput(): void {
        for (const referrorCode of this.getReferrorCodes()) {
            if (referrorCode.text === this.policyHeaderDetailsForm.controls["referrorCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["referrorCodeDetail"].setValue(referrorCode);
            }
        }
    }

    /* PROJECT CODE AUTO-COMPLETE FUNCTIONS */

    getProjectCodes(): any[] {
        let projectCodeOptions = [];
        for (let projectCode of this.jsonDataService.data.project) {
            projectCodeOptions.push({
                value: projectCode.projectCd,
                text: (projectCode.bmCode ? projectCode.bmCode + " - " : "") + projectCode.projectDesc
            });
        }
        projectCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return projectCodeOptions;
    }
    setProjectCodeViaInput(): void {
        for (const projectCode of this.getProjectCodes()) {
            if (projectCode.text === this.policyHeaderDetailsForm.controls["projectCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["projectCodeDetail"].setValue(projectCode);
            }
        }
    }

    /* PROJECT CODE AUTO-COMPLETE FUNCTIONS */

    getClientTypeGroups(): any[] {
        let clientTypeGroupOptions = [];
        for (let clientTypeGroup of this.jsonDataService.data.client) {
            clientTypeGroupOptions.push({
                value: clientTypeGroup.clientCd,
                text: clientTypeGroup.clientDesc
            });
        }
        clientTypeGroupOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return clientTypeGroupOptions;
    }
    setClientTypeGroupViaInput(): void {
        for (const clientTypeGroup of this.getClientTypeGroups()) {
            if (clientTypeGroup.text === this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].value) {
                this.policyHeaderDetailsForm.controls["clientTypeGroupDetail"].setValue(clientTypeGroup);
            }
        }
    }

    getExpiry(): void {
        let noOfYears = this.productInfo.coverageInfo.noOfYears;

        let effDate = new Date(this.periodForm.getRawValue().effDate);
        effDate = new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + effDate.getFullYear() + " " + this.sublineTime);
        if (this.productService.productInfo.sublineCd == 'CTPL') {
            let years = this.jsonDataService.data.mvPremType.filter((a: any) => a.mvPremType == this.productService.productInfo.vehicleInfo?.mvPremTypeDetail?.value)[0]?.noOfYears;
            this.periodForm.patchValue({
                expDate: new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + (effDate.getFullYear() + years) + " " + this.sublineTime),
            });
        } else {
            this.periodForm.patchValue({
                expDate: new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + (effDate.getFullYear() + noOfYears) + " " + this.sublineTime),
            });
        }

    }

    insuredCol1:any[]= [];
    insuredCol2:any[]= [];
    longerRow:any[] = [];

    buildAmountInsuredHA():void{
        let bldgItems = this.productInfo.bldgForm?.bldgItms?.filter((i:any)=> i.bldgAmt);
        let contItems = this.productInfo.contForm?.contItms?.filter((i:any)=> i.contAmt);

        let contents:any[] = [];

        if(bldgItems || contItems){
            let itemNo = 0;
            contents = bldgItems.concat(contItems).sort((a:any,b:any)=>a.seqNo-b.seqNo).map((i:any)=>{
                itemNo += 1;
                return {
                    amt : i.bldgAmt || i.contAmt,
                    itmCd : i.bldgItmCd || i.contItmCd,
                    itemNo: itemNo,
                    curr: getCurrencySymbol(i.contCurrency, 'narrow') || getCurrencySymbol(i.bldgCurrency, 'narrow')
                };
            });
        }else{
            contents = this.productInfo.bldgCont
                .sort((a:any,b:any)=>a.itemNo - b.itemNo)
                .map((i:any)=>{
                    return {amt : i.bldgAmt,
                            itmCd : i.bldgItmCd,
                            itemNo: i.itemNo,
                            curr: getCurrencySymbol(i.bldgCurrency, 'narrow')}
                });
        }

        let mtnContents:any[] = this.jsonDataService.data.firePlanItemCd.filter((b:any)=>b.lineCd == this.productInfo.lineCd && b.sublineCd == this.productInfo.sublineCd && b.planCd == this.productInfo.planInfo.planCd)

        mtnContents.forEach(a=>{
            let content = contents.find(b=>a.itemCd == b.itmCd) || {curr: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow')};
            content.label = this.jsonDataService.data.fireItemCd.find((b:any)=>b.itemCd == a.itemCd).itemLabel;
            
            a.colGrp == 1 ? this.insuredCol1.push(content) : this.insuredCol2.push(content);
            this.total += parseFloat(content.amt || 0)
        });
        this.longerRow = this.insuredCol1.length > this.insuredCol2.length ? this.insuredCol1 : this.insuredCol2;
    }

    onClickPrint(reportType: string): void {
        if (reportType === "quote") {
            this.productService.setProductInfo("creationProcess", "quotation");
            this.router.navigate(["/quotation/email-print"]);
        } else if (reportType === "policy") {
            if(this.productService.productInfo.creationProcess !== 'renewal'){
                this.productService.setProductInfo("creationProcess", "policy");
            }
            this.router.navigate(["/policy/email-print"]);
        }
    }
}