import { DatePipe, getCurrencySymbol } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OverlayComponent } from 'src/app/components/common/overlay/overlay';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { DownloadService } from 'src/app/services/download.service';

import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    templateUrl: './pol-summary.component.html'
})
export class PolSummaryComponent {

    //Vehicle Detail Overlay
    @ViewChild('vehicleDetail')vehicleDetail!: OverlayComponent;

    // Cancelation Overlay
    @ViewChild("cancelPol") cancelPol!: OverlayComponent;

    // COVERAGES TABLE
    lastSortedColumn: string = "";
    sorting: string = "ASC";

    productInfo: any = {};
    coverages: any[] = [];
    dependents: any = [];
    coverageItems: any[] = [];
    benefitsCoveragesCI:any = {};
    benefitsCoveragesFPA: any[] = [];
    benefitsCoveragesIND: any[] = [];
    benefitsCoveragesREN: any[] = [];
    showBenCovDetails:boolean = false;
    showComm: boolean = false;
    premiumCharges: any = {};
    printPolButton: boolean = true;
    payButton: boolean = true;
    sendNotice:boolean = false;
    saveButton:boolean = false;
    oldExpiryDate:string = "";
    contactUsText:string = "";
    updateClientInfoText: string = "";
    kycComplianceText:string = "";
    missingKYCInfo:string = "";
    policyCancellationText:string = "If you would like to cancel the policy, "; // para sa policy cancelation
    validCancellationText:boolean = false; // para sa policy cancelation
    wacText:string = "";
    wacLink:string = "";

    helpParams: any = {
        tableName: "",
        dedAmt: "",
        towingAmt: "",
        repairAmt: ""
    }
    dedAmtComment: String = "";
    towingAmtComment: String = "";
    repairAmtComment: String = "";
    quickLink: any = {
        quickLinkPdfPath1: "",
        quickLinkPdfPath2: "",
        quickLinkPdfPath3: "",
        quickLinkPdfPath4: "",
        quickLinkPdfName1: "",
        quickLinkPdfName2: "",
        quickLinkPdfName3: "",
        quickLinkPdfName4: "",
        count: 0
    }

    seeMorePolicyHolder: boolean = false;
    seeMoreVehicleDetails: boolean = false;
    seeMoreFirePropertyInfo: boolean = false;
    seeMorePAPropertyInfo: boolean = false;
    seeMoreConstruction: boolean = false;
    seeMoreOccupancy: boolean = false;
    seeMoreBankName: boolean = false;
    seeMoreOtherRiskDetail: boolean = false;
    seeMoreInterestedParty: boolean = false;
    seeMorePAOtherRiskDetail: boolean = false;
    seeMorePAInterestedParty: boolean = false;
    seeMorePARiskEndo: boolean = false;
    seeMoreFireRiskEndo: boolean = false;


    form: FormGroup = this.formBuilder.group({
        address: ["", Validators.required],
        email: ["", Validators.required],
        mobileNo: ["", Validators.required],
        telNo: [""],
        maritalStat: ["", Validators.required],
        provOrCity: ["", Validators.required],
        postCode:["", Validators.required]
    });

    //para sa cancellation ng policy
    cancellationForm: FormGroup = this.formBuilder.group({
        reason: [""],
    });

    mvFileNoLength:number = this.parameterService.paramN("MVFILENO_LENGTH") || 0;
    plateNoMaxLength:number = this.parameterService.paramN("PLATENO_LENGTH") || 10;

    vehicleForm: FormGroup = this.formBuilder.group({
        plateNo: ["", [Validators.required,Validators.maxLength(this.plateNoMaxLength)]],
        engineNo: ["", Validators.required],
        chassisNo: ["", Validators.required],
        mvFileNo: ["",[Validators.required, Validators.minLength(this.mvFileNoLength), Validators.maxLength(this.mvFileNoLength)]],
    });

    
    smsCountryCd: any[] = [
        {
          countryCd: 23,
          BM_CODE: 'PHP',
          smsCountryCd: '63',
          prefix: '0'
        }
      ]

    filteredMaritalStat: Observable<any> = new Observable;
    filteredProvince: Observable<any> = new Observable;
    filteredPostal: Observable<any> = new Observable;
    filteredReasonOptions:Observable<any>; // para sa cancellation ng policy

    defaultCountry:Number = 0;
    coverageLength = 0;

    insuringTerms: string = "";
    insuringTermsGrp: string = "";
    termsRenewalText: string = "";
    termsPaymentText: string = "";
    procInfoConsentLink: string = "";

    isAccepted:boolean = false;
    showCheckBox: boolean = false;
    tinPattern = new RegExp('^([0-9]{3}\-){3}[0-9]{3,5}?$');
    renewalAgentCd:string = '';
    
    agentType: String = '';
    holderSuppDocs:any[] = [];
    detailsSuppDocs:any[]= [];

    minPremMsg:string = this.jsonDataService.data.minPremLimit.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.minPremText || "";


    constructor(
        // PUBLIC
        public userDetails: UserDetailsService,
        public productService: ProductService,
        public formService: FormService,
        public userDetailsService: UserDetailsService,

        // PRIVATE
        private router: Router,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private formBuilder: FormBuilder,
        public autocompleteService: AutocompleteService,
        private parameterService: ParameterService,
        private securityService: SecurityService,
        private appMessageService: AppMessageService,
        private dialogBoxService: DialogBoxService,
        private messageBoxService: MessageBoxService,
        private downloadService: DownloadService
    ) {
        this.pageValidationService.validatePageAccessibility();
        
        this.productInfo = this.productService.productInfo;
        
        if(this.productInfo.lineCd == 'MC'){
            this.detailsSuppDocs = this.productInfo.vehicleInfo.supportingDocs;
        }
        this.holderSuppDocs = this.productInfo.customerInfo.supportingDocs || [];
        
        this.filteredReasonOptions = this.autocompleteService.set(this.cancellationForm, "reason", this.getReasons()); // para sa cancellation ng policy
        if (this.productInfo.creationProcess === "renewal") {
            this.form.patchValue({
                address: this.productInfo.policyHolderDetails.policy.policyAddress,
                email: this.productInfo.customerInfo.emailAddress,
                mobileNo: this.productInfo.customerInfo.mobileNo,
                telNo: this.productInfo.customerInfo.telNo,
                maritalStat: this.productInfo.customerInfo.maritalDetail,
                provOrCity: this.productInfo.customerInfo.permanentCityDetail,
                postCode: this.productInfo.customerInfo.permanentPostalDetail
            });

            this.filteredMaritalStat = this.autocompleteService.set(this.form, "maritalStat", this.getMaritalStat());
            this.filteredProvince = this.autocompleteService.set(this.form, "provOrCity", this.getProvince());
            this.filteredPostal = this.autocompleteService.set(this.form, "postCode", this.getPostal());
            this.defaultCountry = this.parameterService.paramN("DEFAULT_ASSD_COUNTRY");
            this.productInfo.customerInfo.permanentAddress = this.productInfo.policyHolderDetails.policy.policyAddress;
            this.productService.setProductInfo2(this.productInfo);
        } else if (this.productInfo.creationProcess === "expired") {
            this.productService.setPolStat(7);
        } else {
            this.productService.setPolStat(2);
        }

        this.renewalAgentCd = this.parameterService.paramV("REN_AGENT_CD");
        this.contactUsText = this.parameterService.paramV("SUMMARY_QUICK_LINK");
        this.kycComplianceText = this.parameterService.paramV("KYC_COMPLIANCE_TEXT");
        this.missingKYCInfo = this.parameterService.paramV("RENEWAL_MISSINGKYC_PROMPT");
        // ? client info text (get text value from paramV)
        this.updateClientInfoText = this.parameterService.paramV("UPDATE_CLIENT_INFO") ?? "";
        // ? processing of information link
        this.procInfoConsentLink = this.parameterService.paramV("PROC_INFO_CONSENT") ?? "";

        if (this.productInfo.fromLink === 'renewal') {
            // // this.termsRenewalText = this.parameterService.paramV("ACCEPT_RENEWAL_PAYT") ?? "null : No Setup in [ACCEPT_RENEWAL_PAYT]";
            const termsRenewalRawText = `
                By renewing and paying the premiums due for this policy, I confirm that I have read, understood and agree to the Insuring Terms and Conditions 
                of the policy that is being renewed as is. I certify that all the personal information and documents that I have confirmed and/or provided are true, 
                complete and accurate to the best of my knowledge and I understand that any falsification or misrepresentation of information may warrant 
                BPI MS to cancel the policy or deny a claim related to this policy. Further, I give my express consent to the 
                PROCESSING OF MY INFORMATION. I also understand and agree that I will receive an electronic 
                copy of my renewed policy. Should I want a printed hard copy of my e-policy, I can request for such by calling BPI MS at (02) 8840-9000 or email 
                bpimshelpline@bpi.com.ph.
            `;
            const termsTextLink = 'PROCESSING OF MY INFORMATION';
            const termsTextLinkHtml = `<a href='${this.procInfoConsentLink}' target='_blank'>${termsTextLink}</a>`;
            this.termsRenewalText = termsRenewalRawText.replace(termsTextLink,termsTextLinkHtml);
            // this.termsRenewalText = `
            //     By renewing and paying the premiums due for this policy, I confirm that I have read, understood and agree to the Insuring Terms and Conditions 
            //     of the policy that is being renewed as is. I certify that all the personal information and documents that I have confirmed and/or provided are true, 
            //     complete and accurate to the best of my knowledge and I understand that any falsification or misrepresentation of information may warrant 
            //     BPI MS to cancel the policy or deny a claim related to this policy. Further, I give my express consent to the 
            //     <a href='${this.procInfoConsentLink}' target='_blank'>PROCESSING OF MY INFORMATION</a>. I also understand and agree that I will receive an electronic 
            //     copy of my renewed policy. Should I want a printed hard copy of my e-policy, I can request for such by calling BPI MS at (02) 8840-9000 or email 
            //     bpimshelpline@bpi.com.ph.
            // `;
        }
            
        if (this.productInfo.fromLink === 'payment') {
            this.termsPaymentText = `
                I certify that all the information and documents I provided/confirmed in relation to this application are true, 
                complete and accurate to the best of my knowledge and I understand that any falsification or misrepresentation of 
                information may warrant BPI MS to cancel the policy or deny a claim related to this policy. Further, I give my express 
                consent to the <a href='${this.procInfoConsentLink}' target='_blank'>PROCESSING OF MY INFORMATION</a>. I also understand and agree that I will 
                receive an electronic copy of my renewed policy. Should I want a printed hard copy of my e-policy, I can request for such by calling BPI MS at (02) 8840-9000 or email bpimshelpline@bpi.com.ph.
            `;
        }

        this.insuringTermsGrp = this.parameterService.paramV("GPA_TERMS_PATH");
        this.insuringTerms = this.jsonDataService.data.plans.filter((e: any) =>
            e.lineCd == this.productInfo.lineCd
            && e.sublineCd == this.productInfo.sublineCd
            && e.planCd == this.productInfo.planInfo.planCd
        )[0]?.termsPath;

        const reasonCd = this.productService.productInfo.reasonCd; // para sa policy cancellation
        
        if(!this.productInfo.paymentInfo.paytDate){
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getPolicyPaytInfo({
                policyId:this.productInfo.policyId
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if(res){
                            this.productInfo.paymentInfo = res;
                            this.productService.setProductInfo2(this.productInfo);
                            this.initializeStepperDetail();
                        }else{
                            this.initializeStepperDetail();
                        }                                       
                    }
                });
            });
        }else{
            this.initializeStepperDetail();
        }

        
        this.setCoverages("", "");
        this.commissionDisp();
        this.setPremiumCharges();
        this.setHelpDescription();
        this.setQuickLinks();
        this.assignDependentsAndBenefitsCoverages();
        this.setVehicleDetailForm();
        // this.validateCancellationText(); // Uncomment para gumana yung pag cancel ng policy per transaction
        this.dependents = this.productInfo.dependents;
        
        if (this.userDetails.userId) {
            this.agentType = this.jsonDataService.data.agent.find((a: any) => a.agentCd === this.userDetails.userId)?.agentType;
        } else {
            this.agentType = "A";
        }
        this.displayedCurrency = getCurrencySymbol(this.productInfo.propertyInfo?.currency, 'narrow');
        if(this.productInfo.lineCd == 'HA'){
            this.buildAmountInsuredHA();
        }
        
        this.wacText = this.parameterService.paramV("RISK_WC_LINK_TEXT");
        this.wacText = this.wacText.replace('Warranties and Clauses.',  '');
        this.wacLink = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 1)[0]?.linkPath;
    }

    initializeStepperDetail(): void {
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.titleStepperDtl = "E-Policy Summary";

        // AYAW MAG TAKE EFFECT KAPAG WALA TIMEOUT
        setTimeout(() => {
            this.stepperDtlService.showStepNo = false;
        }, 0);

        if ((this.userDetailsService.accountType || 'P') === "P" && (this.productInfo.quoteStatCd === 8 || this.productInfo.polStatCd === 3) && this.productInfo.paytTag != 'Y') {
            this.payButton = true;
            this.printPolButton = false;
        } else if (this.productInfo.quoteStatCd === 9) {
            this.payButton = true;
            this.printPolButton = true;
        }
        

        if (this.productInfo.quoteStatCd === 4 || this.productInfo.polStatCd === 5) {
            this.printPolButton = true;
        }

        if (this.productInfo.creationProcess === "renewal") {
            if (this.productInfo.polStatCd == 3) {
                this.payButton = true;
                this.printPolButton = false;
                this.sendNotice = true;
                this.saveButton = true;
            }

            if ([2, 3].indexOf(this.productInfo.polStatCd) >= 0 && !this.userDetailsService.userId) {
                this.payButton = true;
                this.printPolButton = false;
                this.sendNotice = false;
                this.saveButton = true;
            }

            if (this.productInfo.polStatCd == 4) {
                this.payButton = true;
                this.printPolButton = true;
                this.sendNotice = false;
                this.saveButton = true;
            }

            if (this.productService.productInfo.polStatCd == 5) {
                this.payButton = false;
                this.printPolButton = true;
                this.sendNotice = false;
                this.saveButton = false;
            }

            if (this.productInfo.polStatCd == 6) {
                this.payButton = true;
                this.printPolButton = false;
                this.sendNotice = true;
                this.saveButton = true;
            }

            if (this.productInfo.polStatCd == 6 && !this.userDetailsService.userId) {
                this.payButton = true;
                this.printPolButton = false;
                this.sendNotice = false;
                this.saveButton = true;
            }

            if (this.productInfo.polStatCd == 7 || this.productInfo.polStatCd == 8) {
                this.payButton = false;
                this.printPolButton = false;
                this.sendNotice = false;
                this.saveButton = false;
            }

            if(this.productInfo.fromLink && this.productInfo.paymentInfo.paytDate){
                this.printPolButton = true;
                this.messageBoxService.show1('Info',"Policy has already been paid.",'Ok',null)
            }
        }

        if (this.productInfo.quoteStatCd === 4 || this.productInfo.polStatCd === 5 || this.productInfo.quoteStatCd === 5 || this.productInfo.polStatCd === 7 || (this.productInfo.quoteStatCd === 9&&this.productInfo.paymentInfo.compPaytRefNo) || this.productInfo.paymentInfo.paytDate || this.productInfo.paytTag == 'Y') {
            this.payButton = false;
        }

        if (!this.productInfo.fromLink) {
            this.isAccepted = true;
        }

        // ? show checkbox prompt if from link and (quoteStat is not 4 or polStat is not 5,7,8) 
        if(!this.userDetailsService.userId && this.productInfo.fromLink) {
            this.showCheckBox = true;

            if(!this.payButton){
                this.isAccepted = true;
            }
        }

        if (this.productService.productInfo.creationProcess !== "renewal") {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "toDashboard" },
                { class: "btn2", label: "Print Quotation", action: "onClickPrint", actionParam: "quote" },
                { class: "btn2", label: "Print Policy", action: "onClickPrint", actionParam: "policy", isShow: this.printPolButton ? "true" : "false" },
                { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false" }
            ];
        } else {
            this.stepperDtlService.stepperDtlBtns = [
                { class: "btn4", label: "Back", action: "toDashboard" },
                { class: "btn2", label: "Print Policy", action: "onClickPrint", actionParam: "policy", isShow: this.printPolButton ? "true" : "false" },
                { class: "btn2", label: "Send Notice", action: "sendMail", isShow: this.sendNotice ? "true" : "false" },
                { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false" }
            ];
            if (this.productInfo.fromLink) {
                this.stepperDtlService.stepperDtlBtns[3] = { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false", disabled: this.isAccepted ? "false" : "true"};
            }
        }
    }

    setCoverages(sortColumn: string, key: string): void {
        this.coverages = this.productInfo.coverages;
        if (!this.coverages[this.coverages.length - 1].perilCd) {
            this.coverages.pop();
        }

        const perils = this.jsonDataService.data.peril.filter((a: any) => a.lineCd === this.productInfo.lineCd);
        this.coverages = this.productInfo.coverages.map((a: any) => {
            let perilName =  perils.filter((b: any) => a.perilCd === b.perilCd)[0].perilLname;
            if(this.productInfo.lineCd == 'HA'){
                perilName = this.jsonDataService.data.fireItemCd.find((b:any)=>a.itemCd == b.itemCd)?.itemLabel + ' - ' + perilName
            }
            return {
                ...a,
                perilName: perilName
            }
        });

        this.sortCoverages(sortColumn, key);
            for (let index = 0; index < this.coverages.length; index++) {
                this.coverageItems.push(
                    {
                        itemNo: this.coverages[index].itemNo,
                        itemTitle: this.coverages[index].itemTitle
                    }
                )
            }
            this.coverages = this.getUniqueArray(this.coverages, ['itemNo','perilCd', 'itemTitle','tsiAmt', 'premAmt']);
            this.coverageItems = this.getUniqueArray(this.coverageItems, ['itemNo', 'itemTitle']);
            
            for (let index = 0; index < this.coverageItems.length; index++) {
                this.coverages.push({
                itemNo: this.coverageItems[index]?.itemNo,
                perilName: "Item Premium",
                tsiAmt: null,
                premRt: null,
                premAmt: this.coverages.reduce((total, item) => item.itemNo ===  this.coverageItems[index].itemNo ? total + item.premAmt : total, 0)
                });
            }
            this.coverages.push({
                itemNo: this.coverageItems.length,
                perilName: "Total Premium",
                tsiAmt: null,
                premRt: null,
                premAmt: this.productInfo.perilInfo.premAmt
            });
    }

    getUniqueArray(arr:any[], keyProps:any[]) {
        return Object.values(
          arr.reduce((uniqueMap, entry) => {
            const key = keyProps.map((k) => entry[k]).join('|')
            if (!(key in uniqueMap)) uniqueMap[key] = entry
            return uniqueMap
          }, {}),
        )
      }
    
      maskString(str:any, num:number){
        let maskedString = str.split(" ");
        let returnedString = '';
        for (let index = 0; index < maskedString.length; index++) {
            if(index>(num-1)){
                returnedString += maskedString[index].replace(/./g, '*') + ' ';
            }
            else {
                returnedString += maskedString[index] + ' ';
            }
        }
        return returnedString;
      }

    sortCoverages(sortColumn: string, key: string): void {
        if (sortColumn) {
            if (this.lastSortedColumn === sortColumn) {
                this.sorting = this.sorting === "ASC" ? "DESC" : "ASC";
            } else {
                this.lastSortedColumn = sortColumn;
                this.sorting = "ASC";
            }

            this.coverages.sort((a, b) => {
                if (this.sorting === "ASC") {
                    return this.compareStrings(a[key], b[key]);
                } else {
                    return this.compareStrings(b[key], a[key]);
                }
            });
        }
    }

    compareStrings(a: any, b: any): number {
        if (typeof a === "string") {
            a = a.toLowerCase();
            b = b.toLowerCase();
        }

        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    setHelpDescription() {
        this.dedAmtComment = this.jsonDataService.data.help.filter((a: any) => a.tableName === 'BMP015' && a.columnName === 'DED_AMT')[0]?.comment;
        this.towingAmtComment = this.jsonDataService.data.help.filter((a: any) => a.tableName === 'BMP015' && a.columnName === 'TOWING_AMT')[0]?.comment;
        this.repairAmtComment = this.jsonDataService.data.help.filter((a: any) => a.tableName === 'BMP015' && a.columnName === 'REPAIR_LIMIT_AMT')[0]?.comment;
    }
    setQuickLinks() {
        this.quickLink.quickLinkPdfPath1 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 1)[0]?.linkPath;
        this.quickLink.quickLinkPdfPath2 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 2)[0]?.linkPath;
        this.quickLink.quickLinkPdfPath3 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 3)[0]?.linkPath;
        this.quickLink.quickLinkPdfPath4 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 4)[0]?.linkPath;
        this.quickLink.quickLinkPdfName1 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 1)[0]?.linkName;
        this.quickLink.quickLinkPdfName2 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 2)[0]?.linkName;
        this.quickLink.quickLinkPdfName3 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 3)[0]?.linkName;
        this.quickLink.quickLinkPdfName4 = this.jsonDataService.data.quickLinks.filter((a: any) => a.lineCd === this.productInfo.lineCd && a.sublineCd === this.productInfo.sublineCd && a.planCd === this.productInfo.planInfo.planCd && a.linkNo === 4)[0]?.linkName;
        if (this.quickLink.quickLinkPdfPath1 && this.quickLink.quickLinkPdfName1) {
            this.quickLink.count += 1;
        }
        if (this.quickLink.quickLinkPdfPath2 && this.quickLink.quickLinkPdfName2) {
            this.quickLink.count += 1;
        }
        if (this.quickLink.quickLinkPdfPath3 && this.quickLink.quickLinkPdfName3) {
            this.quickLink.count += 1;
        }
        if (this.quickLink.quickLinkPdfPath4 && this.quickLink.quickLinkPdfName4) {
            this.quickLink.count += 1;
        }
    }

    assignDependentsAndBenefitsCoverages() {
        if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'FPA' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length && (this.productInfo.creationProcess!='renewal'||this.productInfo.fromLink=='payment')) {
            for (let benCovFPA of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesFPA.push({
                    perilCd: benCovFPA.perilCd,
                    perilName: benCovFPA.perilLname,
                    tsiPrincipal: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'PR' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiSpouse: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'SP' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt,
                    tsiChildren: this.productInfo.benefitsCoverages.filter((a:any) => a.planSubGrp === 'CH' && a.perilCd === benCovFPA.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'PA'  && this.productService.productInfo.sublineCd === 'IND' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length && (this.productInfo.creationProcess!='renewal'||this.productInfo.fromLink=='payment')) {
            for (let benCovIND of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesIND.push({
                    perilCd: benCovIND.perilCd,
                    perilName: benCovIND.perilLname,
                    tsiAmt: this.productInfo.benefitsCoverages.filter((a:any) => a.perilCd === benCovIND.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
        else if (this.productService.productInfo.lineCd === 'CI' && this.productInfo.benefitsCoverages && this.productInfo.benefitsCoverages.length && (this.productInfo.creationProcess!='renewal'||this.productInfo.fromLink=='payment')) {
            this.benefitsCoveragesCI.noOfDays = this.productInfo.benefitsCoverages[0].noOfDays;
            this.benefitsCoveragesCI.tsiAmt = this.productInfo.benefitsCoverages[0].tsiAmt;
            this.benefitsCoveragesCI.maxProfit = this.benefitsCoveragesCI.noOfDays*this.benefitsCoveragesCI.tsiAmt;
            this.showBenCovDetails = true;
        }
        else if((this.productService.productInfo.lineCd === 'CI'||this.productService.productInfo.lineCd === 'PA')&&(this.productInfo.creationProcess=='renewal'&&this.productInfo.fromLink!='payment')){
            for (let benCovREN of this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.productInfo.lineCd).filter((a:any) =>
                this.productInfo.coverages.map((b:any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                this.benefitsCoveragesREN.push({
                    perilCd: benCovREN.perilCd,
                    perilName: benCovREN.perilLname,
                    tsiAmt: this.productInfo.coverages.filter((a:any) => a.perilCd === benCovREN.perilCd)[0].tsiAmt
                });
            }
            this.showBenCovDetails = true;
        }
    }

    private commissionDisp(): void {
        let m072Dtls = this.jsonDataService.data.agentGrps.find((a: any) => a.agentGrp == this.userDetails.agentGrp);
        let m093Dtls = this.jsonDataService.data.userType.filter((a: any) => a.userType == (this.userDetails.accountType || 'P'))[0];
        if (this.userDetails.accountType === 'A') {
            if ((m072Dtls && m072Dtls?.hideCommTag == "N") ||
                (!m072Dtls?.hideCommTag && m093Dtls?.hideCommTag == 'N')) {
                this.showComm = true;
            }
        }
        else {
            this.showComm = true;
        }
    }

    setPremiumCharges(): void {
        const data = this.productService.productInfo;
        this.premiumCharges.premiumAmt = 0;
        if (data.lineCd === "MC") {
            this.premiumCharges.premiumAmt = data.ara.map((a: any) => a.premAmt).reduce((a: any, b: any) => a + b);
        } else {
            for (const coverage of data.coverages) {
                this.premiumCharges.premiumAmt += coverage.premAmt;
            }
        }

        // ? ammountDue = premiumAmt
        this.premiumCharges.amountDue = this.premiumCharges.premiumAmt;

        let othChargesAmt = 0;
        this.premiumCharges.taxes = [];
        for (let tax of this.jsonDataService.data.tax.filter((a: any) => data.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
            let taxAmt = 0;

            if (data.lineCd === "MC") {
                for (let key of data.ara.map((b: any) => b.year)) {
                    taxAmt += data.taxes.filter((a: any) => a.year == key && a.taxCd == tax.taxCd)[0].taxAmt;
                }
            } else {
                taxAmt = data.taxes.filter((a: any) => a.taxCd === tax.taxCd).reduce((psum:number,a:any)=>psum+a.taxAmt,0) || 0;
            }

            if (tax.ocTag === "N") {
                this.premiumCharges.taxes.push({
                    name: tax.taxName,
                    value: taxAmt
                });
            } else {
                othChargesAmt += taxAmt;
            }
            // ? ammountDue = premiumAmt + taxAmt[0] + ... + taxAmt[n]
            this.premiumCharges.amountDue += taxAmt;
        }

        if (othChargesAmt) {
            this.premiumCharges.taxes.push({ name: "Other Charges", value: othChargesAmt });
        }

        if (data.lineCd === "MC") {
            this.premiumCharges.deductibleAmt = data.ara[0]?.dedAmt;
            this.premiumCharges.towingAmt = data.ara[0]?.towingAmt;
            this.premiumCharges.repairLimitAmt = data.ara[0]?.repairLimitAmt;
            this.premiumCharges.commissionAmt = data.ara.map((b: any) => b.commAmt).reduce((b: any, c: any) => b + c);
        } else if(data.lineCd == 'HA'){
            this.premiumCharges.commissionAmt = data.premiumCharges?.commissionAmt || (data.coverages?.map((b: any) => b.commAmt).reduce((b: any, c: any) => b + c)) || 0;
        }else {
            //this.premiumCharges.amountDue += this.premiumCharges.premiumAmt;
            this.premiumCharges.commissionAmt = data.perilInfo.comAmount ?? 0;
        }

        this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
    }

    onClickPrint(reportType: string): void {
        if (reportType === "quote") {
            if(this.productService.productInfo?.quoteStatCd === 5 && this.productService.productInfo?.polStatCd === 7) {
                this.productService.setProductInfo("creationProcess", "expired");
            } else {
                this.productService.setProductInfo("creationProcess", "quotation");
            }
            this.router.navigate(["/quotation/email-print"]);
        } else if (reportType === "policy") {
            if(this.productService.productInfo?.quoteStatCd === 5 && this.productService.productInfo?.polStatCd === 7) {
                this.productService.setProductInfo("creationProcess", "expired");
            } else if(this.productService.productInfo.creationProcess !== 'renewal'){
                this.productService.setProductInfo("creationProcess", "policy");
            }
            this.router.navigate(["/policy/email-print"]);
        }
    }

    public onClickPay(): void {
            if (this.productInfo.creationProcess === "renewal" && this.productInfo.fromLink != 'payment') {
                this.jsonDataService.contorlLoading(true);
                this.apiCallService.getRenewalInfo({
                    policyId: this.productInfo.policyId,
                    action: 'pay'
                }).subscribe((response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.securityService.hasValidCsrfToken(response, () => {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                sessionStorage.setItem("rid", res.recordId);

                                // Check Report Type
                                if (this.productInfo.lineCd === "MC") {
                                    res.reportName = "BMR002";
                                } else {
                                    if(this.productInfo.clientCd=='BPIMS'){
                                        res.reportName = "BMR011";
                                    }
                                    else {
                                        if(this.productInfo.agentCd==this.renewalAgentCd){
                                            res.reportName = 'BMR008';
                                        }
                                        else{
                                            res.reportName = 'BMR010';
                                        }
                                    }
                                }

                                this.apiCallService.printPDF({
                                    reportType: res.reportName,
                                    policyId: this.productInfo.policyId
                                }).subscribe((data: any) => {
                                    if (data.status === 'SUCCESS') {
                                        let response = JSON.parse(this.jsonDataService.decrypt(data.content));
                                        this.jsonDataService.contorlLoading(false);
                                        res.folderName = response.link.split("/")[3];
                                        this.productService.setProductInfo2(res);
                                        this.productService.setProductInfo("creationProcess", "renewal");
                                        // ? put fromLink again because it is overwritten by setProductInfo2
                                        this.productService.setProductInfo("fromLink", this.productInfo.fromLink ?? null);
                                        this.productInfo = this.productService.productInfo;

                                        if (this.tinPattern.test(this.productInfo.customerInfo.tinNo)==false || this.productInfo.customerInfo.riskProfilingDetail.value == 1 || this.productInfo.customerInfo.blockedTag === "Y" || this.productInfo.customerInfo.totalLossTag === "Y" || this.productInfo.polStatCd == 7 || this.productInfo.polStatCd == 8 || this.productInfo.customerInfo == 9 || this.productInfo.customerInfo == 7 || this.productInfo.idExpiryDate!=='N' || (this.productInfo?.vehicleInfo?.cocNo==null&&this.productInfo.lineCd =='MC'&&this.productInfo.sublineCd=='CTPL')) {
                                            //Checks if there are missing KYC Info else Show the default message
                                            if(this.productInfo.customerInfo.blockedTag === "Y"){
                                                if(this.productInfo.customerInfo.renStatCd==9){
                                                    this.dialogBoxService.open({
                                                        messageType: "I",
                                                        content: 'Your policy is already expired and blocked for payment. You may contact BPI MS for more information.',
                                                        buttons: [{
                                                            text: "Contact BPI MS",
                                                            action: () => {
                                                                this.router.navigate([]).then(result => {  window.open('https://www.bpims.com/contact/contact-information', '_blank'); });
                                                            }
                                                        }, {
                                                            text: "Close"
                                                        }]
                                                    });
                                                }
                                                else{
                                                    this.dialogBoxService.open({
                                                        messageType: "I",
                                                        content: 'Payment is subject for review and approval. One of our representatives will contact you as soon as possible or you may contact BPI MS for more information.',
                                                        buttons: [{
                                                            text: "Contact BPI MS",
                                                            action: () => {
                                                                this.router.navigate([]).then(result => {  window.open('https://www.bpims.com/contact/contact-information', '_blank'); });
                                                            }
                                                        }, {
                                                            text: "Close"
                                                        }]
                                                    });
                                                }
                                                
                                            }
                                            else if(this.productInfo.customerInfo.riskProfilingDetail.value == 1){
                                                this.jsonDataService.contorlLoading(false);
                                                this.messageBoxService.show1("Error", this.productInfo.riskErrorMessage||"There's something wrong getting the records. Please contact BPI MS for more information.", "Ok", "");
                                            }
                                            else if(this.productInfo.checkKyc == 'Y'||this.tinPattern.test(this.productInfo.customerInfo.tinNo)==false||this.productInfo.customerInfo == 9||this.productInfo.customerInfo == 7){
                                                this.dialogBoxService.open({
                                                    messageType: "E",
                                                    content: this.missingKYCInfo,
                                                    buttons: [{
                                                        text: "View & Update Personal Info",
                                                        action: () => {
                                                            this.gotoKYC();
                                                        }
                                                    },{ text: "Close" }]
                                                });
                                            }
                                            else{
                                                if (this.productInfo.lineCd === "MC") {
                                                    if(this.productInfo.sublineCd=='CTPL'&&this.productInfo?.vehicleInfo?.cocNo==null){
                                                        this.dialogBoxService.open({
                                                            messageType: "E",
                                                            content: "Unable to generate COC Number. Please check the contract type of this policy.",
                                                            buttons: [{ text: "Close" }]
                                                        });
                                                    }
                                                    else{
                                                        let vehicleInfo = this.productService.productInfo.vehicleInfo;
                                                        this.dialogBoxService.open({
                                                            messageType: "I",
                                                            content: 'Payment is subject for review and approval. One of our representatives will contact you as soon as possible or you may contact BPI MS for more information.',
                                                            buttons: [{
                                                                text: "Contact BPI MS",
                                                                action: () => {
                                                                    const url = this.router.serializeUrl(
                                                                        this.router.createUrlTree(["/contact-us"], {
                                                                            queryParams: {
                                                                                brand: vehicleInfo.makeDetail.text,
                                                                                yearModel: vehicleInfo.yearModelDetail.value,
                                                                                model: vehicleInfo.modelDetail.text + " " + vehicleInfo.subModelDetail.text,
                                                                                srp: vehicleInfo.purchasePrice
                                                                            }
                                                                        })
                                                                    );
                                                                    this.router.navigateByUrl(url);
                                                                }
                                                            }, {
                                                                text: "Close"
                                                            }]
                                                        });
                                                    }
                                                } 
                                                else {
                                                    this.dialogBoxService.open({
                                                        messageType: "I",
                                                        content: 'Payment is subject for review and approval. One of our representatives will contact you as soon as possible or you may contact BPI MS for more information.',
                                                        buttons: [{
                                                            text: "Contact BPI MS",
                                                            action: () => {
                                                                const url = this.router.serializeUrl(
                                                                    this.router.createUrlTree(["/contact-us"], {})
                                                                );
                                                                this.router.navigateByUrl(url);
                                                            }
                                                        }, {
                                                            text: "Close"
                                                        }]
                                                    });
                                                }
                                            }
                                        } else {
                                            this.checkCocaf();
                                        }
                                    } else {
                                        this.jsonDataService.contorlLoading(false);
                                        this.messageBoxService.show1("Error", "There's something wrong getting the records. Please contact BPI MS for more information.", "Ok", "");
                                    }
                                    
                                });
                            });
                        }
                        else{
                            this.jsonDataService.contorlLoading(false);
                            this.messageBoxService.show1("Error", "There's something wrong getting the records. Please contact BPI MS for more information.", "Ok", "");
                        }
                    });
                });
            } else {
                this.router.navigate(["/policy/payment"]);
            }
    }

    toDashboard(): void {
        if(this.userDetails.userId){
            this.router.navigate(["/dashboard"]);
        }else{
            this.router.navigate(['/'])
        }
    }

    plateNoCheck:boolean = false;
    engineNoCheck:boolean = false;
    chassisNoCheck:boolean = false;
    mvFileNoCheck:boolean = false;

    checkCocaf(){
        if(this.productInfo?.vehicleInfo?.cocNo!="S"&&this.productInfo.checkCocaf=="Y"&&this.productInfo.creationProcess=="renewal"&&this.productInfo.fromLink!="payment"){
            if(this.checkVehicleDetails()){
                this.apiCallService.checkRenewalCocaf({
                    policyId: this.productInfo.policyId,
                    userId: this.userDetailsService.userId
                }).subscribe((response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (response.status === "SUCCESS") {
                                if (res.noCocafCheck) {
                                    this.jsonDataService.contorlLoading(false);
                                    this.router.navigate(["/policy/payment"]);
                                } else {
                                    if(res.errorMessage){
                                        this.jsonDataService.contorlLoading(false);
                                        this.messageBoxService.show1("Error", res.errorMessage, "Ok", () => {
                                            this.vehicleDetail.open('Back');
                                        });
                                    }
                                    else{
                                        if (res.cocNo && res.cocAuthNo) {
                                            this.jsonDataService.contorlLoading(false);
                                            this.messageBoxService.show1("Success", "You have successfully generated your COC No. " + res.cocNo + " and COC Authentication No. " + res.cocAuthNo + ". Please click the Pay button again to proceed.", "Ok", "");    
                                        }
                                        else{
                                            this.jsonDataService.contorlLoading(false);
                                        }
                                    }
                                }
                            } else {
                                this.jsonDataService.contorlLoading(false);
                                this.messageBoxService.show1("Error", res.errorMessage, "Ok", "");
                            }
                        });
                    });
                });
            }
            else{
                this.jsonDataService.contorlLoading(false);
                this.vehicleDetail.open('Back');
            }
        }
        else{
            this.jsonDataService.contorlLoading(false);
            this.router.navigate(["/policy/payment"]);
        }
    }
    
    showVehicleDetailsForm(){
        return this.vehicleDetail.open('Back');
    }
    checkVehicleDetails(){
        return this.productInfo.vehicleInfo.plateNo&&this.productInfo.vehicleInfo.engineNo&&this.productInfo.vehicleInfo.chassisNo&&this.productInfo.vehicleInfo.mvFileNo&&this.productInfo.vehicleInfo.mvFileNo.length>=this.mvFileNoLength;
    }
    setVehicleDetailForm(){
        if(this.productInfo.creationProcess=="renewal"&&this.productInfo.fromLink!="payment"){
            this.productInfo.vehicleInfo.plateNo = this.productInfo?.vehicleInfo?.plateNo?.replace(/\s/g, "");
            this.productInfo.vehicleInfo.engineNo = this.productInfo?.vehicleInfo?.engineNo?.replace(/\s/g, "");
            this.productInfo.vehicleInfo.chassisNo = this.productInfo?.vehicleInfo?.chassisNo?.replace(/\s/g, "");
            this.productInfo.vehicleInfo.mvFileNo = this.productInfo?.vehicleInfo?.mvFileNo?.replace(/\s/g, "");
            this.plateNoCheck = this.productInfo.vehicleInfo.plateNo=='';
            this.engineNoCheck = this.productInfo.vehicleInfo.engineNo=='';
            this.chassisNoCheck = this.productInfo.vehicleInfo.chassisNo=='';
            this.mvFileNoCheck = this.productInfo.vehicleInfo.mvFileNo==''||this.productInfo?.vehicleInfo?.mvFileNo?.length!=this.mvFileNoLength;
            this.productService.setProductInfo2(this.productInfo);
    
            this.vehicleForm.patchValue({
                plateNo: this.productInfo.vehicleInfo.plateNo,
                engineNo: this.productInfo.vehicleInfo.engineNo,
                chassisNo: this.productInfo.vehicleInfo.chassisNo,
                mvFileNo: this.productInfo.vehicleInfo.mvFileNo
            });
        }
    }
    updateVehicleDetails(plateNo:any, engineNo:any, chassisNo:any, mvFileNo:any){
        if(plateNo&&engineNo&&chassisNo&&mvFileNo){
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.updateVehicleDetails({
                policyId: this.productInfo.policyId,
                plateNo: plateNo.toUpperCase(),
                engineNo: engineNo.toUpperCase(),
                chassisNo: chassisNo.toUpperCase(),
                mvFileNo: mvFileNo.toUpperCase()
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.jsonDataService.contorlLoading(false);
                        this.productInfo.vehicleInfo.plateNo = plateNo;
                        this.productInfo.vehicleInfo.engineNo = engineNo;
                        this.productInfo.vehicleInfo.chassisNo = chassisNo;
                        this.productInfo.vehicleInfo.mvFileNo = mvFileNo;
                        this.setVehicleDetailForm();    
                        this.vehicleDetail.close();
                        this.messageBoxService.show1("Success", "You have successfully updated your vehicle details.", "Ok", () => {
                            this.jsonDataService.contorlLoading(true);
                            this.checkCocaf();
                        });                                                         
                    }
                });
            });
        }
        else{
            this.vehicleDetail.close();
            this.appMessageService.showAppMessage("There's something wrong saving the vehicle detail. Please contact BPI MS for more information.", "error");
        }
    }

    /*
    checkMvFileNoLength() {
        if (this.vehicleForm.controls['mvFileNo'].value.length < this.mvFileNoMinLength) {
                this.vehicleForm.controls["mvFileNo"]?.setErrors({"incorrect" : true});
        } else {
            this.vehicleForm.controls["mvFileNo"]?.setErrors(null);
        }
    }
    */

    //Obsolete Saving Function
    onClickSave() {
        if(this.productService.productInfo.creationProcess == 'renewal' && this.form.invalid){
            this.form.updateValueAndValidity();
            this.dialogBoxService.open({
                messageType: "E",
                content: this.missingKYCInfo,
                buttons: [{
                    text: "View & Update Personal Info",
                    action: () => {
                        this.gotoKYC();
                    }
                },{ text: "Close" }]
            });
            return;
        }
        this.jsonDataService.contorlLoading(true);
        const form = this.form.getRawValue();
        const request = {
            policyId: this.productService.productInfo.policyId,
            compAdd: form.address,
            emailAdd: form.email,
            mobileNo: form.mobileNo,
            telNo: form.telNo,
            maritalStat: form.maritalStat.value,
            provOrCity: form.provOrCity.provCd,
            city: form.provOrCity.value,
            postCode: form.postCode.value
        };

        this.apiCallService.saveRenwalCustomerInfo(request).subscribe((response:any)=>{
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.productInfo.policyHolderDetails.policy.policyAddress = this.form.get('address')?.value;
                    this.productInfo.customerInfo.emailAddress = this.form.get('email')?.value;
                    this.productInfo.assdEmailAddress = this.form.get('email')?.value;
                    this.productInfo.customerInfo.mobileNo = this.form.get('mobileNo')?.value;
                    this.productInfo.customerInfo.telNo = this.form.get('telNo')?.value;
                    this.productInfo.customerInfo.maritalDetail = this.form.get('maritalStat')?.value;
                    this.productInfo.customerInfo.permanentCityDetail = this.form.get('provOrCity')?.value;
                    this.productInfo.customerInfo.permanentPostalDetail = this.form.get('postCode')?.value;
                    this.productInfo.customerInfo.permanentAddress = this.form.get('address')?.value;
                    this.productService.setProductInfo2(this.productInfo);
                    this.appMessageService.showAppMessage("Successfuly saved.", "success");
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            });
            this.jsonDataService.contorlLoading(false);
        })
    }

    // SELECT FIELD FUNCTIONS

    setMaritalPlaceholder(): string {
        if (typeof this.form.controls["maritalStat"].value !== "object"
            || this.formService.isInvalid(this.form, "maritalStat")) {
            return "Please select Marital Status";
        } else {
            return "Marital Status";
        }
    }
    getMaritalStat(): any[] {
        let marStat: any[] = [];
        for (let marital of this.jsonDataService.data.maritalStat.filter((content: { bmDesc: null; }) => content.bmDesc != null && content.bmDesc != '')) {
            marStat.push({
                value: marital.maritalStatCd,
                text: marital.bmDesc
            });
        }
        marStat.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return marStat;
    }
    setMaritalViaInput(): void {
        for (const marital of this.getMaritalStat()) {
            if (marital.text === this.form.controls["maritalStat"].value
                || marital.value === this.form.controls["maritalStat"].value.value) {
                this.form.controls["maritalStat"].setValue(marital);
            }
        }
    }
    setMaritalViaOption(option: any): void {
        const optionEl = option._element.nativeElement;
    }

    setProvPlaceholder(): string {
        let placeholder = "";
        if (typeof this.form.controls["provOrCity"].value !== "object"
            || this.formService.isInvalid(this.form, "provOrCity")) {
            placeholder = "Please select Province or City";
        } else {
            placeholder = "Province or City";
        }
        return placeholder;
    }
    getProvince(): any[] {
        let provCity: any[] = [];
        let name = "";
        let country = "";
        let prov = "";
        let cityCd = "";

        country = this.productInfo.customerInfo.countryOfBirthOriginDetail?.value;
        prov = this.form.controls["postCode"].value.provCd || "";
        cityCd = this.form.controls["postCode"].value.cityCd || "";

        if ((prov === "") && (cityCd === "")) {
            for (let prov of this.jsonDataService.data.province) {
                for (let city of this.jsonDataService.data.city.filter((a: any) => a.provinceCd === prov.provinceCd && a.countryCd === prov.countryCd && a.countryCd === Number(country))) {
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", prov.provinceName),
                        provCd: city.provinceCd,
                        countryCd: city.countryCd
                    });
                }
            }
        } else {
            for (let province of this.jsonDataService.data.province) {
                for (let city of this.jsonDataService.data.city.filter((a: any) => a.provinceCd === prov && a.countryCd === Number(country) && a.cityCd === Number(cityCd) && a.provinceCd === province.provinceCd && a.countryCd === province.countryCd)) {
                    provCity.push({
                        value: city.cityCd,
                        text: name.concat(city.cityName, ", ", province.provinceName),
                        provCd: city.provinceCd,
                        countryCd: city.countryCd
                    });
                }
            }
        }

        provCity.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return provCity;
    }
    setProvViaInput(): void {
        for (const prov of this.getProvince()) {

            if (prov.text === this.form.controls["provOrCity"].value
            /*|| prov.value === this.form.controls["provOrCity"].value.value*/) {
                this.form.controls["provOrCity"].setValue(prov);
            }
            if (this.form.controls["provOrCity"].value === "") {
                this.form.patchValue({ postCode: { value: "", text: "", provCd: "", countryCd: "", cityCd: "" } });
                this.resetProv();
            }
        }

        this.resetPostal();
    }
    setProvViaOption(option: any): void {
        const optionEl = option._element.nativeElement;
        this.resetPostal();
    }

    resetProv(): void {
        this.filteredProvince = this.autocompleteService.set(this.form, 'provOrCity', this.getProvince());
        if (this.getProvince().length === 1) {
            this.form.controls['provOrCity'].setValue(this.getProvince()[0]);
        }
    }
    //Postal Code
    setPostalPlaceholder():string {
        let placeholder = "";
        if (   typeof this.form.controls["postCode"].value !== "object"
            || this.formService.isInvalid(this.form, "postCode")) {
            placeholder = "Please select Postal Code";
        } else {
            placeholder = "Postal Code";
        }
        return placeholder;
    }
    getPostal():any[] {
        let postal: any[] = [];
        let postName = "";
        let country = "";
        let prov = "";
        let city = "";
        country = this.productInfo.customerInfo.countryOfBirthOriginDetail?.value;
        prov = this.form.controls["provOrCity"].value.provCd || "";
        city = this.form.controls["provOrCity"].value.value || "";
        
        if (prov === "" && city === "") {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        } else {
            for (let post of this.jsonDataService.data.postalCds.filter((a:any) => a.countryCd === Number(country)
                 && a.provinceCd === Number(prov) && a.cityCd === Number(city))) {
                    postal.push({
                        value: post.postalCd,
                        text: postName.concat(post.bmCode, ' - ', post.postalDesc),
                        provCd: post.provinceCd,
                        countryCd: post.countryCd,
                        cityCd: post.cityCd
                    });
            }
        }

        postal.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return postal;
    }
    setPostalViaInput():void {
        for (const post of this.getPostal()) {
            if (   post.text === this.form.controls["postCode"].value
                || post.value === this.form.controls["postCode"].value.value) {
                    this.form.controls["postCode"].setValue(post);
            }
        }
        this.resetProv();
    }
    setPostalViaOption(option:any):void {
        const optionEl = option._element.nativeElement;
        this.resetProv();

    }
    resetPostal():void{
        this.filteredPostal = this.autocompleteService.set(this.form, 'postCode', this.getPostal());
        if (this.getPostal().length === 1) {
            this.form.controls['postCode'].setValue(this.getPostal()[0]);
        }
    }
        
    commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = control.value?.split(',').map((e: any) => e.trim());
        const forbidden = emails?.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { 'email': { value: control.value } } : null;
    };
    
    emailForm = this.formBuilder.group({
        recipient: ['', [Validators.required, this.commaSepEmail]],
        cc: ['', this.commaSepEmail],
        subject: ['', [Validators.required]],
        emailContent: ['', [Validators.required]],
    });

    emailSent: boolean = false;
    datePipe = new DatePipe('en-us');
    
    sendMail(): void {
        this.jsonDataService.contorlLoading(true);
        const id = this.productInfo.policyId;
        let element = '';
        if(this.productInfo.reportName){
            element = this.productInfo.reportName;
        }
        else{
            if(this.productInfo.clientCd=='BPIMS'){
                this.productInfo.reportName = "BMR011";
            }
            else {
                if(this.productInfo.agentCd==this.renewalAgentCd){
                    this.productInfo.reportName = 'BMR008';
                }
                else{
                    this.productInfo.reportName = 'BMR010';
                }
            }
            element = this.productInfo.reportName;
        }

        const policyNumber = this.productInfo.policyNo;
        let oldExpiryDate = "N/A"; // Nilagay ko lang po para di magerror kung null yung oldExpiryDate
        if(this.productInfo.oldExpiryDate){
            oldExpiryDate = this.datePipe.transform(new Date(this.productInfo.oldExpiryDate), 'MMMM d, YYYY') || "N/A";
        }
        const polNo = 'XXXX' + policyNumber.substring(policyNumber.length-4, policyNumber.length);
        const assdFirstName = this.productInfo.customerInfo.firstName;
        const emailAddress = this.productInfo.customerInfo.emailAddress;
        // var startLink = '<div style="display:flex"><div style="display:inline-flex; background:#B11116; padding:10px; border-radius:10px; font-weight:bold;"><a style="text-decoration:none; -webkit-user-select: none;"><span style="color:#FFF;">';
        // var endLink = '</span></a></div></div>';
        var pLink = '<div style="display:flex; padding-left:20%; padding-right:20%">P_REN_LINK</div>';


        // LINK
        const encryptedParams = this.jsonDataService.encrypt({
            policyId: this.productInfo.policyId,
            requestId: this.productInfo.requestId
        });
        // const link = 'https://' + window.location.hostname + '/renewal?params=' + `${btoa(encryptedParams)}`;
        const link = 'https://' + window.location.hostname + '/renewal-and-payments';

        const cc = '';
        let emailType:number = 5;
        
        if(element=="BMR008"){
            emailType = 4;
        }
        else if(element=="BMR010"){
            emailType = 5;
        }
        else if(element=="BMR011"){
            emailType = 6;
        }
        let email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
            policyEmail.lineCd == this.productInfo.lineCd &&
            policyEmail.sublineCd == this.productInfo.sublineCd &&
            policyEmail.emailType == 5)[0];
        let header = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
            policyEmail.lineCd == this.productInfo.lineCd &&
            policyEmail.sublineCd == this.productInfo.sublineCd &&
            policyEmail.emailType == 5)[0]?.headerImgPath;
        header = "https://"+ window.location.hostname + header;
        header = header.replace(/\\/g,"/");
        let emailSubject = email.emailSubject.split('P_ASSURED_NAME').join(assdFirstName);

        let emailBody = email.emailBody.split('P_POL_NO').join(polNo)
        emailBody = emailBody.split('P_OLD_EXP_DATE').join(oldExpiryDate);
        emailBody = emailBody.split('P_REN_LINK').join(pLink);
        emailBody = emailBody.split('P_ASSURED_NAME').join(assdFirstName);
        emailBody = emailBody.split('P_PLAN_NAME').join(this.productInfo.planName);
        emailBody = emailBody.split('P_DOC_NAME').join(this.productInfo.docName);
        emailBody = emailBody.split('P_RISK_NAME').join(this.productInfo.insuredRisk);
        emailBody = emailBody.split('P_PAYT_REF_NO').join(this.productInfo.paymentInfo.paytRefNo);
        
        this.emailForm.patchValue({ 
            subject: emailSubject, 
            emailContent: emailBody,
            recipient: emailAddress,
            cc: cc
        });
        let folderName = '';

        let smsContent = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
        policyEmail.lineCd == this.productInfo.lineCd &&
        policyEmail.sublineCd == this.productInfo.sublineCd &&
        policyEmail.emailType == 9)[0];
        
        smsContent = smsContent.emailBody.split('P_ASSURED_NAME').join(this.productInfo.customerInfo.firstName);
        smsContent = smsContent.split('P_OLD_EXP_DATE').join(oldExpiryDate);
        smsContent = smsContent.split('P_LINK').join(link);
        smsContent = smsContent.split('P_POL_NO').join(polNo);
        smsContent = smsContent.split('P_RISK_NAME').join(this.productInfo.insuredRisk);
        smsContent = smsContent.split('P_PAYT_REF_NO').join(this.productInfo.paymentInfo.paytRefNo);
        smsContent = smsContent.split('P_PLAN_NAME').join(this.productInfo.planName);

        let smsParamsArr:any = [
            {
            mobileNo: this.productInfo.customerInfo.mobileNo,
            message: smsContent,
            countryCd: this.smsCountryCd[0].countryCd
            }
        ];

        for (var i = 0; i < smsParamsArr.length; i++) {
            const el = smsParamsArr[i];
            var smsCode = this.smsCountryCd[0]; 

            el.mobileNo = el.mobileNo.trim();

            if(el.mobileNo.charAt(0) == '0') {
            el.mobileNo = smsCode.smsCountryCd + (el.mobileNo.substr(el.mobileNo.indexOf(smsCode.prefix) + smsCode.prefix.length, el.mobileNo.length - smsCode.prefix.length));
            } else if(el.mobileNo.charAt(0) == '+') {
            el.mobileNo = el.mobileNo.substr(1, el.mobileNo.length);
            }
        }
        if(this.productInfo.folderName){
            folderName = this.productInfo.folderName;
            //SEND MAIL
            this.apiCallService.sendMail({
                ...this.emailForm.getRawValue(), 
                policyId: this.productInfo.policyId,
                emailType: emailType,
                userId: this.userDetailsService.userId,
                lineCd: this.productInfo.lineCd,
                sublineCd: this.productInfo.sublineCd,
                folderName: folderName,
                header: header
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if(this.productInfo.polStatCd==3){
                            this.apiCallService.updatePolicyStatus({
                                policyId: this.productInfo.policyId,
                                newStatus: 6,
                                renStatus: 4
                            }).subscribe((response:any) => {
                                this.securityService.checkRequestKeyResponse(response, () => {
                                        if (response.status === "SUCCESS") {
                                            this.jsonDataService.contorlLoading(false);
                                            this.sendNotice = false;
                                            this.productInfo.polStatDesc = 'Sent Notice';
                                            this.productInfo.polStatCd = 6;
                                            this.stepperDtlService.stepperDtlBtns = [
                                                { class: "btn4", label: "Back", action: "toDashboard" },
                                                { class: "btn2", label: "Print Policy", action: "onClickPrint", actionParam: "policy", isShow: this.printPolButton ? "true" : "false" },
                                                { class: "btn2", label: "Send Notice", action: "sendMail", isShow: this.sendNotice ? "true" : "false" },
                                                { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false" }
                                            ];

                                            this.sendSms(smsParamsArr);
                                        } else {
                                            this.jsonDataService.contorlLoading(false);
                                            this.messageBoxService.show1("Error", "Your email was not sent.", "Ok", "");
                                        }
                                });
                            });
                        }
                        else {
                            if (response.status === "SUCCESS") {
                                this.sendSms(smsParamsArr);
                            } else {
                                this.jsonDataService.contorlLoading(false);
                                this.messageBoxService.show1("Error", "Your email was not sent.", "Ok", "");
                            }
                        }
                    });
                });
            });
        }
        else{
            this.apiCallService.printPDF({
                reportType: element,
                policyId: id
            }).subscribe((res:any) => {
                if (res.status === 'SUCCESS') {
                    let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                    folderName = response.link.split("/")[3];
            
                    //SEND MAIL
                    this.apiCallService.sendMail({
                        ...this.emailForm.getRawValue(), 
                        policyId: this.productInfo.policyId,
                        emailType: emailType,
                        userId: this.userDetailsService.userId,
                        lineCd: this.productInfo.lineCd,
                        sublineCd: this.productInfo.sublineCd,
                        folderName: folderName,
                        header: header
                    }).subscribe((response:any) => {
                        this.securityService.checkRequestKeyResponse(response, () => {
                            this.securityService.hasValidCsrfToken(response, () => {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                if(this.productInfo.polStatCd==3){
                                    this.apiCallService.updatePolicyStatus({
                                        policyId: this.productInfo.policyId,
                                        newStatus: 6,
                                        renStatus: 4
                                    }).subscribe((response:any) => {
                                        this.securityService.checkRequestKeyResponse(response, () => {
                                                if (response.status === "SUCCESS") {
                                                    this.sendNotice = false;
                                                    this.productInfo.polStatDesc = 'Sent Notice';
                                                    this.productInfo.polStatCd = 6;
                                                    this.stepperDtlService.stepperDtlBtns = [
                                                        { class: "btn4", label: "Back", action: "toDashboard" },
                                                        { class: "btn2", label: "Print Policy", action: "onClickPrint", actionParam: "policy", isShow: this.printPolButton ? "true" : "false" },
                                                        { class: "btn2", label: "Send Notice", action: "sendMail", isShow: this.sendNotice ? "true" : "false" },
                                                        { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false" }
                                                    ];

                                                this.sendSms(smsParamsArr);
                                                } else {
                                                    this.jsonDataService.contorlLoading(false);
                                                    this.messageBoxService.show1("Error", "Your email was not sent.", "Ok", "");
                                                }
                                        });
                                    });
                                }
                                else {
                                    if (response.status === "SUCCESS") {
                                        this.sendSms(smsParamsArr);
                                    } else {
                                        this.jsonDataService.contorlLoading(false);
                                        this.messageBoxService.show1("Error", "Your email was not sent.", "Ok", "");
                                    }
                                }
                            });
                        });
                    });
                } else {
                    this.appMessageService.showAppMessage("There's something wrong getting the records. Please contact BPI MS for more information.", "error");
                }
            
            });
        }
    }

    sendSms(smsParamsArr:any){
        this.apiCallService.sendSms(smsParamsArr).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
                if (data.status === "SUCCESS") {
                    this.jsonDataService.contorlLoading(false);
                    this.messageBoxService.show1("Success", "Your email and sms was sent successfully.", "Ok", "");
                }
                else {
                    this.jsonDataService.contorlLoading(false);
                    this.messageBoxService.show1("Success", "Your email was sent successfully.", "Ok", "");
                }
            });
        });
    }
    gotoKYC(){
        this.router.navigate(["/policy/customer-information"]);
    }

    contactBPI(){
        const url = this.router.serializeUrl(
            this.router.createUrlTree(["/contact-us"], {})
        );
        this.router.navigateByUrl(url);
    }

    updateIsAccepted(event: any): void {
        this.isAccepted = event;
        if (this.productInfo.fromLink) {
            this.stepperDtlService.stepperDtlBtns[3] = { class: "btn2", label: "Pay", action: "onClickPay", actionParam: "payment", isShow: this.payButton ? "true" : "false", disabled: !this.isAccepted };
        }
    }

    evaluateGetPdf(): void {
        if (this.productInfo.lineCd === 'PA' 
        && this.productInfo.sublineCd === 'IND'
        && this.productInfo.coverageInfo.grpTag === 'Y') {
            this.getPdf('insuringtermsgrp');
        }
        else {
            this.getPdf('insuringterms');
        }
    }

    getPdf(pdfName: string): void {
        if (pdfName === "insuringterms") {
            window.open(this.insuringTerms);
        }
        else if (pdfName === "insuringtermsgrp") {
            window.open(this.insuringTermsGrp);
        }
    }

    //para sa policy cancellation
    validateCancellationText(): void {
        const userType = this.userDetails?.accountType;
        const userId = this.userDetails?.userId;
        this.jsonDataService.data.BMM162.map((a: any) => {
            if(a?.userType === userType) {
                this.validCancellationText = true;
            }
        });
        this.jsonDataService.data.BMM163.filter((a: any) => a.tranCd === "UPC").map((b: any) => {
            if(b?.userId === userId) {
                this.validCancellationText = true;
            }
        });
    }

    //Para sa Cancellation ng Policy
    getReasons():any[] {
        let reasonOptions = [];
        for (let reason of this.jsonDataService.data.cancelReason) {
            reasonOptions.push({
                value: reason.reasonCd,
                text: reason.reasonDesc
            });
        }
        reasonOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
        return reasonOptions;
    }

    //Para sa Cancellation ng Policy
    setReasonViaInput(event:any):void {
        this.resetReason();
        for (const reason of this.getReasons()) {
            if (reason.text === this.cancellationForm.controls["reason"].value) {
                this.cancellationForm.controls["reason"].setValue(reason);
            }
        }
    }
    
    //Para sa Cancellation ng Policy
    resetReason():void {
        this.cancellationForm.controls["reason"].setValue("");
    }

    //Para sa Cancellation ng Policy
    cancelPolicy(reasonCd:string) {
        if (!reasonCd) {
            this.appMessageService.showAppMessage("Please pick a valid reason for your cancellation", "error");
        } else {
            this.proceedCancelPolicy(reasonCd);
        }
    }

    //Para sa Cancellation ng Policy
    proceedCancelPolicy(reasonCd:string) {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.cancelPolicy({
            quoteId: this.productInfo.quoteId,
            policyId: this.productInfo.policyId,
            reasonCd: parseInt(reasonCd)
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.cancellationForm.markAsPristine();
                    this.router.navigate(["/dashboard"]);
                    this.appMessageService.showAppMessage("Your policy is now cancelled", "success");
                } else {
                    this.resetReason();
                    this.appMessageService.showAppMessage("There is an error in cancelling your policy", "error");
                }
            });
        });
    }


    viewFile(doc:any, from:String): void{
        let subDir = '';
        let id = '';
        if(from=='detail'){
            id = this.productInfo.quoteId+'/1';
            if(this.productInfo.lineCd == 'MC'){
                subDir = 'supporting-docs/vehicle-details';
            }else if(this.productInfo.lineCd == 'HA'){
                subDir = 'supporting-docs/property-details';
            }
        }else if(from =='holder'){
            id = this.productInfo.customerInfo.assdNo;
            subDir = 'supporting-docs/customer-information';
        }else if(from == 'id'){
            id = this.productInfo.customerInfo.assdNo;
            subDir = 'attachments';
        }
        this.jsonDataService.contorlLoading(true);
        
        this.apiCallService.getPresignedUrl({
            action: "getObject",
            directory: `${subDir}/${id}`,
            filename: doc.filename
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.jsonDataService.contorlLoading(false);
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        this.downloadService.downloadFileUsingPresignedUrl(res.presignedUrl, doc.filename);
                    });
                }
            });
        });
    }

    insuredCol1:any[]= [];
    insuredCol2:any[]= [];
    longerRow:any[] = [];
    total:number = 0;
    displayedCurrency: string = "";

    buildAmountInsuredHA():void{
        let bldgItems = this.productInfo.bldgForm?.bldgItms?.filter((i:any)=> i.bldgAmt);
        let contItems = this.productInfo.contForm?.contItms?.filter((i:any)=> i.contAmt);

        let contents:any[] = [];

        if(bldgItems || contItems){
            let itemNo = 0;
            contents = bldgItems.concat(contItems).sort((a:any,b:any)=>a.seqNo-b.seqNo).map((i:any)=>{
                itemNo += 1;
                return {
                    amt : i.bldgAmt || i.contAmt,
                    itmCd : i.bldgItmCd || i.contItmCd,
                    itemNo: itemNo,
                    curr: getCurrencySymbol(i.contCurrency, 'narrow') || getCurrencySymbol(i.bldgCurrency, 'narrow')
                };
            });
        }else{
            contents = this.productInfo.bldgCont
                .sort((a:any,b:any)=>a.itemNo - b.itemNo)
                .map((i:any)=>{
                    return {amt : i.bldgAmt,
                            itmCd : i.bldgItmCd,
                            itemNo: i.itemNo,
                            curr: getCurrencySymbol(i.bldgCurrency, 'narrow')}
                });
        }

        let mtnContents:any[] = this.jsonDataService.data.firePlanItemCd.filter((b:any)=>b.lineCd == this.productInfo.lineCd && b.sublineCd == this.productInfo.sublineCd && b.planCd == this.productInfo.planInfo.planCd)

        mtnContents.forEach(a=>{
            let content = contents.find(b=>a.itemCd == b.itmCd) || {curr: getCurrencySymbol(this.productInfo.planInfo.currencyCd, 'narrow')};
            content.label = this.jsonDataService.data.fireItemCd.find((b:any)=>b.itemCd == a.itemCd).itemLabel;
            
            a.colGrp == 1 ? this.insuredCol1.push(content) : this.insuredCol2.push(content);
            this.total += parseFloat(content.amt || 0)
        });
        this.longerRow = this.insuredCol1.length > this.insuredCol2.length ? this.insuredCol1 : this.insuredCol2;
    }
}