import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { PopupService } from 'src/app/services/popup.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent implements OnInit {

  pdfUrl: string = '';
  pdfTitle: string = '';

  dialogRef!: MatDialogRef<any>;
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;

  constructor(public dialog: MatDialog, popupService: PopupService, private jsonDataService: JsonDataService, private productService: ProductService) {
    popupService.setPopUpComponent(this);
  }

  ngOnInit(): void {
  }

  open(screenCd: String):void{
    let {lineCd, sublineCd} = this.productService.productInfo;
    let planCd = this.productService.productInfo.planInfo?.planCd;
    let params = this.jsonDataService.data.popup.filter((d:any)=> d.lineCd == lineCd && d.sublineCd == sublineCd && d.planCd == planCd && d.screenCd == screenCd)[0];
    this.pdfTitle = params?.notifTitle;
    this.pdfUrl = params?.notifPath;
    if(!this.pdfUrl) {
      return;
    }

    this.dialogRef = this.dialog.open(this.templateRef, {
      data: { animal: 'panda' }
    }).addPanelClass(['osp-overlay', 'osp-popup']);
  }

}
