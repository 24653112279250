import { Component, OnInit } from '@angular/core';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {

  showLoading:Boolean = false;
  constructor(private jsonDataService: JsonDataService) {
    jsonDataService.loading$.subscribe(e=>{
      this.showLoading = e;
    })
  }

  ngOnInit(): void {

  }

}
