<section id="request-quotation">
    <div class="container arial">
        <div class="case top-border border4">
            <form [formGroup]="form">
                <div id="request-quotation-form">
                    <div class="form-fields">
                        <h2>Request for Quotation</h2>
                        <div class="form">
                            <div class="col-case">
                                <div class="field required">
                                    <label>Name</label>
                                    <mat-form-field appearance="fill">
                                        <input id="gray-rq" matInput formControlName="clientName" tabindex="1" uppercase>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'clientName')">
                                        {{formService.getFieldErrorMsg(form, "clientName")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case col-2">
                                <div class="field required">
                                    <label>Mobile Number</label>
                                    <mat-form-field appearance="fill">
                                        <input id="gray-rq" matInput formControlName="mobile" tabindex="2" mobileNo>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'mobile')">
                                        {{customErrorMsg.mobile}}
                                    </p>
                                </div>
                                <div class="field required">
                                    <label>Email</label>
                                    <mat-form-field appearance="fill">
                                        <input id="gray-rq" matInput formControlName="email" tabindex="3">
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'email')">
                                        {{formService.getFieldErrorMsg(form, "email")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case">
                                <div class="field">
                                    <label>Message</label>
                                    <mat-form-field appearance="fill">
                                        <textarea id="gray-rq" matInput rows="3" formControlName="message" tabindex="4"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        <h2 class="vehicle-label">Property Details</h2>
                            <div class="col-case">
                                <div class="field required">
                                    <label>Complete Address</label>
                                    <mat-form-field appearance="fill">
                                        <input id="gray-rq" matInput formControlName="compAddress" tabindex="5" uppercase>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'compAddress')">
                                        {{formService.getFieldErrorMsg(form, "compAddress")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case col-2">
                            <div class="field required">
                                <label>Country</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input id="gray-rq" matInput placeholder=" Country" formControlName="country" [matAutocomplete]="autoCountry" (input)="setCountryViaInput($event)" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                    <mat-autocomplete id="gray-rq" #autoCountry="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCountryViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredCountryOptions | async" [value]="option">{{option.text}}</mat-option>
                                    </mat-autocomplete>    
                                </mat-form-field>
                            </div>
                            <div class="field required">
                                <label>Province</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input id="gray-rq" matInput placeholder=" Province" formControlName="province" [matAutocomplete]="autoProvinceDetail" (input)="setProvinceViaInput($event)" autocomplete>
                                    <mat-autocomplete id="gray-rq" #autoProvinceDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvinceViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredProvinceOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'province')">
                                    {{formService.getFieldErrorMsg(form, "province")}}
                                </p>
                            </div>
                            </div>
                            <div class="col-case col-2">
                            <div class="field required">
                                <label>City</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input id="gray-rq" matInput placeholder="City" formControlName="city" [matAutocomplete]="autoCityDetail" (input)="setCityViaInput($event)" autocomplete>
                                    <mat-autocomplete id="gray-rq" #autoCityDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCityViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredCityOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'city')">
                                    {{formService.getFieldErrorMsg(form, "city")}}
                                </p>
                            </div>
                                <div class="field required">
                                    <label>Postal Code</label>
                                    <mat-form-field class="select" appearance="fill">
                                        <input id="gray-rq" matInput [placeholder]="setPostalPlaceholder('postCode')" formControlName="postCode" [matAutocomplete]="autoPostal" (input)="setPostalViaInput('postCode')" oninput="this.value = this.value.toUpperCase()" autocomplete>
                                        <mat-autocomplete id="gray-rq" #autoPostal="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setPostalViaOption($event.option, 'city')">
                                            <mat-option *ngFor="let option of filteredPostal | async" [value]="option" [attr.provCd]="option.provCd" [attr.countryCd]="option.countryCd" [attr.cityCd]="option.cityCd">{{option.text}}</mat-option>
                                        </mat-autocomplete>    
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'postCode')">
                                        {{formService.getFieldErrorMsg(form, "postCode")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case label">
                                <div class="field required">
                                    <label>Properties to be Insured</label>
                                </div>
                            </div>
                            <div class="col-case">
                                <div class="field">
                                    <label>Amount of Insurance Applied for</label>
                                    <div class="col-case col-2">
                                        <div class="field">
                                            <div class="checkbox bldg">
                                                <mat-checkbox (change)="chkBox($event, 'bldgInsuredAmt')" [checked]="bldg">Building</mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="col-case col-2">
                                                <div class="field currency" id="gray-rq">
                                                    <mat-form-field appearance="fill">
                                                        <input id="gray-rq" matInput formControlName="currency" class="currency-input">
                                                    </mat-form-field>
                                                </div>
                                                <div class="field currency-field" id="gray-rq">
                                                    <mat-form-field appearance="fill">
                                                        <input id="gray-rq" matInput class="amount-field" [placeholder]="setAmtPlaceholder()" (input)="chkAmt('bldg')" (blur)="chkSILimit()" maxlength="12" formControlName="bldgInsuredAmt" amount>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'bldgInsuredAmt')">
                                                        {{formService.getFieldErrorMsg(form, "bldgInsuredAmt")}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <label>&nbsp;</label>
                                    <div class="col-case col-2">
                                        <div class="field">
                                            <div class="checkbox cont">
                                                <mat-checkbox (change)="chkBox($event, 'contInsuredAmt')" [checked]="cont">Contents</mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="col-case col-2">
                                                <div class="field currency">
                                                    <mat-form-field appearance="fill">
                                                        <input id="gray-rq" matInput formControlName="currency" class="currency-input">
                                                    </mat-form-field>
                                                </div>
                                                <div class="field currency-field">
                                                    <mat-form-field appearance="fill">
                                                        <input id="gray-rq" matInput class="amount-field" [placeholder]="setAmtPlaceholder()" (input)="chkAmt('cont')" (blur)="chkSILimit()" maxlength="12" formControlName="contInsuredAmt" amount>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'contInsuredAmt')">
                                                        {{formService.getFieldErrorMsg(form, "contInsuredAmt")}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <label>&nbsp;</label>
                                    <div class="col-case col-2">
                                        <div class="field total-label-case">
                                            <div class="">
                                                <p class="label-total">Total Sum Insured</p>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="" *ngIf="total">
                                                <p class="label-total">{{form.get("currency")?.value}}&nbsp;&nbsp;&nbsp;&nbsp;{{total | number:'1.2-2':'en-US'}}</p>
                                                <p class="si-field-error" *ngIf="siError != ''">
                                                    {{siError}}
                                                </p>
                                            </div>
                                            <!--<div class="col-case col-2">
                                                <div class="field currency">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput formControlName="currency" class="currency-input">
                                                    </mat-form-field>
                                                </div>
                                                <div class="field currency-field">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput class="amount-field" [placeholder]="setAmtPlaceholder()" formControlName="total" amount>
                                                    </mat-form-field>
                                                    <p class="field-error" *ngIf="siError != ''">
                                                        {{siError}}
                                                    </p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="col-case">
                                    <div class="field required">
                                        <label>No. of Storeys/Floors</label>
                                        <mat-form-field appearance="fill">
                                            <input id="gray-rq" matInput placeholder="No. of Storeys/Floors" type="text" formControlName="numFLR" class="noArrow" (keypress)="allowNumericDigitsOnlyWODecimal($event)">
                                        </mat-form-field>
                                        <p class="field-error" *ngIf="floorValidation()">
                                            The value you have entered is not within the allowable limit of storeys/floors.
                                        </p>
                                        <p class="field-error" *ngIf="formService.isInvalid(form, 'numFLR')">
                                            {{formService.getFieldErrorMsg(form, "numFLR")}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-case col-2">
                                    <div class="field required">
                                        <label>The roof is made of:</label>
                                        <mat-radio-group formControlName="numROOF" class="radio-group" (change)="setRoofValue($event.value)">
                                            <mat-radio-button class="radio-button wall" *ngFor="let roof of roofOptions" [value]="roof.value">
                                                <p>{{roof.text}}</p>
                                            </mat-radio-button> 
                                        </mat-radio-group>
                                    </div>
                                    <div class="field required">
                                        <label>The exterior walls are made of:</label>
                                        <mat-radio-group formControlName="numWALL" class="radio-group" (change)="setWallValue($event.value)">
                                            <mat-radio-button class="radio-button roof" *ngFor="let wall of wallOptions" [value]="wall.value">{{wall.text}}</mat-radio-button> 
                                        </mat-radio-group>
                                    </div>
                                </div>
                        </div>
                        <div class="invoice-box" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                            <p *ngIf="this.lineCd === 'MC'">Drag Sales Invoice Image here</p>
                            <p *ngIf="this.lineCd != 'MC'">Drag Image here</p>
                            <p>or</p>
                            <button class="btn btn3 proxima" (click)="fileInput.click()">Upload Image</button>

                            <input id="gray-rq" hidden (change)="onFileChange($event)" #fileInput type="file" id="file" multiple>
                            <div class="attach-preview image" *ngIf="emailParams.attach.length > 0">
                                <ng-container *ngFor="let x of emailParams.attach; index as i">
                                    <div class="preview" *ngIf="x.type.indexOf('image') !== -1">
                                        <img [src]="x.path">
                                        <span class="delete" (click)="onClickDelete(i)">X</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="attach-preview pdf-name" *ngIf="emailParams.attach.length > 0">
                                <ng-container *ngFor="let x of emailParams.attach; index as i">
                                    <div class="preview" *ngIf="x.type.indexOf('image') === -1">
                                        <span class="pdf-filename">{{x.filename}}</span>
                                        <span class="delete" (click)="onClickDelete(i)">X</span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <p class="field-error" *ngIf="formService.isInvalid(form, 'invoiceImg')">
                            {{customErrorMsg.fileType}}
                        </p>
                        <p class="field-error" *ngIf="formService.isInvalid(form, 'invoiceImg')">
                            {{customErrorMsg.fileSize}}
                        </p>
                        <div *ngIf="attachCaveat" class="col-case attach-caveat montserrat">
                            <p [innerHTML]="attachCaveat"></p>
                        </div>
                    </div>
                </div>
                <div class="form-btns">
                    <button class="btn btn3" (click)="processContactForm()">Submit</button>
                </div>
            </form>
        </div>

        <div class="support case montserrat">
            <div class="support-container">
                <div class="image"></div>
                <div class="info">
                    <h2>Customer Support</h2>
                    <p>{{custDesc}}</p>
                    <div class="sub-info" *ngIf="custNumber">
                        <p>Customer Service</p>
                        <p>{{custNumber}}</p>
                    </div>
                    <div class="sub-info" *ngIf="custFaxNo">
                        <p>Fax</p>
                        <p>{{custFaxNo}}</p>
                    </div>
                    <div class="sub-info" *ngIf="custEmail">
                        <p>Email</p>
                        <p>{{custEmail}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>