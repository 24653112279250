<section id="coverage-perils">
    <div class="container">
        <form [formGroup]="coverageInfo">
            <div class="form " id="coverage-perils-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div [ngClass]="coverageHeaderngClass">
                        <div class="field required">
                            <label>Effective Date</label>
                            <mat-form-field appearance="fill">
                                <!-- <mat-label>Choose a date</mat-label> -->
                                <input matInput [matDatepicker]="picker" (dateChange)="getExpiry();" (dateInput)="getExpiry();" formControlName="effDate" [min]="minEffDate" [max]="maxEffDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <p class="field-error" *ngIf="coverageInfo.get('effDate')?.errors?.matDatepickerMin || coverageInfo.get('effDate')?.errors?.matDatepickerMax; else invalidDateError">
                                Effective Date must be within {{effDateRangeDays}} days starting from {{sameDayTag ?'today' : 'the next day'}}.
                            </p>
                            <ng-template #invalidDateError>
                                <p class="field-error" *ngIf="coverageInfo.get('effDate')?.status === 'INVALID'">
                                    Invalid Date.
                                </p>
                            </ng-template>
                        </div>
                        <div class="field required">
                            <label>Expiry Date</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker2" formControlName="expDate">
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field required" *ngIf="userDetails.accountType && (['A' ,'E' ,'B'].includes(userDetails.accountType))">
                            <label>Client Type Group</label>
                            <mat-form-field appearance="fill">
                                <input matInput placeholder="Client Type Group" formControlName="clientTypeGroupDetail" uppercase [withSpace]='true' [withSpecialCharacters]='true' (keydown.space)="$event.preventDefault()">
                            </mat-form-field>
                            <p class="field-error" *ngIf="clientTypeError">
                                {{clientTypeError}}
                            </p>
                        </div>
                        <div class="field" *ngIf="!userDetails.accountType || userDetails.accountType == 'P'">
                            <label>{{ prodVouchCdExist ? 'Product / Voucher Code' : 'Referror / Product Code' }}</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="promoCode" promoCode>
                            </mat-form-field>
                            <p class="field-error">
                                {{promoError}}
                            </p>
                        </div>
                        <div class="field" *ngIf="(maxDepWithPrem > 0 && (['PA' ,'CI'].includes(lineCd) && !['A' ,'E'].includes(userDetails.accountType)))">
                            <label>No. of Dependents</label>
                            <mat-form-field appearance="fill">
                                <input matInput type="number" formControlName="depWithPrem" class="noArrow">
                            </mat-form-field>
                            <p class="field-error">
                                {{ depWithPremError }}
                            </p>
                        </div>
                        <div class="field show-comp-btn">
                            <label>&nbsp;</label>
                            <mat-form-field appearance="fill">
                                <button class="btn btn3" (click)="showComputation()" [disabled]="coverageInfo.get('effDate')?.status == 'INVALID'  || disableShowCompBtn">Show Computation</button>
                                <input matInput formControlName="btnHolder">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="userDetails.userId && (userDetails.accountType !== 'P' && userDetails.accountType !== 'B')">
                        <div [ngClass]="{'col-case': true, 'col-4':  true }">
                            <div class="field required">
                                <label>Agent</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Agent" (ngModelChange)="agentChanged($event)" formControlName="agentDetail" [matAutocomplete]="autoAgentDetail" (input)="setAgentViaInput()" autocomplete>
                                    <mat-autocomplete #autoAgentDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredAgentDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(coverageInfo, 'agentDetail')">
                                    {{formService.getFieldErrorMsg(coverageInfo, "agentDetail")}}
                                </p>
                            </div>
                            <div class="field required">
                                <label>BMA</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="BMA" formControlName="bmaDetail" [matAutocomplete]="autoBmaDetail" (input)="setBmaViaInput()" autocomplete>
                                    <mat-autocomplete #autoBmaDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredBmaDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(coverageInfo, 'bmaDetail')">
                                    {{formService.getFieldErrorMsg(coverageInfo, "bmaDetail")}}
                                </p>
                            </div>
                            <div class="field required" *ngIf="(lineCd === 'CI' && userDetails.accountType === 'E')">
                                <label>Maximum No. of Days</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="noOfDays">
                                </mat-form-field>
                            </div>
                            <div class="field" *ngIf="(maxDepWithPrem > 0 && (['PA' ,'CI'].includes(lineCd) && ['A' ,'E'].includes(userDetails.accountType)))">
                                <label>No. of Dependents</label>
                                <mat-form-field appearance="fill">
                                    <input matInput type="number" formControlName="depWithPrem" class="noArrow">
                                </mat-form-field>
                                <p class="field-error">
                                    {{ depWithPremError }}
                                </p>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-case col-4">
                        <div class="field"></div>
                        <div class="field"></div>
                        <div class="field"></div>
                    </div>
                    <div *ngIf="haveData">
                        <!-- <div *ngIf="summarizedPremTag == 'N'"> -->
                        <ng-container *ngIf="summarizedPremTag == 'N'">
                            <ng-container>
                                <div class="edit-container" *ngIf="(m093?.editSiTag=='Y' || m093?.editPremTag=='Y')">
                                    <p class="edit-btn" (click)="toggleEdit(true)" *ngIf="!editFlag">Edit Coverage ></p>
                                    <p class="edit-btn" (click)="toggleEdit(true);showComputation({perils:dataSource.data})" *ngIf="editFlag">Done Editing ></p>
                                </div>
                            </ng-container>
                            <div class="box-wrap bg1 table-box">
                                <form [formGroup]="tableForm">
                                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" formArrayName="perils">

                                        <ng-container *ngIf="lineCd === 'HA'" matColumnDef="itemNo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                                            <td mat-cell *matCellDef="let element"> {{ displayItemText(element.itemCd) }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="perilName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Coverage </th>
                                            <td mat-cell *matCellDef="let element"> {{element.perilName}}
                                                <mat-icon (click)="tooltip.toggle()" #tooltip="matTooltip" [matTooltip]="element.shortDesc" matTooltipPosition="right" class="tooltip" *ngIf="element.shortDesc">help_outline</mat-icon>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="amountCovered">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Amount Covered </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <ng-container *ngIf="m093?.editSiTag!='Y' || (!editFlag || !element.perilCd) || (element.tsiOpts?.length == 0 && !(element.fmvSw == 'Y' && element.defaultSw != 'Y')) || (element.perilType == 'A' && (element.groupCd || element.basicPerilCd)) 
                                                                        || (lineCd == 'MC' && element.perilCd == '4' && !editablePD) || ((userDetails.accountType||'P')=='P' &&  element.perilCd != '1' && lineCd == 'MC') || element.fixedSiTag=='Y' || lineCd === 'HA' else tsiText">
                                                    {{element.tsiAmt != null ? (element.tsiAmt | currency:'PHP':'₱ ') : ''}}
                                                </ng-container>
                                                <ng-template #tsiText>
                                                    <mat-form-field appearance="fill" [formGroupName]="index">
                                                        <input matInput formControlName="tsiAmt" *ngIf="element.fmvSw == 'Y' && element.defaultSw != 'Y' else tsiSelect" amount>
                                                        <ng-template #tsiSelect>
                                                            <mat-select formControlName="tsiAmt">
                                                                <mat-option *ngFor="let opt of element.tsiOpts" [value]="addDecimalPlaces(opt.tsiAmt,2)">
                                                                    {{ opt.tsiAmt | number:'1.2-2' }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </ng-template>
                                                    </mat-form-field>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="premRt">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Rate </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <ng-container *ngIf="(lineCd == 'HA') || (element.fmvSw == 'Y' && element.defaultSw != 'Y')">
                                                    <!-- TEMP -->
                                                    <ng-container *ngIf="(!editFlag || !element.perilCd) || (userDetails.accountType == 'A' && userDetails.premTag == 'N' && !(element.fmvSw == 'Y' && element.defaultSw != 'Y')) 
                                                                             || m093?.editPremTag!='Y' || element.fixedPremTag=='Y' || (element.itemNo && element.itemNo != 1) else premText">
                                                        {{element.premRt != null ? (element.premRt | number:'1.6-6') : ''}}
                                                    </ng-container>
                                                    <ng-template #premText>
                                                        <mat-form-field appearance="fill" [formGroupName]="index">
                                                            <mat-select formControlName="premRt" *ngIf="userDetails.accountType == 'A' && userDetails.premTag != 'Y' && element.fmvSw == 'Y' && element.defaultSw != 'Y' else premRtInput">
                                                                <mat-option *ngFor="let opt of element.rtOpts" [value]="addDecimalPlaces(opt.premRt)">
                                                                    {{ opt.premRt | number:'1.6-6' }}
                                                                </mat-option>
                                                            </mat-select>
                                                            <ng-template #premRtInput>
                                                                <input matInput formControlName="premRt">
                                                            </ng-template>
                                                        </mat-form-field>
                                                    </ng-template>
                                                </ng-container>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="premAmount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Premium </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <ng-container *ngIf="(userDetails.premTag != 'Y' && userDetails.accountType == 'A') ||  m093?.editPremAmtTag!='Y' || (!editFlag || !element.perilCd)   || element.fixedPremTag=='Y' else premAmtInput">
                                                    <ng-container *ngIf="element.premAmt == 0 else paidPrem">
                                                        FREE
                                                    </ng-container>
                                                    <ng-template #paidPrem>
                                                        {{element.premAmt != null ? (element.premAmt | currency:'PHP':'₱ ') : '-'}}
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #premAmtInput>
                                                    <mat-form-field appearance="fill" [formGroupName]="index">
                                                        <input matInput formControlName="premAmt" amount>
                                                    </mat-form-field>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="commRt">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Comm Rate </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <ng-container *ngIf="editFlag && userDetails.accountType == 'E' && this.coverageInfo.get('promoUserType')?.value == 'A' && element.perilType == 'B' && element.fixedPremTag=='N' && (element.itemNo && element.itemNo == 1) else commRtFixed">
                                                    <mat-form-field appearance="fill" [formGroupName]="index">
                                                        <input matInput formControlName="commRt">
                                                    </mat-form-field>
                                                </ng-container>
                                                <ng-template #commRtFixed>
                                                    {{element.commRt != null ? (element.commRt | number:'1.2-2') : ''}}
                                                </ng-template>
                                            </td>

                                        </ng-container>

                                        <ng-container matColumnDef="commAmt">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Comm Amount </th>
                                            <td mat-cell *matCellDef="let element"> {{element.commAmt != null ? (element.commAmt | currency:'PHP':'₱ ') : ''}}</td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>

                                </form>
                            </div>
                            <span *ngIf="minPremTag">{{minPremMsg}}</span>
                        </ng-container>
                        <!-- <div *ngIf="summarizedPremTag == 'Y'"> -->
                        <ng-container *ngIf="summarizedPremTag == 'Y'">
                            <div class="product-details">
                                <div class="summary premium-charges">
                                    <h2>Premium And Charges</h2>
                                    <div class="box-1">
                                        <div class="box-holder style-2">
                                            <div class="detail">
                                                <div class="label">Premium</div>
                                                <div class="value">{{premiumCharges.premiumAmt | currency: 'PHP': '₱ '}}</div>
                                            </div>
                                            <div class="detail" *ngFor="let tax of premiumCharges.taxes">
                                                <div class="label">{{tax.name}}</div>
                                                <div class="value">{{tax.value | currency: 'PHP': '₱ '}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-2">
                                        <div class="box-holder style-2">
                                            <div class="detail">
                                                <div class="label">Total Amount to be paid</div>
                                                <div class="value">{{premiumCharges.amountDue | currency: 'PHP': '₱ '}}</div>
                                            </div>
                                            <ng-container *ngIf="userDetails.userId && userDetails.accountType !== 'P'">
                                                <div class="detail">
                                                    <div class="label">Commission Amount</div>
                                                    <div class="value">{{premiumCharges.commissionAmt | currency: 'PHP': '₱ '}}</div>
                                                </div>
                                                <div class="detail total-amt">
                                                    <div class="label">Net Due</div>
                                                    <div class="value">{{premiumCharges.netDue | currency: 'PHP': '₱ '}}</div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="col-case"></div>

                    <div *ngIf="haveData">
                        <form [formGroup]="policyHeaderDetailsForm">
                            <ng-container *ngIf="userDetails.userId && (userDetails.accountType === 'E' || (userDetails.accountType === 'A' && agentType == 'D'))">
                                <div class="form-title">
                                    <h2>Policy Header Details</h2>
                                </div>
                                <div id="policy-header-details-form">
                                    <div class="form-loader"></div>
                                    <div class="form-fields">
                                        <div class="form" class='policy-header-form'>
                                            <div class="policy-header-details-form-msg top"></div>
                                            <div class="with-form-msg">
                                                <ng-container>
                                                    <div class="col-case col-3">
                                                        <div [ngClass]='{"field" : true, "required" : checkerAgentCdParam()}'>
                                                            <label>Source Code</label>
                                                            <mat-form-field class="select" appearance="fill">
                                                                <input matInput placeholder="Source Code" formControlName="sourceCodeDetail" [matAutocomplete]="autoSourceCodeDetail" (input)="setSourceCodeViaInput()" autocomplete>
                                                                <mat-autocomplete #autoSourceCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                                    <mat-option *ngFor="let option of filteredSourceCodeDetailOptions | async" [value]="option">
                                                                        {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </mat-form-field>
                                                            <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'sourceCodeDetail')">
                                                                {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "sourceCodeDetail")}}
                                                            </p>
                                                        </div>
                                                        <div [ngClass]='{"field" : true, "required" : checkerAgentCdParam()}'>
                                                            <label>Source Extension</label>
                                                            <mat-form-field class="select" appearance="fill">
                                                                <input matInput placeholder="Source Extension" formControlName="sourceExtensionDetail" [matAutocomplete]="autoSourceExtensionDetail" (input)="setSourceExtensionViaInput()" autocomplete>
                                                                <mat-autocomplete #autoSourceExtensionDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                                    <mat-option *ngFor="let option of filteredSourceExtensionDetailOptions | async" [value]="option">
                                                                        {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </mat-form-field>
                                                            <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'sourceExtensionDetail')">
                                                                {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "sourceExtensionDetail")}}
                                                            </p>
                                                        </div>
                                                        <div [ngClass]='{"field" : true, "required" : checkerAgentCdParam()}' *ngIf="userDetails.userId && (userDetails.accountType === 'E')">
                                                            <label>Referrer</label>
                                                            <mat-form-field class="select" appearance="fill">
                                                                <input matInput placeholder="Referrer" formControlName="referrorCodeDetail" [matAutocomplete]="autoReferrorCodeDetail" (input)="setReferrorCodeViaInput()" autocomplete>
                                                                <mat-autocomplete #autoReferrorCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                                    <!-- <cdk-virtual-scroll-viewport itemSize="10" class="cdk-viewport"> 
                                                                        <mat-option *cdkVirtualFor="let option of filteredReferrorCodeDetailOptions | async" [value]="option">
                                                                            {{option.text}}
                                                                        </mat-option>
                                                                    </cdk-virtual-scroll-viewport> -->
                                                                    <mat-option *ngFor="let option of filteredReferrorCodeDetailOptions | async" [value]="option">
                                                                        {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </mat-form-field>
                                                            <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'referrorCodeDetail')">
                                                                {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "referrorCodeDetail")}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-case col-3" *ngIf="userDetails.userId && (userDetails.accountType === 'E')">
                                                        <div class="field required">
                                                            <label>Project Code</label>
                                                            <mat-form-field class="select" appearance="fill">
                                                                <input matInput placeholder="Project Code" formControlName="projectCodeDetail" [matAutocomplete]="autoProjectCodeDetail" (input)="setProjectCodeViaInput()" autocomplete>
                                                                <mat-autocomplete #autoProjectCodeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                                    <mat-option *ngFor="let option of filteredProjectCodeDetailOptions | async" [value]="option">
                                                                        {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </mat-form-field>
                                                            <p class="field-error" *ngIf="formService.isInvalid(policyHeaderDetailsForm, 'projectCodeDetail')">
                                                                {{formService.getFieldErrorMsg(policyHeaderDetailsForm, "projectCodeDetail")}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="policy-header-details-form-msg bottom"></div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </form>
                    </div>
                </div>
            </div>
            <div class="form-btns">
                <button class="btn btn4" (click)="move('back')">Back</button>
                <!-- <button class="btn btn5" (click)="saveCoverageInfo()" *ngIf="hasAccount && haveData">Save</button>
                <button class="btn btn2" (click)="move('next')" *ngIf="haveData">{{stepperCheck().label}}</button> -->
                <button class="btn btn5" (click)="saveCoverageInfo()" *ngIf="stepperDtlService.stepperDtlBtns[1].isShow == 'true'">Save</button>
                <button class="btn btn2" (click)="move('next')" *ngIf="stepperDtlService.stepperDtlBtns[2].isShow == 'true'">{{stepperCheck().label}}</button>
            </div>
        </form>
    </div>
</section>