import { Directive, HostListener, ElementRef, Optional } from '@angular/core';

@Directive({
    selector: '[trim]',
})
export class TrimDirective {
    constructor(private el: ElementRef) {}

    @HostListener('blur') onBlur() {
        this.el.nativeElement.value = this.el.nativeElement.value.trim();
    }
}
