import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppMessageService {

    hideAppMessage:any;

    /*
        messageType VALUES
        - success
        - error
        - info
    */
    showAppMessage(message:string, messageType:string):void {
        const form = document.querySelector("#app-message");
        if (form !== null) {
            form.classList.remove("show");
            form.innerHTML = `
                <div class="container">
                    <div class="message-container">
                        <div class="message-type ${messageType.toLowerCase()}">
                            <div class="icon"></div>
                        </div>
                        <p>${message}</p>
                    </div>
                </div>`;

            setTimeout(() => {
                clearTimeout(this.hideAppMessage);
                form.classList.add("show");
                this.hideAppMessage = setTimeout(() => {
                    form.classList.remove("show");
                    setTimeout(() => {
                        form.innerHTML = "";
                    }, 300);
                }, 3500);
            }, 300);
        }
    }
}