import { Component, ElementRef, forwardRef, HostListener, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'email-list',
  templateUrl: './email-list.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => EmailListComponent)
  }]
})



export class EmailListComponent implements ControlValueAccessor {

    separators:string[] = [',',';',' ','Comma','Space','Semicolon']; 
    value:string = '';
    emailList:any[] = [];
    emailInput:string = '';

    focused:boolean = false;
    hideInput:boolean = false;


    @ViewChild('emailListContainer', { read: ElementRef }) emailListContainer!: ElementRef;
    @ViewChild('emailListInput', { read: ElementRef }) emailListInput!: ElementRef;
    @ViewChild('background', { read: ElementRef }) background!: ElementRef;

    @HostListener('document:click', ['$event'])
    clickOutsideCurrentPopup(event: Event) {
        if (!this.emailListContainer.nativeElement.contains(event.target) && this.focused) {
            this.focused = false;
            this.onBlur();
        }else if(this.emailListContainer.nativeElement.contains(event.target) && !this.focused){
            this.focused = true;
            this.emailListInput.nativeElement.focus();
            this.onFocus();
        }

        if(event.target == this.background?.nativeElement){
            this.hideInput = false;
            this.emailListInput.nativeElement.click();
        }
    }

    changed!:(value:string) => void;
    touched!:() => void;
    isDisabled!: boolean;

    writeValue(value: string): void {
        this.emailInput = value;
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.changed = fn;
    }
    registerOnTouched(fn: any): void {
        this.touched = fn;
    }
    setDisabledState(isDisabled:boolean):void{
        this.isDisabled = isDisabled;
    }
    onChange(v:string){
        this.emailInput = v;
        const value:string = (this.emailList.length != 0 ? this.emailList.map((e:any)=>e.email).join(',') + ',' : '') + v;
        this.changed(value);
    }
    onKeyDown(event:any):any{
        if(this.separators.indexOf(event.data || '') != -1){
            this.emailInput = this.emailInput.replace(/[, ;]/g,'');
            let email = this.emailInput;
            email && this.emailList.push({
                email: email,
                isValid: this.isValidEmail(email)
            });
            this.emailInput = '';
            this.changed(this.emailList.map((e:any)=>e.email).join(','));
            event.preventDefault();
        }else if(event.data.length > 1){
            this.emailInput = '';
            for(let email of event.data.split(/[, ;]/g)){
                email && this.emailList.push({
                    email: email,
                    isValid: this.isValidEmail(email)
                });
            }
            this.changed(this.emailList.map((e:any)=>e.email).join(','));
            if(this.emailList.length >= 1){
                this.emailInput = this.emailList.pop().email;
            }
            event.preventDefault();
        }
    }
    onListKeyDown(event:any,email:any):any{
        if(this.separators.indexOf(event.key || '') != -1){
            email.email = email.email.replace(/[, ;]/g,'');
            this.onEditBlur(email);
            setTimeout(()=>{
                this.emailListInput.nativeElement.focus();
            })
            event.preventDefault();
        }
    }
    onFocus(){
        this.emailList = this.emailInput ? this.emailInput.split(',').map(e=>{
            return {email: e,isValid:this.isValidEmail(e)}
        }) : [];
        this.emailInput = '';
    }
    onBlur(){
        this.emailInput = this.emailList.map((e:any)=>e.email).join(',') + ( this.emailList.length != 0 && this.emailInput ? ',' : '') + (this.emailInput);
        this.emailList = [];
    }

    deleteEmail(email:any):void{
        this.emailList = this.emailList.filter((e:any)=>e!=email);
        const value:string = (this.emailList.length != 0 ? this.emailList.map((e:any)=>e.email).join(',') + ',' : '') + this.emailInput;
        this.changed(value);
        setTimeout(()=>{
            this.emailListInput.nativeElement.click();
        },0);
        this.hideInput = false;
    }

    isValidEmail(email:string):boolean{
        return !!String(email || '').match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
    }

    onDbClickEmail(email:any):void{
        this.emailInput && this.emailList.push({
            email: this.emailInput,
            isValid: this.isValidEmail(this.emailInput)
        });
        this.emailInput = '';
        this.emailList.forEach((e)=>{
            e.isEdit = false;
        })
        email.isEdit = true;
        this.hideInput = true;
    }

    onEditBlur(email:any):void{
        email.isValid = this.isValidEmail(email.email);
        email.isEdit = false;
        this.hideInput = false;
        const value:string = (this.emailList.length != 0 ? this.emailList.map((e:any)=>e.email).join(',') + ',' : '') + this.emailInput;
        this.changed(value);
    }
}
