import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

    constructor(
        private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private router: Router,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        public formService: FormService,
        private securityService: SecurityService
    ) { 
        if (!this.userDetailsService.userId) {
            this.router.navigate(["/"]);
        }else if(window.location.pathname == '/dashboard'){
            this.form.patchValue({
                accountType: this.userDetailsService.accountType,
                userId: this.userDetailsService.accountType == "P" ? this.userDetailsService.email : this.userDetailsService.userId,
            });
            this.form.get('oldPassword')?.disable();
            this.isResetPassword = true;
        } else {
            this.form.patchValue({
                accountType: this.userDetailsService.accountType,
                userId: this.userDetailsService.accountType == "P" ? this.userDetailsService.email : this.userDetailsService.userId,
            });
        }
    }

    showOldPasswordValue: boolean = false;
    showNewPasswordValue: boolean = false;
    showRepeatPasswordValue: boolean = false;
    isPasswordValid:boolean = false;

    //GET THIS FROM JSON DATA SERVICE LATER
    passwordReq: any = {
        minLength: 8,
        maxLength: 10,
        minLetter: 1,
        minDigit: 1,
        minSpecial: 1,
        minUppercase: 1,
        minLowercase: 1,
    };

    passwordReqState: any = {
        isMinLength: true,
        isMaxLength: true,
        hasMinLetter: true,
        hasMinDigit: true,
        hasMinSpecial: true,
        hasMinUppercase: true,
        hasMinLowercase: true
    }

    form: FormGroup = this.formBuilder.group({
        accountType: [""],
        userId: [""],
        oldPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required]],
        repeatPassword: ["", [Validators.required]]
    });

    isResetPassword:boolean = false;

    processPassword():void {
        this.jsonDataService.contorlLoading(true);
        if (this.form.valid) {
            let npw = this.form.value.newPassword;
            let rpw = this.form.value.repeatPassword;

            if (npw === rpw) {
                this.changePassword(this.form.value);
            } else {
                this.jsonDataService.contorlLoading(false);
                this.formService.showFormMsg("changeFormMsg", "New password and confirm password are not the same.", "E");
            }
        } else {
            this.jsonDataService.contorlLoading(false);
            this.formService.showFormMsg("changeFormMsg", "Please fill all the required fields.", "E");
        }
    }

    changePassword(params:any) {
        this.apiCallService.changePassword({
            at: params.accountType,
            un: params.userId,
            opw: params.oldPassword,
            npw: params.newPassword,
            rpw: params.repeatPassword
        }).subscribe((response:any) => {
            this.securityService.hasValidCsrfToken(response, () => {
                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                if (response.status === "SUCCESS") {
                    if(res.status === "SUCCESS"){
                        this.apiCallService.logout().subscribe((response:any) => {
                            this.jsonDataService.contorlLoading(false);
                            sessionStorage.setItem("bssid", "1");
                            sessionStorage.removeItem("rkc");
                            this.userDetailsService.resetUserDetails();
                            this.router.navigate(["/"]);
                            this.appMessageService.showAppMessage(res.message, "success");
                            //this.appMessageService.showAppMessage('Your password has been changed successfully. Use your new password to login.', "success");
                        });
                    } else if (res.status === "INVALID"){
                        this.formService.showFormMsg("changeFormMsg", res.message, "E");
                        this.form.patchValue({
                            oldPassword: "",
                            newPassword: "",
                            repeatPassword: "",
                        });
                        this.jsonDataService.contorlLoading(false);
                    }
                } else if (response.status == "INVALID") {
                    this.formService.showFormMsg("changeFormMsg", res.message, "E");
                    this.form.patchValue({
                        oldPassword: "",
                        newPassword: "",
                        repeatPassword: "",
                    });
                    this.jsonDataService.contorlLoading(false);
                } else if (response.status == "LOCKED") {
                    this.apiCallService.logout().subscribe((response:any) => {
                        this.securityService.checkRequestKeyResponse(response, () => {
                            this.jsonDataService.contorlLoading(false);
                            sessionStorage.setItem("bssid", "1");
                            sessionStorage.removeItem("rkc");
                            this.userDetailsService.resetUserDetails();
                            this.router.navigate(["/"]);
                            this.appMessageService.showAppMessage(res.message, "info");
                        });
                    });
                }
            });
        });
    }

    //CHANGE THIS TO DIRECTIVES LATER
    checkPassword() {
        if(this.form.value.newPassword) {
            let npw = this.form.value.newPassword;

            let pwdReq = this.passwordReq;
            let pwsReqState = this.passwordReqState;

            let letterCount = 0;
            let numberCount = 0;
            let specialCharCount = 0;
            let lowerCharCount = 0;
            let upperCharCount = 0;

            npw.split("").forEach((value: any) => {
                if (value.search(/[a-z]/i) >= 0) { letterCount++; }
                if (value.search(/[0-9]/) >= 0) { numberCount++; }
                if (value.search(/[!@#$%^&*]/) >= 0) { specialCharCount++; }
                if (value.search(/[a-z]/) >= 0) { lowerCharCount++; }
                if (value.search(/[A-Z]/) >= 0) { upperCharCount++; }
            });

            npw.length < pwdReq.minLength && pwdReq.minLength !== 0 ? pwsReqState.isMinLength = false : pwsReqState.isMinLength = true;
            npw.length > pwdReq.maxLength && pwdReq.maxLength !== 0 ? pwsReqState.isMaxLength = false : pwsReqState.isMaxLength = true;
            letterCount < pwdReq.minLetter ? pwsReqState.hasMinLetter = false : pwsReqState.hasMinLetter = true;
            numberCount < pwdReq.minDigit ? pwsReqState.hasMinDigit = false : pwsReqState.hasMinDigit = true;
            specialCharCount < pwdReq.minSpecial ? pwsReqState.hasMinSpecial = false : pwsReqState.hasMinSpecial = true;
            lowerCharCount < pwdReq.minLowercase ? pwsReqState.hasMinLowercase = false : pwsReqState.hasMinLowercase = true;
            upperCharCount < pwdReq.minUppercase ? pwsReqState.hasMinUppercase = false : pwsReqState.hasMinUppercase = true;

            this.passwordReqState = pwsReqState;

            this.validatePassword();
        }
    }

    validatePassword(): void {
        for (var reqs in this.passwordReqState) {
            if(!this.passwordReqState[reqs]) {
                this.isPasswordValid = false;
                break;
            };
            this.isPasswordValid = true;
        }
    }

}
