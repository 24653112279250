import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    selector: 'renewal-redirect',
    templateUrl: './renewal-redirect.component.html'
})
export class RenewalRedirectComponent implements OnInit {

    form: FormGroup = this.formBuilder.group({
        answer: ["", [Validators.required]]
    });

    showPasswordValue:boolean = false;
    requestId: string = '';
    policyId: string = '';

    constructor(
        // PUBLIC
        public formService: FormService,

        // PRIVATE
        private formBuilder: FormBuilder,
        private router: Router,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private productService: ProductService,
        private appMessageService: AppMessageService,
        private activatedRoute: ActivatedRoute
        ) { 
            this.activatedRoute.queryParamMap.subscribe((p: any) => {
                if (p.params.params) {
                    let params: any = atob(p.params?.params);
                    params = JSON.parse(jsonDataService.decrypt(params));
                    this.requestId = params.requestId;
                    this.policyId = params.policyId;
                }else{
                    router.navigateByUrl("/");
                }
            });
        }

    ngOnInit(): void {
    }

    onSubmit(){
        // TODO
        this.goToPolicySummary();
    }

    goToPolicySummary(){
        if(this.form.invalid){
            return;
        }

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getRenewalInfo({
            requestId: this.requestId,
            policyId: this.policyId,
            answer: this.form.get('answer')?.value
        }).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));

                        if (res.error) {
                            this.appMessageService.showAppMessage("Invalid password.", "error");
                        } else {
                            sessionStorage.setItem("rid", this.requestId);
                            this.productService.setProductInfo2(res);
                            this.productService.setProductInfo("creationProcess", "renewal");
                            // ? This sets fromLink property to renewal because its from renewal link
                            this.productService.setProductInfo("fromLink", "renewal");
                            this.router.navigate(["/policy/summary"]);
                        }
                    });
                } else {
                    this.appMessageService.showAppMessage("Invalid password.", "error");
                }
            });
      	}); 
    }

}
