<section id="maintenance">
    <div class="header">
        <div class="container">
            <img src="./assets/images/common/company-logo/bpims-logo1.png" alt="BPI MS" />
        </div>
    </div>
    <div class="body openSans">
        <div class="container">
            <h1>Time Out!</h1>
            <div class="description">
                <p>We are working on a few items to make your experience better.</p>
                <p>Please visit us again soon.</p>
            </div>
            <img id="maintenance-logo" src="./assets/images/maintenance/maintenance.jpg" alt="BPI MS Maintenance" />
            <p>For immediate protection needs, you may contact us at insure@bpims.com or call (02) 8840-9000.</p>
            <img id="maintenance-logo-sub" src="./assets/images/maintenance/maintenance-sub.png" alt="BPI MS Maintenance Sub" />
        </div>
    </div>
</section>
