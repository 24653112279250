<section id="change-password">
    <div class="container arial" [class.solo]="true">
        <div class="case reg-case top-border border4" [class.show]="true">
            <form [formGroup]="form" id="change-form">
                <div id="change-password-form">
                    <div class="form-loader"></div>
                    <div class="form-fields">
                        <h2>{{isResetPassword ? 'Set Your Password' : 'Change Password'}}</h2>
                        <p *ngIf="isResetPassword">Enter your desired password</p>
                        <div class="form">
                            <div class="col-case" *ngIf="!isResetPassword">
                                <div class="field required">
                                    <label>Old Password</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="oldPassword" [type]="showOldPasswordValue ? 'text' : 'password'">
                                        <button mat-icon-button matSuffix (click)="showOldPasswordValue = !showOldPasswordValue">
                                            <mat-icon>{{showOldPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="formService.isInvalid(form, 'oldPassword')">
                                            {{formService.getFieldErrorMsg(form, "oldPassword")}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-case">
                                <div class="field required">
                                    <label>New Password</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="newPassword" [type]="showNewPasswordValue ? 'text' : 'password'" (keyup)="checkPassword()">
                                        <button mat-icon-button matSuffix (click)="showNewPasswordValue = !showNewPasswordValue">
                                            <mat-icon>{{showNewPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="formService.isInvalid(form, 'newPassword')">
                                            {{formService.getFieldErrorMsg(form, "newPassword")}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-case">
                                <div class="field required">
                                    <label>Confirm Password</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="repeatPassword" [type]="showRepeatPasswordValue ? 'text' : 'password'" (keyup)="checkPassword()">
                                        <button mat-icon-button matSuffix (click)="showRepeatPasswordValue = !showRepeatPasswordValue">
                                            <mat-icon>{{showRepeatPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="formService.isInvalid(form, 'repeatPassword')">
                                            {{formService.getFieldErrorMsg(form, "repeatPassword")}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pwd-reqs">
                        <p [ngClass]="passwordReqState.isMinLength ? 'Y' : 'N'">Passwords must be at least {{ passwordReq.minLength == 1 ? passwordReq.minLength + " character." :  passwordReq.minLength + " characters." }}</p>
                        <p [ngClass]="passwordReqState.isMaxLength ? 'Y' : 'N'">Passwords must be less than or equal to {{ passwordReq.maxLength == 1 ? passwordReq.maxLength + " character." :  passwordReq.maxLength + " characters." }}</p>
                        <p [ngClass]="passwordReqState.hasMinLetter ? 'Y' : 'N'">Passwords must contain at least {{ passwordReq.minLetter == 1 ? passwordReq.minLetter + " letter." :  passwordReq.minLetter + " letters."  }}</p>
                        <p [ngClass]="passwordReqState.hasMinDigit ? 'Y' : 'N'">Passwords must contain at least {{ passwordReq.minDigit == 1 ? passwordReq.minDigit + " digit." :  passwordReq.minDigit + " digits."  }}</p>
                        <p [ngClass]="passwordReqState.hasMinSpecial ? 'Y' : 'N'">Passwords must contain at least {{ passwordReq.minSpecial == 1 ? passwordReq.minSpecial + " special character." :  passwordReq.minSpecial + " special characters."  }}</p>
                        <p [ngClass]="passwordReqState.hasMinLowercase ? 'Y' : 'N'">Passwords must contain at least {{ passwordReq.minLowercase == 1 ? passwordReq.minLowercase + " lowercase letter." :  passwordReq.minLowercase + " lowercase letters."  }}</p>
                        <p [ngClass]="passwordReqState.hasMinUppercase ? 'Y' : 'N'">Passwords must contain at least {{ passwordReq.minUppercase == 1 ? passwordReq.minUppercase + " uppercase letter." :  passwordReq.minUppercase + " uppercase letters."  }}</p>
                    </div>
                    <div class="changeFormMsg bottom"></div>
                </div>
                <div class="form-btns">
                    <button class="btn btn2" (click)="processPassword()" [disabled]="!isPasswordValid">Submit</button>
                </div>
            </form>
        </div>
    </div>
</section>