import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// NON-FORMS
import { HomeComponent } from './components/home/home';
import { ContactUsComponent } from './components/contact-us/contact-us';
import { FAQComponent } from './components/faq/faq';
import { DashboardComponent } from './components/dashboard/dashboard';
import { ProfileComponent } from './components/profile/profile';
import { RenewalRedirectComponent } from './components/renewal-redirect/renewal-redirect.component';
import { PaymentRedirectComponent } from './components/payment-redirect/payment-redirect';

// FORMS
import { LoginRegisterComponent } from './components/forms/login-register/login-register';
import { ChangePasswordComponent } from './components/forms/change-password/change-password.component';
import { FormComponent } from './components/forms/form/form';
import { PaymentAndRenewalComponent } from './components/forms/payment-and-renewal/payment-and-renewal';

// FORMS (QUOTATION)
import { IntroductionComponent } from './components/forms/quotation/introduction/introduction';
import { ChoosePlanComponent } from './components/forms/quotation/choose-plan/choose-plan';
import { VehicleDetailsComponent } from './components/forms/quotation/vehicle-details/vehicle-details';
import { PersonalDetailsComponent } from './components/forms/quotation/personal-details/personal-details.component';
import { PropertyAssessmentComponent } from './components/forms/quotation/property-assessment/property-assessment';
import { PropertyDetailsComponent } from './components/forms/quotation/property-details/property-details';
import { CoveragePerilsComponent } from './components/forms/quotation/coverage-perils/coverage-perils';
import { RevPremChargesComponent } from './components/forms/quotation/rev-prem-charges/rev-prem-charges';
import { EmailPrintComponent } from './components/forms/quotation/email-print/email-print';
import { RequestQuotationComponent } from './components/request-quotation/request-quotation';

// FORMS (POLICY)
import { PolCustomerInformationComponent } from './components/forms/policy/customer-information/customer-information';
import { AcceptanceComponent } from './components/forms/policy/acceptance/acceptance';
import { PaymentComponent } from './components/forms/policy/payment/payment';
import { EmailPrintPolicyComponent } from './components/forms/policy/email-print/email-print';
import { PolSummaryComponent } from './components/forms/policy/pol-summary/pol-summary.component';

// APP MODULE PROVIDERS
import { ConfirmLeaveGuard } from './guards/confirm-leave.guard';
import { CheckJsonDataGuard } from './guards/check-json-data.guard';

// FORMS (RENEWAL & PAYMENTS)
import { PolicyDetailsValidationComponent } from './components/forms/payment-and-renewal/policy-details-validation/policy-details-validation';

const routes: Routes = [
  	{ path: "", component: HomeComponent, canDeactivate:[CheckJsonDataGuard] },
  	{ path: "login", component: LoginRegisterComponent },
  	{ path: "register", component: LoginRegisterComponent },
  	{ path: "quotation", component: FormComponent, canDeactivate:[CheckJsonDataGuard] ,
      children: [
      	{ path: "", component: IntroductionComponent },
      	{ path: "choose-plan", component: ChoosePlanComponent ,canDeactivate:[CheckJsonDataGuard]},
      	{ path: "vehicle-details", component: VehicleDetailsComponent, canDeactivate:[CheckJsonDataGuard] },
        { path: "personal-details", component: PersonalDetailsComponent },
		{ path: "property-assessment", component: PropertyAssessmentComponent },
        { path: "property-details", component: PropertyDetailsComponent, canDeactivate:[CheckJsonDataGuard] },
      	{ path: "coverage-perils", component: CoveragePerilsComponent, canDeactivate: [CheckJsonDataGuard] },
        { path: "review-premium-charges", component: RevPremChargesComponent, canDeactivate:[CheckJsonDataGuard] },
      	{ path: "account", component: LoginRegisterComponent },
      	{ path: "email-print", component: EmailPrintComponent, canDeactivate:[CheckJsonDataGuard] }
      ]
  	},
  	{ path: "policy", component: FormComponent,
      children: [
        { path: "vehicle-details", component: VehicleDetailsComponent , canDeactivate:[CheckJsonDataGuard]},
        { path: "personal-details", component: PersonalDetailsComponent },
        { path: "property-details", component: PropertyDetailsComponent, canDeactivate:[CheckJsonDataGuard] },
		{ path: "customer-information", component: PolCustomerInformationComponent , canDeactivate:[CheckJsonDataGuard] },
		{ path: "acceptance", component: AcceptanceComponent , canDeactivate:[CheckJsonDataGuard] },
		{ path: "payment", component: PaymentComponent , canDeactivate:[CheckJsonDataGuard] },
		{ path: "email-print", component: EmailPrintPolicyComponent , canDeactivate:[CheckJsonDataGuard] },
        { path: "summary", component: PolSummaryComponent , canDeactivate:[CheckJsonDataGuard] }
      ]
  	},
	{ path: "dashboard", component: DashboardComponent, canDeactivate:[CheckJsonDataGuard] },
	{ path: "profile", component: ProfileComponent ,canDeactivate:[CheckJsonDataGuard] },
	{ path: "change-password", component: ChangePasswordComponent},
	{ path: "contact-us", component: ContactUsComponent },
	{ path: "faq", component: FAQComponent },
    { path: "renewal", component: RenewalRedirectComponent },
    { path: "payment", component: PaymentRedirectComponent },
	{ path: "request-quotation", component: RequestQuotationComponent, canDeactivate:[CheckJsonDataGuard]  },
	{ path: "renewal-payment", component: PaymentAndRenewalComponent, canDeactivate:[CheckJsonDataGuard] ,
      children: [
      	{ path: "", component: IntroductionComponent },
		{ path: "policy-details", component: PolicyDetailsValidationComponent , canDeactivate:[CheckJsonDataGuard]},
	  ]
	},
	{ path: "renewal-and-payments", component: HomeComponent },
  	{ path: "**", redirectTo: "", pathMatch: "full" }
];

@NgModule({
  	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  	exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponents = [
  	HomeComponent,
    ContactUsComponent,
	FAQComponent,
    DashboardComponent,
    ProfileComponent,
    RenewalRedirectComponent,
    PaymentRedirectComponent,
    LoginRegisterComponent,
    ChangePasswordComponent,
  	FormComponent,
  	IntroductionComponent,
  	ChoosePlanComponent,
    VehicleDetailsComponent,
    PersonalDetailsComponent,
	PropertyAssessmentComponent,
    PropertyDetailsComponent,
  	CoveragePerilsComponent,
    RevPremChargesComponent,
  	EmailPrintComponent,
	PolCustomerInformationComponent,
	AcceptanceComponent,
	PaymentComponent,
    EmailPrintPolicyComponent,
    PolSummaryComponent,
	RequestQuotationComponent
]