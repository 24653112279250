import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
	selector: 'sub-login',
  	templateUrl: './sub-login.html'
})
export class SubLoginComponent {

    @Input() parentChildCommon:any;

  	showEmailField:boolean = true;
  	showUserIdField:boolean = false;
  	showPasswordValue:boolean = false;

  	form:FormGroup = this.formBuilder.group ({
    	accountType: ["", [Validators.required]],
		email: [""],
		userId: [""],
    	password: ["", [Validators.required]]
  	});

    varAccountTypes:any = [];

  	constructor (
        public router: Router,
		public formService: FormService,
		private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService
  	) {
        for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.USER_TYPE")) {
            this.varAccountTypes.push({
                value: refCd.rvLowValue,
                text: refCd.rvMeaning
            });
        }

		this.form.controls["accountType"].setValue("P");
		this.checkAccountType();
    }

    // MOVE THIS FUNCTION TO login.ts
  	checkAccountType():void {
    	this.showEmailField = false;
    	this.showUserIdField = false;

		this.form.controls["email"].reset();
		this.form.controls["userId"].reset();
    	this.form.controls["password"].reset();

    	if (this.form.value.accountType === "P") {
			this.showEmailField = true;
			this.form.controls["email"].setValidators([Validators.required, Validators.email]);
			this.form.controls["userId"].clearValidators();
			this.form.controls["email"].setValue("");
    	} else { 
			this.showUserIdField = true;
			this.form.controls["email"].clearValidators();
			this.form.controls["userId"].setValidators([Validators.required]);
			this.form.controls["userId"].setValue("");
		}

		this.form.controls["email"].updateValueAndValidity();
        this.form.controls["userId"].updateValueAndValidity();
  	}

    // MOVE THIS FUNCTION TO login.ts
  	processLogin():void {
        this.jsonDataService.contorlLoading(false);
    	if (this.form.valid) {
      		let form = this.form.value;
      		let accountType = form.accountType;
      		let password = form.password;
      		let un = accountType === "P" ? form.email : form.userId.toUpperCase();

            this.login({ un: un, password: password });
    	} else {
            this.formService.showFormMsg("loginFormMsg", "Please fill all the required fields.", "E");
    	}
  	}

    login(params:any):void {
        this.parentChildCommon.accountType = this.form.value.accountType;
        this.parentChildCommon.userName = params.un;
        this.jsonDataService.contorlLoading(true);
        this.parentChildCommon.loginFunction(params.password).subscribe((response:any) => {
            this.jsonDataService.contorlLoading(false);
            this.formService.showFormMsg("loginFormMsg", response, "E");
        });
    }

	goToForm(formName: string) {
		this.parentChildCommon.goToForm(formName);
	}
}