<section id="contact-us">
    <div class="container arial">
        <div class="case top-border border4">
            <form [formGroup]="form">
                <div id="contact-us-form">
                    <div class="form-fields">
                        <h2>Contact Us</h2>
                        <div class="form">
                            <div class="col-case">
                                <div class="field required">
                                    <label>Name</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="clientName" tabindex="1" uppercase trim>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'clientName')">
                                        {{formService.getFieldErrorMsg(form, "clientName")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case col-2">
                                <div class="field required">
                                    <label>Mobile Number</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="mobile" tabindex="2" mobileNo>
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'mobile')">
                                        {{customErrorMsg.mobile}}
                                    </p>
                                </div>
                                <div class="field required">
                                    <label>Email</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="email" tabindex="3">
                                    </mat-form-field>
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'email')">
                                        {{formService.getFieldErrorMsg(form, "email")}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-case">
                                <div class="field">
                                    <label>Message</label>
                                    <mat-form-field appearance="fill">
                                        <textarea matInput rows="3" formControlName="message" tabindex="4"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="this.lineCd === 'MC'">
                            <h2 class="vehicle-label">Vehicle Details</h2>
                            <div class="form">
                                <div class="col-case col-2">
                                    <div class="field">
                                        <label>Year Model</label>
                                        <mat-form-field appearance="fill">
                                            <input matInput formControlName="yearModel" tabindex="5">
                                        </mat-form-field>
                                    </div>
                                    <div class="field">
                                        <label>Brand</label>
                                        <mat-form-field appearance="fill">
                                            <input matInput formControlName="brand" tabindex="6" uppercase>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-case">
                                    <div class="field">
                                        <label>Model and Variant</label>
                                        <mat-form-field appearance="fill">
                                            <input matInput formControlName="model" tabindex="7" uppercase>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-case">
                                    <div class="field">
                                        <label>Car Value / Price</label>
                                        <mat-form-field appearance="fill">
                                            <input matInput formControlName="srp" tabindex="8" amount>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.lineCd === 'HA'">
                        <h2 class="vehicle-label">Property Details</h2>
                        <div class="form">
                            <div class="col-case">
                                <div class="field required">
                                    <label>Complete Address</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="compAddress" tabindex="9" uppercase trim>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-case col-2">
                            <div class="field required">
                                <label>Province</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder=" Province" formControlName="province" [matAutocomplete]="autoProvinceDetail" (input)="setProvinceViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoProvinceDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setProvinceViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredProvinceOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="field required">
                                <label>City</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="City" formControlName="city" [matAutocomplete]="autoCityDetail" (input)="setCityViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoCityDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setCityViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredCityOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            </div>
                            <div class="col-case">
                                <div class="field required">
                                    <label>Name of Subdivision</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="subdivision" tabindex="12" uppercase trim>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        </ng-container>
                        <div class="invoice-box" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                            <p *ngIf="this.lineCd === 'MC'">Drag Sales Invoice Image here</p>
                            <p *ngIf="this.lineCd != 'MC'">Drag Image here</p>
                            <p>or</p>
                            <button class="btn btn3 proxima" (click)="fileInput.click()">Upload Image</button>

                            <input hidden (change)="onFileChange($event)" #fileInput type="file" id="file" multiple>
                            <div class="attach-preview image" *ngIf="emailParams.attach.length > 0">
                                <ng-container *ngFor="let x of emailParams.attach; index as i">
                                    <div class="preview" *ngIf="x.type.indexOf('image') !== -1">
                                        <img [src]="x.path">
                                        <span class="delete" (click)="onClickDelete(i)">X</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="attach-preview pdf-name" *ngIf="emailParams.attach.length > 0">
                                <ng-container *ngFor="let x of emailParams.attach; index as i">
                                    <div class="preview" *ngIf="x.type.indexOf('image') === -1">
                                        <span class="pdf-filename">{{x.filename}}</span>
                                        <span class="delete" (click)="onClickDelete(i)">X</span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <p class="field-error" *ngIf="formService.isInvalid(form, 'invoiceImg')">
                            {{customErrorMsg.fileType}}
                        </p>
                        <p class="field-error" *ngIf="formService.isInvalid(form, 'invoiceImg')">
                            {{customErrorMsg.fileSize}}
                        </p>
                        <div *ngIf="attachCaveat" class="col-case attach-caveat montserrat">
                            <p [innerHTML]="attachCaveat"></p>
                        </div>
                    </div>
                </div>
                <div class="form-btns">
                    <button class="btn btn3" (click)="processContactForm()">Submit</button>
                </div>
            </form>
        </div>

        <div class="support case montserrat">
            <div class="support-container">
                <div class="image"></div>
                <div class="info">
                    <h2>Customer Support</h2>
                    <p>{{custDesc}}</p>
                    <div class="sub-info" *ngIf="custNumber">
                        <p>Customer Service</p>
                        <p>{{custNumber}}</p>
                    </div>
                    <div class="sub-info" *ngIf="custFaxNo">
                        <p>Fax</p>
                        <p>{{custFaxNo}}</p>
                    </div>
                    <div class="sub-info" *ngIf="custEmail">
                        <p>Email</p>
                        <p>{{custEmail}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>