import { Injectable } from '@angular/core';
import { JsonDataService } from 'src/app/services/json-data.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

    private parameters:any;

    constructor (
        private jsonDataService: JsonDataService
    ) {
        this.parameters = this.jsonDataService.data.params;
    }

    paramV(paramName:string):string {
        let paramValue;
        for (const param of this.parameters) {
            if (param.paramName === paramName) {
                paramValue = param.paramValueV;
            }
        }
        return paramValue;
    }

    paramN(paramName:string):number {
        let paramValue;
        for (const param of this.parameters) {
            if (param.paramName === paramName) {
                paramValue = param.paramValueN;
            }
        }
        return paramValue;
    }
}