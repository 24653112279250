<div id="message-box" *ngIf="messageBoxService.showMsgBox">
    <div id="overlay"></div>
    <div id="content">
        <h2>{{ messageBoxService.header }}</h2>
        <div class="message" [innerHTML]="messageBoxService.message"></div>
        <div class="btns">
            <button class="btn btn2" *ngFor="let btn of messageBoxService.btns" (click)="btn.btnFunc()">
                {{ btn.btnLbl }}
            </button>
        </div>
    </div>
</div>
