import { Component } from '@angular/core';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';

@Component({
  templateUrl: './form.html'
})
export class FormComponent {
    constructor (
        public stepperDtlService: StepperDtlService
    ) { }
}