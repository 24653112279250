<section id="payment">
    <div class="container montserrat">
        <div class="form" [class.no-boder]="userDetailsService.accountType!='P'">
            <div class="purchase-info">
                <div class="top-border border2">
                    <div>
                        <h2>Policy to Purchase</h2>
                        <div class="policy">
                            <img src="./assets/images/policy/payment/policy-icon-blurred.jpg">
                            <div class="policy-info">
                                <div class="info">
                                    <p>Policy No.</p>
                                    <p>{{productService.productInfo.policyNo}}</p>
                                </div>
                                <div class="info">
                                    <p>Payment Ref. No.</p>
                                    <p>{{productService.productInfo.paymentInfo.paytRefNo}}</p>
                                </div>
                                <div class="info">
                                    <p>Coverage Period</p>
                                    <p>{{productService.productInfo.coverageInfo?.effDate | date:'MM/dd/yyyy'}} - {{productService.productInfo.coverageInfo?.expDate | date:'MM/dd/yyyy'}}</p>
                                </div>
                                <div class="amount-sp">
                                    <div class="amounts">
                                        <div class="amount" *ngFor="let item of paytBreakdown">
                                            <p>{{item?.name}}</p>
                                            <p>{{item?.value | currency: 'PHP' : '₱ '}}</p>
                                        </div>
                                    </div>
                                    <div class="amount">
                                        <p>Total Amount</p>
                                        <p>{{totalAmount | currency: 'PHP': '₱ '}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="amounts">
                            <div class="amount" *ngFor="let item of paytBreakdown">
                                <p>{{item?.name}}</p>
                                <p>{{item?.value | currency: 'PHP' : '₱ '}}</p>
                            </div>
                        </div>
                        <div class="amount">
                            <p>Total Amount</p>
                            <p>{{totalAmount | currency: 'PHP': '₱ '}}</p>
                        </div>
                    </div>
                </div>
                <a id="generate-payment-link-btn" class="btn btn2" (click)="openPaytLinkSendOpts()" *ngIf="showSendPaymentLinkBtn">
                    Send {{ selectedPaytOptId !== 6 ? "Payment Instructions" : "notification" }} to Customer
                </a>
                <p id="gen-link-text" *ngIf="showSendPaymentLinkBtn && genLinkText">{{genLinkText}}</p>
            </div>
            <div class="purchase-form" [class.no-boder]="userDetailsService.accountType=='P'">
                <div class="payment-options">
                    <h2>Select Payment Options</h2>
                    <div class="options">
                        <div class="option" *ngFor="let option of paytOptions" (click)="setSelectedPaymentMethod(option)" [class.selected]="selectedPaytOptId == option.paytCd">
                            <div [class.selected]="selectedPaytOptId === option.paytCd">
                                <img [src]="option.imagePath">
                            </div>
                            <p>{{option.paytDesc}}</p>
                        </div>
                    </div>
                </div>
                <div class="payment-form">
                    <ng-container *ngIf="[2, 6].indexOf(selectedPaytOptId) < 0">
                        <ng-container *ngTemplateOutlet="paynamicsPaymentForm"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="[2].indexOf(selectedPaytOptId) >= 0">
                        <ng-container *ngTemplateOutlet="bpiPaymentForm"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="[6].indexOf(selectedPaytOptId) >= 0">
                        <ng-container *ngTemplateOutlet="payrollDeductionPaymentForm"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form terms" *ngIf="userDetailsService.userId && userDetailsService.accountType !== 'P' && ((paytTerms.paytTermsTag === 'Y' && paytTerms.paytTermsPath) || selectedPaytOptId === 6)">
            <div class="purchase-info"></div>
            <div class="terms-form">
                <div class="terms">
                    <ng-container>
                        <div class="col-case">
                            <mat-checkbox (ngModelChange)="updateIsAccepted($event)" [ngModel]="isAccepted"></mat-checkbox>
                            <div class='terms-text'><span [innerHTML]="termsText"></span></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-btns">
            <button class="btn btn4" (click)="move('back')">Back</button>
            <button class="btn btn5" (click)="move('next')" *ngIf="userDetailsService.accountType !== 'P' && userDetailsService.userId">Skip</button>
            <!-- <button class="btn btn2" (click)="pay()" [disabled]="(!isAccepted && userDetailsService.accountType !== 'P') || paytOptions.length === 0">Pay</button> -->
            <button class="btn btn2" (click)="pay()" [disabled]="(!isAccepted && userDetailsService.accountType !== 'P') || paytOptions.length === 0 || selectedPaytOptId === 0 || (this.noBpiAcct && this.selectedPaytOptId === 2)">Pay</button>
        </div>
    </div>
</section>

<!-- PAYMENT OPTIONS -->
<ng-template #paynamicsPaymentForm>
    <form [formGroup]="creditDebitForm" class="credit-debit-form">
        <div class="form">
            <div class="col-case" *ngIf="selectedPaytOptId === 4">
                <div class="field required">
                    <label>Choose Credit Card</label>
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="installmentType">
                            <mat-option *ngFor="let ccInstallment of jsonDataService.data.ccInstallments" [value]="ccInstallment.ccInstCd">
                                {{ccInstallment.ccInstDesc}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'installmentType')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "installmentType") }}
                    </p>
                </div>
            </div>
            <div class="col-case col-2">
                <div class="field required">
                    <label>First Name</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="firstName" placeholder="First Name">
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'firstName')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "firstName") }}
                    </p>
                </div>
                <div class="field required">
                    <label>Last Name</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="lastName" placeholder="Last Name">
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'lastName')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "lastName") }}
                    </p>
                </div>
            </div>
            <div class="col-case">
                <div class="field required">
                    <label>Complete Address</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="address" placeholder="Complete Address" uppercase trim>
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'address')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "address") }}
                    </p>
                </div>
            </div>
            <div class="col-case">
                <div class="field required">
                    <label>City</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="city" placeholder="City">
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'city')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "city") }}
                    </p>
                </div>
            </div>
            <div class="col-case">
                <div class="field required">
                    <label>Postal Code</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="postalCode" placeholder="Postal Code">
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'postalCode')">
                        {{ formService.getFieldErrorMsg(creditDebitForm, "postalCode") }}
                    </p>
                </div>
            </div>
            <div class="col-case">
                <div class="field required">
                    <label>Email Address</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="email" placeholder="Email Address" email>
                    </mat-form-field>
                    <p class="field-error" *ngIf="formService.isInvalid(creditDebitForm, 'email')">
                        {{formService.getFieldErrorMsg(creditDebitForm, "email")}}
                    </p>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #bpiPaymentForm>
    <div *ngIf="noBpiAcct" class="login-bpi-cont">
        <button class="btn btn2 proxima" [disabled]="loadingAccts" (click)="toBpiLogin()">Sign in to your BPI Account</button>
    </div>
    <form [formGroup]="bpiForm" *ngIf="!noBpiAcct">
        <div class="form">
            <div class="form-note weighted">
                <p>Use another BPI Account. <a (click)="toBpiLogin()">Click Here</a></p>
            </div>
            <div class="col-case">
                <div class="field required">
                    <label>Account Name / Account No.</label>
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="account">
                            <mat-option *ngFor="let account of bpilAccounts" [value]="account">
                                {{account.accountPreferredName + ' / ' + account.accountNumber}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-case">
                <div class="field">
                    <label>Account Type</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="accountType">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #payrollDeductionPaymentForm>
    <form [formGroup]="payrollDeductionForm" class="credit-debit-form">
        <div class="col-case col-2">
            <div class="field required">
                <label>First Name</label>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="firstName" placeholder="First Name">
                </mat-form-field>
                <p class="field-error" *ngIf="formService.isInvalid(payrollDeductionForm, 'firstName')">
                    {{ formService.getFieldErrorMsg(payrollDeductionForm, "firstName") }}
                </p>
            </div>
            <div class="field required">
                <label>Last Name</label>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="lastName" placeholder="Last Name">
                </mat-form-field>
                <p class="field-error" *ngIf="formService.isInvalid(payrollDeductionForm, 'lastName')">
                    {{ formService.getFieldErrorMsg(payrollDeductionForm, "lastName") }}
                </p>
            </div>
        </div>
        <div class="col-case">
            <div class="field required">
                <label>Employee No</label>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="employeeNo" placeholder="Employee No" class="numbers">
                </mat-form-field>
                <p class="field-error" *ngIf="formService.isInvalid(payrollDeductionForm, 'employeeNo')">
                    {{ formService.getFieldErrorMsg(payrollDeductionForm, "employeeNo") }}
                </p>
            </div>
        </div>
        <div class="col-case">
            <div class="field required">
                <label>Email Address</label>
                <mat-form-field appearance="fill">
                    <input matInput formControlName="email" placeholder="Email Address" email>
                </mat-form-field>
                <p class="field-error" *ngIf="formService.isInvalid(payrollDeductionForm, 'email')">
                    {{ formService.getFieldErrorMsg(payrollDeductionForm, "email") }}
                </p>
            </div>
        </div>
    </form>
</ng-template>
<!-- END OF PAYMENT OPTIONS -->

<!-- OTP -->
<ng-template #otpTemplate>
    <mat-dialog-content>
        <div class="otp-processing-container" *ngIf="otpDisplay == 'processing'">
            <mat-spinner class="processing" diameter="100" color="red"></mat-spinner>
        </div>
        <div class="otp-container" *ngIf="otpDisplay !== 'processing'">
            <ng-container [ngSwitch]="otpDisplay">
                <ng-container *ngSwitchCase="'send'">
                    <!-- <img [src]="otpIconLoc" class="icon" /> -->
                    <div class="weighted x-large">One-Time PIN</div>
                    <div>To proceed, a 6-digit code will be sent via SMS.</div>
                    <div class="weighted otp-message-top">Is this your number?</div>
                    <div class="weighted otp-message-bottom larger">{{otpObj.mobileNumber}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'input'">
                    <!-- <img [src]="otpIconLoc" class="icon" /> -->
                    <div class="weighted x-large">One-Time PIN</div>
                    <div>Enter 6-digit code sent to</div>
                    <div class="weighted">{{otpObj.mobileNumber}}</div>
                    <div class="input-container">
                        <form [formGroup]="otpForm">
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp1" #otpInputRef (focus)="onOtpInputFocus(1)" [bpiOtpInput]="1" [otpInputRefList]="otpInputRefList" >
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp2" #otpInputRef (focus)="onOtpInputFocus(2)" [bpiOtpInput]="2" [otpInputRefList]="otpInputRefList" >
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp3" #otpInputRef (focus)="onOtpInputFocus(3)" [bpiOtpInput]="3" [otpInputRefList]="otpInputRefList" >
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp4" #otpInputRef (focus)="onOtpInputFocus(4)" [bpiOtpInput]="4" [otpInputRefList]="otpInputRefList" >
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp5" #otpInputRef (focus)="onOtpInputFocus(5)" [bpiOtpInput]="5" [otpInputRefList]="otpInputRefList" >
                            <input matInput autocomplete inputmode="numeric" pattern="\d*" formControlName="otp6" #otpInputRef (focus)="onOtpInputFocus(6)" [bpiOtpInput]="6" [otpInputRefList]="otpInputRefList" >
                        </form>
                    </div>
                    <div class="resend" (click)="sendOTP()" *ngIf="!sendingOtp">&nbsp;Resend code via SMS</div>
                    <div class="error-msg">&nbsp;{{otpError}}</div>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="'processing'">
                    <div class="weighted x-large">Processing Payment</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <mat-spinner class="processing" diameter="100" color="red"></mat-spinner>
                </ng-container> -->
            </ng-container>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="otp-actions" *ngIf="otpDisplay !== 'processing'">
        <ng-container [ngSwitch]="otpDisplay" *ngIf="!otpLoading">
            <button mat-button class="btn2" (click)="sendOTP()" *ngSwitchCase="'send'">Yes. Send me the code.</button>
            <button mat-button class="btn2" (click)="submitOTP()" *ngSwitchCase="'input'">Submit</button>
        </ng-container>
        <button mat-button class="btn2" *ngIf="otpLoading">
            <span>&nbsp;</span>
            <mat-spinner *ngIf="otpLoading" diameter="24" color="white"></mat-spinner>
        </button>
    </mat-dialog-actions>
</ng-template>