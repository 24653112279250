import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[mobileNo]',
    providers: [{provide: NG_VALIDATORS, useExisting: MobileNoDirective, multi: true}]
})
export class MobileNoDirective implements AfterViewInit,Validator {

    constructor (
        private el: ElementRef
    ) { }

    validate(control: AbstractControl): ValidationErrors | null {
        const allowed = /(^09)[0-9]{9}$/g.test(control.value) || control.value == '';
        return !allowed ? {'mobileNo': true} : null;
    }

    ngAfterViewInit():void {
       this.el.nativeElement.placeholder = '09XXXXXXXXX';
    }

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener("keydown", ["$event"])
    onKeyDown(e:KeyboardEvent) {
        if (
               this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
            || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
            || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
            || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
            || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
            || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
            || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
            || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
            || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        if (e.shiftKey || (!parseInt(e.key) && parseInt(e.key) !== 0)) {
            e.preventDefault();
        }
    }

    @HostListener("paste", ["$event"])
    onPaste(event:ClipboardEvent) {
        event.preventDefault();

        const pastedInput = event.clipboardData?.getData("text/plain").replace(/[^0-9\.]/g, "");
        pastedInput && document.execCommand("insertText", false, pastedInput);
    }
}