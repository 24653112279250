<section id="login-register" [class.solo]="form !== 'both'">
    <div class="container arial" [class.solo]="form !== 'both'">
        <div class="buttons">
            <button id="btn-register" (click)="showCase('register')">Register</button>
            <button id="btn-login" (click)="showCase('login')">Login</button>
        </div>
        <div class="case reg-case top-border border4" [class.show]="form === 'both'" *ngIf="showForm('register')">
            <registration (changeForm)="form = $event"></registration>
        </div>
        <div class="case login-case top-border border4 bg1" *ngIf="showForm('login')">
            <login></login>
        </div>
    </div>
</section>