import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";

export class ApiTableDataSource implements DataSource<any> {

    private dataSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public apiCall:any;
    public service:any;
    public length:any = 0;
    public dataList:any;
    public filteredList:any;
    public filtered:boolean = false;
    public type:string = "";

    constructor() { }

    loadData(params:any):void {
        if(this.type === "clients") {
            this.loadingSubject.next(true);
            this.service[this.apiCall](params).pipe(
                    catchError(() => of([])),
                    finalize(() => this.loadingSubject.next(false)))
                .subscribe((data:any) => {
                    this.dataList = data.content;
                    const initialData = this.dataList ? this.dataList.filter((initData: any) => {
                        return (initData.rownum_ > 0 && initData.rownum_ <= 10)
                    }) : [];
                    // this.dataSubject.next(data.content); previous implementation
                    this.dataSubject.next(initialData); //To handle sending of multiple requests per keyup
                    this.length = this.dataList ? this.dataList.length : 0;
                });
        }
    }

    navigateData(params:any):void { // filtering of data based on pagination
        if(this.type === "clients") {
            if(this.filtered) {
                let navigatedData = this.filteredList.filter((nav: any, index: number) => {
                    return index+1 > params.start && index+1 <= (params.start + params.length) 
                });
                this.dataSubject.next(navigatedData);
            } else {
                let navigatedData = this.dataList.filter((nav: any) => {
                    return nav.rownum_ > params.start && nav.rownum_ <= (params.start + params.length)
                });
                this.dataSubject.next(navigatedData);
            }
        }
    }

    filterData(params:any):void { // filtering of data based on search input
        if(this.type === "clients") {
            if(this.dataList) {
                if(params.search === "") {
                    let filteredData = this.dataList.filter((initData: any) => {
                        return (initData.rownum_ > 0 && initData.rownum_ <= 10)
                    })
                    this.filteredList = "";
                    this.dataSubject.next(filteredData);
                    this.length = this.dataList.length;
                    this.filtered = false;
                } else {
                    let filteredData = this.dataList.filter((search: any) => {
                        return search.displayName.indexOf(params.search) > -1
                    });
                    this.filteredList = filteredData;
                    filteredData = this.filteredList.length > 10 ? filteredData.filter((filter: any, i: number) => {
                        return i < 10
                    }) : filteredData;
                    this.dataSubject.next(filteredData);
                    this.length = this.filteredList.length;
                    this.filtered = true;
                }
            }
        }
    }

    connect(collectionViewer: CollectionViewer):Observable<any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer):void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}