<overlay #overlay [title]="LOV_TYPES[this.type].title || 'List of Values'">
    <div content id="lov-container">
        <mat-form-field appearance="fill">
            <input matInput placeholder="Search..." #input>
        </mat-form-field>
        <div class="table-container">
            <table mat-table class="" [dataSource]="dataSource" matSort matSortDisableClear>

                <ng-container *ngFor="let col of LOV_TYPES[type].colDefs" matColumnDef="{{col.key}}">
                    <mat-header-cell *matHeaderCellDef class="number" arrowPosition="before"> {{col.header}} </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="number"> {{row[col.key]}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="LOV_TYPES[type].displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: LOV_TYPES[type].displayedColumns" (click)="onClick($event,row)" [class.selected]="selectedRow?.rownum_ == row.rownum_"></mat-row>
            </table>
        </div>
        <div class="spinner-container" *ngIf="dataSource?.loading$ | async">
            <mat-spinner></mat-spinner>
        </div>

        <mat-paginator [length]="dataSource?.length" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true"></mat-paginator>
    </div>


    <button mat-button buttons class="btn-primary" (click)="onClickOk()">Ok</button>
</overlay>