import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { JsonDataService } from 'src/app/services/json-data.service';
import { ProductService } from 'src/app/services/product.service';
import { FAQService } from 'src/app/services/faq.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

import { MessageBoxService } from 'src/app/services/message-box.service';

declare var jquery:any;
declare var $:any;

interface StringIndexedObject {
    [key: string]: any;
  }

@Component({
    templateUrl: './home.html'
})
export class HomeComponent implements OnInit {

    paramParams: any[];
    paramLines: any[];
    paramSublines: any[];
    paramRefCds: any[];
    paramSublinePurposes: any[];
    faqProduct: any[];
    faq: any[];
    faqPaytRenewal: any[];

    productLine: string = "*";
    purpose: number = 0;
    noOfProductsResult: number = 0;
    showSelectProductList: boolean = false;
    purposes: any[] = [];
    products: any = {};

    faqGroupCd: number = 0;
    faqPaytRenewalGroupCd: number = 0;

    contents: StringIndexedObject = {
        'COM': 'Motor_View_Content',
        'CTPL': 'Liability_View_Content',
        'FPA': 'Family_View_Content',
        'IND': 'Personal_View_Content',
        'HCA': 'Home_View_Content'
    };

    constructor (
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router,
        private userDetailsService: UserDetailsService,
        private jsonService: JsonDataService,
        private productService: ProductService,
        private faqService: FAQService,
        private msgBox: MessageBoxService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.faqGroupCd = this.jsonService.data.refCds.filter((a: any) => a.rvDomain === "BMM074.GROUP_CD" && a.rvMeaning === 'Main')[0]['rvLowValue']*1;
        this.faqPaytRenewalGroupCd = this.jsonService.data.refCds.filter((a: any) => a.rvDomain === "BMM074.GROUP_CD" && a.rvMeaning === 'Renewal and Payments')[0]['rvLowValue']*1;

        this.paramParams = this.jsonService.data.params;
        this.paramRefCds = this.jsonService.data.refCds;
        this.paramSublinePurposes = this.jsonService.data.sublinePurposes;
        this.faqProduct = this.jsonService.data.faqProduct;
        this.faq = this.jsonService.data.faq.filter((a: any) => a.groupCd === this.faqGroupCd);
        this.faqPaytRenewal = this.jsonService.data.faq.filter((a: any) => a.groupCd === this.faqPaytRenewalGroupCd);

        this.paramLines = [];
        for (const line of this.jsonService.data.lines) {
            if (line.userTypeView) {
                if (line.userTypeView.split(",").indexOf(this.userDetailsService.accountType || "P") >= 0) {
                    this.paramLines.push(line);
                }
            } else {
                this.paramLines.push(line);
            }
        }

        this.paramSublines = [];
        for (const subline of this.jsonService.data.sublines) {
            for (const line of this.paramLines) {
                if (line.lineCd === subline.lineCd) {
                    if (subline.userTypeView) {
                        if (subline.userTypeView.split(",").indexOf(this.userDetailsService.accountType || "P") >= 0) {
                            this.paramSublines.push(subline);
                        }
                    } else {
                        this.paramSublines.push(subline);
                    }

                    break;
                }
            }
        }

        // RESET QUOTATION INFORMATION
        this.productService.setProductInfo2("");

        this.setLineAndProduct();
    }

    ngOnInit(): void {
        $(".banner").bxSlider({
            auto: true,
            pager: true,
            controls: false,
            infiniteLoop: true,
            slideWidth: 944
        });
    }

    private setLineAndProduct(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params?.p) {
                try {
                    this.productLine = JSON.parse(this.jsonService.decrypt(p.params.p));

                    let lineFound = false;
                    for (const line of this.paramLines) {
                        if (this.productLine === line.lineCd) {
                            lineFound = true;
                            break;
                        }
                    }

                    if (!lineFound) {
                        throw Error();
                    }

                    this.location.replaceState("/");
                } catch (e) {
                    this.router.navigateByUrl("/");
                }
            } else {
                for (const line of this.paramLines) {
                    if (line.defaultTag === "Y") {
                        this.productLine = line.lineCd;
                    }
                }
            }

            this.setProductLineAndPurpose(this.productLine);

            // Sample link (PA)
            // https://www.osp-devt-r2.com?p=U2FsdGVkX1%2FYcTO4JeL8e2HJw2qqyDHk%2FpWR4%2FppxHc%3D

            // List of lines
            // CI - U2FsdGVkX19IrWcpgb3skYhgbPHqS347gn0D%2Fa34Tz8%3D
            // FI - U2FsdGVkX19f3zrTQuP5VuOgc%2BAmKy76l0ETnuuVJ3E%3D
            // HA - U2FsdGVkX18NTotve3bVCwbEeFl%2BhdXNeyU9cIEvE5o%3D
            // MC - U2FsdGVkX19A7MknNM%2BTQTqnFF5cIGv9KEN8FzfMs1o%3D
            // PA - U2FsdGVkX1%2FYcTO4JeL8e2HJw2qqyDHk%2FpWR4%2FppxHc%3D
        });

        this.activatedRoute.url.subscribe((u: any) => {
            if(u.length > 0 && u[0].path === 'renewal-and-payments') {
                this.location.replaceState("/");
                this.productLine = 'paytRenewal';

                this.setProductLineAndPurpose(this.productLine);
            }
        });
    }

    // REINITIALIZE THE PRODUCTS AND PURPOSE PER LINE
    setProductLineAndPurpose(lineCd:string):void {
        if(lineCd == 'paytRenewal') {
            this.showSelectProductList = false;
            this.productLine = lineCd;
            this.purpose = 0;
            this.purposes = [];
            this.noOfProductsResult = 1;
            this.products = [];

            var imagePath = this.paramParams.filter((a: any) => a.paramName == 'REN_PREMPAYT_LANDING_IMAGE')[0]['paramValueV'];
            var sublineName = this.paramParams.filter((a: any) => a.paramName == 'REN_PREMPAYT_LANDING_TITLE')[0]['paramValueV'];
            var shortDesc = this.paramParams.filter((a: any) => a.paramName == 'REN_PREMPAYT_LANDING_DESC')[0]['paramValueV'];
            var purpose = this.paramParams.filter((a: any) => a.paramName == 'REN_PREMPAYT_LANDING_PURPOSE')[0]['paramValueV'];

            this.purposes.push({
                rvLowValue: -1,
                rvMeaning: purpose
            });

            this.products.push({
                imagePath: imagePath,
                sublineName: sublineName,
                shortDesc: shortDesc
            });
        } else {
            this.showSelectProductList = false;
            this.productLine = lineCd;
            this.purpose = 0;
            this.getProductsData();
            this.getPurposes();
        }
    }

    private getProductsData(): void {
        this.noOfProductsResult = 0;

        this.products = [];
        for (let subline = 0; subline < this.paramSublines.length; subline++) {
            if (this.productLine === '*' || this.productLine === this.paramSublines[subline].lineCd) {
                let executeOnce = false;
                for (const sublinePurpose of this.paramSublinePurposes) {
                    if (   !executeOnce
                        && (   this.purpose === 0
                            || (   (this.productLine === '*' || this.productLine === sublinePurpose.lineCd)
                                && this.paramSublines[subline].sublineCd === sublinePurpose.sublineCd
                                && this.purpose === sublinePurpose.purposeCd))) {
                        executeOnce = true;
                        this.noOfProductsResult++;
                        this.products.push({
                            imagePath: this.paramSublines[subline].imagePath,
                            sublineName: this.paramSublines[subline].sublineName,
                            shortDesc: this.paramSublines[subline].shortDesc,
                            lineCd: this.paramSublines[subline].lineCd,
                            sublineCd: this.paramSublines[subline].sublineCd,
                            availTag: this.paramSublines[subline].availTag
                        });
                    }
                }
            }
        }
    }

    // GET THE PURPOSE/S PER PRODUCT LINE
    getPurposes(): void {
        this.purposes = [];
        for (const refCd of this.paramRefCds) {
            if (refCd.rvDomain === "BMM036.PURPOSE_CD") {
                for (const sublinePurpose of this.paramSublinePurposes) {
                    if ((this.productLine === "*" || this.productLine === sublinePurpose.lineCd) && String(sublinePurpose.purposeCd) === refCd.rvLowValue) {
                        this.purposes.push({
                            rvLowValue: refCd.rvLowValue,
                            rvMeaning: refCd.rvMeaning
                        });
                        break;
                    }
                }
            }
        }

        if (this.purposes.length === 0) {
            this.purposes.push({
                rvLowValue: -1,
                rvMeaning: "No Purpose Found"
            });
        }
    }

    // SET SPECIFIC PURPOSE AND REINITIALIZE THE PRODUCTS THAT WILL BE DISPLAYED
    public setProductPurpose (purposeCd: string): void {
        if (purposeCd != "-1") {
            this.purpose = Number(purposeCd);
            this.getProductsData();
        }
    }

    public createQuotation (lineCd: string, sublineCd: string): void {
        const triggerPixel = !!this.contents[sublineCd]
        if (triggerPixel) this.fbAnalyticsService.trackEvent(`${this.contents[sublineCd]}_Avail_Now`)
        
        this.productService.setProductInfo2({
            creationProcess: "quotation",
            lineCd: lineCd,
            sublineCd: sublineCd
        });

        const accountType = this.userDetailsService.accountType;
        if (accountType && accountType !== "P") {
            const accountName = '"' + this.userDetailsService.name + '"';
            if (accountName === this.jsonService.retrieveFromStorage("ndX93#SI$%mdpJ98D90d8c4m@f=")) {
                if (lineCd === "HA") {
                    this.productService.setProductInfo2({
                        creationProcess: "quotation",
                        lineCd: lineCd,
                        sublineCd: sublineCd,
                        planEvaluated: "N",
                        language: "E"

                    });
                    this.router.navigateByUrl("/quotation/property-assessment");
                } else {
                    this.router.navigateByUrl("/quotation/choose-plan");
                }
            } else {
                this.router.navigateByUrl("/quotation");
            }
        } else {
            this.router.navigateByUrl("/quotation");
        }
    }

    public goToPaytRenewal (): void {
        if(this.userDetailsService.userId !== '') {
            var acctType = this.jsonService.data.refCds.filter((a: any) => a.rvDomain === "BMM013.USER_TYPE" && a.rvLowValue === this.userDetailsService.accountType)[0]['rvMeaning'];
            var msg = 'You are currently logged-in to your ' + (acctType || 'Personal') + ' account. Please logout first in order to proceed.';

            this.msgBox.show1('E', msg, 'Ok', '');
        } else {
            this.productService.setProductInfo2({
                creationProcess: "paytRenewal",
                lineCd: "paytRenewal",
                sublineCd: "paytRenewal"
            });
    
            const accountType = this.userDetailsService.accountType;
            this.router.navigateByUrl("/renewal-payment");
        }
    }

    public setFAQ (lineCd: string, sublineCd: string, content?: string): void {
        const triggerPixel = !!this.contents[sublineCd]
        if (triggerPixel) this.fbAnalyticsService.trackEvent(`${this.contents[sublineCd]}_Learn_More`)

        this.faqService.faqInfo.lineCd = lineCd;
        this.faqService.faqInfo.sublineCd = sublineCd;
        this.faqService.faqInfo.content = [];

        if (this.faqService.faqInfo.sublineCd === "faq") {
            this.faqService.faqInfo.title = "";
            this.faqService.faqInfo.content = this.faq;
        } else if(this.faqService.faqInfo.sublineCd === "paytRenewal") {
            this.faqService.faqInfo.title = this.paramParams.filter((a: any) => a.paramName == 'REN_PREMPAYT_LANDING_TITLE')[0]['paramValueV'];
            this.faqService.faqInfo.content = this.faqPaytRenewal;
        } else {
            this.paramSublines.forEach((a:any) => {
                if (a.lineCd === lineCd && a.sublineCd === sublineCd) {
                    this.faqService.faqInfo.title = a.sublineName;
                }
            });
            this.faqProduct.forEach((a:any) => {
                if (a.lineCd === this.faqService.faqInfo.lineCd && a.subLineCd === this.faqService.faqInfo.sublineCd) {
                    this.faqService.faqInfo.content.push(a);
                }
            });
        }

        this.router.navigate(["/faq"]);
    }
}