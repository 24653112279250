import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { DownloadService } from 'src/app/services/download.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UtilityService } from 'src/app/services/utility.service';
import { mimeTypes } from 'mime-wrapper';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
  selector: 'personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalDetailsComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    existingClient: [""],
    policyHolderAdress: ["", [Validators.required]],
    principalLastName: ["", [Validators.required]],
    principalFirstName: ["", [Validators.required]],
    principalMiddleName: [""],
    principalSuffix: [""],
    principalBirthDate: ["", [Validators.required]],
    principalOccupation: ["", [Validators.required]],
    principalMaritalStatus: [""],
    supportingDocs: [[]],
    remarks: [""],
    age: [{value: "", disabled: true}],
  });

  rows: FormArray = this.formBuilder.array([]);
  tableForm = this.formBuilder.group({ 'dependents': this.rows });
  dependentsEdit: any = [];
  dependents: any = [];
  productInfo: any = {};
  lovParams: any = {
    userId:  this.userDetailsService.userId
  }
  registrationNo: any;
  assdNo: any;
  firstDependent: any = [];
  supportingDocsFiles: any[] = [];
  allowedFileExt: any[] = [];
  allowedFileSize: number = 0;
  paValidations: any;
  today: Date = new Date();
  deleteDependentsFlag: any = [true, true, true, true, true];
  
  // string parameters
  nextLabel: string = "Next: ";
  dependentTableActionLabel: string = "Add dependent";
  instructionText: string = "test instructions";
  supportDocErrorMsg: string = "";
  acceptedOcc: string = "";
  acceptedOccLine: string = "";
  validityPeriodErrorMessage: string = "";

  // boolean conditions
  editFlag: boolean = false;
  policyHolderSearch: boolean = false;
  individualFlag: boolean = true;
  occupationCaveat: boolean = false;
  withSupportingDocChange: boolean = false;
  editable: boolean = true;
  displaySupportDocs: boolean = false;
  validPrincipalBirthday: boolean = false;
  saveMessageFlag: boolean = false;

  //principal auto complete options
  filteredPrincipalOccupationOptions!: Observable<any>;
  filteredPrincipalMaritalStatusOptions!: Observable<any>;

  //dependent auto complete options
  filteredDependentsRelationshipOptions: Observable<any>[] = [];
  filteredDependentsOccupationOptions: Observable<any>[] = [];
  filteredDependentsMaritalStatusOptions: Observable<any>[] = [];

  dataSource!: MatTableDataSource<any>;
  dataSourceEdit!: MatTableDataSource<any>;
  displayedColumns: string[] = ["name", "relationship", "occupation", "maritalStatus", "birthDate", "age"];
  displayedColumnsEdit: string[] = ["deleteRow", "lastName", "firstName", "middleName", "suffix", "relationshipEdit", 
  "occupationEdit", "maritalStatusEdit", "birthDateEdit", "ageEdit"];
  

  constructor(
    //PUBLIC
        public formService: FormService,
        public userDetailsService: UserDetailsService,
        public autocompleteService: AutocompleteService,
        public parameterService: ParameterService,
        public dialog: MatDialog,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private stepperDtlService: StepperDtlService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private utilService: UtilityService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private downloadService: DownloadService,
        private dialogBoxService: DialogBoxService,
        private fbAnalyticsService: FacebookEventAnalyticsService
  ) { 
    this.productInfo = this.productService.productInfo;

    this.initializeStepperDetail();

    this.validityPeriodErrorMessage = this.parameterService.paramV("VALIDITY_PERIOD_ERR_MSG");

    this.allowedFileExt = this.parameterService.paramV("RISK_DTL_FILE_EXT").split(",");
    this.allowedFileSize = this.parameterService.paramN("RISK_DTL_FILE_SIZE");

    this.acceptedOcc = this.parameterService.paramV('ACCEPT_OCCUPATION_TERMS').substr(0, this.parameterService.paramV('ACCEPT_OCCUPATION_TERMS').indexOf('E'));
    this.acceptedOccLine = this.parameterService.paramV('ACCEPT_OCCUPATION_TERMS').substr(this.parameterService.paramV('ACCEPT_OCCUPATION_TERMS').indexOf('E'));
  }

    ngOnInit(): void {
        this.pageValidationService.validatePageAccessibility();
        this.setValidatorsFromParametersPrincipal();
        this.paValidationsSetter();
        this.userCheck();
        this.supportDocsCheck();
        this.utilService.setFormGroup(this.tableForm);
        this.productService.productInfo.quoteStatCd ? "" : this.productService.setQuoteStat(1);
        this.filteredPrincipalOccupationOptions = this.autocompleteService.set(this.form, "principalOccupation", this.getOccupation());
        this.filteredPrincipalMaritalStatusOptions = this.autocompleteService.set(this.form, "principalMaritalStatus", this.getMaritalStatus());

        if (this.productInfo.sublineCd == 'FPA') {
          this.fbAnalyticsService.trackEvent('Family_Quotation_ Personal_Details')
        } else if (this.productInfo.sublineCd == 'IND') {
          this.fbAnalyticsService.trackEvent('Personal_Quotation_ Personal_Details')
        }
    }

  initializeStepperDetail(): void {
    this.stepperDtlService.childInstance = this;
    this.stepperDtlService.showStepperDtl = true;
    this.stepperDtlService.titleStepperDtl = "Personal Details";

    if (this.productInfo.creationProcess === "quotation") {
        this.stepperDtlService.stepperDtlStepNo = 3;
        this.nextLabel += "Review";
    } else if (this.productInfo.creationProcess === "policy") {
        this.stepperDtlService.stepperDtlStepNo = 1;
        this.nextLabel += "Customer Info";
    }

    this.stepperDtlService.stepperDtlBtns = [
        { class: "btn4", label: "Back", action: "move", actionParam: "back" },
        { class: "btn5", label: "Save", action: "save", isShow: this.userDetailsService.userId ? "true" : "false" },
        { class: "btn2", label: this.nextLabel, action: "move", actionParam: "next" }
    ];
  }

  getOccupation(): any[] {
    let occupationOptions = [];
    for(let occupations of this.jsonDataService.data.occupation) {
      occupationOptions.push({
        value: occupations.occCd,
        text: occupations.occDesc,
      });
    }
    occupationOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
    return occupationOptions;
  }

  getMaritalStatus(): any[] {
    let maritalStatusOptions = [];
    for(let maritalStatus of this.jsonDataService.data.maritalStat) {
      maritalStatusOptions.push({
        value: maritalStatus.maritalStatCd,
        text: maritalStatus.maritalDesc.toUpperCase(),
      });
    }
    maritalStatusOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
    return maritalStatusOptions;
  }

  getRelationship(): any[] {
    let relationshipOptions = [];
    for(let relationship of this.jsonDataService.data.relationship) {
      relationshipOptions.push({
        value: relationship.relationshipCd,
        text: relationship.relationshipDesc,
      });
    }
    relationshipOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
    return relationshipOptions;
  }

  getRelationshipDependents(): any[] {
    let relationshipOptions: any[] = [];
    const maritalStatCd = this.form.get('principalMaritalStatus')?.value.value;
    const marriedCds = this.parameterService.paramV("MARRIED_VALID_RELATIONSHIP_CD").split(",");
    const widowCds = this.parameterService.paramV("MARRIED_VALID_RELATIONSHIP_CD_2").split(",");
    const unmarriedCds = this.parameterService.paramV("UNMARRIED_VALID_RELATIONSHIP_CD").split(",");
    const maritalStatCdsParams = this.parameterService.paramV("MARITAL_STAT_PARAM").split(",");
    const maritalStatTwoCdsParams = this.parameterService.paramV("MARITAL_STAT_PARAM_2").split(",");
    this.jsonDataService.data.relationship.forEach((data: any) => {
      if(maritalStatCdsParams.includes(maritalStatCd.toString())) {
        if(marriedCds.includes(data.relationshipCd.toString())) {
          relationshipOptions.push({
            value: data.relationshipCd,
            text: data.relationshipDesc,
          });
        }
      } else if(maritalStatTwoCdsParams.includes(maritalStatCd.toString())) {
        if(widowCds.includes(data.relationshipCd.toString())) {
          relationshipOptions.push({
            value: data.relationshipCd,
            text: data.relationshipDesc,
          });
        }
      } else if(unmarriedCds.includes(data.relationshipCd.toString())) {
        relationshipOptions.push({
          value: data.relationshipCd,
          text: data.relationshipDesc,
        });
      }
    });
    relationshipOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
    return relationshipOptions;
  }

  userCheck() {
    if(this.userDetailsService.userId !== "" && this.userDetailsService.accountType === "P") {
      this.initializePrincipalDetails();
      this.form.get('policyHolderAdress')?.clearValidators();
      this.form.get('policyHolderAdress')?.updateValueAndValidity();
    }
    if(this.userDetailsService.userId === "") {
      this.form.get('policyHolderAdress')?.clearValidators();
      this.form.get('policyHolderAdress')?.updateValueAndValidity();
      this.buildDetailsFromProduct();
    }
    if(this.userDetailsService.accountType === "A" || this.userDetailsService.accountType === "B" || this.userDetailsService.accountType === "E") {
      this.policyHolderSearch = true;
      if(this.productInfo.recordId) {
        this.buildDetailsFromDashboard();
      } else {
        this.buildDetailsFromProduct();
        this.formatDependentDob();
      }
      if(this.productInfo.quoteStatCd === 3) {
        this.form.get('existingClient')?.disable();
        this.form.get('policyHolderAdress')?.disable();
        this.form.get('principalLastName')?.disable();
        this.form.get('principalFirstName')?.disable();
        this.form.get('principalBirthDate')?.disable();
        this.form.get('principalOccupation')?.disable();
        this.form.get('principalMaritalStatus')?.disable();
        this.editable = false;
      } else {
        this.form.get('existingClient')?.enable();
        this.form.get('principalOccupation')?.enable();
        this.form.get('principalMaritalStatus')?.enable();
      }
    }
  }

  buildDetailsFromDashboard() {
    const personalDetails = this.productInfo.customerInfo;
    // this.assdNo = this.productInfo.personalInfo === undefined ? personalDetails.assdNo : this.productInfo.personalInfo.assdNo;
    this.assdNo = this.productInfo.personalInfo.assdNo || personalDetails.assdNo;
    
    this.form.patchValue({
      existingClient        : personalDetails.firstName + " " + personalDetails.middleName + " " + personalDetails.lastName,
      policyHolderAdress    : personalDetails.permanentAddress,
      principalLastName     : personalDetails.lastName,
      principalFirstName    : personalDetails.firstName,
      principalMiddleName   : personalDetails.middleName,
      principalSuffix       : personalDetails.suffix,
      principalBirthDate    : personalDetails.birthDate,
      principalOccupation   : personalDetails.occupationDetails,
      principalMaritalStatus: personalDetails.maritalDetail,
    });
    this.form.get('age')?.setValue(this.form.get('principalBirthDate')?.value === "" ? "" : moment().diff(this.form.get('principalBirthDate')?.value, 'years', false));

    if(personalDetails.assdNo !== "" && this.productInfo?.quoteId) {
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getPrincipalDetailsNonPublic(this.productInfo.quoteId).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
            this.jsonDataService.contorlLoading(false);
            if(response.status == "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, () => {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
              
                    if(res[0].length !== 0) {
                      this.occupationCaveat = res[0][0].risk_accept_tag == "Y" ? true : false;
                      this.form.get("remarks")?.setValue(res[0][0].remarks || "");
                    }
      
                    if(res[1] != '' && ((this.productInfo.lineCd === "PA" && this.productInfo.sublineCd === "FPA") || this.paValidations.MAX_DEP_WITH_PREM > 0)) {
                      this.dependents.splice(0, this.dependents.length);
                      this.dependentsEdit.splice(0, this.dependentsEdit.length);
                      res[1].map((data: any, index: any) => {
                        const Fname = data.dependentFname;
                        const Mname = data.dependentMname || '';
                        const Lname = data.dependentLname;
                        const suffix = data.dependentSuffix || '';
                        const relationship = this.jsonDataService.data.relationship.filter((relationship: any) => {
                          return relationship.relationshipCd == data.relationship_cd
                        })[0].relationshipDesc;
                        let occupation = this.jsonDataService.data.occupation.filter((occupation: any) => {
                          return occupation.occCd == data.occupation_cd
                        });
                        if(occupation.length === 0) {
                          occupation = '';                  
                        } else {
                          occupation = this.jsonDataService.data.occupation.filter((occupation: any) => {
                            return occupation.occCd == data.occupation_cd
                          })[0]?.occDesc || '';
                        }
                        const maritalStatus = this.jsonDataService.data.maritalStat.filter((marital: any) => {
                          return marital.maritalStatCd == data.marital_stat_cd
                        })[0]?.maritalDesc || '';
                        const birthDate = moment(data.birthDate).format("MM/DD/YYYY");
                        const age = moment().diff(data.birthDate, 'years', false);
                        this.dependents.push({
                          name: suffix !== '' ? Fname + " " + Mname + " " + Lname + ", " + suffix : Fname + " " + Mname + " " + Lname,
                          relationship: relationship,
                          occupation: occupation,
                          maritalStatus: maritalStatus,
                          birthDate: formatDate(data.birthDate, "yyyy-MM-dd", 'en-US'),
                          age: this.productInfo.quoteStatCd === 1 ? age : data.age,
                          dependentNo: data.dependent_no || null,
                        });
      
                        this.dependentsEdit.push({
                          age: this.productInfo.quoteStatCd === 1 ? age : data.age,
                          birthDate: formatDate(data.birthDate, "yyyy-MM-dd", 'en-US'),
                          firstName: Fname,
                          lastName: Lname,
                          maritalStatus: {
                            value: data.marital_stat_cd,
                            text: maritalStatus
                          },
                          middleName: Mname,
                          occupation: {
                            value: data.occupation_cd,
                            text: occupation
                          },
                          relationship: {
                            value: data.relationship_cd,
                            text: relationship
                          },
                          suffix: suffix,
                          dependentNo: data.dependent_no || null,
                        });
                      });
                      this.dataSource = new MatTableDataSource(this.dependents);
                    }
      
                    if(res[2] !== '' && this.displaySupportDocs) {
                      this.form.get('supportingDocs')?.setValue(res[2]);
                    } else {
                      this.form.patchValue({ supportingDocs: [] }); //testing lng
                    }
                    this.firstDependent = this.dependents;
                    this.buildDetailsFromProduct();
                    this.formatDependentDob();
                    this.jsonDataService.contorlLoading(false);
                });
            } else {
              this.appMessageService.showAppMessage("Error getting your personal information. Please try again later.", "error");
            }
        });
      });
    } else {
      this.jsonDataService.contorlLoading(true);
      this.buildDetailsFromProduct();
      this.formatDependentDob();
      this.jsonDataService.contorlLoading(false);
    }
  }

    private buildDetailsFromProduct(): void {
        const principal = this.productService.productInfo.personalInfo;
        if (principal != undefined) {
            this.assdNo = principal.assdNo || this.productService.productInfo.customerInfo?.assdNo;
            this.occupationCaveat = principal.occupationCaveat === undefined ? this.occupationCaveat : principal.occupationCaveat;

            if (this.productInfo.quoteStatCd !== 1) {
                this.occupationCaveat = true;
            }

            this.form.patchValue({
                existingClient          : principal.existingClient || this.form.get("existingClient")?.value,
                policyHolderAdress      : principal.policyHolderAdress || this.form.get("policyHolderAdress")?.value,
                principalLastName       : principal.principalLastName || this.form.get("principalLastName")?.value,
                principalFirstName      : principal.principalFirstName || this.form.get("principalFirstName")?.value,
                principalBirthDate      : principal.principalBirthDateFormat ? moment(principal.principalBirthDateFormat, "MM/DD/YYYY") : "" || this.form.get("principalBirthDate")?.value,
                principalMiddleName     : principal.principalMiddleName || this.form.get("principalMiddleName")?.value,
                principalSuffix         : principal.principalSuffix || this.form.get("principalSuffix")?.value,
                principalOccupation     : Object.keys(principal.principalOccupation).length !== 0 ? principal.principalOccupation : this.form.get("principalOccupation")?.value,
                principalMaritalStatus  : Object.keys(principal.principalMaritalStatus).length !== 0 ? principal.principalMaritalStatus : this.form.get("principalMaritalStatus")?.value,
                remarks                 : principal.remarks || this.form.get("remarks")?.value,
                age                     : principal.age || this.form.get('age')?.value,
                supportingDocs          : principal.supportingDocs || this.form.get('supportingDocs')?.value,
            });

            if (this.form.get("principalLastName")?.value !== "") {
                this.form.get("principalLastName")?.disable();
            }

            if (this.form.get("principalFirstName")?.value !== "") {
                this.form.get("principalFirstName")?.disable();
            }

            if (this.form.get("principalBirthDate")?.value !== "") {
                this.form.get("principalBirthDate")?.disable();
            }
        }

        if(this.productService.productInfo?.dependents) {
          if(this.productService.productInfo?.dependents.length === 0) {
            delete this.productService.productInfo?.dependentsEdit;
          }
        }
        const dependents = this.productService.productInfo.dependentsEdit;
        if (dependents != undefined) {
            if (dependents.length != 0) {
                this.dependents.splice(0, this.dependents.length);
                this.dependentsEdit.splice(0, this.dependentsEdit.length);

                dependents.map((dep: any) => {
                    const age = moment().diff(dep.birthDate, 'years', false);
                    this.dependents.push({
                        name          : dep.suffix !== "" ? dep.firstName + " " + dep.middleName + " " + dep.lastName + ", " + dep.suffix : dep.firstName + " " + dep.middleName + " " + dep.lastName,
                        relationship  : dep.relationship.text,
                        occupation    : dep.occupation.text,
                        maritalStatus : dep.maritalStatus.text,
                        birthDate     : formatDate(dep.birthDate, "yyyy-MM-dd", 'en-US'),
                        age           : this.productInfo.quoteStatCd === 1 ? age : dep.age,
                    });

                    this.dependentsEdit.push({
                        age             : this.productInfo.quoteStatCd === 1 ? age : dep.age,
                        birthDate       : formatDate(dep.birthDate, "yyyy-MM-dd", 'en-US'),
                        firstName       : dep.firstName,
                        lastName        : dep.lastName,
                        maritalStatus   : dep.maritalStatus,
                        middleName      : dep.middleName,
                        occupation      : dep.occupation,
                        relationship    : dep.relationship,
                        suffix          : dep.suffix,
                        dependentNo     : dep.dependentNo || null,
                    });
                });
            }
        }

        this.firstDependent = this.dependents;
        this.preValidatePrincipalBirthDate();
        this.roundUpDependentsAge();
    }

    private buildPublicDetails(): void {
        this.apiCallService.getPrincipalDetails(
            this.productService.productInfo.quoteId
        ).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if (res[0].length !== 0) {
                            this.form.patchValue({
                                principalMiddleName: res[0][0].mname,
                                principalSuffix: res[0][0].suffix,
                                principalOccupation: {
                                    value: res[0][0]?.occupation_cd || '',
                                    text: this.jsonDataService.data.occupation.filter((data: any) => {
                                        return data.occCd == res[0][0].occupation_cd
                                    })[0]?.occDesc || ''
                                },
                                principalMaritalStatus: {
                                    value: res[0][0]?.marital_stat_cd || '',
                                    text: this.jsonDataService.data.maritalStat.filter((data: any) => {
                                        return data.maritalStatCd == res[0][0].marital_stat_cd
                                    })[0]?.maritalDesc || ''
                                },
                                principalBirthdate: moment(res[0][0].birthDate).format("MM/DD/YYYY")
                            });

                            const principalAge = this.form.get('principalBirthDate')?.value === "" ? "" : moment().diff(this.form.get('principalBirthDate')?.value, 'years', false);
                            this.form.get('age')?.setValue(principalAge);

                            this.occupationCaveat = res[0][0].risk_accept_tag === "Y";
                        }

                        if (res[1] !== "") {
                            this.dependents.splice(0, this.dependents.length);
                            this.dependentsEdit.splice(0, this.dependentsEdit.length);
                            res[1].map((data: any, index: any) => {
                                const Fname = data.dependentFname;
                                const Mname = data.dependentMname || '';
                                const Lname = data.dependentLname;
                                const suffix = data.dependentSuffix || '';
                                const age = moment().diff(data.birthDate, 'years', false);
                                const relationship = this.jsonDataService.data.relationship.filter((relationship: any) => {
                                    return relationship.relationshipCd == data.relationship_cd
                                })[0].relationshipDesc;
                                let occupation = this.jsonDataService.data.occupation.filter((occupation: any) => {
                                    return occupation.occCd == data.occupation_cd
                                });

                                if (occupation.length === 0) {
                                    occupation = '';                  
                                } else {
                                    occupation = occupation[0]?.occDesc || '';
                                }

                                const maritalStatus = this.jsonDataService.data.maritalStat.filter((marital: any) => {
                                    return marital.maritalStatCd == data.marital_stat_cd
                                })[0]?.maritalDesc.toUpperCase() || '';

                                const birthDate = moment(data.birthDate).format("MM/DD/YYYY");
                                this.dependents.push({
                                    name: suffix !== "" ? Fname + " " + Mname + " " + Lname + ", " + suffix : Fname + " " + Mname + " " + Lname,
                                    relationship: relationship,
                                    occupation: occupation,
                                    maritalStatus: maritalStatus,
                                    birthDate: formatDate(data.birthDate, "yyyy-MM-dd", 'en-US'),
                                    age: this.productInfo.quoteStatCd === 1 ? age : data.age,
                                    dependentNo: data.dependent_no || null,
                                });

                                this.dependentsEdit.push({
                                    age: this.productInfo.quoteStatCd === 1 ? age : data.age,
                                    birthDate: formatDate(data.birthDate, "yyyy-MM-dd", 'en-US'),
                                    firstName: Fname,
                                    lastName: Lname,
                                    maritalStatus: {
                                        value: data.marital_stat_cd,
                                        text: maritalStatus
                                    },
                                    middleName: Mname,
                                    occupation: {
                                        value: data.occupation_cd,
                                        text: occupation
                                    },
                                    relationship: {
                                        value: data.relationship_cd,
                                        text: relationship
                                    },
                                    suffix: suffix,
                                    dependentNo: data.dependent_no || null
                                });
                            });
                        }

                        if (res[2] !== '' && res[2] !== undefined) {
                            this.form.get('supportingDocs')?.setValue(res[2]);
                        }

                        this.firstDependent = this.dependents;
                        this.jsonDataService.contorlLoading(false);
                        if(this.saveMessageFlag === true) {
                          this.appMessageService.showAppMessage("Successfully saved.", "success");
                        }
                        this.buildDetailsFromProduct();

                        if (this.dependentsEdit.length !== 0) {
                            this.form.get("principalMaritalStatus")?.disable();
                        } else {
                            this.form.get("principalMaritalStatus")?.enable();
                        }

                        this.formatDependentDob();
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Error getting your personal information. Please try again later.", "error");
                }
            });
        });
    }

    private initializePrincipalDetails(): void {
        this.withSupportingDocChange = false;

        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getProfileInfo().subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        this.buildPublicDetails();

                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        this.registrationNo = res.registrationNo;
                        res.birthRegistrationDate = formatDate(res.birthRegistrationDate, "yyyy-MM-dd", 'en-US');

                        this.form.patchValue({
                            principalLastName: this.form.get('principalLastName')?.value != "" ? this.form.get('principalLastName')?.value : res.lastName,
                            principalFirstName: this.form.get('principalFirstName')?.value != "" ? this.form.get('principalFirstName')?.value : res.firstName,
                            principalMiddleName: this.form.get('principalMiddleName')?.value != "" ? this.form.get('principalMiddleName')?.value : res.middleName,
                            principalSuffix: this.form.get('principalSuffix')?.value != "" ? this.form.get('principalSuffix')?.value : res.suffix,
                            principalBirthDate: this.form.get('principalBirthDate')?.value != "" ? this.form.get('principalBirthDate')?.value : res.birthRegistrationDate,
                        });

                        const principalAge = this.form.get('principalBirthDate')?.value === "" ? "" : moment().diff(this.form.get('principalBirthDate')?.value, 'years', false);
                        this.form.get('age')?.setValue(principalAge);
                        this.form.get('principalLastName')?.disable();
                        this.form.get('principalFirstName')?.disable();
                        this.form.get('principalBirthDate')?.disable();
                        this.preValidatePrincipalBirthDate();
                    });
                } else {
                    this.appMessageService.showAppMessage("Error getting your personal information. Please try again later.", "error");
                }
            });
        });
    }

  setValidatorsFromParametersPrincipal() {
    let principalOccupationReq = this.parameterService.paramV('REQ_PA_PRINCIPAL_OCCUPATION ');
    let principalMaritalStatusReq = this.parameterService.paramV('REQ_PA_PRINCIPAL_MARITAL');
    if(principalOccupationReq == 'Q') {
      this.form.get('principalOccupation')?.setValidators([Validators.required]);
      this.form.get('principalOccupation')?.updateValueAndValidity();
    }
    if(principalMaritalStatusReq == 'Q') {
      this.form.get('principalMaritalStatus')?.setValidators([Validators.required]);
      this.form.get('principalMaritalStatus')?.updateValueAndValidity();
    }
  }

  onLovOk(data: any) {
    this.form.markAsDirty();
    this.withSupportingDocChange = false;
    this.assdNo = data.assdNo;
    this.form.get('principalBirthDate')?.enable();
    this.form.get('principalMaritalStatus')?.enable();
    this.getNonPublicDetails(data);
  }

  getNonPublicDetails(data: any) {
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.getPrincipalDetailsNonPublic(this.productInfo?.quoteId).subscribe((response: any) => {
      this.securityService.checkRequestKeyResponse(response, () => {
        this.jsonDataService.contorlLoading(false);
          if(response.status == "SUCCESS") {
            this.securityService.hasValidCsrfToken(response, () => {
                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                this.occupationCaveat = false;
                const principal = res[0];
                const dependents = res[1];
                const displayName = data.displayName;
                const address1 = data.addressPt1 === "" ? "" : data.addressPt1 === null ? "" : data.addressPt1;
                const address2 = data.addressPt2 === "" ? "" : data.addressPt2 === null ? "" : " " + data.addressPt2;
                const address3 = data.addressPt3 === "" ? "" : data.addressPt3 === null ? "" : " " + data.addressPt3;
                const address4 = data.addressPt4 === "" ? "" : data.addressPt4 === null ? "" : " " + data.addressPt4;
    
                if(principal.length !== 0) {
                  this.form.patchValue({
                    existingClient: displayName,
                    policyHolderAdress: address1 + address2 + address3 + address4,
                    principalLastName: principal[0].lname,
                    principalFirstName: principal[0].fname,
                    principalMiddleName: principal[0].mname,
                    principalSuffix: principal[0].suffix,
                    principalBirthDate: principal[0].birthDate,
                    principalOccupation: {
                      value: res[0][0].occupation_cd,
                      text: this.jsonDataService.data.occupation.filter((data: any) => {
                        return data.occCd == res[0][0].occupation_cd
                      })[0]?.occDesc || ''
                    },
                    principalMaritalStatus: {
                      value: res[0][0].marital_stat_cd,
                      text: this.jsonDataService.data.maritalStat.filter((data: any) => {
                        return data.maritalStatCd == res[0][0].marital_stat_cd
                      })[0]?.maritalDesc || ''
                    },
                    remarks: principal[0].remarks,
                  });
                  if(!(principal[0].birthDate === '' || principal[0].birthDate === null)) {
                    this.form.get('age')?.setValue(moment().diff(principal[0].birthDate, 'years', false));
                  }
                } else {
                  this.form.patchValue({
                    existingClient: displayName,
                    policyHolderAdress: address1 + address2 + address3 + address4,
                    principalLastName: data.surname,
                    principalFirstName: data.givenName,
                    principalMiddleName: data.middleName,
                    principalSuffix: data.suffix,
                    principalBirthDate: data.dob,
                    principalOccupation: '',
                    principalMaritalStatus: '',
                  });
                  if(!(data.dob === '' || data.dob === null)) {
                    this.form.get('age')?.setValue(moment().diff(data.dob, 'years', false));
                  }
                }
                
                if(dependents.length !== 0 && this.paValidations.DEPENDENTS_TAG === "Y") {
                  this.form.get('principalBirthDate')?.disable();
                  this.form.get('principalMaritalStatus')?.disable();
                  this.dependents.splice(0, this.dependents.length);
                  this.dependentsEdit.splice(0, this.dependentsEdit.length);
                  dependents.map((dependents: any) => {
                    const Fname = dependents.dependentFname;
                    const Mname = dependents.dependentMname || '';
                    const Lname = dependents.dependentLname;
                    const suffix = dependents.dependentSuffix || '';
                    const relationship = this.jsonDataService.data.relationship.filter((relationship: any) => {
                      return relationship.relationshipCd == dependents.relationship_cd
                    })[0].relationshipDesc;
                    let occupation = this.jsonDataService.data.occupation.filter((occupation: any) => {
                      return occupation.occCd == dependents.occupation_cd
                    });
                    if(occupation.length === 0) {
                      occupation = '';                  
                    } else {
                      occupation = this.jsonDataService.data.occupation.filter((occupation: any) => {
                        return occupation.occCd == dependents.occupation_cd
                      })[0]?.occDesc || '';
                    }
                    const maritalStatus = this.jsonDataService.data.maritalStat.filter((marital: any) => {
                      return marital.maritalStatCd == dependents.marital_stat_cd
                    })[0]?.maritalDesc || '';
                    const birthDate = moment(dependents.birthDate).format("MM/DD/YYYY");
                    this.dependents.push({
                      name: suffix !== "" ? Fname + " " + Mname + " " + Lname + ", " + suffix : Fname + " " + Mname + " " + Lname,
                      relationship: relationship,
                      occupation: occupation,
                      maritalStatus: maritalStatus,
                      birthDate: formatDate(dependents.birthDate, "yyyy-MM-dd", 'en-US'),
                      age: dependents.age,
                      dependentNo: dependents.dependent_no || null,
                    });
    
                    this.dependentsEdit.push({
                      age: dependents.age,
                      // birthDate: moment(dependents.birthDate),
                      birthDate: formatDate(dependents.birthDate, "yyyy-MM-dd", 'en-US'),
                      firstName: Fname,
                      lastName: Lname,
                      maritalStatus: {
                        value: dependents.marital_stat_cd,
                        text: maritalStatus
                      },
                      middleName: Mname,
                      occupation: {
                        value: dependents.occupation_cd,
                        text: occupation
                      },
                      relationship: {
                        value: dependents.relationship_cd,
                        text: relationship
                      },
                      suffix: suffix,
                      dependentNo: dependents.dependent_no || null,
                    });
                  });
                  this.dataSource = new MatTableDataSource(this.dependents);
                } else {
                  this.dependents.splice(0, this.dependents.length);
                  this.dependentsEdit.splice(0, this.dependentsEdit.length);
                  this.dataSource = new MatTableDataSource(this.dependents);
                }
                // if(res[2] !== '') {
                //   this.form.get('supportingDocs')?.setValue(res[2]);
                // }
                this.firstDependent = this.dependents;
                this.preValidatePrincipalBirthDate();
                this.roundUpDependentsAge();
                

                if(this.form.get('principalLastName')?.value === "" || this.form.get('principalLastName')?.value === null) {
                  this.form.get('principalLastName')?.enable();
                } else {
                  this.form.get('principalLastName')?.disable();
                }
                
                if(this.form.get('principalFirstName')?.value === "" || this.form.get('principalFirstName')?.value === null) {
                  this.form.get('principalFirstName')?.enable();
                } else {
                  this.form.get('principalFirstName')?.disable();
                }
                
                if(this.form.get('principalBirthDate')?.value === "" || this.form.get('principalBirthDate')?.value === null) {
                  this.form.get('principalBirthDate')?.enable();
                } else {
                  this.form.get('principalBirthDate')?.disable();
                }
            });
          } else {
            this.appMessageService.showAppMessage("Error getting your personal information. Please try again later.", "error");
          }
      });
    });
  }

  toggleEdit() {
    if(this.form.get('principalMaritalStatus')?.value == "") {
      this.appMessageService.showAppMessage("Please select Marital Status first.", "info");
    } else if(this.form.get('principalBirthDate')?.value == "") {
      this.appMessageService.showAppMessage("Please enter Birth Date first.", "info");
    } else if(!this.validPrincipalBirthday) {
      this.appMessageService.showAppMessage(this.paValidations.PRIN_AGE_ERR_MSG, "info");
    } else if(!this.editable) {
      this.appMessageService.showAppMessage("Cannot edit dependents while in pending acceptance.", "info");
    } else {
      this.form.get('principalMaritalStatus')?.disable();
      this.form.get('principalBirthDate')?.disable();
      if(!this.validateDependentsData().invalidFlag) {
        this.editFlag = !this.editFlag;
        if(this.editFlag) {
          // let dependentsSetAge: number = 0;
          let relationshipFlag = this.jsonDataService.data.relationship.filter((data: any) => {
            return data.multipleTag == "N"
          });
          relationshipFlag = relationshipFlag.map((data: any) => data.relationshipCd.toString());
          this.setDependentEditData();
          this.dataSourceEdit.data.forEach((dependents: any, index) => {
            let row: FormGroup = this.formBuilder.group({
              lastName:       [dependents.lastName],
              firstName:      [dependents.firstName],
              middleName:     [dependents.middleName],
              suffix:         [dependents.suffix],
              relationship:   [dependents.relationship],
              occupation:     [dependents.occupation],
              maritalStatus:  [dependents.maritalStatus],
              birthDate:      [dependents.birthDate, { updateOn: 'blur' }],
              age:            [dependents.age, { updateOn: 'blur' }]
            });
            
            row.get('lastName')?.valueChanges.subscribe((lastName: any) => {
              this.dependentsEdit[index].lastName = lastName.toUpperCase();
              this.dependentsDeleteEnabler(index);
            });

            row.get('firstName')?.valueChanges.subscribe((firstName: any) => {
              this.dependentsEdit[index].firstName = firstName.toUpperCase();
              this.dependentsDeleteEnabler(index);
            });

            row.get('middleName')?.valueChanges.subscribe((middleName: any) => {
              this.dependentsEdit[index].middleName = middleName.toUpperCase();
              this.dependentsDeleteEnabler(index);
            });

            row.get('suffix')?.valueChanges.subscribe((suffix: any) => {
              this.dependentsEdit[index].suffix = suffix.toUpperCase();
              this.dependentsDeleteEnabler(index);
            });

            row.get('relationship')?.valueChanges.subscribe((relationship: any) => {
              if(relationship!='' && relationship.text != undefined) {
                let relationshipData = this.dependentsEdit.filter((e: any) => e.relationship != "");
                let usedRelationshipCds: any[] = [];
                relationshipData.forEach((relData: any) => {
                  if(relData.relationship.value !== undefined) {
                    if(relationshipFlag.includes(relData.relationship.value.toString())) {
                      usedRelationshipCds.push(relData.relationship.value.toString())
                    }
                  }
                });
                if(usedRelationshipCds.includes(relationship.value.toString())) {
                  row.get('relationship')?.setValue('');
                  this.appMessageService.showAppMessage("You can only have one " + relationship.text + ".", "error");
                }
                if(relationship.value == 7) {
                  if(usedRelationshipCds.includes(relationship.value.toString())) {
                    row.get('relationship')?.setValue('');
                    this.appMessageService.showAppMessage("You can only have one " + relationship.text + ".", "error");
                  } else {
                    row.get('maritalStatus')?.setValue({value: 2, text: "Married"});
                    row.get('maritalStatus')?.disable();
                  }
                } else {
                  row.get('maritalStatus')?.setValue('');
                  row.get('maritalStatus')?.enable();
                }
              } else {
                row.get('occupation')?.setValue('');
                row.get('maritalStatus')?.setValue('');
                row.get('birthDate')?.setValue('');
                row.get('age')?.setValue('');
                this.dependentsEdit[index].birthDate = "";
                this.dependentsEdit[index].age = "";
              }
              this.dependentsEdit[index].relationship = relationship;
              var arrayControl = this.tableForm.get('dependents') as FormArray;
              this.dependentsDeleteEnabler(index);
            });

            row.get('occupation')?.valueChanges.subscribe((occupation: any) => {
              if(occupation != '' && occupation.text != undefined && row.get('relationship')?.value.text != undefined) {
                const relationshipUnemp = this.parameterService.paramV("CHECK_RELATIONSHIP_CD_FOR_UNEMP").split(",");
                const occCdChildSib = this.parameterService.paramV("OCCUPATION_CHILD_SIB").split(",");
                const relationshipCd = row.get('relationship')?.value.value.toString();
                if(relationshipUnemp.includes(relationshipCd)) {
                  if(occupation.value != this.parameterService.paramN("OCCUPATION_UNEMPLOYED") && !(occCdChildSib.includes(occupation.value.toString()))) {
                    row.get('occupation')?.setValue('');
                    this.appMessageService.showAppMessage("Only unmarried and unemployed children or sibling/s are allowed as dependents.", "error");
                  } else {
                    this.dependentsEdit[index].occupation = occupation;
                  }
                } else {
                  this.dependentsEdit[index].occupation = occupation;
                }
              } else {
                if(row.get('relationship')?.value.text == undefined && occupation !== "") {
                  this.dependentsEdit[index].occupation = '';
                  this.appMessageService.showAppMessage("Please select relationship first.", "error");
                  if(occupation != '') {
                    row.get('occupation')?.setValue('');
                  }
                }
              }
              if(occupation.text == undefined) {
                this.dependentsEdit[index].occupation = '';
              }
            });

            row.get('maritalStatus')?.valueChanges.subscribe((maritalStatus: any) => {
              if(maritalStatus != "" && maritalStatus.text != undefined && row.get('relationship')?.value.text != undefined) {
                const unmarriedCds = this.parameterService.paramV("CHECK_RELATIONSHIP_CD_FOR_UNMARRIED").split(",");
                let relationship = row.get('relationship')?.value.value.toString();
                if(unmarriedCds.includes(relationship)) {
                  if(maritalStatus.value != 5) {
                    row.get('maritalStatus')?.setValue('');
                    this.appMessageService.showAppMessage("Only unmarried and unemployed children or sibling/s are allowed as dependents.", "error");
                  }
                }
              } else {
                if(row.get('relationship')?.value.text == undefined && maritalStatus != '') {
                  this.dependentsEdit[index].maritalStatus = '';
                  this.appMessageService.showAppMessage("Please select relationship first.", "error");
                  row.get('maritalStatus')?.setValue('');
                }
              }
              if(maritalStatus.text != undefined && row.get('relationship')?.value.text != undefined) {
                this.dependentsEdit[index].maritalStatus = maritalStatus;
              } else {
                this.dependentsEdit[index].maritalStatus = '';
              }
            });

            // row.get('birthDate')?.valueChanges.subscribe((birthDate: any) => {
            //   if(birthDate !== '') {
            //     if(birthDate !== null) {
            //       if(typeof birthDate._i === "string") {
            //         const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
            //         dependentsSetAge = moment().diff(birthDate, 'years', false);
            //         const dependentAge = birthDate == null ? null : moment().diff(birthDate.subtract(depAgeValidityPeriod, 'days'), 'years', false);
            //         birthDate.add(depAgeValidityPeriod, 'days'); // para pag click sa datepicker kung anong date yung hulinh pinili nag susubstract din kasi yung date sa datepicker pag click ulit
            //         row.patchValue({
            //           age: dependentAge,
            //         });
            //       }
            //     } else {
            //       this.appMessageService.showAppMessage("Invalid Date", "error");
            //       row.get('birthDate')?.setValue('');
            //       row.get('age')?.setValue('');
            //     }
            //   }
            //   this.dependentsEdit[index].birthDate = typeof birthDate != "string" && birthDate !== null ? birthDate.format('YYYY-MM-DD') : birthDate;
            // });

            // row.get('age')?.valueChanges.subscribe((age: any) => {
            //   this.dependentsEdit[index].age = age;
            //   if(row.get('relationship')?.value === "") {
            //     if(age !== '') {
            //       row.get('birthDate')?.setValue('');
            //       row.get('age')?.setValue('');
            //       this.appMessageService.showAppMessage("Please select relationship first.", "error");
            //     }
            //   } else {
            //     if(age !== '') {
            //       const maxDependentParentSpouseAge = this.paValidations.MAX_PARENT_SPOUSE_AGE;
            //       const minDependentSpouseAge = this.paValidations.MIN_SPOUSE_AGE;
            //       const minDependentChildSiblingAge = this.paValidations.MIN_CHILD_SIBLING_AGE;
            //       const maxDependentChildSiblingAge = this.paValidations.MAX_CHILD_SIBLING_AGE;
            //       const dependetAgeErrorMsg = this.paValidations.DEP_AGE_ERR_MSG;
            //       const relationshipCd = row.get('relationship')?.value.value;
            //       const principalAge = moment().diff(this.form.get('principalBirthDate')?.value, 'years', false);
            //       const lowLimitDependentAge = moment().year() - moment(row.get('birthDate')?.value).year();
            //       if(age === null) {
            //         this.appMessageService.showAppMessage("Invalid Date", "error");
            //         row.get('birthDate')?.setValue('');
            //         row.get('age')?.setValue('');
            //       } else if(row.get('birthDate')?.value > moment()) {
            //         this.appMessageService.showAppMessage("Invalid Date", "error");
            //         row.get('birthDate')?.setValue('');
            //         row.get('age')?.setValue('');
            //       } else if(relationshipCd == 1 || relationshipCd == 2 || relationshipCd == 8) {
            //         if(age <= principalAge || age > maxDependentParentSpouseAge) {
            //           this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            //           row.get('birthDate')?.setValue('');
            //           row.get('age')?.setValue('');
            //         }
            //       } else if (relationshipCd == 7) {
            //         if(age > maxDependentParentSpouseAge || lowLimitDependentAge < minDependentSpouseAge) {
            //           this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            //           row.get('birthDate')?.setValue('');
            //           row.get('age')?.setValue('');
            //         }
            //       } else {
            //         if(lowLimitDependentAge < minDependentChildSiblingAge || age > maxDependentChildSiblingAge) {
            //           this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            //           row.get('birthDate')?.setValue('');
            //           row.get('age')?.setValue('');
            //         }
            //       }
            //     }
            //   }
            // });
            this.rows.push(row);
            var arrayControl = this.tableForm.get('dependents') as FormArray;
            const rowArray = arrayControl.controls[index];
          });
          for(var i = 0; i <= 4; i++) {
            this.filteredDependentsRelationshipOptions[i] = this.autocompleteService.setArray(this.tableForm, "relationship", this.getRelationshipDependents(), "dependents", i);
            this.filteredDependentsOccupationOptions[i] = this.autocompleteService.setArray(this.tableForm, "occupation", this.getOccupation(), "dependents", i);
            this.filteredDependentsMaritalStatusOptions[i] = this.autocompleteService.setArray(this.tableForm, "maritalStatus", this.getMaritalStatus(), "dependents", i);
          }
        } else {
          if(this.dependents.length === 0) {
            this.form.get('principalMaritalStatus')?.enable();
            if(this.userDetailsService.accountType !== "P") {
              this.form.get('principalBirthDate')?.enable();
            }
          }
          // this.rows.clear();
          this.dataSource = new MatTableDataSource(this.dependents);
        }
      } else {
        this.appMessageService.showAppMessage(this.validateDependentsData().errorMessage, "error");
      }
    }
  }

  validateDependentsData(): any {
    let dependentsEditData = this.dependentsEdit.filter((data: any) => {
      return  data.lastName       != "" || 
              data.firstName      != "" ||
              data.age            != "" ||
              data.maritalStatus  != "" ||
              data.middleName     != "" ||
              data.occupation     != "" ||
              data.relationship   != "" ||
              data.suffix         != ""
    });
    let dependentLimit = dependentsEditData.filter((data: any) => {
      return data.relationship.value === 9 || data.relationship.value === 10;
    });
    let relationship = this.parameterService.paramV('REQ_PA_DEPENDENT_RELATIONSHIP');
    let occupation = this.parameterService.paramV('REQ_PA_DEPENDENT_OCCUPATION ');
    let maritalStat = this.parameterService.paramV('REQ_PA_DEPENDENT_MARITAL_STAT');
    let bday = this.parameterService.paramV('REQ_PA_DEPENDENT_BDAY');
    let invalidFlag: boolean = false;
    let errorMessage = "There are missing or invalid information.";
    this.dependents.splice(0, this.dependents.length);
    const maxNumberOfDependents = this.paValidations.MAX_NO_CHILD_SIBLING;
    if(dependentLimit.length > maxNumberOfDependents) {
      errorMessage = this.paValidations.MAX_NO_ERR_MSG;
      invalidFlag = true;
    }
    dependentsEditData.map((data: any) => {
      if(data.lastName == "") {
        invalidFlag = true;
      }
      if(data.firstName == "") {
        invalidFlag = true;
      }
      if(relationship == "Q" && data.relationship == "") {
        invalidFlag = true;
      }
      if(occupation == "Q"  && data.occupation == "") {
        invalidFlag = true;
      }
      if(maritalStat == "Q"  && data.maritalStatus == "") {
        invalidFlag = true;
      }
      if(bday == "Q"  && data.age === "") {
        invalidFlag = true;
      }
      if(data.birthDate != "") {
        this.dependents.push({
          name: data.suffix !== "" ? data.firstName + " " + data.middleName + " " + data.lastName + ", " + data.suffix : data.firstName + " " + data.middleName + " " + data.lastName,
          relationship: data.relationship.text,
          occupation: data.occupation.text,
          maritalStatus: data.maritalStatus.text,
          // birthDate: data.birthDate == null ? null : data.birthDate.format("MM/DD/YYYY"),
          birthDate: moment(data.birthDate).format("MM/DD/YYYY"),
          age: data.age,
        });
      }
    });

    //=================== New requirement for depWithPremium ======================
    if(this.paValidations.MAX_DEP_WITH_PREM > 0) {
      const depWithPremium: number = this.productInfo.coverageInfo?.depWithPrem;
      const dependentCount: number = dependentsEditData.length;
      if(dependentCount > depWithPremium) {
        errorMessage = this.paValidations.MAX_DEP_ERR_MSG;
        invalidFlag = true;
      }
    }
    //=================== New requirement for depWithPremium ======================
    return {invalidFlag, errorMessage};
  }

  setDependentEditData() {
    this.rows.clear();
    if(this.dependentsEdit.length == 0) {
      for(var i = 1; i <= 5; i++) {
        this.dependentsEdit.push({
          lastName:       "",
          firstName:      "",
          middleName:     "",
          suffix:         "",
          relationship:   "",
          occupation:     "",
          maritalStatus:  "",
          birthDate:      "",
          age:            "",
        });
      }
    } else {
      const count: number = this.dependentsEdit.length
      for(var i = count; i < 5; i++) {
        this.dependentsEdit.push({
          lastName:       "",
          firstName:      "",
          middleName:     "",
          suffix:         "",
          relationship:   "",
          occupation:     "",
          maritalStatus:  "",
          birthDate:      "",
          age:            "",
        });
      }
    }
    this.dependentsEdit = [
      ...this.dependentsEdit.filter((data: any) => data.lastName !== "" ),
      ...this.dependentsEdit.filter((data: any) => data.lastName === "" )
    ];
    this.dependentsEdit.map((edit: any, i: any) => {
      if(
        edit.lastName       != "" || 
        edit.firstName      != "" ||
        edit.age            != "" ||
        edit.maritalStatus  != "" ||
        edit.middleName     != "" ||
        edit.occupation     != "" ||
        edit.relationship   != "" ||
        edit.suffix         != ""
      ) {
        this.deleteDependentsFlag[i] = true;
      } else {
        this.deleteDependentsFlag[i] = false;
        this.deleteDependents(i);
      }
    });
    this.dataSourceEdit = new MatTableDataSource(this.dependentsEdit);
    this.dataSourceEdit.data = this.dependentsEdit;
  }

    move(action: string): void {
        // if(action == "back") {
        //   // this.processMove();
        //   if (this.productInfo.creationProcess === "quotation") {
        //       this.router.navigate(["/quotation/coverage-perils"]);
        //   } else if (this.productInfo.creationProcess === "policy") {
        //       this.router.navigate(["/dashboard"]);
        //   }
        // } else if (action === "next") {
        //     let dependentValid: boolean = true;
        //     if(this.productInfo.lineCd === "PA" && this.productInfo.sublineCd === "FPA") {
        //       dependentValid = this.dependentsEdit.length === 0 ? false : true;
        //     }
        //     if (this.form.valid && dependentValid) {
        //         if(this.form.dirty && this.userDetailsService.userId) {
        //           this.appMessageService.showAppMessage("Please fill and save the principal details form first.", "info");
        //         } else if(this.firstDependent !== this.dependents) {
        //           this.appMessageService.showAppMessage("Please fill and save the principal details form first.", "info");
        //         } else if (!this.occupationCaveat) {
        //           this.appMessageService.showAppMessage(this.parameterService.paramV("OCCUPATION_TERMS_ERR_MESSAGE"), "info");
        //         } else {
        //             if (this.editFlag) {
        //                 this.appMessageService.showAppMessage("Please finish editing dependents first.", "error");
        //             } else {
        //                 // this.processMove();
        //                 if (this.productInfo.creationProcess === "quotation") {
        //                     this.router.navigate(["/quotation/review-premium-charges"]);
        //                 } else if (this.productInfo.creationProcess === "policy") {
        //                     this.router.navigate(["/policy/customer-information"]);
        //                 }
        //             }
        //         }
        //     } else {
        //         this.appMessageService.showAppMessage("There are missing or invalid information.", "error");
        //     }
        // }

      // prompt message for unsave changes
      // if(this.form.dirty && this.userDetailsService.userId) {
      //   this.showConfirm(action);
      // } else if(this.firstDependent !== this.dependents) {
      //   this.showConfirm(action);
      // } else if (this.editFlag) {
      //   this.appMessageService.showAppMessage("Please finish editing dependents first.", "error");
      // } else {
      //   this.proceedMove(action);
      // }

      if(action == "back") {
        // this.processMove();
        if((this.form.dirty || this.tableForm.dirty) && this.userDetailsService.userId) {
          this.showConfirm(action);
        } else if((this.firstDependent !== this.dependents) && false) {
          this.showConfirm(action);
        } else if (this.editFlag) {
          this.appMessageService.showAppMessage("Please finish editing dependents first.", "error");
        } else {
          this.proceedMove(action);
        }
      } else if (action === "next") {
          let dependentValid: boolean = true;
          let dependentAgeValid: boolean = true;
          if(this.productInfo.lineCd === "PA" && this.productInfo.sublineCd === "FPA") {
            dependentValid = this.dependentsEdit.length === 0 ? false : true;
          }
          this.dependentsEdit.map((dep: any) => { // check if age is still valid
            const maxDependentParentSpouseAge = this.paValidations.MAX_PARENT_SPOUSE_AGE;
            const minDependentSpouseAge = this.paValidations.MIN_SPOUSE_AGE;
            const minDependentChildSiblingAge = this.paValidations.MIN_CHILD_SIBLING_AGE;
            const maxDependentChildSiblingAge = this.paValidations.MAX_CHILD_SIBLING_AGE;
            const lowLimitDependentAge = moment().year() - moment(dep.birthDate).year();
            const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
            let dependentAge = moment().diff(moment(dep.birthDate).subtract(depAgeValidityPeriod, 'days'), 'years', false);
            const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
            const expDate = moment(this.productInfo.coverageInfo.expDate).startOf('day');
            // rounding of age to 1 when less than 1
            // if((dep.relationship.value === 10 || dep.relationship.value === 9) && dependentSetAge < 1) {
            //   row.get('age')?.setValue(1);
            //   this.dependentsEdit[i].age = 1;
            //   lowLimitDependentAge = 1;
            // }
            const dobWithValidityPeriod = moment(moment(dep.birthDate).subtract(depAgeValidityPeriod, 'days'));
            let maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
            let ageWithAddedYear = moment(dobWithValidityPeriod);
            ageWithAddedYear.add(1, 'years');
            if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) { // checks next birthday against eff date
              if((dep.relationship.value === 10 || dep.relationship.value === 9) && maxLimitEffAge < 1) { // checks whether child or sibling less than 1 year old
                dep.age = 1;
                maxLimitEffAge = 1;
              } else {
                dep.age = maxLimitEffAge;
                dependentAge = maxLimitEffAge;
              }
            }
            if(dep.relationship.value == 1 || dep.relationship.value == 2 || dep.relationship.value == 8) {
              if(maxLimitEffAge > maxDependentParentSpouseAge) {
                dependentAgeValid = false;
              }
            } else if (dep.relationship.value == 7) {
              if(maxLimitEffAge > maxDependentParentSpouseAge || maxLimitEffAge < minDependentSpouseAge) {
                dependentAgeValid = false;
              }
            } else {
              if(maxLimitEffAge < minDependentChildSiblingAge || maxLimitEffAge > maxDependentChildSiblingAge) {
                dependentAgeValid = false;
              }
            }
          });

          const form = this.form.getRawValue();
          const occValid:boolean = form?.principalOccupation?.value;
          const martitalStatValid:boolean = form?.principalMaritalStatus?.value;

          if (this.form.valid && this.userDetailsService.userId && occValid && martitalStatValid) {
            let dependentsInsert = this.dependentsEdit.filter((data: any) => {
              return data.lastName !== "" ||
                    data.firstName !== "" ||
                    data.middleName !== "" ||
                    data.suffix !== "" ||
                    data.relationship !== "" ||
                    data.occupation !== "" ||
                    data.maritalStatus !== "" ||
                    data.birthDate !== "" ||
                    data.age !== "";    
            });
              if((this.form.dirty || this.tableForm.dirty) && this.userDetailsService.userId) {
                this.appMessageService.showAppMessage("Please fill and save the principal details form first.", "info");
              } else if (this.firstDependent !== this.dependents && false) { // false muna para hnd magtrigger
                this.appMessageService.showAppMessage("Please fill and save the principal details form first.", "info");
              } else if (!this.occupationCaveat) {
                this.appMessageService.showAppMessage(this.parameterService.paramV("OCCUPATION_TERMS_ERR_MESSAGE"), "info");
              } else if (this.productInfo.sublineCd === "FPA" && this.productInfo.quoteStatCd === 1 && !dependentAgeValid) {
                const ageValidityErrorMessage = this.validityPeriodErrorMessage.replace("BMM136.MAX_PARENT_SPOUSE_AGE", this.paValidations.MAX_PARENT_SPOUSE_AGE).replace("BMM136.MAX_CHILD_SIBLING_AGE", this.paValidations.MAX_CHILD_SIBLING_AGE).replace("BMM011.DEP_AGE_VALIDITY_PERIOD", this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD").toString());
                this.appMessageService.showAppMessage(ageValidityErrorMessage, "error");
              } else if(!this.validPrincipalBirthday) {
                this.appMessageService.showAppMessage(this.paValidations.PRIN_AGE_ERR_MSG, "info");
              }
              
              //=================== New requirement for depWithPremium ======================
              else if(this.paValidations.MAX_DEP_WITH_PREM > 0 && (dependentsInsert.length !== this.productInfo.coverageInfo?.depWithPrem)) {
                this.appMessageService.showAppMessage(this.paValidations.MAX_DEP_ERR_MSG, "info");
              }
              //=================== New requirement for depWithPremium ======================
              
              else {
                  if (this.editFlag) {
                      this.appMessageService.showAppMessage("Please finish editing dependents first.", "error");
                  } else {
                      // this.processMove();
                      if (this.productInfo.creationProcess === "quotation") {
                          this.router.navigate(["/quotation/review-premium-charges"]);
                      } else if (this.productInfo.creationProcess === "policy") {
                          this.router.navigate(["/policy/customer-information"]);
                      }
                  }
              }
          } else {
              this.appMessageService.showAppMessage("There are missing or invalid information.", "error");
          }
      }
    }

    public save(): void {
        let dependentValid = true;
        if (this.productInfo.lineCd === "PA" && this.productInfo.sublineCd === "FPA") {
            dependentValid = this.dependentsEdit.length !== 0;
        }

        const form = this.form.getRawValue();
        const occValid:boolean = form?.principalOccupation?.value;
        const martitalStatValid:boolean = form?.principalMaritalStatus?.value;

        if(!occValid) {
          this.appMessageService.showAppMessage("Please input a valid occupation.", "error");
          return;
        }

        if(!martitalStatValid) {
          this.appMessageService.showAppMessage("Please input a valid marital status.", "error");
          return;
        }

        if (this.form.valid && dependentValid) {
            if (this.editFlag) {
                this.appMessageService.showAppMessage("Please finish editing dependents first.", "error");
            } else if (!this.occupationCaveat) {
                this.appMessageService.showAppMessage(this.parameterService.paramV("OCCUPATION_TERMS_ERR_MESSAGE"), "info");
            } else if (!this.validPrincipalBirthday) {
                this.appMessageService.showAppMessage(this.paValidations.PRIN_AGE_ERR_MSG, "info")
            } else {
                this.jsonDataService.contorlLoading(true);
                if (this.policyHolderSearch) {
                    this.checkExistingQuotationNonPublic();
                } else {
                    this.checkExistingQuotationPublic();
                }
            }
        } else {
            this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
        }
    }

    private checkExistingQuotationPublic(): void {
        const lineCd = this.productService.productInfo.lineCd;
        const sublineCd = this.productService.productInfo.sublineCd;
        const planCd = this.productService.productInfo.planInfo.planCd;
        const userType = "public";
        const userId = this.userDetailsService.userId;

        this.apiCallService.checkExistingQuotationPublic(
            [ lineCd, sublineCd, planCd, userType, userId, this.productService.productInfo.quoteId ]
        ).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (res === "valid") {
                        this.processSave();
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage(res, "error");
                    }
                } else {
                    this.appMessageService.showAppMessage("Error validating your principal details.", "error");
                }
            });
        });
    }

  checkExistingQuotationNonPublic() {
    const lineCd = this.productService.productInfo.lineCd;
    const sublineCd = this.productService.productInfo.sublineCd;
    const planCd = this.productService.productInfo.planInfo.planCd;
    const userType = "non-public";
    const form = this.form.getRawValue();
    let lovFlag = form.existingClient != "" ? true : false;
    if(lovFlag) {
      const assdNo = this.assdNo;
      this.apiCallService.checkExistingQuotationPublic([lineCd, sublineCd, planCd, userType, assdNo, this.productService.productInfo.quoteId]).subscribe((response:any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          if (response.status === "SUCCESS") {
            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
            if(res == "valid") {
              this.processSave();
            } else {
              this.jsonDataService.contorlLoading(false);
              this.appMessageService.showAppMessage(res, "error");
            }
          } else {
              this.appMessageService.showAppMessage("Error validating your principal details.", "error");
          }
        });
      });
    } else {
      this.processSave();
    }
  }

    private processMove(): void {
        this.productInfo.personalInfo = this.form.getRawValue();
        this.productInfo.personalInfo["principalBirthDateFormat"] = moment(this.productInfo.personalInfo.principalBirthDate).format("MM/DD/YYYY");
        this.productInfo.personalInfo["principalFullName"] = this.productInfo.personalInfo.principalFirstName + " " + 
            (this.productInfo.personalInfo.principalMiddleName == null ? "" : (this.productInfo.personalInfo.principalMiddleName + " ")) + 
            this.productInfo.personalInfo.principalLastName + " " + 
            (this.productInfo.personalInfo.principalSuffix || "");
        this.productInfo.personalInfo["userId"] = this.userDetailsService.userId;
        this.productInfo.personalInfo["emailAddress"] = this.userDetailsService.email;
        this.productInfo.personalInfo["registrationNo"] = this.registrationNo;
        this.productInfo.personalInfo["mobileNo"] = this.userDetailsService.mobileNo;
        this.productInfo.personalInfo["accountType"] = this.userDetailsService.accountType;
        this.productInfo.personalInfo["lineCd"] = this.productService.productInfo.lineCd;
        this.productInfo.personalInfo["sublineCd"] = this.productService.productInfo.sublineCd;
        this.productInfo.personalInfo["planCd"] = this.productService.productInfo.planInfo.planCd;
        this.productInfo.personalInfo["assdNo"] = this.assdNo;
        this.productInfo.personalInfo["quoteId"] = this.productService.productInfo.quoteId;
        this.productInfo.personalInfo["occupationCaveat"] = this.occupationCaveat;

        let dependentsInsert = this.dependentsEdit.filter((data: any) => {
            return data.lastName != "" ||
                   data.firstName != "" ||
                   data.middleName != "" ||
                   data.suffix != "" ||
                   data.relationship != "" ||
                   data.occupation != "" ||
                   data.maritalStatus != "" ||
                   data.birthDate != "" ||
                   data.age != "";    
        });

        this.productInfo.dependents = this.dependents;
        if (this.validateDependentsData().invalidFlag !== true || this.tableForm.pristine) {
            this.productInfo.dependentsEdit = dependentsInsert;
        } else {
            this.productInfo.dependents.splice(0, this.dependents.length);
            this.productInfo.dependentsEdit.splice(0, this.dependentsEdit.length);
        }
    }

    private processSave(): void {
        let dependentsInsert = this.dependentsEdit.filter((data: any) => {
            return data.lastName !== "" ||
                   data.firstName !== "" ||
                   data.middleName !== "" ||
                   data.suffix !== "" ||
                   data.relationship !== "" ||
                   data.occupation !== "" ||
                   data.maritalStatus !== "" ||
                   data.birthDate !== "" ||
                   data.age !== "";    
        });

        let insertData = this.form.getRawValue();
        insertData["principalBirthDate"] = moment(insertData.principalBirthDate).format("YYYY-MM-DD");
        insertData["userId"] = this.userDetailsService.userId;
        insertData["emailAddress"] = this.userDetailsService.email;
        insertData["registrationNo"] = this.registrationNo;
        insertData["mobileNo"] = this.userDetailsService.mobileNo;
        insertData["accountType"] = this.userDetailsService.accountType;
        insertData["lineCd"] = this.productService.productInfo.lineCd;
        insertData["sublineCd"] = this.productService.productInfo.sublineCd;
        insertData["planCd"] = this.productService.productInfo.planInfo.planCd;
        insertData["assdNo"] = this.assdNo;
        insertData["quoteId"] = this.productService.productInfo.quoteId;

        this.apiCallService.savePrincipalDetails(
            [ insertData, dependentsInsert ]
        ).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.saveMessageFlag = true;
                    if (this.supportingDocsFiles.length > 0 && this.withSupportingDocChange) {
                        this.uploadSupportingDocs(0, response);
                    } else {
                        if (this.userDetailsService.accountType === "P") {
                            this.processMove();
                            this.initializePrincipalDetails();
                        } else {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.nonPublicAfterSave(res);
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage("Successfully saved.", "success");
                        }

                        // this.jsonDataService.contorlLoading(false);
                        // this.appMessageService.showAppMessage("Successfully saved.", "success");
                    }

                    this.form.markAsPristine();
                    this.tableForm.markAsPristine();
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Error saving your principal details.", "error");
                }
            });
        });
    }

  async uploadSupportingDocs(index: number, response: any) {
    this.jsonDataService.contorlLoading(true);
    const base64String = await this.formService.getFileAsBase64(this.supportingDocsFiles[index]);
    this.apiCallService.getPresignedUrl({
      action: "putObject",
      directory: `supporting-docs/personal-details/${this.productInfo.quoteId}/1`,
      filename: this.supportingDocsFiles[index].name,
      validation: "VehicleSuppDoc",
      base64FileString: base64String
    }).subscribe((getPresignedUrlResponse: any) => {
      this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
        this.jsonDataService.contorlLoading(false);
          if (getPresignedUrlResponse.status === "SUCCESS") {
            this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                if (res.presignedUrl) {
                  this.apiCallService.uploadPresignedUrl(
                      res.presignedUrl, this.supportingDocsFiles[index]
                  ).subscribe((uploadPresignedUrlResponse: any) => {
                      if (index === (this.supportingDocsFiles.length-1)) {
                        if(this.userDetailsService.accountType == "P") {
                          this.processMove();
                          this.initializePrincipalDetails();
                        } else {
                          const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                          this.nonPublicAfterSave(res);
                        }
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage("Successfully saved.", "success");
                      } else {
                          this.uploadSupportingDocs(index+1, response);
                      }
                  });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.supportingDocsFiles = [];
                    this.form.controls["supportingDocs"].setValue([]);
                    this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                }
            });
          } else {
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage("There's something wrong saving your personal details. Please try again later.", "error");
          }
      });
    });
  }

  onFileChange(event: any): void {
    this.withSupportingDocChange = true;

    let totalSize = 0;
    this.form.controls["supportingDocs"].value.forEach((el: any) => {
        totalSize += (el.size / 1024 / 1024);
    });

    if (event.target.files && event.target.files.length) {
        const files = event.target.files;

        for (let index = 0; index < files.length; index++) {
            const reader = new FileReader();
            const element = files[index];

            reader.readAsDataURL(element);
            reader.onload = () => {
                this.supportDocErrorMsg = "";
                const type = mimeTypes.getExtension(element.type) || "";
                if (!this.allowedFileExt.includes(type.toUpperCase())) {
                    this.supportDocErrorMsg = "Some files were not attached. Accepted file types: " + this.allowedFileExt.join(", ");
                } else {
                    totalSize += (element.size / 1024 / 1024);

                    if (totalSize > this.allowedFileSize) {
                        this.supportDocErrorMsg = "You have reached the maximum file size for the attachment. Some files were not uploaded.";
                    } else {
                        const supportingDocs = this.form.controls["supportingDocs"].value;
                        supportingDocs.push({
                            filename: element.name,
                            size: element.size
                        });

                        this.supportingDocsFiles.push(element);
                        this.form.controls["supportingDocs"].setValue(supportingDocs);
                        this.form.markAsDirty();
                    }
                }

                if (this.supportDocErrorMsg) {
                    setTimeout(() => {
                        this.supportDocErrorMsg = "";
                    }, 5000);
                }
            };
        }
    }
  }

  onClickView(index: number): void {
    const filename = this.form.controls["supportingDocs"].value[index].filename;

    let newFile = false;
    for (const supportingDocsFile of this.supportingDocsFiles) {
        if (supportingDocsFile.name === filename) {
            newFile = true;
            this.downloadService.downloadFile(supportingDocsFile);
        }
    }

    if (!newFile) {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getPresignedUrl({
            action: "getObject",
            directory: `supporting-docs/personal-details/${this.productInfo.quoteId}/1`,
            filename: filename
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.jsonDataService.contorlLoading(false);
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        this.downloadService.downloadFileUsingPresignedUrl(res.presignedUrl, filename);
                    });
                }
            });
        });
    }
  }

  onClickDelete(index: number): void {
    this.form.controls["supportingDocs"].setValue(
        this.form.controls["supportingDocs"].value.filter((a:any, i:number) => { return i !== index })
    );
    this.supportingDocsFiles.splice(index, 1);
    this.form.markAsDirty();
    this.withSupportingDocChange = true;
  }

  clear() {
    this.assdNo = null;
    this.form.patchValue({
      existingClient        : "",
      policyHolderAdress    : "",
      principalLastName     : "",
      principalFirstName    : "",
      principalMiddleName   : "",
      principalSuffix       : "",
      principalBirthDate    : "",
      principalOccupation   : "",
      principalMaritalStatus: "",
      age                   : "",
    });
    this.form.get('supportingDocs')?.reset();
    this.form.get('principalLastName')?.enable();
    this.form.get('principalFirstName')?.enable();
    this.form.get('principalBirthDate')?.enable();
    this.form.get('principalMaritalStatus')?.enable();
    this.dependents.splice(0, this.dependents.length);
    this.dependentsEdit.splice(0, this.dependentsEdit.length);
    this.dataSource = new MatTableDataSource(this.dependents);
    this.firstDependent = this.dependents;
  }

  validatePrincipalBirthDate() {
    this.validPrincipalBirthday = false;
    const ageValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
    const age = moment().diff(this.form.get('principalBirthDate')?.value, 'years', false);
    const dob = moment(this.form.get('principalBirthDate')?.value);
    const dobWithValidityPeriod = dob.subtract(ageValidityPeriod, 'days');
    const minAge = this.paValidations.MIN_PRIN_AGE;
    const maxAge = this.paValidations.MAX_PRIN_AGE;
    const ageError = this.paValidations.PRIN_AGE_ERR_MSG;
    const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
    const lowLimitAge = moment().year() - moment(this.form.get('principalBirthDate')?.value).year();
    const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
    if(isNaN(age)) {
      this.appMessageService.showAppMessage("Invalid Date", "error");
      this.form.patchValue({principalBirthDate: ""});
      this.form.patchValue({age: ""});
    }else if(this.form.get('principalBirthDate')?.value > moment()) {
      this.appMessageService.showAppMessage("Invalid Date", "error");
      this.form.patchValue({principalBirthDate: ""});
      this.form.patchValue({age: ""});
    } else if(maxLimitEffAge < minAge || maxLimitEffAge > maxAge) {
      this.appMessageService.showAppMessage(ageError, "info");
      this.form.patchValue({principalBirthDate: ""});
      this.form.patchValue({age: ""});
    } else {
      this.validPrincipalBirthday = true;
      this.form.patchValue({age: maxLimitEffAge});
    }
  }

  preValidatePrincipalBirthDate() {
    this.validPrincipalBirthday = false;
    const ageValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
    const age = moment().diff(this.form.get('principalBirthDate')?.value, 'years', false);
    const dob = moment(this.form.get('principalBirthDate')?.value);
    const dobWithValidityPeriod = dob.subtract(ageValidityPeriod, 'days');
    const minAge = this.paValidations.MIN_PRIN_AGE;
    const maxAge = this.paValidations.MAX_PRIN_AGE;
    const ageError = this.paValidations.PRIN_AGE_ERR_MSG;
    const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
    const expDate = moment(this.productInfo.coverageInfo.expDate).startOf('day');
    const lowLimitAge = moment().year() - moment(this.form.get('principalBirthDate')?.value).year();
    const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
    this.form.get('age')?.setValue(maxLimitEffAge || "");
    // if(!isNaN(age) && !(this.form.get('principalBirthDate')?.value > moment()) && !(lowLimitAge < minAge || age > maxAge)) {
    //   this.validPrincipalBirthday = true;
    // }
    
    // With age validity and eff date
    if(!isNaN(age) && !(this.form.get('principalBirthDate')?.value > moment()) && !(maxLimitEffAge < minAge || maxLimitEffAge > maxAge)) {
      this.validPrincipalBirthday = true;
    }
  }

  getPdf(event: MouseEvent) {
    event.preventDefault();
    const occupationTermsLink = this.parameterService.paramV("OCCUPATION_TERMS_LINK");
    window.open(occupationTermsLink);
  }

  dependentOccupationBlur(i: number, event: any) {
    const nodeName = event.relatedTarget !== null ? event.relatedTarget.nodeName : null;
    if(event.relatedTarget === null || nodeName !== "MAT-OPTION") {
      var arrayControl = this.tableForm.get('dependents') as FormArray;
      const row = arrayControl.controls[i];
      const rowOccupation = row.get("occupation")?.value;
      if(typeof rowOccupation !== "object" && rowOccupation !== "") {
        row.get("occupation")?.setValue("");
        this.appMessageService.showAppMessage("Please select Occupation.", "info");
      }
    }
  }

  supportDocsCheck() {
    const lineCd = this.productInfo.lineCd || "";
    const sublineCd = this.productInfo.sublineCd || "";
    const planCd = this.productInfo.planInfo.planCd || "";
    const plans = this.jsonDataService.data.plans.filter((plans: any) => {
      return plans.supportDocTag && plans.lineCd === lineCd && plans.sublineCd === sublineCd && plans.planCd === planCd
    }).map((supportDocTag: any) => {
      return supportDocTag.supportDocTag
    })[0] || "";
    this.displaySupportDocs = plans === "Y" ? true : false;
  }

  paValidationsSetter() {
    const lineCd = this.productInfo.lineCd;
    const sublineCd = this.productInfo.sublineCd;
    const planCd = this.productInfo.planInfo.planCd;
    this.paValidations = this.jsonDataService.data.paValidations.filter((validators: any) => {
      return validators.LINE_CD === lineCd && validators.SUBLINE_CD === sublineCd && validators.PLAN_CD === planCd
    }).length !== 0 ? this.jsonDataService.data.paValidations.filter((validators: any) => {
      return validators.LINE_CD === lineCd && validators.SUBLINE_CD === sublineCd && validators.PLAN_CD === planCd
    })[0] : "";
    this.individualFlag = this.paValidations.DEPENDENTS_TAG === "Y" ? false : true;

    //=================== New requirement for depWithPremium ======================
    if(this.paValidations.MAX_DEP_WITH_PREM > 0) {
      this.individualFlag = false;
    }
    //=================== New requirement for depWithPremium ======================

    this.instructionText = this.paValidations.INCLUSION_TEXT;
  }

  nonPublicAfterSave(res: any) {
    this.assdNo = this.assdNo || res.assdNo;
    this.form.patchValue({existingClient: res.displayName});
    this.processMove();
  }

  validateDependentsBirthDate(event: any, i: number) {
    const dob = event.value;
    const row = this.rows.controls[i];
    if(dob !== null) {
      // comment yung if para gumana din sa tinype lng
      // if(typeof dob._i === "object") {
        const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
        dob.add(depAgeValidityPeriod, 'days'); // para pag click sa datepicker kung anong date yung hulinh pinili nag susubstract din kasi yung date sa datepicker pag click ulit
        const dobWithValidityPeriod = moment(dob.subtract(depAgeValidityPeriod, 'days'));
        const dependentSetAge = moment().diff(dob, 'years', false);
        let dependentAge = moment().diff(dobWithValidityPeriod, 'years', false);
        const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
        const expDate = moment(this.productInfo.coverageInfo.expDate).startOf('day');
        const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);

        const maxDependentParentSpouseAge = this.paValidations.MAX_PARENT_SPOUSE_AGE;
        const minDependentSpouseAge = this.paValidations.MIN_SPOUSE_AGE;
        const minDependentChildSiblingAge = this.paValidations.MIN_CHILD_SIBLING_AGE;
        const maxDependentChildSiblingAge = this.paValidations.MAX_CHILD_SIBLING_AGE;
        const dependetAgeErrorMsg = this.paValidations.DEP_AGE_ERR_MSG;
        const relationshipCd = row.get('relationship')?.value.value;
        const principalAge = effDate.diff(this.form.get('principalBirthDate')?.value, 'years', false);
        let lowLimitDependentAge = moment().year() - moment(dob).year(); // changed to dob to fix error when changing when there is an existing value already
        const youngerParentErrorMsg = this.parameterService.paramV("PARENT_AGE_ERR_MESSAGE");

        this.dependentsEdit[i].birthDate = typeof dob != "string" && dob !== null ? dob.format('YYYY-MM-DD') : dob;
        this.dependentsEdit[i].age = maxLimitEffAge;
        
        let ageWithAddedYear = moment(dobWithValidityPeriod);
        ageWithAddedYear.add(1, 'years');
        row.get('age')?.setValue(maxLimitEffAge);
        if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) { // checks next birthday against eff date
          if((relationshipCd === 10 || relationshipCd === 9) && dependentAge < 1) { // checks whether child or sibling less than 1 year old
            row.get('age')?.setValue(1);
            this.dependentsEdit[i].age = 1;
            dependentAge = 1;
          } else {
            // row.get('age')?.setValue(maxLimitEffAge);
            // this.dependentsEdit[i].age = maxLimitEffAge;
            // dependentAge = maxLimitEffAge;
          }
        } else {
          row.get('age')?.setValue(maxLimitEffAge);
          this.dependentsEdit[i].age = maxLimitEffAge;
          dependentAge = maxLimitEffAge;
        }

        // rounding of age to 1 when less than 1
        // if((relationshipCd === 10 || relationshipCd === 9) && dependentSetAge < 1) {
        //     if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) {
        //     row.get('age')?.setValue(1);
        //     this.dependentsEdit[i].age = 1;
        //   }
        // }
        if(dependentAge === null) {
          this.appMessageService.showAppMessage("Invalid Date", "error");
          row.get('birthDate')?.setValue('');
          row.get('age')?.setValue('');
          this.dependentsEdit[i].birthDate = "";
          this.dependentsEdit[i].age = "";
        } else if(dob > moment()) {
          this.appMessageService.showAppMessage("Invalid Date", "error");
          row.get('birthDate')?.setValue('');
          row.get('age')?.setValue('');
          this.dependentsEdit[i].birthDate = "";
          this.dependentsEdit[i].age = "";
        } else if(relationshipCd == 1 || relationshipCd == 2 || relationshipCd == 8) {
          if(dependentAge > maxDependentParentSpouseAge) {
            this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            row.get('birthDate')?.setValue('');
            row.get('age')?.setValue('');
            this.dependentsEdit[i].birthDate = "";
            this.dependentsEdit[i].age = "";
          }
          if(dependentAge <= principalAge) {
            this.appMessageService.showAppMessage(youngerParentErrorMsg, "error");
            row.get('birthDate')?.setValue('');
            row.get('age')?.setValue('');
            this.dependentsEdit[i].birthDate = "";
            this.dependentsEdit[i].age = "";
          }
        } else if (relationshipCd == 7) {
          if(dependentAge > maxDependentParentSpouseAge || maxLimitEffAge < minDependentSpouseAge) {
            this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            row.get('birthDate')?.setValue('');
            row.get('age')?.setValue('');
            this.dependentsEdit[i].birthDate = "";
            this.dependentsEdit[i].age = "";
          }
        } else if (relationshipCd === undefined) {
          this.appMessageService.showAppMessage("Please select a relationship first", "error");
          row.get('birthDate')?.setValue('');
          row.get('age')?.setValue('');
          this.dependentsEdit[i].birthDate = "";
          this.dependentsEdit[i].age = "";
        } else {
          if(dependentAge < minDependentChildSiblingAge || dependentAge > maxDependentChildSiblingAge) {
            this.appMessageService.showAppMessage(dependetAgeErrorMsg, "error");
            row.get('birthDate')?.setValue('');
            row.get('age')?.setValue('');
            this.dependentsEdit[i].birthDate = "";
            this.dependentsEdit[i].age = "";
          }
        }
      // }
    }
  }

  backToQuotationProcessing() {
    this.productInfo.creationProcess = "quotation";
    this.productInfo.previousProcess = "quotation";
    this.productService.setProductInfo2(this.productInfo);
    this.router.navigate(["/quotation/choose-plan"]);
  }

  reprintQuote() {
    this.productInfo.creationProcess = "quotation";
    this.productInfo.previousProcess = "policy";
    this.productService.setProductInfo2(this.productInfo);
    this.router.navigate(["/quotation/email-print"]);
  }

  getOccupationDependents(event: any, i: number): void {
    let occupationOptions = [];
    const nodeName = event.relatedTarget !== null ? event.relatedTarget.nodeName : null;
    if(event.relatedTarget === null || nodeName !== "MAT-OPTION") {
      var arrayControl = this.tableForm.get('dependents') as FormArray;
      const row = arrayControl.controls[i];
      const rowRelationship = row.get("relationship")?.value;
      if(typeof rowRelationship !== "object" && rowRelationship !== "") {
        row.get("relationship")?.setValue("");
        this.appMessageService.showAppMessage("Please select relationship.", "info");
      } else {
        const unEmpRelCds = this.parameterService.paramV("CHECK_RELATIONSHIP_CD_FOR_UNEMP").split(",");
        const occCdChildSib = this.parameterService.paramV("OCCUPATION_CHILD_SIB").split(",");
        if(rowRelationship) {
          for(let occupations of this.jsonDataService.data.occupation) {
            if(unEmpRelCds.includes(rowRelationship.value.toString())) {
              if(occCdChildSib.includes(occupations.occCd.toString())) {
                occupationOptions.push({
                  value: occupations.occCd,
                  text: occupations.occDesc,
                });
              }
            } else {
              occupationOptions.push({
                value: occupations.occCd,
                text: occupations.occDesc,
              });
            }
          }
          occupationOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
          this.filteredDependentsOccupationOptions[i] = this.autocompleteService.setArray(this.tableForm, "occupation", occupationOptions, "dependents", i);
        } else {
          this.filteredDependentsOccupationOptions[i] = this.autocompleteService.setArray(this.tableForm, "occupation", this.getOccupation(), "dependents", i);
        }        
      }
    }
  }

  showConfirm(action: string): void {
    this.dialogBoxService.open({
        messageType: "C",
        content: "You have unsaved changes. Do you want to continue?",
        buttons: [
            { text: "No" },
            {
                text: "Yes", action: () => {
                    this.proceedMove(action);
                }
            }
        ]
    });
  }

  proceedMove(action: string): void {
    if(action == "back") {
      if (this.productInfo.creationProcess === "quotation") {
        this.router.navigate(["/quotation/coverage-perils"]);
      } else if (this.productInfo.creationProcess === "policy") {
          this.router.navigate(["/dashboard"]);
      }
    } else if (action === "next") {
      if (this.productInfo.creationProcess === "quotation") {
        this.router.navigate(["/quotation/review-premium-charges"]);
      } else if (this.productInfo.creationProcess === "policy") {
          this.router.navigate(["/policy/customer-information"]);
      }
    }
  }

  roundUpDependentsAge() {
    this.dependentsEdit = this.dependentsEdit.map((data: any) => {
      const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
      const dobWithValidityPeriod = moment(moment(data.birthDate).subtract(depAgeValidityPeriod, 'days'));
      const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
      const expDate = moment(this.productInfo.coverageInfo.expDate).startOf('day');
      const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
          
      let ageWithAddedYear = moment(dobWithValidityPeriod);
      ageWithAddedYear.add(1, 'years');
      if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) { // checks next birthday against eff date
        if((data.relationship.value === 10 || data.relationship.value === 9) && data.age < 1) { // checks whether child or sibling less than 1 year old
          data.age = 1;
          // dependentAge = 1;
        }
      } else {
        data.age = maxLimitEffAge;
      }
      // data.maritalStatus.text = data.maritalStatus.text.toUpperCase();
      return data;
    });
    this.dependents = this.dependents.map((data: any) => {
      const depAgeValidityPeriod = this.parameterService.paramN("DEP_AGE_VALIDITY_PERIOD");
      const dobWithValidityPeriod = moment(moment(data.birthDate).subtract(depAgeValidityPeriod, 'days'));
      const effDate = moment(this.productInfo.coverageInfo.effDate).startOf('day');
      const expDate = moment(this.productInfo.coverageInfo.expDate).startOf('day');
      const maxLimitEffAge = effDate.diff(dobWithValidityPeriod, 'years', false);
      const relationshipCd = this.jsonDataService.data.relationship.filter((relationship: any) => {
        return relationship.relationshipDesc == data.relationship
      })[0].relationshipCd;
          
      let ageWithAddedYear = moment(dobWithValidityPeriod);
      ageWithAddedYear.add(1, 'years');
      if(ageWithAddedYear.isSameOrAfter(effDate) && ageWithAddedYear.isSameOrBefore(expDate)) { // checks next birthday against eff date
        if((relationshipCd === 10 || relationshipCd === 9) && data.age < 1) { // checks whether child or sibling less than 1 year old
          data.age = 1;
          // dependentAge = 1;
        }
      } else {
        data.age = maxLimitEffAge;
      }
      // data.birthDate = moment(data.birthDate).format("MM/DD/YYYY");
      // data.maritalStatus.text = data.maritalStatus.text.toUpperCase();
      return data;
    });
    // this.dataSource = new MatTableDataSource(this.dependents);
  }

  formatDependentDob() {
    this.dependents = this.dependents.map((data: any) => { // formatting ng date sa dependents main table
      data.birthDate = moment(data.birthDate).format("MM/DD/YYYY");
      return data;
    });
    this.dataSource = new MatTableDataSource(this.dependents);
  }

  deleteDependents(i: any): void {
    this.dependentsEdit[i] = {...this.dependentsEdit[i],
                              lastName:       "",
                              firstName:      "",
                              middleName:     "",
                              suffix:         "",
                              relationship:   "",
                              occupation:     "",
                              maritalStatus:  "",
                              birthDate:      "",
                              age:            "" };
    this.rows.at(i)?.patchValue({
      lastName:       "",
      firstName:      "",
      middleName:     "",
      suffix:         "",
      relationship:   "",
      occupation:     "",
      maritalStatus:  "",
      birthDate:      "",
      age:            ""
    });
    this.deleteDependentsFlag[i] = false;
    // this.dataSourceEdit = new MatTableDataSource(this.dependentsEdit);
    if(this.dataSourceEdit?.data) {
      this.dataSourceEdit.data = this.dependentsEdit;
    }
  }

  dependentsDeleteEnabler(i: number) {
    const row = this.dependentsEdit[i];
    if(
      row.lastName       != "" || 
      row.firstName      != "" ||
      row.age            != "" ||
      row.maritalStatus  != "" ||
      row.middleName     != "" ||
      row.occupation     != "" ||
      row.relationship   != "" ||
      row.suffix         != ""
    ) {
      this.deleteDependentsFlag[i] = true;
    } else {
      this.deleteDependentsFlag[i] = false;
    }
  }

  setOccupationViaInput(event: any): void {
    this.formService.setFieldToUpperCase(this.form, "principalOccupation", true, event);
    for (const occupation of this.getOccupation()) {
        if (occupation.text === this.form.controls["principalOccupation"].value.text) {
            this.form.controls["principalOccupation"].setValue(occupation);
        }
    }
  }

  setMaritalStatViaInput(event: any): void {
    this.formService.setFieldToUpperCase(this.form, "principalMaritalStatus", true, event);
    for (const maritalStat of this.getMaritalStatus()) {
        if (maritalStat.text === this.form.controls["principalMaritalStatus"].value.text) {
            this.form.controls["principalMaritalStatus"].setValue(maritalStat);
        }
    }
  }
}
