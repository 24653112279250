<section id="header" [class]="getHeaderStyle()">
    <div class="container">
        <a routerLink="" class="nav-logo">
            <!--
                WAG GANITO IMPLEMENT. NAGKAKAROON NG DELAY SA TRANSITION KAPAG NAGBAGO NG STYLE
                <img [src]="getHeaderStyle() === 'style1' ? varCompanyLogo1 : varCompanyLogo2" alt="BPI/MS">
            -->
            <img *ngIf="getHeaderStyle() === 'style1'" [src]="varCompanyLogo1" alt="BPI MS">
            <img *ngIf="getHeaderStyle() === 'style2'" [src]="varCompanyLogo2" alt="BPI MS">
        </a>
        <input type="checkbox" id="chk-sp-menu-trigger" (click)="toggleHtmlScroll()" [(ngModel)]="chkSpTrigger">
        <div class="sp-menu-container">
            <a (click)="navigateTo('/login')" *ngIf="!userDetailsService.userId" class="btn btn1 btn-login">Login</a>
            <div (click)="isMenuOpen = !isMenuOpen" class="user-menu proxima" *ngIf="userDetailsService.userId">
                <ng-container *ngIf="userDetailsService.accountType=='P' else noProfile">
                    <a>HI {{getUserName()}}</a>
                    <ul [class.show]="isMenuOpen">
                        <li (click)="navigateTo('/dashboard')"><a>Dashboard</a></li>
                        <li (click)="navigateTo('/profile')"><a>User Profile</a></li>
                        <li (click)="navigateTo('/change-password')"><a>Change Password</a></li>
                        <li (click)="logout()"><a>Logout</a></li>
                    </ul>
                </ng-container>
                <ng-template #noProfile>
                    <a>HI {{getUserName()}}</a>
                    <ul [class.show]="isMenuOpen">
                        <li (click)="navigateTo('/dashboard')"><a>Dashboard</a></li>
                        <li (click)="navigateTo('/change-password')"><a>Change Password</a></li>
                        <li (click)="logout()"><a>Logout</a></li>
                    </ul>
                </ng-template>
            </div>
            <div class="sp-menu">
                <div></div>
            </div>
        </div>
        <nav class="proxima">
            <div class="nav-left">
                <ul>
                    <li (click)="navigateTo('/')"><a>Browse Products</a></li>
                    <li><a href="{{aboutUsLink}}" target="_blank">About</a></li>
                    <li><a href="{{contactUsLink}}" target="_blank">Contact Us</a></li>
                    <li class="parent-menu">
                        <a>Branches</a>
                        <ul class="sub-menu">
                            <li><a (click)="gotoLink('ncr')">Metro Manila</a></li>
                            <li><a (click)="gotoLink('luzon')">Luzon</a></li>
                            <li><a (click)="gotoLink('visayas')">Visayas</a></li>
                            <li><a (click)="gotoLink('mindanao')">Mindanao</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="nav-right">
                <ul *ngIf="!userDetailsService.userId">
                    <li><a routerLink="register" class="btn-reg">Register</a></li>
                    <li><a routerLink="login" class="btn btn1 btn-login">Login</a></li>
                </ul>
                <ul *ngIf="userDetailsService.userId">
                    <li class="parent-menu">
                        <a>HI {{getUserName()}}</a>
                        <ul class="sub-menu user-menu">
                            <li (click)="navigateTo('/dashboard')"><a>Dashboard</a></li>
                            <ng-container *ngIf="userDetailsService.accountType=='P'">
                                <li (click)="navigateTo('/profile')"><a>User Profile</a></li>
                            </ng-container>
                            <li (click)="navigateTo('/change-password')"><a>Change Password</a></li>
                            <li (click)="logout()"><a>Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="nav-mobile">
                <ul>
                    <li (click)="navigateTo('/')"><a>Browse Products</a></li>
                    <li><a href="{{aboutUsLink}}" target="_blank">About</a></li>
                    <li><a (click)="trackContactService()" href="{{contactUsLink}}" target="_blank">Contact Us</a></li>
                    <li class="parent-menu" (click)="chkSubBranchTrigger = !chkSubBranchTrigger">
                        <input type="checkbox" id="chk-sub-branches-trigger" [(ngModel)]="chkSubBranchTrigger">
                        <a>Branches</a>
                        <ul class="sub-menu">
                            <li><a (click)="gotoLink('ncr')">Metro Manila</a></li>
                            <li><a (click)="gotoLink('luzon')">Luzon</a></li>
                            <li><a (click)="gotoLink('visayas')">Visayas</a></li>
                            <li><a (click)="gotoLink('mindanao')">Mindanao</a></li>
                        </ul>
                    </li>
                    <!-- INCASE BALIK SA DATI -->
                    <!-- <li class="parent-menu" (click)="chkSubUserTrigger = !chkSubUserTrigger" *ngIf="userDetailsService.userId">
                        <input type="checkbox" id="chk-sub-user-trigger" [(ngModel)]="chkSubUserTrigger">
                        <a>HI {{getUserName()}}</a>
                        <ul class="sub-menu">
                            <li (click)="navigateTo('/dashboard')"><a>Dashboard</a></li>
                            <li (click)="logout()"><a>Logout</a></li>
                        </ul>
                    </li> -->
                </ul>
            </div>
        </nav>
    </div>
</section>