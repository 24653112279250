import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[birthDate]',
    providers: [{provide: NG_VALIDATORS, useExisting: BirthDateDirective, multi: true}]
})
export class BirthDateDirective implements AfterViewInit,Validator {

    constructor (
        private el: ElementRef
    ) { }

    validate(control: AbstractControl): ValidationErrors | null {
        const birthDate = new Date(control.value);
        const upperLegalDate = new Date();
            upperLegalDate.setFullYear(new Date().getFullYear() - 18);
        const lowerLegalDate = new Date();
            lowerLegalDate.setFullYear(new Date().getFullYear() - 100);
        const isLegel = birthDate.getTime() < upperLegalDate.getTime() && birthDate.getTime() > lowerLegalDate.getTime();
        return !isLegel ? {'birthDate': true} : null;
    }

    ngAfterViewInit():void {
       this.el.nativeElement.placeholder = 'MM/DD/YYYY';
    }

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    @HostListener("keydown", ["$event"])
    onKeyDown(e:KeyboardEvent) {
        if (
               this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
            || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
            || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
            || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
            || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
            || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
            || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
            || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
            || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        /*if (e.shiftKey || (!parseInt(e.key) && parseInt(e.key) !== 0)) {
            e.preventDefault();
        }*/
        if (e.shiftKey || !e.key.match(/^[0-9\/\-]$/g)) {
            e.preventDefault();
        }
    }

    @HostListener("paste", ["$event"])
    onPaste(event:ClipboardEvent) {
        event.preventDefault();

        const pastedInput = event.clipboardData?.getData("text/plain").replace(/[^0-9\/\-.]/g, "");
        pastedInput && document.execCommand("insertText", false, pastedInput);
    }
}