import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppMessageService } from 'src/app/services/app-message.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from './json-data.service';
import { UserDetailsService } from './user-details.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Injectable({
    providedIn: 'root'
})
export class PageValidationService {

    productInfo:any = {};
    currentUrl!:any[];

    constructor (
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private productService: ProductService,
        private appMessageService: AppMessageService,
        private userDetailsService: UserDetailsService,
        private jsonDataService: JsonDataService,
        private parameterService: ParameterService
    ) { }

    validatePageAccessibility():void {
        this.productInfo = this.productService.productInfo || {};
        this.currentUrl = this.router.url.split("/");

        let noPageAccess = true;

        // LOGIN / REGISTER VALIDATION
        if (   (["login", "register"].indexOf(this.currentUrl[1]) >= 0 || this.currentUrl[1].includes("login"))
            && this.satisfyLoginRegisterRequirement()) {
            noPageAccess = false;
        }

        // DASHBOARD VALIDATION
        else if (this.currentUrl[1] === "dashboard" && this.satisfyDashboardRequirement()) {
            noPageAccess = false;
        }

        // PROFILE VALIDATION
        else if (this.currentUrl[1] === "profile" && this.satisfyProfileRequirement()) {
            noPageAccess = false;
        }

        // QUOTATION VALIDATION
        else if (this.currentUrl[1].includes("quotation") && this.satisfyQuotationRequirement()) {
            noPageAccess = false;

            // Kung may next URL after quotation (meaning di introduction page)
            if (this.currentUrl[2] !== undefined) {
                this.validateQuotationPagesAccessibility(this.currentUrl[2]);
            }
        }

        // POLICY VALIDATION
        else if (this.currentUrl[1] === "policy" && this.satisfyPolicyRequirement()) {
            noPageAccess = false;
            this.validatePolicyPagesAccessibility(this.currentUrl[2]);
        }
        
        if (noPageAccess) {
            this.router.navigate(["/renewal"]);
        }
    }

    validateQuotationPagesAccessibility(page:string, showAppMessage:boolean = true):void {
        if (page === "choose-plan") {
            if (this.productInfo.lineCd === 'HA') {

                if ([9, 10].includes(this.productInfo.planEvaluated)) {
                    this.router.navigate(["/quotation/property-assessment"]);
                }
                
                let reqForQuBankSw = this.parameterService.paramV('REQ_FOR_QU_BANK_SW')?.split(',') ?? [];
                let reqForQuBank = this.parameterService.paramV('REQ_FOR_QU_BANK')?.split(',');
                if(!reqForQuBankSw?.includes(this.userDetailsService.accountType || 'P') && reqForQuBank.includes(this.productInfo.propertyAssessment?.bankDetail?.mortAccGrp)) {
                    this.productInfo.planEvaluated = 10;
                    this.productService.setProductInfo2(this.productInfo);
                    this.router.navigate(["/quotation/property-assessment"]);
                }
                
                if(this.productInfo.propertyAssessment?.roof?.classCd != 'A' || this.productInfo.propertyAssessment?.wall?.classCd != 'A'){
                    this.productInfo.planEvaluated = 9;
                    this.productService.setProductInfo2(this.productInfo);
                    this.router.navigate(["/quotation/property-assessment"]);
                }
            }
        } else if (page === "vehicle-details") {
            // Kung wala pa plan code
            if (!this.productInfo.planInfo?.planCd) {
                showAppMessage ? this.appMessageService.showAppMessage("Please choose an insurance plan.", "info") : "";
                this.router.navigate(["/quotation/choose-plan"]);
            }

            this.validateQuotationPagesAccessibility("choose-plan");
        } else if (page === "personal-details") {

            if (!this.productInfo.planInfo?.planCd) {
                showAppMessage ? this.appMessageService.showAppMessage("Please choose an insurance plan.", "info") : "";
                this.router.navigate(["/quotation/choose-plan"]);
            }

            // if (!this.productInfo.coverageInfo) {
            //     showAppMessage ? this.appMessageService.showAppMessage("You don't have any computed coverage.", "info") : "";
            //     this.router.navigate(["/quotation/coverage-perils"]);
            // }

            this.validateQuotationPagesAccessibility("choose-plan");

        } else if (page === "property-details") {
            if (!this.productInfo.planInfo?.planCd) {
                showAppMessage ? this.appMessageService.showAppMessage("Please choose an insurance plan.", "info") : "";
                this.router.navigate(["/quotation/choose-plan"]);
            }

            this.validateQuotationPagesAccessibility("choose-plan");
        } else if (page === "coverage-perils") {
            // Kung wala pa saved na vehicleInfo
            // Ginamit ko lang yearModelDetail since required lagi sa quotation
            // Sure may laman kapag nag save
            // ? Check LineCD
            if (this.productInfo.lineCd === 'MC') {
                if (!this.productInfo.vehicleInfo?.yearModelDetail) {
                    showAppMessage ? this.appMessageService.showAppMessage("Please fill the vehicle details form.", "info") : "";
                    this.router.navigate(["/quotation/vehicle-details"]);
                }

                // NOTE: Currently walang checking ng mga quotation required fields

                this.validateQuotationPagesAccessibility("vehicle-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if (this.productInfo.lineCd === 'PA') {
                if (!this.productInfo.planInfo?.planCd) {
                    showAppMessage ? this.appMessageService.showAppMessage("Please choose an insurance plan.", "info") : "";
                    this.router.navigate(["/quotation/choose-plan"]);
                }
    
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if (this.productInfo.lineCd === 'HA') {
                let requiredLat = this.parameterService.paramV("REQ_HA_LAT");
                let requiredLong = this.parameterService.paramV("REQ_HA_LONG");
                let requiredHouseNo = this.parameterService.paramV("REQ_HA_HOUSE_NO");
                let requiredStreet = this.parameterService.paramV("REQ_HA_STREET");
                let requiredBrgy = this.parameterService.paramV("REQ_HA_BRGY");

                if (requiredLat === 'Q') {
                    if (!this.productInfo.propertyInfo?.latitude) {
                        showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                        this.router.navigate(["/quotation/property-details"]);
                    }
                }

                if (requiredLong === 'Q') {
                    if (!this.productInfo.propertyInfo?.longitude) {
                        showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                        this.router.navigate(["/quotation/property-details"]);
                    }
                }

                if (requiredHouseNo === 'Q') {
                    if (!this.productInfo.propertyInfo?.houseNo) {
                        showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                        this.router.navigate(["/quotation/property-details"]);
                    }
                }

                if (requiredStreet === 'Q') {
                    if (!this.productInfo.propertyInfo?.street) {
                        showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                        this.router.navigate(["/quotation/property-details"]);
                    }
                }

                if (requiredBrgy === 'Q') {
                    if (!this.productInfo.propertyInfo?.brgy) {
                        showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                        this.router.navigate(["/quotation/property-details"]);
                    }
                }
                
                // if (!this.productInfo.propertyInfo?.compAdd) {
                // if (!this.productInfo.propertyInfo?.houseNo || !this.productInfo.propertyInfo?.street|| !this.productInfo.propertyInfo?.brgy) {
                //     showAppMessage ? this.appMessageService.showAppMessage("Please fill the property details form.", "info") : "";
                //     this.router.navigate(["/quotation/property-details"]);
                // }

                this.validateQuotationPagesAccessibility("property-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            }
        } else if (page === "review-premium-charges") {
            // Kung wala pa saved coverageInfo
            if (!this.productInfo.coverageInfo || Object.keys(this.productInfo.coverageInfo).length === 0) {
                showAppMessage ? this.appMessageService.showAppMessage("You don't have any computed coverage.", "info") : "";
                this.router.navigate(["/quotation/coverage-perils"]);
            }

            this.validateQuotationPagesAccessibility("coverage-perils");
            this.validateQuotationPagesAccessibility("vehicle-details");
            this.validateQuotationPagesAccessibility("personal-details");
            this.validateQuotationPagesAccessibility("property-details");
            this.validateQuotationPagesAccessibility("choose-plan");
        } else if (page === "account" || page.includes("account")) {
            // Kung may logged in user na
            if (this.productInfo.lineCd === 'MC') {
                if (this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("You are already logged in.", "info") : "";
                    this.router.navigate(["/quotation/review-premium-charges"]);
                }
    
                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("vehicle-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if(this.productInfo.lineCd === 'PA') {
                if (this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("You are already logged in.", "info") : "";
                    this.router.navigate(["/quotation/coverage-perils"]);
                }
    
                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("personal-details");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if(this.productInfo.lineCd === 'HA') {
                if (this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("You are already logged in.", "info") : "";
                    this.router.navigate(["/quotation/review-premium-charges"]);
                }

                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("property-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            }
        } else if (page === "email-print") {
            // Kung wala pa naka-login
            if (this.productInfo.lineCd === 'MC') {
                if (!this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("Please register or login first.", "info") : "";
                    this.router.navigate(["/quotation/account"]);
                }
    
                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("vehicle-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if (this.productInfo.lineCd === 'PA') {
                if (!this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("Please register or login first.", "info") : "";
                    this.router.navigate(["/quotation/account"]);
                }
    
                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("personal-details");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("choose-plan");
            } else if (this.productInfo.lineCd === 'HA') {
                if (!this.userDetailsService.userId) {
                    showAppMessage ? this.appMessageService.showAppMessage("Please register or login first.", "info") : "";
                    this.router.navigate(["/quotation/account"]);
                }
    
                this.validateQuotationPagesAccessibility("review-premium-charges");
                this.validateQuotationPagesAccessibility("coverage-perils");
                this.validateQuotationPagesAccessibility("property-details");
                this.validateQuotationPagesAccessibility("choose-plan");
            }
        }
    }

    validatePolicyPagesAccessibility(page:string):void {
        if (page === "customer-information"&&this.productInfo.creationProcess!=='renewal') {
            if (this.productInfo.lineCd === "MC") {
                // Kung wala pa saved na policy required sa vehicleInfo
                // Ginamit ko lang plateNo, engineNo, tsaka chassisNo since required lagi sa policy
                // Sure may laman kapag nag save
                if (   !this.productInfo.vehicleInfo?.plateNo
                    && !this.productInfo.vehicleInfo?.engineNo
                    && !this.productInfo.vehicleInfo?.chassisNo
                ) {
                    this.appMessageService.showAppMessage("Please fill the policy required fields in vehicle details form.", "info");
                    this.router.navigate(["/policy/vehicle-details"]);
                }
            } else if ((this.productInfo.lineCd === "PA" || this.productInfo.lineCd === "CI")) {
                if (    !this.productInfo.personalInfo?.principalLastName
                    &&  !this.productInfo.personalInfo?.principalFirstName
                    )
                {
                    this.appMessageService.showAppMessage("Please fill the policy required fields in personal details form.", "info");
                    this.router.navigate(["/policy/personal-details"]);
                }

                /*if (this.productInfo.sublineCd === 'FPA') {
                    if (this.productInfo.dependents) {
                        this.appMessageService.showAppMessage("Please fill the policy required fields in personal details form.", "info");
                        this.router.navigate(["/policy/personal-details"]);
                    }
                }*/
            } else if (this.productInfo.lineCd === "HA") {
                let accType = this.parameterService.paramV("HA_SURROUNDING").split(",") || [];
                let user = this.userDetailsService.accountType || "P";

                let requiredLat = this.parameterService.paramV("REQ_HA_LAT");
                let requiredLong = this.parameterService.paramV("REQ_HA_LONG");

                if (requiredLat === 'P') {
                    if (!this.productInfo.propertyInfo?.latitude) {
                        this.appMessageService.showAppMessage("Please fill the policy required fields in property details form.", "info");
                        this.router.navigate(["/policy/property-details"]);
                    }
                }

                if (requiredLong === 'P') {
                    if (!this.productInfo.propertyInfo?.longitude) {
                        this.appMessageService.showAppMessage("Please fill the policy required fields in property details form.", "info");
                        this.router.navigate(["/policy/property-details"]);
                    }
                }

                // if (accType.includes(user.toUpperCase())) {
                //     if (!this.productInfo.propertyInfo?.nearLand
                //         || !this.productInfo.propertyInfo?.surroundF
                //         || !this.productInfo.propertyInfo?.surroundR
                //         || !this.productInfo.propertyInfo?.surroundL
                //         || !this.productInfo.propertyInfo?.surroundB
                //         // || !this.productInfo.propertyInfo?.occupancyF
                //         // || !this.productInfo.propertyInfo?.occupancyR
                //         // || !this.productInfo.propertyInfo?.occupancyL
                //         // || !this.productInfo.propertyInfo?.occupancyB
                //         // || !this.productInfo.propertyInfo?.storeyF
                //         // || !this.productInfo.propertyInfo?.storeyR
                //         // || !this.productInfo.propertyInfo?.storeyL
                //         // || !this.productInfo.propertyInfo?.storeyB
                //         // || !this.productInfo.propertyInfo?.roofF
                //         // || !this.productInfo.propertyInfo?.roofR
                //         // || !this.productInfo.propertyInfo?.roofL
                //         // || !this.productInfo.propertyInfo?.roofB
                //         // || !this.productInfo.propertyInfo?.exWallF
                //         // || !this.productInfo.propertyInfo?.exWallR
                //         // || !this.productInfo.propertyInfo?.exWallL
                //         // || !this.productInfo.propertyInfo?.exWallB
                //         ) {
                //         this.appMessageService.showAppMessage("Please fill the policy required fields in property details form.", "info");
                //         this.router.navigate(["/policy/property-details"]);
                //     }
                // } else {
                //     if (!this.productInfo.propertyInfo?.nearLand) {
                //         this.appMessageService.showAppMessage("Please fill the policy required fields in property details form.", "info");
                //         this.router.navigate(["/policy/property-details"]);
                //     }
                // }
            }
        } else if (page === "acceptance") {
            // Kung wala pa saved na customerInfo
            // Ginamit ko lang firstName since required lagi sa customer information
            // Sure may laman kapag nag save
            if (!this.productInfo.customerInfo?.permanentAddress) {
                this.appMessageService.showAppMessage("Please fill the customer information form.", "info");
                this.router.navigate(["/policy/customer-information"]);
            }

            this.validatePolicyPagesAccessibility("customer-information");
        } else if (page === "payment" && this.productInfo.creationProcess !== "renewal") {
            // Kapag ang status ay 4 (Completed)
            if (this.productInfo.quoteStatCd === 4) {
                this.appMessageService.showAppMessage("Policy already paid.", "info");
                this.router.navigate(["/policy/email-print"]);
            }

            this.validatePolicyPagesAccessibility("acceptance");
            this.validatePolicyPagesAccessibility("customer-information");
        }
    }

    satisfyLoginRegisterRequirement():boolean {
        let satisfied = true;

        this.activatedRoute.queryParamMap.subscribe((p:any) => {
            if (p.params?.params) {
                let params:any = this.jsonDataService.parseBase64(p.params.params);
                params = JSON.parse(this.jsonDataService.decrypt(params));
                if (["RESET_PASS", "VERIFIED", "EXPIRED"].indexOf(params.status) >= 0) {
                    satisfied = true;
                    // if (this.userDetailsService.userId) {
                    //     satisfied = true;
                    // } else {
                    //     satisfied = false;
                    // }
                } else {
                    satisfied = false;
                }
            } else if (this.userDetailsService.userId) {
                satisfied = false;
            }
        });

        return satisfied;
    }

    satisfyDashboardRequirement():boolean {
        let satisfied = true;

        // Kung wala pa naka-login
        if (!this.userDetailsService.userId) {
            satisfied = false;
        }

        return satisfied;
    }

    satisfyProfileRequirement():boolean {
        let satisfied = true;

        // Kung wala pa naka-login
        if (!this.userDetailsService.userId) {
            satisfied = false;
        }

        return satisfied;
    }

    satisfyQuotationRequirement():boolean {
        let satisfied = true;

        // Kung hindi quotation yung creationProcess
        if (this.productInfo.creationProcess !== "quotation" && this.productInfo.creationProcess !== "expired") {
            satisfied = false;
        }

        // Kung wala value line code and subline code
        if (!(this.productInfo.lineCd && this.productInfo.sublineCd)) {
            satisfied = false;
        }

        // Kung may policy number na at di email-print o review-premium-charges yung URL sa quotation
        // Pwede mag print ng quotation kahit policy na
        // Pwede ireview yung quotation kahit policy na basta cancelled
        // Triald and error sa condition na pwede pumunta sa review premium pag cancelled ang policy
        if (this.productInfo.policyNo && this.currentUrl[2] !== "email-print" && this.currentUrl[2] !== "review-premium-charges" && this.productInfo.quoteStatCd !== 7) {
            satisfied = false;
        }

        return satisfied;
    }

    satisfyPolicyRequirement():boolean {
        let satisfied = true;

        // Kung hindi policy yung creationProcess
        if (this.productInfo.creationProcess !== "policy" && this.productInfo.creationProcess !== "renewal" && this.productInfo.creationProcess !== "expired") {
            satisfied = false;
        }

        // Kung wala value line code and subline code
        if (!(this.productInfo.lineCd && this.productInfo.sublineCd)) {
            satisfied = false;
        }

        // Kung wala pa naka-login
        if (!this.userDetailsService.userId && this.productInfo.creationProcess !== "renewal") {
            satisfied = false;
        }

        // Validate all quotation requirements
        // Renewal has no quotation
        if(this.productInfo.creationProcess !== "renewal"){
            this.validateQuotationPagesAccessibility("email-print", false);
            this.validateQuotationPagesAccessibility("review-premium-charges", false);
            this.validateQuotationPagesAccessibility("coverage-perils", false);
            this.validateQuotationPagesAccessibility("vehicle-details", false);
            this.validateQuotationPagesAccessibility("choose-plan", false);
        }

        return satisfied;
    }
}