<section id="personal-details">
    <div class="container">
        <form [formGroup]="form">
            <div id="personal-details-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div class="form" *ngIf="policyHolderSearch">
                        <div class="title">
                            <h1>
                                PRINCIPAL
                            </h1>
                        </div>
                        <div class="col-case col-2">
                            <div class="field">
                                <label>
                                    For existing client, you may select from the list of 
                                    values by clicking the magnifying glass icon.
                                </label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="existingClient" readonly>
                                    <div matSuffix class="lov-container">
                                        <mat-icon class="lov" (click)="lov.open()">search</mat-icon>
                                        <mat-icon class="lov" (click)="clear()">clear</mat-icon>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="field required">
                                <label class="policy-address">
                                    Complete Address of the Policy Holder
                                </label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="policyHolderAdress" uppercase trim>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="title" *ngIf="!policyHolderSearch">
                            <h1>
                                PRINCIPAL
                            </h1>
                        </div>
                        <div class="principal-details-container">
                            <div class="field required last-name">
                                <label>Last Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="principalLastName" uppercase trim>
                                </mat-form-field>
                            </div>
                            <div class="field required first-name">
                                <label>First Name</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="principalFirstName" uppercase trim>
                                </mat-form-field>
                            </div>
                            <div class="middle-suffix">
                                <div class="field middle-name">
                                    <label>Middle Name</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="principalMiddleName" uppercase trim>
                                    </mat-form-field>
                                </div>
                                <div class="field suffix">
                                    <label>Suffix</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="principalSuffix" uppercase trim>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="middle-suffix">
                                <div class="field middle-name required">
                                    <label>Birth Date</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput [matDatepicker]="picker" formControlName="principalBirthDate" [max]="today" (dateChange)="validatePrincipalBirthDate()">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="field suffix">
                                    <label>Age</label>
                                    <mat-form-field appearance="fill">
                                        <input matInput formControlName="age" readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="field required">
                                <label>Occupation</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput formControlName="principalOccupation" [matAutocomplete]="principalOccupation" (input)="setOccupationViaInput($event)" autocomplete>
                                    <mat-autocomplete #principalOccupation="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredPrincipalOccupationOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="field required">
                                <label>Marital Status</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput formControlName="principalMaritalStatus" [matAutocomplete]="principalMaritalStatus" (input)="setMaritalStatViaInput($event)" autocomplete>
                                    <mat-autocomplete #principalMaritalStatus="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredPrincipalMaritalStatusOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <ng-container *ngIf="productInfo.creationProcess === 'quotation' else polBlock">
                        </ng-container>
                        <ng-template #polBlock>
                            <div class="form-note">
                                <p>Do you want to view your quotation details? <a (click)="backToQuotationProcessing()">Click here to go back to quotation.</a><a (click)="reprintQuote()">Reprint quote.</a></p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="form" [hidden]="individualFlag && !displaySupportDocs && userDetailsService.accountType === 'P'">
                        <div [hidden]="individualFlag" class="dependents-hidden">
                            <div class="dependent-details-container">
                                <div class="title">
                                    <h1>
                                        DEPENDENTS
                                    </h1>
                                </div>
                                <div class="table-action">
                                    <p (click)="toggleEdit()" *ngIf="!editFlag">Add dependent ></p>
                                    <p (click)="toggleEdit()" *ngIf="editFlag">Done Editing ></p>
                                </div>
                            </div>
                            <div class="box-wrap bg1 table-box" [hidden]="editFlag">
                                <form [formGroup]="tableForm">
                                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> NAME </th>
                                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="relationship">
                                            <th mat-header-cell *matHeaderCellDef> RELATIONSHIP </th>
                                            <td mat-cell *matCellDef="let element"> {{element.relationship}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="occupation">
                                            <th mat-header-cell *matHeaderCellDef> OCCUPATION </th>
                                            <td mat-cell *matCellDef="let element"> {{element.occupation}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="maritalStatus">
                                            <th mat-header-cell *matHeaderCellDef> MARITAL STATUS </th>
                                            <td mat-cell *matCellDef="let element"> {{element.maritalStatus}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="birthDate">
                                            <th mat-header-cell *matHeaderCellDef> BIRTH DATE </th>
                                            <td mat-cell *matCellDef="let element"> {{element.birthDate}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="age">
                                            <th mat-header-cell *matHeaderCellDef> AGE </th>
                                            <td mat-cell *matCellDef="let element"> {{element.age}} </td>
                                        </ng-container>
        
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                </form>
                            </div>
                            <div class="box-wrap bg1 table-box" [hidden]="!editFlag">
                                <form [formGroup]="tableForm">
                                    <table mat-table [dataSource]="dataSourceEdit" matSort class="mat-elevation-z8" formArrayName="dependents">

                                        <ng-container matColumnDef="deleteRow">
                                            <th mat-header-cell *matHeaderCellDef class="field required"></th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <div class="delete-dependents">
                                                    <mat-icon [ngClass]="deleteDependentsFlag[index] ? 'delete-dependents-icon' : 'delete-dependents-icon-disabled'" (click)="deleteDependents(index)">delete_outline</mat-icon>
                                                </div>
                                            </td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="lastName">
                                            <th mat-header-cell *matHeaderCellDef class="field required"> LAST NAME </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <div class="field">
                                                    <mat-form-field appearance="fill" [formGroupName]="index">
                                                        <input matInput formControlName="lastName" placeholder="LAST NAME" uppercase trim>
                                                    </mat-form-field>
                                                </div>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="firstName">
                                            <th mat-header-cell *matHeaderCellDef> FIRST NAME </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="firstName" placeholder="FIRST NAME" uppercase trim>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="middleName">
                                            <th mat-header-cell *matHeaderCellDef> MIDDLE NAME </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="middleName" placeholder="MIDDLE NAME" uppercase trim>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="suffix">
                                            <th mat-header-cell *matHeaderCellDef> SUFFIX </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="suffix" placeholder="SUFFIX" uppercase trim>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="relationshipEdit">
                                            <th mat-header-cell *matHeaderCellDef> RELATIONSHIP </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="relationship" placeholder="RELATIONSHIP" [matAutocomplete]="relationship" (blur)="getOccupationDependents($event, index)" autocomplete>
                                                    <mat-autocomplete #relationship="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                        <mat-option *ngFor="let option of filteredDependentsRelationshipOptions[index] | async" [value]="option">
                                                            {{option.text}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="occupationEdit">
                                            <th mat-header-cell *matHeaderCellDef> OCCUPATION </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="occupation" placeholder="OCCUPATION" [matAutocomplete]="occupation" (blur)="dependentOccupationBlur(index, $event)" autocomplete>
                                                    <mat-autocomplete #occupation="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                        <mat-option *ngFor="let option of filteredDependentsOccupationOptions[index] | async" [value]="option">
                                                            {{option.text}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="maritalStatusEdit">
                                            <th mat-header-cell *matHeaderCellDef> MARITAL STATUS </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="maritalStatus" placeholder="MARITAL STATUS" [matAutocomplete]="maritalStatus" autocomplete>
                                                    <mat-autocomplete #maritalStatus="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                                        <mat-option *ngFor="let option of filteredDependentsMaritalStatusOptions[index] | async" [value]="option">
                                                            {{option.text}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="birthDateEdit">
                                            <th mat-header-cell *matHeaderCellDef> BIRTH DATE </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="birthDate" placeholder="BIRTH DATE" [max]="today" [matDatepicker]="picker" (dateChange)="validateDependentsBirthDate($event, index)">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="ageEdit">
                                            <th mat-header-cell *matHeaderCellDef> AGE </th>
                                            <td mat-cell *matCellDef="let element; let index = index">
                                                <mat-form-field appearance="fill" [formGroupName]="index">
                                                    <input matInput formControlName="age" placeholder="AGE" readonly>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
        
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsEdit"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsEdit;"></tr>
                                    </table>
                                </form>
                            </div>
                            <div class="form-note-instructions montserrat">
                                <pre [innerHTML]="instructionText" class="instruction-text"></pre>
                            </div>
                        </div>
                        <div class="col-case col-3" *ngIf="displaySupportDocs && userDetailsService.userId">
                            <div class="field supporting-docs">
                                <label>Supporting Documents</label>
                                <button class="btn btn6 proxima" (click)="fileInput.click()">Upload File (Total file size up to 10MB)</button>
                                <input type="file" #fileInput (change)="onFileChange($event)" multiple hidden>
                                <p class="error-msg" *ngIf="supportDocErrorMsg">{{supportDocErrorMsg}}</p>
                                <div class="files" *ngFor="let a of form.controls['supportingDocs'].value; index as i">
                                    <p>{{a.filename}}</p>
                                    <div class="buttons">
                                        <p class="view" (click)="onClickView(i)">View</p>
                                        <p class="remove" (click)="onClickDelete(i)">Remove</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="userDetailsService.userId && userDetailsService.accountType !== 'P'">
                            <div class="field">
                                <label>Remarks (optional)</label>
                                <mat-form-field appearance="fill">
                                    <textarea matInput placeholder="Remarks" rows="3" formControlName="remarks" maxlength="1000"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <mat-checkbox [(ngModel)]="occupationCaveat" [ngModelOptions]="{standalone: true}">
                        <!-- prevent checkbox trigger when clicking on link -->
                        {{acceptedOcc}} <a class="link" (mousedown)="$event.stopPropagation()" (click)="getPdf($event)">{{acceptedOccLine}}</a>
                    </mat-checkbox>
                    <div class="form-btns">
                        <button class="btn btn4" (click)="move('back')">Back</button>
                        <button class="btn btn5" (click)="save()" *ngIf="userDetailsService.userId">Save</button>
                        <button class="btn btn2" (click)="move('next')">{{nextLabel}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<lov #lov (selected)="onLovOk($event)" [type]="'clients'" [params]="lovParams"></lov>