import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pmToNoon'
})
export class PmToNoonPipe implements PipeTransform {

  transform(value: string | null): string | null {
    if(value?.split(":")[0].split(" ")[1] == '12' && value?.split(":")[1].split(" ")[0] == '00') {
      return value && value.replace('PM', 'NOON');
    }
    return value;
  }

}
