import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[latLong]'
})
export class LatLongDirective implements AfterViewInit  {

  constructor(private el: ElementRef) { 
  }

  ngAfterViewInit():void {
    this.el.nativeElement.setAttribute('type','tel');
  }


  @HostListener("textInput", ["$event"])
  onTextInput(e:any){
      if ((!parseInt(e.data) && parseInt(e.data) !== 0) && e.data !='.') {
          e.preventDefault();
      }
  }
}
