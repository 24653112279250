<section id="sub-login">
    <form [formGroup]="form" ng-submit="processLogin()">
        <div id="sub-login-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <h2>Log in to your account</h2>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Account Type</label>
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="accountType" (selectionChange)="checkAccountType()">
                                    <mat-option *ngFor="let accountType of varAccountTypes" [value]="accountType.value">{{accountType.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-case" *ngIf="showEmailField">
                        <div class="field required">
                            <label>Email Address</label>
                            <mat-form-field appearance="fill">
                                <input autocomplete="off" matInput formControlName="email">
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'email')">
                                {{formService.getFieldErrorMsg(form, "email")}}
                            </p>
                        </div>
                    </div>
                    <div class="col-case" *ngIf="showUserIdField">
                        <div class="field required">
                            <label>User ID</label>
                            <mat-form-field appearance="fill">
                                <input autocomplete="off" matInput formControlName="userId" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'userId')">
                                {{formService.getFieldErrorMsg(form, "userId")}}
                            </p>
                        </div>
                    </div>
                    <div class="col-case">
                        <div class="field required">
                            <label>Password</label>
                            <mat-form-field appearance="fill">
                                <input autocomplete="off" matInput formControlName="password" [type]="showPasswordValue ? 'text' : 'password'">
                                <mat-icon matSuffix (click)="showPasswordValue = !showPasswordValue">{{showPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </mat-form-field>
                            <p class="field-error" *ngIf="formService.isInvalid(form, 'password')">
                                {{formService.getFieldErrorMsg(form, "password")}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-note forgot-password">
                    <p>Forgot Password? <a (click)="goToForm('forgot')">Click here</a></p>
                </div>
            </div>
            <div class="loginFormMsg bottom"></div>
        </div>
        <div class="form-btns hwl">
            <button class="btn btn2" (click)="processLogin()">Login</button>
        </div>
    </form>
    <div class="registration-btn hwl" *ngIf="router.url === '/login' || router.url.includes('login')">
        <p class="note">Don't have an account yet? Create your account here.</p>
        <a routerLink="/register" class="btn">Register</a>
    </div>
</section>