import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { JsonDataService } from 'src/app/services/json-data.service';
import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { OverlayComponent } from 'src/app/components/common/overlay/overlay';
import { APICallService } from 'src/app/services/api-call.service';
import { SecurityService } from 'src/app/services/security.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

interface StringIndexedObject {
    [key: string]: any;
  }

@Component({
    templateUrl: './choose-plan.html',
    encapsulation: ViewEncapsulation.None
})
export class ChoosePlanComponent {

    @ViewChild("referralCode") referralCode!: OverlayComponent;

    productInfo:any = {};
    prodVouchInfo: any = {};
    plans:any = [];
    planPerils:any = [];
    perils:any = [];
    date:Date = new Date;
    conditionText:any = "";
    showBenefits:Boolean = false;
    benefits:any = [];
    referralCd:String = "";
    profileForm: FormGroup = this.fb.group ({
        refCode: [""]
    });
    prodVouchForm: FormGroup = this.fb.group ({
        prodVouchCode: [""]
    });
    planOfferForm: FormGroup = this.fb.group ({
        isMoreCoverage: [""],
        isCB1: [""],
        isCB2: [""],
        isCB3: [""]
    });
    promoError: any = '';
    userType: String = '';
    prodVouchToolTip: string = "";
    prodPlaceHolder: string = 'Enter Referral or Product Code';
    prodCdTag: string = '';
    vouchCdTag: string = '';
    wordingsTitle:string = "";
    wordingsOffer:string = "";
    answerYes:string = "";
    answerNo:string = "";
    condition1:string = "";
    condition2:string = "";
    condition3:string = "";
    planFL: number = 0;
    nextDisabled: boolean = false;
    // isCB1Check: boolean = false;
    // isCB2Check: boolean = false;
    // isCB3Check: boolean = false;
    isPlanOnlyFL: boolean = false;
    showField: boolean = false;
    errorMessage: string = "";
    selDisabled: boolean = false;
    showCaveat: boolean = false;
    mortCaveat:string = "";
    mortLink:string = "";
    isHazardHunterSwitch:boolean = this.parameterService.paramV('HAZARD_HUNTER_SWITCH')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P')!=-1;

    contents: StringIndexedObject = {
        'COM': 'Motor',
        'CTPL': 'Liability',
        'FPA': 'Family',
        'IND': 'Personal'
    };
    
    constructor (
        private router: Router,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private productService: ProductService,
        private jsonService: JsonDataService,
        private appMessageService: AppMessageService,
        private dialogBoxService: DialogBoxService,
        private fb: FormBuilder,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        public parameterService: ParameterService,
        public userDetailsService: UserDetailsService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.userType = userDetailsService.accountType || 'P';
        this.checkCode();
        this.initializeStepperDetail();
        this.prodVouchToolTip = this.jsonDataService.data.params.filter((a: any) => { return a.paramName === 'PRODUCT_CODE_TEXT' })[0]?.paramValueV || '';
        
        const productLineCd = this.productInfo.lineCd;
        const productSublineCd = this.productInfo.sublineCd;
        this.showCaveat = this.productInfo.propertyAssessment?.isMORT === 'Y' ? true : false;
        let isPlanIncluded: boolean = true;
        let isHAPlan: boolean = false;

        this.prodCdTag = this.jsonDataService.data.userType.filter((a: any) => { return a.userType === this.userType })[0].prodCdTag;
        this.vouchCdTag = this.jsonDataService.data.userType.filter((a: any) => { return a.userType === this.userType })[0].vouchCdTag;
        this.planFL = this.parameterService.paramN("PLAN_CD_FL_ONLY");
        this.mortCaveat = this.parameterService.paramV("MORT_ACCT_TEXT") || "";
        this.mortLink = this.parameterService.paramV("MORT_ACCT_LINK") || "";

        if (this.prodCdTag === 'Y' || this.vouchCdTag === 'Y') {
            this.showField = true;
        }

        if(this.productInfo.coverageInfo) {
            this.profileForm.patchValue({refCode: this.productInfo.coverageInfo.promoCode});
        }
        
        let pvCd = this.productInfo.prodVouchInfo?.prodVouchCd || '';
        if(pvCd !== '') {
            this.prodVouchForm.patchValue({prodVouchCode: this.productInfo.prodVouchInfo.prodVouchCd});
            if (!this.productInfo.coverageInfo) {
                this.validateCd();
            } else {
                if (!this.productInfo.coverageInfo.conType) {
                    this.validateCd(); 
                }
            }
        }
        
        for (const plan of this.jsonService.data.plans.filter((a:any) =>
                a.lineCd === productLineCd
             && a.sublineCd === productSublineCd
             //&& a.planCd === perUser.planCd
             && formatDate(this.date, "yyyy-MM-dd", "en") >= a.effDateFrom
             && (formatDate(this.date, "yyyy-MM-dd", "en") <= a.effDateTo || a.effDateTo === null))) 
        {
            for (const perUser of this.jsonService.data.viewPlanPerUser.filter((b:any) =>
                    b.lineCd === productLineCd
                 && b.sublineCd === productSublineCd
                 && b.userType === this.userType
                 && b.planCd === plan.planCd)) 
            {
                if (productLineCd === "HA" && this.productInfo.planEvaluated === 2) {
                    isPlanIncluded = true;
                    isHAPlan = false;
                    for (const p of this.jsonDataService.data.hcaPlan.filter((p: any) => 
                        p.lineCd == productLineCd && p.sublineCd == productSublineCd && p.planCd == plan.planCd)) {
                        isHAPlan = true;
                        if (p.planCd === plan.planCd && p.assessmentCd === "HH1" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isHH1) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "HH4" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isHH4) {
                            isPlanIncluded = false;
                        }
                        if (this.productInfo.propertyAssessment.isHIST === "Y") {
                            //accepted answer with value, will undergo addtl condition
                            if (p.planCd === plan.planCd && p.assessmentCd === "HIST" && p.acceptedAnswer && p.acceptedAnswer > this.productInfo.propertyAssessment.numHIST) {
                                isPlanIncluded = false;
                            }
                        }
                    }
                    if (isPlanIncluded && isHAPlan) {
                        this.plans.push(plan);
                    }
                } else if (productLineCd === "HA" && (this.productInfo.planEvaluated === 1 || this.productInfo.quoteId)) {
                    isPlanIncluded = true;
                    isHAPlan = false;
                    for (const p of this.jsonDataService.data.hcaPlan.filter((p: any) => 
                        p.lineCd == productLineCd && p.sublineCd == productSublineCd && p.planCd == plan.planCd)) {
                        isHAPlan = true;
                        if (p.planCd === plan.planCd && p.assessmentCd === "MORT" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isMORT) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "ASUBD" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isASUBD) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "NSUBD" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isNSUBD) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "FLR" && p.acceptedAnswer < this.productInfo.propertyAssessment.numFLR) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "ROOF" && !p.acceptedAnswer.includes(this.productInfo.propertyAssessment.numROOF)) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "WALL" && !p.acceptedAnswer.includes(this.productInfo.propertyAssessment.numWALL)) {
                            isPlanIncluded = false;
                        }
                    }
                    if ((isPlanIncluded && isHAPlan) || plan.planCd === this.productInfo?.planInfo?.planCd) {
                        this.plans.push(plan);
                    }
                } else {
                    this.plans.push(plan);
                }
            }
        }
        this.isPlanOnlyFL = false;
        if (productLineCd === "HA" && this.plans.length === 0) {
            this.productInfo.planEvaluated = 9;
            this.productService.setProductInfo2(this.productInfo);
            this.stepperDtlService.showStepperDtl = false;
            this.router.navigate(["/quotation/property-assessment"]);
        } else if (productLineCd === "HA" && this.plans.length === 1) {
            //this.onSelectPlanCd(null, '');
            //this.productInfo.planInfo = { planCd: null };
            //this.productService.setProductInfo2(this.productInfo);
            for (const p of this.plans.filter((p: any) => 
                p.lineCd === productLineCd && p.sublineCd === productSublineCd && p.planCd === this.planFL)) {
                if (this.productInfo.planEvaluated === 2 || this.productInfo.quoteId
                    || (this.productInfo.propertyAssessment.isHH1 && this.productInfo.propertyAssessment.isHH1 == 'N')
                    || (this.productInfo.propertyAssessment.isHH4 && this.productInfo.propertyAssessment.isHH4 == 'N')) {
                    this.isPlanOnlyFL = false;  
                    this.nextDisabled = false;    
                } else {
                    this.isPlanOnlyFL = true;  
                    this.nextDisabled = false;     
                    for (const p of this.jsonDataService.data.propAssessmentWordings.filter((p: any) => 
                        p.language == this.productInfo.language)) {
                        if (p.assessmentCd === "COV") {
                            this.wordingsTitle = p.title;
                            this.wordingsOffer = p.wordings;
                            this.answerYes = p.answerYes;
                            this.answerNo = p.answerNo;
                        }
                        // if (p.assessmentCd === "CB1") {
                        //     this.condition1 = p.wordings;
                        // }
                        // if (p.assessmentCd === "CB2") {
                        //     this.condition2 = p.wordings;
                        // }
                        // if (p.assessmentCd === "CB3") {
                        //     this.condition3 = p.wordings;
                        // }
                    }
                    this.planOfferForm.patchValue({
                        isMoreCoverage: "Y"
                    });
                }
            }
        }
        
        for (const condition of this.jsonService.data.sublines.filter((a:any) =>
            a.lineCd === productLineCd && a.sublineCd === productSublineCd)
        ) {
            this.conditionText = condition.conditionText;
        }

        this.planPerils = this.jsonService.data.planPerils;
        this.perils = this.jsonService.data.peril;
    }

    showMortLink(){
        window.open(this.mortLink);
    }

    loadPlans() {
        const productLineCd = this.productInfo.lineCd;
        const productSublineCd = this.productInfo.sublineCd;
        let isPlanIncluded: boolean = true;
        let isHAPlan: boolean = false;
        this.plans = [];

        const triggerPixel = !!this.contents[productSublineCd]
        if (triggerPixel) this.fbAnalyticsService.trackEvent(`${this.contents[productSublineCd]}_Choose_Plan`)

        if (this.productInfo.sublineCd === 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Choose_Plan1')
        }


        for (const plan of this.jsonService.data.plans.filter((a:any) =>
                a.lineCd === productLineCd
             && a.sublineCd === productSublineCd
             //&& a.planCd === perUser.planCd
             && formatDate(this.date, "yyyy-MM-dd", "en") >= a.effDateFrom
             && (formatDate(this.date, "yyyy-MM-dd", "en") <= a.effDateTo || a.effDateTo === null))) 
        {
            for (const perUser of this.jsonService.data.viewPlanPerUser.filter((b:any) =>
                    b.lineCd === productLineCd
                 && b.sublineCd === productSublineCd
                 && b.userType === this.userType
                 && b.planCd === plan.planCd)) 
            {
                if (productLineCd === "HA" && this.productInfo.planEvaluated === 2) {
                    isPlanIncluded = true;
                    isHAPlan = false;
                    for (const p of this.jsonDataService.data.hcaPlan.filter((p: any) => 
                        p.lineCd == productLineCd && p.sublineCd == productSublineCd && p.planCd == plan.planCd)) {
                        isHAPlan = true;
                        if (p.planCd === plan.planCd && p.assessmentCd === "HH1" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isHH1) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "HH4" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isHH4) {
                            isPlanIncluded = false;
                        }
                        if (this.productInfo.propertyAssessment.isHIST === "Y") {
                            //accepted answer with value, will undergo addtl condition
                            if (p.planCd === plan.planCd && p.assessmentCd === "HIST" && p.acceptedAnswer && p.acceptedAnswer > this.productInfo.propertyAssessment.numHIST) {
                                isPlanIncluded = false;
                            }
                        }
                    }
                    if (isPlanIncluded && isHAPlan) {
                        this.plans.push(plan);
                    }
                } else if (productLineCd === "HA" && (this.productInfo.planEvaluated === 1 || this.productInfo.quoteId)) {
                    isPlanIncluded = true;
                    isHAPlan = false;
                    for (const p of this.jsonDataService.data.hcaPlan.filter((p: any) => 
                        p.lineCd == productLineCd && p.sublineCd == productSublineCd && p.planCd == plan.planCd)) {
                        isHAPlan = true;
                        if (p.planCd === plan.planCd && p.assessmentCd === "MORT" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isMORT) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "ASUBD" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isASUBD) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "NSUBD" && p.acceptedAnswer && p.acceptedAnswer !== this.productInfo.propertyAssessment.isNSUBD) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "FLR" && p.acceptedAnswer < this.productInfo.propertyAssessment.numFLR) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "ROOF" && !p.acceptedAnswer.includes(this.productInfo.propertyAssessment.numROOF)) {
                            isPlanIncluded = false;
                        }
                        if (p.planCd === plan.planCd && p.assessmentCd === "WALL" && !p.acceptedAnswer.includes(this.productInfo.propertyAssessment.numWALL)) {
                            isPlanIncluded = false;
                        }
                    }
                    if ((isPlanIncluded && isHAPlan) || plan.planCd === this.productInfo?.planInfo?.planCd) {
                        this.plans.push(plan);
                    }
                } else {
                    this.plans.push(plan);
                }
            }
        }
        this.isPlanOnlyFL = false;
        if (productLineCd === "HA" && this.plans.length === 0) {
            this.productInfo.planEvaluated = 9;
            this.productService.setProductInfo2(this.productInfo);
            this.stepperDtlService.showStepperDtl = false;
            this.router.navigate(["/quotation/property-assessment"]);
        } else if (productLineCd === "HA" && this.plans.length === 1) {
            //this.onSelectPlanCd(null, '');
            //this.productInfo.planInfo = { planCd: null };
            //this.productService.setProductInfo2(this.productInfo);
            for (const p of this.plans.filter((p: any) => 
                p.lineCd === productLineCd && p.sublineCd === productSublineCd && p.planCd === this.planFL)) {
                if (this.productInfo.planEvaluated === 2 || this.productInfo.quoteId
                    || (this.productInfo.propertyAssessment.isHH1 && this.productInfo.propertyAssessment.isHH1 == 'N')
                    || (this.productInfo.propertyAssessment.isHH4 && this.productInfo.propertyAssessment.isHH4 == 'N')) {
                    this.isPlanOnlyFL = false;  
                    this.nextDisabled = false;    
                } else {
                    this.isPlanOnlyFL = true;  
                    this.nextDisabled = false;     
                    for (const p of this.jsonDataService.data.propAssessmentWordings.filter((p: any) => 
                        p.language == this.productInfo.language)) {
                        if (p.assessmentCd === "COV") {
                            this.wordingsTitle = p.title;
                            this.wordingsOffer = p.wordings;
                            this.answerYes = p.answerYes;
                            this.answerNo = p.answerNo;
                        }
                        // if (p.assessmentCd === "CB1") {
                        //     this.condition1 = p.wordings;
                        // }
                        // if (p.assessmentCd === "CB2") {
                        //     this.condition2 = p.wordings;
                        // }
                        // if (p.assessmentCd === "CB3") {
                        //     this.condition3 = p.wordings;
                        // }
                    }
                    this.planOfferForm.patchValue({
                        isMoreCoverage: "Y"
                    });
                }
            }
        }
    }

    checkCode(): void {
        this.productInfo = this.productService.productInfo;
        if (this.productInfo.coverageInfo) {
            let promo = this.productInfo.coverageInfo.promoCode || '';
            if (promo !== "") {
                this.jsonDataService.contorlLoading(true);
                this.apiCallService.valProdVouchCd({
                    lineCd: this.productInfo.lineCd || "",
                    sublineCd: this.productInfo.sublineCd || "",
                    planCd: this.productInfo.planInfo.planCd || "",
                    prodVouchCd: this.productInfo.coverageInfo.promoCode || "",
                    userType: this.userType || "",
                    quoteId: this.productInfo.quoteId,
                    userId: this.userDetailsService.userId
                }).subscribe((response:any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (res.codeType === 'N') {
                                this.productInfo.prodVouchInfo = {
                                    prodVouchCd: '',               
                                    lineCd: '',                    
                                    sublineCd: '',                 
                                    planCd: '',                    
                                    clientCd: '',
                                    projectCd: '',
                                    agentCd: '',
                                    bmaCd: '',
                                    referrorCd: '',
                                    srcCd: '',
                                    srcExtCd: ''
                                };
                                this.productService.setProductInfo2(this.productInfo);
                            } else if (res.codeType === 'R') {
                                // ? do nothing
                            } else {
                                this.errorMessage = res.error;
                                for (const plan of this.jsonService.data.plans.filter((a:any) =>
                                        a.lineCd === res.data.LINE_CD
                                        && a.sublineCd === res.data.SUBLINE_CD
                                        && a.planCd === res.data.PLAN_CD
                                        && formatDate(this.date, "yyyy-MM-dd", "en") >= a.effDateFrom
                                        && (formatDate(this.date, "yyyy-MM-dd", "en") <= a.effDateTo || a.effDateTo === null))) 
                                {
                                    if (plan.productCdTag === "Y") {
                                        this.plans = [];
                                        this.plans.push(plan);
                                        this.showField = false;
                                        this.selDisabled = true;
                                        this.stepperDtlService.titleStepperDtl = "Insurance Plan Details";
                                    }
                                }
                            }
                        } else {
                            this.appMessageService.showAppMessage("Please choose an insurance plan.", "error");
                        }
                    });
                });
            }
        }
        this.buildPlanInfoInProductInfo();
    }

    buildPlanInfoInProductInfo():void {
        
        if (this.productInfo.prodVouchInfo === undefined || this.productInfo.prodVouchInfo.planCd === "") {
            const planInfo = this.productInfo.planInfo ? this.productInfo.planInfo : {
                planCd: null
            };

            this.productInfo.planInfo = planInfo;

            const prodVouchInfo = this.productInfo.prodVouchInfo ? this.productInfo.prodVouchInfo : {
                planCd: ""
            };

            this.productInfo.prodVouchInfo = prodVouchInfo;
            this.productService.setProductInfo2(this.productInfo);
        } else {
            const planInfo = this.productInfo.planInfo ? this.productInfo.planInfo : {
                planCd: null
            };

            //const planInfo =  { planCd: null };
            this.productInfo.planInfo = planInfo;

            const prodVouchInfo = this.productInfo.prodVouchInfo ? this.productInfo.prodVouchInfo : {
                planCd: ""
            };
            
            this.productInfo.prodVouchInfo = prodVouchInfo;
            this.productService.setProductInfo2(this.productInfo);
            this.prodVouchForm.patchValue({ prodVouchCode: this.productInfo.prodVouchInfo.prodVouchCd});
        }
    }

    initializeStepperDetail():void {
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = 1;
        this.stepperDtlService.titleStepperDtl = "Choose An Insurance Plan";
        this.stepperDtlService.stepperDtlBtns = [];

        if (this.productInfo.sublineCd === 'FPA') {
            this.fbAnalyticsService.trackEvent('Family_Choose_Plan')
        } else if (this.productInfo.sublineCd === 'IND') {
            // TODO FOR PERSONAL / INDIVIDUAL
        } else if (this.productInfo.lineCd === 'MC') {
            this.fbAnalyticsService.trackEvent('Motor_Choose_Plan')
        }
    }
    
    planInfoFromProdVouchInfo() {
        if (this.productInfo.prodVouchInfo.planCd != "") {
            this.productInfo.planInfo.planCd = this.productInfo.prodVouchInfo.planCd;
            this.productInfo.planInfo.planName = this.jsonDataService.data.plans.find((a: any) => a.lineCd === this.productInfo.prodVouchInfo.lineCd &&
                                                                                                a.sublineCd === this.productInfo.prodVouchInfo.sublineCd &&
                                                                                                a.planCd === this.productInfo.prodVouchInfo.planCd)?.planName;
            this.productInfo.planInfo.premiumText = this.jsonDataService.data.plans.find((a: any) => a.lineCd === this.productInfo.prodVouchInfo.lineCd &&
                                                                                                    a.sublineCd === this.productInfo.prodVouchInfo.sublineCd &&
                                                                                                    a.planCd === this.productInfo.prodVouchInfo.planCd)?.premiumText;
            this.productInfo.planInfo.referralTag = this.jsonDataService.data.plans.find((a: any) => a.lineCd === this.productInfo.prodVouchInfo.lineCd &&
                                                                                                    a.sublineCd === this.productInfo.prodVouchInfo.sublineCd &&
                                                                                                    a.planCd === this.productInfo.prodVouchInfo.planCd)?.referralTag;
            this.productInfo.planInfo.referralCode = "";
            this.productService.setProductInfo2(this.productInfo);
        }
    }

    move(action:string):void {
        if (action === "back") {
            if (this.showBenefits) {
                this.showBenefits = false;
                this.stepperDtlService.showStepperDtl = true;
                this.benefits = [];
            } else if (this.productInfo.lineCd === "HA") {
                if (this.productInfo?.quoteId) {
                    this.router.navigate(["/"]);
                } else {
                    this.productInfo.planInfo = {};
                    this.productService.setProductInfo2(this.productInfo); 
                    this.stepperDtlService.showStepperDtl = false;
                    this.router.navigate(["/quotation/property-assessment"]);
                }
            } else if (this.stepperDtlService.titleStepperDtl === "Insurance Plan Details") {
                if (this.productInfo.coverageInfo === undefined || Object.keys(this.productInfo.coverageInfo).length === 0) {
                    this.stepperDtlService.titleStepperDtl = "Choose An Insurance Plan";
                        this.showField = true;
                        this.selDisabled = false;
                        const planInfo = { planCd: null };
            
                        this.productInfo.planInfo = planInfo;
            
                        const prodVouchInfo = { planCd: "" };
                        
                        this.productInfo.prodVouchInfo = prodVouchInfo;
                        this.productService.setProductInfo2(this.productInfo);
                        //this.prodVouchForm.patchValue({ prodVouchCode: ""});
                        this.loadPlans();
                } else {
                    this.recomputeMessage(null, 'Product Code');
                }
            } else {
                this.router.navigate(["/"]);
            }
        } else {
            this.planInfoFromProdVouchInfo();
            if (this.productInfo.lineCd === 'HA' && this.isPlanOnlyFL) {
                this.productInfo.propertyAssessment.isCOV = this.planOfferForm.get("isMoreCoverage")?.value;
                // this.productInfo.propertyAssessment.isCB1 = this.planOfferForm.get("isCB1")?.value;
                // this.productInfo.propertyAssessment.isCB2 = this.planOfferForm.get("isCB2")?.value;
                // this.productInfo.propertyAssessment.isCB3 = this.planOfferForm.get("isCB3")?.value;
                this.productService.setProductInfo2(this.productInfo);
                if (this.planOfferForm.get("isMoreCoverage")?.value === 'N') {
                    for (const p of this.jsonService.data.plans.filter((p: any) => 
                    p.lineCd === this.productInfo.lineCd && p.sublineCd === this.productInfo.sublineCd && p.planCd === this.planFL)) {
                        this.onSelectPlanCd(p, ''); 
                        this.isPlanOnlyFL = false;
                        this.move('next');   
                    }
                } else if (this.planOfferForm.get("isMoreCoverage")?.value === 'Y') {
                    if (this.isHazardHunterSwitch) {
                        // ^ also handles if param is null or undefined
                        //this.productInfo.planEvaluated = 4; comment out until further notice
                        this.productInfo.planEvaluated = 2;
                        this.productService.setProductInfo2(this.productInfo);
                        this.stepperDtlService.showStepperDtl = false;
                        this.router.navigate(["/quotation/property-assessment"]);
                    } else {
                        this.stepperDtlService.showStepperDtl = false;
                        this.productInfo.planEvaluated = 11;
                        this.productService.setProductInfo2(this.productInfo);
                        this.router.navigate(["/quotation/property-assessment"]);
                    }
                }
            } else if(this.productInfo.planInfo.planCd) {
                // if(this.stepperDtlService.showStepperDtl) {
                //     if (this.userType === "" || this.userType === "P") {
                //         this.openReferralDialogBox();
                //     } else {
                //         this.openBenefitsPage();    
                //     }
                // } else {
                    this.openBenefitsPage();
                    if (this.productInfo.sublineCd === 'FPA') {
                        this.fbAnalyticsService.trackEvent('Family_ Quotation_ Benefits')
                    } else if (this.productInfo.sublineCd === 'IND') {
                        this.fbAnalyticsService.trackEvent('Personal_Quotation_ Benefits')
                    }
                // }
            } else {
                this.appMessageService.showAppMessage("Please choose an insurance plan.", "info");
            }
        }
    }

    onKeyPress(){
        if (this.prodVouchForm.get("prodVouchCode")?.value === "") {
            this.errorMessage = "";
            //this.onSelectPlanCd(null, '');
        }
    }

    validateCd() {
        let code = this.prodVouchForm.get("prodVouchCode")?.value || ""
        if (code !== "") {
            if (code !== this.productInfo.prodVouchInfo?.prodVouchCd) {
                //if (this.productInfo.coverageInfo === undefined || Object.keys(this.productInfo.coverageInfo).length === 0) {
                    this.jsonDataService.contorlLoading(true);
                    this.apiCallService.valProdVouchCd({
                        lineCd: this.productInfo.lineCd || "",
                        sublineCd: this.productInfo.sublineCd || "",
                        planCd: this.productInfo.planInfo.planCd || "",
                        prodVouchCd: this.prodVouchForm.get("prodVouchCode")?.value || "",
                        userType: this.userType || "",
                        quoteId: this.productInfo.quoteId,
                        userId: this.userDetailsService.userId
                    }).subscribe((response:any) => {
                        this.jsonDataService.contorlLoading(false);
                        this.securityService.checkRequestKeyResponse(response, () => {
                            if (response.status === "SUCCESS") {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                this.errorMessage = res.error;
                                if (res.error === "") {
                                    this.recomputeMessage(res, 'Validate Code');
                                } else {
                                    this.productInfo.prodVouchInfo = {
                                        prodVouchCd: "",               
                                        lineCd: "",                    
                                        sublineCd: "",                 
                                        planCd: "",                    
                                        clientCd: "",
                                        projectCd: "",
                                        agentCd: "",
                                        bmaCd: "",
                                        referrorCd: "",
                                        srcCd: "",
                                        srcExtCd: ""
                                    };

                                    this.productInfo.planInfo.planCd = "";
                                    this.productInfo.planInfo.planName = "";
                                    this.productInfo.planInfo.premiumText = "";
                                    this.productInfo.planInfo.referralTag = "";
                                    this.productInfo.planInfo.referralCode = "";
                                    this.productInfo.planInfo.currencyCd = "";

                                    this.productService.setProductInfo2(this.productInfo);
                                }
                            } else {
                                this.appMessageService.showAppMessage("Please choose an insurance plan.", "error");
                            }
                        });
                    });
                /*} else {
                    this.recomputeMessage(null, 'Validate Screen');
                }*/
            }
        } else if (code !== this.productInfo.prodVouchInfo?.prodVouchCd) {
            this.productInfo.prodVouchInfo = {
                prodVouchCd: "",               
                lineCd: "",                    
                sublineCd: "",                 
                planCd: "",                    
                clientCd: "",
                projectCd: "",
                agentCd: "",
                bmaCd: "",
                referrorCd: "",
                srcCd: "",
                srcExtCd: ""
            };

            this.productInfo.planInfo.planCd = "";
            this.productInfo.planInfo.planName = "";
            this.productInfo.planInfo.premiumText = "";
            this.productInfo.planInfo.referralTag = "";
            this.productInfo.planInfo.referralCode = "";
            this.productInfo.planInfo.currencyCd = "";

            this.productService.setProductInfo2(this.productInfo);
        }
    }

    openBenefitsPage(){
        for (const benefits of this.jsonService.data.planBenefitsAndCoverages.filter((a:any) =>
               a.lineCd === this.productInfo.lineCd 
            && a.sublineCd === this.productInfo.sublineCd
            && a.planCd === this.productInfo.planInfo.planCd)
        ) {
            this.benefits.push(benefits);
            this.showBenefits = true;
        }
        if(this.showBenefits){
            if(this.stepperDtlService.showStepperDtl) {
                this.stepperDtlService.showStepperDtl = false;
            } else {
                this.goToNextPage();
            }
        } else {
            this.goToNextPage();
        }
    }

    openReferralDialogBox(){
        if (this.productInfo.planInfo.referralTag === "Y") {
            if (this.productInfo.prodVouchInfo.prodVouchCd !== "") {
                this.openBenefitsPage();
            } else {
                this.referralCode.open("choosePlan");
            }
        } else {
            this.openBenefitsPage();
        }
    }

    saveReferralCd() {
        this.referralCd = this.profileForm.get("refCode")?.value;
        
        if (this.referralCd != "") {
            if (this.productInfo.coverageInfo) {
                if(this.referralCd != this.productInfo.coverageInfo.promoCode){
                    this.apiCallService.validateReferralCode({
                        quoteId: this.productInfo.quoteId || "",
                        referralCd: this.referralCd || "",
                        lineCd: this.productInfo.lineCd || "",
                        sublineCd: this.productInfo.sublineCd || "",
                        planCd: this.productInfo.planInfo.planCd || ""
                    }).subscribe((response:any) => {
                        this.jsonDataService.contorlLoading(false);
                        this.securityService.checkRequestKeyResponse(response, () => {
                            if (response.status === "SUCCESS") {
                                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                this.promoError = res.promoError;
                                if(this.promoError) {
                                    this.appMessageService.showAppMessage(this.promoError, "error");
                                    this.referralCd = "";
                                    this.profileForm.patchValue({refCode: ""});
                                    this.referralCode?.close();
                                    this.promoError = "";
                                    this.openBenefitsPage();
                                } else {
                                    //save sa product service
                                    this.referralCode?.close();
                                    this.openBenefitsPage();
                                }
                            } else {
                                this.appMessageService.showAppMessage("Error validating your Referral/Promo Code.", "error");
                            }
                        });
                    });
                } else {
                    this.referralCode?.close();
                    this.openBenefitsPage();
                }
            } else {
                this.apiCallService.validateReferralCode({
                    quoteId: this.productInfo.quoteId || "",
                    referralCd: this.referralCd || ""
                }).subscribe((response:any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.promoError = res.promoError;
                            if(this.promoError) {
                                this.appMessageService.showAppMessage(this.promoError, "error");
                                this.referralCd = "";
                                this.profileForm.patchValue({refCode: ""});
                                this.referralCode?.close();
                                this.promoError = "";
                                this.openBenefitsPage();
                            } else {
                                this.productInfo.planInfo.referralCode = this.referralCd;
                                this.referralCode?.close();
                                this.openBenefitsPage();
                            }
                        } else {
                            this.appMessageService.showAppMessage("Error validating your Referral/Promo Code.", "error");
                        }
                    });
                });
            }
        } else {
            this.productInfo.planInfo.referralCode = "";
            this.referralCode?.close();
            this.openBenefitsPage();
        }
    }

    goToNextPage():void {
        if (this.productInfo.lineCd === "MC") {
            this.router.navigate(["/quotation/vehicle-details"]);
        } else if (this.productInfo.lineCd === "PA" || this.productInfo.lineCd === "CI") {
            this.router.navigate(["/quotation/coverage-perils"]);
        } else if (this.productInfo.lineCd === "FI" || this.productInfo.lineCd === "HA") {
            this.router.navigate(["/quotation/property-details"]);
        }
    }

    onSelectPlanCd(plans:any, param: any):void {
        const coverage = this.productInfo.coverageInfo;
        let plan = plans || "";
        /*if (plan != "" && param != "prodVouch") {
            this.prodVouchForm.patchValue({prodVouchCode: ""});
            this.errorMessage = "";
            this.productInfo.prodVouchInfo = {
                prodVouchCd: "",               
                lineCd: "",                    
                sublineCd: "",                 
                planCd: "",                    
                clientCd: "",
                projectCd: "",
                agentCd: "",
                bmaCd: "",
                referrorCd: "",
                srcCd: "",
                srcExtCd: ""
            };
            this.productService.setProductInfo2(this.productInfo);
        }*/

        //let plan = plans === null ? "" : plans;
        if(this.productInfo.planInfo.planCd != plan.planCd && this.productInfo.sublineCd == 'CTPL' && this.productInfo?.vehicleInfo?.yearModelDetail){
            this.dialogBoxService.open({
                messageType: "C",
                content: "Changing your plan will reset your vehicle details. Do you want to proceed?",
                buttons: [
                    { text: "No" },
                    { text: "Yes", action: () => {
                        this.productInfo.planInfo.planCd = plan.planCd;
                        this.productInfo.planInfo.planName = plan.planName;
                        this.productInfo.vehicleInfo = {
                            mortgageType: "",
                            bankDetail: "",
                            loanAccountNo: "",
                            alfesNo: "",
                            vehicleType: "",
                            plateNo: "",
                            yearModelDetail: "",
                            makeDetail: "",
                            modelDetail: "",
                            subModelDetail: "",
                            makeTypeCd: "",
                            ccWt: "",
                            gasType: "",
                            gasDesc: "",
                            noOfSeats: "",
                            fmvMinValue: "",
                            fmvMaxValue: "",
                            seatsCovered: "",
                            color: "",
                            purchasePrice: "",
                            engineNo: "",
                            chassisNo: "",
                            beingUsedDetail: "",
                            mvFileNo: "",
                            mvTypeDetail: "",
                            mvPremTypeDetail: "",
                            remarks: "",
                            cocNo: "",
                            cocAuthNo: "",
                            cocAuthDate: ""
                        }
                        if(coverage !== undefined && coverage.tempId != null){
                            this.resetCoverage();
                        }
                        this.productService.setProductInfo2(this.productInfo);
                    }}
                ]
            });
            return;
        }
        
        if (!this.productInfo.prodVouchInfo.prodVouchCd || this.productInfo.prodVouchInfo.prodVouchCd === "" || this.productInfo.prodVouchInfo.prodVouchCd === null) {
            if (coverage === undefined || Object.keys(coverage).length === 0 || coverage.tempId === null || this.productInfo.planInfo.planCd === plan.planCd) {
                if (this.prodVouchForm.controls['prodVouchCode']?.value === '') {
                    this.productInfo.planInfo.planCd = plan.planCd;
                    this.productInfo.planInfo.planName = plan.planName;
                    this.productInfo.planInfo.premiumText = plan.premiumText;
                    this.productInfo.planInfo.referralTag = plan.referralTag;
                    this.productInfo.planInfo.referralCode = "";
                    this.productInfo.planInfo.currencyCd = plan.currencyCd;
                    
                    this.productService.setProductInfo2(this.productInfo);
                } else {
                    this.recomputeMessage(plan, 'Choose Plan');
                }
            } else {
                this.recomputeMessage(plan, 'Choose Plan');
            }
        } else {
            //if (coverage === undefined || Object.keys(coverage).length === 0 || coverage.tempId === null) {
                this.productInfo.planInfo.planCd = plan.planCd;
                this.productInfo.planInfo.planName = plan.planName;
                this.productInfo.planInfo.premiumText = plan.premiumText;
                this.productInfo.planInfo.referralTag = plan.referralTag;
                this.productInfo.planInfo.referralCode = "";
                this.productInfo.planInfo.currencyCd = plan.currencyCd;
                
                this.productService.setProductInfo2(this.productInfo);
            //} else {*/
                
                if (param !== 'prodVouch') {
                    this.recomputeMessage(plan, 'Choose Plan');
                }
                //this.recomputeMessage(plan, 'Choose Plan');
            //}
        }
    }

    recomputeMessage(plan: any, screen: any) {
        if (screen === "Product Code") {
            this.dialogBoxService.open({
                messageType: "C",
                content: "Changing your plan will require re-computing your coverage. Do you want to proceed?",
                buttons: [
                    { text: "No" , action: () =>{
                        if(screen === 'Choose Plan' || screen === 'Validate Code') {
                            if (this.productInfo.prodVouchInfo?.prodVouchCd) {
                                this.prodVouchForm.patchValue({prodVouchCode: this.productInfo.prodVouchInfo.prodVouchCd});
                            } else {
                                this.prodVouchForm.patchValue({prodVouchCode: ""});
                            }
                        }
                    }},
                    { text: "Yes", action: () => {
                        this.resetCoverage();
                        const maxDepWithPrem = this.jsonDataService.data.paValidations.filter((validators: any) => {
                          return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === plan.planCd
                        }).length !== 0 ? this.jsonDataService.data.paValidations.filter((validators: any) => {
                          return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === plan.planCd
                        })[0].MAX_DEP_WITH_PREM : "";
                        
                        if(maxDepWithPrem > 0) {
                            this.clearDependents();
                        }
                        
                        if (screen === 'Product Code') {
                            this.stepperDtlService.titleStepperDtl = "Choose An Insurance Plan";
                            this.showField = true;
                            this.selDisabled = false;
                            const planInfo = { planCd: null };
                
                            this.productInfo.planInfo = planInfo;
                
                            const prodVouchInfo = { planCd: "" };
                            
                            this.productInfo.prodVouchInfo = prodVouchInfo;
                            this.productService.setProductInfo2(this.productInfo);
                            this.prodVouchForm.patchValue({ prodVouchCode: ""});
                            this.loadPlans();
                        } else if (screen === 'Choose Plan') {
                            this.productInfo.prodVouchInfo = {
                                prodVouchCd: "",               
                                lineCd: "",                    
                                sublineCd: "",                 
                                planCd: "",                    
                                clientCd: "",
                                projectCd: "",
                                agentCd: "",
                                bmaCd: "",
                                referrorCd: "",
                                srcCd: "",
                                srcExtCd: ""
                            };
                            
                            this.productInfo.planInfo.planCd = plan.planCd;
                            this.productInfo.planInfo.planName = plan.planName;
                            this.productInfo.planInfo.premiumText = plan.premiumText;
                            this.productInfo.planInfo.referralTag = plan.referralTag;
                            this.productInfo.planInfo.referralCode = "";
                            this.productInfo.planInfo.currencyCd = plan.currencyCd;
                            this.productService.setProductInfo2(this.productInfo);
                        } else {
                            this.validateCd();
                        }
                    }}
                ]
            });
        } else if (screen === 'Choose Plan') {
            this.dialogBoxService.open({
                messageType: "C",
                content: `You selected <b>${plan.planName}</b>. This will compute your coverage based on this insurance plan. Do you want to continue?`,
                buttons: [
                    { text: "Yes", action: () => {
                        if (!(this.productInfo.coverageInfo === undefined || Object.keys(this.productInfo.coverageInfo).length === 0 || this.productInfo.coverageInfo.tempId === null)) {
                            this.resetCoverage();
                            const maxDepWithPrem = this.jsonDataService.data.paValidations.filter((validators: any) => {
                            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === plan.planCd
                            }).length !== 0 ? this.jsonDataService.data.paValidations.filter((validators: any) => {
                            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === plan.planCd
                            })[0].MAX_DEP_WITH_PREM : "";
                            
                            if(maxDepWithPrem > 0) {
                                this.clearDependents();
                            }
                        }
                        
                        this.prodVouchForm.patchValue({prodVouchCode: ""});
                        this.errorMessage = "";
                        this.productInfo.prodVouchInfo = {
                            prodVouchCd: "",               
                            lineCd: "",                    
                            sublineCd: "",                 
                            planCd: "",                    
                            clientCd: "",
                            projectCd: "",
                            agentCd: "",
                            bmaCd: "",
                            referrorCd: "",
                            srcCd: "",
                            srcExtCd: ""
                        };

                        this.productInfo.planInfo.planCd = plan.planCd;
                        this.productInfo.planInfo.planName = plan.planName;
                        this.productInfo.planInfo.premiumText = plan.premiumText;
                        this.productInfo.planInfo.referralTag = plan.referralTag;
                        this.productInfo.planInfo.referralCode = "";
                        this.productInfo.planInfo.currencyCd = plan.currencyCd;
                        this.productService.setProductInfo2(this.productInfo);
                    }},
                    { text: "No" , action: () =>{
                        //if(screen === 'Choose Plan' || screen === 'Validate Code') {
                            if (this.productInfo.prodVouchInfo?.prodVouchCd) {
                                this.prodVouchForm.patchValue({prodVouchCode: this.productInfo.prodVouchInfo.prodVouchCd});
                            } else {
                                this.prodVouchForm.patchValue({prodVouchCode: ""});
                            }
                        //}
                    }}
                ]
            });
        } else if (screen === 'Validate Code') {
            if (plan?.codeType === 'R' && plan.data.PLAN_CD) {
                let tempPlan = this.jsonService.data.plans.filter((data: any) => { return data.lineCd === this.productInfo.lineCd &&
                    data.sublineCd === this.productInfo.sublineCd &&
                    data.planCd === this.productInfo.planInfo.planCd })[0];
                this.productInfo.prodVouchInfo = {
                    prodVouchCd: this.prodVouchForm.get('prodVouchCode')?.value,
                    lineCd: this.productInfo.lineCd,                    
                    sublineCd: this.productInfo.sublineCd,                 
                    planCd: this.productInfo.planInfo.planCd,
                };
                this.onSelectPlanCd(tempPlan, 'prodVouch');
                this.productService.setProductInfo2(this.productInfo);
                return;
            }

            if (plan?.codeType === 'R' && !plan.data.PLAN_CD) {
                this.appMessageService.showAppMessage("Please choose an insurance plan first then type the code.", "info");
                return;
            }

            let tempPlan = this.jsonService.data.plans.filter((data: any) => { return data.lineCd === plan.data.LINE_CD &&
                                data.sublineCd === plan.data.SUBLINE_CD &&
                                data.planCd === plan.data.PLAN_CD})[0];
            this.dialogBoxService.open({
                messageType: "C",
                content: `Product Code/Voucher Code entered is for <b>${tempPlan.planName}</b>. This will compute your coverage based on this insurance plan. Do you want to continue?`,
                buttons: [
                    { text: "Yes", action: () => {
                        if (!(this.productInfo.coverageInfo === undefined || Object.keys(this.productInfo.coverageInfo).length === 0 || this.productInfo.coverageInfo.tempId === null)) {
                            this.resetCoverage();
                            const maxDepWithPrem = this.jsonDataService.data.paValidations.filter((validators: any) => {
                            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === tempPlan.planCd
                            }).length !== 0 ? this.jsonDataService.data.paValidations.filter((validators: any) => {
                            return validators.LINE_CD === this.productInfo.lineCd && validators.SUBLINE_CD === this.productInfo.sublineCd && validators.PLAN_CD === tempPlan.planCd
                            })[0].MAX_DEP_WITH_PREM : "";
                            
                            if(maxDepWithPrem > 0) {
                                this.clearDependents();
                            }
                        }
                        
                        //this.validateCd();
                        let pVCd = plan.data.PRODUCT_CODE || plan.data.VOUCHER_CODE;
                        this.productInfo.prodVouchInfo = {
                            prodVouchCd: pVCd,               
                            lineCd: plan.data.LINE_CD,                    
                            sublineCd: plan.data.SUBLINE_CD,                 
                            planCd: plan.data.PLAN_CD,                    
                            clientCd: plan.data.CLIENT_CD,
                            projectCd: plan.data.PROJECT_CD,
                            agentCd: plan.data.AGENT_CD,
                            bmaCd: plan.data.BMA_CD,
                            referrorCd: plan.data.REFERROR_CD,
                            srcCd: plan.data.SRC_CD,
                            srcExtCd: plan.data.SRC_EXT_CD
                        };

                        for (const plans of this.jsonService.data.plans.filter((a:any) =>
                                a.lineCd === plan.data.LINE_CD
                             && a.sublineCd === plan.data.SUBLINE_CD
                             && a.planCd === plan.data.PLAN_CD
                             && formatDate(this.date, "yyyy-MM-dd", "en") >= a.effDateFrom
                             && (formatDate(this.date, "yyyy-MM-dd", "en") <= a.effDateTo || a.effDateTo === null))) 
                        {
                            if (plans.productCdTag === "Y") {
                                this.plans = [];
                                this.plans.push(plans);
                                this.showField = false;
                                this.selDisabled = true;
                                this.stepperDtlService.titleStepperDtl = "Insurance Plan Details";
                            }
                        }

                        /*this.onSelectPlanCd(this.plans.filter((data: any) => { return data.lineCd === plan.data.LINE_CD &&
                            data.sublineCd === plan.data.SUBLINE_CD &&
                            data.planCd === plan.data.PLAN_CD})[0], "prodVouch");*/

                        this.onSelectPlanCd(tempPlan, "prodVouch");

                        this.productService.setProductInfo2(this.productInfo);

                    }},
                    { text: "No" , action: () =>{
                        //if(screen === 'Choose Plan' || screen === 'Validate Code') {
                            if (this.productInfo.prodVouchInfo?.prodVouchCd) {
                                this.prodVouchForm.patchValue({prodVouchCode: this.productInfo.prodVouchInfo.prodVouchCd});
                            } else {
                                this.prodVouchForm.patchValue({prodVouchCode: ""});
                            }
                        //}
                    }}
                ]
            });
        }
    };

    // chkBox(event:any, param: string) {
    //     if (param === 'CB1') {
    //         if (event.checked) {
    //             this.planOfferForm.patchValue({isCB1: "Y"});
    //             this.isCB1Check = true;
    //         } else {
    //             this.planOfferForm.patchValue({isCB1: "N"});
    //             this.isCB1Check = false;
    //         }
    //     } else if (param === 'CB2') {
    //         if (event.checked) {
    //             this.planOfferForm.patchValue({isCB2: "Y"});
    //             this.isCB2Check = true;
    //         } else {
    //             this.planOfferForm.patchValue({isCB2: "N"});
    //             this.isCB2Check = false;
    //         }
    //     } else if (param === 'CB3') {
    //         if (event.checked) {
    //             this.planOfferForm.patchValue({isCB3: "Y"});
    //             this.isCB3Check = true;
    //         } else {
    //             this.planOfferForm.patchValue({isCB3: "N"});
    //             this.isCB3Check = false;
    //         }
    //     }

    //     this.isCB1Check = this.condition1 ? this.isCB1Check : true;
    //     this.isCB2Check = this.condition2 ? this.isCB2Check : true;
    //     this.isCB3Check = this.condition3 ? this.isCB3Check : true;

    //     if (this.isCB1Check && this.isCB2Check && this.isCB3Check) {
    //         this.nextDisabled = false;
    //     }
    //     else {
    //         this.nextDisabled = true;
    //     }
    // }

    resetCoverage():void {
        this.productInfo.perilInfo = [];
        this.productInfo.coverages = [];
        this.productInfo.ara = [];
        this.productInfo.peril = [];
        this.productInfo.taxes = [];
        this.productInfo.coverageInfo = {};
        this.productInfo.deductibles = [];
        this.productInfo.recomputeFlag = true;
        this.productService.setProductInfo2(this.productInfo);
    }

    private clearDependents(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.deleteDependents({
            quoteId: this.productInfo.quoteId
        }).subscribe({
            next: (response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.productService.setProductInfo('dependents', []);
                        this.productService.setProductInfo('dependentsEdit', []);
                    }
                    this.jsonDataService.contorlLoading(false);
                });
            },
            error: (e: any) => {
                this.securityService.checkRequestKeyResponse(e, () => {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Error changing plans.", "error");
                });
            }
        });
    }

    getPdf(lineCd:string, sublineCd:string, planCd:string):void {
        for (const plan of this.jsonService.data.plans.filter((a:any) =>
               a.lineCd === lineCd
            && a.sublineCd === sublineCd
            && a.planCd === planCd
        )) {
            window.open(plan.termsPath);
        }
    }
}